import { retryWhenStrategy } from '@alliance/shared/utils'
import { Injectable } from '@angular/core'
import { Observable, of } from 'rxjs'
import { catchError, filter, map, retryWhen, take } from 'rxjs/operators'
import { GetMetrosDictionaryGQL, MetroDictionaryItemFragment } from './metro-dictionary.generated'

@Injectable({
  providedIn: 'root'
})
export class MetroDictionaryService {
  public constructor(private metroDictionaryGQL: GetMetrosDictionaryGQL) {}

  public getMetros$(cityId: string): Observable<MetroDictionaryItemFragment[]> {
    return this.metroDictionaryGQL.fetch({ cityId }, { fetchPolicy: 'cache-first' }).pipe(
      map(({ data }) => (data?.cityMetro || []).reduce<MetroDictionaryItemFragment[]>((acc, cityMetro) => (cityMetro ? [...acc, cityMetro] : acc), [])),
      retryWhen(retryWhenStrategy()),
      catchError(() => of([]))
    )
  }

  public getMetroById$(cityId: string, metroId: string): Observable<MetroDictionaryItemFragment | null> {
    return this.getMetros$(cityId).pipe(
      filter(metros => !!metros?.length),
      take(1),
      map(metros => metros.find(metro => metro.id === metroId) || null)
    )
  }
}
