// id number per type Date (starts with 0)
export interface MonthsItem {
  id: number
  ru: string
  ua: string
  en: string
}
export const MONTHS: MonthsItem[] = [
  {
    id: 0,
    ru: 'Январь',
    ua: 'Січень',
    en: 'January'
  },
  {
    id: 1,
    ru: 'Февраль',
    ua: 'Лютий',
    en: 'February'
  },
  {
    id: 2,
    ru: 'Март',
    ua: 'Березень',
    en: 'March'
  },
  {
    id: 3,
    ru: 'Апрель',
    ua: 'Квітень',
    en: 'April'
  },
  {
    id: 4,
    ru: 'Май',
    ua: 'Травень',
    en: 'May'
  },
  {
    id: 5,
    ru: 'Июнь',
    ua: 'Червень',
    en: 'June'
  },

  {
    id: 6,
    ru: 'Июль',
    ua: 'Липень',
    en: 'July'
  },
  {
    id: 7,
    ru: 'Август',
    ua: 'Серпень',
    en: 'August'
  },
  {
    id: 8,
    ru: 'Сентябрь',
    ua: 'Вересень',
    en: 'September'
  },

  {
    id: 9,
    ru: 'Октябрь',
    ua: 'Жовтень',
    en: 'October'
  },
  {
    id: 10,
    ru: 'Ноябрь',
    ua: 'Листопад',
    en: 'November'
  },
  {
    id: 11,
    ru: 'Декабрь',
    ua: 'Грудень',
    en: 'December'
  }
]
