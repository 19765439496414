import { Environment } from '@alliance/shared/environment'
import { log } from '@alliance/shared/logger'
import { DetectPlatformService, HelpersService, MediaService } from '@alliance/shared/utils'
import { Inject, Injectable } from '@angular/core'
import { DOCUMENT } from '@angular/common'
import { WINDOW } from '@ng-web-apis/common'
import { skip } from 'rxjs'

interface CustomWindow extends Window {
  dataLayer: unknown[]
  google_tag_manager?: { [key: string]: unknown }
}

@Injectable({
  providedIn: 'root'
})
export class GoogleAnalyticService {
  public constructor(
    @Inject(WINDOW) private readonly windowRef: CustomWindow,
    @Inject(DOCUMENT) private document: Document,
    private helpService: HelpersService,
    private environment: Environment,
    private mediaService: MediaService,
    private platform: DetectPlatformService
  ) {
    if (!this.environment?.gtmIdDesktop) {
      log.warn({ where: 'shared-google-analytics: GoogleAnalyticService', category: 'unexpected_value', message: 'gtmIdDesktop not setting in environment' })
    }

    if (!this.environment?.gtmIdMobile) {
      log.warn({ where: 'shared-google-analytics: GoogleAnalyticService', category: 'unexpected_value', message: 'gtmIdMobile not setting in environment' })
    }

    this.mediaService
      .select('isMobileScreen')
      .pipe(skip(1))
      .subscribe(() => {
        this.cleanAnalytics()
        this.addGoogleAnalytics()
      })
  }

  public addGoogleAnalytics(): boolean {
    if (this.windowRef.google_tag_manager) {
      log.error({
        where: 'shared-google-analytics: GoogleAnalyticService',
        category: 'unexpected_value',
        message: 'analytic script already present',
        error: 'Remove google analytic script from index.html, use addGoogleAnalytics instead'
      })
      return true
    }
    const gtmId = this.helpService.isMobile() ? this.environment.gtmIdMobile || '' : this.environment.gtmIdDesktop || ''

    if (this.platform.isBrowser) {
      try {
        const gtmScript = this.document.createElement('script')
        gtmScript.id = 'GTMscript'
        gtmScript.async = true
        gtmScript.src = `https://www.googletagmanager.com/gtm.js?id=${gtmId}`
        this.document.head.insertBefore(gtmScript, this.document.head.firstChild)
      } catch (e) {
        log.error({
          where: 'shared-google-analytics: GoogleAnalyticService',
          category: 'try_catch',
          message: 'addGoogleAnalytics script failed',
          error: e
        })
      }
    }

    return true
  }

  private cleanAnalytics(): void {
    this.document.getElementById('GTMscript')?.remove()
    this.windowRef.dataLayer = []
    delete this.windowRef.google_tag_manager
  }
}
