import { CvDbSearchRequest } from '@alliance/employer/data-access'
import { CvdbState } from '../cvdb.model'

export const createAlternativeFilters = (
  filterValues: CvDbSearchRequest,
  defaultFilters: CvDbSearchRequest,
  state: CvdbState
): { [k in keyof Pick<CvDbSearchRequest, 'moveability' | 'onlyMoveability'>]: boolean } => {
  if (filterValues?.moveability === undefined && filterValues?.onlyMoveability === undefined) {
    const { moveability, onlyMoveability } = defaultFilters
    return { moveability, onlyMoveability }
  }

  const getBooleanType = (param: boolean | undefined, name: 'moveability' | 'onlyMoveability'): boolean => (typeof param === 'boolean' ? param : !!defaultFilters[name])

  return {
    moveability: filterValues?.onlyMoveability && !state?.searchFilters?.moveability ? true : filterValues.moveability,
    onlyMoveability: !filterValues?.moveability && state?.searchFilters?.onlyMoveability ? false : getBooleanType(filterValues.onlyMoveability, 'onlyMoveability')
  }
}
