export const addSuffixForBigNumber = (val: number, localization: 'ru' | 'uk' = 'uk', thousandSuffix?: string, billionSuffix?: string): string => {
  const thousandText = thousandSuffix ?? (localization === 'ru' ? 'тыс' : 'тис')
  const billionText = billionSuffix ?? 'млн'

  if (val >= 1e3 && val < 1e6) {
    return `${val}`.slice(0, -3) + ` ${thousandText}`
  }

  if (val >= 1e6 && val < 1e9) {
    return `${val}`.slice(0, -6) + ` ${billionText}`
  }

  return `${val.toFixed()}`
}
