import { Injectable } from '@angular/core'
import { GetVacanciesStopWordsGQL } from './stop-words.generated'
import { catchError, map } from 'rxjs/operators'
import { Observable, of } from 'rxjs'
import { getNonNullableItems } from '@alliance/shared/utils'

@Injectable({
  providedIn: 'root'
})
export class StopWordsService {
  public constructor(private getVacanciesStopWordsGQL: GetVacanciesStopWordsGQL) {}

  public getStopWords$(): Observable<string[]> {
    return this.getVacanciesStopWordsGQL.fetch({}).pipe(
      map(({ data }) => getNonNullableItems<string>(data?.stopWords || [])),
      catchError(() => of([]))
    )
  }
}
