import { ContextWithImplicit } from '@alliance/shared/models'
import { PolymorpheusContent } from '@alliance/shared/polymorpheus'
import { channelTypes } from './channel-types'

export enum NotificationTypeEnum {
  CREATED = 'CREATED',
  CLOSED = 'CLOSED',
  ACTIVATED = 'ACTIVATED'
}

export enum ActionTypesEnum {
  SUCCESS = 'SUCCESS',
  ERROR = 'ERROR',
  CANCEL = 'CANCEL',
  JOBSEEKER_SUCCESS = 'JOBSEEKER_SUCCESS'
}

export interface Notification<CustomMetaType> {
  channel: channelTypes
  title?: string
  body: PolymorpheusContent<unknown>
  image?: string
  meta?: CustomMetaType
  // temporary hack for features with feature-alert on desktop & snackbar component on mobile
  // prevent rerender mobile-wrap from feature-alert on window resize if snackbar is used for mobile screen
  hideOnMobile?: boolean
  imageClass?: string
}

export interface Action<CustomMetaType> {
  type: NotificationTypeEnum
  payload?: Notification<CustomMetaType>
}

export interface ChatMessageMetaType {
  conversationId: string
}

export interface ActionStatusMessageMetaType {
  status: ActionTypesEnum
}

export interface ActionStatusMessageWithActionMetaType extends ActionStatusMessageMetaType {
  action: {
    handler: () => void
    text: string
  }
}

export interface ContextWithActions<T> extends ContextWithImplicit<T> {
  onClose: (event: Event) => void
  onActivate: () => void
}
