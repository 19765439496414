import { CountryCodesEnum } from '@alliance/shared/models'
import { IsNewRobotaDomainService } from '@alliance/shared/utils'
import { Location } from '@angular/common'
import { Compiler, Injector, Type } from '@angular/core'
import { ChildrenOutletContexts, NavigationExtras, Route, Router, UrlSerializer, UrlTree } from '@angular/router'
import { TranslationService } from '../translation.service'

export class CustomLanguageRouter extends Router {
  private readonly hiddenLanguageCountryCode = this.isNewRobotaDomainService.isNewRobotaDomain() ? CountryCodesEnum.UA : CountryCodesEnum.RU

  public constructor(
    rootComponentType: Type<unknown> | null,
    urlSerializer: UrlSerializer,
    rootContexts: ChildrenOutletContexts,
    location: Location,
    injector: Injector,
    compiler: Compiler,
    config: Route[],
    private translationService: TranslationService,
    private isNewRobotaDomainService: IsNewRobotaDomainService
  ) {
    super(rootComponentType, urlSerializer, rootContexts, location, injector, compiler, config)
  }

  public override navigateByUrl(url: string | UrlTree, extras?: NavigationExtras): Promise<boolean> {
    if (this.isNewRobotaDomainService.isNewRobotaDomain()) {
      if (typeof url === 'string' && !!url.length && this.translationService.currentLangIsRussian() && !url.includes(`/${CountryCodesEnum.RU}`)) {
        url = url.charAt(0) !== '/' && url.charAt(0) !== '?' ? `/${url}` : url
        url = `/${CountryCodesEnum.RU}${url}`
      }
    } else {
      if (typeof url === 'string' && !!url.length && this.translationService.currentLangIsUkrainian() && !url.includes(`/${CountryCodesEnum.UA}`)) {
        url = url.charAt(0) !== '/' && url.charAt(0) !== '?' ? `/${url}` : url
        url = `/${CountryCodesEnum.UA}${url}`
      }
    }

    return super.navigateByUrl(url, extras)
  }

  public override createUrlTree(commands: string[], navigationExtras?: NavigationExtras): UrlTree {
    if (commands?.[0]?.[0] === '/') {
      const urlWithoutQueryParams = this.url.split('?')[0]
      const currentUrlLangPrefix = urlWithoutQueryParams.split('/')[1]

      const targetUrlLangPrefix = commands[0].split('/')[1]

      if (Object.values(CountryCodesEnum).some(code => code === currentUrlLangPrefix) && currentUrlLangPrefix !== targetUrlLangPrefix) {
        commands[0] = `/${currentUrlLangPrefix}${commands[0]}`
      }

      if (Object.values(CountryCodesEnum).includes(targetUrlLangPrefix as CountryCodesEnum) && this.hiddenLanguageCountryCode === targetUrlLangPrefix) {
        commands[0] = commands[0].replace(`/${targetUrlLangPrefix}`, '')
      }
    }

    return super.createUrlTree(commands, navigationExtras)
  }
}
