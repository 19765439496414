import { CommonModule } from '@angular/common'
import { ModuleWithProviders, NgModule } from '@angular/core'
import { DateFromStringPipe } from './date-from-string.pipe'
import { setDefaultOptionsProvider } from './datetime.providers'
import { FormatDatePipe } from './format-date.pipe'

@NgModule({
  imports: [CommonModule],
  declarations: [DateFromStringPipe, FormatDatePipe],
  exports: [DateFromStringPipe, FormatDatePipe]
})
export class SharedDatetimeModule {
  public static forRoot(): ModuleWithProviders<SharedDatetimeModule> {
    return {
      ngModule: SharedDatetimeModule,
      providers: [setDefaultOptionsProvider()]
    }
  }
}
