import { ComponentRef, Injectable, Type } from '@angular/core'
import { ComponentPortal } from '@angular/cdk/portal'
import { asyncScheduler, timer } from 'rxjs'
import { ModalOverlayRef, ModalService } from '../modal'
import { FixedModalComponent } from '../../components/fixed-modal/fixed-modal.component'

@Injectable({
  providedIn: 'root'
})
export class FixedModalService extends ModalService {
  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  protected override readonly hostComponent: any = FixedModalComponent

  public override openByComponent<T, K>(content: Type<T>, data?: K): ModalOverlayRef {
    if (this.hasAttachedModal) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      return
    }

    const overlayRef = this.createOverlay()
    const portal = new ComponentPortal(content)
    const modalRef = new ModalOverlayRef(overlayRef)
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const injector = this.createInjector<K>(modalRef, data)
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
    const modalPortal = new ComponentPortal<FixedModalComponent<T>>(this.hostComponent, null, injector)

    timer(0, asyncScheduler).subscribe(() => {
      const modalComponent: ComponentRef<FixedModalComponent<T>> = overlayRef.attach(modalPortal)
      modalComponent.instance.content = portal
    })

    return modalRef
  }
}
