import { Injectable } from '@angular/core'
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http'
import { BaseService as __BaseService } from '../base-service'
import { JobseekerDataAccessConfiguration as __Configuration } from '../jobseeker-data-access-configuration'
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response'
import { Observable as __Observable } from 'rxjs'
import { map as __map, filter as __filter } from 'rxjs/operators'

@Injectable({
  providedIn: 'root'
})
class FacebookService extends __BaseService {
  public static readonly facebookFacebookPath = '/login/facebook'
  public static readonly facebookTokenPath = '/login/facebook/token'

  public constructor(config: __Configuration, http: HttpClient) {
    super(config, http)
  }

  /**
   * @param params The `FacebookService.FacebookFacebookParams` containing the following parameters:
   *
   * - `state`:
   *
   * - `code`:
   *
   * @return OK
   */
  facebookFacebookResponse(params: FacebookService.FacebookFacebookParams): __Observable<__StrictHttpResponse<{}>> {
    let __params = this.newParams()
    let __headers = new HttpHeaders()
    let __body: any = null
    if (params.state != null) __params = __params.set('state', params.state.toString())
    if (params.code != null) __params = __params.set('code', params.code.toString())
    let req = new HttpRequest<any>('GET', this.rootUrl + `/login/facebook`, __body, {
      headers: __headers,
      params: __params,
      responseType: 'json'
    })

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map(_r => {
        return _r as __StrictHttpResponse<{}>
      })
    )
  }

  /**
   * @param params The `FacebookService.FacebookFacebookParams` containing the following parameters:
   *
   * - `state`:
   *
   * - `code`:
   *
   * @return OK
   */
  facebookFacebook(params: FacebookService.FacebookFacebookParams): __Observable<{}> {
    return this.facebookFacebookResponse(params).pipe(__map(_r => _r.body as {}))
  }

  /**
   * @param token undefined
   * @return OK
   */
  facebookTokenResponse(token: string): __Observable<__StrictHttpResponse<{}>> {
    let __params = this.newParams()
    let __headers = new HttpHeaders()
    let __body: any = null
    if (token != null) __params = __params.set('token', token.toString())
    let req = new HttpRequest<any>('GET', this.rootUrl + `/login/facebook/token`, __body, {
      headers: __headers,
      params: __params,
      responseType: 'json'
    })

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map(_r => {
        return _r as __StrictHttpResponse<{}>
      })
    )
  }

  /**
   * @param token undefined
   * @return OK
   */
  facebookToken(token: string): __Observable<{}> {
    return this.facebookTokenResponse(token).pipe(__map(_r => _r.body as {}))
  }
}

module FacebookService {
  /**
   * Parameters for FacebookFacebook
   */
  export interface FacebookFacebookParams {
    state?: string
    code?: string
  }
}

export { FacebookService }
