import { Injectable } from '@angular/core'
import { Observable } from 'rxjs'
import { PartialSeoParamsResponse, PlatformHosts, SearchByProfessionsCitySeoParams } from '../models'
import { Translations } from '../localization/translations'
import { TranslationService } from '@alliance/shared/translation'
import { HelpersService } from '../helpers.service'
import { DictionaryService } from '@alliance/jobseeker/api'
import { map } from 'rxjs/operators'

@Injectable({
  providedIn: 'root'
})
export class SearchByProfessionsCityDictionaryService {
  public constructor(private translations: Translations, private translationService: TranslationService, private helpersService: HelpersService, private dictionaryService: DictionaryService) {}

  public getParams({ params: { cityId } }: SearchByProfessionsCitySeoParams): Observable<PartialSeoParamsResponse> {
    const url = `/jobsearch/professions-in-cities/${cityId ?? 0}`

    return this.dictionaryService.getCityName$(cityId ?? 0, null, true).pipe(
      map(cityName => ({
        title: this.translationService.translate(this.translations.searchProfSelectCity.title, { cityName }),
        description: this.translationService.translate(this.translations.searchProfSelectCity.description, {
          cityName
        }),
        h1: [this.translationService.translate(this.translations.searchProfSelectCity.h1, { cityName })],
        canonicalUrl: this.helpersService.createURL(PlatformHosts.desktop, url),
        hrefLang: this.helpersService.createHrefLangURLs(url, url),
        noIndexNoFollow: false,
        jsonLd: {
          desktop: this.helpersService.createJsonLd(PlatformHosts.desktop)
        }
      }))
    )
  }
}
