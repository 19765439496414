import { HammerGestureConfig } from '@angular/platform-browser'
import { Injectable } from '@angular/core'

@Injectable()
export class LocalHammerConfig extends HammerGestureConfig {
  public override overrides = {
    // todo DIRECTION_ALL form hummer js (не получилось сделать import DIRECTION_ALL c hummer.js так как в ssr нет window а в hummer.js он нужен)
    pan: { direction: 30 }
  }
}
