import { Pipe, PipeTransform } from '@angular/core'
import { DomSanitizer, SafeHtml, SafeResourceUrl, SafeScript, SafeStyle, SafeUrl } from '@angular/platform-browser'

enum SafeTypesKeys {
  html = 'html',
  style = 'style',
  script = 'script',
  url = 'url',
  resourceUrl = 'resourceUrl'
}

type SafeResponseType = SafeHtml | SafeStyle | SafeScript | SafeUrl | SafeResourceUrl

@Pipe({
  name: 'bypassSanitizer'
})
export class BypassSanitizerPipe implements PipeTransform {
  public constructor(protected sanitizer: DomSanitizer) {}

  public transform(value: string, type: keyof typeof SafeTypesKeys): SafeResponseType {
    switch (type) {
      case 'html':
        return this.sanitizer.bypassSecurityTrustHtml(value)
      case 'style':
        return this.sanitizer.bypassSecurityTrustStyle(value)
      case 'script':
        return this.sanitizer.bypassSecurityTrustScript(value)
      case 'url':
        return this.sanitizer.bypassSecurityTrustUrl(value)
      case 'resourceUrl':
        return this.sanitizer.bypassSecurityTrustResourceUrl(value)
      default:
        throw new Error(`Invalid safe type specified`)
    }
  }
}
