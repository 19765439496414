import { TranslationService } from '@alliance/shared/translation'
import { BreadcrumbList } from 'schema-dts'
import { Injectable } from '@angular/core'

import { Observable, of } from 'rxjs'
import { HelpersService } from '../helpers.service'

import { CandidatesSearchBySeoParams, PartialSeoParamsResponse, PlatformHosts } from '../models'
import { Translations } from '../localization/translations'
import { CandidatesSearchByTypeModel } from '../../openapi/model/candidates-search-by-type.model'

@Injectable({
  providedIn: 'root'
})
export class SearchByRootPageDictionaryService {
  public constructor(private translations: Translations, private translationService: TranslationService, private helpersService: HelpersService) {}

  public getParams({ params }: CandidatesSearchBySeoParams): Observable<PartialSeoParamsResponse> {
    const url = `/candidates`

    return of({
      title: this.translationService.translate(this.translations.searchByRootPage.title),
      description: this.translationService.translate(this.translations.searchByRootPage.description),
      canonicalUrl: this.helpersService.createURL(PlatformHosts.desktop, url),
      alternateUrl: this.helpersService.createURL(PlatformHosts.desktop, url),
      h1: [this.translationService.translate(this.translations.searchByRootPage.defaultHeadingText), this.getHeadingBySearchSegment(params.searchByType)],
      hrefLang: this.helpersService.createHrefLangURLs(url, url),
      noIndexNoFollow: false,
      jsonLd: {
        desktop: this.getEmployerBreadcrumbsList(PlatformHosts.desktop),
        mobile: this.getEmployerBreadcrumbsList(PlatformHosts.desktop)
      }
    })
  }

  private getEmployerBreadcrumbsList(platform: PlatformHosts): string {
    return this.helpersService.createJsonLd<BreadcrumbList>(platform, {
      '@context': 'https://schema.org',
      '@type': 'BreadcrumbList',
      itemListElement: [
        this.helpersService.getEmployerHomePageBreadcrumb(platform),
        this.helpersService.getCandidatesBreadcrumb(platform),
        {
          '@type': 'ListItem',
          position: 3,
          name: this.translationService.translate(this.translations.jsonLd.breadcrumbs.resumeInUkraine),
          item: this.helpersService.createURL(platform, '/candidates/all')
        }
      ]
    })
  }

  private getHeadingBySearchSegment(segment: CandidatesSearchByTypeModel = 'rubrics'): string {
    switch (segment) {
      case 'professions':
        return this.translationService.translate(this.translations.searchByRootPage.h1.professions)
      case 'cities':
        return this.translationService.translate(this.translations.searchByRootPage.h1.cities)
      default:
        return this.translationService.translate(this.translations.searchByRootPage.h1.rubrics)
    }
  }
}
