/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core'

/**
 * Global configuration
 */
@Injectable({
  providedIn: 'root'
})
export class ApplyApiDataAccessConfiguration {
  rootUrl: string = ''
}

/**
 * Parameters for `ApplyApiDataAccessModule.forRoot()`
 */
export interface ApplyApiDataAccessConfigurationParams {
  rootUrl?: string
}
