import { AuthService } from '@alliance/shared/auth/api'
import { addWeeks, endOfDay, getDateFromString, isAfter } from '@alliance/shared/datetime'
import { RxStateService } from '@alliance/shared/models'
import { retryWhenStrategy } from '@alliance/shared/utils'
import { Injectable } from '@angular/core'
import { combineLatest, iif, Observable, of } from 'rxjs'
import { catchError, distinctUntilChanged, map, retryWhen, shareReplay, switchMap } from 'rxjs/operators'
import { CompanyTurbotaStateFragment, GetCompanyTurbotaStateGQL } from './company-turbota-state.generated'
import { TurbotaActivatedServicesContext } from './turbota-activated-services-context-enum'

@Injectable({
  providedIn: 'root'
})
export class CompanyTurbotaStateService extends RxStateService<{
  turbotaState: CompanyTurbotaStateFragment | null
  showFirstActivation: boolean
  firstActivationContext: TurbotaActivatedServicesContext | null
  hasActive: boolean
  activatedOrderId: string | null
  activateToDate: string | null
  hasExpired: boolean
}> {
  public constructor(private authService: AuthService, private getCompanyTurbotaStateGQL: GetCompanyTurbotaStateGQL) {
    super()

    this.initState({
      showFirstActivation: false,
      firstActivationContext: null,
      turbotaState: this.getTurbotaState$().pipe(shareReplay(1)),
      hasActive: this.select('turbotaState').pipe(map(state => !!state?.isActiveNow)),
      activatedOrderId: this.select('turbotaState').pipe(map(state => state?.order?.id ?? null)),
      activateToDate: this.select('turbotaState').pipe(map(state => state?.endsAt || null)),
      hasExpired: combineLatest([this.select('hasActive'), this.select('activateToDate').pipe(map(value => (value ? getDateFromString(value) : null)))]).pipe(
        map(([hasActive, activateToDate]) => hasActive && !!activateToDate && isAfter(new Date(), endOfDay(activateToDate)))
      )
    })
  }

  public setFirstActivationContext(orderId: string, context: TurbotaActivatedServicesContext | null): void {
    this.set({
      showFirstActivation: true,
      firstActivationContext: context,
      turbotaState: {
        hasTurbotaAccess: true,
        isActiveNow: true,
        activatedAt: new Date().toISOString(),
        endsAt: addWeeks(new Date(), 1).toISOString(),
        order: {
          id: orderId
        }
      }
    })
  }

  public resetFirstActivation(): void {
    this.set({ showFirstActivation: false, firstActivationContext: null })
  }

  private getTurbotaState$(): Observable<CompanyTurbotaStateFragment | null> {
    return this.authService.isLoggedIn$.pipe(
      distinctUntilChanged(),
      switchMap(isLoggedIn =>
        iif(
          () => isLoggedIn,
          this.getCompanyTurbotaStateGQL.fetch({ id: this.authService.user?.NotebookId?.toString() || '' }).pipe(
            map(({ data }) => data?.company?.turbotaStatus ?? null),
            retryWhen(retryWhenStrategy()),
            catchError(() => of(null))
          ),
          of(null)
        )
      )
    )
  }
}
