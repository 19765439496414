import { Injectable } from '@angular/core'
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http'
import { BaseService as __BaseService } from '../base-service'
import { EmployerDataAccessConfiguration as __Configuration } from '../employer-data-access-configuration'
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response'
import { Observable as __Observable } from 'rxjs'
import { map as __map, filter as __filter } from 'rxjs/operators'

@Injectable({
  providedIn: 'root'
})
class CheckerService extends __BaseService {
  public static readonly sendPhoneCodePath = '/checker/checkphone'
  public static readonly validatePhoneCodePath = '/checker/checkphonecode'

  public constructor(config: __Configuration, http: HttpClient) {
    super(config, http)
  }

  /**
   * @param phone undefined
   */
  sendPhoneCodeResponse(phone?: string): __Observable<__StrictHttpResponse<any>> {
    let __params = this.newParams()
    let __headers = new HttpHeaders()
    let __body: any = null
    if (phone != null) __params = __params.set('phone', phone.toString())
    let req = new HttpRequest<any>('POST', this.rootUrl + `/checker/checkphone`, __body, {
      headers: __headers,
      params: __params,
      responseType: 'json'
    })

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map(_r => {
        return _r as __StrictHttpResponse<any>
      })
    )
  }

  /**
   * @param phone undefined
   */
  sendPhoneCode(phone?: string): __Observable<any> {
    return this.sendPhoneCodeResponse(phone).pipe(__map(_r => _r.body as any))
  }

  /**
   * @param params The `CheckerService.ValidatePhoneCodeParams` containing the following parameters:
   *
   * - `Phone`:
   *
   * - `Code`:
   *
   * - `PhoneType`:
   */
  validatePhoneCodeResponse(params: CheckerService.ValidatePhoneCodeParams): __Observable<__StrictHttpResponse<any>> {
    let __params = this.newParams()
    let __headers = new HttpHeaders()
    let __body: any = null
    if (params.Phone != null) __params = __params.set('Phone', params.Phone.toString())
    if (params.Code != null) __params = __params.set('Code', params.Code.toString())
    if (params.PhoneType != null) __params = __params.set('PhoneType', params.PhoneType.toString())
    let req = new HttpRequest<any>('POST', this.rootUrl + `/checker/checkphonecode`, __body, {
      headers: __headers,
      params: __params,
      responseType: 'json'
    })

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map(_r => {
        return _r as __StrictHttpResponse<any>
      })
    )
  }

  /**
   * @param params The `CheckerService.ValidatePhoneCodeParams` containing the following parameters:
   *
   * - `Phone`:
   *
   * - `Code`:
   *
   * - `PhoneType`:
   */
  validatePhoneCode(params: CheckerService.ValidatePhoneCodeParams): __Observable<any> {
    return this.validatePhoneCodeResponse(params).pipe(__map(_r => _r.body as any))
  }
}

module CheckerService {
  /**
   * Parameters for ValidatePhoneCode
   */
  export interface ValidatePhoneCodeParams {
    Phone: string
    Code: string
    PhoneType?: 'Company' | 'MultiUser'
  }
}

export { CheckerService }
