import { InjectionToken } from '@angular/core'

export interface SentryInitOptions {
  dsn: string
  tracesSampleRate: string
  transactionNamesList?: string[]
  transactionMapping?: (contextName: string) => string
}

export const SENTRY_INIT_OPTIONS = new InjectionToken<SentryInitOptions>('SentryInitOptions')
