import { Injectable } from '@angular/core'
import { RxStateService } from '@alliance/shared/models'

@Injectable({
  providedIn: 'root'
})
export class RedirectUrlService extends RxStateService<{
  redirectUrl: string
}> {
  public constructor() {
    super()

    this.initState({
      redirectUrl: ''
    })
  }

  public setRedirectUrl(url: string): void {
    this.set({
      redirectUrl: url
    })
  }

  public getRedirectUrl(): string {
    return this.get().redirectUrl
  }

  public resetRedirectUrl(): void {
    this.set({
      redirectUrl: ''
    })
  }
}
