import { NotebookCompanyInfo, NotebookService } from '@alliance/employer/data-access'
import { AuthService } from '@alliance/shared/auth/api'
import { Injectable } from '@angular/core'
import { Observable } from 'rxjs'
import { distinctUntilChanged, filter, share, shareReplay, switchMap } from 'rxjs/operators'

@Injectable({
  providedIn: 'root'
})
export class CompanyInfoService {
  public companyInfo$: Observable<NotebookCompanyInfo | null>

  public constructor(private notebookService: NotebookService, private authService: AuthService) {
    this.companyInfo$ = this.authService.token$.pipe(
      distinctUntilChanged(),
      share(),
      filter(token => !!token && this.authService.isEmployer),
      switchMap(() =>
        this.notebookService.companyInfoV2({
          notebookId: this.authService.user?.NotebookId || 0,
          userId: this.authService.user?.MultiUserId || 0
        })
      ),
      shareReplay(1)
    )
  }
}
