import { Injectable } from '@angular/core'

import { Observable, of } from 'rxjs'
import { catchError } from 'rxjs/operators'

import { FetchResult } from '@apollo/client/core'

import { CompanyTrackViewGQL, CompanyTrackViewMutation, CompanyTrackViewMutationVariables } from './company.service.generated'

@Injectable()
export class CompanyTrackService {
  public constructor(private companyTrackViewGQL: CompanyTrackViewGQL) {}
  public companyTrackView(input: CompanyTrackViewMutationVariables): Observable<FetchResult<CompanyTrackViewMutation> | null> {
    return this.companyTrackViewGQL.mutate(input).pipe(catchError(() => of(null)))
  }
}
