import { FilterMyServicesInput, ServiceState, ServiceType } from '@alliance/shared/domain-gql'

export const DEFAULT_PUBLICATION_SERVICES_FILTER: FilterMyServicesInput = {
  states: [ServiceState.Active, ServiceState.Ordered],
  types: [
    ServiceType.Test,
    ServiceType.FreeBusiness,
    ServiceType.Business,
    ServiceType.BusinessSingle,
    ServiceType.Optimum,
    ServiceType.OptimumSingle,
    ServiceType.Professional,
    ServiceType.ProfessionalSingle,
    ServiceType.Anonymous,
    ServiceType.Base
  ]
}
