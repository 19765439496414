import { Injectable } from '@angular/core'
import { Observable, of } from 'rxjs'
import { catchError, filter, map, retryWhen } from 'rxjs/operators'

import { ApplyFilterInput, CandidateType } from '@alliance/shared/domain-gql'
import { getNonNullableItems, retryWhenStrategy } from '@alliance/shared/utils'
import { RxStateService } from '@alliance/shared/models'

import { GetAppliesCountGQL, CandidatesAggregationsItemFragment } from './applies-count.generated'
import { CANDIDATE_UNVIEWED_STATUS_ID } from '../../constants'

const DEFAULT_FILTER: ApplyFilterInput = {
  onlyForPublishedVacancies: true,
  candidateTypes: [
    CandidateType.Application,
    CandidateType.ApplicationWithFile,
    CandidateType.ApplicationWithResume,
    CandidateType.SelectedResume,
    CandidateType.VacancyOffered,
    CandidateType.VacancyInteraction
  ]
}

@Injectable({ providedIn: 'root' })
export class AppliesCountService extends RxStateService<{
  aggregations: CandidatesAggregationsItemFragment[] | null
  newCandidatesCount: number
}> {
  public constructor(private getAppliesCountGQL: GetAppliesCountGQL) {
    super()

    this.initState({
      aggregations: this.aggregations$,
      newCandidatesCount: this.newCandidatesCount$
    })
  }

  public getNewCandidatesCount$(): Observable<number> {
    return this.select('newCandidatesCount')
  }

  private get aggregations$(): Observable<CandidatesAggregationsItemFragment[] | null> {
    return this.getAppliesCountGQL.fetch({ filter: DEFAULT_FILTER }, { fetchPolicy: 'network-only' }).pipe(
      map(({ data }) => getNonNullableItems(data?.applies?.aggregations || [])),
      retryWhen(retryWhenStrategy()),
      catchError(() => of(null))
    )
  }

  private get newCandidatesCount$(): Observable<number> {
    return this.select('aggregations').pipe(
      filter(Boolean),
      map(aggregations => {
        const items = aggregations.map(aggregation => aggregation.items)

        const unViewedItem = items
          .find(item => item.some(statusItem => statusItem.item.__typename === 'ApplyStatus'))
          ?.find(item => item.item.__typename === 'ApplyStatus' && item.item.id === CANDIDATE_UNVIEWED_STATUS_ID)

        return unViewedItem?.count ?? 0
      })
    )
  }
}
