import { Injectable } from '@angular/core'
import { ApolloLink, NextLink } from '@apollo/client/core'
import { onError } from '@apollo/client/link/error'

@Injectable({
  providedIn: 'root'
})
export class GqlErrorHandlerMiddleware {
  private readonly allowedRetryOperations = ['getCurrentEmployerState', 'GetApplies', 'GetResume']

  public onGqlError(): ApolloLink {
    return onError(({ operation, graphQLErrors, forward }): NextLink | null => {
      if (graphQLErrors && this.allowedRetryOperations.includes(operation.operationName)) {
        return forward(operation) as NextLink
      }
      return null
    })
  }
}
