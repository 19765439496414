import { AccountLinkModel } from '@alliance/shared/feature-account-link-map'

export enum MobileHeaderNavigationButtonEnum {
  home = 'home',
  back = 'back',
  custom = 'custom'
}

export interface BaseNavigationButton {
  type: MobileHeaderNavigationButtonEnum
  url?: AccountLinkModel
}

export interface HomeNavigationButton extends BaseNavigationButton {
  type: MobileHeaderNavigationButtonEnum.home
  isFullLogo?: boolean
}

export interface BackNavigationButton extends BaseNavigationButton {
  type: MobileHeaderNavigationButtonEnum.back
  fallbackUrl?: AccountLinkModel
}

export interface CustomNavigationButton extends BaseNavigationButton {
  type: MobileHeaderNavigationButtonEnum.custom
  action: VoidFunction
}

export type MobileHeaderNavigationButton = HomeNavigationButton | BackNavigationButton | CustomNavigationButton
