import { ApplicationInitStatus, Injectable, NgZone } from '@angular/core'
import { AuthService } from '@alliance/shared/auth/api'
import { DetectPlatformService, safeJsonParse } from '@alliance/shared/utils'
import { BehaviorSubject, interval, Observable, takeWhile } from 'rxjs'
import { filter, map, skipWhile, switchMap, take } from 'rxjs/operators'
import { MessageTargetType } from '../models/StreamType'
import { SocketMessage } from '../models/socket-message.interface'
import { SocketService } from '../socket/socket.service'
import { WsMessageService } from '../ws-message/ws-message.service'
import { isSignalrCloseMessage, SIGNALR_INVOCATION_MESSAGE_TYPE, SignalrHandshakeRequest, SignalrPingMessage } from './signalr'

export enum ConnectionTypeEnum {
  closed = 'CLOSED',
  connected = 'CONNECTED',
  break = 'BREAK'
}

const RECORD_SEPARATOR = String.fromCharCode(30)
const HANDSHAKE_REQUEST: SignalrHandshakeRequest = { protocol: 'json', version: 1 }
const HANDSHAKE_REQUEST_TEXT = JSON.stringify(HANDSHAKE_REQUEST) + RECORD_SEPARATOR
const PING_MESSAGE: SignalrPingMessage = { type: 6 }
const PING_MESSAGE_TEXT = JSON.stringify(PING_MESSAGE) + RECORD_SEPARATOR
const DEFAULT_PING_INTERVAL = 20 * 1000
const isDevModeOnlyMessage = (message: SocketMessage): boolean => message.arguments && message.arguments[0] === 'error'

const prepareSignalrResponseText = (data: string): string => data.substring(0, data.indexOf(RECORD_SEPARATOR))

@Injectable({
  providedIn: 'root'
})
export class SignalrMessageService extends WsMessageService {
  public connection$ = new BehaviorSubject<ConnectionTypeEnum>(ConnectionTypeEnum.closed)
  private messageStream = new BehaviorSubject<SocketMessage | null>(null)
  private messageStream$ = this.messageStream.asObservable().pipe(skipWhile(m => !m))
  private hasToken = false
  private allowReconnect = false

  public constructor(
    private readonly authService: AuthService,
    private readonly ngZone: NgZone,
    private readonly socketService: SocketService,
    private readonly detectPlatformService: DetectPlatformService,
    private readonly appInitStatus: ApplicationInitStatus
  ) {
    super()
  }

  private get isConnected(): boolean {
    return this.connection$.value === ConnectionTypeEnum.connected
  }

  public connection(): void {
    if (this.detectPlatformService.isBrowser) {
      this.appInitStatus.donePromise.then(() => {
        this.authService.token$.subscribe(token => {
          this.hasToken = !!token
          if (token) {
            this.connect().pipe(take(1)).subscribe()
          } else {
            super.disconnect()
          }
        })
      })
    }
  }

  public override connect(): Observable<boolean> {
    if (this.socket) {
      super.disconnect()
    }
    return this.socketService.connect().pipe(switchMap(data => super.connect(data.url ?? '')))
  }

  public getStreamByType<T = unknown>(streamType: MessageTargetType[]): Observable<T> {
    return this.messageStream$.pipe(
      filter(m => m?.type === SIGNALR_INVOCATION_MESSAGE_TYPE),
      filter(m => (m?.target ? streamType.includes(m?.target) : false)),
      filter((msg): msg is SocketMessage<T> => !!msg),
      map(arg => arg.arguments[0])
    )
  }

  protected override OnError(): void {
    this.connection$.next(ConnectionTypeEnum.break)
  }

  protected override OnClose(data: CloseEvent): void {
    const { wasClean } = data

    if (wasClean || !this.hasToken) {
      // intentional close
      this.connection$.next(ConnectionTypeEnum.closed)
    }

    if (!wasClean && this.hasToken) {
      this.connection$.next(ConnectionTypeEnum.break)
    }

    if (this.allowReconnect) {
      this.reconnect()
    }
  }

  protected OnOpen(): void {
    this.handleOpen()
  }

  protected OnMessage({ data }: { data: string }): void {
    this.handleMessage(data)
  }

  private reconnect(): void {
    if (this.isConnected || !this.hasToken) {
      return
    }

    this.connect().pipe(take(1)).subscribe()
  }

  private handleOpen(): void {
    if (!this.socket) {
      return
    }

    const socket = this.socket
    this.allowReconnect = false
    socket.send(HANDSHAKE_REQUEST_TEXT)
    this.setupKeepAlive(socket)
    this.connection$.next(ConnectionTypeEnum.connected)
  }

  private handleMessage(data: string): void {
    const message = safeJsonParse<SocketMessage | null>(prepareSignalrResponseText(data), null)

    if (!message) {
      return
    }

    if (isSignalrCloseMessage(message) && message.allowReconnect) {
      this.connection$.next(ConnectionTypeEnum.closed)
      this.allowReconnect = message.allowReconnect
    }

    if (message.arguments && !isDevModeOnlyMessage(message)) {
      this.messageStream.next(message)
    }
  }

  private setupKeepAlive(socket: WebSocket, pingInterval: number = DEFAULT_PING_INTERVAL): void {
    this.ngZone.runOutsideAngular(() => {
      interval(pingInterval)
        .pipe(takeWhile(() => this.isConnected && socket.readyState === socket.OPEN && socket === this.socket))
        .subscribe({
          next: () => socket.send(PING_MESSAGE_TEXT)
        })
    })
  }
}
