import { isObjectWithProperty, safeJsonParse } from '@alliance/shared/utils'
import { Inject, Injectable } from '@angular/core'
import { map, tap } from 'rxjs/operators'
import { BehaviorSubject, Observable } from 'rxjs'
import { BreadcrumbList, ListItem } from 'schema-dts'
import { SeoDictionaryService } from './seo-dictionary.service'
import { SeoDomManipulatingService } from './seo-dom-manipulating.service'
import { PlatformHosts, SEO_PLATFORM_TOKEN, SeoDictionary, SeoParamsResponse } from './models'
import { SeoParamsResponseModel } from '../openapi/model/seo-params-response.model'
import { SeoParamsResponseJsonLdModel } from '../openapi/model/seo-params-response-json-ld.model'

const isBreadcrumbList = (value: unknown): value is BreadcrumbList => isObjectWithProperty<BreadcrumbList>(value, '@type') && value['@type'] === 'BreadcrumbList'
const isListItem = (value: unknown): value is ListItem => isObjectWithProperty<ListItem>(value, '@type') && value['@type'] === 'ListItem'

@Injectable({ providedIn: 'root' })
export class SeoService {
  public h1$ = new BehaviorSubject<string[]>([])
  public breadcrumbList$ = new BehaviorSubject<ListItem[]>([])
  public canonicalUrl$ = new BehaviorSubject<string>('')

  public constructor(private seoDictionaryService: SeoDictionaryService, private seoDomManipulatingService: SeoDomManipulatingService, @Inject(SEO_PLATFORM_TOKEN) private seoToken: PlatformHosts) {}

  public getSeoParams(params: SeoDictionary): Observable<SeoParamsResponseModel> {
    return this.seoDictionaryService.getSeoParams(params).pipe(
      tap(({ h1, canonicalUrl, jsonLd }) => {
        this.breadcrumbList$.next(this.getBreadcrumbsList(jsonLd, 'desktop'))
        this.h1$.next(h1)
        this.canonicalUrl$.next(canonicalUrl)
      })
    )
  }

  public setDefaultSeo(): void {
    this.h1$.next([])
    this.canonicalUrl$.next('')
    this.seoDomManipulatingService.setDefaultSeo()
  }

  public setSeoParamsInDOM(params: SeoParamsResponse): void {
    this.seoDomManipulatingService.setAllParamsInDOM(this.seoToken, params)
  }

  public setSeoParams(params: SeoDictionary): Observable<SeoParamsResponseModel> {
    return this.getSeoParams(params).pipe(
      map(seoParams => {
        this.setSeoParamsInDOM(seoParams)
        return seoParams
      })
    )
  }

  private getBreadcrumbsList(jsonLd: SeoParamsResponseJsonLdModel, platform: 'mobile' | 'desktop'): ListItem[] {
    const parsedJsonLd = platform ? safeJsonParse<unknown[]>(platform === 'desktop' ? jsonLd.desktop : jsonLd.mobile, []) : []

    if (!parsedJsonLd || !Array.isArray(parsedJsonLd)) {
      return []
    }

    const breadcrumbList = parsedJsonLd.find(isBreadcrumbList)?.itemListElement

    if (!breadcrumbList || !Array.isArray(breadcrumbList)) {
      return []
    }

    return breadcrumbList.filter(isListItem)
  }
}
