import { Injectable } from '@angular/core'
import { Observable, catchError, map, of } from 'rxjs'

import { getNonNullableItems } from '@alliance/shared/utils'

import { VacancyChangeOwnerGQL } from './change-vacancy-owner.generated'
import { ChangeVacancyOwnerParams } from '../../../models/vacancy-actions/change-vacancy-owner/change-vacancy-owner-params.interface'
import { ChangeVacancyOwnerResponse } from '../../../models/vacancy-actions/change-vacancy-owner/change-vacancy-owner-response.interface'

@Injectable()
export class ChangeVacancyOwnerService {
  public constructor(private readonly vacancyChangeOwnerGQL: VacancyChangeOwnerGQL) {}

  public changeVacancyOwner$(params: ChangeVacancyOwnerParams): Observable<ChangeVacancyOwnerResponse | null> {
    const { employer } = params
    return this.vacancyChangeOwnerGQL
      .mutate({
        input: {
          contactEmail: employer.email,
          contactPersonName: employer.name,
          contactPhone: employer.phone,
          employerId: employer.id,
          vacanciesIds: params.vacanciesIds,
          vacancyId: params.vacanciesIds[0] // is going to be removed after schema change
        }
      })
      .pipe(
        map(({ data }) => {
          const vacancies = getNonNullableItems(data?.vacancyChangeOwner?.vacancies ?? [])
          if (vacancies.length) {
            return { vacancies }
          }
          return null
        }),
        catchError(() => of(null))
      )
  }
}
