import { log } from '@alliance/shared/logger'
import { Inject, Injectable, PLATFORM_ID } from '@angular/core'
import { isPlatformBrowser } from '@angular/common'
import { LOCATION, NAVIGATOR } from '@ng-web-apis/common'
import { CookieDict, CookieOptions, CookieService } from 'ngx-cookie'
import { CookieStorage } from '../interfaces/cookie-storage'
import { REMOVABLE_PREFIX } from '../utils/constants'

@Injectable()
export class CookieStorageImplementation implements CookieStorage {
  public constructor(
    @Inject(NAVIGATOR) private navigator: Navigator,
    @Inject(LOCATION) private readonly location: Location,
    @Inject(PLATFORM_ID) private platformId: object,
    private cookieService: CookieService
  ) {
    if (isPlatformBrowser(this.platformId) && !this.isAvailable) {
      log.warn({ where: 'shared-storage', category: 'unexpected_value', message: 'COOKIE_STORAGE UNAVAILABLE' })
    }
  }

  public get isAvailable(): boolean {
    try {
      return this.navigator.cookieEnabled
    } catch (e) {
      return false
    }
  }

  public get length(): number {
    return Object.keys(this.cookieService.getAll()).length
  }

  public clear(options: Partial<CookieOptions> = this.getDefaultCookieConfig()): void {
    this.cookieService.removeAll(options)
  }

  public getItem(key: string): string | null {
    const item = this.cookieService.get(key)

    return item != null ? item : null
  }

  public key(index: number): string | null {
    const keys = Object.keys(this.cookieService.getAll())

    return index >= 0 && index < keys.length ? keys[index] : null
  }

  public removeItem(key: string, options: Partial<CookieOptions> = this.getDefaultCookieConfig()): void {
    this.cookieService.remove(key, { ...this.getDefaultCookieConfig(), ...options })
  }

  public setItem(key: string, data: string, options: Partial<CookieOptions> = this.getDefaultCookieConfig(), removableAfterLogout?: boolean): void {
    if (removableAfterLogout && !key.startsWith(REMOVABLE_PREFIX)) {
      throw new Error(`name for removable key should start with prefix '${REMOVABLE_PREFIX}'`)
    }
    this.cookieService.put(key, data, { ...this.getDefaultCookieConfig(), ...options })
  }

  public getAll(): CookieDict {
    return this.cookieService.getAll()
  }

  public clearAllRemovableKeysAfterLogout(): void {
    Object.keys(this.getAll()).forEach(key => (key.startsWith(REMOVABLE_PREFIX) ? this.removeItem(key) : null))
  }

  private getDefaultCookieConfig(): Partial<CookieOptions> {
    return {
      path: '/',
      domain: this.location.host.includes('robota') ? '.robota.ua' : '.rabota.ua'
    }
  }
}
