import { Injectable } from '@angular/core'
import { Observable, catchError, map, of } from 'rxjs'

import { VacancyPublicationType, VacancyContacts, VacancyStatus, VacancyClosingType, AllowedVacancyActions } from '@alliance/shared/domain-gql'

import {
  GetApproveVacancyDataGQL,
  GetChangeVacancyOwnerDataGQL,
  GetCompleteVacancyDataGQL,
  GetMakeHotDataGQL,
  GetProlongVacancyDataGQL,
  GetPublishVacancyDataGQL,
  GetRejectVacancyDataGQL,
  VacancyItemCurrentPublicationServiceFragment,
  GetRiseUpVacancyDataGQL,
  CurrentPublicationServiceFragment
} from './vacancy-action-data.generated'

@Injectable()
export class VacancyActionDataService {
  public constructor(
    private readonly getCompleteVacancyDataGQL: GetCompleteVacancyDataGQL,
    private readonly getRejectVacancyDataGQL: GetRejectVacancyDataGQL,
    private readonly getApproveVacancyDataGQL: GetApproveVacancyDataGQL,
    private readonly getChangeVacancyOwnerDataGQL: GetChangeVacancyOwnerDataGQL,
    private readonly getMakeHotDataGQL: GetMakeHotDataGQL,
    private readonly getProlongVacancyDataGQL: GetProlongVacancyDataGQL,
    private readonly getPublishVacancyDataGQL: GetPublishVacancyDataGQL,
    private readonly getRiseUpVacancyDataGQL: GetRiseUpVacancyDataGQL
  ) {}

  public getCompleteVacancyData$(vacancyId: string): Observable<{ id: string; title: string; status: VacancyStatus; ownerId: string } | null> {
    return this.getCompleteVacancyDataGQL.fetch({ id: vacancyId }, { fetchPolicy: 'network-only' }).pipe(
      map(({ data }) => {
        const vacancy = data?.vacancyById?.vacancy
        if (!vacancy) {
          return null
        }
        const { id, title, status, owner } = vacancy
        return { id, title, status, ownerId: owner.id }
      }),
      catchError(() => of(null))
    )
  }

  public getRejectVacancyData$(vacancyId: string): Observable<{ id: string; title: string; status: VacancyStatus; ownerId: string } | null> {
    return this.getRejectVacancyDataGQL.fetch({ id: vacancyId }, { fetchPolicy: 'network-only' }).pipe(
      map(({ data }) => {
        const vacancy = data?.vacancyById?.vacancy
        if (!vacancy) {
          return null
        }
        const { id, title, status, owner } = vacancy
        return { id, title, status, ownerId: owner.id }
      }),
      catchError(() => of(null))
    )
  }

  public getApproveVacancyData$(vacancyId: string): Observable<{ id: string; title: string; status: VacancyStatus; ownerId: string; publicationType: VacancyPublicationType } | null> {
    return this.getApproveVacancyDataGQL.fetch({ id: vacancyId }, { fetchPolicy: 'network-only' }).pipe(
      map(({ data }) => {
        const vacancy = data?.vacancyById?.vacancy
        if (!vacancy) {
          return null
        }
        const { id, title, status, owner, publicationType } = vacancy
        return { id, title, status, ownerId: owner.id, publicationType }
      }),
      catchError(() => of(null))
    )
  }

  public getChangeVacancyOwnerData$(
    vacancyId: string
  ): Observable<{ id: string; title: string; cityName: string; ownerId: string; contacts: Pick<VacancyContacts, 'phones' | 'name' | 'photo'> } | null> {
    return this.getChangeVacancyOwnerDataGQL.fetch({ id: vacancyId }, { fetchPolicy: 'network-only' }).pipe(
      map(({ data }) => {
        const vacancy = data?.vacancyById?.vacancy
        if (!vacancy) {
          return null
        }
        const { id, title, owner, city, contacts } = vacancy
        return { id, title, cityName: city.name, ownerId: owner.id, contacts }
      }),
      catchError(() => of(null))
    )
  }

  public getMakeHotData$(vacancyId: string): Observable<{
    id: string
    title: string
    status: VacancyStatus
    ownerId: string
    hotPeriod: { begin: string | null; end: string | null }
    publishPeriod: { end: string | null }
  } | null> {
    return this.getMakeHotDataGQL.fetch({ id: vacancyId }, { fetchPolicy: 'network-only' }).pipe(
      map(({ data }) => {
        const vacancy = data?.vacancyById?.vacancy

        if (!vacancy) {
          return null
        }

        const { id, title, status, owner, hotPeriod, publishPeriod } = vacancy

        return {
          id,
          title,
          status,
          ownerId: owner.id,
          hotPeriod: { begin: hotPeriod?.begin ?? null, end: hotPeriod?.end ?? null },
          publishPeriod: { end: publishPeriod?.end ?? null }
        }
      }),
      catchError(() => of(null))
    )
  }

  public getProlongVacancyData$(vacancyId: string): Observable<{
    id: string
    title: string
    status: VacancyStatus
    ownerId: string
    closingType: VacancyClosingType
    publicationType: VacancyPublicationType
    allowedVacancyActions: AllowedVacancyActions[]
    hasMyUnreviewedProlongationRequest: boolean
  } | null> {
    return this.getProlongVacancyDataGQL.fetch({ id: vacancyId }, { fetchPolicy: 'network-only' }).pipe(
      map(({ data }) => {
        const vacancy = data?.vacancyById?.vacancy

        if (!vacancy) {
          return null
        }
        const { id, title, status, owner, closingType, publicationType, allowedVacancyActions, hasMyUnreviewedProlongationRequest } = vacancy

        return {
          id,
          title,
          status,
          closingType,
          publicationType,
          hasMyUnreviewedProlongationRequest,
          ownerId: owner.id,
          allowedVacancyActions: allowedVacancyActions.filter((item): item is AllowedVacancyActions => !!item)
        }
      }),
      catchError(() => of(null))
    )
  }

  public getPublishVacancyData$(vacancyId: string): Observable<{
    id: string
    title: string
    status: VacancyStatus
    ownerId: string
    cityId: string
    publicationType: VacancyPublicationType
    currentPublicationService: VacancyItemCurrentPublicationServiceFragment | null
  } | null> {
    return this.getPublishVacancyDataGQL.fetch({ id: vacancyId }, { fetchPolicy: 'network-only' }).pipe(
      map(({ data }) => {
        const vacancy = data?.vacancyById?.vacancy

        if (!vacancy) {
          return null
        }

        const { id, title, status, owner, city, publicationType, currentPublicationService } = vacancy

        return {
          id,
          title,
          status,
          ownerId: owner.id,
          cityId: city.id,
          publicationType,
          currentPublicationService
        }
      }),
      catchError(() => of(null))
    )
  }

  public getRiseUpVacancyData$(vacancyId: string): Observable<{
    id: string
    title: string
    status: VacancyStatus
    ownerId: string
    sortDate: string
    currentPublicationService: CurrentPublicationServiceFragment | null
  } | null> {
    return this.getRiseUpVacancyDataGQL.fetch({ id: vacancyId }, { fetchPolicy: 'network-only' }).pipe(
      map(({ data }) => {
        const vacancy = data?.vacancyById?.vacancy

        if (!vacancy) {
          return null
        }

        const { id, title, status, owner, sortDate, currentPublicationService } = vacancy

        return {
          id,
          title,
          status,
          ownerId: owner.id,
          sortDate,
          currentPublicationService
        }
      }),
      catchError(() => of(null))
    )
  }
}
