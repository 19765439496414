import { Injectable } from '@angular/core'
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http'
import { BaseService as __BaseService } from '../base-service'
import { EmployerDataAccessConfiguration as __Configuration } from '../employer-data-access-configuration'
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response'
import { Observable as __Observable } from 'rxjs'
import { map as __map, filter as __filter } from 'rxjs/operators'

import { UserExistsModel } from '../models/user-exists-model'
import { SiteUser } from '../models/site-user'
import { RobotaUaVariables } from '../models/robota-ua-variables'
@Injectable({
  providedIn: 'root'
})
class AccountService extends __BaseService {
  public static readonly accountExistPath = '/account/existsbyemail'
  public static readonly getUserInfoPath = '/account/userinfo'
  public static readonly clearCurrentUserCachePath = '/account/userinfo/cache'
  public static readonly deleteExistPath = '/account'
  public static readonly getRuaVarsPath = '/account/userstate'

  public constructor(config: __Configuration, http: HttpClient) {
    super(config, http)
  }

  /**
   * @param email undefined
   * @return Success
   */
  accountExistResponse(email?: string): __Observable<__StrictHttpResponse<UserExistsModel>> {
    let __params = this.newParams()
    let __headers = new HttpHeaders()
    let __body: any = null
    if (email != null) __params = __params.set('email', email.toString())
    let req = new HttpRequest<any>('GET', this.rootUrl + `/account/existsbyemail`, __body, {
      headers: __headers,
      params: __params,
      responseType: 'json'
    })

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map(_r => {
        return _r as __StrictHttpResponse<UserExistsModel>
      })
    )
  }

  /**
   * @param email undefined
   * @return Success
   */
  accountExist(email?: string): __Observable<UserExistsModel> {
    return this.accountExistResponse(email).pipe(__map(_r => _r.body as UserExistsModel))
  }

  /**
   * @param params The `AccountService.GetUserInfoParams` containing the following parameters:
   *
   * - `includeCompanyInfo`:
   *
   * - `email`:
   *
   * @return Success
   */
  getUserInfoResponse(params: AccountService.GetUserInfoParams): __Observable<__StrictHttpResponse<SiteUser>> {
    let __params = this.newParams()
    let __headers = new HttpHeaders()
    let __body: any = null
    if (params.includeCompanyInfo != null) __params = __params.set('includeCompanyInfo', params.includeCompanyInfo.toString())
    if (params.email != null) __params = __params.set('email', params.email.toString())
    let req = new HttpRequest<any>('GET', this.rootUrl + `/account/userinfo`, __body, {
      headers: __headers,
      params: __params,
      responseType: 'json'
    })

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map(_r => {
        return _r as __StrictHttpResponse<SiteUser>
      })
    )
  }

  /**
   * @param params The `AccountService.GetUserInfoParams` containing the following parameters:
   *
   * - `includeCompanyInfo`:
   *
   * - `email`:
   *
   * @return Success
   */
  getUserInfo(params: AccountService.GetUserInfoParams): __Observable<SiteUser> {
    return this.getUserInfoResponse(params).pipe(__map(_r => _r.body as SiteUser))
  }
  clearCurrentUserCacheResponse(): __Observable<__StrictHttpResponse<any>> {
    let __params = this.newParams()
    let __headers = new HttpHeaders()
    let __body: any = null
    let req = new HttpRequest<any>('DELETE', this.rootUrl + `/account/userinfo/cache`, __body, {
      headers: __headers,
      params: __params,
      responseType: 'json'
    })

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map(_r => {
        return _r as __StrictHttpResponse<any>
      })
    )
  }
  clearCurrentUserCache(): __Observable<any> {
    return this.clearCurrentUserCacheResponse().pipe(__map(_r => _r.body as any))
  }

  /**
   * @param email undefined
   */
  deleteExistResponse(email?: string): __Observable<__StrictHttpResponse<any>> {
    let __params = this.newParams()
    let __headers = new HttpHeaders()
    let __body: any = null
    if (email != null) __params = __params.set('email', email.toString())
    let req = new HttpRequest<any>('DELETE', this.rootUrl + `/account`, __body, {
      headers: __headers,
      params: __params,
      responseType: 'json'
    })

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map(_r => {
        return _r as __StrictHttpResponse<any>
      })
    )
  }

  /**
   * @param email undefined
   */
  deleteExist(email?: string): __Observable<any> {
    return this.deleteExistResponse(email).pipe(__map(_r => _r.body as any))
  }

  /**
   * @return Success
   */
  getRuaVarsResponse(): __Observable<__StrictHttpResponse<RobotaUaVariables>> {
    let __params = this.newParams()
    let __headers = new HttpHeaders()
    let __body: any = null
    let req = new HttpRequest<any>('GET', this.rootUrl + `/account/userstate`, __body, {
      headers: __headers,
      params: __params,
      responseType: 'json'
    })

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map(_r => {
        return _r as __StrictHttpResponse<RobotaUaVariables>
      })
    )
  }

  /**
   * @return Success
   */
  getRuaVars(): __Observable<RobotaUaVariables> {
    return this.getRuaVarsResponse().pipe(__map(_r => _r.body as RobotaUaVariables))
  }
}

module AccountService {
  /**
   * Parameters for GetUserInfo
   */
  export interface GetUserInfoParams {
    includeCompanyInfo?: boolean
    email?: string
  }
}

export { AccountService }
