import { Observable, of, throwError, timer } from 'rxjs'
import { delayWhen, switchMap } from 'rxjs/operators'

const RETRY_DELAY_TIME = 1000
const RETRY_ATTEMPTS = 3

export const retryWhenStrategy = (retryAttempts = RETRY_ATTEMPTS, retryDelay = RETRY_DELAY_TIME) => (errors: Observable<Error>): Observable<Error> =>
  errors.pipe(
    delayWhen((_, index) => timer((index + 1) * retryDelay)),
    switchMap((error, index) => (index === retryAttempts ? throwError(error) : of(error)))
  )
