import { log } from '@alliance/shared/logger'
import { Inject, Injectable } from '@angular/core'
import { WINDOW } from '@ng-web-apis/common'
import { DetectPlatformService } from './detect-platform.service'

export enum RuavarsPlatform {
  desktop = 'desktop',
  mobile = 'mobile'
}

export interface CustomWindow extends Window {
  alliance_ruavars: NotebookCompanyInfo
  ruavars: {
    [key: string]: unknown
  }
}
export interface NotebookCompanyInfo {
  notebookId: number
  isEmployer: boolean
  isJobsearcher: boolean
  cvdbAccess: number
  isConfirmed: boolean
  isEnableActivateService: boolean
  multiUserNeedApproval: boolean
  isPaid: boolean
  isEverPublishVacancy: boolean
  addDate: Date
  tryCvDb: number
  isExistTickets: boolean
  userEMail: string
  hasFreeBusinessService: boolean
  canHaveFreeBusinessService: boolean
  multiUserRoleId: string
  multiUserId: number
  emailId: number
  isLogged: boolean
  uid: null | string
  platform: RuavarsPlatform
  vacancyId: null | string
  transactionId: null | string
  city: null | string
  isUkrainian: boolean
  isFirstApply: boolean
  hasResume: boolean
}

@Injectable({ providedIn: 'root' })
export class RuavarsService {
  private defaultRuavars: NotebookCompanyInfo = {
    notebookId: 0,
    isEmployer: false,
    isJobsearcher: false,
    cvdbAccess: 0,
    isConfirmed: false,
    isEnableActivateService: false,
    multiUserNeedApproval: false,
    isPaid: false,
    isEverPublishVacancy: false,
    addDate: new Date(0, 0, 0),
    tryCvDb: 0,
    isExistTickets: false,
    userEMail: '',
    hasFreeBusinessService: false,
    canHaveFreeBusinessService: false,
    multiUserRoleId: '',
    multiUserId: 0,
    emailId: 0,
    isLogged: false,
    uid: null,
    platform: RuavarsPlatform.desktop,
    vacancyId: null,
    city: null,
    transactionId: null,
    isUkrainian: false,
    isFirstApply: false,
    hasResume: false
  }

  public constructor(@Inject(WINDOW) private readonly windowRef: CustomWindow, private platform: DetectPlatformService) {}

  public setInfo(mainInfo: Partial<NotebookCompanyInfo>): void {
    if (!mainInfo) {
      return
    }
    Object.entries(mainInfo).forEach(([key, value]) => {
      if (typeof value !== 'undefined') {
        this.defaultRuavars = {
          ...this.defaultRuavars,
          [key]: value
        }
      }
    })
    this.updateRuavarsInfo()
  }

  public updateOldRuaVars<T>(key: string, value: T): void {
    if (this.platform.isBrowser && this.windowRef['ruavars']) {
      try {
        this.windowRef['ruavars'][key] = value
      } catch (e) {
        log.warn({ where: 'shared-utils: RuavarsService', category: 'try_catch', message: 'updateOldRuaVars failed', error: e })
      }
    }
  }

  private updateRuavarsInfo(): void {
    if (this.platform.isBrowser) {
      this.windowRef['alliance_ruavars'] = this.defaultRuavars
    }
  }
}
