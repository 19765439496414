import { Injectable } from '@angular/core'
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http'
import { BaseService as __BaseService } from '../base-service'
import { EmployerDataAccessConfiguration as __Configuration } from '../employer-data-access-configuration'
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response'
import { Observable as __Observable } from 'rxjs'
import { map as __map, filter as __filter } from 'rxjs/operators'

import { CvdbService } from '../models/cvdb-service'
import { TicketService4Detail } from '../models/ticket-service-4detail'
import { TicketServiceItem } from '../models/ticket-service-item'
import { PriceInfo } from '../models/price-info'
import { ServiceInfo } from '../models/service-info'
import { CvDbPriceList } from '../models/cv-db-price-list'
import { AddServicePrice } from '../models/add-service-price'
@Injectable({
  providedIn: 'root'
})
class ServiceService extends __BaseService {
  public static readonly canActivatePath = '/api/service/canactivate'
  public static readonly serviceListPath = '/api/service/list/{page}'
  public static readonly serviceList_1Path = '/api/service/list/{page}'
  public static readonly serviceCvdbListPath = '/api/service/cvdb'
  public static readonly activatePath = '/api/service/activate/{orderId}/{detailId}'
  public static readonly activate_1Path = '/api/service/activate/{orderId}/{detailId}'
  public static readonly ticketListPath = '/api/service/tickets/{type}'
  public static readonly ticketList_1Path = '/api/service/tickets/{type}'
  public static readonly resumeCheckPath = '/api/service/resumecheck'
  public static readonly resumeCheck_1Path = '/api/service/resumecheck'
  public static readonly priceListPath = '/api/service/pricelist'
  public static readonly fullServiceListPath = '/api/service/fulllist'
  public static readonly getPricePath = '/api/service/getprice'
  public static readonly getCvDbPricePath = '/api/service/cvdbpricelist'
  public static readonly getAdditionalPricePath = '/api/service/additionalpricelist'
  public static readonly getSurveyTicketsPath = '/api/service/surveytickets'
  public static readonly awareAboutFreeBusinessServicePath = '/api/service/free-business/awareness'

  public constructor(config: __Configuration, http: HttpClient) {
    super(config, http)
  }

  /**
   * @return Success
   */
  canActivateResponse(): __Observable<__StrictHttpResponse<boolean>> {
    let __params = this.newParams()
    let __headers = new HttpHeaders()
    let __body: any = null
    let req = new HttpRequest<any>('GET', this.rootUrl + `/api/service/canactivate`, __body, {
      headers: __headers,
      params: __params,
      responseType: 'json'
    })

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map(_r => {
        return (_r as HttpResponse<any>).clone({
          body: (_r as HttpResponse<any>).body === true
        }) as __StrictHttpResponse<boolean>
      })
    )
  }

  /**
   * @return Success
   */
  public canActivate(): __Observable<boolean> {
    return this.canActivateResponse().pipe(__map(_r => _r.body as boolean))
  }

  /**
   * @param page undefined
   */
  serviceListResponse(page: number): __Observable<__StrictHttpResponse<any>> {
    let __params = this.newParams()
    let __headers = new HttpHeaders()
    let __body: any = null

    let req = new HttpRequest<any>('GET', this.rootUrl + `/api/service/list/${page}`, __body, {
      headers: __headers,
      params: __params,
      responseType: 'json'
    })

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map(_r => {
        return _r as __StrictHttpResponse<any>
      })
    )
  }

  /**
   * @param page undefined
   */
  serviceList(page: number): __Observable<any> {
    return this.serviceListResponse(page).pipe(__map(_r => _r.body as any))
  }

  /**
   * @param page undefined
   */
  serviceList_1Response(page: number): __Observable<__StrictHttpResponse<any>> {
    let __params = this.newParams()
    let __headers = new HttpHeaders()
    let __body: any = null

    let req = new HttpRequest<any>('POST', this.rootUrl + `/api/service/list/${page}`, __body, {
      headers: __headers,
      params: __params,
      responseType: 'json'
    })

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map(_r => {
        return _r as __StrictHttpResponse<any>
      })
    )
  }

  /**
   * @param page undefined
   */
  serviceList_1(page: number): __Observable<any> {
    return this.serviceList_1Response(page).pipe(__map(_r => _r.body as any))
  }

  /**
   * @return Success
   */
  serviceCvdbListResponse(): __Observable<__StrictHttpResponse<Array<CvdbService>>> {
    let __params = this.newParams()
    let __headers = new HttpHeaders()
    let __body: any = null
    let req = new HttpRequest<any>('GET', this.rootUrl + `/api/service/cvdb`, __body, {
      headers: __headers,
      params: __params,
      responseType: 'json'
    })

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map(_r => {
        return _r as __StrictHttpResponse<Array<CvdbService>>
      })
    )
  }

  /**
   * @return Success
   */
  serviceCvdbList(): __Observable<Array<CvdbService>> {
    return this.serviceCvdbListResponse().pipe(__map(_r => _r.body as Array<CvdbService>))
  }

  /**
   * @param params The `ServiceService.ActivateParams` containing the following parameters:
   *
   * - `orderId`:
   *
   * - `detailId`:
   */
  activateResponse(params: ServiceService.ActivateParams): __Observable<__StrictHttpResponse<any>> {
    let __params = this.newParams()
    let __headers = new HttpHeaders()
    let __body: any = null

    let req = new HttpRequest<any>('GET', this.rootUrl + `/api/service/activate/${params.orderId}/${params.detailId}`, __body, {
      headers: __headers,
      params: __params,
      responseType: 'json'
    })

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map(_r => {
        return _r as __StrictHttpResponse<any>
      })
    )
  }

  /**
   * @param params The `ServiceService.ActivateParams` containing the following parameters:
   *
   * - `orderId`:
   *
   * - `detailId`:
   */
  activate(params: ServiceService.ActivateParams): __Observable<any> {
    return this.activateResponse(params).pipe(__map(_r => _r.body as any))
  }

  /**
   * @param params The `ServiceService.Activate_1Params` containing the following parameters:
   *
   * - `orderId`:
   *
   * - `detailId`:
   */
  activate_1Response(params: ServiceService.Activate_1Params): __Observable<__StrictHttpResponse<any>> {
    let __params = this.newParams()
    let __headers = new HttpHeaders()
    let __body: any = null

    let req = new HttpRequest<any>('POST', this.rootUrl + `/api/service/activate/${params.orderId}/${params.detailId}`, __body, {
      headers: __headers,
      params: __params,
      responseType: 'json'
    })

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map(_r => {
        return _r as __StrictHttpResponse<any>
      })
    )
  }

  /**
   * @param params The `ServiceService.Activate_1Params` containing the following parameters:
   *
   * - `orderId`:
   *
   * - `detailId`:
   */
  activate_1(params: ServiceService.Activate_1Params): __Observable<any> {
    return this.activate_1Response(params).pipe(__map(_r => _r.body as any))
  }

  /**
   * @param type undefined
   * @return Success
   */
  ticketListResponse(type: 'All' | 'Business' | 'Optimum' | 'Professional' | 'Anonym' | 'Hot' | 'Test'): __Observable<__StrictHttpResponse<Array<TicketService4Detail>>> {
    let __params = this.newParams()
    let __headers = new HttpHeaders()
    let __body: any = null

    let req = new HttpRequest<any>('GET', this.rootUrl + `/api/service/tickets/${type}`, __body, {
      headers: __headers,
      params: __params,
      responseType: 'json'
    })

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map(_r => {
        return _r as __StrictHttpResponse<Array<TicketService4Detail>>
      })
    )
  }

  /**
   * @param type undefined
   * @return Success
   */
  ticketList(type: 'All' | 'Business' | 'Optimum' | 'Professional' | 'Anonym' | 'Hot' | 'Test'): __Observable<Array<TicketService4Detail>> {
    return this.ticketListResponse(type).pipe(__map(_r => _r.body as Array<TicketService4Detail>))
  }

  /**
   * @param type undefined
   * @return Success
   */
  ticketList_1Response(type: 'All' | 'Business' | 'Optimum' | 'Professional' | 'Anonym' | 'Hot' | 'Test'): __Observable<__StrictHttpResponse<Array<TicketService4Detail>>> {
    let __params = this.newParams()
    let __headers = new HttpHeaders()
    let __body: any = null

    let req = new HttpRequest<any>('POST', this.rootUrl + `/api/service/tickets/${type}`, __body, {
      headers: __headers,
      params: __params,
      responseType: 'json'
    })

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map(_r => {
        return _r as __StrictHttpResponse<Array<TicketService4Detail>>
      })
    )
  }

  /**
   * @param type undefined
   * @return Success
   */
  ticketList_1(type: 'All' | 'Business' | 'Optimum' | 'Professional' | 'Anonym' | 'Hot' | 'Test'): __Observable<Array<TicketService4Detail>> {
    return this.ticketList_1Response(type).pipe(__map(_r => _r.body as Array<TicketService4Detail>))
  }

  /**
   * @param params The `ServiceService.ResumeCheckParams` containing the following parameters:
   *
   * - `rubricIds`:
   *
   * - `cityId`:
   *
   * @return Success
   */
  resumeCheckResponse(params: ServiceService.ResumeCheckParams): __Observable<__StrictHttpResponse<Array<TicketServiceItem>>> {
    let __params = this.newParams()
    let __headers = new HttpHeaders()
    let __body: any = null
    if (params.rubricIds != null) __params = __params.set('rubricIds', params.rubricIds.toString())
    if (params.cityId != null) __params = __params.set('cityId', params.cityId.toString())
    let req = new HttpRequest<any>('GET', this.rootUrl + `/api/service/resumecheck`, __body, {
      headers: __headers,
      params: __params,
      responseType: 'json'
    })

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map(_r => {
        return _r as __StrictHttpResponse<Array<TicketServiceItem>>
      })
    )
  }

  /**
   * @param params The `ServiceService.ResumeCheckParams` containing the following parameters:
   *
   * - `rubricIds`:
   *
   * - `cityId`:
   *
   * @return Success
   */
  resumeCheck(params: ServiceService.ResumeCheckParams): __Observable<Array<TicketServiceItem>> {
    return this.resumeCheckResponse(params).pipe(__map(_r => _r.body as Array<TicketServiceItem>))
  }

  /**
   * @param params The `ServiceService.ResumeCheck_1Params` containing the following parameters:
   *
   * - `rubricIds`:
   *
   * - `cityId`:
   *
   * @return Success
   */
  resumeCheck_1Response(params: ServiceService.ResumeCheck_1Params): __Observable<__StrictHttpResponse<Array<TicketServiceItem>>> {
    let __params = this.newParams()
    let __headers = new HttpHeaders()
    let __body: any = null
    if (params.rubricIds != null) __params = __params.set('rubricIds', params.rubricIds.toString())
    if (params.cityId != null) __params = __params.set('cityId', params.cityId.toString())
    let req = new HttpRequest<any>('POST', this.rootUrl + `/api/service/resumecheck`, __body, {
      headers: __headers,
      params: __params,
      responseType: 'json'
    })

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map(_r => {
        return _r as __StrictHttpResponse<Array<TicketServiceItem>>
      })
    )
  }

  /**
   * @param params The `ServiceService.ResumeCheck_1Params` containing the following parameters:
   *
   * - `rubricIds`:
   *
   * - `cityId`:
   *
   * @return Success
   */
  resumeCheck_1(params: ServiceService.ResumeCheck_1Params): __Observable<Array<TicketServiceItem>> {
    return this.resumeCheck_1Response(params).pipe(__map(_r => _r.body as Array<TicketServiceItem>))
  }

  /**
   * @return Success
   */
  priceListResponse(): __Observable<__StrictHttpResponse<Array<PriceInfo>>> {
    let __params = this.newParams()
    let __headers = new HttpHeaders()
    let __body: any = null
    let req = new HttpRequest<any>('GET', this.rootUrl + `/api/service/pricelist`, __body, {
      headers: __headers,
      params: __params,
      responseType: 'json'
    })

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map(_r => {
        return _r as __StrictHttpResponse<Array<PriceInfo>>
      })
    )
  }

  /**
   * @return Success
   */
  priceList(): __Observable<Array<PriceInfo>> {
    return this.priceListResponse().pipe(__map(_r => _r.body as Array<PriceInfo>))
  }

  /**
   * @return Success
   */
  fullServiceListResponse(): __Observable<__StrictHttpResponse<Array<ServiceInfo>>> {
    let __params = this.newParams()
    let __headers = new HttpHeaders()
    let __body: any = null
    let req = new HttpRequest<any>('GET', this.rootUrl + `/api/service/fulllist`, __body, {
      headers: __headers,
      params: __params,
      responseType: 'json'
    })

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map(_r => {
        return _r as __StrictHttpResponse<Array<ServiceInfo>>
      })
    )
  }

  /**
   * @return Success
   */
  fullServiceList(): __Observable<Array<ServiceInfo>> {
    return this.fullServiceListResponse().pipe(__map(_r => _r.body as Array<ServiceInfo>))
  }

  /**
   * @param params The `ServiceService.GetPriceParams` containing the following parameters:
   *
   * - `Unit`:
   *
   * - `ServiceId`:
   *
   * - `RubricIds`:
   *
   * - `Count`:
   *
   * - `CityIds`:
   */
  getPriceResponse(params: ServiceService.GetPriceParams): __Observable<__StrictHttpResponse<any>> {
    let __params = this.newParams()
    let __headers = new HttpHeaders()
    let __body: any = null
    if (params.Unit != null) __params = __params.set('Unit', params.Unit.toString())
    if (params.ServiceId != null) __params = __params.set('ServiceId', params.ServiceId.toString())
    ;(params.RubricIds || []).forEach(val => {
      if (val != null) __params = __params.append('RubricIds', val.toString())
    })
    if (params.Count != null) __params = __params.set('Count', params.Count.toString())
    ;(params.CityIds || []).forEach(val => {
      if (val != null) __params = __params.append('CityIds', val.toString())
    })
    let req = new HttpRequest<any>('GET', this.rootUrl + `/api/service/getprice`, __body, {
      headers: __headers,
      params: __params,
      responseType: 'json'
    })

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map(_r => {
        return _r as __StrictHttpResponse<any>
      })
    )
  }

  /**
   * @param params The `ServiceService.GetPriceParams` containing the following parameters:
   *
   * - `Unit`:
   *
   * - `ServiceId`:
   *
   * - `RubricIds`:
   *
   * - `Count`:
   *
   * - `CityIds`:
   */
  getPrice(params: ServiceService.GetPriceParams): __Observable<any> {
    return this.getPriceResponse(params).pipe(__map(_r => _r.body as any))
  }

  /**
   * @return Success
   */
  getCvDbPriceResponse(): __Observable<__StrictHttpResponse<CvDbPriceList>> {
    let __params = this.newParams()
    let __headers = new HttpHeaders()
    let __body: any = null
    let req = new HttpRequest<any>('GET', this.rootUrl + `/api/service/cvdbpricelist`, __body, {
      headers: __headers,
      params: __params,
      responseType: 'json'
    })

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map(_r => {
        return _r as __StrictHttpResponse<CvDbPriceList>
      })
    )
  }

  /**
   * @return Success
   */
  getCvDbPrice(): __Observable<CvDbPriceList> {
    return this.getCvDbPriceResponse().pipe(__map(_r => _r.body as CvDbPriceList))
  }

  /**
   * @return Success
   */
  getAdditionalPriceResponse(): __Observable<__StrictHttpResponse<Array<AddServicePrice>>> {
    let __params = this.newParams()
    let __headers = new HttpHeaders()
    let __body: any = null
    let req = new HttpRequest<any>('GET', this.rootUrl + `/api/service/additionalpricelist`, __body, {
      headers: __headers,
      params: __params,
      responseType: 'json'
    })

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map(_r => {
        return _r as __StrictHttpResponse<Array<AddServicePrice>>
      })
    )
  }

  /**
   * @return Success
   */
  getAdditionalPrice(): __Observable<Array<AddServicePrice>> {
    return this.getAdditionalPriceResponse().pipe(__map(_r => _r.body as Array<AddServicePrice>))
  }

  /**
   * @return Success
   */
  getSurveyTicketsResponse(): __Observable<__StrictHttpResponse<number>> {
    let __params = this.newParams()
    let __headers = new HttpHeaders()
    let __body: any = null
    let req = new HttpRequest<any>('GET', this.rootUrl + `/api/service/surveytickets`, __body, {
      headers: __headers,
      params: __params,
      responseType: 'json'
    })

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map(_r => {
        return (_r as HttpResponse<any>).clone({
          body: parseFloat((_r as HttpResponse<any>).body as string)
        }) as __StrictHttpResponse<number>
      })
    )
  }

  /**
   * @return Success
   */
  getSurveyTickets(): __Observable<number> {
    return this.getSurveyTicketsResponse().pipe(__map(_r => _r.body as number))
  }
  awareAboutFreeBusinessServiceResponse(): __Observable<__StrictHttpResponse<any>> {
    let __params = this.newParams()
    let __headers = new HttpHeaders()
    let __body: any = null
    let req = new HttpRequest<any>('PATCH', this.rootUrl + `/api/service/free-business/awareness`, __body, {
      headers: __headers,
      params: __params,
      responseType: 'json'
    })

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map(_r => {
        return _r as __StrictHttpResponse<any>
      })
    )
  }
  awareAboutFreeBusinessService(): __Observable<any> {
    return this.awareAboutFreeBusinessServiceResponse().pipe(__map(_r => _r.body as any))
  }
}

module ServiceService {
  /**
   * Parameters for Activate
   */
  export interface ActivateParams {
    orderId: number
    detailId: number
  }

  /**
   * Parameters for Activate_1
   */
  export interface Activate_1Params {
    orderId: number
    detailId: number
  }

  /**
   * Parameters for ResumeCheck
   */
  export interface ResumeCheckParams {
    rubricIds?: string
    cityId?: number
  }

  /**
   * Parameters for ResumeCheck_1
   */
  export interface ResumeCheck_1Params {
    rubricIds?: string
    cityId?: number
  }

  /**
   * Parameters for GetPrice
   */
  export interface GetPriceParams {
    Unit?: 'Week' | 'Month' | 'Quater' | 'HalfYear' | 'Year' | 'Piece'
    ServiceId?: number
    RubricIds?: Array<number>
    Count?: number
    CityIds?: Array<number>
  }
}

export { ServiceService }
