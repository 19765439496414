import { ComponentPortal } from '@angular/cdk/portal'
import { ComponentRef, Injectable, Type } from '@angular/core'
import { asyncScheduler, timer } from 'rxjs'
import { take } from 'rxjs/operators'
import { VerticalModalComponent } from '../../components/vertical-modal/vertical-modal.component'
import { VerticalModalPositionEnum } from '../../models/vertical-modal-postion.enum'
import { ModalOverlayRef, ModalService } from '../modal'

@Injectable({
  providedIn: 'root'
})
export class VerticalModalService extends ModalService {
  public modalRef: ModalOverlayRef | undefined
  protected override readonly hostComponent = VerticalModalComponent

  public override openByComponent<T, K>(content: Type<T>, data?: K, position?: VerticalModalPositionEnum, offset?: number): ModalOverlayRef {
    const overlayRef = this.createOverlay()
    const portal = new ComponentPortal(content)
    const modalRef = new ModalOverlayRef(overlayRef)
    const injector = this.createInjector<K | undefined>(modalRef, data)
    const modalPortal = new ComponentPortal<VerticalModalComponent<T>>(this.hostComponent, null, injector)

    this.hold(this.select('headerIsRendered').pipe(take(1)), () => {
      timer(0, asyncScheduler).subscribe(() => {
        const modalComponent: ComponentRef<VerticalModalComponent<T>> = overlayRef.attach(modalPortal)

        if (position) {
          modalComponent.instance.direction = position
        }

        if (offset) {
          modalComponent.instance.offset = offset
        }

        modalComponent.instance.content = portal
        modalComponent.changeDetectorRef.detectChanges()
      })
    })

    this.modalRef = modalRef
    return modalRef
  }
}
