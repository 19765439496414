import { LanguageCodesEnum, CountryCodesEnum } from '@alliance/shared/models'
import { Injectable } from '@angular/core'
import { ActivatedRoute, Params, PRIMARY_OUTLET, Router } from '@angular/router'

@Injectable()
export class LanguageUrlGeneratorService {
  public constructor(private router: Router, private route: ActivatedRoute) {}

  public getCurrentUrlOnTargetLanguage(lang: LanguageCodesEnum): string {
    const urlTree = this.router.parseUrl(this.router.url)
    const { segments = [] } = urlTree?.root?.children?.[PRIMARY_OUTLET] || {} // for ex: ['employer', 'services'] or ['ua', 'vacancy', 'list']
    const { queryParams } = this.route.snapshot // for ex: { city: 1, keyword: 'php' }

    const segmentsAsCommandsWithoutLangPrefix = segments.map(({ path }) => path).filter(command => command.replace(/^\/+/, '').replace(/\/$/, '') !== CountryCodesEnum.RU.toString()) // remove lang prefix (pre-remove slashes, if they are there)

    let commands: unknown[]

    if (!segmentsAsCommandsWithoutLangPrefix.length) {
      commands = lang === LanguageCodesEnum.UK ? ['/'] : [`/${CountryCodesEnum.RU}`]
    } else {
      commands = lang === LanguageCodesEnum.UK ? [...segmentsAsCommandsWithoutLangPrefix] : [CountryCodesEnum.RU, ...segmentsAsCommandsWithoutLangPrefix]
    }

    return this.createUrlForTarget(commands, queryParams, lang)
  }

  private createUrlForTarget(commands: unknown[], queryParams: Params, lang: LanguageCodesEnum): string {
    let url = this.router.createUrlTree(commands, { queryParams }).toString()
    if (lang === LanguageCodesEnum.UK) {
      const regex = new RegExp(`(\\/(${CountryCodesEnum.RU})(?=\\/|\\b))`)
      url = url.replace(regex, '')
    }
    return url
  }
}
