import { Injectable } from '@angular/core'
import { Observable, catchError, map, of } from 'rxjs'

import { getNonNullableItems } from '@alliance/shared/utils'

import { VacancyCloseGQL } from './complete-vacancy.generated'
import { CompleteVacancyParams } from '../../../models/vacancy-actions/complete-vacancy/complete-vacancy-params.interface'
import { CompleteVacancyResponse } from '../../../models/vacancy-actions/complete-vacancy/complete-vacancy-response.interface'

@Injectable()
export class CompleteVacancyService {
  public constructor(private readonly vacancyCloseGQL: VacancyCloseGQL) {}

  public completeVacancy$(params: CompleteVacancyParams): Observable<CompleteVacancyResponse | null> {
    return this.vacancyCloseGQL
      .mutate({
        input: {
          vacanciesIds: params.vacanciesIds,
          vacancyId: params.vacanciesIds[0] // is going to be removed after schema change
        }
      })
      .pipe(
        map(({ data }) => {
          const vacanciesIds = getNonNullableItems(data?.vacancyClose?.vacancies ?? []).map(item => item.id)
          if (vacanciesIds.length) {
            return { vacanciesIds }
          }
          return null
        }),
        catchError(() => of(null))
      )
  }
}
