import { forwardRef, Provider, Type } from '@angular/core'
import { NG_ASYNC_VALIDATORS, NG_VALIDATORS, NG_VALUE_ACCESSOR } from '@angular/forms'

export const defaultValueAccessorProvider = <T>(componentRef: Type<T>): Provider => ({
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => componentRef),
  multi: true
})

export const defaultValidatorProvider = <T>(componentRef: Type<T>): Provider => ({
  provide: NG_VALIDATORS,
  useExisting: forwardRef(() => componentRef),
  multi: true
})

export const defaultAsyncValidatorProvider = <T>(componentRef: Type<T>): Provider => ({
  provide: NG_ASYNC_VALIDATORS,
  useExisting: forwardRef(() => componentRef),
  multi: true
})
