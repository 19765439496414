import { DataLayerPushEvent } from '@alliance/shared/utils'

export const openChatEvent: DataLayerPushEvent = {
  event: 'chat_events',
  eventCategory: 'chat',
  eventAction: 'right_sidebar',
  eventLabel: 'click'
}

export const openChatFromListEvent = (conversationId: string): DataLayerPushEvent => ({
  eventCategory: 'chat',
  event: 'chat_events',
  eventAction: 'chat_list',
  eventLabel: 'open',
  eventContent: conversationId
})
