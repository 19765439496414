import { CvDbService, ResumeService as EmployerResumeService } from '@alliance/employer/api'
import { AccountJobsearcherService, ResumeService as JobseekerResumeService } from '@alliance/jobseeker/api'
import { ICONS_ASSETS_PATH } from '@alliance/shared/constants'
import { Environment } from '@alliance/shared/environment'
import { log } from '@alliance/shared/logger'
import { DetectPlatformService } from '@alliance/shared/utils'
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http'
import { ApplicationRef, Injectable } from '@angular/core'
import { makeStateKey, StateKey, TransferState } from '@angular/platform-browser'
import { filter, map, Observable, of, take } from 'rxjs'
import { tap } from 'rxjs/operators'
import { TransferRequestService } from './transfer-request.service'

@Injectable()
export class TransferInterceptorService implements HttpInterceptor {
  private isCacheActive = true

  public constructor(
    private transferState: TransferState,
    private platform: DetectPlatformService,
    private transferRequestService: TransferRequestService,
    private env: Environment,
    private appRef: ApplicationRef
  ) {
    // from https://github.com/angular/universal/blob/2e10dace5d64bf8c245f79bd0ea3879c0b06bbbf/modules/common/clover/src/transfer-http-cache/transfer-http-cache.interceptor.ts#L47
    this.appRef.isStable
      .pipe(
        filter(isStable => isStable),
        take(1),
        tap(() => (this.isCacheActive = false))
      )
      .subscribe()
  }

  public intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    if (!this.isCacheActive || request.method !== 'GET') {
      return next.handle(request)
    }

    const filters: ReadonlyArray<(url: string) => boolean> = [
      // cvdb implemented own transfer-state, so skip all urls contains `/cvdb/resume` and `/resume/xxx`
      (url): boolean => !url.includes(new URL(CvDbService.searchPath, this.env.employerApi ?? '').toString()),
      (url): boolean => !url.includes(new URL(EmployerResumeService.getPath.split('/{')[0], this.env.employerApi ?? '').toString()),

      /* jobseeker attaches and cvs */
      (url): boolean => !url.includes(new URL(AccountJobsearcherService.getAttachListPath, this.env.jobseekerApi ?? '').toString()),
      (url): boolean => !url.includes(new URL(AccountJobsearcherService.getAttachListPath, this.env.jobseekerApiUk ?? '').toString()),
      (url): boolean => !url.includes(new URL(JobseekerResumeService.resumeGetJobseekerResumeListPath, this.env.jobseekerApi ?? '').toString()),
      (url): boolean => !url.includes(new URL(JobseekerResumeService.resumeGetJobseekerResumeListPath, this.env.jobseekerApiUk ?? '').toString())
    ]

    const key = this.getKey(request)

    if (this.platform.isServer && filters.map(filterFunc => filterFunc(request.url)).every(filterResult => filterResult)) {
      return next.handle(request).pipe(
        map(response => {
          const LARGE_RESPONSE_THRESHOLD = 30e3
          if ('body' in response && JSON.stringify(response.body).length <= LARGE_RESPONSE_THRESHOLD) {
            this.transferState.set(key, response.body)
          }
          return response
        })
      )
    }

    const storedResponse = this.transferRequestService.get<unknown>(key, null)

    if (storedResponse) {
      return of(
        new HttpResponse({
          body: storedResponse,
          status: 200
        })
      )
    }

    return next.handle(request)
  }

  private getKey(request: HttpRequest<unknown>): StateKey<string> {
    const iconsPath = `/${ICONS_ASSETS_PATH}/`
    let iconKey: string | undefined

    if (request.url.includes(iconsPath)) {
      try {
        iconKey = new URL(request.url).pathname.replace(`/${ICONS_ASSETS_PATH}/`, '').replace('.svg', '')
      } catch (e) {
        log.warn({ where: 'shared-ssr-transfer-state', category: 'try_catch', message: 'iconsPath failed', error: e })
      }
    }

    return makeStateKey<string>(iconKey ?? request.urlWithParams)
  }
}
