import { Injectable } from '@angular/core'
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http'
import { BaseService as __BaseService } from '../base-service'
import { EmployerDataAccessConfiguration as __Configuration } from '../employer-data-access-configuration'
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response'
import { Observable as __Observable } from 'rxjs'
import { map as __map, filter as __filter } from 'rxjs/operators'

import { VacancyStatictic } from '../models/vacancy-statictic'
@Injectable({
  providedIn: 'root'
})
class StaticticsService extends __BaseService {
  public static readonly vacancyStatisticsPath = '/statictics/vacancy/{vacancyId}'

  public constructor(config: __Configuration, http: HttpClient) {
    super(config, http)
  }

  /**
   * @param params The `StaticticsService.VacancyStatisticsParams` containing the following parameters:
   *
   * - `vacancyId`:
   *
   * - `period`:
   *
   * @return Success
   */
  vacancyStatisticsResponse(params: StaticticsService.VacancyStatisticsParams): __Observable<__StrictHttpResponse<VacancyStatictic>> {
    let __params = this.newParams()
    let __headers = new HttpHeaders()
    let __body: any = null

    if (params.period != null) __params = __params.set('period', params.period.toString())
    let req = new HttpRequest<any>('GET', this.rootUrl + `/statictics/vacancy/${params.vacancyId}`, __body, {
      headers: __headers,
      params: __params,
      responseType: 'json'
    })

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map(_r => {
        return _r as __StrictHttpResponse<VacancyStatictic>
      })
    )
  }

  /**
   * @param params The `StaticticsService.VacancyStatisticsParams` containing the following parameters:
   *
   * - `vacancyId`:
   *
   * - `period`:
   *
   * @return Success
   */
  vacancyStatistics(params: StaticticsService.VacancyStatisticsParams): __Observable<VacancyStatictic> {
    return this.vacancyStatisticsResponse(params).pipe(__map(_r => _r.body as VacancyStatictic))
  }
}

module StaticticsService {
  /**
   * Parameters for VacancyStatistics
   */
  export interface VacancyStatisticsParams {
    vacancyId: number
    period?: 'Yesterday' | 'Week' | 'Month' | 'Year'
  }
}

export { StaticticsService }
