/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core'
import { HttpClient, HttpResponse, HttpContext } from '@angular/common/http'
import { BaseService } from '../base-service'
import { ApplyApiDataAccessConfiguration } from '../apply-api-data-access-configuration'
import { StrictHttpResponse } from '../strict-http-response'
import { RequestBuilder } from '../request-builder'
import { Observable } from 'rxjs'
import { map, filter } from 'rxjs/operators'

import { UpdateVacancyOwnerFromSqlToElasticInput } from '../models/update-vacancy-owner-from-sql-to-elastic-input'
import { VacancyOwnerChangeSqlRecord } from '../models/vacancy-owner-change-sql-record'

@Injectable({
  providedIn: 'root'
})
export class ApplyElasticImportService extends BaseService {
  public constructor(config: ApplyApiDataAccessConfiguration, http: HttpClient) {
    super(config, http)
  }

  /**
   * Path part for operation elasticImportVacancyOwnerPut
   */
  public static readonly ElasticImportVacancyOwnerPutPath = '/elastic-import/vacancy-owner'

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `elasticImportVacancyOwnerPut$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  elasticImportVacancyOwnerPut$Plain$Response(params?: { context?: HttpContext; body?: UpdateVacancyOwnerFromSqlToElasticInput }): Observable<StrictHttpResponse<Array<VacancyOwnerChangeSqlRecord>>> {
    const rb = new RequestBuilder(this.rootUrl, ApplyElasticImportService.ElasticImportVacancyOwnerPutPath, 'put')
    if (params) {
      rb.body(params.body, 'application/*+json')
    }

    return this.http
      .request(
        rb.build({
          responseType: 'text',
          accept: 'text/plain',
          context: params?.context
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<Array<VacancyOwnerChangeSqlRecord>>
        })
      )
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `elasticImportVacancyOwnerPut$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  elasticImportVacancyOwnerPut$Plain(params?: { context?: HttpContext; body?: UpdateVacancyOwnerFromSqlToElasticInput }): Observable<Array<VacancyOwnerChangeSqlRecord>> {
    return this.elasticImportVacancyOwnerPut$Plain$Response(params).pipe(map((r: StrictHttpResponse<Array<VacancyOwnerChangeSqlRecord>>) => r.body as Array<VacancyOwnerChangeSqlRecord>))
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `elasticImportVacancyOwnerPut$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  elasticImportVacancyOwnerPut$Json$Response(params?: { context?: HttpContext; body?: UpdateVacancyOwnerFromSqlToElasticInput }): Observable<StrictHttpResponse<Array<VacancyOwnerChangeSqlRecord>>> {
    const rb = new RequestBuilder(this.rootUrl, ApplyElasticImportService.ElasticImportVacancyOwnerPutPath, 'put')
    if (params) {
      rb.body(params.body, 'application/*+json')
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'text/json',
          context: params?.context
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<Array<VacancyOwnerChangeSqlRecord>>
        })
      )
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `elasticImportVacancyOwnerPut$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  elasticImportVacancyOwnerPut$Json(params?: { context?: HttpContext; body?: UpdateVacancyOwnerFromSqlToElasticInput }): Observable<Array<VacancyOwnerChangeSqlRecord>> {
    return this.elasticImportVacancyOwnerPut$Json$Response(params).pipe(map((r: StrictHttpResponse<Array<VacancyOwnerChangeSqlRecord>>) => r.body as Array<VacancyOwnerChangeSqlRecord>))
  }

  /**
   * Path part for operation elasticImportCompaniesPost
   */
  public static readonly ElasticImportCompaniesPostPath = '/elastic-import/companies'

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `elasticImportCompaniesPost()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  elasticImportCompaniesPost$Response(params?: { context?: HttpContext; body?: Array<number> }): Observable<StrictHttpResponse<void>> {
    const rb = new RequestBuilder(this.rootUrl, ApplyElasticImportService.ElasticImportCompaniesPostPath, 'post')
    if (params) {
      rb.body(params.body, 'application/*+json')
    }

    return this.http
      .request(
        rb.build({
          responseType: 'text',
          accept: '*/*',
          context: params?.context
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>
        })
      )
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `elasticImportCompaniesPost$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  elasticImportCompaniesPost(params?: { context?: HttpContext; body?: Array<number> }): Observable<void> {
    return this.elasticImportCompaniesPost$Response(params).pipe(map((r: StrictHttpResponse<void>) => r.body as void))
  }

  /**
   * Path part for operation elasticImportCandidatesPost
   */
  public static readonly ElasticImportCandidatesPostPath = '/elastic-import/candidates'

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `elasticImportCandidatesPost()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  elasticImportCandidatesPost$Response(params?: { context?: HttpContext; body?: Array<string> }): Observable<StrictHttpResponse<void>> {
    const rb = new RequestBuilder(this.rootUrl, ApplyElasticImportService.ElasticImportCandidatesPostPath, 'post')
    if (params) {
      rb.body(params.body, 'application/*+json')
    }

    return this.http
      .request(
        rb.build({
          responseType: 'text',
          accept: '*/*',
          context: params?.context
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>
        })
      )
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `elasticImportCandidatesPost$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  elasticImportCandidatesPost(params?: { context?: HttpContext; body?: Array<string> }): Observable<void> {
    return this.elasticImportCandidatesPost$Response(params).pipe(map((r: StrictHttpResponse<void>) => r.body as void))
  }

  /**
   * Path part for operation elasticImportLostCandidatesLastResultsGet
   */
  public static readonly ElasticImportLostCandidatesLastResultsGetPath = '/elastic-import/lost-candidates/last-results'

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `elasticImportLostCandidatesLastResultsGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  elasticImportLostCandidatesLastResultsGet$Response(params?: { context?: HttpContext }): Observable<StrictHttpResponse<void>> {
    const rb = new RequestBuilder(this.rootUrl, ApplyElasticImportService.ElasticImportLostCandidatesLastResultsGetPath, 'get')
    if (params) {
    }

    return this.http
      .request(
        rb.build({
          responseType: 'text',
          accept: '*/*',
          context: params?.context
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>
        })
      )
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `elasticImportLostCandidatesLastResultsGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  elasticImportLostCandidatesLastResultsGet(params?: { context?: HttpContext }): Observable<void> {
    return this.elasticImportLostCandidatesLastResultsGet$Response(params).pipe(map((r: StrictHttpResponse<void>) => r.body as void))
  }

  /**
   * Path part for operation elasticImportLostCandidatesPost
   */
  public static readonly ElasticImportLostCandidatesPostPath = '/elastic-import/lost-candidates'

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `elasticImportLostCandidatesPost()` instead.
   *
   * This method doesn't expect any request body.
   */
  elasticImportLostCandidatesPost$Response(params?: { onlyForPublishedVacancies?: boolean; context?: HttpContext }): Observable<StrictHttpResponse<void>> {
    const rb = new RequestBuilder(this.rootUrl, ApplyElasticImportService.ElasticImportLostCandidatesPostPath, 'post')
    if (params) {
      rb.query('onlyForPublishedVacancies', params.onlyForPublishedVacancies, {})
    }

    return this.http
      .request(
        rb.build({
          responseType: 'text',
          accept: '*/*',
          context: params?.context
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>
        })
      )
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `elasticImportLostCandidatesPost$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  elasticImportLostCandidatesPost(params?: { onlyForPublishedVacancies?: boolean; context?: HttpContext }): Observable<void> {
    return this.elasticImportLostCandidatesPost$Response(params).pipe(map((r: StrictHttpResponse<void>) => r.body as void))
  }

  /**
   * Path part for operation elasticImportLostCandidatesDelete
   */
  public static readonly ElasticImportLostCandidatesDeletePath = '/elastic-import/lost-candidates'

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `elasticImportLostCandidatesDelete()` instead.
   *
   * This method doesn't expect any request body.
   */
  elasticImportLostCandidatesDelete$Response(params?: { context?: HttpContext }): Observable<StrictHttpResponse<void>> {
    const rb = new RequestBuilder(this.rootUrl, ApplyElasticImportService.ElasticImportLostCandidatesDeletePath, 'delete')
    if (params) {
    }

    return this.http
      .request(
        rb.build({
          responseType: 'text',
          accept: '*/*',
          context: params?.context
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>
        })
      )
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `elasticImportLostCandidatesDelete$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  elasticImportLostCandidatesDelete(params?: { context?: HttpContext }): Observable<void> {
    return this.elasticImportLostCandidatesDelete$Response(params).pipe(map((r: StrictHttpResponse<void>) => r.body as void))
  }

  /**
   * Path part for operation elasticImportLostCandidatesForcedFinishPut
   */
  public static readonly ElasticImportLostCandidatesForcedFinishPutPath = '/elastic-import/lost-candidates/forced-finish'

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `elasticImportLostCandidatesForcedFinishPut()` instead.
   *
   * This method doesn't expect any request body.
   */
  elasticImportLostCandidatesForcedFinishPut$Response(params?: { context?: HttpContext }): Observable<StrictHttpResponse<void>> {
    const rb = new RequestBuilder(this.rootUrl, ApplyElasticImportService.ElasticImportLostCandidatesForcedFinishPutPath, 'put')
    if (params) {
    }

    return this.http
      .request(
        rb.build({
          responseType: 'text',
          accept: '*/*',
          context: params?.context
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>
        })
      )
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `elasticImportLostCandidatesForcedFinishPut$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  elasticImportLostCandidatesForcedFinishPut(params?: { context?: HttpContext }): Observable<void> {
    return this.elasticImportLostCandidatesForcedFinishPut$Response(params).pipe(map((r: StrictHttpResponse<void>) => r.body as void))
  }
}
