declare let process: unknown

const processWithEnv = (value: unknown): value is { env: unknown } => typeof value === 'object' && !!value && 'env' in value
const envWithNode = (value: unknown): value is { NODE_ENV: unknown } => typeof value === 'object' && !!value && 'NODE_ENV' in value

export const isServer = (): boolean => {
  try {
    return typeof process !== 'undefined' && processWithEnv(process) && envWithNode(process.env)
  } catch {
    return false
  }
}
