import { log } from '@alliance/shared/logger'
import { DOCUMENT } from '@angular/common'
import { Inject, Injectable } from '@angular/core'
import { WINDOW } from '@ng-web-apis/common'
import { DetectPlatformService } from './detect-platform.service'

interface WindowScrollOptions {
  topPosition: number
  leftPosition?: number
  behavior?: 'auto' | 'smooth'
}

interface ElementScrollOptions extends WindowScrollOptions {
  element: HTMLElement
}

interface ElementScrollIntoViewOptions extends ScrollIntoViewOptions {
  element: HTMLElement
}

@Injectable({
  providedIn: 'root'
})
export class ScrollHelperService {
  public constructor(@Inject(WINDOW) private readonly window: Window, @Inject(DOCUMENT) private readonly document: Document, private platform: DetectPlatformService) {}

  public scrollTo(params: WindowScrollOptions): void {
    if (this.platform.isBrowser && this.document?.defaultView) {
      const { topPosition, leftPosition = 0, behavior = 'auto' } = params
      this.scrollToPosition(topPosition, leftPosition, behavior)
    }
  }

  public scrollElement(params: ElementScrollOptions): void {
    if (!params.element) {
      return
    }

    if (this.platform.isBrowser) {
      try {
        const isScrollBehaviorSupported = 'scrollBehavior' in params.element.style
        if (isScrollBehaviorSupported) {
          const { element, topPosition = 0, leftPosition = 0, behavior = 'auto' } = params
          element.scroll({ top: topPosition, left: leftPosition, behavior })
        }
      } catch {
        log.warn({ where: 'shared-utils: ScrollHelperService', category: 'try_catch', message: 'scrollElement failed', error: 'Unable to execute scroll on element' })
      }
    }
  }

  public scrollElementIntoView(params: ElementScrollIntoViewOptions): void {
    if (!params.element) {
      return
    }

    if (this.platform.isBrowser) {
      try {
        const { element, block = 'center', inline = 'center' } = params
        element.scrollIntoView({ block, inline })
      } catch {
        log.warn({ where: 'shared-utils: ScrollHelperService', category: 'try_catch', message: 'scrollElementIntoView failed', error: 'Unable to execute scroll on element' })
      }
    }
  }

  public scrollToElement(params: ElementScrollOptions): void {
    if (!params.element) {
      return
    }
    const { element, topPosition, leftPosition = 0, behavior = 'auto' } = params
    this.scrollToPosition(element.offsetTop + topPosition, leftPosition, behavior)
  }

  public getScrolledHeight(el?: HTMLElement): number {
    if (this.platform.isServer) {
      return 0
    }

    try {
      return el ? el.scrollTop : this.window.scrollY
    } catch {
      log.warn({ where: 'shared-utils: ScrollHelperService', category: 'try_catch', message: 'getScrolledHeight failed', error: 'Unable to get scroll position' })
      return 0
    }
  }

  private scrollToPosition(top: number, left: number, behavior: 'auto' | 'smooth'): void {
    if (this.platform.isBrowser && this.document?.defaultView && this.document?.documentElement) {
      try {
        const isScrollBehaviorSupported = 'scrollBehavior' in this.document.documentElement.style
        const scrollOptions: ScrollToOptions = {
          left,
          top,
          behavior
        }
        isScrollBehaviorSupported ? this.document.defaultView.scrollTo(scrollOptions) : this.document.defaultView.scrollTo(scrollOptions.left ?? 0, scrollOptions.top ?? 0)
      } catch {
        log.warn({ where: 'shared-utils: ScrollHelperService', category: 'try_catch', message: 'scrollToPosition failed', error: 'Unable to execute scroll' })
      }
    }
  }
}
