import { CvDbHintsResponse, CvDbSearchFacetsResponse, CvDbSearchHistoryItemResponse, CvKnockout, CvNote, ResumeInfo, SearchItem, VacancyOffer } from '@alliance/employer/data-access'
import { format, parseISO } from '@alliance/shared/datetime'
import { RxStateService } from '@alliance/shared/models'
import { Injectable } from '@angular/core'
import { ResumeFileModel } from '../models/ResumeFileModel'
import { CvdbState, INITIAL_CVDB_STATE } from './cvdb.model'

@Injectable({ providedIn: 'root' })
export class CvdbStore extends RxStateService<CvdbState> {
  public constructor() {
    super()

    this.initState(INITIAL_CVDB_STATE)
  }

  public set id(value: number | null) {
    this.set({ id: value })
  }

  public set searchFacets(value: CvDbSearchFacetsResponse | null) {
    if (value) {
      this.set(store => ({
        searchFacets: { ...store.searchFacets, ...value },
        searchNewCv: value?.newCv || 0,
        pending: { ...store.pending, searchFacets: false }
      }))
    }
  }

  public set searchHistoryList(searchHistoryList: CvDbSearchHistoryItemResponse[]) {
    this.set({ searchHistoryList })
  }

  public set selectedSearches(selectedSearches: SearchItem[]) {
    this.set({ selectedSearches })
  }

  public set alternativeHints(data: CvDbHintsResponse) {
    this.set({ alternativeHints: data })
  }

  public set allCvdbTotal(value: number) {
    this.set({ allCvdbTotal: value })
  }

  public addResumeFile(id: number, value: ResumeFileModel): void {
    this.set(store => ({
      resumeFileMap: {
        ...store.resumeFileMap,
        [id]: value
      }
    }))
  }

  public updateNotesMap(id: number, value: CvNote[]): void {
    this.set(store => ({
      notesMap: {
        ...store.notesMap,
        [id]: value
      }
    }))
  }

  public updateSearchListWithNotes(id: number, value: CvNote[]): void {
    const searchList = this.get()?.searchList || []
    const cvFromSearchList = searchList.find(el => el.resumeId === id)
    if (cvFromSearchList) {
      // todo: cast types CvNote and CvKnockoutComment from BE
      cvFromSearchList.comments = value.map(el => {
        const parsedDate = typeof el.addDate === 'string' ? parseISO(el.addDate) : el.addDate
        return {
          comment: el.description,
          user: el.multiUserName,
          date: format(parsedDate || new Date(), 'dd.MM.yyyy')
        }
      })
      this.updateSearchList(cvFromSearchList)
    }
  }

  public addResume(id: number, value: ResumeInfo | null): void {
    this.set(store => ({
      resumeMap: { ...store.resumeMap, [id]: value },
      pending: { ...store.pending, resume: false }
    }))
  }

  public updateSearchList(data: CvKnockout): void {
    const currentList = this.get()?.searchList || []
    const newList = currentList.map(resume => (resume.resumeId === data.resumeId ? data : resume))
    this.set({ searchList: [...newList] })
  }

  public updateVacancyOffersMap(id: number, value: VacancyOffer[]): void {
    this.set(store => ({
      resumeMap: {
        ...store.resumeMap,
        [id]: {
          ...store.resumeMap[id],
          vacancyOffers: value
        }
      }
    }))
  }
}
