import { MyVacanciesFilterInput } from '@alliance/shared/domain-gql'
import { Inject, Injectable, InjectionToken, Optional } from '@angular/core'
import { catchError, Observable, of } from 'rxjs'
import { map, switchMap } from 'rxjs/operators'
import { VacanciesEmployerIdsService } from '../vacancies-employer-ids.service'
import { GetVacanciesCountGQL } from './vacancies-count.generated'

export const VACANCIES_COUNT_FILTERS = new InjectionToken<MyVacanciesFilterInput>('VACANCIES_COUNT_FILTERS')

@Injectable()
export class VacanciesCountService {
  public constructor(
    @Optional() @Inject(VACANCIES_COUNT_FILTERS) private readonly filters: MyVacanciesFilterInput | null,
    private readonly vacanciesEmployerIdsService: VacanciesEmployerIdsService,
    private readonly getVacanciesCountGQL: GetVacanciesCountGQL
  ) {}

  public getCount$(): Observable<number | null> {
    return this.vacanciesEmployerIdsService.getEmployerIds$().pipe(
      switchMap(employerIds =>
        employerIds
          ? this.getVacanciesCountGQL.fetch({ filter: { ...this.filters, employerIds } }, { fetchPolicy: 'network-only' }).pipe(
              map(({ data }) => data?.myVacancies?.totalCount || null),
              catchError(() => of(null))
            )
          : of(null)
      )
    )
  }
}
