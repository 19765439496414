import { Environment } from '@alliance/shared/environment'

// take from https://raw.githubusercontent.com/cyclosproject/ng-openapi-gen/master/templates/module.handlebars
// and comment code from constructor and below

import { NgModule, ModuleWithProviders } from '@angular/core'
import { VacancySearchDataAccessConfiguration, VacancySearchDataAccessConfigurationParams } from './vacancy-search-data-access-configuration'

import { VacancySearchApiService } from './services/vacancy-search-api.service'

/**
 * Module that provides all services and configuration.
 */
@NgModule({
  imports: [],
  exports: [],
  declarations: [],
  providers: [
    VacancySearchApiService,
    {
      provide: VacancySearchDataAccessConfiguration,
      useFactory: (environment: Environment): VacancySearchDataAccessConfigurationParams => ({
        rootUrl: environment.vacancySearchApi
      }),
      deps: [Environment]
    }
  ]
})
export class VacancySearchDataAccessModule {
  public static forRoot(params: VacancySearchDataAccessConfigurationParams): ModuleWithProviders<VacancySearchDataAccessModule> {
    return {
      ngModule: VacancySearchDataAccessModule,
      providers: [
        {
          provide: VacancySearchDataAccessConfiguration,
          useValue: params
        }
      ]
    }
  }

  /*
    public constructor(
      @Optional() @SkipSelf() parentModule: VacancySearchDataAccessModule,
      @Optional() http: HttpClient
    ) {

      if (parentModule) {
        throw new Error('VacancySearchDataAccessModule is already loaded. Import in your base AppModule only.');
      }
      if (!http) {
        throw new Error('You need to import the HttpClientModule in your AppModule! \n' +
          'See also https://github.com/angular/angular/issues/20575');
      }

    } */
}
