import { concat, from, Observable, ReplaySubject } from 'rxjs'
import { Directive, OnDestroy, OnInit, ɵmarkDirty as markDirty } from '@angular/core'
import { mergeMap, takeUntil, tap } from 'rxjs/operators'
import { RxStateService } from '../services/rx-state.service'

const OnInitSubject = Symbol('OnInitSubject')
const OnDestroySubject = Symbol('OnDestroySubject')

@Directive()
export abstract class RxStateComponent<T extends object> extends RxStateService<T> implements OnInit, OnDestroy {
  private [OnInitSubject] = new ReplaySubject<true>(1)
  private [OnDestroySubject] = new ReplaySubject<true>(1)

  public get onInit$(): Observable<true> {
    return this[OnInitSubject].asObservable()
  }

  public get onDestroy$(): Observable<true> {
    return this[OnDestroySubject].asObservable()
  }

  public connectViewState<K extends keyof T>(keys: K[]): Pick<T, K & keyof T> {
    const sink = {} as Pick<T, K & keyof T>
    const updateSink$ = from(keys).pipe(
      mergeMap(sourceKey =>
        this.select(sourceKey).pipe(
          tap(sinkValue => {
            sink[sourceKey] = sinkValue
          })
        )
      )
    )
    concat(this.onInit$, updateSink$)
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(() => {
        markDirty(this)
      })
    return sink
  }

  public ngOnInit(): void {
    this[OnInitSubject].next(true)
    this[OnInitSubject].complete()
  }

  public override ngOnDestroy(): void {
    super.ngOnDestroy()
    this[OnDestroySubject].next(true)
    this[OnDestroySubject].complete()
  }
}
