import { Injectable } from '@angular/core'
import { LocalStorage } from '@alliance/shared/storage'
import { BehaviorSubject, Observable } from 'rxjs'
import { AccountService, VacancyResultItem } from '@alliance/jobseeker/data-access'
import { HttpClient } from '@angular/common/http'
import { Environment } from '@alliance/shared/environment'
import { RECOMS_VACANCY_TIME_STAMP_STORAGE_KEY } from '@alliance/shared/constants'

@Injectable({
  providedIn: 'root'
})
export class VacancyDislikeService {
  public dislikedVacs = new BehaviorSubject<VacancyResultItem[] | null>(null)
  private readonly HUB_API_URL = 'https://hub-api.robota.ua' // FIXME: deprecated years ago not existing anymore
  private readonly DISLIKE_BANNER_STATUS_STORAGE_KEY = 'dislikePopupAlwaysClosed'
  private uid: number | undefined

  public constructor(private localStorage: LocalStorage, private http: HttpClient, private environment: Environment, private accountService: AccountService) {
    this.accountService.accountGet().subscribe(({ uid }) => {
      if (uid) {
        this.uid = uid
      }
    })
  }

  public dislikeVacancy(vacancyId: number): Observable<unknown> {
    this.localStorage.setItem(RECOMS_VACANCY_TIME_STAMP_STORAGE_KEY, `${Date.now()}`)

    const hubApiTopic = 'hub_vacancy_was_disliked'
    return this.http.get(this.HUB_API_URL, {
      params: { topic: hubApiTopic, userId: `${this.uid || ''}`, vacancyId: `${vacancyId}` }
    })
  }

  public unDislikeVacancy(vacancyId: number): Observable<unknown> {
    this.localStorage.setItem(RECOMS_VACANCY_TIME_STAMP_STORAGE_KEY, `${Date.now()}`)

    return this.http.get(`${this.environment.matchingEuropeApi ?? ''}/remove_vacancy_dislike`, {
      params: { uid: `${this.uid || ''}`, vid: `${vacancyId}` }
    })
  }

  public checkBannerVisibility(): boolean {
    if (this.localStorage.getItem(this.DISLIKE_BANNER_STATUS_STORAGE_KEY)) {
      return false
    }
    this.localStorage.setItem(this.DISLIKE_BANNER_STATUS_STORAGE_KEY, `1`)
    return true
  }
}
