import { Injectable } from '@angular/core'
import { map, Observable } from 'rxjs'
import { RxStateService } from '@alliance/shared/models'
import { UserSatisfactionSurveyService } from '../../domain'

type UserSatisfactionSurveyStatus = 'open' | 'closed'
const CLOSED_STATUS: UserSatisfactionSurveyStatus = 'closed' as const
const OPEN_STATUS: UserSatisfactionSurveyStatus = 'open' as const

// TODO: move to employer/user/domain @romandott
@Injectable({
  providedIn: 'root'
})
export class UserSatisfactionSurveyFacadeService extends RxStateService<{
  status: UserSatisfactionSurveyStatus
}> {
  public constructor(private readonly userSatisfactionSurveyService: UserSatisfactionSurveyService) {
    super()

    this.initState({ status: this.getStatus$() })
  }

  public isOpen$(): Observable<boolean> {
    return this.select('status').pipe(map(status => status === OPEN_STATUS))
  }

  public close$(withAnswer: boolean): Observable<void> {
    return this.userSatisfactionSurveyService.close$(withAnswer).pipe(map(() => this.set({ status: CLOSED_STATUS })))
  }

  private getStatus$(): Observable<UserSatisfactionSurveyStatus> {
    return this.userSatisfactionSurveyService.isOpen$().pipe(map(isOpen => (isOpen ? OPEN_STATUS : CLOSED_STATUS)))
  }
}
