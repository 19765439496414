import { NgZoneHelperService } from '@alliance/shared/utils'
import { DOCUMENT } from '@angular/common'
import { AfterViewInit, Directive, ElementRef, EventEmitter, Inject, Input, OnDestroy, Output } from '@angular/core'
import { fromEvent, Subscription } from 'rxjs'

@Directive({
  selector: '[allianceOuterClick]'
})
export class OuterClickDirective implements AfterViewInit, OnDestroy {
  @Input() public emit = true
  @Input() public documentEvent: 'mousedown' | 'mouseup' = 'mousedown'

  @Output() public clickedOutside = new EventEmitter<void>()

  private subscription = new Subscription()

  public constructor(@Inject(DOCUMENT) private readonly document: Document, private elementRef: ElementRef<HTMLElement>, private ngZoneHelper: NgZoneHelperService) {}

  public ngAfterViewInit(): void {
    this.subscription.add(
      fromEvent(this.document, this.documentEvent)
        .pipe(this.ngZoneHelper.outsideNgZone())
        .subscribe(event => {
          if (this.emit) {
            const isClickedInside = (event.target instanceof Node && this.elementRef.nativeElement.contains(event.target)) || event.target === this.document.documentElement

            if (!isClickedInside) {
              this.clickedOutside.emit()
            }
          }
        })
    )
  }

  public ngOnDestroy(): void {
    this.subscription.unsubscribe()
  }
}
