import { Environment } from '@alliance/shared/environment'
import { LanguageCodesEnum } from '@alliance/shared/models'
import { TranslocoConfig } from '@ngneat/transloco'

export const translocoDefaultConfig = (environment: Environment): TranslocoConfig => ({
  availableLangs: [LanguageCodesEnum.RU, LanguageCodesEnum.UK],
  defaultLang: LanguageCodesEnum.RU,
  prodMode: environment.production,
  reRenderOnLangChange: true,
  missingHandler: {
    allowEmpty: true
  }
})
