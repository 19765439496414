import { DataLayerPushEvent } from '@alliance/shared/utils'

const baseEventData: { event: string; eventCategory: string } = { event: 'EMP_other', eventCategory: 'EMP_other' }

export const getWidgetShownEvent = (label: string): DataLayerPushEvent => ({
  ...baseEventData,
  eventAction: 'show_Helper_widget',
  eventLabel: `${label}`
})

export const getWidgetClickedEvent = (label: string): DataLayerPushEvent => ({
  ...baseEventData,
  eventAction: 'click_Helper_widget',
  eventLabel: `${label}`
})
