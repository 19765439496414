import { RxStateService } from '@alliance/shared/models'
import { Injectable } from '@angular/core'
import {
  ConversationViewModel,
  MessageStatus,
  MessageUpdateContextViewModel,
  MessageUpdateOperation,
  MessageViewModel,
  MessagesService,
  FetchDirection,
  SendMessageContext,
  MessageType
} from '@alliance/chat/data-access'
import { distinctUntilChanged, filter, map, switchMap } from 'rxjs/operators'
import { catchError, finalize, Observable, of } from 'rxjs'
import { Md5 } from 'ts-md5'

@Injectable({ providedIn: 'root' })
export class ChatMessageStateService extends RxStateService<{
  conversationData: ConversationViewModel | null
  messageList: MessageViewModel[]
  isLastPage: boolean
  pending: boolean
}> {
  public constructor(private messagesService: MessagesService) {
    super()
    this.initState({
      conversationData: null,
      messageList: this.select('conversationData').pipe(
        distinctUntilChanged((prev, curr) => {
          if (!prev || !curr) {
            return false
          }
          return prev.conversationId === curr.conversationId
        }),
        filter((data): data is ConversationViewModel => !!data),
        switchMap(data => (data?.conversationId ? this.getMessageList$(data.conversationId) : of([]).pipe(finalize(() => this.set({ pending: false })))))
      ),
      pending: true,
      isLastPage: false
    })
  }

  public showNewMessage(message: MessageViewModel): void {
    if (this.get().conversationData?.conversationId === message.conversationId) {
      this.set(state => ({
        messageList: [...(state.messageList ?? []), message].filter((msg, index, self) => index === self.findIndex(obj => obj.id === msg.id)),
        conversationData: {
          ...state.conversationData,
          vacancy: message.vacancy ? message.vacancy : state.conversationData?.vacancy,
          lastMessage: message
        }
      }))
    }
  }

  public fetchMore(): void {
    const { isLastPage, conversationData, messageList } = this.get()
    if (!isLastPage && conversationData?.conversationId) {
      this.hold(
        this.messagesService.messagesGet$Json({ conversationId: this.get().conversationData?.conversationId ?? '', lastSeen: messageList[0].id ?? '', direction: FetchDirection.Older }).pipe(
          map(list => this.sortMessage(list)),
          catchError(() => of([]))
        ),
        responseMessageList => {
          if (responseMessageList) {
            this.set(state => ({
              messageList: [...responseMessageList, ...state.messageList],
              isLastPage: responseMessageList.length === 0
            }))
          }
        }
      )
    }
  }

  public sendMessage$(payload: SendMessageContext, conversationId?: string): Observable<MessageViewModel> {
    return this.messagesService.messagesPost$Json({
      conversationId: conversationId || (this.get().conversationData?.conversationId ?? ''),
      body: payload
    })
  }

  public sendFirstMessage$(message: string, conversationId: string): Observable<MessageViewModel> {
    return this.sendMessage$(
      {
        messageType: MessageType.Text,
        text: message,
        syncTag: Md5.hashStr(Date.now().toString()).toString()
      },
      conversationId
    )
  }

  public sendMessageReadStatus(list: string[]): Observable<MessageUpdateContextViewModel | null> {
    const conversationId = this.get().conversationData?.conversationId
    if (!conversationId || !list?.length) {
      return of(null)
    }
    return this.messagesService
      .messagesPatch$Json({
        conversationId,
        body: {
          conversationId,
          operation: MessageUpdateOperation.Read,
          messageIds: list
        }
      })
      .pipe(catchError(() => of(null)))
  }

  public setMsgReadStatus(messageIds: string[]): void {
    if (!messageIds?.length) {
      return
    }
    this.set(state => ({
      messageList: (state.messageList ?? []).map(msg => {
        if (msg.id && messageIds?.includes(msg.id)) {
          return {
            ...msg,
            seen: true,
            status: MessageStatus.Read
          }
        }
        return msg
      })
    }))
  }

  private getMessageList$(conversationId: string): Observable<MessageViewModel[]> {
    return this.messagesService.messagesGet$Json({ conversationId, lastSeen: undefined, direction: FetchDirection.Older, limit: 20 }).pipe(
      map(list => this.sortMessage(list)),
      catchError(() => of([])),
      finalize(() => this.set({ pending: false }))
    )
  }

  private sortMessage(list: MessageViewModel[]): MessageViewModel[] {
    return list.sort((a, b) => (a?.id ? a?.created ?? 0 : (a?.created ?? 0) + 100000) - (b?.id ? b?.created ?? 0 : (b?.created ?? 0) + 100000))
  }
}
