import { DataLayerPushEvent } from '@alliance/shared/utils'

export const OPTION_CLICK_USER_SATISFACTION_SURVEY_EVENT: DataLayerPushEvent = {
  event: 'EMP_other',
  eventCategory: 'EMP_other',
  eventAction: 'clickPopup_RateService'
}

export const SHOW_POPUP_USER_SATISFACTION_SURVEY_EVENT: DataLayerPushEvent = {
  event: 'EMP_other',
  eventCategory: 'EMP_other',
  eventAction: 'showPopup_RateService'
}

export const CLOSE_POPUP_USER_SATISFACTION_SURVEY_EVENT: DataLayerPushEvent = {
  event: 'EMP_other',
  eventCategory: 'EMP_other',
  eventAction: 'closePopup_RateService'
}

export const SUBMIT_USER_SATISFACTION_SURVEY_EVENT: DataLayerPushEvent = {
  event: 'EMP_other',
  eventCategory: 'EMP_other',
  eventAction: 'submitPopup_RateService'
}

export const ANSWER_CLOSE_USER_SATISFACTION_SURVEY_EVENT: DataLayerPushEvent = {
  event: 'EMP_other',
  eventCategory: 'EMP_other',
  eventAction: 'closePopupAfterRate_RateService'
}
