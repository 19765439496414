import { Environment } from '@alliance/shared/environment'
import { NgModule, ModuleWithProviders } from '@angular/core'
import { HttpClientModule } from '@angular/common/http'
import { JobseekerDataAccessConfiguration, JobseekerDataAccessConfigurationInterface } from './jobseeker-data-access-configuration'

import { AccountService } from './services/account.service'
import { AccountEmployerService } from './services/account-employer.service'
import { AccountJobsearcherService } from './services/account-jobsearcher.service'
import { AppleService } from './services/apple.service'
import { AutocompleteService } from './services/autocomplete.service'
import { CheckerService } from './services/checker.service'
import { CompanyService } from './services/company.service'
import { DictionaryService } from './services/dictionary.service'
import { FacebookService } from './services/facebook.service'
import { GoogleService } from './services/google.service'
import { LocationService } from './services/location.service'
import { PushService } from './services/push.service'
import { ResumeService } from './services/resume.service'
import { VacancyService } from './services/vacancy.service'
import { WizardService } from './services/wizard.service'

/**
 * Provider for all JobseekerDataAccess services, plus JobseekerDataAccessConfiguration
 */
@NgModule({
  imports: [HttpClientModule],
  exports: [HttpClientModule],
  declarations: [],
  providers: [
    {
      provide: JobseekerDataAccessConfiguration,
      useFactory: (environment: Environment): JobseekerDataAccessConfigurationInterface => ({
        rootUrl: environment.jobseekerApi
      }),
      deps: [Environment]
    },
    AccountService,
    AccountEmployerService,
    AccountJobsearcherService,
    AppleService,
    AutocompleteService,
    CheckerService,
    CompanyService,
    DictionaryService,
    FacebookService,
    GoogleService,
    LocationService,
    PushService,
    ResumeService,
    VacancyService,
    WizardService
  ]
})
export class JobseekerDataAccessModule {
  public static forRoot(customParams?: JobseekerDataAccessConfigurationInterface): ModuleWithProviders<JobseekerDataAccessModule> {
    return {
      ngModule: JobseekerDataAccessModule,
      providers: [
        customParams && customParams.rootUrl
          ? {
              provide: JobseekerDataAccessConfiguration,
              useValue: { rootUrl: customParams.rootUrl }
            }
          : []
      ]
    }
  }
}
