import { AuthService, AUTOLOGIN_QUERY_NAME } from '@alliance/shared/auth/api'
import { LoginService } from '@alliance/shared/login/api'
import { MediaService } from '@alliance/shared/utils'
import { Injectable } from '@angular/core'
import { ActivatedRouteSnapshot, CanActivate, Router, UrlTree } from '@angular/router'
import { Observable, race, timer } from 'rxjs'
import { filter, map, take } from 'rxjs/operators'

@Injectable()
export class OnlySeekerGuard implements CanActivate {
  private timer$ = timer(5000).pipe(map(v => !!v))

  public constructor(private router: Router, private authService: AuthService, private loginService: LoginService, private mediaService: MediaService) {}

  public canActivate(route: ActivatedRouteSnapshot): Observable<boolean | UrlTree> {
    return race(this.authService.token$.pipe(filter(token => (route.queryParamMap.has(AUTOLOGIN_QUERY_NAME) ? !!token : true))), this.timer$).pipe(
      take(1),
      map(() => {
        const { token, isEmployer } = this.authService

        if (isEmployer) {
          return this.router.parseUrl('/my')
        }

        if (token) {
          return true
        }

        if (this.mediaService.isMobileScreen()) {
          return true
        }

        this.loginService.goToLogin()
        return false
      })
    )
  }
}
