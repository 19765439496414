const GAP = {
  5: '5px',
  10: '10px',
  15: '15px',
  20: '20px',
  40: '40px',
  80: '80px'
}

module.exports = { GAP }
