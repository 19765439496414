import { Injectable } from '@angular/core'
import { Observable } from 'rxjs'
import { HttpClient, HttpHeaders } from '@angular/common/http'
import { map } from 'rxjs/operators'

@Injectable({
  providedIn: 'root'
})
export class FileToUrlGeneratorService {
  public constructor(private http: HttpClient) {}

  public getFile$(url: string, defaultName: string, headers: HttpHeaders = new HttpHeaders()): Observable<Blob | null> {
    return this.http
      .get(url, {
        observe: 'response',
        responseType: 'blob',
        headers
      })
      .pipe(
        map(response => {
          const contentType = response?.headers?.get('content-type')
          const contentDisposition = response?.headers.get('content-disposition')
          const fileNameMatches = contentDisposition ? /filename="?([^;"]+)(?=\.\w+)"?;?/g.exec(contentDisposition) : null

          if (response?.body && contentType) {
            return new File([response.body], fileNameMatches && fileNameMatches.length > 1 ? fileNameMatches[1] : defaultName, {
              type: contentType
            })
          }
          return null
        })
      )
  }
}
