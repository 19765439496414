import { DepartmentEnum } from '@alliance/shared/domain-gql'

type ManagerCardsType = 'mainAndTechManagers' | 'onlyMainManager' | 'mainAndSuportCard'
type DepartmentIdType = number | undefined | null

export interface ManagerCardsState {
  isMainManagerVisible: boolean
  isTechManagerVisible: boolean
  isSupportCardVisible: boolean
}

export const getManagerCardsState = (departmentId: DepartmentIdType): ManagerCardsState => MANAGER_CARDS_MAP[getManagerCardsType(departmentId)]

export const getDepartmentForManagerCardById = (departmentId: DepartmentIdType): DepartmentEnum | null => (typeof departmentId === 'number' ? DEPARTMENT_MANAGER_ID_MAP[departmentId] ?? null : null)

const DEPARTMENT_MANAGER_ID_MAP: { [key: number]: DepartmentEnum } = {
  2: DepartmentEnum.Fs,
  12: DepartmentEnum.Is
}

const MANAGER_CARDS_MAP: Record<ManagerCardsType, ManagerCardsState> = {
  mainAndTechManagers: { isMainManagerVisible: true, isTechManagerVisible: true, isSupportCardVisible: false },
  onlyMainManager: { isMainManagerVisible: true, isTechManagerVisible: false, isSupportCardVisible: false },
  mainAndSuportCard: { isMainManagerVisible: true, isTechManagerVisible: false, isSupportCardVisible: true }
}

const getManagerCardsType = (departmentId: DepartmentIdType): ManagerCardsType => {
  const department = getDepartmentForManagerCardById(departmentId)

  if (department !== DepartmentEnum.Fs && department !== DepartmentEnum.Is) {
    return 'mainAndSuportCard'
  }

  if (department === DepartmentEnum.Fs) {
    return 'mainAndTechManagers'
  }

  return 'onlyMainManager'
}
