import { ApplicationRef, ComponentFactoryResolver, ComponentRef, Injectable, Injector, ViewContainerRef } from '@angular/core'
import { ComponentPortal, ComponentType, DomPortalOutlet } from '@angular/cdk/portal'

@Injectable({
  providedIn: 'root'
})
export class RenderAsLazyService {
  public constructor(private resolver: ComponentFactoryResolver, private app: ApplicationRef, private injector: Injector) {}

  public render<C>(viewContainerRef: ViewContainerRef, component: ComponentType<C>): ComponentRef<C> {
    const host = new DomPortalOutlet(viewContainerRef.element.nativeElement as Element, this.resolver, this.app, this.injector)
    const portal = new ComponentPortal(component, viewContainerRef, this.injector, this.resolver)
    const componentRef = portal.attach(host)
    componentRef.changeDetectorRef.markForCheck()
    return componentRef
  }

  public renderWithContextInjector<C>(viewContainerRef: ViewContainerRef, component: ComponentType<C>, injector: Injector): ComponentRef<C> {
    const host = new DomPortalOutlet(viewContainerRef.element.nativeElement as Element, this.resolver, this.app, injector)
    const portal = new ComponentPortal(component, viewContainerRef, injector, this.resolver)
    const componentRef = portal.attach(host)
    componentRef.changeDetectorRef.markForCheck()
    return componentRef
  }
}
