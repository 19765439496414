/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core'
import { HttpClient, HttpResponse, HttpContext } from '@angular/common/http'
import { BaseService } from '../base-service'
import { PromoDataAccessConfigurationInterface } from '../promo-data-access-configuration-interface'
import { StrictHttpResponse } from '../strict-http-response'
import { RequestBuilder } from '../request-builder'
import { Observable } from 'rxjs'
import { map, filter } from 'rxjs/operators'

import { SpinWheelResponse } from '../models/spin-wheel-response'

@Injectable({
  providedIn: 'root'
})
export class SpecialActivityService extends BaseService {
  constructor(config: PromoDataAccessConfigurationInterface, http: HttpClient) {
    super(config, http)
  }

  /**
   * Path part for operation specialActivityPromoWheelGet
   */
  static readonly SpecialActivityPromoWheelGetPath = '/special-activity/promo-wheel'

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `specialActivityPromoWheelGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  specialActivityPromoWheelGet$Plain$Response(params?: {}, context?: HttpContext): Observable<StrictHttpResponse<SpinWheelResponse>> {
    const rb = new RequestBuilder(this.rootUrl, SpecialActivityService.SpecialActivityPromoWheelGetPath, 'get')
    if (params) {
    }

    return this.http
      .request(
        rb.build({
          responseType: 'text',
          accept: 'text/plain',
          context: context
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<SpinWheelResponse>
        })
      )
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `specialActivityPromoWheelGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  specialActivityPromoWheelGet$Plain(params?: {}, context?: HttpContext): Observable<SpinWheelResponse> {
    return this.specialActivityPromoWheelGet$Plain$Response(params, context).pipe(map((r: StrictHttpResponse<SpinWheelResponse>) => r.body as SpinWheelResponse))
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `specialActivityPromoWheelGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  specialActivityPromoWheelGet$Json$Response(params?: {}, context?: HttpContext): Observable<StrictHttpResponse<SpinWheelResponse>> {
    const rb = new RequestBuilder(this.rootUrl, SpecialActivityService.SpecialActivityPromoWheelGetPath, 'get')
    if (params) {
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'text/json',
          context: context
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<SpinWheelResponse>
        })
      )
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `specialActivityPromoWheelGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  specialActivityPromoWheelGet$Json(params?: {}, context?: HttpContext): Observable<SpinWheelResponse> {
    return this.specialActivityPromoWheelGet$Json$Response(params, context).pipe(map((r: StrictHttpResponse<SpinWheelResponse>) => r.body as SpinWheelResponse))
  }

  /**
   * Path part for operation specialActivityActivatePromoWheelPost
   */
  static readonly SpecialActivityActivatePromoWheelPostPath = '/special-activity/activate-promo-wheel'

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `specialActivityActivatePromoWheelPost$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  specialActivityActivatePromoWheelPost$Plain$Response(params?: {}, context?: HttpContext): Observable<StrictHttpResponse<SpinWheelResponse>> {
    const rb = new RequestBuilder(this.rootUrl, SpecialActivityService.SpecialActivityActivatePromoWheelPostPath, 'post')
    if (params) {
    }

    return this.http
      .request(
        rb.build({
          responseType: 'text',
          accept: 'text/plain',
          context: context
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<SpinWheelResponse>
        })
      )
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `specialActivityActivatePromoWheelPost$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  specialActivityActivatePromoWheelPost$Plain(params?: {}, context?: HttpContext): Observable<SpinWheelResponse> {
    return this.specialActivityActivatePromoWheelPost$Plain$Response(params, context).pipe(map((r: StrictHttpResponse<SpinWheelResponse>) => r.body as SpinWheelResponse))
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `specialActivityActivatePromoWheelPost$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  specialActivityActivatePromoWheelPost$Json$Response(params?: {}, context?: HttpContext): Observable<StrictHttpResponse<SpinWheelResponse>> {
    const rb = new RequestBuilder(this.rootUrl, SpecialActivityService.SpecialActivityActivatePromoWheelPostPath, 'post')
    if (params) {
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'text/json',
          context: context
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<SpinWheelResponse>
        })
      )
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `specialActivityActivatePromoWheelPost$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  specialActivityActivatePromoWheelPost$Json(params?: {}, context?: HttpContext): Observable<SpinWheelResponse> {
    return this.specialActivityActivatePromoWheelPost$Json$Response(params, context).pipe(map((r: StrictHttpResponse<SpinWheelResponse>) => r.body as SpinWheelResponse))
  }
}
