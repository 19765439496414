/* tslint:disable */
/* eslint-disable */

/**
 * Determines message fetching strategy
 */
export enum FetchDirection {
  Older = 'Older',
  Newer = 'Newer'
}
