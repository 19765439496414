import { RxStateComponent } from '@alliance/shared/models'
import { MediaService, UserSideEnum } from '@alliance/shared/utils'
import { AfterViewInit, ChangeDetectionStrategy, Component, ComponentRef, Injector, Input, OnDestroy, ViewContainerRef, ɵcreateInjector as createInjector } from '@angular/core'
import { combineLatest, from, Observable, of } from 'rxjs'
import { catchError, map, switchMap } from 'rxjs/operators'
import { FooterData } from '../models/footer-data.interface'

@Component({
  selector: 'alliance-shared-footer',
  template: '',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FooterComponent
  extends RxStateComponent<{
    footerData: FooterData | null
    userSide: UserSideEnum | null
    isEmployer: boolean
    isSeeker: boolean
    showFooter: boolean
    isMobile: boolean
  }>
  implements AfterViewInit, OnDestroy
{
  private componentRef: ComponentRef<unknown> | null = null

  public constructor(private readonly injector: Injector, private readonly viewContainerRef: ViewContainerRef, private readonly mediaService: MediaService) {
    super()

    this.initState({
      footerData: null,
      userSide: null,
      isEmployer: false,
      isSeeker: false,
      showFooter: combineLatest([this.select('footerData'), this.select('isMobile')]).pipe(
        map(([data, isMobile]) => (data?.preventRenderFooterIfMobile ? !isMobile && !!data?.isShowFooter : !!data?.isShowFooter))
      ),
      isMobile: this.mediaService.select('isMobileScreen')
    })
  }

  @Input() public set footerData(value: FooterData | null) {
    this.set({ footerData: value })
  }

  @Input() public set userSide(value: UserSideEnum | null) {
    this.set({ userSide: value })
  }

  @Input() public set isEmployer(value: boolean) {
    this.set({ isEmployer: value })
  }

  @Input() public set isSeeker(value: boolean) {
    this.set({ isSeeker: value })
  }

  public ngAfterViewInit(): void {
    this.hold(
      this.select('showFooter').pipe(switchMap(showFooter => (showFooter ? this.select('isMobile').pipe(switchMap(isMobile => this.render$(isMobile))) : of(this.clean()).pipe(map(() => null))))),
      componentRef => {
        this.componentRef = componentRef
      }
    )
  }

  public override ngOnDestroy(): void {
    super.ngOnDestroy()

    this.clean()
  }

  private render$(isMobile: boolean): Observable<ComponentRef<unknown> | null> {
    this.clean()

    return isMobile ? this.renderMobile$() : this.renderDesktop$()
  }

  private renderDesktop$(): Observable<ComponentRef<unknown> | null> {
    return from(import('../footer/desktop')).pipe(
      map(({ SharedFooterDesktopModule }) => {
        const injector = createInjector(SharedFooterDesktopModule, this.injector)
        const module = injector.get(SharedFooterDesktopModule)

        if (this.viewContainerRef) {
          const desktopComponentRef = module.render(this.viewContainerRef)

          this.hold(this.select('userSide'), userSide => (desktopComponentRef.instance.userSide = userSide))
          this.hold(this.select('isEmployer'), isEmployer => (desktopComponentRef.instance.isEmployer = isEmployer))
          this.hold(this.select('isSeeker'), isSeeker => (desktopComponentRef.instance.isSeeker = isSeeker))

          return desktopComponentRef
        }

        return null
      }),
      catchError(() => of(null))
    )
  }

  private renderMobile$(): Observable<ComponentRef<unknown> | null> {
    return from(import('../footer/mobile')).pipe(
      map(({ SharedFooterMobileModule }) => {
        const injector = createInjector(SharedFooterMobileModule, this.injector)
        const module = injector.get(SharedFooterMobileModule)

        if (this.viewContainerRef) {
          const mobileComponentRef = module.render(this.viewContainerRef)

          this.hold(this.select('userSide'), userSide => (mobileComponentRef.instance.userSide = userSide))
          this.hold(this.select('isEmployer'), isEmployer => (mobileComponentRef.instance.isEmployer = isEmployer))
          this.hold(this.select('isSeeker'), isSeeker => (mobileComponentRef.instance.isSeeker = isSeeker))

          return mobileComponentRef
        }

        return null
      }),
      catchError(() => of(null))
    )
  }

  private clean(): void {
    this.componentRef?.destroy()
    this.viewContainerRef?.clear()
  }
}
