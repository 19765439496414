import { SentryBrowserErrorHandlerService } from '@alliance/shared/sentry'
import { LocalStorage } from '@alliance/shared/storage'
import { DetectPlatformService } from '@alliance/shared/utils'
import { Injectable } from '@angular/core'
import { OpenModalHelperService } from './open-modal-helper.service'

@Injectable()
export class OnChunkLoadErrorHandler extends SentryBrowserErrorHandlerService {
  public constructor(localStorage: LocalStorage, platform: DetectPlatformService, private openModalHelperService: OpenModalHelperService) {
    super(localStorage, platform)
  }

  public override handleError(error: unknown): void {
    if (error && typeof error === 'object') {
      const errorMessage = 'toString' in error ? (error as Error).toString() : ''

      if (/Loading chunk .+ failed/.test(errorMessage)) {
        return this.showConfirmReloadModal()
      }
    }

    return super.handleError(error)
  }

  private showConfirmReloadModal(): void {
    if (this.platform.isServer) {
      return
    }

    return this.openModalHelperService.showConfirmReloadModal()
  }
}
