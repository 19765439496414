import { Injectable } from '@angular/core'
import { DELETE_ACC_SURVEY_SCRIPT_URL, DELETE_RESUME_SURVEY_SCRIPT_URL } from '../constants/constants'
import { DynamicFilesLoaderService } from '@alliance/shared/utils'

@Injectable()
export class SurveyMonkeyService {
  public constructor(private dynamicFilesLoaderService: DynamicFilesLoaderService) {}

  public showSurveyMonkeyDialog(surveyType: 'deleteAcc' | 'deleteResume'): void {
    let scriptUrl = ''

    let scriptId = ''

    if (surveyType === 'deleteResume') {
      scriptUrl = DELETE_RESUME_SURVEY_SCRIPT_URL
      scriptId = 'DELETE_RESUME_SURVEY_SCRIPT'
    }

    if (surveyType === 'deleteAcc') {
      scriptUrl = DELETE_ACC_SURVEY_SCRIPT_URL
      scriptId = 'DELETE_ACC_SURVEY_SCRIPT'
    }

    this.dynamicFilesLoaderService.loadFile(scriptUrl, scriptId, 'script', '1')
  }
}
