import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { Observable, throwError } from 'rxjs'
import { catchError } from 'rxjs/operators'
import { BaseRestrictedByRightErrorMiddlewareService } from './base-restricted-by-right-error-middleware.service'

@Injectable()
export class RestrictedByRightErrorInterceptorService extends BaseRestrictedByRightErrorMiddlewareService implements HttpInterceptor {
  private readonly allowedUrls = [
    this.environment.companyEmployeesServiceApi,
    this.environment.employerApi,
    this.environment.employerServiceApi,
    this.environment.paymentApi,
    this.environment.vacancyApi
  ].map(url => (url ? new URL(url).origin : url))

  public intercept(req: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    const allowedUrl = this.allowedUrls.includes(new URL(req.url).origin)

    return this.platform.isBrowser && allowedUrl
      ? next.handle(req).pipe(
          catchError(response => {
            if (response instanceof HttpErrorResponse && this.isValidGlobalExceptionResponse(response.error) && response.error?.key === this.GLOBAL_EXCEPTION_NAME) {
              this.notifyRestrictedByRightError()
            }
            return throwError(response)
          })
        )
      : next.handle(req)
  }
}
