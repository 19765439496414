import { TransferService } from '@alliance/shared/utils'
import { animate, state, style, transition, trigger } from '@angular/animations'
import { AfterViewInit, ChangeDetectionStrategy, Component, ElementRef, Inject, Input } from '@angular/core'
import { DOCUMENT } from '@angular/common'
import { of } from 'rxjs'
import { delay } from 'rxjs/operators'
import { ModalOverlayRef, SANTA_MODAL_DATA, SANTA_MODAL_REF } from '../../services/modal'
import { ModalComponent } from '../modal/modal.component'

const DEFAULT_OFFSET = 20

@Component({
  selector: 'santa-snackbar',
  templateUrl: './snackbar.component.html',
  styleUrls: ['./snackbar.component.tw.css'],
  animations: [
    trigger('openState', [
      state('void, exit', style({ transform: 'translateY(100%)' })),
      state(
        'opened',
        style({
          transform: 'none'
        })
      ),
      transition('* => *', [animate('200ms cubic-bezier(0.86, 0, 0.07, 1)')])
    ]),
    trigger('openStateBottom', [
      state('void, exit', style({ transform: 'translateY(200%)' })),
      state(
        'opened',
        style({
          transform: 'none'
        })
      ),
      transition('* => *', [animate('200ms cubic-bezier(0.86, 0, 0.07, 1)')])
    ])
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SnackbarComponent<T> extends ModalComponent<T> implements AfterViewInit {
  @Input() public offset = DEFAULT_OFFSET

  public readonly defaultOffset = DEFAULT_OFFSET

  public constructor(
    @Inject(SANTA_MODAL_REF) _modalOverlayRef: ModalOverlayRef,
    @Inject(SANTA_MODAL_DATA) public data: { value: string },
    @Inject(DOCUMENT) public _document: Document,
    private elemRef: ElementRef<HTMLElement>,
    transfer: TransferService
  ) {
    super(_modalOverlayRef, transfer)

    this.hold(of(null).pipe(delay(3000)), () => {
      this.animateCloseModal()
    })
  }

  public ngAfterViewInit(): void {
    this.elemRef?.nativeElement?.parentElement?.classList.add('santa-w-0')
    this._document?.documentElement?.classList.remove('cdk-global-scrollblock')
  }
}
