import { Injectable } from '@angular/core'
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http'
import { BaseService as __BaseService } from '../base-service'
import { JobseekerDataAccessConfiguration as __Configuration } from '../jobseeker-data-access-configuration'
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response'
import { Observable as __Observable } from 'rxjs'
import { map as __map, filter as __filter } from 'rxjs/operators'

import { FcmToken } from '../models/fcm-token'
@Injectable({
  providedIn: 'root'
})
class PushService extends __BaseService {
  public static readonly pushStorePath = '/fcm/tokenpush'
  public static readonly pushDeletePath = '/fcm/tokenpush'
  public static readonly pushChangeSubscriptionStatusPath = '/fcm/push-type/{type}/status/{status}'
  public static readonly pushGetSubscriptionStatusPath = '/fcm/push-type/{type}/status'

  public constructor(config: __Configuration, http: HttpClient) {
    super(config, http)
  }

  /**
   * @param data undefined
   */
  pushStoreResponse(data: FcmToken): __Observable<__StrictHttpResponse<any>> {
    let __params = this.newParams()
    let __headers = new HttpHeaders()
    let __body: any = null
    __body = data
    let req = new HttpRequest<any>('POST', this.rootUrl + `/fcm/tokenpush`, __body, {
      headers: __headers,
      params: __params,
      responseType: 'json'
    })

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map(_r => {
        return _r as __StrictHttpResponse<any>
      })
    )
  }

  /**
   * @param data undefined
   */
  pushStore(data: FcmToken): __Observable<any> {
    return this.pushStoreResponse(data).pipe(__map(_r => _r.body as any))
  }

  /**
   * @param token undefined
   */
  pushDeleteResponse(token: string): __Observable<__StrictHttpResponse<any>> {
    let __params = this.newParams()
    let __headers = new HttpHeaders()
    let __body: any = null
    if (token != null) __params = __params.set('token', token.toString())
    let req = new HttpRequest<any>('DELETE', this.rootUrl + `/fcm/tokenpush`, __body, {
      headers: __headers,
      params: __params,
      responseType: 'json'
    })

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map(_r => {
        return _r as __StrictHttpResponse<any>
      })
    )
  }

  /**
   * @param token undefined
   */
  pushDelete(token: string): __Observable<any> {
    return this.pushDeleteResponse(token).pipe(__map(_r => _r.body as any))
  }

  /**
   * @param params The `PushService.PushChangeSubscriptionStatusParams` containing the following parameters:
   *
   * - `type`:
   *
   * - `token`:
   *
   * - `status`:
   *
   * - `email`:
   *
   * - `cid`:
   */
  pushChangeSubscriptionStatusResponse(params: PushService.PushChangeSubscriptionStatusParams): __Observable<__StrictHttpResponse<any>> {
    let __params = this.newParams()
    let __headers = new HttpHeaders()
    let __body: any = null

    if (params.token != null) __params = __params.set('token', params.token.toString())

    if (params.email != null) __params = __params.set('email', params.email.toString())
    if (params.cid != null) __params = __params.set('cid', params.cid.toString())
    let req = new HttpRequest<any>('PUT', this.rootUrl + `/fcm/push-type/${params.type}/status/${params.status}`, __body, {
      headers: __headers,
      params: __params,
      responseType: 'json'
    })

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map(_r => {
        return _r as __StrictHttpResponse<any>
      })
    )
  }

  /**
   * @param params The `PushService.PushChangeSubscriptionStatusParams` containing the following parameters:
   *
   * - `type`:
   *
   * - `token`:
   *
   * - `status`:
   *
   * - `email`:
   *
   * - `cid`:
   */
  pushChangeSubscriptionStatus(params: PushService.PushChangeSubscriptionStatusParams): __Observable<any> {
    return this.pushChangeSubscriptionStatusResponse(params).pipe(__map(_r => _r.body as any))
  }

  /**
   * @param params The `PushService.PushGetSubscriptionStatusParams` containing the following parameters:
   *
   * - `type`:
   *
   * - `token`:
   *
   * @return OK
   */
  pushGetSubscriptionStatusResponse(params: PushService.PushGetSubscriptionStatusParams): __Observable<__StrictHttpResponse<boolean>> {
    let __params = this.newParams()
    let __headers = new HttpHeaders()
    let __body: any = null

    if (params.token != null) __params = __params.set('token', params.token.toString())
    let req = new HttpRequest<any>('GET', this.rootUrl + `/fcm/push-type/${params.type}/status`, __body, {
      headers: __headers,
      params: __params,
      responseType: 'json'
    })

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map(_r => {
        return (_r as HttpResponse<any>).clone({
          body: (_r as HttpResponse<any>).body === true
        }) as __StrictHttpResponse<boolean>
      })
    )
  }

  /**
   * @param params The `PushService.PushGetSubscriptionStatusParams` containing the following parameters:
   *
   * - `type`:
   *
   * - `token`:
   *
   * @return OK
   */
  pushGetSubscriptionStatus(params: PushService.PushGetSubscriptionStatusParams): __Observable<boolean> {
    return this.pushGetSubscriptionStatusResponse(params).pipe(__map(_r => _r.body as boolean))
  }
}

module PushService {
  /**
   * Parameters for PushChangeSubscriptionStatus
   */
  export interface PushChangeSubscriptionStatusParams {
    type: 'Recommend' | 'Alert' | 'Create_Cv' | 'InstantResumeApplyView' | 'SummaryResumeView' | 'FreshRecommendedVacancies'
    token: string
    status: boolean
    email?: string
    cid?: string
  }

  /**
   * Parameters for PushGetSubscriptionStatus
   */
  export interface PushGetSubscriptionStatusParams {
    type: 'Recommend' | 'Alert' | 'Create_Cv' | 'InstantResumeApplyView' | 'SummaryResumeView' | 'FreshRecommendedVacancies'
    token: string
  }
}

export { PushService }
