// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line max-classes-per-file
import { InjectionToken } from '@angular/core'

// eslint-disable-next-line @typescript-eslint/no-unsafe-return, @typescript-eslint/no-explicit-any
export const GenericTranslation = <Props>(): new () => Props => class {} as any

const concatIfExistsPath = (path: string, suffix: string): string => (path ? `${path}.${suffix}` : suffix)

export const transformObjectToPath = <T extends object | string>(suffix: string, objectToTransformOrEndOfPath: T, path = ''): T =>
  typeof objectToTransformOrEndOfPath === 'object'
    ? Object.entries(objectToTransformOrEndOfPath).reduce((objectToTransform, [key, value]) => {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore TODO: FIX ME SOMEDAY
        // TODO: Fix this the next time the file is edited.
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
        objectToTransform[key] = transformObjectToPath(key, value, concatIfExistsPath(path, suffix))
        return objectToTransform
        // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
      }, {} as T)
    : (concatIfExistsPath(path, suffix) as T)

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line @typescript-eslint/explicit-function-return-type, @typescript-eslint/no-explicit-any
export function TranslationsFactory(lang: any) {
  // eslint-disable-next-line max-classes-per-file
  return class Translations extends GenericTranslation<typeof lang>() {
    public constructor() {
      super()
      Object.assign(this, transformObjectToPath('', lang))
    }
  }
}

export const TranslationsToken = new InjectionToken<string>('TranslationsToken')
