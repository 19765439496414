import { Injectable } from '@angular/core'
import { Observable, of } from 'rxjs'
import { catchError, map } from 'rxjs/operators'
import { GetServiceCountersGQL, GetServiceCountersQuery, GetServiceCountersQueryVariables } from '../../infrastructure/graphql/company-services'

@Injectable({
  providedIn: 'root'
})
export class ServicePackageCounterService {
  public constructor(private readonly serviceCountersGQL: GetServiceCountersGQL) {}

  public getPublicationsCount$(companyId: string, type: 'inactive' = 'inactive'): Observable<number | null> {
    if (type === 'inactive') {
      return this.getInactiveServicePublicationsCount$(companyId)
    }

    return of(0)
  }

  private getInactiveServicePublicationsCount$(companyId: string): Observable<number | null> {
    const query = this.getServiceCounters$(companyId)
    return query.pipe(
      map(result => {
        if (!result) {
          return null
        }

        const { company } = result
        return company?.summaryServices?.reduce((previous, current) => previous + (current?.total || 0), 0) || 0
      })
    )
  }

  private getServiceCounters$(companyId: string): Observable<GetServiceCountersQuery | null> {
    const input: GetServiceCountersQueryVariables = { companyId }
    return this.serviceCountersGQL
      .fetch(input)
      .pipe(map(result => result.data))
      .pipe(catchError(() => of(null)))
  }
}
