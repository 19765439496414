/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { Observable } from 'rxjs'
import { map } from 'rxjs/operators'

import { BaseService } from '../base-service'
import { TagsSuggesterDataAccessConfiguration } from '../tags-suggester-data-access-configuration'
import { StrictHttpResponse } from '../strict-http-response'

import { suggestByNameEndpointSuggestNameGet } from '../fn/suggest/suggest-by-name-endpoint-suggest-name-get'
import { suggestByNameEndpointSuggestNameGet_1 } from '../fn/suggest/suggest-by-name-endpoint-suggest-name-get-1'
import { SuggestByNameEndpointSuggestNameGet_1$Params } from '../fn/suggest/suggest-by-name-endpoint-suggest-name-get-1'
import { SuggestByNameEndpointSuggestNameGet$Params } from '../fn/suggest/suggest-by-name-endpoint-suggest-name-get'
import { suggestEndpointSuggestGet } from '../fn/suggest/suggest-endpoint-suggest-get'
import { suggestEndpointSuggestGet_1 } from '../fn/suggest/suggest-endpoint-suggest-get-1'
import { SuggestEndpointSuggestGet_1$Params } from '../fn/suggest/suggest-endpoint-suggest-get-1'
import { SuggestEndpointSuggestGet$Params } from '../fn/suggest/suggest-endpoint-suggest-get'
import { SuggestResponse } from '../models/suggest-response'

@Injectable({ providedIn: 'root' })
export class TagsSuggesterSuggestService extends BaseService {
  constructor(config: TagsSuggesterDataAccessConfiguration, http: HttpClient) {
    super(config, http)
  }

  /** Path part for operation `suggestEndpointSuggestGet()` */
  static readonly SuggestEndpointSuggestGetPath = '/suggest/'

  /**
   * Cluster suggestions by tag ids.
   *
   * Cluster suggestions by tag ids. <br><br>
   *
   * `profession_tag (int)` - profession id <br>
   * `skill_tags (str)` - skill ids separated with comma <br>
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `suggestEndpointSuggestGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  suggestEndpointSuggestGet$Response(params: SuggestEndpointSuggestGet$Params, context?: HttpContext): Observable<StrictHttpResponse<SuggestResponse>> {
    return suggestEndpointSuggestGet(this.http, this.rootUrl, params, context)
  }

  /**
   * Cluster suggestions by tag ids.
   *
   * Cluster suggestions by tag ids. <br><br>
   *
   * `profession_tag (int)` - profession id <br>
   * `skill_tags (str)` - skill ids separated with comma <br>
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `suggestEndpointSuggestGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  suggestEndpointSuggestGet(params: SuggestEndpointSuggestGet$Params, context?: HttpContext): Observable<SuggestResponse> {
    return this.suggestEndpointSuggestGet$Response(params, context).pipe(map((r: StrictHttpResponse<SuggestResponse>): SuggestResponse => r.body))
  }

  /** Path part for operation `suggestEndpointSuggestGet_1()` */
  static readonly SuggestEndpointSuggestGet_1Path = '/suggest'

  /**
   * Cluster suggestions by tag ids.
   *
   * Cluster suggestions by tag ids. <br><br>
   *
   * `profession_tag (int)` - profession id <br>
   * `skill_tags (str)` - skill ids separated with comma <br>
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `suggestEndpointSuggestGet_1()` instead.
   *
   * This method doesn't expect any request body.
   */
  suggestEndpointSuggestGet_1$Response(params: SuggestEndpointSuggestGet_1$Params, context?: HttpContext): Observable<StrictHttpResponse<SuggestResponse>> {
    return suggestEndpointSuggestGet_1(this.http, this.rootUrl, params, context)
  }

  /**
   * Cluster suggestions by tag ids.
   *
   * Cluster suggestions by tag ids. <br><br>
   *
   * `profession_tag (int)` - profession id <br>
   * `skill_tags (str)` - skill ids separated with comma <br>
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `suggestEndpointSuggestGet_1$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  suggestEndpointSuggestGet_1(params: SuggestEndpointSuggestGet_1$Params, context?: HttpContext): Observable<SuggestResponse> {
    return this.suggestEndpointSuggestGet_1$Response(params, context).pipe(map((r: StrictHttpResponse<SuggestResponse>): SuggestResponse => r.body))
  }

  /** Path part for operation `suggestByNameEndpointSuggestNameGet()` */
  static readonly SuggestByNameEndpointSuggestNameGetPath = '/suggest/name/'

  /**
   * Cluster suggestions by tag names.
   *
   * Cluster suggestions by tag names. <br><br>
   *
   * `profession_tag (str)` - name of profession <br>
   * `skill_tags (str)` - skill names separated with comma <br>
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `suggestByNameEndpointSuggestNameGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  suggestByNameEndpointSuggestNameGet$Response(params: SuggestByNameEndpointSuggestNameGet$Params, context?: HttpContext): Observable<StrictHttpResponse<SuggestResponse>> {
    return suggestByNameEndpointSuggestNameGet(this.http, this.rootUrl, params, context)
  }

  /**
   * Cluster suggestions by tag names.
   *
   * Cluster suggestions by tag names. <br><br>
   *
   * `profession_tag (str)` - name of profession <br>
   * `skill_tags (str)` - skill names separated with comma <br>
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `suggestByNameEndpointSuggestNameGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  suggestByNameEndpointSuggestNameGet(params: SuggestByNameEndpointSuggestNameGet$Params, context?: HttpContext): Observable<SuggestResponse> {
    return this.suggestByNameEndpointSuggestNameGet$Response(params, context).pipe(map((r: StrictHttpResponse<SuggestResponse>): SuggestResponse => r.body))
  }

  /** Path part for operation `suggestByNameEndpointSuggestNameGet_1()` */
  static readonly SuggestByNameEndpointSuggestNameGet_1Path = '/suggest/name'

  /**
   * Cluster suggestions by tag names.
   *
   * Cluster suggestions by tag names. <br><br>
   *
   * `profession_tag (str)` - name of profession <br>
   * `skill_tags (str)` - skill names separated with comma <br>
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `suggestByNameEndpointSuggestNameGet_1()` instead.
   *
   * This method doesn't expect any request body.
   */
  suggestByNameEndpointSuggestNameGet_1$Response(params: SuggestByNameEndpointSuggestNameGet_1$Params, context?: HttpContext): Observable<StrictHttpResponse<SuggestResponse>> {
    return suggestByNameEndpointSuggestNameGet_1(this.http, this.rootUrl, params, context)
  }

  /**
   * Cluster suggestions by tag names.
   *
   * Cluster suggestions by tag names. <br><br>
   *
   * `profession_tag (str)` - name of profession <br>
   * `skill_tags (str)` - skill names separated with comma <br>
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `suggestByNameEndpointSuggestNameGet_1$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  suggestByNameEndpointSuggestNameGet_1(params: SuggestByNameEndpointSuggestNameGet_1$Params, context?: HttpContext): Observable<SuggestResponse> {
    return this.suggestByNameEndpointSuggestNameGet_1$Response(params, context).pipe(map((r: StrictHttpResponse<SuggestResponse>): SuggestResponse => r.body))
  }
}
