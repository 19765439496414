import { RxStateComponent } from '@alliance/shared/models'
import { DetectPlatformService, MediaService, NgZoneHelperService } from '@alliance/shared/utils'
import { AfterViewInit, ChangeDetectionStrategy, Component, ComponentRef, Injector, ViewContainerRef, ɵcreateInjector as createInjector } from '@angular/core'
import { asyncScheduler, from, of, switchMap, timer } from 'rxjs'
import { catchError, filter, map, retry } from 'rxjs/operators'

@Component({
  selector: 'alliance-notification-shell-alert-lazy',
  template: '',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LazyShellAlertComponent
  extends RxStateComponent<{
    isMobileScreen: boolean
  }>
  implements AfterViewInit {
  private componentRef: ComponentRef<unknown> | null = null

  public constructor(
    private _viewContainerRef: ViewContainerRef,
    private _injector: Injector,
    private mediaService: MediaService,
    private ngZoneHelper: NgZoneHelperService,
    private platform: DetectPlatformService
  ) {
    super()

    this.initState({
      isMobileScreen: this.mediaService.select('isMobileScreen')
    })
  }

  public ngAfterViewInit(): void {
    if (this.platform.isBrowser) {
      this.hold(
        timer(1000, asyncScheduler).pipe(
          this.ngZoneHelper.outsideNgZone(),
          switchMap(() => from(import('@alliance/notification/feature-alert'))),
          retry(2),
          catchError(() => of(null)),
          filter(Boolean),
          switchMap(({ NotificationFeatureAlertModule }) =>
            this.select('isMobileScreen').pipe(
              map(isMobile => {
                const injector = createInjector(NotificationFeatureAlertModule, this._injector)
                const module = injector.get(NotificationFeatureAlertModule)

                this.componentRef?.destroy()
                this._viewContainerRef.detach()
                this._viewContainerRef.clear()

                return isMobile ? module.renderLazyMobileWrap(this._viewContainerRef) : module.renderLazyDesktopWrap(this._viewContainerRef)
              })
            )
          )
        ),
        componentRef => (this.componentRef = componentRef)
      )
    }
  }
}
