import { ActionStatusMessageMetaType, ActionTypesEnum, channelTypes, NotificationService, NotificationTypeEnum } from '@alliance/notification/api'
import { AuthService } from '@alliance/shared/auth/api'
import { RxStateService } from '@alliance/shared/models'
import { SnackbarService } from '@alliance/shared/santa'
import { MediaService } from '@alliance/shared/utils'
import { SignalrMessageService, StreamTypeMap } from '@alliance/socket/api'
import { Injectable, Injector } from '@angular/core'
import { of } from 'rxjs'
import { switchMap } from 'rxjs/operators'
import { EmployerBannerNotificationsSocketMessage } from './models/employer-banner-notifications-socket-message'

@Injectable({ providedIn: 'root' })
export class EmployerBannerNotificationsService extends RxStateService<{
  messageStream: EmployerBannerNotificationsSocketMessage | null
}> {
  public constructor(private injector: Injector, private authService: AuthService, private signalrMessageService: SignalrMessageService, private mediaService: MediaService) {
    super()

    this.initState({
      messageStream: this.authService.token$.pipe(
        switchMap(token =>
          !!token && this.authService.isEmployer ? this.signalrMessageService.getStreamByType<EmployerBannerNotificationsSocketMessage>(StreamTypeMap.bannerEmployerMessage) : of(null)
        )
      )
    })
  }

  private get notificationService(): NotificationService {
    return this.injector.get(NotificationService)
  }

  private get snackbarService(): SnackbarService {
    return this.injector.get(SnackbarService)
  }

  public subscribeToEmployerBannerNotifications(): void {
    this.hold(this.select('messageStream'), message => {
      if (!message?.text) {
        return
      }

      const { text } = message

      this.mediaService.isMobileScreen()
        ? this.snackbarService.openByComponent(null, { value: text })
        : this.notificationService.dispatch<ActionStatusMessageMetaType>({
            type: NotificationTypeEnum.CREATED,
            payload: {
              channel: channelTypes.USER_ACTIONS,
              body: text,
              meta: {
                status: ActionTypesEnum.SUCCESS
              }
            }
          })
    })
  }
}
