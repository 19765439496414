import { ImageAttributesNameEnum, ImageAttributesType } from '@alliance/shared/models'
import { TranslationService } from '@alliance/shared/translation'
import { Injectable } from '@angular/core'
import { Translations } from '../localization/translations'

@Injectable()
export class ImageAttributesHelperService {
  public constructor(private translations: Translations, private translationService: TranslationService) {}

  public getCompanyLogoAttributes(companyName: string, imageOrder: number | null = null): ImageAttributesType {
    const { alt, title } = this.translations.imageAttributes.companyLogo
    return {
      [ImageAttributesNameEnum.alt]: this.translationService.translate(alt, { companyName, imageOrder }),
      [ImageAttributesNameEnum.title]: this.translationService.translate(title, { companyName, imageOrder })
    }
  }

  public getCompanyImageAttributes(companyName: string, imageOrder: number | null = null): ImageAttributesType {
    const { alt, title } = this.translations.imageAttributes.companyImage
    return {
      [ImageAttributesNameEnum.alt]: this.translationService.translate(alt, { companyName, imageOrder }),
      [ImageAttributesNameEnum.title]: this.translationService.translate(title, { companyName, imageOrder })
    }
  }

  public getVacancyImageAttributes(companyName: string, vacancyName: string, imageOrder: number | null = null): ImageAttributesType {
    const { alt, title } = this.translations.imageAttributes.vacancyImage
    return {
      [ImageAttributesNameEnum.alt]: this.translationService.translate(alt, { companyName, vacancyName, imageOrder }),
      [ImageAttributesNameEnum.title]: this.translationService.translate(title, { companyName, vacancyName, imageOrder })
    }
  }
}
