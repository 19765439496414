/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core'
import { HttpClient, HttpResponse, HttpContext } from '@angular/common/http'
import { BaseService } from '../base-service'
import { ApplyApiDataAccessConfiguration } from '../apply-api-data-access-configuration'
import { StrictHttpResponse } from '../strict-http-response'
import { RequestBuilder } from '../request-builder'
import { Observable } from 'rxjs'
import { map, filter } from 'rxjs/operators'

import { ComparisonFilterMode } from '../models/comparison-filter-mode'

@Injectable({
  providedIn: 'root'
})
export class ApplyCandidatesComparisonService extends BaseService {
  public constructor(config: ApplyApiDataAccessConfiguration, http: HttpClient) {
    super(config, http)
  }

  /**
   * Path part for operation candidatesComparisonForAllLastResultsGet
   */
  public static readonly CandidatesComparisonForAllLastResultsGetPath = '/candidates-comparison/for-all/last-results'

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `candidatesComparisonForAllLastResultsGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  candidatesComparisonForAllLastResultsGet$Response(params?: { context?: HttpContext }): Observable<StrictHttpResponse<void>> {
    const rb = new RequestBuilder(this.rootUrl, ApplyCandidatesComparisonService.CandidatesComparisonForAllLastResultsGetPath, 'get')
    if (params) {
    }

    return this.http
      .request(
        rb.build({
          responseType: 'text',
          accept: '*/*',
          context: params?.context
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>
        })
      )
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `candidatesComparisonForAllLastResultsGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  candidatesComparisonForAllLastResultsGet(params?: { context?: HttpContext }): Observable<void> {
    return this.candidatesComparisonForAllLastResultsGet$Response(params).pipe(map((r: StrictHttpResponse<void>) => r.body as void))
  }

  /**
   * Path part for operation candidatesComparisonForAllLastResultsDelete
   */
  public static readonly CandidatesComparisonForAllLastResultsDeletePath = '/candidates-comparison/for-all/last-results'

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `candidatesComparisonForAllLastResultsDelete()` instead.
   *
   * This method doesn't expect any request body.
   */
  candidatesComparisonForAllLastResultsDelete$Response(params?: { context?: HttpContext }): Observable<StrictHttpResponse<void>> {
    const rb = new RequestBuilder(this.rootUrl, ApplyCandidatesComparisonService.CandidatesComparisonForAllLastResultsDeletePath, 'delete')
    if (params) {
    }

    return this.http
      .request(
        rb.build({
          responseType: 'text',
          accept: '*/*',
          context: params?.context
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>
        })
      )
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `candidatesComparisonForAllLastResultsDelete$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  candidatesComparisonForAllLastResultsDelete(params?: { context?: HttpContext }): Observable<void> {
    return this.candidatesComparisonForAllLastResultsDelete$Response(params).pipe(map((r: StrictHttpResponse<void>) => r.body as void))
  }

  /**
   * Path part for operation candidatesComparisonForAllForcedFinishPut
   */
  public static readonly CandidatesComparisonForAllForcedFinishPutPath = '/candidates-comparison/for-all/forced-finish'

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `candidatesComparisonForAllForcedFinishPut()` instead.
   *
   * This method doesn't expect any request body.
   */
  candidatesComparisonForAllForcedFinishPut$Response(params?: { context?: HttpContext }): Observable<StrictHttpResponse<void>> {
    const rb = new RequestBuilder(this.rootUrl, ApplyCandidatesComparisonService.CandidatesComparisonForAllForcedFinishPutPath, 'put')
    if (params) {
    }

    return this.http
      .request(
        rb.build({
          responseType: 'text',
          accept: '*/*',
          context: params?.context
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>
        })
      )
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `candidatesComparisonForAllForcedFinishPut$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  candidatesComparisonForAllForcedFinishPut(params?: { context?: HttpContext }): Observable<void> {
    return this.candidatesComparisonForAllForcedFinishPut$Response(params).pipe(map((r: StrictHttpResponse<void>) => r.body as void))
  }

  /**
   * Path part for operation candidatesComparisonForAllPost
   */
  public static readonly CandidatesComparisonForAllPostPath = '/candidates-comparison/for-all'

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `candidatesComparisonForAllPost()` instead.
   *
   * This method doesn't expect any request body.
   */
  candidatesComparisonForAllPost$Response(params?: { filterMode?: ComparisonFilterMode; context?: HttpContext }): Observable<StrictHttpResponse<void>> {
    const rb = new RequestBuilder(this.rootUrl, ApplyCandidatesComparisonService.CandidatesComparisonForAllPostPath, 'post')
    if (params) {
      rb.query('filterMode', params.filterMode, {})
    }

    return this.http
      .request(
        rb.build({
          responseType: 'text',
          accept: '*/*',
          context: params?.context
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>
        })
      )
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `candidatesComparisonForAllPost$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  candidatesComparisonForAllPost(params?: { filterMode?: ComparisonFilterMode; context?: HttpContext }): Observable<void> {
    return this.candidatesComparisonForAllPost$Response(params).pipe(map((r: StrictHttpResponse<void>) => r.body as void))
  }

  /**
   * Path part for operation candidatesComparisonForCompaniesPost
   */
  public static readonly CandidatesComparisonForCompaniesPostPath = '/candidates-comparison/for-companies'

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `candidatesComparisonForCompaniesPost()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  candidatesComparisonForCompaniesPost$Response(params?: { filterMode?: ComparisonFilterMode; context?: HttpContext; body?: Array<number> }): Observable<StrictHttpResponse<void>> {
    const rb = new RequestBuilder(this.rootUrl, ApplyCandidatesComparisonService.CandidatesComparisonForCompaniesPostPath, 'post')
    if (params) {
      rb.query('filterMode', params.filterMode, {})
      rb.body(params.body, 'application/*+json')
    }

    return this.http
      .request(
        rb.build({
          responseType: 'text',
          accept: '*/*',
          context: params?.context
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>
        })
      )
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `candidatesComparisonForCompaniesPost$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  candidatesComparisonForCompaniesPost(params?: { filterMode?: ComparisonFilterMode; context?: HttpContext; body?: Array<number> }): Observable<void> {
    return this.candidatesComparisonForCompaniesPost$Response(params).pipe(map((r: StrictHttpResponse<void>) => r.body as void))
  }

  /**
   * Path part for operation candidatesComparisonForVacancyPost
   */
  public static readonly CandidatesComparisonForVacancyPostPath = '/candidates-comparison/for-vacancy'

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `candidatesComparisonForVacancyPost()` instead.
   *
   * This method doesn't expect any request body.
   */
  candidatesComparisonForVacancyPost$Response(params?: { vacancyId?: number; context?: HttpContext }): Observable<StrictHttpResponse<void>> {
    const rb = new RequestBuilder(this.rootUrl, ApplyCandidatesComparisonService.CandidatesComparisonForVacancyPostPath, 'post')
    if (params) {
      rb.query('vacancyId', params.vacancyId, {})
    }

    return this.http
      .request(
        rb.build({
          responseType: 'text',
          accept: '*/*',
          context: params?.context
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>
        })
      )
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `candidatesComparisonForVacancyPost$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  candidatesComparisonForVacancyPost(params?: { vacancyId?: number; context?: HttpContext }): Observable<void> {
    return this.candidatesComparisonForVacancyPost$Response(params).pipe(map((r: StrictHttpResponse<void>) => r.body as void))
  }
}
