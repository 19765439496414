/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http'
import { Observable } from 'rxjs'
import { filter, map } from 'rxjs/operators'
import { StrictHttpResponse } from '../../strict-http-response'
import { RequestBuilder } from '../../request-builder'

import { MassActionsResponse } from '../../models/mass-actions-response'
import { UserConversationsModel } from '../../models/user-conversations-model'

export interface ConversationMassActionsArchiveByUser$Json$Params {
  body?: UserConversationsModel
}

export function conversationMassActionsArchiveByUser$Json(
  http: HttpClient,
  rootUrl: string,
  params?: ConversationMassActionsArchiveByUser$Json$Params,
  context?: HttpContext
): Observable<StrictHttpResponse<MassActionsResponse>> {
  const rb = new RequestBuilder(rootUrl, conversationMassActionsArchiveByUser$Json.PATH, 'post')
  if (params) {
    rb.body(params.body, 'application/*+json')
  }

  return http.request(rb.build({ responseType: 'json', accept: 'text/json', context })).pipe(
    filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
    map((r: HttpResponse<any>) => {
      return r as StrictHttpResponse<MassActionsResponse>
    })
  )
}

conversationMassActionsArchiveByUser$Json.PATH = '/v1/conversation-mass-actions/archive-by-user'
