import { DataLayerPushEvent } from '@alliance/shared/utils'

export const VACANCIES_RISING_UP_EVENT: DataLayerPushEvent = {
  eventCategory: 'EMP_Vacancy',
  event: 'EMP_Vacancy',
  eventAction: 'TryToMakeRisingUp',
  eventLabel: null,
  eventContent: null
}

export const VACANCIES_BUY_RISING_UP_EVENT: DataLayerPushEvent = {
  eventCategory: 'EMP_Vacancy',
  event: 'EMP_Vacancy',
  eventAction: 'BuyRisingUp',
  eventLabel: null,
  eventContent: null
}

export const VACANCIES_MOVE_UP_ACTIVATE_SERVICE_EVENT: DataLayerPushEvent = {
  eventCategory: 'EMP_Vacancy',
  event: 'EMP_Vacancy',
  eventAction: 'ActivateRisingUp',
  eventLabel: null,
  eventContent: null
}

export const VACANCIES_NOT_ACTIVATE_RISING_UP_EVENT: DataLayerPushEvent = {
  eventCategory: 'EMP_Vacancy',
  event: 'EMP_Vacancy',
  eventAction: 'NotActivateRisingUp',
  eventLabel: null,
  eventContent: null
}
