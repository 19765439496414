import { Environment } from '@alliance/shared/environment'

// take from https://raw.githubusercontent.com/cyclosproject/ng-openapi-gen/master/templates/module.handlebars
// and comment code from constructor and below

import { NgModule, ModuleWithProviders } from '@angular/core'

import { CompanyApiDataAccessConfiguration, CompanyApiDataAccessConfigurationParams } from './company-api-data-access-configuration'

import { CompanyApiService } from './services/company-api.service'

/**
 * Module that provides all services and configuration.
 */
@NgModule({
  imports: [],
  exports: [],
  declarations: [],
  providers: [
    CompanyApiService,
    {
      provide: CompanyApiDataAccessConfiguration,
      useFactory: (environment: Environment): CompanyApiDataAccessConfigurationParams => ({
        rootUrl: environment.companyServiceApi
      }),
      deps: [Environment]
    }
  ]
})
export class CompanyApiDataAccessModule {
  public static forRoot(params: CompanyApiDataAccessConfigurationParams): ModuleWithProviders<CompanyApiDataAccessModule> {
    return {
      ngModule: CompanyApiDataAccessModule,
      providers: [
        {
          provide: CompanyApiDataAccessConfiguration,
          useValue: params
        }
      ]
    }
  }

  /*
    public constructor(
      @Optional() @SkipSelf() parentModule: CompanyApiDataAccessModule,
      @Optional() http: HttpClient
    ) {

      if (parentModule) {
        throw new Error('CompanyApiDataAccessModule is already loaded. Import in your base AppModule only.');
      }
      if (!http) {
        throw new Error('You need to import the HttpClientModule in your AppModule! \n' +
          'See also https://github.com/angular/angular/issues/20575');
      }

    } */
}
