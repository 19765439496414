import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { AppPromoBannerComponent } from './app-promo-banner.component'
import { MobileModalService, ModalOverlayRef } from '@alliance/shared/santa'
import { SantaButtonModule } from '@alliance/santa/button'
import { SharedIconsModule } from '@alliance/shared/icons'
import { SharedTranslationModule } from '@alliance/shared/translation'

@NgModule({
  imports: [CommonModule, SantaButtonModule, SharedIconsModule, SharedTranslationModule],
  declarations: [AppPromoBannerComponent]
})
export class JobseekerAppPromoBannerModule {
  public constructor(public mobileModalService: MobileModalService) {}

  public openMapModal(): ModalOverlayRef {
    return this.mobileModalService.openByComponent(AppPromoBannerComponent, null, true)
  }
}
