import { CvdbService, TicketService4Detail } from '@alliance/employer/data-access'
import { ResumeContactsFilters, ResumeContactsScenariosEnum } from './models/open-contacts'

export class BillingModel {
  public vacancyServices: TicketService4Detail[] = []
  public contactServices: CvdbService[] = []
  public contactServicesToActivate: CvdbService[] = []
  public suitableActivatedServices: CvdbService[] = []
  //
  public contactScenario: ResumeContactsScenariosEnum | null = null
  public contactFilter: ResumeContactsFilters | null = null
  public currentContactsService: CvdbService | null = null
  //
  public pending: BillingStorePendingModel = {
    currentContactsService: false,
    openContactsCount: false
  }
  public resumeServicesOpenContactsCount = 0
}

export interface BillingStorePendingModel {
  currentContactsService: boolean
  openContactsCount: boolean
}
