export interface CvContact {
  id?: number
  typeId?: CvContact.TypeIdEnum
  subTypeId?: CvContact.SubTypeIdEnum
  description?: string
}

export namespace CvContact {
  export type TypeIdEnum = 'Phone' | 'Portfolio' | 'SocialNetwork'
  export const TypeIdEnum = {
    Phone: 'Phone' as TypeIdEnum,
    Portfolio: 'Portfolio' as TypeIdEnum,
    SocialNetwork: 'SocialNetwork' as TypeIdEnum
  }
  export type SubTypeIdEnum = 'facebook' | 'googleplus' | 'linkedin' | 'none' | 'ok' | 'twitter' | 'vk' | 'telegram' | 'viber' | 'instagram'
  export const SubTypeIdEnum = {
    Facebook: 'facebook' as SubTypeIdEnum,
    Googleplus: 'googleplus' as SubTypeIdEnum,
    Linkedin: 'linkedin' as SubTypeIdEnum,
    None: 'none' as SubTypeIdEnum,
    Ok: 'ok' as SubTypeIdEnum,
    Twitter: 'twitter' as SubTypeIdEnum,
    Vk: 'vk' as SubTypeIdEnum,
    Telegram: 'telegram' as SubTypeIdEnum,
    Viber: 'viber' as SubTypeIdEnum,
    Instagram: 'instagram' as SubTypeIdEnum
  }
}
