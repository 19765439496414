import { Injectable } from '@angular/core'
import { Observable, of } from 'rxjs'
import { PartialSeoParamsResponse, PlatformHosts } from '../models'
import { Translations } from '../localization/translations'
import { TranslationService } from '@alliance/shared/translation'
import { HelpersService } from '../helpers.service'
import { SearchAction, WebSite } from 'schema-dts'

@Injectable({
  providedIn: 'root'
})
export class HomePageDictionaryService {
  public constructor(private translations: Translations, private translationService: TranslationService, private helpersService: HelpersService) {}
  public getJsonLd(platform: PlatformHosts): string {
    return this.helpersService.createJsonLd<WebSite>(platform, [
      {
        '@context': 'https://schema.org',
        '@type': 'WebSite',
        url: this.helpersService.createURL(platform, '/'),
        potentialAction: [
          {
            '@type': 'SearchAction',
            target: 'https://robota.ua/zapros/{search_term_string}/ukraine'
          } as SearchAction
        ]
      },
      {
        '@context': 'https://schema.org',
        '@type': 'Organization',
        url: this.helpersService.createURL(platform, '/'),
        logo: 'https://images.cf-rabota.com.ua/emails/logo-robota-ua.png'
      }
    ])
  }

  public getParams(): Observable<PartialSeoParamsResponse> {
    const url = '/'

    return of({
      title: this.translationService.translate(this.translations.home.title),
      description: this.translationService.translate(this.translations.home.description),
      canonicalUrl: this.helpersService.createURL(PlatformHosts.desktop, url),
      hrefLang: this.helpersService.createHrefLangURLs(url, url),
      noIndexNoFollow: false,
      jsonLd: {
        mobile: this.getJsonLd(PlatformHosts.desktop),
        desktop: this.getJsonLd(PlatformHosts.desktop)
      }
    })
  }
}
