/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core'
import { HttpClient, HttpResponse } from '@angular/common/http'
import { BaseService } from '../base-service'
import { CompanyApiDataAccessConfiguration } from '../company-api-data-access-configuration'
import { StrictHttpResponse } from '../strict-http-response'
import { RequestBuilder } from '../request-builder'
import { Observable } from 'rxjs'
import { map, filter } from 'rxjs/operators'

import { CompanyFilesUploadOutput } from '../models/company-files-upload-output'

@Injectable({
  providedIn: 'root'
})
export class CompanyApiService extends BaseService {
  public constructor(config: CompanyApiDataAccessConfiguration, http: HttpClient) {
    super(config, http)
  }

  /**
   * Path part for operation apiCompanyEditInfoVerificationFilePost
   */
  public static readonly ApiCompanyEditInfoVerificationFilePostPath = '/api/company-edit-info/verification-file'

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiCompanyEditInfoVerificationFilePost$Plain()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  apiCompanyEditInfoVerificationFilePost$Plain$Response(params?: {
    body?: {
      file?: Blob | null
    }
  }): Observable<StrictHttpResponse<CompanyFilesUploadOutput>> {
    const rb = new RequestBuilder(this.rootUrl, CompanyApiService.ApiCompanyEditInfoVerificationFilePostPath, 'post')
    if (params) {
      rb.body(params.body, 'multipart/form-data')
    }

    return this.http
      .request(
        rb.build({
          responseType: 'text',
          accept: 'text/plain'
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<CompanyFilesUploadOutput>
        })
      )
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiCompanyEditInfoVerificationFilePost$Plain$Response()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  apiCompanyEditInfoVerificationFilePost$Plain(params?: {
    body?: {
      file?: Blob | null
    }
  }): Observable<CompanyFilesUploadOutput> {
    return this.apiCompanyEditInfoVerificationFilePost$Plain$Response(params).pipe(map((r: StrictHttpResponse<CompanyFilesUploadOutput>) => r.body as CompanyFilesUploadOutput))
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiCompanyEditInfoVerificationFilePost$Json()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  apiCompanyEditInfoVerificationFilePost$Json$Response(params?: {
    body?: {
      file?: Blob | null
    }
  }): Observable<StrictHttpResponse<CompanyFilesUploadOutput>> {
    const rb = new RequestBuilder(this.rootUrl, CompanyApiService.ApiCompanyEditInfoVerificationFilePostPath, 'post')
    if (params) {
      rb.body(params.body, 'multipart/form-data')
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'text/json'
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<CompanyFilesUploadOutput>
        })
      )
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiCompanyEditInfoVerificationFilePost$Json$Response()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  apiCompanyEditInfoVerificationFilePost$Json(params?: {
    body?: {
      file?: Blob | null
    }
  }): Observable<CompanyFilesUploadOutput> {
    return this.apiCompanyEditInfoVerificationFilePost$Json$Response(params).pipe(map((r: StrictHttpResponse<CompanyFilesUploadOutput>) => r.body as CompanyFilesUploadOutput))
  }

  /**
   * Path part for operation apiCompanyEditInfoCompanyLogoPost
   */
  public static readonly ApiCompanyEditInfoCompanyLogoPostPath = '/api/company-edit-info/company-logo'

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiCompanyEditInfoCompanyLogoPost$Plain()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  apiCompanyEditInfoCompanyLogoPost$Plain$Response(params?: {
    body?: {
      file?: Blob | null
    }
  }): Observable<StrictHttpResponse<CompanyFilesUploadOutput>> {
    const rb = new RequestBuilder(this.rootUrl, CompanyApiService.ApiCompanyEditInfoCompanyLogoPostPath, 'post')
    if (params) {
      rb.body(params.body, 'multipart/form-data')
    }

    return this.http
      .request(
        rb.build({
          responseType: 'text',
          accept: 'text/plain'
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<CompanyFilesUploadOutput>
        })
      )
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiCompanyEditInfoCompanyLogoPost$Plain$Response()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  apiCompanyEditInfoCompanyLogoPost$Plain(params?: {
    body?: {
      file?: Blob | null
    }
  }): Observable<CompanyFilesUploadOutput> {
    return this.apiCompanyEditInfoCompanyLogoPost$Plain$Response(params).pipe(map((r: StrictHttpResponse<CompanyFilesUploadOutput>) => r.body as CompanyFilesUploadOutput))
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiCompanyEditInfoCompanyLogoPost$Json()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  apiCompanyEditInfoCompanyLogoPost$Json$Response(params?: {
    body?: {
      file?: Blob | null
    }
  }): Observable<StrictHttpResponse<CompanyFilesUploadOutput>> {
    const rb = new RequestBuilder(this.rootUrl, CompanyApiService.ApiCompanyEditInfoCompanyLogoPostPath, 'post')
    if (params) {
      rb.body(params.body, 'multipart/form-data')
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'text/json'
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<CompanyFilesUploadOutput>
        })
      )
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiCompanyEditInfoCompanyLogoPost$Json$Response()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  apiCompanyEditInfoCompanyLogoPost$Json(params?: {
    body?: {
      file?: Blob | null
    }
  }): Observable<CompanyFilesUploadOutput> {
    return this.apiCompanyEditInfoCompanyLogoPost$Json$Response(params).pipe(map((r: StrictHttpResponse<CompanyFilesUploadOutput>) => r.body as CompanyFilesUploadOutput))
  }
}
