import { RxStateService } from '@alliance/shared/models'
import { ComponentPortal, TemplatePortal } from '@angular/cdk/portal'
import { Injectable, Injector, TemplateRef, Type } from '@angular/core'
import { asapScheduler, distinctUntilChanged, filter, Observable, take, timer } from 'rxjs'
import { HEADER_BOTTOM_CONTENT_DATA } from '../tokens/header-content-data.token'

@Injectable({ providedIn: 'root' })
export class HeaderHelperService extends RxStateService<{
  headerOverlayCreated: boolean
  bottomContent: ComponentPortal<unknown> | TemplatePortal<unknown> | null
}> {
  public constructor() {
    super()

    this.initState({
      bottomContent: null,
      headerOverlayCreated: false
    })
  }

  public setBottomContentByComponent<T, K>(content: Type<T>, data: K | null = null): void {
    const portal = new ComponentPortal<T>(content, null, this.createInjector<K | null>(data))

    timer(0, asapScheduler).subscribe(() => {
      this.set({ bottomContent: portal })
    })
  }

  public setBottomContentByTemplate<T>(content: TemplateRef<T>): void {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const portal = new TemplatePortal<T>(content, null)

    timer(0, asapScheduler).subscribe(() => {
      this.set({ bottomContent: portal })
    })
  }

  public clearBottomContent(): void {
    this.set({ bottomContent: null })
  }

  public setOverlayCreated(): void {
    this.set({ headerOverlayCreated: true })
  }

  public headerOverlayCreated$(): Observable<boolean> {
    return this.select('headerOverlayCreated').pipe(
      filter(created => created),
      distinctUntilChanged(),
      take(1)
    )
  }

  private createInjector<K>(data: K): Injector {
    return Injector.create({
      providers: [
        {
          provide: HEADER_BOTTOM_CONTENT_DATA,
          useValue: data
        }
      ]
    })
  }
}
