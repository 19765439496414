import { logFactory } from '@alliance/shared/logger'
import { Observable } from 'rxjs'
import { tap } from 'rxjs/operators'

export const logger = logFactory({ where: 'shared-utils: logObservable', message: 'logObservable for' })

export const logObservable = (name?: string): (<T>(source$: Observable<T>) => Observable<T>) => <T>(source$: Observable<T>): Observable<T> =>
  source$.pipe(
    tap(
      data => logger.log({ data, ...(name && { name }) }),
      e => logger.error({ category: 'unexpected_value', error: e, ...(name && { name }) })
    )
  )
