import { Injectable } from '@angular/core'
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http'
import { BaseService as __BaseService } from '../base-service'
import { JobseekerDataAccessConfiguration as __Configuration } from '../jobseeker-data-access-configuration'
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response'
import { Observable as __Observable } from 'rxjs'
import { map as __map, filter as __filter } from 'rxjs/operators'

import { ChatWizardResumeDto } from '../models/chat-wizard-resume-dto'
import { WizardResumeDTO } from '../models/wizard-resume-dto'
@Injectable({
  providedIn: 'root'
})
class WizardService extends __BaseService {
  static readonly chatResumeCreateResumeFromChatPath = '/wizard-chat/resume'
  static readonly chatResumeUpdateResumeFromChatPath = '/wizard-chat/resume/{resumeId}'
  static readonly chatResumeCreateResumeFromChatTempPath = '/wizard-chat/resume-validated'
  static readonly resumeWizardCreatePath = '/wizard/create'
  static readonly resumeWizardUpdatePath = '/wizard/update'
  static readonly resumeWizardPostPath = '/wizard/parse'

  constructor(config: __Configuration, http: HttpClient) {
    super(config, http)
  }

  /**
   * @param chatResume undefined
   * @return OK
   */
  chatResumeCreateResumeFromChatResponse(chatResume: ChatWizardResumeDto): __Observable<__StrictHttpResponse<{}>> {
    let __params = this.newParams()
    let __headers = new HttpHeaders()
    let __body: any = null
    __body = chatResume
    let req = new HttpRequest<any>('POST', this.rootUrl + `/wizard-chat/resume`, __body, {
      headers: __headers,
      params: __params,
      responseType: 'json'
    })

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map(_r => {
        return _r as __StrictHttpResponse<{}>
      })
    )
  }

  /**
   * @param chatResume undefined
   * @return OK
   */
  chatResumeCreateResumeFromChat(chatResume: ChatWizardResumeDto): __Observable<{}> {
    return this.chatResumeCreateResumeFromChatResponse(chatResume).pipe(__map(_r => _r.body as {}))
  }

  /**
   * @param params The `WizardService.ChatResumeUpdateResumeFromChatParams` containing the following parameters:
   *
   * - `resumeId`:
   *
   * - `chatResume`:
   *
   * @return OK
   */
  chatResumeUpdateResumeFromChatResponse(params: WizardService.ChatResumeUpdateResumeFromChatParams): __Observable<__StrictHttpResponse<{}>> {
    let __params = this.newParams()
    let __headers = new HttpHeaders()
    let __body: any = null

    __body = params.chatResume
    let req = new HttpRequest<any>('POST', this.rootUrl + `/wizard-chat/resume/${params.resumeId}`, __body, {
      headers: __headers,
      params: __params,
      responseType: 'json'
    })

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map(_r => {
        return _r as __StrictHttpResponse<{}>
      })
    )
  }

  /**
   * @param params The `WizardService.ChatResumeUpdateResumeFromChatParams` containing the following parameters:
   *
   * - `resumeId`:
   *
   * - `chatResume`:
   *
   * @return OK
   */
  chatResumeUpdateResumeFromChat(params: WizardService.ChatResumeUpdateResumeFromChatParams): __Observable<{}> {
    return this.chatResumeUpdateResumeFromChatResponse(params).pipe(__map(_r => _r.body as {}))
  }

  /**
   * @param chatResume undefined
   * @return OK
   */
  chatResumeCreateResumeFromChatTempResponse(chatResume: ChatWizardResumeDto): __Observable<__StrictHttpResponse<{}>> {
    let __params = this.newParams()
    let __headers = new HttpHeaders()
    let __body: any = null
    __body = chatResume
    let req = new HttpRequest<any>('POST', this.rootUrl + `/wizard-chat/resume-validated`, __body, {
      headers: __headers,
      params: __params,
      responseType: 'json'
    })

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map(_r => {
        return _r as __StrictHttpResponse<{}>
      })
    )
  }

  /**
   * @param chatResume undefined
   * @return OK
   */
  chatResumeCreateResumeFromChatTemp(chatResume: ChatWizardResumeDto): __Observable<{}> {
    return this.chatResumeCreateResumeFromChatTempResponse(chatResume).pipe(__map(_r => _r.body as {}))
  }

  /**
   * @param resumeDto undefined
   * @return OK
   */
  resumeWizardCreateResponse(resumeDto: WizardResumeDTO): __Observable<__StrictHttpResponse<{}>> {
    let __params = this.newParams()
    let __headers = new HttpHeaders()
    let __body: any = null
    __body = resumeDto
    let req = new HttpRequest<any>('POST', this.rootUrl + `/wizard/create`, __body, {
      headers: __headers,
      params: __params,
      responseType: 'json'
    })

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map(_r => {
        return _r as __StrictHttpResponse<{}>
      })
    )
  }

  /**
   * @param resumeDto undefined
   * @return OK
   */
  resumeWizardCreate(resumeDto: WizardResumeDTO): __Observable<{}> {
    return this.resumeWizardCreateResponse(resumeDto).pipe(__map(_r => _r.body as {}))
  }

  /**
   * @param params The `WizardService.ResumeWizardUpdateParams` containing the following parameters:
   *
   * - `resumeId`:
   *
   * - `resumeDto`:
   *
   * @return OK
   */
  resumeWizardUpdateResponse(params: WizardService.ResumeWizardUpdateParams): __Observable<__StrictHttpResponse<{}>> {
    let __params = this.newParams()
    let __headers = new HttpHeaders()
    let __body: any = null
    if (params.resumeId != null) __params = __params.set('resumeId', params.resumeId.toString())
    __body = params.resumeDto
    let req = new HttpRequest<any>('PUT', this.rootUrl + `/wizard/update`, __body, {
      headers: __headers,
      params: __params,
      responseType: 'json'
    })

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map(_r => {
        return _r as __StrictHttpResponse<{}>
      })
    )
  }

  /**
   * @param params The `WizardService.ResumeWizardUpdateParams` containing the following parameters:
   *
   * - `resumeId`:
   *
   * - `resumeDto`:
   *
   * @return OK
   */
  resumeWizardUpdate(params: WizardService.ResumeWizardUpdateParams): __Observable<{}> {
    return this.resumeWizardUpdateResponse(params).pipe(__map(_r => _r.body as {}))
  }

  /**
   * @return OK
   */
  resumeWizardPostResponse(): __Observable<__StrictHttpResponse<{}>> {
    let __params = this.newParams()
    let __headers = new HttpHeaders()
    let __body: any = null
    let req = new HttpRequest<any>('POST', this.rootUrl + `/wizard/parse`, __body, {
      headers: __headers,
      params: __params,
      responseType: 'json'
    })

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map(_r => {
        return _r as __StrictHttpResponse<{}>
      })
    )
  }

  /**
   * @return OK
   */
  resumeWizardPost(): __Observable<{}> {
    return this.resumeWizardPostResponse().pipe(__map(_r => _r.body as {}))
  }
}

module WizardService {
  /**
   * Parameters for ChatResumeUpdateResumeFromChat
   */
  export interface ChatResumeUpdateResumeFromChatParams {
    resumeId: number
    chatResume: ChatWizardResumeDto
  }

  /**
   * Parameters for ResumeWizardUpdate
   */
  export interface ResumeWizardUpdateParams {
    resumeId: number
    resumeDto: WizardResumeDTO
  }
}

export { WizardService }
