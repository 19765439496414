import { Injectable } from '@angular/core'
import { catchError, map, Observable, of } from 'rxjs'

import { getNonNullableItems } from '@alliance/shared/utils'
import { getOperationName } from '@apollo/client/utilities'
import { GetAllPublicationServicesDocument } from '@alliance/employer/gql-domain'

import { ApproveVacancyGQL } from './approve-vacancy.generated'
import { ApproveVacancyParams } from '../../../models/vacancy-actions/approve-vacancy/approve-vacancy-params.interface'
import { ApproveVacancyResponse } from '../../../models/vacancy-actions/approve-vacancy/approve-vacancy-response.interface'

@Injectable()
export class ApproveVacancyService {
  public constructor(private readonly approveVacancyGQL: ApproveVacancyGQL) {}

  public approveVacancy$(params: ApproveVacancyParams): Observable<ApproveVacancyResponse | null> {
    if (!params.vacancies.length) {
      return of(null)
    }

    const vacancies = params.vacancies.map(({ id, publicationType }) => ({ vacancyId: +id, publicationType }))

    return this.approveVacancyGQL
      .mutate(
        {
          input: {
            vacancies,
            vacancyId: params.vacancies[0].id, // is going to be removed after  schema change
            publicationType: params.vacancies[0].publicationType // is going to be removed after schema change
          }
        },
        {
          refetchQueries: () => [getOperationName(GetAllPublicationServicesDocument) ?? '']
        }
      )
      .pipe(
        map(({ data }) => {
          const vacanciesList = getNonNullableItems(data?.vacancyPublish?.vacancies ?? [])
          if (vacanciesList.length) {
            return { vacancies: vacanciesList }
          }
          return null
        }),
        catchError(() => of(null))
      )
  }
}
