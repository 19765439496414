import { NotificationAlertRerenderConfigEventPayload, PubsubMessageTypesEnum } from '@alliance/shared/utils'
import { Component, ChangeDetectionStrategy, AfterViewInit } from '@angular/core'
import { ModalComponent } from '../modal/modal.component'

@Component({
  selector: 'santa-mobile-full-screen-modal',
  templateUrl: './mobile-full-screen-modal.component.html',
  styleUrls: ['./mobile-full-screen-modal.component.tw.css'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MobileFullScreenModalComponent<T> extends ModalComponent<T> implements AfterViewInit {
  public ngAfterViewInit(): void {
    this.transfer.publish<NotificationAlertRerenderConfigEventPayload>(PubsubMessageTypesEnum.rerenderNotificationAlert, {
      recreateOverlay: true
    })
  }
}
