import { Injectable } from '@angular/core'
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http'
import { BaseService as __BaseService } from '../base-service'
import { JobseekerDataAccessConfiguration as __Configuration } from '../jobseeker-data-access-configuration'
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response'
import { Observable as __Observable } from 'rxjs'
import { map as __map, filter as __filter } from 'rxjs/operators'

import { EducationInfoDTO } from '../models/education-info-dto'
import { CvStateInfo } from '../models/cv-state-info'
import { PersonalInfoDTO } from '../models/personal-info-dto'
import { LanguageAbilityInfoDTO } from '../models/language-ability-info-dto'
import { PositionInfoDTO } from '../models/position-info-dto'
import { ScheduleInfoDTO } from '../models/schedule-info-dto'
import { ExperienceInfoDTO } from '../models/experience-info-dto'
import { RecommendationInfoDTO } from '../models/recommendation-info-dto'
import { ResumeShortListItemDTO } from '../models/resume-short-list-item-dto'
import { ResumeShortInfoDto } from '../models/resume-short-info-dto'
import { UserModel } from '../models/user-model'
import { ResumeModel } from '../models/resume-model'
import { SkillInfoDTO } from '../models/skill-info-dto'
import { TrainingInfoDTO } from '../models/training-info-dto'
import { AdditionalInfoDTO } from '../models/additional-info-dto'
import { ContactInfoDTO } from '../models/contact-info-dto'
import { DiiaCertificateInfoDTO } from '../models/diia-certificate-info-dto'
@Injectable({
  providedIn: 'root'
})
class ResumeService extends __BaseService {
  public static readonly resumeEducationPostPath = '/resume/{resumeId}/education'
  public static readonly resumeEducationDeletePath = '/resume/{resumeId}/education/{educationId}'
  public static readonly resumeStateGetPath = '/resume/{resumeId}/state'
  public static readonly resumeStatePostPath = '/resume/{resumeId}/state'
  public static readonly resumeStatePost_1Path = '/resume/{resumeId}/date'
  public static readonly resumeStatePostSearchStatePath = '/resume/{resumeId}/searchstate'
  public static readonly resumePersonalPostPath = '/resume/{resumeId}/personal'
  public static readonly resumeLanguagePostPath = '/resume/{resumeId}/language'
  public static readonly resumeLanguageDeletePath = '/resume/{resumeId}/language'
  public static readonly resumeLanguageDelete_1Path = '/resume/{resumeId}/language/{languageId}'
  public static readonly resumePositionPostPath = '/resume/{resumeId}/position'
  public static readonly resumePositionPost_1Path = '/resume/{resumeId}/shedule'
  public static readonly resumeExperiencePostPath = '/resume/{resumeId}/experience'
  public static readonly resumeExperienceDeletePath = '/resume/{resumeId}/experience'
  public static readonly resumeExperiencePost_1Path = '/resume/{resumeId}/experience/{experienceId}/recommendation'
  public static readonly resumeExperienceDelete_1Path = '/resume/{resumeId}/experience/{experienceId}'
  public static readonly resumeExperienceDelete_2Path = '/resume/{resumeId}/experience/{experienceId}/recommendation/{recommendationId}'
  public static readonly resumeGetJobseekerResumeListPath = '/resume'
  public static readonly resumeCreateBlankResumePath = '/resume'
  public static readonly resumeCreateBlankResume_1Path = '/resume/bybot'
  public static readonly resumeGetPath = '/resume/{resumeId}'
  public static readonly resumeDeletePath = '/resume/{resumeId}'
  public static readonly resumeCopyPath = '/resume/{resumeId}/copy'
  public static readonly resumeConfirmResumeEmailPath = '/resume/{resumeId}/confirmemail'
  public static readonly resumeSkillPostPath = '/resume/{resumeId}/skill'
  public static readonly resumeSkillDeletePath = '/resume/{resumeId}/skill'
  public static readonly resumeTrainingPostPath = '/resume/{resumeId}/training'
  public static readonly resumeTrainingDeletePath = '/resume/{resumeId}/training/{trainingId}'
  public static readonly resumeAdditionalPostPath = '/resume/{resumeId}/additional'
  public static readonly resumeAdditionalDeletePath = '/resume/{resumeId}/additional/{additionalId}'
  public static readonly resumePhotoUploadResumePhotoPath = '/resume/{resumeId}/photo'
  public static readonly resumePhotoDeletePath = '/resume/{resumeId}/photo'
  public static readonly resumePhotoAddResumePhotoNamePath = '/resume/{resumeId}/photobyname'
  public static readonly resumeContactPostPath = '/resume/{resumeId}/contact'
  public static readonly resumeDiiaCertificatePostPath = '/resume/{resumeId}/diiacertificate'
  public static readonly resumeDiiaCertificateDeletePath = '/resume/{resumeId}/diiacertificate'
  public static readonly resumeUiLanguagePostPath = '/resume/{resumeId}/uilanguage'

  public constructor(config: __Configuration, http: HttpClient) {
    super(config, http)
  }

  /**
   * @param params The `ResumeService.ResumeEducationPostParams` containing the following parameters:
   *
   * - `resumeId`:
   *
   * - `data`:
   *
   * @return OK
   */
  resumeEducationPostResponse(params: ResumeService.ResumeEducationPostParams): __Observable<__StrictHttpResponse<number>> {
    let __params = this.newParams()
    let __headers = new HttpHeaders()
    let __body: any = null

    __body = params.data
    let req = new HttpRequest<any>('POST', this.rootUrl + `/resume/${params.resumeId}/education`, __body, {
      headers: __headers,
      params: __params,
      responseType: 'json'
    })

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map(_r => {
        return (_r as HttpResponse<any>).clone({
          body: parseFloat((_r as HttpResponse<any>).body as string)
        }) as __StrictHttpResponse<number>
      })
    )
  }

  /**
   * @param params The `ResumeService.ResumeEducationPostParams` containing the following parameters:
   *
   * - `resumeId`:
   *
   * - `data`:
   *
   * @return OK
   */
  resumeEducationPost(params: ResumeService.ResumeEducationPostParams): __Observable<number> {
    return this.resumeEducationPostResponse(params).pipe(__map(_r => _r.body as number))
  }

  /**
   * @param params The `ResumeService.ResumeEducationDeleteParams` containing the following parameters:
   *
   * - `resumeId`:
   *
   * - `educationId`:
   *
   * @return OK
   */
  resumeEducationDeleteResponse(params: ResumeService.ResumeEducationDeleteParams): __Observable<__StrictHttpResponse<number>> {
    let __params = this.newParams()
    let __headers = new HttpHeaders()
    let __body: any = null

    let req = new HttpRequest<any>('DELETE', this.rootUrl + `/resume/${params.resumeId}/education/${params.educationId}`, __body, {
      headers: __headers,
      params: __params,
      responseType: 'json'
    })

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map(_r => {
        return (_r as HttpResponse<any>).clone({
          body: parseFloat((_r as HttpResponse<any>).body as string)
        }) as __StrictHttpResponse<number>
      })
    )
  }

  /**
   * @param params The `ResumeService.ResumeEducationDeleteParams` containing the following parameters:
   *
   * - `resumeId`:
   *
   * - `educationId`:
   *
   * @return OK
   */
  resumeEducationDelete(params: ResumeService.ResumeEducationDeleteParams): __Observable<number> {
    return this.resumeEducationDeleteResponse(params).pipe(__map(_r => _r.body as number))
  }

  /**
   * @param resumeId undefined
   * @return OK
   */
  resumeStateGetResponse(resumeId: number): __Observable<__StrictHttpResponse<CvStateInfo>> {
    let __params = this.newParams()
    let __headers = new HttpHeaders()
    let __body: any = null

    let req = new HttpRequest<any>('GET', this.rootUrl + `/resume/${resumeId}/state`, __body, {
      headers: __headers,
      params: __params,
      responseType: 'json'
    })

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map(_r => {
        return _r as __StrictHttpResponse<CvStateInfo>
      })
    )
  }

  /**
   * @param resumeId undefined
   * @return OK
   */
  resumeStateGet(resumeId: number): __Observable<CvStateInfo> {
    return this.resumeStateGetResponse(resumeId).pipe(__map(_r => _r.body as CvStateInfo))
  }

  /**
   * @param params The `ResumeService.ResumeStatePostParams` containing the following parameters:
   *
   * - `resumeId`:
   *
   * - `data`:
   */
  resumeStatePostResponse(params: ResumeService.ResumeStatePostParams): __Observable<__StrictHttpResponse<any>> {
    let __params = this.newParams()
    let __headers = new HttpHeaders()
    let __body: any = null

    __body = params.data
    let req = new HttpRequest<any>('POST', this.rootUrl + `/resume/${params.resumeId}/state`, __body, {
      headers: __headers,
      params: __params,
      responseType: 'json'
    })

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map(_r => {
        return _r as __StrictHttpResponse<any>
      })
    )
  }

  /**
   * @param params The `ResumeService.ResumeStatePostParams` containing the following parameters:
   *
   * - `resumeId`:
   *
   * - `data`:
   */
  resumeStatePost(params: ResumeService.ResumeStatePostParams): __Observable<any> {
    return this.resumeStatePostResponse(params).pipe(__map(_r => _r.body as any))
  }

  /**
   * @param resumeId undefined
   */
  resumeStatePost_1Response(resumeId: number): __Observable<__StrictHttpResponse<any>> {
    let __params = this.newParams()
    let __headers = new HttpHeaders()
    let __body: any = null

    let req = new HttpRequest<any>('POST', this.rootUrl + `/resume/${resumeId}/date`, __body, {
      headers: __headers,
      params: __params,
      responseType: 'json'
    })

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map(_r => {
        return _r as __StrictHttpResponse<any>
      })
    )
  }

  /**
   * @param resumeId undefined
   */
  resumeStatePost_1(resumeId: number): __Observable<any> {
    return this.resumeStatePost_1Response(resumeId).pipe(__map(_r => _r.body as any))
  }

  /**
   * @param params The `ResumeService.ResumeStatePostSearchStateParams` containing the following parameters:
   *
   * - `state`:
   *
   * - `resumeId`:
   */
  resumeStatePostSearchStateResponse(params: ResumeService.ResumeStatePostSearchStateParams): __Observable<__StrictHttpResponse<any>> {
    let __params = this.newParams()
    let __headers = new HttpHeaders()
    let __body: any = null
    if (params.state != null) __params = __params.set('state', params.state.toString())

    let req = new HttpRequest<any>('POST', this.rootUrl + `/resume/${params.resumeId}/searchstate`, __body, {
      headers: __headers,
      params: __params,
      responseType: 'json'
    })

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map(_r => {
        return _r as __StrictHttpResponse<any>
      })
    )
  }

  /**
   * @param params The `ResumeService.ResumeStatePostSearchStateParams` containing the following parameters:
   *
   * - `state`:
   *
   * - `resumeId`:
   */
  resumeStatePostSearchState(params: ResumeService.ResumeStatePostSearchStateParams): __Observable<any> {
    return this.resumeStatePostSearchStateResponse(params).pipe(__map(_r => _r.body as any))
  }

  /**
   * @param params The `ResumeService.ResumePersonalPostParams` containing the following parameters:
   *
   * - `resumeId`:
   *
   * - `data`:
   */
  resumePersonalPostResponse(params: ResumeService.ResumePersonalPostParams): __Observable<__StrictHttpResponse<any>> {
    let __params = this.newParams()
    let __headers = new HttpHeaders()
    let __body: any = null

    __body = params.data
    let req = new HttpRequest<any>('POST', this.rootUrl + `/resume/${params.resumeId}/personal`, __body, {
      headers: __headers,
      params: __params,
      responseType: 'json'
    })

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map(_r => {
        return _r as __StrictHttpResponse<any>
      })
    )
  }

  /**
   * @param params The `ResumeService.ResumePersonalPostParams` containing the following parameters:
   *
   * - `resumeId`:
   *
   * - `data`:
   */
  resumePersonalPost(params: ResumeService.ResumePersonalPostParams): __Observable<any> {
    return this.resumePersonalPostResponse(params).pipe(__map(_r => _r.body as any))
  }

  /**
   * @param params The `ResumeService.ResumeLanguagePostParams` containing the following parameters:
   *
   * - `resumeId`:
   *
   * - `data`:
   */
  resumeLanguagePostResponse(params: ResumeService.ResumeLanguagePostParams): __Observable<__StrictHttpResponse<any>> {
    let __params = this.newParams()
    let __headers = new HttpHeaders()
    let __body: any = null

    __body = params.data
    let req = new HttpRequest<any>('POST', this.rootUrl + `/resume/${params.resumeId}/language`, __body, {
      headers: __headers,
      params: __params,
      responseType: 'json'
    })

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map(_r => {
        return _r as __StrictHttpResponse<any>
      })
    )
  }

  /**
   * @param params The `ResumeService.ResumeLanguagePostParams` containing the following parameters:
   *
   * - `resumeId`:
   *
   * - `data`:
   */
  resumeLanguagePost(params: ResumeService.ResumeLanguagePostParams): __Observable<any> {
    return this.resumeLanguagePostResponse(params).pipe(__map(_r => _r.body as any))
  }

  /**
   * @param resumeId undefined
   */
  resumeLanguageDeleteResponse(resumeId: number): __Observable<__StrictHttpResponse<any>> {
    let __params = this.newParams()
    let __headers = new HttpHeaders()
    let __body: any = null

    let req = new HttpRequest<any>('DELETE', this.rootUrl + `/resume/${resumeId}/language`, __body, {
      headers: __headers,
      params: __params,
      responseType: 'json'
    })

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map(_r => {
        return _r as __StrictHttpResponse<any>
      })
    )
  }

  /**
   * @param resumeId undefined
   */
  resumeLanguageDelete(resumeId: number): __Observable<any> {
    return this.resumeLanguageDeleteResponse(resumeId).pipe(__map(_r => _r.body as any))
  }

  /**
   * @param params The `ResumeService.ResumeLanguageDelete_1Params` containing the following parameters:
   *
   * - `resumeId`:
   *
   * - `languageId`:
   */
  resumeLanguageDelete_1Response(params: ResumeService.ResumeLanguageDelete_1Params): __Observable<__StrictHttpResponse<any>> {
    let __params = this.newParams()
    let __headers = new HttpHeaders()
    let __body: any = null

    let req = new HttpRequest<any>('DELETE', this.rootUrl + `/resume/${params.resumeId}/language/${params.languageId}`, __body, {
      headers: __headers,
      params: __params,
      responseType: 'json'
    })

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map(_r => {
        return _r as __StrictHttpResponse<any>
      })
    )
  }

  /**
   * @param params The `ResumeService.ResumeLanguageDelete_1Params` containing the following parameters:
   *
   * - `resumeId`:
   *
   * - `languageId`:
   */
  resumeLanguageDelete_1(params: ResumeService.ResumeLanguageDelete_1Params): __Observable<any> {
    return this.resumeLanguageDelete_1Response(params).pipe(__map(_r => _r.body as any))
  }

  /**
   * @param params The `ResumeService.ResumePositionPostParams` containing the following parameters:
   *
   * - `resumeId`:
   *
   * - `data`:
   *
   * @return OK
   */
  resumePositionPostResponse(params: ResumeService.ResumePositionPostParams): __Observable<__StrictHttpResponse<{}>> {
    let __params = this.newParams()
    let __headers = new HttpHeaders()
    let __body: any = null

    __body = params.data
    let req = new HttpRequest<any>('POST', this.rootUrl + `/resume/${params.resumeId}/position`, __body, {
      headers: __headers,
      params: __params,
      responseType: 'json'
    })

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map(_r => {
        return _r as __StrictHttpResponse<{}>
      })
    )
  }

  /**
   * @param params The `ResumeService.ResumePositionPostParams` containing the following parameters:
   *
   * - `resumeId`:
   *
   * - `data`:
   *
   * @return OK
   */
  resumePositionPost(params: ResumeService.ResumePositionPostParams): __Observable<{}> {
    return this.resumePositionPostResponse(params).pipe(__map(_r => _r.body as {}))
  }

  /**
   * @param params The `ResumeService.ResumePositionPost_1Params` containing the following parameters:
   *
   * - `resumeId`:
   *
   * - `data`:
   */
  resumePositionPost_1Response(params: ResumeService.ResumePositionPost_1Params): __Observable<__StrictHttpResponse<any>> {
    let __params = this.newParams()
    let __headers = new HttpHeaders()
    let __body: any = null

    __body = params.data
    let req = new HttpRequest<any>('POST', this.rootUrl + `/resume/${params.resumeId}/shedule`, __body, {
      headers: __headers,
      params: __params,
      responseType: 'json'
    })

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map(_r => {
        return _r as __StrictHttpResponse<any>
      })
    )
  }

  /**
   * @param params The `ResumeService.ResumePositionPost_1Params` containing the following parameters:
   *
   * - `resumeId`:
   *
   * - `data`:
   */
  resumePositionPost_1(params: ResumeService.ResumePositionPost_1Params): __Observable<any> {
    return this.resumePositionPost_1Response(params).pipe(__map(_r => _r.body as any))
  }

  /**
   * @param params The `ResumeService.ResumeExperiencePostParams` containing the following parameters:
   *
   * - `resumeId`:
   *
   * - `data`:
   *
   * @return OK
   */
  resumeExperiencePostResponse(params: ResumeService.ResumeExperiencePostParams): __Observable<__StrictHttpResponse<number>> {
    let __params = this.newParams()
    let __headers = new HttpHeaders()
    let __body: any = null

    __body = params.data
    let req = new HttpRequest<any>('POST', this.rootUrl + `/resume/${params.resumeId}/experience`, __body, {
      headers: __headers,
      params: __params,
      responseType: 'json'
    })

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map(_r => {
        return (_r as HttpResponse<any>).clone({
          body: parseFloat((_r as HttpResponse<any>).body as string)
        }) as __StrictHttpResponse<number>
      })
    )
  }

  /**
   * @param params The `ResumeService.ResumeExperiencePostParams` containing the following parameters:
   *
   * - `resumeId`:
   *
   * - `data`:
   *
   * @return OK
   */
  resumeExperiencePost(params: ResumeService.ResumeExperiencePostParams): __Observable<number> {
    return this.resumeExperiencePostResponse(params).pipe(__map(_r => _r.body as number))
  }

  /**
   * @param resumeId undefined
   */
  resumeExperienceDeleteResponse(resumeId: number): __Observable<__StrictHttpResponse<any>> {
    let __params = this.newParams()
    let __headers = new HttpHeaders()
    let __body: any = null

    let req = new HttpRequest<any>('DELETE', this.rootUrl + `/resume/${resumeId}/experience`, __body, {
      headers: __headers,
      params: __params,
      responseType: 'json'
    })

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map(_r => {
        return _r as __StrictHttpResponse<any>
      })
    )
  }

  /**
   * @param resumeId undefined
   */
  resumeExperienceDelete(resumeId: number): __Observable<any> {
    return this.resumeExperienceDeleteResponse(resumeId).pipe(__map(_r => _r.body as any))
  }

  /**
   * @param params The `ResumeService.ResumeExperiencePost_1Params` containing the following parameters:
   *
   * - `resumeId`:
   *
   * - `experienceId`:
   *
   * - `data`:
   *
   * @return OK
   */
  resumeExperiencePost_1Response(params: ResumeService.ResumeExperiencePost_1Params): __Observable<__StrictHttpResponse<number>> {
    let __params = this.newParams()
    let __headers = new HttpHeaders()
    let __body: any = null

    __body = params.data
    let req = new HttpRequest<any>('POST', this.rootUrl + `/resume/${params.resumeId}/experience/${params.experienceId}/recommendation`, __body, {
      headers: __headers,
      params: __params,
      responseType: 'json'
    })

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map(_r => {
        return (_r as HttpResponse<any>).clone({
          body: parseFloat((_r as HttpResponse<any>).body as string)
        }) as __StrictHttpResponse<number>
      })
    )
  }

  /**
   * @param params The `ResumeService.ResumeExperiencePost_1Params` containing the following parameters:
   *
   * - `resumeId`:
   *
   * - `experienceId`:
   *
   * - `data`:
   *
   * @return OK
   */
  resumeExperiencePost_1(params: ResumeService.ResumeExperiencePost_1Params): __Observable<number> {
    return this.resumeExperiencePost_1Response(params).pipe(__map(_r => _r.body as number))
  }

  /**
   * @param params The `ResumeService.ResumeExperienceDelete_1Params` containing the following parameters:
   *
   * - `resumeId`:
   *
   * - `experienceId`:
   */
  resumeExperienceDelete_1Response(params: ResumeService.ResumeExperienceDelete_1Params): __Observable<__StrictHttpResponse<any>> {
    let __params = this.newParams()
    let __headers = new HttpHeaders()
    let __body: any = null

    let req = new HttpRequest<any>('DELETE', this.rootUrl + `/resume/${params.resumeId}/experience/${params.experienceId}`, __body, {
      headers: __headers,
      params: __params,
      responseType: 'json'
    })

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map(_r => {
        return _r as __StrictHttpResponse<any>
      })
    )
  }

  /**
   * @param params The `ResumeService.ResumeExperienceDelete_1Params` containing the following parameters:
   *
   * - `resumeId`:
   *
   * - `experienceId`:
   */
  resumeExperienceDelete_1(params: ResumeService.ResumeExperienceDelete_1Params): __Observable<any> {
    return this.resumeExperienceDelete_1Response(params).pipe(__map(_r => _r.body as any))
  }

  /**
   * @param params The `ResumeService.ResumeExperienceDelete_2Params` containing the following parameters:
   *
   * - `resumeId`:
   *
   * - `recommendationId`:
   *
   * - `experienceId`:
   */
  resumeExperienceDelete_2Response(params: ResumeService.ResumeExperienceDelete_2Params): __Observable<__StrictHttpResponse<any>> {
    let __params = this.newParams()
    let __headers = new HttpHeaders()
    let __body: any = null

    let req = new HttpRequest<any>('DELETE', this.rootUrl + `/resume/${params.resumeId}/experience/${params.experienceId}/recommendation/${params.recommendationId}`, __body, {
      headers: __headers,
      params: __params,
      responseType: 'json'
    })

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map(_r => {
        return _r as __StrictHttpResponse<any>
      })
    )
  }

  /**
   * @param params The `ResumeService.ResumeExperienceDelete_2Params` containing the following parameters:
   *
   * - `resumeId`:
   *
   * - `recommendationId`:
   *
   * - `experienceId`:
   */
  resumeExperienceDelete_2(params: ResumeService.ResumeExperienceDelete_2Params): __Observable<any> {
    return this.resumeExperienceDelete_2Response(params).pipe(__map(_r => _r.body as any))
  }

  /**
   * @param notebookId undefined
   * @return OK
   */
  resumeGetJobseekerResumeListResponse(notebookId?: number): __Observable<__StrictHttpResponse<Array<ResumeShortListItemDTO>>> {
    let __params = this.newParams()
    let __headers = new HttpHeaders()
    let __body: any = null
    if (notebookId != null) __params = __params.set('notebookId', notebookId.toString())
    let req = new HttpRequest<any>('GET', this.rootUrl + `/resume`, __body, {
      headers: __headers,
      params: __params,
      responseType: 'json'
    })

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map(_r => {
        return _r as __StrictHttpResponse<Array<ResumeShortListItemDTO>>
      })
    )
  }

  /**
   * @param notebookId undefined
   * @return OK
   */
  resumeGetJobseekerResumeList(notebookId?: number): __Observable<Array<ResumeShortListItemDTO>> {
    return this.resumeGetJobseekerResumeListResponse(notebookId).pipe(__map(_r => _r.body as Array<ResumeShortListItemDTO>))
  }

  /**
   * @return OK
   */
  resumeCreateBlankResumeResponse(): __Observable<__StrictHttpResponse<ResumeShortInfoDto>> {
    let __params = this.newParams()
    let __headers = new HttpHeaders()
    let __body: any = null
    let req = new HttpRequest<any>('POST', this.rootUrl + `/resume`, __body, {
      headers: __headers,
      params: __params,
      responseType: 'json'
    })

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map(_r => {
        return _r as __StrictHttpResponse<ResumeShortInfoDto>
      })
    )
  }

  /**
   * @return OK
   */
  resumeCreateBlankResume(): __Observable<ResumeShortInfoDto> {
    return this.resumeCreateBlankResumeResponse().pipe(__map(_r => _r.body as ResumeShortInfoDto))
  }

  /**
   * @param user undefined
   * @return OK
   */
  resumeCreateBlankResume_1Response(user: UserModel): __Observable<__StrictHttpResponse<ResumeShortInfoDto>> {
    let __params = this.newParams()
    let __headers = new HttpHeaders()
    let __body: any = null
    __body = user
    let req = new HttpRequest<any>('POST', this.rootUrl + `/resume/bybot`, __body, {
      headers: __headers,
      params: __params,
      responseType: 'json'
    })

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map(_r => {
        return _r as __StrictHttpResponse<ResumeShortInfoDto>
      })
    )
  }

  /**
   * @param user undefined
   * @return OK
   */
  resumeCreateBlankResume_1(user: UserModel): __Observable<ResumeShortInfoDto> {
    return this.resumeCreateBlankResume_1Response(user).pipe(__map(_r => _r.body as ResumeShortInfoDto))
  }

  /**
   * @param resumeId undefined
   * @return OK
   */
  resumeGetResponse(resumeId: number): __Observable<__StrictHttpResponse<ResumeModel>> {
    let __params = this.newParams()
    let __headers = new HttpHeaders()
    let __body: any = null

    let req = new HttpRequest<any>('GET', this.rootUrl + `/resume/${resumeId}`, __body, {
      headers: __headers,
      params: __params,
      responseType: 'json'
    })

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map(_r => {
        return _r as __StrictHttpResponse<ResumeModel>
      })
    )
  }

  /**
   * @param resumeId undefined
   * @return OK
   */
  resumeGet(resumeId: number): __Observable<ResumeModel> {
    return this.resumeGetResponse(resumeId).pipe(__map(_r => _r.body as ResumeModel))
  }

  /**
   * @param resumeId undefined
   */
  resumeDeleteResponse(resumeId: number): __Observable<__StrictHttpResponse<any>> {
    let __params = this.newParams()
    let __headers = new HttpHeaders()
    let __body: any = null

    let req = new HttpRequest<any>('DELETE', this.rootUrl + `/resume/${resumeId}`, __body, {
      headers: __headers,
      params: __params,
      responseType: 'json'
    })

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map(_r => {
        return _r as __StrictHttpResponse<any>
      })
    )
  }

  /**
   * @param resumeId undefined
   */
  resumeDelete(resumeId: number): __Observable<any> {
    return this.resumeDeleteResponse(resumeId).pipe(__map(_r => _r.body as any))
  }

  /**
   * @param resumeId undefined
   * @return OK
   */
  resumeCopyResponse(resumeId: number): __Observable<__StrictHttpResponse<string>> {
    let __params = this.newParams()
    let __headers = new HttpHeaders()
    let __body: any = null

    let req = new HttpRequest<any>('POST', this.rootUrl + `/resume/${resumeId}/copy`, __body, {
      headers: __headers,
      params: __params,
      responseType: 'json'
    })

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map(_r => {
        return _r as __StrictHttpResponse<string>
      })
    )
  }

  /**
   * @param resumeId undefined
   * @return OK
   */
  resumeCopy(resumeId: number): __Observable<string> {
    return this.resumeCopyResponse(resumeId).pipe(__map(_r => _r.body as string))
  }

  /**
   * @param resumeId undefined
   * @return OK
   */
  resumeConfirmResumeEmailResponse(resumeId: number): __Observable<__StrictHttpResponse<boolean>> {
    let __params = this.newParams()
    let __headers = new HttpHeaders()
    let __body: any = null

    let req = new HttpRequest<any>('POST', this.rootUrl + `/resume/${resumeId}/confirmemail`, __body, {
      headers: __headers,
      params: __params,
      responseType: 'json'
    })

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map(_r => {
        return (_r as HttpResponse<any>).clone({
          body: (_r as HttpResponse<any>).body === true
        }) as __StrictHttpResponse<boolean>
      })
    )
  }

  /**
   * @param resumeId undefined
   * @return OK
   */
  resumeConfirmResumeEmail(resumeId: number): __Observable<boolean> {
    return this.resumeConfirmResumeEmailResponse(resumeId).pipe(__map(_r => _r.body as boolean))
  }

  /**
   * @param params The `ResumeService.ResumeSkillPostParams` containing the following parameters:
   *
   * - `resumeId`:
   *
   * - `data`:
   */
  resumeSkillPostResponse(params: ResumeService.ResumeSkillPostParams): __Observable<__StrictHttpResponse<any>> {
    let __params = this.newParams()
    let __headers = new HttpHeaders()
    let __body: any = null

    __body = params.data
    let req = new HttpRequest<any>('POST', this.rootUrl + `/resume/${params.resumeId}/skill`, __body, {
      headers: __headers,
      params: __params,
      responseType: 'json'
    })

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map(_r => {
        return _r as __StrictHttpResponse<any>
      })
    )
  }

  /**
   * @param params The `ResumeService.ResumeSkillPostParams` containing the following parameters:
   *
   * - `resumeId`:
   *
   * - `data`:
   */
  resumeSkillPost(params: ResumeService.ResumeSkillPostParams): __Observable<any> {
    return this.resumeSkillPostResponse(params).pipe(__map(_r => _r.body as any))
  }

  /**
   * @param resumeId undefined
   */
  resumeSkillDeleteResponse(resumeId: number): __Observable<__StrictHttpResponse<any>> {
    let __params = this.newParams()
    let __headers = new HttpHeaders()
    let __body: any = null

    let req = new HttpRequest<any>('DELETE', this.rootUrl + `/resume/${resumeId}/skill`, __body, {
      headers: __headers,
      params: __params,
      responseType: 'json'
    })

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map(_r => {
        return _r as __StrictHttpResponse<any>
      })
    )
  }

  /**
   * @param resumeId undefined
   */
  resumeSkillDelete(resumeId: number): __Observable<any> {
    return this.resumeSkillDeleteResponse(resumeId).pipe(__map(_r => _r.body as any))
  }

  /**
   * @param params The `ResumeService.ResumeTrainingPostParams` containing the following parameters:
   *
   * - `resumeId`:
   *
   * - `data`:
   *
   * @return OK
   */
  resumeTrainingPostResponse(params: ResumeService.ResumeTrainingPostParams): __Observable<__StrictHttpResponse<{}>> {
    let __params = this.newParams()
    let __headers = new HttpHeaders()
    let __body: any = null

    __body = params.data
    let req = new HttpRequest<any>('POST', this.rootUrl + `/resume/${params.resumeId}/training`, __body, {
      headers: __headers,
      params: __params,
      responseType: 'json'
    })

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map(_r => {
        return _r as __StrictHttpResponse<{}>
      })
    )
  }

  /**
   * @param params The `ResumeService.ResumeTrainingPostParams` containing the following parameters:
   *
   * - `resumeId`:
   *
   * - `data`:
   *
   * @return OK
   */
  resumeTrainingPost(params: ResumeService.ResumeTrainingPostParams): __Observable<{}> {
    return this.resumeTrainingPostResponse(params).pipe(__map(_r => _r.body as {}))
  }

  /**
   * @param params The `ResumeService.ResumeTrainingDeleteParams` containing the following parameters:
   *
   * - `trainingId`:
   *
   * - `resumeId`:
   */
  resumeTrainingDeleteResponse(params: ResumeService.ResumeTrainingDeleteParams): __Observable<__StrictHttpResponse<any>> {
    let __params = this.newParams()
    let __headers = new HttpHeaders()
    let __body: any = null

    let req = new HttpRequest<any>('DELETE', this.rootUrl + `/resume/${params.resumeId}/training/${params.trainingId}`, __body, {
      headers: __headers,
      params: __params,
      responseType: 'json'
    })

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map(_r => {
        return _r as __StrictHttpResponse<any>
      })
    )
  }

  /**
   * @param params The `ResumeService.ResumeTrainingDeleteParams` containing the following parameters:
   *
   * - `trainingId`:
   *
   * - `resumeId`:
   */
  resumeTrainingDelete(params: ResumeService.ResumeTrainingDeleteParams): __Observable<any> {
    return this.resumeTrainingDeleteResponse(params).pipe(__map(_r => _r.body as any))
  }

  /**
   * @param params The `ResumeService.ResumeAdditionalPostParams` containing the following parameters:
   *
   * - `resumeId`:
   *
   * - `data`:
   *
   * @return OK
   */
  resumeAdditionalPostResponse(params: ResumeService.ResumeAdditionalPostParams): __Observable<__StrictHttpResponse<number>> {
    let __params = this.newParams()
    let __headers = new HttpHeaders()
    let __body: any = null

    __body = params.data
    let req = new HttpRequest<any>('POST', this.rootUrl + `/resume/${params.resumeId}/additional`, __body, {
      headers: __headers,
      params: __params,
      responseType: 'json'
    })

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map(_r => {
        return (_r as HttpResponse<any>).clone({
          body: parseFloat((_r as HttpResponse<any>).body as string)
        }) as __StrictHttpResponse<number>
      })
    )
  }

  /**
   * @param params The `ResumeService.ResumeAdditionalPostParams` containing the following parameters:
   *
   * - `resumeId`:
   *
   * - `data`:
   *
   * @return OK
   */
  resumeAdditionalPost(params: ResumeService.ResumeAdditionalPostParams): __Observable<number> {
    return this.resumeAdditionalPostResponse(params).pipe(__map(_r => _r.body as number))
  }

  /**
   * @param params The `ResumeService.ResumeAdditionalDeleteParams` containing the following parameters:
   *
   * - `resumeId`:
   *
   * - `additionalId`:
   */
  resumeAdditionalDeleteResponse(params: ResumeService.ResumeAdditionalDeleteParams): __Observable<__StrictHttpResponse<any>> {
    let __params = this.newParams()
    let __headers = new HttpHeaders()
    let __body: any = null

    let req = new HttpRequest<any>('DELETE', this.rootUrl + `/resume/${params.resumeId}/additional/${params.additionalId}`, __body, {
      headers: __headers,
      params: __params,
      responseType: 'json'
    })

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map(_r => {
        return _r as __StrictHttpResponse<any>
      })
    )
  }

  /**
   * @param params The `ResumeService.ResumeAdditionalDeleteParams` containing the following parameters:
   *
   * - `resumeId`:
   *
   * - `additionalId`:
   */
  resumeAdditionalDelete(params: ResumeService.ResumeAdditionalDeleteParams): __Observable<any> {
    return this.resumeAdditionalDeleteResponse(params).pipe(__map(_r => _r.body as any))
  }

  /**
   * @param params The `ResumeService.ResumePhotoUploadResumePhotoParams` containing the following parameters:
   *
   * - `resumeId`:
   *
   * - `photo`: upload file
   *
   * @return OK
   */
  resumePhotoUploadResumePhotoResponse(params: ResumeService.ResumePhotoUploadResumePhotoParams): __Observable<__StrictHttpResponse<string>> {
    let __params = this.newParams()
    let __headers = new HttpHeaders()
    let __body: any = null
    let __formData = new FormData()
    __body = __formData

    if (params.photo != null) {
      __formData.append('photo', params.photo as string | Blob)
    }
    let req = new HttpRequest<any>('POST', this.rootUrl + `/resume/${params.resumeId}/photo`, __body, {
      headers: __headers,
      params: __params,
      responseType: 'json'
    })

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map(_r => {
        return _r as __StrictHttpResponse<string>
      })
    )
  }

  /**
   * @param params The `ResumeService.ResumePhotoUploadResumePhotoParams` containing the following parameters:
   *
   * - `resumeId`:
   *
   * - `photo`: upload file
   *
   * @return OK
   */
  resumePhotoUploadResumePhoto(params: ResumeService.ResumePhotoUploadResumePhotoParams): __Observable<string> {
    return this.resumePhotoUploadResumePhotoResponse(params).pipe(__map(_r => _r.body as string))
  }

  /**
   * @param resumeId undefined
   */
  resumePhotoDeleteResponse(resumeId: number): __Observable<__StrictHttpResponse<any>> {
    let __params = this.newParams()
    let __headers = new HttpHeaders()
    let __body: any = null

    let req = new HttpRequest<any>('DELETE', this.rootUrl + `/resume/${resumeId}/photo`, __body, {
      headers: __headers,
      params: __params,
      responseType: 'json'
    })

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map(_r => {
        return _r as __StrictHttpResponse<any>
      })
    )
  }

  /**
   * @param resumeId undefined
   */
  resumePhotoDelete(resumeId: number): __Observable<any> {
    return this.resumePhotoDeleteResponse(resumeId).pipe(__map(_r => _r.body as any))
  }

  /**
   * @param params The `ResumeService.ResumePhotoAddResumePhotoNameParams` containing the following parameters:
   *
   * - `resumeId`:
   *
   * - `photoName`:
   *
   * @return OK
   */
  resumePhotoAddResumePhotoNameResponse(params: ResumeService.ResumePhotoAddResumePhotoNameParams): __Observable<__StrictHttpResponse<{}>> {
    let __params = this.newParams()
    let __headers = new HttpHeaders()
    let __body: any = null

    if (params.photoName != null) __params = __params.set('photoName', params.photoName.toString())
    let req = new HttpRequest<any>('POST', this.rootUrl + `/resume/${params.resumeId}/photobyname`, __body, {
      headers: __headers,
      params: __params,
      responseType: 'json'
    })

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map(_r => {
        return _r as __StrictHttpResponse<{}>
      })
    )
  }

  /**
   * @param params The `ResumeService.ResumePhotoAddResumePhotoNameParams` containing the following parameters:
   *
   * - `resumeId`:
   *
   * - `photoName`:
   *
   * @return OK
   */
  resumePhotoAddResumePhotoName(params: ResumeService.ResumePhotoAddResumePhotoNameParams): __Observable<{}> {
    return this.resumePhotoAddResumePhotoNameResponse(params).pipe(__map(_r => _r.body as {}))
  }

  /**
   * @param params The `ResumeService.ResumeContactPostParams` containing the following parameters:
   *
   * - `resumeId`:
   *
   * - `data`:
   */
  resumeContactPostResponse(params: ResumeService.ResumeContactPostParams): __Observable<__StrictHttpResponse<any>> {
    let __params = this.newParams()
    let __headers = new HttpHeaders()
    let __body: any = null

    __body = params.data
    let req = new HttpRequest<any>('POST', this.rootUrl + `/resume/${params.resumeId}/contact`, __body, {
      headers: __headers,
      params: __params,
      responseType: 'json'
    })

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map(_r => {
        return _r as __StrictHttpResponse<any>
      })
    )
  }

  /**
   * @param params The `ResumeService.ResumeContactPostParams` containing the following parameters:
   *
   * - `resumeId`:
   *
   * - `data`:
   */
  resumeContactPost(params: ResumeService.ResumeContactPostParams): __Observable<any> {
    return this.resumeContactPostResponse(params).pipe(__map(_r => _r.body as any))
  }

  /**
   * @param params The `ResumeService.ResumeDiiaCertificatePostParams` containing the following parameters:
   *
   * - `resumeId`:
   *
   * - `data`:
   *
   * @return OK
   */
  resumeDiiaCertificatePostResponse(params: ResumeService.ResumeDiiaCertificatePostParams): __Observable<__StrictHttpResponse<{}>> {
    let __params = this.newParams()
    let __headers = new HttpHeaders()
    let __body: any = null

    __body = params.data
    let req = new HttpRequest<any>('POST', this.rootUrl + `/resume/${params.resumeId}/diiacertificate`, __body, {
      headers: __headers,
      params: __params,
      responseType: 'json'
    })

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map(_r => {
        return _r as __StrictHttpResponse<{}>
      })
    )
  }

  /**
   * @param params The `ResumeService.ResumeDiiaCertificatePostParams` containing the following parameters:
   *
   * - `resumeId`:
   *
   * - `data`:
   *
   * @return OK
   */
  resumeDiiaCertificatePost(params: ResumeService.ResumeDiiaCertificatePostParams): __Observable<{}> {
    return this.resumeDiiaCertificatePostResponse(params).pipe(__map(_r => _r.body as {}))
  }

  /**
   * @param resumeId undefined
   * @return OK
   */
  resumeDiiaCertificateDeleteResponse(resumeId: number): __Observable<__StrictHttpResponse<{}>> {
    let __params = this.newParams()
    let __headers = new HttpHeaders()
    let __body: any = null

    let req = new HttpRequest<any>('DELETE', this.rootUrl + `/resume/${resumeId}/diiacertificate`, __body, {
      headers: __headers,
      params: __params,
      responseType: 'json'
    })

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map(_r => {
        return _r as __StrictHttpResponse<{}>
      })
    )
  }

  /**
   * @param resumeId undefined
   * @return OK
   */
  resumeDiiaCertificateDelete(resumeId: number): __Observable<{}> {
    return this.resumeDiiaCertificateDeleteResponse(resumeId).pipe(__map(_r => _r.body as {}))
  }

  /**
   * @param params The `ResumeService.ResumeUiLanguagePostParams` containing the following parameters:
   *
   * - `resumeId`:
   *
   * - `language`:
   */
  resumeUiLanguagePostResponse(params: ResumeService.ResumeUiLanguagePostParams): __Observable<__StrictHttpResponse<any>> {
    let __params = this.newParams()
    let __headers = new HttpHeaders()
    let __body: any = null

    if (params.language != null) __params = __params.set('language', params.language.toString())
    let req = new HttpRequest<any>('POST', this.rootUrl + `/resume/${params.resumeId}/uilanguage`, __body, {
      headers: __headers,
      params: __params,
      responseType: 'json'
    })

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map(_r => {
        return _r as __StrictHttpResponse<any>
      })
    )
  }

  /**
   * @param params The `ResumeService.ResumeUiLanguagePostParams` containing the following parameters:
   *
   * - `resumeId`:
   *
   * - `language`:
   */
  resumeUiLanguagePost(params: ResumeService.ResumeUiLanguagePostParams): __Observable<any> {
    return this.resumeUiLanguagePostResponse(params).pipe(__map(_r => _r.body as any))
  }
}

module ResumeService {
  /**
   * Parameters for ResumeEducationPost
   */
  export interface ResumeEducationPostParams {
    resumeId: number
    data: EducationInfoDTO
  }

  /**
   * Parameters for ResumeEducationDelete
   */
  export interface ResumeEducationDeleteParams {
    resumeId: number
    educationId: number
  }

  /**
   * Parameters for ResumeStatePost
   */
  export interface ResumeStatePostParams {
    resumeId: number
    data: CvStateInfo
  }

  /**
   * Parameters for ResumeStatePostSearchState
   */
  export interface ResumeStatePostSearchStateParams {
    state: 'None' | 'Active' | 'NowWorking'
    resumeId: number
  }

  /**
   * Parameters for ResumePersonalPost
   */
  export interface ResumePersonalPostParams {
    resumeId: number
    data: PersonalInfoDTO
  }

  /**
   * Parameters for ResumeLanguagePost
   */
  export interface ResumeLanguagePostParams {
    resumeId: number
    data: LanguageAbilityInfoDTO
  }

  /**
   * Parameters for ResumeLanguageDelete_1
   */
  export interface ResumeLanguageDelete_1Params {
    resumeId: number
    languageId: number
  }

  /**
   * Parameters for ResumePositionPost
   */
  export interface ResumePositionPostParams {
    resumeId: number
    data: PositionInfoDTO
  }

  /**
   * Parameters for ResumePositionPost_1
   */
  export interface ResumePositionPost_1Params {
    resumeId: number
    data: ScheduleInfoDTO
  }

  /**
   * Parameters for ResumeExperiencePost
   */
  export interface ResumeExperiencePostParams {
    resumeId: number
    data: ExperienceInfoDTO
  }

  /**
   * Parameters for ResumeExperiencePost_1
   */
  export interface ResumeExperiencePost_1Params {
    resumeId: number
    experienceId: number
    data: RecommendationInfoDTO
  }

  /**
   * Parameters for ResumeExperienceDelete_1
   */
  export interface ResumeExperienceDelete_1Params {
    resumeId: number
    experienceId: number
  }

  /**
   * Parameters for ResumeExperienceDelete_2
   */
  export interface ResumeExperienceDelete_2Params {
    resumeId: number
    recommendationId: number
    experienceId: number
  }

  /**
   * Parameters for ResumeSkillPost
   */
  export interface ResumeSkillPostParams {
    resumeId: number
    data: SkillInfoDTO
  }

  /**
   * Parameters for ResumeTrainingPost
   */
  export interface ResumeTrainingPostParams {
    resumeId: number
    data: TrainingInfoDTO
  }

  /**
   * Parameters for ResumeTrainingDelete
   */
  export interface ResumeTrainingDeleteParams {
    trainingId: number
    resumeId: number
  }

  /**
   * Parameters for ResumeAdditionalPost
   */
  export interface ResumeAdditionalPostParams {
    resumeId: number
    data: AdditionalInfoDTO
  }

  /**
   * Parameters for ResumeAdditionalDelete
   */
  export interface ResumeAdditionalDeleteParams {
    resumeId: number
    additionalId: number
  }

  /**
   * Parameters for ResumePhotoUploadResumePhoto
   */
  export interface ResumePhotoUploadResumePhotoParams {
    resumeId: number

    /**
     * upload file
     */
    photo: Blob
  }

  /**
   * Parameters for ResumePhotoAddResumePhotoName
   */
  export interface ResumePhotoAddResumePhotoNameParams {
    resumeId: number
    photoName: string
  }

  /**
   * Parameters for ResumeContactPost
   */
  export interface ResumeContactPostParams {
    resumeId: number
    data: ContactInfoDTO
  }

  /**
   * Parameters for ResumeDiiaCertificatePost
   */
  export interface ResumeDiiaCertificatePostParams {
    resumeId: number
    data: DiiaCertificateInfoDTO
  }

  /**
   * Parameters for ResumeUiLanguagePost
   */
  export interface ResumeUiLanguagePostParams {
    resumeId: number
    language: 'None' | 'Russian' | 'English' | 'Ukrainian'
  }
}

export { ResumeService }
