/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { Observable } from 'rxjs'
import { map } from 'rxjs/operators'

import { BaseService } from '../base-service'
import { NotificationDataAccessConfiguration } from '../notification-data-access-configuration'
import { StrictHttpResponse } from '../strict-http-response'

import { conversationsCount$Json } from '../fn/conversations/conversations-count-json'
import { ConversationsCount$Json$Params } from '../fn/conversations/conversations-count-json'
import { conversationsCount$Plain } from '../fn/conversations/conversations-count-plain'
import { ConversationsCount$Plain$Params } from '../fn/conversations/conversations-count-plain'
import { conversationsGet$Json } from '../fn/conversations/conversations-get-json'
import { ConversationsGet$Json$Params } from '../fn/conversations/conversations-get-json'
import { conversationsGet$Plain } from '../fn/conversations/conversations-get-plain'
import { ConversationsGet$Plain$Params } from '../fn/conversations/conversations-get-plain'
import { conversationsPost$Json } from '../fn/conversations/conversations-post-json'
import { ConversationsPost$Json$Params } from '../fn/conversations/conversations-post-json'
import { conversationsPost$Plain } from '../fn/conversations/conversations-post-plain'
import { ConversationsPost$Plain$Params } from '../fn/conversations/conversations-post-plain'
import { ConversationViewModel } from '../models/conversation-view-model'
import { getAll$Json } from '../fn/conversations/get-all-json'
import { GetAll$Json$Params } from '../fn/conversations/get-all-json'
import { getAll$Plain } from '../fn/conversations/get-all-plain'
import { GetAll$Plain$Params } from '../fn/conversations/get-all-plain'

/**
 * Provides functionality to operate with conversations: fetch conversations related to user, retrieve conversation by id, initiate new one, update existing conversation, etc.
 */
@Injectable({ providedIn: 'root' })
export class ConversationsService extends BaseService {
  constructor(config: NotificationDataAccessConfiguration, http: HttpClient) {
    super(config, http)
  }

  /** Path part for operation `getAll()` */
  static readonly GetAllPath = '/v1/conversations'

  /**
   * Fetches conversations related to corresponding user by filters specified.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getAll$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAll$Plain$Response(params?: GetAll$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<ConversationViewModel>>> {
    return getAll$Plain(this.http, this.rootUrl, params, context)
  }

  /**
   * Fetches conversations related to corresponding user by filters specified.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getAll$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAll$Plain(params?: GetAll$Plain$Params, context?: HttpContext): Observable<Array<ConversationViewModel>> {
    return this.getAll$Plain$Response(params, context).pipe(map((r: StrictHttpResponse<Array<ConversationViewModel>>): Array<ConversationViewModel> => r.body))
  }

  /**
   * Fetches conversations related to corresponding user by filters specified.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getAll$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAll$Json$Response(params?: GetAll$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<ConversationViewModel>>> {
    return getAll$Json(this.http, this.rootUrl, params, context)
  }

  /**
   * Fetches conversations related to corresponding user by filters specified.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getAll$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAll$Json(params?: GetAll$Json$Params, context?: HttpContext): Observable<Array<ConversationViewModel>> {
    return this.getAll$Json$Response(params, context).pipe(map((r: StrictHttpResponse<Array<ConversationViewModel>>): Array<ConversationViewModel> => r.body))
  }

  /** Path part for operation `conversationsPost()` */
  static readonly ConversationsPostPath = '/v1/conversations'

  /**
   * Initiates new conversation from signed-in user based on specified RUA.ChatAPI.ViewModels.Incoming.ConversationContext
   * Possible cases:
   *  - RecruiterId (retrieved from auth token) + CvId
   *  - RecruiterId (retrieved from auth token) + ApplyId + ApplyType
   *  - VacancyId + CvId.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `conversationsPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  conversationsPost$Plain$Response(params?: ConversationsPost$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<ConversationViewModel>> {
    return conversationsPost$Plain(this.http, this.rootUrl, params, context)
  }

  /**
   * Initiates new conversation from signed-in user based on specified RUA.ChatAPI.ViewModels.Incoming.ConversationContext
   * Possible cases:
   *  - RecruiterId (retrieved from auth token) + CvId
   *  - RecruiterId (retrieved from auth token) + ApplyId + ApplyType
   *  - VacancyId + CvId.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `conversationsPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  conversationsPost$Plain(params?: ConversationsPost$Plain$Params, context?: HttpContext): Observable<ConversationViewModel> {
    return this.conversationsPost$Plain$Response(params, context).pipe(map((r: StrictHttpResponse<ConversationViewModel>): ConversationViewModel => r.body))
  }

  /**
   * Initiates new conversation from signed-in user based on specified RUA.ChatAPI.ViewModels.Incoming.ConversationContext
   * Possible cases:
   *  - RecruiterId (retrieved from auth token) + CvId
   *  - RecruiterId (retrieved from auth token) + ApplyId + ApplyType
   *  - VacancyId + CvId.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `conversationsPost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  conversationsPost$Json$Response(params?: ConversationsPost$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<ConversationViewModel>> {
    return conversationsPost$Json(this.http, this.rootUrl, params, context)
  }

  /**
   * Initiates new conversation from signed-in user based on specified RUA.ChatAPI.ViewModels.Incoming.ConversationContext
   * Possible cases:
   *  - RecruiterId (retrieved from auth token) + CvId
   *  - RecruiterId (retrieved from auth token) + ApplyId + ApplyType
   *  - VacancyId + CvId.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `conversationsPost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  conversationsPost$Json(params?: ConversationsPost$Json$Params, context?: HttpContext): Observable<ConversationViewModel> {
    return this.conversationsPost$Json$Response(params, context).pipe(map((r: StrictHttpResponse<ConversationViewModel>): ConversationViewModel => r.body))
  }

  /** Path part for operation `conversationsCount()` */
  static readonly ConversationsCountPath = '/v1/conversations'

  /**
   * Counts conversations related to corresponding user with filters specified.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `conversationsCount$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  conversationsCount$Plain$Response(params?: ConversationsCount$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<number>> {
    return conversationsCount$Plain(this.http, this.rootUrl, params, context)
  }

  /**
   * Counts conversations related to corresponding user with filters specified.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `conversationsCount$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  conversationsCount$Plain(params?: ConversationsCount$Plain$Params, context?: HttpContext): Observable<number> {
    return this.conversationsCount$Plain$Response(params, context).pipe(map((r: StrictHttpResponse<number>): number => r.body))
  }

  /**
   * Counts conversations related to corresponding user with filters specified.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `conversationsCount$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  conversationsCount$Json$Response(params?: ConversationsCount$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<number>> {
    return conversationsCount$Json(this.http, this.rootUrl, params, context)
  }

  /**
   * Counts conversations related to corresponding user with filters specified.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `conversationsCount$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  conversationsCount$Json(params?: ConversationsCount$Json$Params, context?: HttpContext): Observable<number> {
    return this.conversationsCount$Json$Response(params, context).pipe(map((r: StrictHttpResponse<number>): number => r.body))
  }

  /** Path part for operation `conversationsGet()` */
  static readonly ConversationsGetPath = '/v1/conversations/{conversationId}'

  /**
   * Retrieves info about corresponding conversation.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `conversationsGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  conversationsGet$Plain$Response(params: ConversationsGet$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<ConversationViewModel>> {
    return conversationsGet$Plain(this.http, this.rootUrl, params, context)
  }

  /**
   * Retrieves info about corresponding conversation.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `conversationsGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  conversationsGet$Plain(params: ConversationsGet$Plain$Params, context?: HttpContext): Observable<ConversationViewModel> {
    return this.conversationsGet$Plain$Response(params, context).pipe(map((r: StrictHttpResponse<ConversationViewModel>): ConversationViewModel => r.body))
  }

  /**
   * Retrieves info about corresponding conversation.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `conversationsGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  conversationsGet$Json$Response(params: ConversationsGet$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<ConversationViewModel>> {
    return conversationsGet$Json(this.http, this.rootUrl, params, context)
  }

  /**
   * Retrieves info about corresponding conversation.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `conversationsGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  conversationsGet$Json(params: ConversationsGet$Json$Params, context?: HttpContext): Observable<ConversationViewModel> {
    return this.conversationsGet$Json$Response(params, context).pipe(map((r: StrictHttpResponse<ConversationViewModel>): ConversationViewModel => r.body))
  }
}
