export enum NewAccountActionsEnum {
  addVacancy = 'addVacancy',
  editVacancy = 'editVacancy',
  viewVacancy = 'viewVacancy',
  viewApply = 'viewApply',
  viewModal = 'viewModal',
  viewCv = 'viewCv',
  openContact = 'openContact',
  applyFilter = 'applyFilter',
  downloadCv = 'downloadCv',
  suggestVacancy = 'suggestVacancy',
  initChat = 'initChat'
}
