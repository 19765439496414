import { Injectable } from '@angular/core'
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http'
import { BaseService as __BaseService } from '../base-service'
import { EmployerDataAccessConfiguration as __Configuration } from '../employer-data-access-configuration'
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response'
import { Observable as __Observable } from 'rxjs'
import { map as __map, filter as __filter } from 'rxjs/operators'

import { CvSearchResponse } from '../models/cv-search-response'
import { CvSearchRequest } from '../models/cv-search-request'
import { ResumeInfo } from '../models/resume-info'
import { ResumeRecommended } from '../models/resume-recommended'
import { SimilarCvResponse } from '../models/similar-cv-response'
import { CommonResponse } from '../models/common-response'
import { SendMessageRequest } from '../models/send-message-request'
import { CvDbTicket } from '../models/cv-db-ticket'
import { CvDownloadRequest } from '../models/cv-download-request'
import { OpenContactsCountResponse as RabotaUaEmployerCodeModelResponsesOpenContactsCountResponse } from '../models/RabotaUA/EmployerCode/Model/Responses/open-contacts-count-response'

@Injectable({
  providedIn: 'root'
})
class ResumeService extends __BaseService {
  public static readonly searchPath = '/resume/search'
  public static readonly getPath = '/resume/{id}'
  public static readonly searchByVacancyPath = '/resume/byvacancy/{vacancyId}'
  public static readonly recommendByVacancyPath = '/resume/recommendbyvacancy'
  public static readonly recommendByInactiveVacancyPath = '/resume/recommendbyinactivevacancy'
  public static readonly interestedByVacancyPath = '/resume/interestedbyvacancy'
  public static readonly searchByResumePath = '/resume/byresume/{resumeId}'
  public static readonly openContactPath = '/resume/open/{id}'
  public static readonly messagePath = '/resume/message'
  public static readonly sendMessagePath = '/resume/message'
  public static readonly addSelectedResumeToVacancyPath = '/resume/addtonotebook'
  public static readonly removeSelectedResumePath = '/resume/addtonotebook'
  public static readonly get_1Path = '/resume/cvcount'
  public static readonly openablePath = '/resume/openable'
  public static readonly getResumePath = '/resume/getfile/{id}'
  public static readonly resumeAsFilePath = '/resume/file'
  public static readonly dislikeCvPath = '/resume/dislike/{cvId}'
  public static readonly dislikeCvRevocationPath = '/resume/dislike/revocation/{cvId}'

  public constructor(config: __Configuration, http: HttpClient) {
    super(config, http)
  }

  /**
   * Path part for operation openContactsCount
   */
  public readonly OpenContactsCountPath = '/resume/open-contacts-count'

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `openContactsCount$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  openContactsCount$Plain$Response(): __Observable<__StrictHttpResponse<RabotaUaEmployerCodeModelResponsesOpenContactsCountResponse>> {
    // const rb = new RequestBuilder(this.rootUrl, ResumeService.OpenContactsCountPath, 'get')
    // if (params) {
    // }

    let __params = this.newParams()
    let __headers = new HttpHeaders()
    let __body: any = null

    let req = new HttpRequest<any>('GET', this.rootUrl + this.OpenContactsCountPath, __body, {
      headers: __headers,
      params: __params,
      responseType: 'json'
    })

    return this.http.request(req).pipe(
      __filter((r: any) => r instanceof HttpResponse),
      __map((r: HttpResponse<any>) => {
        return r as __StrictHttpResponse<RabotaUaEmployerCodeModelResponsesOpenContactsCountResponse>
      })
    )
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `openContactsCount$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  openContactsCount$Plain(): __Observable<RabotaUaEmployerCodeModelResponsesOpenContactsCountResponse> {
    return this.openContactsCount$Plain$Response().pipe(
      __map((r: __StrictHttpResponse<RabotaUaEmployerCodeModelResponsesOpenContactsCountResponse>) => r.body as RabotaUaEmployerCodeModelResponsesOpenContactsCountResponse)
    )
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `openContactsCount$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  openContactsCount$Json$Response(): __Observable<__StrictHttpResponse<RabotaUaEmployerCodeModelResponsesOpenContactsCountResponse>> {
    // const rb = new RequestBuilder(this.rootUrl, ResumeService.OpenContactsCountPath, 'get')
    // if (params) {
    // }

    let __params = this.newParams()
    let __headers = new HttpHeaders()
    let __body: any = null

    let req = new HttpRequest<any>('GET', this.rootUrl + this.OpenContactsCountPath, __body, {
      headers: __headers,
      params: __params,
      responseType: 'json'
    })

    return this.http.request(req).pipe(
      __filter((r: any) => r instanceof HttpResponse),
      __map((r: HttpResponse<any>) => {
        return r as __StrictHttpResponse<RabotaUaEmployerCodeModelResponsesOpenContactsCountResponse>
      })
    )
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `openContactsCount$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  openContactsCount$Json(): __Observable<RabotaUaEmployerCodeModelResponsesOpenContactsCountResponse> {
    return this.openContactsCount$Json$Response().pipe(
      __map((r: __StrictHttpResponse<RabotaUaEmployerCodeModelResponsesOpenContactsCountResponse>) => r.body as RabotaUaEmployerCodeModelResponsesOpenContactsCountResponse)
    )
  }

  /**
   * @param request undefined
   * @return Success
   */
  searchResponse(request?: CvSearchRequest): __Observable<__StrictHttpResponse<CvSearchResponse>> {
    let __params = this.newParams()
    let __headers = new HttpHeaders()
    let __body: any = null
    __body = request
    let req = new HttpRequest<any>('POST', this.rootUrl + `/resume/search`, __body, {
      headers: __headers,
      params: __params,
      responseType: 'json'
    })

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map(_r => {
        return _r as __StrictHttpResponse<CvSearchResponse>
      })
    )
  }

  /**
   * @param request undefined
   * @return Success
   */
  search(request?: CvSearchRequest): __Observable<CvSearchResponse> {
    return this.searchResponse(request).pipe(__map(_r => _r.body as CvSearchResponse))
  }

  /**
   * @param params The `ResumeService.GetParams` containing the following parameters:
   *
   * - `id`:
   *
   * - `markView`:
   *
   * @return Success
   */
  getResponse(params: ResumeService.GetParams): __Observable<__StrictHttpResponse<ResumeInfo>> {
    let __params = this.newParams()
    let __headers = new HttpHeaders()
    let __body: any = null

    if (params.markView != null) __params = __params.set('markView', params.markView.toString())
    let req = new HttpRequest<any>('GET', this.rootUrl + `/resume/${params.id}`, __body, {
      headers: __headers,
      params: __params,
      responseType: 'json'
    })

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map(_r => {
        return _r as __StrictHttpResponse<ResumeInfo>
      })
    )
  }

  /**
   * @param params The `ResumeService.GetParams` containing the following parameters:
   *
   * - `id`:
   *
   * - `markView`:
   *
   * @return Success
   */
  get(params: ResumeService.GetParams): __Observable<ResumeInfo> {
    return this.getResponse(params).pipe(__map(_r => _r.body as ResumeInfo))
  }

  /**
   * @param vacancyId undefined
   * @return Success
   */
  searchByVacancyResponse(vacancyId: number): __Observable<__StrictHttpResponse<CvSearchResponse>> {
    let __params = this.newParams()
    let __headers = new HttpHeaders()
    let __body: any = null

    let req = new HttpRequest<any>('GET', this.rootUrl + `/resume/byvacancy/${vacancyId}`, __body, {
      headers: __headers,
      params: __params,
      responseType: 'json'
    })

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map(_r => {
        return _r as __StrictHttpResponse<CvSearchResponse>
      })
    )
  }

  /**
   * @param vacancyId undefined
   * @return Success
   */
  searchByVacancy(vacancyId: number): __Observable<CvSearchResponse> {
    return this.searchByVacancyResponse(vacancyId).pipe(__map(_r => _r.body as CvSearchResponse))
  }

  /**
   * @param params The `ResumeService.RecommendByVacancyParams` containing the following parameters:
   *
   * - `VacancyId`:
   *
   * - `Start`:
   *
   * - `Page`:
   *
   * - `Count`:
   *
   * @return Success
   */
  recommendByVacancyResponse(params: ResumeService.RecommendByVacancyParams): __Observable<__StrictHttpResponse<ResumeRecommended>> {
    let __params = this.newParams()
    let __headers = new HttpHeaders()
    let __body: any = null
    if (params.VacancyId != null) __params = __params.set('VacancyId', params.VacancyId.toString())
    if (params.Start != null) __params = __params.set('Start', params.Start.toString())
    if (params.Page != null) __params = __params.set('Page', params.Page.toString())
    if (params.Count != null) __params = __params.set('Count', params.Count.toString())
    let req = new HttpRequest<any>('GET', this.rootUrl + `/resume/recommendbyvacancy`, __body, {
      headers: __headers,
      params: __params,
      responseType: 'json'
    })

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map(_r => {
        return _r as __StrictHttpResponse<ResumeRecommended>
      })
    )
  }

  /**
   * @param params The `ResumeService.RecommendByVacancyParams` containing the following parameters:
   *
   * - `VacancyId`:
   *
   * - `Start`:
   *
   * - `Page`:
   *
   * - `Count`:
   *
   * @return Success
   */
  recommendByVacancy(params: ResumeService.RecommendByVacancyParams): __Observable<ResumeRecommended> {
    return this.recommendByVacancyResponse(params).pipe(__map(_r => _r.body as ResumeRecommended))
  }

  /**
   * @param params The `ResumeService.RecommendByInactiveVacancyParams` containing the following parameters:
   *
   * - `VacancyId`:
   *
   * - `Start`:
   *
   * - `Page`:
   *
   * - `Count`:
   *
   * @return Success
   */
  recommendByInactiveVacancyResponse(params: ResumeService.RecommendByInactiveVacancyParams): __Observable<__StrictHttpResponse<ResumeRecommended>> {
    let __params = this.newParams()
    let __headers = new HttpHeaders()
    let __body: any = null
    if (params.VacancyId != null) __params = __params.set('VacancyId', params.VacancyId.toString())
    if (params.Start != null) __params = __params.set('Start', params.Start.toString())
    if (params.Page != null) __params = __params.set('Page', params.Page.toString())
    if (params.Count != null) __params = __params.set('Count', params.Count.toString())
    let req = new HttpRequest<any>('GET', this.rootUrl + `/resume/recommendbyinactivevacancy`, __body, {
      headers: __headers,
      params: __params,
      responseType: 'json'
    })

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map(_r => {
        return _r as __StrictHttpResponse<ResumeRecommended>
      })
    )
  }

  /**
   * @param params The `ResumeService.RecommendByInactiveVacancyParams` containing the following parameters:
   *
   * - `VacancyId`:
   *
   * - `Start`:
   *
   * - `Page`:
   *
   * - `Count`:
   *
   * @return Success
   */
  recommendByInactiveVacancy(params: ResumeService.RecommendByInactiveVacancyParams): __Observable<ResumeRecommended> {
    return this.recommendByInactiveVacancyResponse(params).pipe(__map(_r => _r.body as ResumeRecommended))
  }

  /**
   * @param params The `ResumeService.InterestedByVacancyParams` containing the following parameters:
   *
   * - `VacancyId`:
   *
   * - `Start`:
   *
   * - `Page`:
   *
   * - `Count`:
   *
   * @return Success
   */
  interestedByVacancyResponse(params: ResumeService.InterestedByVacancyParams): __Observable<__StrictHttpResponse<ResumeRecommended>> {
    let __params = this.newParams()
    let __headers = new HttpHeaders()
    let __body: any = null
    if (params.VacancyId != null) __params = __params.set('VacancyId', params.VacancyId.toString())
    if (params.Start != null) __params = __params.set('Start', params.Start.toString())
    if (params.Page != null) __params = __params.set('Page', params.Page.toString())
    if (params.Count != null) __params = __params.set('Count', params.Count.toString())
    let req = new HttpRequest<any>('GET', this.rootUrl + `/resume/interestedbyvacancy`, __body, {
      headers: __headers,
      params: __params,
      responseType: 'json'
    })

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map(_r => {
        return _r as __StrictHttpResponse<ResumeRecommended>
      })
    )
  }

  /**
   * @param params The `ResumeService.InterestedByVacancyParams` containing the following parameters:
   *
   * - `VacancyId`:
   *
   * - `Start`:
   *
   * - `Page`:
   *
   * - `Count`:
   *
   * @return Success
   */
  interestedByVacancy(params: ResumeService.InterestedByVacancyParams): __Observable<ResumeRecommended> {
    return this.interestedByVacancyResponse(params).pipe(__map(_r => _r.body as ResumeRecommended))
  }

  /**
   * @param params The `ResumeService.SearchByResumeParams` containing the following parameters:
   *
   * - `resumeId`:
   *
   * - `ukrainian`:
   *
   * - `count`:
   *
   * @return Success
   */
  searchByResumeResponse(params: ResumeService.SearchByResumeParams): __Observable<__StrictHttpResponse<SimilarCvResponse>> {
    let __params = this.newParams()
    let __headers = new HttpHeaders()
    let __body: any = null

    if (params.ukrainian != null) __params = __params.set('ukrainian', params.ukrainian.toString())
    if (params.count != null) __params = __params.set('count', params.count.toString())
    let req = new HttpRequest<any>('GET', this.rootUrl + `/resume/byresume/${params.resumeId}`, __body, {
      headers: __headers,
      params: __params,
      responseType: 'json'
    })

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map(_r => {
        return _r as __StrictHttpResponse<SimilarCvResponse>
      })
    )
  }

  /**
   * @param params The `ResumeService.SearchByResumeParams` containing the following parameters:
   *
   * - `resumeId`:
   *
   * - `ukrainian`:
   *
   * - `count`:
   *
   * @return Success
   */
  searchByResume(params: ResumeService.SearchByResumeParams): __Observable<SimilarCvResponse> {
    return this.searchByResumeResponse(params).pipe(__map(_r => _r.body as SimilarCvResponse))
  }

  /**
   * @param id undefined
   */
  openContactResponse(id: number): __Observable<__StrictHttpResponse<any>> {
    let __params = this.newParams()
    let __headers = new HttpHeaders()
    let __body: any = null

    let req = new HttpRequest<any>('POST', this.rootUrl + `/resume/open/${id}`, __body, {
      headers: __headers,
      params: __params,
      responseType: 'json'
    })

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map(_r => {
        return _r as __StrictHttpResponse<any>
      })
    )
  }

  /**
   * @param id undefined
   */
  openContact(id: number): __Observable<any> {
    return this.openContactResponse(id).pipe(__map(_r => _r.body as any))
  }

  /**
   * @return Success
   */
  messageResponse(): __Observable<__StrictHttpResponse<string>> {
    let __params = this.newParams()
    let __headers = new HttpHeaders()
    let __body: any = null
    let req = new HttpRequest<any>('GET', this.rootUrl + `/resume/message`, __body, {
      headers: __headers,
      params: __params,
      responseType: 'json'
    })

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map(_r => {
        return _r as __StrictHttpResponse<string>
      })
    )
  }

  /**
   * @return Success
   */
  message(): __Observable<string> {
    return this.messageResponse().pipe(__map(_r => _r.body as string))
  }

  /**
   * @param request undefined
   * @return Success
   */
  sendMessageResponse(request?: SendMessageRequest): __Observable<__StrictHttpResponse<CommonResponse>> {
    let __params = this.newParams()
    let __headers = new HttpHeaders()
    let __body: any = null
    __body = request
    let req = new HttpRequest<any>('POST', this.rootUrl + `/resume/message`, __body, {
      headers: __headers,
      params: __params,
      responseType: 'json'
    })

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map(_r => {
        return _r as __StrictHttpResponse<CommonResponse>
      })
    )
  }

  /**
   * @param request undefined
   * @return Success
   */
  sendMessage(request?: SendMessageRequest): __Observable<CommonResponse> {
    return this.sendMessageResponse(request).pipe(__map(_r => _r.body as CommonResponse))
  }

  /**
   * @param params The `ResumeService.AddSelectedResumeToVacancyParams` containing the following parameters:
   *
   * - `vacancyId`:
   *
   * - `resumeId`:
   */
  addSelectedResumeToVacancyResponse(params: ResumeService.AddSelectedResumeToVacancyParams): __Observable<__StrictHttpResponse<any>> {
    let __params = this.newParams()
    let __headers = new HttpHeaders()
    let __body: any = null
    if (params.vacancyId != null) __params = __params.set('vacancyId', params.vacancyId.toString())
    if (params.resumeId != null) __params = __params.set('resumeId', params.resumeId.toString())
    let req = new HttpRequest<any>('POST', this.rootUrl + `/resume/addtonotebook`, __body, {
      headers: __headers,
      params: __params,
      responseType: 'json'
    })

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map(_r => {
        return _r as __StrictHttpResponse<any>
      })
    )
  }

  /**
   * @param params The `ResumeService.AddSelectedResumeToVacancyParams` containing the following parameters:
   *
   * - `vacancyId`:
   *
   * - `resumeId`:
   */
  addSelectedResumeToVacancy(params: ResumeService.AddSelectedResumeToVacancyParams): __Observable<any> {
    return this.addSelectedResumeToVacancyResponse(params).pipe(__map(_r => _r.body as any))
  }

  /**
   * @param resumeId undefined
   */
  removeSelectedResumeResponse(resumeId?: number): __Observable<__StrictHttpResponse<any>> {
    let __params = this.newParams()
    let __headers = new HttpHeaders()
    let __body: any = null
    if (resumeId != null) __params = __params.set('resumeId', resumeId.toString())
    let req = new HttpRequest<any>('DELETE', this.rootUrl + `/resume/addtonotebook`, __body, {
      headers: __headers,
      params: __params,
      responseType: 'json'
    })

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map(_r => {
        return _r as __StrictHttpResponse<any>
      })
    )
  }

  /**
   * @param resumeId undefined
   */
  removeSelectedResume(resumeId?: number): __Observable<any> {
    return this.removeSelectedResumeResponse(resumeId).pipe(__map(_r => _r.body as any))
  }

  /**
   * @param params The `ResumeService.Get_1Params` containing the following parameters:
   *
   * - `Period`:
   *
   * - `Moveability`:
   *
   * - `Keywords`:
   *
   * - `CityId`:
   *
   * @return Success
   */
  get_1Response(params: ResumeService.Get_1Params): __Observable<__StrictHttpResponse<{ [key: string]: {} }>> {
    let __params = this.newParams()
    let __headers = new HttpHeaders()
    let __body: any = null
    if (params.Period != null) __params = __params.set('Period', params.Period.toString())
    if (params.Moveability != null) __params = __params.set('Moveability', params.Moveability.toString())
    if (params.Keywords != null) __params = __params.set('Keywords', params.Keywords.toString())
    if (params.CityId != null) __params = __params.set('CityId', params.CityId.toString())
    let req = new HttpRequest<any>('POST', this.rootUrl + `/resume/cvcount`, __body, {
      headers: __headers,
      params: __params,
      responseType: 'json'
    })

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map(_r => {
        return _r as __StrictHttpResponse<{ [key: string]: {} }>
      })
    )
  }

  /**
   * @param params The `ResumeService.Get_1Params` containing the following parameters:
   *
   * - `Period`:
   *
   * - `Moveability`:
   *
   * - `Keywords`:
   *
   * - `CityId`:
   *
   * @return Success
   */
  get_1(params: ResumeService.Get_1Params): __Observable<{ [key: string]: {} }> {
    return this.get_1Response(params).pipe(__map(_r => _r.body as { [key: string]: {} }))
  }

  /**
   * @param params The `ResumeService.OpenableParams` containing the following parameters:
   *
   * - `rubricIds`:
   *
   * - `cityIds`:
   *
   * @return Success
   */
  openableResponse(params: ResumeService.OpenableParams): __Observable<__StrictHttpResponse<Array<CvDbTicket>>> {
    let __params = this.newParams()
    let __headers = new HttpHeaders()
    let __body: any = null
    if (params.rubricIds != null) __params = __params.set('rubricIds', params.rubricIds.toString())
    if (params.cityIds != null) __params = __params.set('cityIds', params.cityIds.toString())
    let req = new HttpRequest<any>('GET', this.rootUrl + `/resume/openable`, __body, {
      headers: __headers,
      params: __params,
      responseType: 'json'
    })

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map(_r => {
        return _r as __StrictHttpResponse<Array<CvDbTicket>>
      })
    )
  }

  /**
   * @param params The `ResumeService.OpenableParams` containing the following parameters:
   *
   * - `rubricIds`:
   *
   * - `cityIds`:
   *
   * @return Success
   */
  openable(params: ResumeService.OpenableParams): __Observable<Array<CvDbTicket>> {
    return this.openableResponse(params).pipe(__map(_r => _r.body as Array<CvDbTicket>))
  }

  /**
   * @param params The `ResumeService.GetResumeParams` containing the following parameters:
   *
   * - `id`:
   *
   * - `User-Agent`:
   */
  getResumeResponse(params: ResumeService.GetResumeParams): __Observable<__StrictHttpResponse<string>> {
    let __params = this.newParams()
    let __headers = new HttpHeaders()
    let __body: any = null

    if (params.UserAgent != null) __headers = __headers.set('User-Agent', params.UserAgent.toString())
    let req = new HttpRequest<any>('GET', this.rootUrl + `/resume/getfile/${params.id}`, __body, {
      headers: __headers,
      params: __params,
      responseType: 'blob'
    })

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map(_r => {
        return _r as __StrictHttpResponse<string>
      })
    )
  }

  /**
   * @param params The `ResumeService.GetResumeParams` containing the following parameters:
   *
   * - `id`:
   *
   * - `User-Agent`:
   */
  getResume(params: ResumeService.GetResumeParams): __Observable<__StrictHttpResponse<string>> {
    return this.getResumeResponse(params).pipe(__map(_r => _r as __StrictHttpResponse<string>))
  }

  /**
   * @param params The `ResumeService.ResumeAsFileParams` containing the following parameters:
   *
   * - `request`:
   *
   * - `User-Agent`:
   */
  resumeAsFileResponse(params: ResumeService.ResumeAsFileParams): __Observable<__StrictHttpResponse<any>> {
    let __params = this.newParams()
    let __headers = new HttpHeaders()
    let __body: any = null
    __body = params.request
    if (params.UserAgent != null) __headers = __headers.set('User-Agent', params.UserAgent.toString())
    let req = new HttpRequest<any>('POST', this.rootUrl + `/resume/file`, __body, {
      headers: __headers,
      params: __params,
      responseType: 'blob'
    })

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map(_r => {
        return _r as __StrictHttpResponse<any>
      })
    )
  }

  /**
   * @param params The `ResumeService.ResumeAsFileParams` containing the following parameters:
   *
   * - `request`:
   *
   * - `User-Agent`:
   */
  resumeAsFile(params: ResumeService.ResumeAsFileParams): __Observable<__StrictHttpResponse<any>> {
    return this.resumeAsFileResponse(params).pipe(__map(_r => _r as __StrictHttpResponse<any>))
  }

  /**
   * @param cvId undefined
   */
  dislikeCvResponse(cvId: number): __Observable<__StrictHttpResponse<any>> {
    let __params = this.newParams()
    let __headers = new HttpHeaders()
    let __body: any = null

    let req = new HttpRequest<any>('POST', this.rootUrl + `/resume/dislike/${cvId}`, __body, {
      headers: __headers,
      params: __params,
      responseType: 'json'
    })

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map(_r => {
        return _r as __StrictHttpResponse<any>
      })
    )
  }

  /**
   * @param cvId undefined
   */
  dislikeCv(cvId: number): __Observable<any> {
    return this.dislikeCvResponse(cvId).pipe(__map(_r => _r.body as any))
  }

  /**
   * @param cvId undefined
   */
  dislikeCvRevocationResponse(cvId: number): __Observable<__StrictHttpResponse<any>> {
    let __params = this.newParams()
    let __headers = new HttpHeaders()
    let __body: any = null

    let req = new HttpRequest<any>('POST', this.rootUrl + `/resume/dislike/revocation/${cvId}`, __body, {
      headers: __headers,
      params: __params,
      responseType: 'json'
    })

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map(_r => {
        return _r as __StrictHttpResponse<any>
      })
    )
  }

  /**
   * @param cvId undefined
   */
  dislikeCvRevocation(cvId: number): __Observable<any> {
    return this.dislikeCvRevocationResponse(cvId).pipe(__map(_r => _r.body as any))
  }
}

module ResumeService {
  /**
   * Parameters for Get
   */
  export interface GetParams {
    id: number
    markView?: boolean
  }

  /**
   * Parameters for RecommendByVacancy
   */
  export interface RecommendByVacancyParams {
    VacancyId?: number
    Start?: number
    Page?: number
    Count?: number
  }

  /**
   * Parameters for RecommendByInactiveVacancy
   */
  export interface RecommendByInactiveVacancyParams {
    VacancyId?: number
    Start?: number
    Page?: number
    Count?: number
  }

  /**
   * Parameters for InterestedByVacancy
   */
  export interface InterestedByVacancyParams {
    VacancyId?: number
    Start?: number
    Page?: number
    Count?: number
  }

  /**
   * Parameters for SearchByResume
   */
  export interface SearchByResumeParams {
    resumeId: number
    ukrainian?: boolean
    count?: number
  }

  /**
   * Parameters for AddSelectedResumeToVacancy
   */
  export interface AddSelectedResumeToVacancyParams {
    vacancyId?: number
    resumeId?: number
  }

  /**
   * Parameters for Get_1
   */
  export interface Get_1Params {
    Period?: number
    Moveability?: boolean
    Keywords?: string
    CityId?: number
  }

  /**
   * Parameters for Openable
   */
  export interface OpenableParams {
    rubricIds?: string
    cityIds?: string
  }

  /**
   * Parameters for GetResume
   */
  export interface GetResumeParams {
    id: number
    UserAgent?: string
  }

  /**
   * Parameters for ResumeAsFile
   */
  export interface ResumeAsFileParams {
    request?: CvDownloadRequest
    UserAgent?: string
  }
}

export { ResumeService }
