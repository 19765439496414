import { FormArray, FormGroup } from '@angular/forms'

export const markFormAsTouched = (form: FormGroup | FormArray): void => {
  for (const control of Object.values(form.controls)) {
    if (control instanceof FormGroup || control instanceof FormArray) {
      markFormAsTouched(control)
    } else {
      control.markAsTouched()
    }
  }
}

export const markFormAsDirty = (form: FormGroup | FormArray): void => {
  for (const control of Object.values(form.controls)) {
    if (control instanceof FormGroup || control instanceof FormArray) {
      markFormAsDirty(control)
    } else {
      control.markAsDirty()
    }
  }
}
