import { Environment } from '@alliance/shared/environment'
import { ClientIdMiddlewareService, GetApolloClientMetricsService } from '@alliance/shared/gql/utils'
import { GqlLanguageMiddlewareService } from '@alliance/shared/translation'
import { DetectPlatformService } from '@alliance/shared/utils'
import { CommonModule } from '@angular/common'
import { HttpClientModule } from '@angular/common/http'
import { NgModule } from '@angular/core'
import { makeStateKey, TransferState } from '@angular/platform-browser'
import { from, InMemoryCache, NormalizedCacheObject } from '@apollo/client/core'
import { Apollo } from 'apollo-angular'
import { HttpLink, HttpLinkHandler } from 'apollo-angular/http'
import { JOBSEEKER_APOLLO_CLIENT_NAME } from './constants'

const STATE_KEY = makeStateKey<unknown>(JOBSEEKER_APOLLO_CLIENT_NAME)

@NgModule({
  exports: [CommonModule, HttpClientModule],
  providers: [GqlLanguageMiddlewareService, ClientIdMiddlewareService]
})
export class JobseekerDomainGqlModule {
  public cache: InMemoryCache | undefined
  public link: HttpLinkHandler | undefined

  // eslint-disable-next-line max-lines-per-function
  public constructor(
    private apollo: Apollo,
    private httpLink: HttpLink,
    private environment: Environment,
    private transferState: TransferState,
    private platform: DetectPlatformService,
    private langMiddlewareService: GqlLanguageMiddlewareService,
    private getApolloClientMetricsService: GetApolloClientMetricsService,
    private clientIdMiddlewareService: ClientIdMiddlewareService
  ) {
    if (!this.apollo.use(JOBSEEKER_APOLLO_CLIENT_NAME)) {
      this.cache = new InMemoryCache({
        typePolicies: {
          AnswerOption: {
            keyFields: false
          }
        }
      })

      const link = from([
        this.langMiddlewareService.getMiddleware(),
        this.clientIdMiddlewareService.getMiddleware(),
        this.httpLink.create({
          uri: this.environment.graphqlApi,
          withCredentials: false
        })
      ])

      this.apollo.createNamed(JOBSEEKER_APOLLO_CLIENT_NAME, {
        link,
        cache: this.cache,
        ...this.getApolloClientMetricsService.apolloClientMetrics,
        defaultOptions: {
          watchQuery: {
            errorPolicy: 'all'
          },
          query: {
            errorPolicy: 'all'
          }
        },
        connectToDevTools: true,
        ...(this.platform.isBrowser
          ? {
              // queries with `forceFetch` enabled will be delayed
              ssrForceFetchDelay: 200
            }
          : {
              // avoid to run twice queries with `forceFetch` enabled
              ssrMode: true
            })
      })

      if (this.platform.isBrowser) {
        this.onBrowser()
      } else {
        this.onServer()
      }
    }
  }

  public onServer(): void {
    // serializes the cache and puts it under a key
    this.transferState.onSerialize(STATE_KEY, () => this.cache?.extract())
  }

  public onBrowser(): void {
    // reads the serialized cache
    const state = this.transferState.get<NormalizedCacheObject | null>(STATE_KEY, null)
    // and puts it in the Apollo
    if (state) {
      this.cache?.restore(state)
    }
  }
}
