import { concat, from, Observable, ReplaySubject } from 'rxjs'
import { Directive, OnDestroy, OnInit, ɵmarkDirty as markDirty } from '@angular/core'
import { mergeMap, takeUntil, tap } from 'rxjs/operators'
import { RxState } from '@rx-angular/state'

type ObservableDictionary<T> = {
  [P in keyof T]: Observable<T[P]>
}

const OnInitSubject = Symbol('OnInitSubject')
const OnDestroySubject = Symbol('OnDestroySubject')

@Directive()
export abstract class ReactiveComponent implements OnInit, OnDestroy {
  private [OnInitSubject] = new ReplaySubject<true>(1)
  private [OnDestroySubject] = new ReplaySubject<true>(1)

  public get onInit$(): Observable<true> {
    return this[OnInitSubject].asObservable()
  }

  public get onDestroy$(): Observable<true> {
    return this[OnDestroySubject].asObservable()
  }

  public connectState<K extends keyof T, T extends object>(state: RxState<T>, keys: K[]): Pick<T, K & keyof T> {
    const sink = {} as Pick<T, K & keyof T>
    const updateSink$ = from(keys).pipe(
      mergeMap(sourceKey =>
        state.select(sourceKey).pipe(
          tap(sinkValue => {
            sink[sourceKey] = sinkValue
          })
        )
      )
    )
    concat(this.onInit$, updateSink$)
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(() => {
        markDirty(this)
      })
    return sink
  }

  public connect<T>(sources: ObservableDictionary<T>): T {
    const sink = {} as T
    const sourceKeys = Object.keys(sources) as Array<keyof T>
    const updateSink$ = from(sourceKeys).pipe(
      mergeMap(sourceKey => {
        const source$ = sources[sourceKey]

        return source$.pipe(
          tap(sinkValue => {
            sink[sourceKey] = sinkValue
          })
        )
      })
    )

    concat(this.onInit$, updateSink$)
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(() => {
        markDirty(this)
      })

    return sink
  }

  public ngOnInit(): void {
    this[OnInitSubject].next(true)
    this[OnInitSubject].complete()
  }

  public ngOnDestroy(): void {
    this[OnDestroySubject].next(true)
    this[OnDestroySubject].complete()
  }
}
