import { Environment } from '@alliance/shared/environment'

// take from https://raw.githubusercontent.com/cyclosproject/ng-openapi-gen/master/templates/module.handlebars
// and comment code from constructor and below

import { NgModule, ModuleWithProviders } from '@angular/core'

import { ApplyApiDataAccessConfiguration, ApplyApiDataAccessConfigurationParams } from './apply-api-data-access-configuration'

import { ApplyApplyIdService } from './services/apply-apply-id.service'
import { ApplyResumeIdService } from './services/apply-resume-id.service'
import { ApplyAbTestsService } from './services/apply-ab-tests.service'
import { ApplyAttachApplicationService } from './services/apply-attach-application.service'
import { ApplyAttachRepeatedApplicationService } from './services/apply-attach-repeated-application.service'
import { ApplyCandidatesComparisonService } from './services/apply-candidates-comparison.service'
import { ApplyElasticImportService } from './services/apply-elastic-import.service'
import { ApplyNoCvApplicationService } from './services/apply-no-cv-application.service'
import { ApplyProfCvApplicationService } from './services/apply-prof-cv-application.service'

/**
 * Module that provides all services and configuration.
 */
@NgModule({
  imports: [],
  exports: [],
  declarations: [],
  providers: [
    ApplyApplyIdService,
    ApplyResumeIdService,
    ApplyAbTestsService,
    ApplyAttachApplicationService,
    ApplyAttachRepeatedApplicationService,
    ApplyCandidatesComparisonService,
    ApplyElasticImportService,
    ApplyNoCvApplicationService,
    ApplyProfCvApplicationService,
    {
      provide: ApplyApiDataAccessConfiguration,
      useFactory: (environment: Environment): ApplyApiDataAccessConfigurationParams => ({
        rootUrl: environment.applyApi
      }),
      deps: [Environment]
    }
  ]
})
export class ApplyApiDataAccessModule {
  public static forRoot(params: ApplyApiDataAccessConfigurationParams): ModuleWithProviders<ApplyApiDataAccessModule> {
    return {
      ngModule: ApplyApiDataAccessModule,
      providers: [
        {
          provide: ApplyApiDataAccessConfiguration,
          useValue: params
        }
      ]
    }
  }

  /*
    public constructor(
      @Optional() @SkipSelf() parentModule: ApplyApiDataAccessModule,
      @Optional() http: HttpClient
    ) {

      if (parentModule) {
        throw new Error('ApplyApiDataAccessModule is already loaded. Import in your base AppModule only.');
      }
      if (!http) {
        throw new Error('You need to import the HttpClientModule in your AppModule! \n' +
          'See also https://github.com/angular/angular/issues/20575');
      }

    } */
}
