import { Injectable } from '@angular/core'
import { Observable } from 'rxjs'

import { AppliesCountService } from '@alliance/employer/vacancies/domain'

@Injectable({ providedIn: 'root' })
export class AppliesCountApiService {
  public constructor(private readonly appliesCountService: AppliesCountService) {}

  public getNewCandidatesCount$(): Observable<number> {
    return this.appliesCountService.getNewCandidatesCount$()
  }
}
