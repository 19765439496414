export const GetPrepositionByWordFirstLetters = (title: string, langUk: boolean = true): string => {
  const ukrRegexp = /^(в|ф|льв|св|тв|хв|В|Ф|Льв|Св|Тв|Хв)/g
  const ruRegexp = /^((в|В|ф|Ф|л|Л|ль|Ль)[^АаОоУуИиЕеЁёЭэЮюЯяЫы])/gi

  if (langUk) {
    return title.match(ukrRegexp) ? 'у' : 'в'
  } else {
    return title.match(ruRegexp) ? 'во' : 'в'
  }
}
