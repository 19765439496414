import { Injectable } from '@angular/core'
import { RxStateService } from '@alliance/shared/models'
import { catchError, map, switchMap } from 'rxjs/operators'
import { iif, Observable, of } from 'rxjs'
import { AuthService } from '@alliance/shared/auth/api'
import { AccountJobsearcherService, VacancyMediaItemDto } from '@alliance/jobseeker/data-access'
import { ApolloCache, defaultDataIdFromObject, FetchResult } from '@apollo/client/core'
import {
  AddVacancyToFavoriteGQL,
  AddVacancyToFavoriteMutation,
  DislikeVacancyGQL,
  DislikeVacancyMutation,
  PublishedVacancyPageFragment,
  RemoveVacancyFromFavoriteGQL,
  RemoveVacancyFromFavoriteMutation,
  SeekerFavoriteCounterGQL,
  UnDilikeVacancyGQL,
  UnDilikeVacancyMutation
} from '@alliance/jobseeker/domain-gql'
import { VacancyMediaType } from '@alliance/shared/domain-gql'
import { LabelTypeEnum, VacancyLabelArrayItem, VacancyLabelData } from '../models/models'

export interface UpdateFavoriteCache {
  cache: ApolloCache<AddVacancyToFavoriteMutation> | ApolloCache<RemoveVacancyFromFavoriteMutation>
  id: string
  isFavorite: boolean
}

export interface UpdateDislikeCache {
  cache: ApolloCache<DislikeVacancyMutation> | ApolloCache<UnDilikeVacancyMutation>
  id: string
  isDisliked: boolean
}

@Injectable({
  providedIn: 'root'
})
export class VacancyService extends RxStateService<{
  favoritesCount: number | null
}> {
  public constructor(
    private authService: AuthService,
    private accountJsService: AccountJobsearcherService,
    private addVacancyToFavoriteGQL: AddVacancyToFavoriteGQL,
    private removeVacancyFromFavoriteGQL: RemoveVacancyFromFavoriteGQL,
    private dislikeVacancyGQL: DislikeVacancyGQL,
    private unDilikeVacancyGQL: UnDilikeVacancyGQL,
    private seekerFavoriteCounterGQL: SeekerFavoriteCounterGQL
  ) {
    super()

    this.initState({
      favoritesCount: this.authService.token$.pipe(
        switchMap(value =>
          iif(() => !!value && !this.authService.isEmployer, this.seekerFavoriteCounterGQL.fetch().pipe(map(({ data }) => Number(data?.seekerFavoriteVacancies?.totalCount?.value ?? 0))), of(null))
        ),
        catchError(() => of(null))
      )
    })
  }

  public addToFavorite(vacancyId: string | null, updateCacheFunc: null | ((params: UpdateFavoriteCache) => void) = null): Observable<FetchResult> {
    const vacId = vacancyId || ''
    return this.addVacancyToFavoriteGQL.mutate(
      { vacancyId: vacId },
      {
        optimisticResponse: {
          __typename: 'Mutation',
          seekerFavoriteVacancies: {
            __typename: 'SeekerFavoriteVacancyMutations',
            add: {
              __typename: 'AddSeekerFavoriteVacanciesOutput',
              vacancies: [
                {
                  id: vacId
                }
              ]
            }
          }
        },
        update: (cache, { data }) => {
          const id = defaultDataIdFromObject(data?.seekerFavoriteVacancies?.add?.vacancies?.[0] || {})
          if (id && updateCacheFunc) {
            updateCacheFunc({
              cache,
              id,
              isFavorite: true
            })
          }
        }
      }
    )
  }

  public removeToFavorite(vacancyId: string | null, updateCacheFunc: null | ((params: UpdateFavoriteCache) => void) = null): Observable<FetchResult> {
    const vacId = vacancyId || ''
    return this.removeVacancyFromFavoriteGQL.mutate(
      { vacancyId: vacId },
      {
        optimisticResponse: {
          __typename: 'Mutation',
          seekerFavoriteVacancies: {
            __typename: 'SeekerFavoriteVacancyMutations',
            remove: {
              __typename: 'RemoveSeekerFavoriteVacanciesOutput',
              vacancies: [
                {
                  id: vacId
                }
              ]
            }
          }
        },
        update: (cache, { data }) => {
          const id = defaultDataIdFromObject(data?.seekerFavoriteVacancies?.remove?.vacancies?.[0] || {})
          if (id && updateCacheFunc) {
            updateCacheFunc({
              cache,
              id,
              isFavorite: false
            })
          }
        }
      }
    )
  }

  public dislikeVacancy(vacancyId: string | null, updateCacheFunc: null | ((params: UpdateDislikeCache) => void) = null): Observable<FetchResult> {
    const vacId = vacancyId || ''
    return this.dislikeVacancyGQL.mutate(
      { vacancyId: vacId },
      {
        optimisticResponse: {
          __typename: 'Mutation',
          seekerDislikedVacancies: {
            __typename: 'SeekerDislikedVacancyMutations',
            dislike: {
              __typename: 'DislikeSeekerVacanciesOutput',
              vacancies: [
                {
                  id: vacId
                }
              ]
            }
          }
        },
        update: (cache, { data }) => {
          const id = defaultDataIdFromObject(data?.seekerDislikedVacancies?.dislike?.vacancies?.[0] || {})
          if (id && updateCacheFunc) {
            updateCacheFunc({
              cache,
              id,
              isDisliked: true
            })
          }
        }
      }
    )
  }

  public unDislikeVacancy(vacancyId: string | null, updateCacheFunc: null | ((params: UpdateDislikeCache) => void) = null): Observable<FetchResult> {
    const vacId = vacancyId || ''
    return this.unDilikeVacancyGQL.mutate(
      { vacancyId: vacId },
      {
        optimisticResponse: {
          __typename: 'Mutation',
          seekerDislikedVacancies: {
            __typename: 'SeekerDislikedVacancyMutations',
            undislike: {
              __typename: 'UndislikeSeekerVacanciesOutput',
              vacancies: [
                {
                  id: vacId
                }
              ]
            }
          }
        },
        update: (cache, { data }) => {
          const id = defaultDataIdFromObject(data?.seekerDislikedVacancies?.undislike?.vacancies?.[0] || {})
          if (id && updateCacheFunc) {
            updateCacheFunc({
              cache,
              id,
              isDisliked: false
            })
          }
        }
      }
    )
  }

  public getSliderItems(media: PublishedVacancyPageFragment): VacancyMediaItemDto[] | null {
    if (!media) {
      return null
    }

    const { media: _media } = media

    return (_media || []).map(item => {
      const { description = '', url = '', type = VacancyMediaType.UploadedImage } = item || {}

      let narrowedType: VacancyMediaItemDto.TypeEnum
      switch (type) {
        case VacancyMediaType.UploadedImage:
          narrowedType = VacancyMediaItemDto.TypeEnum.UploadedImage
          break
        case VacancyMediaType.YoutubeVideo:
          narrowedType = VacancyMediaItemDto.TypeEnum.YoutubeVideo
          break
        default:
          narrowedType = VacancyMediaItemDto.TypeEnum.UploadedImage
      }
      return {
        url,
        type: narrowedType,
        description: description ?? ''
      }
    })
  }

  public getSearchTag(data: PublishedVacancyPageFragment | undefined | null): string {
    if (!data) {
      return ''
    }

    return data.keyTagGroups && data.keyTagGroups.length ? data?.keyTagGroups[0]?.name || '' : data.title
  }

  public getVacancyLabel(data: VacancyLabelData): VacancyLabelArrayItem[] {
    return [
      {
        type: LabelTypeEnum.hot,
        show: data.hot
      },
      {
        type: LabelTypeEnum.close,
        show: data.closed
      },
      {
        type: LabelTypeEnum.apply,
        show: !!data?.apply?.isApply,
        date: data?.apply?.date || undefined
      },
      {
        type: LabelTypeEnum.remote,
        show: data.remote
      }
    ]
  }

  public likeNoGraphQlVacancy(id: number, like: boolean): Observable<Record<string, never>> {
    return this.accountJsService.vacancyJobsearcherLike({ id, like })
  }
}
