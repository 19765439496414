import { Injectable } from '@angular/core'
import { Observable, of } from 'rxjs'
import { PartialSeoParamsResponse, PlatformHosts } from '../models'
import { Translations } from '../localization/translations'
import { TranslationService } from '@alliance/shared/translation'
import { HelpersService } from '../helpers.service'

@Injectable({
  providedIn: 'root'
})
export class SearchByProfessionsAlphabetDictionaryService {
  public constructor(private translations: Translations, private translationService: TranslationService, private helpersService: HelpersService) {}

  public getParams(): Observable<PartialSeoParamsResponse> {
    const url = `/jobsearch/professions`

    return of({
      title: this.translationService.translate(this.translations.searchProfAlphabet.title),
      description: this.translationService.translate(this.translations.searchProfAlphabet.description),
      h1: [this.translationService.translate(this.translations.searchProfAlphabet.h1)],
      canonicalUrl: this.helpersService.createURL(PlatformHosts.desktop, url),
      hrefLang: this.helpersService.createHrefLangURLs(url, url),
      noIndexNoFollow: false,
      jsonLd: {
        desktop: this.helpersService.createJsonLd(PlatformHosts.desktop)
      }
    })
  }
}
