import { ModuleWithProviders, NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { SharedTranslationModule } from '@alliance/shared/translation'
import { PlatformHosts, SEO_PLATFORM_TOKEN } from './models'
import { SeoService } from './seo.service'
import { SharedPipesTimePeriodModule } from '@alliance/shared/pipes/time-period'
import { SharedPipesPrettyNumberModule } from '@alliance/shared/pipes/pretty-number'

@NgModule({
  imports: [CommonModule, SharedTranslationModule, SharedPipesTimePeriodModule, SharedPipesPrettyNumberModule],
  providers: [SeoService]
})
export class CoreSeoModule {
  public static forRoot(platform: PlatformHosts): ModuleWithProviders<CoreSeoModule> {
    return {
      ngModule: CoreSeoModule,
      providers: [
        {
          provide: SEO_PLATFORM_TOKEN,
          useValue: platform
        }
      ]
    }
  }
}
