import { NotificationAlertRerenderConfigEventPayload, PubsubMessageTypesEnum } from '@alliance/shared/utils'
import { animate, state, style, transition, trigger } from '@angular/animations'
import { ChangeDetectionStrategy, Component, Input, OnDestroy } from '@angular/core'
import { ModalComponent } from '../modal/modal.component'
import { VerticalModalPositionEnum } from '../../models/vertical-modal-postion.enum'

@Component({
  selector: 'santa-vertical-modal',
  templateUrl: './vertical-modal.component.html',
  styleUrls: ['./vertical-modal.component.tw.css'],
  animations: [
    trigger('openState', [
      state('void, exit', style({ transform: 'translateX(100%)' })),
      state(
        'opened',
        style({
          transform: 'none'
        })
      ),
      transition('* => *', [animate('200ms cubic-bezier(0.86, 0, 0.07, 1)')])
    ]),
    trigger('openStateLeft', [
      state('void, exit', style({ transform: 'translateX(-100%)' })),
      state(
        'opened',
        style({
          transform: 'none'
        })
      ),
      transition('* => *', [animate('200ms cubic-bezier(0.86, 0, 0.07, 1)')])
    ])
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class VerticalModalComponent<T> extends ModalComponent<T> implements OnDestroy {
  @Input() public direction: VerticalModalPositionEnum = VerticalModalPositionEnum.RIGHT
  @Input() public offset = 0

  public readonly PositionEnum = VerticalModalPositionEnum
  public readonly positionConfig: Record<VerticalModalPositionEnum, string> = {
    [VerticalModalPositionEnum.LEFT]: 'vertical-modal-left-direction',
    [VerticalModalPositionEnum.RIGHT]: 'vertical-modal-right-direction'
  }

  public override ngOnDestroy(): void {
    super.ngOnDestroy()

    this.transfer.publish<NotificationAlertRerenderConfigEventPayload>(PubsubMessageTypesEnum.rerenderNotificationAlert, {
      recreateOverlay: false,
      onlyCustomEvents: false
    })
  }
}
