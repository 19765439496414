export type EnvironmentAppEnum = 'desktop' | 'admin-crm' | 'admin-mailtpl' | 'teams' | 'clubs' | 'ats' | null
// FIXME: do we need this at all?
export const EnvironmentApp = {
  demo: 'demo' as EnvironmentAppEnum, // FIXME: what?
  desktop: 'desktop' as EnvironmentAppEnum,
  admin_crm: 'admin-crm' as EnvironmentAppEnum,
  admin_mailtpl: 'admin-mailtpl' as EnvironmentAppEnum,
  teams: 'teams' as EnvironmentAppEnum,
  clubs: 'clubs' as EnvironmentAppEnum,
  ats: 'ats' as EnvironmentAppEnum
}

// FIXME: remove me at all, environment may be derived from ENV_NAME environment variable and should be injected via DI
export type EnvironmentNameEnum = 'test2' | 'prod' | 'dev'
export const EnvironmentName = {
  prod: 'Production' as EnvironmentNameEnum,
  dev: 'Employer' as EnvironmentNameEnum, // FIXME: this one seems to be wrong
  staging: 'Staging' as EnvironmentNameEnum
}

// FIXME: class properties driffted align Environment, EnvironmentOctopus and EnvironmentDev
export abstract class Environment {
  // FIXME: what - abstract prop?
  public abstract readonly name: EnvironmentNameEnum
  public abstract readonly app: EnvironmentAppEnum
  public abstract readonly production: boolean
  public abstract readonly domain?: string
  public abstract readonly authApi: string
  public abstract readonly accountHost?: string
  public abstract readonly publicHost?: string
  public abstract readonly mainSiteUrl?: string
  public abstract readonly mobileSiteUrl?: string
  public abstract readonly cloudFlareUrl?: string
  public abstract readonly companyLogoUrl?: string
  public abstract readonly attachResumeUrl?: string
  public abstract readonly keyWords?: string
  public abstract readonly gtm?: string
  public abstract readonly api?: string
  public abstract readonly usersApi?: string
  public abstract readonly chatApi?: string
  public abstract readonly socketApi?: string
  public abstract readonly jobseekerApi?: string
  public abstract readonly jobseekerApiUk?: string
  public abstract readonly employerApi?: string
  public abstract readonly promoApi?: string
  public abstract readonly matchingClustersApi?: string
  public abstract readonly managerUrl?: string
  public abstract readonly statistic?: string
  public abstract readonly admin8Api?: string
  public abstract readonly portfolio?: string
  public abstract readonly matchingEuropeApi?: string
  public abstract readonly matchingRecomApi?: string
  public abstract readonly attachViaAdminUrl?: string
  public abstract readonly notificationApi?: string
  public abstract readonly adminApi?: string
  public abstract readonly adjwtApi?: string
  public abstract readonly crmApiUrl?: string
  public abstract readonly crm20ApiUrl?: string
  public abstract readonly graphqlApi?: string
  public abstract readonly graphqlLimitsApi?: string
  public abstract readonly admin8Host?: string
  public abstract readonly graphqlApiCredentials?: boolean
  public abstract readonly vacancyApi?: string
  public abstract readonly applyApi?: string
  public abstract readonly employerAppsApi?: string
  public abstract readonly vacancySearchApi?: string
  public abstract readonly multiUserStatisticsApi?: string
  public abstract readonly employerServiceApi?: string
  public abstract readonly paymentApi?: string
  public abstract readonly hubApi?: string
  public abstract readonly companyLogoUrlHostname?: string
  public abstract readonly companyEmployeesServiceApi?: string
  public abstract readonly companyServiceApi?: string
  public abstract readonly gtmIdDesktop?: string
  public abstract readonly gtmIdMobile?: string
  public abstract readonly clubsApi?: string
  public abstract readonly clubsStaticAWS?: string
  public abstract readonly targetMailingApi?: string
  public abstract readonly categorizationAdminApi?: string
  public abstract readonly budni?: string
  public abstract readonly feedbackApi?: string
  public abstract readonly googleClientId?: string
  public abstract readonly atsApi?: string
  public abstract readonly ats?: string
  public abstract readonly graphqlAtsApi?: string
  public abstract readonly tagsSuggesterApi?: string
  public abstract readonly bannerApi?: string
  public abstract readonly marketingApi?: string
  public abstract readonly chatGptProxy?: string
}
