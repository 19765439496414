import { VacancyPublishPeriod } from '@alliance/shared/domain-gql'
import { endOfToday, getDateFromString } from '@alliance/shared/datetime'

export type VacancyPublishPeriodForStatistic = Pick<VacancyPublishPeriod, 'autoProlongEnd' | 'end'>

export const getEndDateOfVacancyStatistic = (publishPeriod: VacancyPublishPeriodForStatistic): Date => {
  const todayEndDate = endOfToday()
  const endAtDate = getDateFromString(publishPeriod.autoProlongEnd || publishPeriod.end)

  return !endAtDate || endAtDate > todayEndDate ? todayEndDate : endAtDate
}
