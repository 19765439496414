import { EmployerAccessByRightsEnum, EmployerRightsService } from '@alliance/employer/gql-domain'
import { AuthService } from '@alliance/shared/auth/api'
import { Injectable } from '@angular/core'
import { Observable, of } from 'rxjs'
import { map, switchMap } from 'rxjs/operators'

@Injectable()
export class VacanciesEmployerIdsService {
  private readonly userId = this.authService.user?.MultiUserId?.toString() || ''

  public constructor(private readonly authService: AuthService, private readonly employerRightsService: EmployerRightsService) {}

  public getEmployerIds$(): Observable<string[] | null> {
    return this.authService.token$.pipe(
      map(token => !!token && this.authService.isEmployer),
      switchMap(isLoggedIn =>
        isLoggedIn
          ? this.employerRightsService
              .hasRightTo$(EmployerAccessByRightsEnum.viewSomeoneElseVacancies)
              .pipe(map(hasRightToViewSomeoneElseVacancies => (!hasRightToViewSomeoneElseVacancies ? [this.userId] : [])))
          : of(null)
      )
    )
  }
}
