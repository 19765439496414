<div class="santa-px-10">
  <alliance-icon [name]="closeIcon.name" [inheritColor]="true" (click)="closeDialog()" class="santa-absolute santa-top-10 santa-right-10 santa-text-black-800"></alliance-icon>
  <img class="santa-block santa-mx-auto" src="https://images.cf-rabota.com.ua/alliance/app-promo-banner.svg" alt="app promo banner" height="80" width="80" loading="eager" />
  <p class="santa-mb-10 santa-typo-h3 santa-text-center">{{ translations.appBetter | translate }}</p>
  <div class="santa-p-10 santa-bg-black-100">
    <p class="santa-block santa-typo-additional santa-mt-10" *ngFor="let tip of tipsList; trackBy: trackByFn">
      <alliance-icon [name]="tip.icon"></alliance-icon>
      <span class="santa-ml-10">{{ tip.text }}</span>
    </p>
  </div>
  <santa-button [fullWidth]="true" class="santa-mt-10 santa-block" (click)="goToStore()">
    <div class="santa-flex">
      <img
        *ngIf="state.platform === 'ios'"
        class="santa-block santa-mx-auto"
        src="https://images.cf-rabota.com.ua/alliance/app-promo-appstore.svg"
        alt="app promo apple store/"
        width="auto"
        height="auto"
        loading="eager"
      />
      <img
        *ngIf="state.platform === 'android'"
        class="santa-block santa-mx-auto"
        src="https://images.cf-rabota.com.ua/alliance/app-promo-googlestore.svg"
        alt="app promo google store/"
        width="auto"
        height="auto"
        loading="eager"
      />
    </div>
  </santa-button>
</div>
