import { EnvironmentApp, EnvironmentOctopus } from '@alliance/shared/environment'

class EnvironmentImpl extends EnvironmentOctopus {
  public override app = EnvironmentApp.desktop
  public override clubsStaticAWS = 'https://static.dev.robota.ua/clubs'
  public attachViaAdminUrl = `https://admin10.${this.domain}/export/attach/getattach.aspx?file=`
  public cloudFlareUrl = 'https://logo-frankfurt.cf-rabota.com.ua'
  public multiUserStatisticsApi = `https://multi-user-statistics-api.${this.domain}/api`
}

export const environment = new EnvironmentImpl()
