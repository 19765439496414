import { EnvironmentName, EnvironmentNameEnum } from './environment'

declare const process: { env: { ENV_NAME?: string } }

// CONSIDER: do not expose me, I not supposed to be used outside of this module, and keeped here just for backward compatibility
export const getNamespace = ({ production }: { production: boolean }): EnvironmentNameEnum => {
  if (typeof process === 'undefined' || typeof process.env.ENV_NAME === 'undefined') {
    return production ? EnvironmentName.prod : EnvironmentName.dev
  }
  switch (process.env.ENV_NAME?.toLowerCase()) {
    case 'production':
      return EnvironmentName.prod
    case 'prod':
      return EnvironmentName.prod
    case 'staging':
      return EnvironmentName.staging
    case 'test2':
      return EnvironmentName.staging
    default:
      return production ? EnvironmentName.prod : EnvironmentName.dev
  }
}
