/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core'
import { HttpClient, HttpResponse, HttpContext } from '@angular/common/http'
import { BaseService } from '../base-service'
import { ApplyApiDataAccessConfiguration } from '../apply-api-data-access-configuration'
import { StrictHttpResponse } from '../strict-http-response'
import { RequestBuilder } from '../request-builder'
import { Observable } from 'rxjs'
import { map, filter } from 'rxjs/operators'

import { ApplySavingResult } from '../models/apply-saving-result'
import { NoCvApplyRequest } from '../models/no-cv-apply-request'

@Injectable({
  providedIn: 'root'
})
export class ApplyNoCvApplicationService extends BaseService {
  public constructor(config: ApplyApiDataAccessConfiguration, http: HttpClient) {
    super(config, http)
  }

  /**
   * Path part for operation noCvApplicationPost
   */
  public static readonly NoCvApplicationPostPath = '/no-cv-application'

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `noCvApplicationPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  noCvApplicationPost$Plain$Response(params?: { context?: HttpContext; body?: NoCvApplyRequest }): Observable<StrictHttpResponse<ApplySavingResult>> {
    const rb = new RequestBuilder(this.rootUrl, ApplyNoCvApplicationService.NoCvApplicationPostPath, 'post')
    if (params) {
      rb.body(params.body, 'application/*+json')
    }

    return this.http
      .request(
        rb.build({
          responseType: 'text',
          accept: 'text/plain',
          context: params?.context
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<ApplySavingResult>
        })
      )
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `noCvApplicationPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  noCvApplicationPost$Plain(params?: { context?: HttpContext; body?: NoCvApplyRequest }): Observable<ApplySavingResult> {
    return this.noCvApplicationPost$Plain$Response(params).pipe(map((r: StrictHttpResponse<ApplySavingResult>) => r.body as ApplySavingResult))
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `noCvApplicationPost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  noCvApplicationPost$Json$Response(params?: { context?: HttpContext; body?: NoCvApplyRequest }): Observable<StrictHttpResponse<ApplySavingResult>> {
    const rb = new RequestBuilder(this.rootUrl, ApplyNoCvApplicationService.NoCvApplicationPostPath, 'post')
    if (params) {
      rb.body(params.body, 'application/*+json')
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'text/json',
          context: params?.context
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<ApplySavingResult>
        })
      )
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `noCvApplicationPost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  noCvApplicationPost$Json(params?: { context?: HttpContext; body?: NoCvApplyRequest }): Observable<ApplySavingResult> {
    return this.noCvApplicationPost$Json$Response(params).pipe(map((r: StrictHttpResponse<ApplySavingResult>) => r.body as ApplySavingResult))
  }
}
