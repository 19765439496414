import { Inject, Injectable } from '@angular/core'
import { LOCATION } from '@ng-web-apis/common'

@Injectable({
  providedIn: 'root'
})
export class IsNewRobotaDomainService {
  public constructor(@Inject(LOCATION) private location: Location) {}

  public isNewRobotaDomain(): boolean {
    const newDomain = 'robota.ua'
    return this.location?.host?.includes(newDomain)
  }
}
