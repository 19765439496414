import { RxStateService } from '@alliance/shared/models'
import { LocalStorage } from '@alliance/shared/storage'
import { DetectPlatformService, safeJsonParse } from '@alliance/shared/utils'
import { Injectable } from '@angular/core'
import { VacancyViewedStorageKey } from './constants/vacancy-viewed-storage-key'

@Injectable({ providedIn: 'root' })
export class VacancyViewedService extends RxStateService<{
  vacancyViewedIds: number[]
}> {
  public constructor(private platform: DetectPlatformService, public localStorage: LocalStorage) {
    super()

    this.initState({
      vacancyViewedIds: []
    })

    if (this.platform.isBrowser) {
      const vacancyViewedIds = safeJsonParse<number[]>(this.localStorage.getItem(VacancyViewedStorageKey), [])

      this.set({ vacancyViewedIds })
    }

    this.hold(this.select('vacancyViewedIds'), ids => {
      this.localStorage.setItem(VacancyViewedStorageKey, JSON.stringify(ids))
    })
  }

  public setVacancyViewed(id: number): void {
    const { vacancyViewedIds } = this.get()

    if (!vacancyViewedIds.includes(id)) {
      this.set({ vacancyViewedIds: [...vacancyViewedIds, id] })
    }
  }
}
