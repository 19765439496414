import { DataLayerPushEvent } from '@alliance/shared/utils'

export const APPLIES_RESUME_TRY_TO_SUGGEST_VACANCY_EVENT: DataLayerPushEvent = {
  event: 'EMP_Applies',
  eventCategory: 'EMP_Applies',
  eventAction: 'TryToOffer',
  eventLabel: null,
  eventContent: null
}

export const APPLIES_RESUME_SUGGEST_VACANCY_EVENT: DataLayerPushEvent = {
  event: 'EMP_Applies',
  eventCategory: 'EMP_Applies',
  eventAction: 'OfferSuccess',
  eventLabel: null,
  eventContent: null
}
