import { SocketDataAccessModule } from '@alliance/socket/data-access'
import { CommonModule } from '@angular/common'
import { NgModule } from '@angular/core'
import { SignalrMessageService } from './signalr-message/signalr-message.service'

@NgModule({
  imports: [CommonModule, SocketDataAccessModule]
})
export class SocketApiModule {
  public constructor(private signalrMessageService: SignalrMessageService) {
    this.signalrMessageService.connection()
  }
}
