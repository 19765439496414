import { Injectable } from '@angular/core'
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http'
import { BaseService as __BaseService } from '../base-service'
import { EmployerDataAccessConfiguration as __Configuration } from '../employer-data-access-configuration'
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response'
import { Observable as __Observable } from 'rxjs'
import { map as __map, filter as __filter } from 'rxjs/operators'

import { CompanyAddress } from '../models/company-address'
import { EmployerServiceInfo } from '../models/employer-service-info'
import { CvDbTicket } from '../models/cv-db-ticket'
import { VacancyDesign } from '../models/vacancy-design'
import { NotebookCompanyInfo } from '../models/notebook-company-info'
import { MediaLibraryViewModel } from '../models/media-library-view-model'
import { Manager } from '../models/manager'
import { ManagersInfo } from '../models/managers-info'
import { SpinWheelResult } from '../models/spin-wheel-result'
import { ActivateSpinWheelResponse } from '../models/activate-spin-wheel-response'

@Injectable({
  providedIn: 'root'
})
class NotebookService extends __BaseService {
  public static readonly companyAddressPath = '/notebook/addresses'
  public static readonly messageTemplatePath = '/notebook/messagetemplate'
  public static readonly messageTemplate_1Path = '/notebook/messagetemplate'
  public static readonly getTicketsPath = '/notebook/tickets'
  public static readonly getCvdbTicketsPath = '/notebook/cvdbtickets'
  public static readonly feedbackPath = '/notebook/feedback'
  public static readonly getVacancyDesignPath = '/notebook/vacancydesign'
  public static readonly companyInfoPath = '/notebook/companyinfo'
  public static readonly companyInfoV2Path = '/v2/notebooks/{notebookId}/users/{userId}/info'
  public static readonly getMediaLibraryItemsPath = '/notebook/media-library'
  public static readonly deleteMediaPath = '/notebook/media'
  public static readonly logUserPath = '/notebook/loguser/{pageId}'
  public static readonly getManagerPath = '/notebook/manager'
  public static readonly getManagersPath = '/v2/notebook/{multiUserId}/managers'
  public static readonly activateCompanyOfferPath = '/notebook/activateCompanyOffer'
  public static readonly activateCVMOfferPath = '/notebook/activateCVMOffer'
  public static readonly getSpinWheelResultPath = '/notebook/spin-wheel-result'
  public static readonly activateSpinWheelPath = '/notebook/activate-spin-wheel'

  public constructor(config: __Configuration, http: HttpClient) {
    super(config, http)
  }

  /**
   * @return Success
   */
  companyAddressResponse(): __Observable<__StrictHttpResponse<Array<CompanyAddress>>> {
    let __params = this.newParams()
    let __headers = new HttpHeaders()
    let __body: any = null
    let req = new HttpRequest<any>('GET', this.rootUrl + `/notebook/addresses`, __body, {
      headers: __headers,
      params: __params,
      responseType: 'json'
    })

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map(_r => {
        return _r as __StrictHttpResponse<Array<CompanyAddress>>
      })
    )
  }

  /**
   * @return Success
   */
  companyAddress(): __Observable<Array<CompanyAddress>> {
    return this.companyAddressResponse().pipe(__map(_r => _r.body as Array<CompanyAddress>))
  }

  /**
   * @param params The `NotebookService.MessageTemplateParams` containing the following parameters:
   *
   * - `useDefault`:
   *
   * - `letter`:
   *
   * @return Success
   */
  messageTemplateResponse(params: NotebookService.MessageTemplateParams): __Observable<__StrictHttpResponse<string>> {
    let __params = this.newParams()
    let __headers = new HttpHeaders()
    let __body: any = null
    if (params.useDefault != null) __params = __params.set('useDefault', params.useDefault.toString())
    if (params.letter != null) __params = __params.set('letter', params.letter.toString())
    let req = new HttpRequest<any>('GET', this.rootUrl + `/notebook/messagetemplate`, __body, {
      headers: __headers,
      params: __params,
      responseType: 'json'
    })

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map(_r => {
        return _r as __StrictHttpResponse<string>
      })
    )
  }

  /**
   * @param params The `NotebookService.MessageTemplateParams` containing the following parameters:
   *
   * - `useDefault`:
   *
   * - `letter`:
   *
   * @return Success
   */
  messageTemplate(params: NotebookService.MessageTemplateParams): __Observable<string> {
    return this.messageTemplateResponse(params).pipe(__map(_r => _r.body as string))
  }

  /**
   * @param params The `NotebookService.MessageTemplate_1Params` containing the following parameters:
   *
   * - `text`:
   *
   * - `letter`:
   */
  messageTemplate_1Response(params: NotebookService.MessageTemplate_1Params): __Observable<__StrictHttpResponse<any>> {
    let __params = this.newParams()
    let __headers = new HttpHeaders()
    let __body: any = null
    if (params.text != null) __params = __params.set('text', params.text.toString())
    if (params.letter != null) __params = __params.set('letter', params.letter.toString())
    let req = new HttpRequest<any>('PUT', this.rootUrl + `/notebook/messagetemplate`, __body, {
      headers: __headers,
      params: __params,
      responseType: 'json'
    })

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map(_r => {
        return _r as __StrictHttpResponse<any>
      })
    )
  }

  /**
   * @param params The `NotebookService.MessageTemplate_1Params` containing the following parameters:
   *
   * - `text`:
   *
   * - `letter`:
   */
  messageTemplate_1(params: NotebookService.MessageTemplate_1Params): __Observable<any> {
    return this.messageTemplate_1Response(params).pipe(__map(_r => _r.body as any))
  }

  /**
   * @return Success
   */
  getTicketsResponse(): __Observable<__StrictHttpResponse<Array<EmployerServiceInfo>>> {
    let __params = this.newParams()
    let __headers = new HttpHeaders()
    let __body: any = null
    let req = new HttpRequest<any>('GET', this.rootUrl + `/notebook/tickets`, __body, {
      headers: __headers,
      params: __params,
      responseType: 'json'
    })

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map(_r => {
        return _r as __StrictHttpResponse<Array<EmployerServiceInfo>>
      })
    )
  }

  /**
   * @return Success
   */
  getTickets(): __Observable<Array<EmployerServiceInfo>> {
    return this.getTicketsResponse().pipe(__map(_r => _r.body as Array<EmployerServiceInfo>))
  }

  /**
   * @return Success
   */
  getCvdbTicketsResponse(): __Observable<__StrictHttpResponse<Array<CvDbTicket>>> {
    let __params = this.newParams()
    let __headers = new HttpHeaders()
    let __body: any = null
    let req = new HttpRequest<any>('GET', this.rootUrl + `/notebook/cvdbtickets`, __body, {
      headers: __headers,
      params: __params,
      responseType: 'json'
    })

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map(_r => {
        return _r as __StrictHttpResponse<Array<CvDbTicket>>
      })
    )
  }

  /**
   * @return Success
   */
  getCvdbTickets(): __Observable<Array<CvDbTicket>> {
    return this.getCvdbTicketsResponse().pipe(__map(_r => _r.body as Array<CvDbTicket>))
  }

  /**
   * @param message undefined
   */
  feedbackResponse(message?: string): __Observable<__StrictHttpResponse<any>> {
    let __params = this.newParams()
    let __headers = new HttpHeaders()
    let __body: any = null
    if (message != null) __params = __params.set('message', message.toString())
    let req = new HttpRequest<any>('POST', this.rootUrl + `/notebook/feedback`, __body, {
      headers: __headers,
      params: __params,
      responseType: 'json'
    })

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map(_r => {
        return _r as __StrictHttpResponse<any>
      })
    )
  }

  /**
   * @param message undefined
   */
  feedback(message?: string): __Observable<any> {
    return this.feedbackResponse(message).pipe(__map(_r => _r.body as any))
  }

  /**
   * @return Success
   */
  getVacancyDesignResponse(): __Observable<__StrictHttpResponse<Array<VacancyDesign>>> {
    let __params = this.newParams()
    let __headers = new HttpHeaders()
    let __body: any = null
    let req = new HttpRequest<any>('GET', this.rootUrl + `/notebook/vacancydesign`, __body, {
      headers: __headers,
      params: __params,
      responseType: 'json'
    })

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map(_r => {
        return _r as __StrictHttpResponse<Array<VacancyDesign>>
      })
    )
  }

  /**
   * @return Success
   */
  getVacancyDesign(): __Observable<Array<VacancyDesign>> {
    return this.getVacancyDesignResponse().pipe(__map(_r => _r.body as Array<VacancyDesign>))
  }
  companyInfoResponse(): __Observable<__StrictHttpResponse<any>> {
    let __params = this.newParams()
    let __headers = new HttpHeaders()
    let __body: any = null
    let req = new HttpRequest<any>('GET', this.rootUrl + `/notebook/companyinfo`, __body, {
      headers: __headers,
      params: __params,
      responseType: 'json'
    })

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map(_r => {
        return _r as __StrictHttpResponse<any>
      })
    )
  }
  companyInfo(): __Observable<any> {
    return this.companyInfoResponse().pipe(__map(_r => _r.body as any))
  }

  /**
   * @param params The `NotebookService.CompanyInfoV2Params` containing the following parameters:
   *
   * - `userId`:
   *
   * - `notebookId`:
   *
   * @return Success
   */
  companyInfoV2Response(params: NotebookService.CompanyInfoV2Params): __Observable<__StrictHttpResponse<NotebookCompanyInfo>> {
    let __params = this.newParams()
    let __headers = new HttpHeaders()
    let __body: any = null

    let req = new HttpRequest<any>('GET', this.rootUrl + `/v2/notebooks/${params.notebookId}/users/${params.userId}/info`, __body, {
      headers: __headers,
      params: __params,
      responseType: 'json'
    })

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map(_r => {
        return _r as __StrictHttpResponse<NotebookCompanyInfo>
      })
    )
  }

  /**
   * @param params The `NotebookService.CompanyInfoV2Params` containing the following parameters:
   *
   * - `userId`:
   *
   * - `notebookId`:
   *
   * @return Success
   */
  companyInfoV2(params: NotebookService.CompanyInfoV2Params): __Observable<NotebookCompanyInfo> {
    return this.companyInfoV2Response(params).pipe(__map(_r => _r.body as NotebookCompanyInfo))
  }

  /**
   * @param params The `NotebookService.GetMediaLibraryItemsParams` containing the following parameters:
   *
   * - `Start`:
   *
   * - `OnlyMine`:
   *
   * - `Count`:
   *
   * @return Success
   */
  getMediaLibraryItemsResponse(params: NotebookService.GetMediaLibraryItemsParams): __Observable<__StrictHttpResponse<Array<MediaLibraryViewModel>>> {
    let __params = this.newParams()
    let __headers = new HttpHeaders()
    let __body: any = null
    if (params.Start != null) __params = __params.set('Start', params.Start.toString())
    if (params.OnlyMine != null) __params = __params.set('OnlyMine', params.OnlyMine.toString())
    if (params.Count != null) __params = __params.set('Count', params.Count.toString())
    let req = new HttpRequest<any>('GET', this.rootUrl + `/notebook/media-library`, __body, {
      headers: __headers,
      params: __params,
      responseType: 'json'
    })

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map(_r => {
        return _r as __StrictHttpResponse<Array<MediaLibraryViewModel>>
      })
    )
  }

  /**
   * @param params The `NotebookService.GetMediaLibraryItemsParams` containing the following parameters:
   *
   * - `Start`:
   *
   * - `OnlyMine`:
   *
   * - `Count`:
   *
   * @return Success
   */
  getMediaLibraryItems(params: NotebookService.GetMediaLibraryItemsParams): __Observable<Array<MediaLibraryViewModel>> {
    return this.getMediaLibraryItemsResponse(params).pipe(__map(_r => _r.body as Array<MediaLibraryViewModel>))
  }

  /**
   * @param ids undefined
   */
  deleteMediaResponse(ids?: Array<number>): __Observable<__StrictHttpResponse<any>> {
    let __params = this.newParams()
    let __headers = new HttpHeaders()
    let __body: any = null
    __body = ids
    let req = new HttpRequest<any>('DELETE', this.rootUrl + `/notebook/media`, __body, {
      headers: __headers,
      params: __params,
      responseType: 'json'
    })

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map(_r => {
        return _r as __StrictHttpResponse<any>
      })
    )
  }

  /**
   * @param ids undefined
   */
  deleteMedia(ids?: Array<number>): __Observable<any> {
    return this.deleteMediaResponse(ids).pipe(__map(_r => _r.body as any))
  }

  /**
   * @param pageId undefined
   */
  logUserResponse(pageId: number): __Observable<__StrictHttpResponse<any>> {
    let __params = this.newParams()
    let __headers = new HttpHeaders()
    let __body: any = null

    let req = new HttpRequest<any>('GET', this.rootUrl + `/notebook/loguser/${pageId}`, __body, {
      headers: __headers,
      params: __params,
      responseType: 'json'
    })

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map(_r => {
        return _r as __StrictHttpResponse<any>
      })
    )
  }

  /**
   * @param pageId undefined
   */
  logUser(pageId: number): __Observable<any> {
    return this.logUserResponse(pageId).pipe(__map(_r => _r.body as any))
  }

  /**
   * @return Success
   */
  getManagerResponse(): __Observable<__StrictHttpResponse<Manager>> {
    let __params = this.newParams()
    let __headers = new HttpHeaders()
    let __body: any = null
    let req = new HttpRequest<any>('GET', this.rootUrl + `/notebook/manager`, __body, {
      headers: __headers,
      params: __params,
      responseType: 'json'
    })

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map(_r => {
        return _r as __StrictHttpResponse<Manager>
      })
    )
  }

  /**
   * @return Success
   */
  getManager(): __Observable<Manager> {
    return this.getManagerResponse().pipe(__map(_r => _r.body as Manager))
  }

  /**
   * @param multiUserId undefined
   * @return Success
   */
  getManagersResponse(multiUserId: number): __Observable<__StrictHttpResponse<ManagersInfo>> {
    let __params = this.newParams()
    let __headers = new HttpHeaders()
    let __body: any = null

    let req = new HttpRequest<any>('GET', this.rootUrl + `/v2/notebook/${multiUserId}/managers`, __body, {
      headers: __headers,
      params: __params,
      responseType: 'json'
    })

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map(_r => {
        return _r as __StrictHttpResponse<ManagersInfo>
      })
    )
  }

  /**
   * @param multiUserId undefined
   * @return Success
   */
  getManagers(multiUserId: number): __Observable<ManagersInfo> {
    return this.getManagersResponse(multiUserId).pipe(__map(_r => _r.body as ManagersInfo))
  }

  /**
   * @return Success
   */
  activateCompanyOfferResponse(): __Observable<__StrictHttpResponse<boolean>> {
    let __params = this.newParams()
    let __headers = new HttpHeaders()
    let __body: any = null
    let req = new HttpRequest<any>('POST', this.rootUrl + `/notebook/activateCompanyOffer`, __body, {
      headers: __headers,
      params: __params,
      responseType: 'json'
    })

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map(_r => {
        return (_r as HttpResponse<any>).clone({
          body: (_r as HttpResponse<any>).body === true
        }) as __StrictHttpResponse<boolean>
      })
    )
  }

  /**
   * @return Success
   */
  activateCompanyOffer(): __Observable<boolean> {
    return this.activateCompanyOfferResponse().pipe(__map(_r => _r.body as boolean))
  }

  /**
   * @param segment undefined
   * @return Success
   */
  activateCVMOfferResponse(segment?: 'UnLoyalInsideSales' | 'SleepyInsideSales1m' | 'SleepyInsideSales3m' | 'ActiveInsideSales'): __Observable<__StrictHttpResponse<boolean>> {
    let __params = this.newParams()
    let __headers = new HttpHeaders()
    let __body: any = null
    if (segment != null) __params = __params.set('segment', segment.toString())
    let req = new HttpRequest<any>('POST', this.rootUrl + `/notebook/activateCVMOffer`, __body, {
      headers: __headers,
      params: __params,
      responseType: 'json'
    })

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map(_r => {
        return (_r as HttpResponse<any>).clone({
          body: (_r as HttpResponse<any>).body === true
        }) as __StrictHttpResponse<boolean>
      })
    )
  }

  /**
   * @param segment undefined
   * @return Success
   */
  activateCVMOffer(segment?: 'UnLoyalInsideSales' | 'SleepyInsideSales1m' | 'SleepyInsideSales3m' | 'ActiveInsideSales'): __Observable<boolean> {
    return this.activateCVMOfferResponse(segment).pipe(__map(_r => _r.body as boolean))
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getSpinWheelResult()` instead.
   *
   * This method doesn't expect any request body.
   */
  getSpinWheelResultResponse(): __Observable<__StrictHttpResponse<SpinWheelResult>> {
    let __params = this.newParams()
    let __headers = new HttpHeaders()
    let __body: any = null
    let req = new HttpRequest<any>('GET', this.rootUrl + `/notebook/spin-wheel-result`, __body, {
      headers: __headers,
      params: __params,
      responseType: 'json'
    })

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map(_r => {
        return _r as __StrictHttpResponse<any>
      })
    )
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getSpinWheelResult$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getSpinWheelResult(): __Observable<SpinWheelResult> {
    return this.getSpinWheelResultResponse().pipe(__map(_r => _r.body as SpinWheelResult))
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `activateSpinWheel()` instead.
   *
   * This method doesn't expect any request body.
   */
  activateSpinWheelResponse(): __Observable<__StrictHttpResponse<ActivateSpinWheelResponse>> {
    let __params = this.newParams()
    let __headers = new HttpHeaders()
    let __body: any = null
    let req = new HttpRequest<any>('POST', this.rootUrl + `/notebook/activate-spin-wheel`, __body, {
      headers: __headers,
      params: __params,
      responseType: 'json'
    })

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map(_r => {
        return _r as __StrictHttpResponse<any>
      })
    )
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `activateSpinWheel$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  activateSpinWheel(): __Observable<ActivateSpinWheelResponse> {
    return this.activateSpinWheelResponse().pipe(__map(_r => _r.body as ActivateSpinWheelResponse))
  }
}

module NotebookService {
  /**
   * Parameters for MessageTemplate
   */
  export interface MessageTemplateParams {
    useDefault?: boolean
    letter?: 'None' | 'InvitationLetterRus' | 'InvitationLetterEng' | 'RefuseLetterRus' | 'RefuseLetterEng' | 'AutoReplyCompanyLetter' | 'InvitationLetterUkr' | 'RefuseLetterUkr'
  }

  /**
   * Parameters for MessageTemplate_1
   */
  export interface MessageTemplate_1Params {
    text?: string
    letter?: 'None' | 'InvitationLetterRus' | 'InvitationLetterEng' | 'RefuseLetterRus' | 'RefuseLetterEng' | 'AutoReplyCompanyLetter' | 'InvitationLetterUkr' | 'RefuseLetterUkr'
  }

  /**
   * Parameters for CompanyInfoV2
   */
  export interface CompanyInfoV2Params {
    userId: number
    notebookId: number
  }

  /**
   * Parameters for GetMediaLibraryItems
   */
  export interface GetMediaLibraryItemsParams {
    Start?: number
    OnlyMine?: boolean
    Count?: number
  }
}

export { NotebookService }
