export enum CandidatesSearchSubPagesEnum {
  filters = 'filters',
  keyword = 'keyword',
  city = 'city',
  cities = 'cities',
  rubrics = 'rubrics',
  professions = 'professions'
}

export enum CandidatesCvSubPagesEnum {
  suggestVacancy = 'suggest-vacancy',
  openContacts = 'open-contacts',
  limitReached = 'limit-reached'
}
