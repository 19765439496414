/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core'

/**
 * Global configuration
 */
@Injectable({
  providedIn: 'root'
})
export class VacancyApiDataAccessConfiguration {
  rootUrl: string = 'https://vacancy-api.robota.ua'
}

/**
 * Parameters for `VacancyApiDataAccessModule.forRoot()`
 */
export interface VacancyApiDataAccessConfigurationParams {
  rootUrl?: string
}
