import { Injectable } from '@angular/core'
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http'
import { BaseService as __BaseService } from '../base-service'
import { EmployerDataAccessConfiguration as __Configuration } from '../employer-data-access-configuration'
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response'
import { Observable as __Observable } from 'rxjs'
import { map as __map, filter as __filter } from 'rxjs/operators'

import { ApplyListResponse } from '../models/apply-list-response'
import { ApplyListRequest } from '../models/apply-list-request'
import { BaseApplyItem } from '../models/base-apply-item'
import { ApplyItem } from '../models/apply-item'
import { ApplyItemRequest } from '../models/apply-item-request'
import { ApplyReadStateRequest } from '../models/apply-read-state-request'
import { InviteRequest } from '../models/invite-request'
import { FolderRequest } from '../models/folder-request'
import { ApplyCount } from '../models/apply-count'
@Injectable({
  providedIn: 'root'
})
class ApplyService extends __BaseService {
  public static readonly listPath = '/apply/list'
  public static readonly viewPath = '/apply/view/{id}'
  public static readonly getApplyPath = '/apply'
  public static readonly setReadStatePath = '/apply/setreadstate'
  public static readonly likePath = '/apply/likecv'
  public static readonly invitePath = '/apply/invite'
  public static readonly refusePath = '/apply/refuse'
  public static readonly setFolderPath = '/apply/setfolder'
  public static readonly applyCounterPath = '/apply/counter'
  public static readonly getAttachPath = '/apply/getfile/{id}'

  public constructor(config: __Configuration, http: HttpClient) {
    super(config, http)
  }

  /**
   * @param request undefined
   * @return Success
   */
  listResponse(request?: ApplyListRequest): __Observable<__StrictHttpResponse<ApplyListResponse>> {
    let __params = this.newParams()
    let __headers = new HttpHeaders()
    let __body: any = null
    __body = request
    let req = new HttpRequest<any>('POST', this.rootUrl + `/apply/list`, __body, {
      headers: __headers,
      params: __params,
      responseType: 'json'
    })

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map(_r => {
        return _r as __StrictHttpResponse<ApplyListResponse>
      })
    )
  }

  /**
   * @param request undefined
   * @return Success
   */
  list(request?: ApplyListRequest): __Observable<ApplyListResponse> {
    return this.listResponse(request).pipe(__map(_r => _r.body as ApplyListResponse))
  }

  /**
   * @param params The `ApplyService.ViewParams` containing the following parameters:
   *
   * - `id`: Id of application or selected resume
   *
   * - `resumeType`: AttachedFile  – application made using file,
   *   Notepad – application made using resume created on our website,
   *   Selected – saved cv from CVDB,
   *   NoCvApply – application without CV (only phone number specified)
   *
   * @return Success
   */
  viewResponse(params: ApplyService.ViewParams): __Observable<__StrictHttpResponse<BaseApplyItem>> {
    let __params = this.newParams()
    let __headers = new HttpHeaders()
    let __body: any = null

    if (params.resumeType != null) __params = __params.set('resumeType', params.resumeType.toString())
    let req = new HttpRequest<any>('POST', this.rootUrl + `/apply/view/${params.id}`, __body, {
      headers: __headers,
      params: __params,
      responseType: 'json'
    })

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map(_r => {
        return _r as __StrictHttpResponse<BaseApplyItem>
      })
    )
  }

  /**
   * @param params The `ApplyService.ViewParams` containing the following parameters:
   *
   * - `id`: Id of application or selected resume
   *
   * - `resumeType`: AttachedFile  – application made using file,
   *   Notepad – application made using resume created on our website,
   *   Selected – saved cv from CVDB,
   *   NoCvApply – application without CV (only phone number specified)
   *
   * @return Success
   */
  view(params: ApplyService.ViewParams): __Observable<BaseApplyItem> {
    return this.viewResponse(params).pipe(__map(_r => _r.body as BaseApplyItem))
  }

  /**
   * @param data undefined
   * @return Success
   */
  getApplyResponse(data?: ApplyItemRequest): __Observable<__StrictHttpResponse<ApplyItem>> {
    let __params = this.newParams()
    let __headers = new HttpHeaders()
    let __body: any = null
    __body = data
    let req = new HttpRequest<any>('POST', this.rootUrl + `/apply`, __body, {
      headers: __headers,
      params: __params,
      responseType: 'json'
    })

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map(_r => {
        return _r as __StrictHttpResponse<ApplyItem>
      })
    )
  }

  /**
   * @param data undefined
   * @return Success
   */
  getApply(data?: ApplyItemRequest): __Observable<ApplyItem> {
    return this.getApplyResponse(data).pipe(__map(_r => _r.body as ApplyItem))
  }

  /**
   * @param request undefined
   */
  setReadStateResponse(request?: ApplyReadStateRequest): __Observable<__StrictHttpResponse<any>> {
    let __params = this.newParams()
    let __headers = new HttpHeaders()
    let __body: any = null
    __body = request
    let req = new HttpRequest<any>('POST', this.rootUrl + `/apply/setreadstate`, __body, {
      headers: __headers,
      params: __params,
      responseType: 'json'
    })

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map(_r => {
        return _r as __StrictHttpResponse<any>
      })
    )
  }

  /**
   * @param request undefined
   */
  setReadState(request?: ApplyReadStateRequest): __Observable<any> {
    return this.setReadStateResponse(request).pipe(__map(_r => _r.body as any))
  }

  /**
   * @param params The `ApplyService.LikeParams` containing the following parameters:
   *
   * - `value`: true - make "Interesting",
   *   false - remove from "Interesting"
   *
   * - `resumeType`: AttachedFile  – application made using file,
   *   Notepad – application made using resume created on our website,
   *   Selected – saved cv from CVDB,
   *   NoCvApply – application without CV (only phone number specified)
   *
   * - `id`:
   */
  likeResponse(params: ApplyService.LikeParams): __Observable<__StrictHttpResponse<any>> {
    let __params = this.newParams()
    let __headers = new HttpHeaders()
    let __body: any = null
    if (params.value != null) __params = __params.set('value', params.value.toString())
    if (params.resumeType != null) __params = __params.set('resumeType', params.resumeType.toString())
    if (params.id != null) __params = __params.set('id', params.id.toString())
    let req = new HttpRequest<any>('POST', this.rootUrl + `/apply/likecv`, __body, {
      headers: __headers,
      params: __params,
      responseType: 'json'
    })

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map(_r => {
        return _r as __StrictHttpResponse<any>
      })
    )
  }

  /**
   * @param params The `ApplyService.LikeParams` containing the following parameters:
   *
   * - `value`: true - make "Interesting",
   *   false - remove from "Interesting"
   *
   * - `resumeType`: AttachedFile  – application made using file,
   *   Notepad – application made using resume created on our website,
   *   Selected – saved cv from CVDB,
   *   NoCvApply – application without CV (only phone number specified)
   *
   * - `id`:
   */
  like(params: ApplyService.LikeParams): __Observable<any> {
    return this.likeResponse(params).pipe(__map(_r => _r.body as any))
  }

  /**
   * @param request undefined
   */
  inviteResponse(request?: InviteRequest): __Observable<__StrictHttpResponse<any>> {
    let __params = this.newParams()
    let __headers = new HttpHeaders()
    let __body: any = null
    __body = request
    let req = new HttpRequest<any>('POST', this.rootUrl + `/apply/invite`, __body, {
      headers: __headers,
      params: __params,
      responseType: 'json'
    })

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map(_r => {
        return _r as __StrictHttpResponse<any>
      })
    )
  }

  /**
   * @param request undefined
   */
  invite(request?: InviteRequest): __Observable<any> {
    return this.inviteResponse(request).pipe(__map(_r => _r.body as any))
  }

  /**
   * @param request undefined
   */
  refuseResponse(request?: InviteRequest): __Observable<__StrictHttpResponse<any>> {
    let __params = this.newParams()
    let __headers = new HttpHeaders()
    let __body: any = null
    __body = request
    let req = new HttpRequest<any>('POST', this.rootUrl + `/apply/refuse`, __body, {
      headers: __headers,
      params: __params,
      responseType: 'json'
    })

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map(_r => {
        return _r as __StrictHttpResponse<any>
      })
    )
  }

  /**
   * @param request undefined
   */
  refuse(request?: InviteRequest): __Observable<any> {
    return this.refuseResponse(request).pipe(__map(_r => _r.body as any))
  }

  /**
   * @param request undefined
   */
  setFolderResponse(request?: FolderRequest): __Observable<__StrictHttpResponse<any>> {
    let __params = this.newParams()
    let __headers = new HttpHeaders()
    let __body: any = null
    __body = request
    let req = new HttpRequest<any>('POST', this.rootUrl + `/apply/setfolder`, __body, {
      headers: __headers,
      params: __params,
      responseType: 'json'
    })

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map(_r => {
        return _r as __StrictHttpResponse<any>
      })
    )
  }

  /**
   * @param request undefined
   */
  setFolder(request?: FolderRequest): __Observable<any> {
    return this.setFolderResponse(request).pipe(__map(_r => _r.body as any))
  }

  /**
   * @param params The `ApplyService.ApplyCounterParams` containing the following parameters:
   *
   * - `vacancyId`: '0' – selected resumes that are not tied to any vacancy, '-1' – all vacancies, '&gt; 0' – existing vacancy id
   *
   * - `multiuserId`:
   *
   * @return Success
   */
  applyCounterResponse(params: ApplyService.ApplyCounterParams): __Observable<__StrictHttpResponse<Array<ApplyCount>>> {
    let __params = this.newParams()
    let __headers = new HttpHeaders()
    let __body: any = null
    if (params.vacancyId != null) __params = __params.set('vacancyId', params.vacancyId.toString())
    if (params.multiuserId != null) __params = __params.set('multiuserId', params.multiuserId.toString())
    let req = new HttpRequest<any>('GET', this.rootUrl + `/apply/counter`, __body, {
      headers: __headers,
      params: __params,
      responseType: 'json'
    })

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map(_r => {
        return _r as __StrictHttpResponse<Array<ApplyCount>>
      })
    )
  }

  /**
   * @param params The `ApplyService.ApplyCounterParams` containing the following parameters:
   *
   * - `vacancyId`: '0' – selected resumes that are not tied to any vacancy, '-1' – all vacancies, '&gt; 0' – existing vacancy id
   *
   * - `multiuserId`:
   *
   * @return Success
   */
  applyCounter(params: ApplyService.ApplyCounterParams): __Observable<Array<ApplyCount>> {
    return this.applyCounterResponse(params).pipe(__map(_r => _r.body as Array<ApplyCount>))
  }

  /**
   * @param params The `ApplyService.GetAttachParams` containing the following parameters:
   *
   * - `id`: id of application or selected resume
   *
   * - `resumeType`: AttachedFile  – application made using file,
   *   Notepad – application made using resume created on our website,
   *   Selected – saved cv from CVDB,
   *   NoCvApply – application without CV (only phone number specified)
   *
   * - `User-Agent`:
   */
  getAttachResponse(params: ApplyService.GetAttachParams): __Observable<__StrictHttpResponse<string>> {
    let __params = this.newParams()
    let __headers = new HttpHeaders()
    let __body: any = null

    if (params.resumeType != null) __params = __params.set('resumeType', params.resumeType.toString())
    if (params.UserAgent != null) __headers = __headers.set('User-Agent', params.UserAgent.toString())
    let req = new HttpRequest<any>('GET', this.rootUrl + `/apply/getfile/${params.id}`, __body, {
      headers: __headers,
      params: __params,
      responseType: 'blob'
    })

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map(_r => {
        return _r as __StrictHttpResponse<string>
      })
    )
  }

  /**
   * @param params The `ApplyService.GetAttachParams` containing the following parameters:
   *
   * - `id`: id of application or selected resume
   *
   * - `resumeType`: AttachedFile  – application made using file,
   *   Notepad – application made using resume created on our website,
   *   Selected – saved cv from CVDB,
   *   NoCvApply – application without CV (only phone number specified)
   *
   * - `User-Agent`:
   */
  getAttach(params: ApplyService.GetAttachParams): __Observable<__StrictHttpResponse<string>> {
    return this.getAttachResponse(params).pipe(__map(_r => _r as __StrictHttpResponse<string>))
  }
}

module ApplyService {
  /**
   * Parameters for View
   */
  export interface ViewParams {
    /**
     * Id of application or selected resume
     */
    id: number

    /**
     * AttachedFile  – application made using file,
     * Notepad – application made using resume created on our website,
     * Selected – saved cv from CVDB,
     * NoCvApply – application without CV (only phone number specified)
     */
    resumeType?: 'None' | 'AttachedFile' | 'Notepad' | 'Selected' | 'NoCvApply'
  }

  /**
   * Parameters for Like
   */
  export interface LikeParams {
    /**
     * true - make "Interesting",
     * false - remove from "Interesting"
     */
    value?: boolean

    /**
     * AttachedFile  – application made using file,
     * Notepad – application made using resume created on our website,
     * Selected – saved cv from CVDB,
     * NoCvApply – application without CV (only phone number specified)
     */
    resumeType?: 'None' | 'AttachedFile' | 'Notepad' | 'Selected' | 'NoCvApply'
    id?: number
  }

  /**
   * Parameters for ApplyCounter
   */
  export interface ApplyCounterParams {
    /**
     * '0' – selected resumes that are not tied to any vacancy, '-1' – all vacancies, '&gt; 0' – existing vacancy id
     */
    vacancyId?: number
    multiuserId?: number
  }

  /**
   * Parameters for GetAttach
   */
  export interface GetAttachParams {
    /**
     * id of application or selected resume
     */
    id: number

    /**
     * AttachedFile  – application made using file,
     * Notepad – application made using resume created on our website,
     * Selected – saved cv from CVDB,
     * NoCvApply – application without CV (only phone number specified)
     */
    resumeType?: 'None' | 'AttachedFile' | 'Notepad' | 'Selected' | 'NoCvApply'
    UserAgent?: string
  }
}

export { ApplyService }
