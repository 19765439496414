import { Injectable } from '@angular/core'
import { DetectPlatformService } from './detect-platform.service'
import { HelpersService } from './helpers.service'
import { DomSanitizer, SafeUrl } from '@angular/platform-browser'

@Injectable()
export class GenerateViberTelegramBotLinkService {
  public constructor(private platform: DetectPlatformService, private helpersService: HelpersService, private domSanitizer: DomSanitizer) {}

  public getViberLink(cid = this.helpersService.getCid(), uid: string | number = ''): SafeUrl {
    return this.getLink(cid, uid, 'viber://pa?chatURI=rabotaua&context=')
  }

  public getTelegramLink(cid = this.helpersService.getCid(), uid: string | number = ''): SafeUrl {
    return this.getLink(cid, uid, 'https://t.me/robotaua_now_bot?start=')
  }

  private getLink(cid = this.helpersService.getCid(), uid: string | number = '', url: string): SafeUrl {
    if (this.platform.isServer) {
      return this.domSanitizer.bypassSecurityTrustUrl(url)
    }
    const userDataInBase64 = btoa(`${uid}/${cid}`)
    return this.domSanitizer.bypassSecurityTrustUrl(url + userDataInBase64)
  }
}
