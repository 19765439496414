import { stringify } from './stringify'

const isClass = (object: object): boolean => {
  try {
    return object.constructor.name !== 'Object'
  } catch {
    return false
  }
}

const getReplacer = (level: number = 0): ((value: unknown) => unknown) => (value: unknown): unknown => {
  let n = level

  if (Array.isArray(value)) {
    return value.slice(0, 10).reduce<unknown[]>((acc_array, item) => [...acc_array, getReplacer(level)(item as unknown)], [])
  }

  if (typeof value === 'object' && value !== null) {
    n += 1
    if (isClass(value)) {
      const normalized = Object.getOwnPropertyNames(value).reduce<object>((acc_class, property) => ({ ...acc_class, [property]: value[property as keyof typeof value] }), {})
      return n <= 3 ? normalize(normalized, n) : stringify(normalized)
    }
    return n <= 3 ? normalize(value, n) : stringify(value)
  }

  return value
}

export const normalize = (obj: object, level = 0): object => {
  const res: Record<string, unknown> = {}
  Object.entries(obj)
    .slice(0, 20)
    .forEach(([key, value]) => {
      res[key] = getReplacer(level)(value)
    })
  return res
}
