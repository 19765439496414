import { KeyValuePairInt32Int32 } from './key-value-pair-int-32int-32'
export interface CvSearchRequest {
  /**
   * Сортувати за.
   * 0 - релевантність, 1 - дата оновлення.
   */
  sort?: CvSearchRequest.SortEnum

  /**
   * Номер сторінки, починаючи з 0.
   */
  page?: number

  /**
   * Мова відвовіді.
   * true - укр, false - рус.
   */
  ukrainian?: boolean

  /**
   * ID міста.
   */
  cityId?: number

  /**
   * Не використовується.
   */
  inside?: boolean

  /**
   * Готовність до переїзду.
   * true - якщо готовий до переїзду; false - не готовий до переїзду.
   */
  moveability?: boolean

  /**
   * Вид освіти.
   * 1 - вища, 2 - незакінченна вища, 3 - середньо-спеціальна, 4 - середня, 5 - МВА.
   */
  educationId?: number

  /**
   * ID рубрики - https://employer-api.rabota.ua/values/rubrics
   */
  parentId?: number

  /**
   * ID підрубрики - https://employer-api.rabota.ua/values/subrubrics
   */
  rubricIds?: Array<number>

  /**
   * Вік "від".
   */
  ageFrom?: number

  /**
   * Вік "до".
   */
  ageTo?: number

  /**
   * Зарплата "від".
   */
  salaryFrom?: number

  /**
   * Зарплата "до".
   */
  salaryTo?: number

  /**
   * Стать.
   * 1 - мужчина, 2 - жінка.
   */
  sex?: number

  /**
   * Не використовується.
   */
  profLevelIds?: Array<number>

  /**
   * Вид зайнятості.
   * 1 - повна, 2 - не повна, 3 - віддалена, 4 - стажування/практика, 5- проектна робота, 6 - сезонна/тимчасова робота.
   */
  scheduleId?: number

  /**
   * Володіння мовами -  https://api.rabota.ua/dictionary/language, https://api.rabota.ua/dictionary/language/skill
   */
  languages?: Array<KeyValuePairInt32Int32>

  /**
   * Період пошуку.
   * 1 - сьогодні, 2 - 3 дні, 3 - останній тиждень, 4-місяць, 5 - рік, 6 - весь час, 7 - 3 місяці
   */
  period?: number

  /**
   * Галузь компанії - https://api.rabota.ua/dictionary/branch
   */
  branchId?: number

  /**
   * Досвід роботи.
   * 1 - від 1 до 2 років, 2 - від 2 до 5 років, 3 - більше 5 років, 4 - немає, 5 - до 1 року - https://api.rabota.ua/dictionary/experience
   */
  experienceId?: number

  /**
   * Не використовується.
   */
  expSubRubricId?: number

  /**
   * Не використовується.
   */
  expRubricId?: number

  /**
   * Фоточка.
   * true - yes, false - no.
   */
  hasPhoto?: boolean

  /**
   * Мова резюме.
   * 1 - рус, 2 - анг, 3 - укр.
   */
  cvLanguage?: number

  /**
   * Пошуковий запит.
   */
  keyWords?: string
  isSynonym?: boolean
  resumeFillingTypeIds?: Array<string>
  districts?: Array<string>

  /**
   * Де шукати.
   * everywhere - по всьому тексту, default - з урахуванням синонімів, speciality - в назві резюме, education - в освіті, skills - в ключових навичках, experience - в досвіді роботи.
   */
  searchType?: string
  lastSort?: string
  cityDistrictId?: number
}

export namespace CvSearchRequest {
  export type SortEnum = 'LastActivity' | 'Score' | 'UpdateDate'
  export const SortEnum = {
    LastActivity: 'LastActivity' as SortEnum,
    Score: 'Score' as SortEnum,
    UpdateDate: 'UpdateDate' as SortEnum
  }
}
