export interface SendOfferMessageRequest {
  vacancyId?: number
  resumeId?: number
  message?: string
  sendCopy?: boolean
  platformId?: SendOfferMessageRequest.PlatformIdEnum
  resumeType?: SendOfferMessageRequest.ResumeTypeEnum
}

export namespace SendOfferMessageRequest {
  export type PlatformIdEnum = 'Android' | 'Desktop' | 'EmployerAccount' | 'IOS' | 'Mobile' | 'None'
  export const PlatformIdEnum = {
    Android: 'Android' as PlatformIdEnum,
    Desktop: 'Desktop' as PlatformIdEnum,
    EmployerAccount: 'EmployerAccount' as PlatformIdEnum,
    IOS: 'IOS' as PlatformIdEnum,
    Mobile: 'Mobile' as PlatformIdEnum,
    None: 'None' as PlatformIdEnum
  }
  export type ResumeTypeEnum = 'AttachedFile' | 'NoCvApply' | 'None' | 'Notepad' | 'Selected'
  export const ResumeTypeEnum = {
    AttachedFile: 'AttachedFile' as ResumeTypeEnum,
    NoCvApply: 'NoCvApply' as ResumeTypeEnum,
    None: 'None' as ResumeTypeEnum,
    Notepad: 'Notepad' as ResumeTypeEnum,
    Selected: 'Selected' as ResumeTypeEnum
  }
}
