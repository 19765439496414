import { decodeToken } from './decode-token'

interface Token {
  exp: number
}

const isToken = (token: unknown): token is Token => typeof token === 'object' && token !== null && 'exp' in token

export const isJwtTokenExpired = (token: string): boolean => {
  const decoded = decodeToken(token)

  if (!isToken(decoded)) {
    return false
  }

  const date = new Date(0)
  date.setUTCSeconds(decoded.exp)

  return date.valueOf() < new Date().valueOf()
}
