import { Inject, Injectable, Optional } from '@angular/core'
import { REQUEST } from '@nguniversal/express-engine/tokens'
import { Request } from 'express'
import { makeStateKey, StateKey, TransferState } from '@angular/platform-browser'

const bodyIsObject = (body: unknown): body is object => typeof body === 'object'
const keyExistsInBody = <T>(body: object, key: string): body is { [key: string]: T } => key in body

@Injectable({ providedIn: 'root' })
export class TransferRequestService {
  public constructor(@Optional() @Inject(REQUEST) private request: Request, private transferState: TransferState) {}

  public get<T>(key: string, defaultValue: T): T {
    const stateKey: StateKey<string> = makeStateKey<string>(key)
    const body: unknown = this.request?.body

    if (bodyIsObject(body) && keyExistsInBody<T>(body, key)) {
      return body[key]
    } else {
      const stored = this.transferState.get<T>(stateKey, defaultValue)
      if (stored) {
        this.transferState.remove(stateKey)
      }
      return stored
    }
  }
}
