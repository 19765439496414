<div [ngClass]="positionConfig[direction]" class="vertical-modal-container">
  <div
    *ngIf="direction === PositionEnum.RIGHT; else forLeftPosition"
    allianceOuterClick
    (clickedOutside)="animateCloseModal()"
    (click)="onInnerClick($event)"
    class="santa-h-full santa-flex santa-relative santa-bg-white"
    [@openState]="openedState$ | async"
    (@openState.done)="onAnimationEnd($event)"
    [style.margin-right.px]="direction === PositionEnum.RIGHT ? offset : 0"
  >
    <ng-container [cdkPortalOutlet]="content"></ng-container>
  </div>

  <ng-template #forLeftPosition>
    <div
      allianceOuterClick
      (clickedOutside)="animateCloseModal()"
      (click)="onInnerClick($event)"
      class="santa-h-full santa-flex santa-relative santa-bg-white"
      [@openStateLeft]="openedState$ | async"
      (@openStateLeft.done)="onAnimationEnd($event)"
      [style.margin-left.px]="direction === PositionEnum.LEFT ? offset : 0"
    >
      <ng-container [cdkPortalOutlet]="content"></ng-container>
    </div>
  </ng-template>
</div>
