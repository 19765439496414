export const BENEFITS_ICONS_LIST = {
  '1': {
    url: 'https://images.cf-rabota.com.ua/alliance/mini-profile-benefits/new/english-courses.svg',
    __unused_name__: 'Курсы иностранного языка'
  },
  '2': {
    url: 'https://images.cf-rabota.com.ua/alliance/mini-profile-benefits/new/office-near-metro.svg',
    __unused_name__: 'Офис возле метро'
  },
  '3': {
    url: 'https://images.cf-rabota.com.ua/alliance/mini-profile-benefits/new/office-in-center.svg',
    __unused_name__: 'Офис в центре'
  },
  '4': {
    url: 'https://images.cf-rabota.com.ua/alliance/mini-profile-benefits/new/auto.svg',
    __unused_name__: 'Развозка'
  },
  '5': {
    url: 'https://images.cf-rabota.com.ua/alliance/mini-profile-benefits/new/fare.svg',
    __unused_name__: 'Компенсация проезда'
  },
  '6': {
    url: 'https://images.cf-rabota.com.ua/alliance/mini-profile-benefits/new/medical-insurance.svg',
    __unused_name__: 'Медицинское страхование'
  },
  '7': {
    url: 'https://images.cf-rabota.com.ua/alliance/mini-profile-benefits/new/first-aid-post.svg',
    __unused_name__: 'Корпоративный врач/Медпункт'
  },
  '8': {
    url: 'https://images.cf-rabota.com.ua/alliance/mini-profile-benefits/new/free-lunches.svg',
    __unused_name__: 'Бесплатные обеды'
  },
  '9': {
    url: 'https://images.cf-rabota.com.ua/alliance/mini-profile-benefits/new/fridge-with-food.svg',
    __unused_name__: 'Еда в офисе'
  },
  '10': {
    url: 'https://images.cf-rabota.com.ua/alliance/mini-profile-benefits/new/accommodation.svg',
    __unused_name__: 'Проживание'
  },
  '11': {
    url: 'https://images.cf-rabota.com.ua/alliance/mini-profile-benefits/new/discounts-for-employees.svg',
    __unused_name__: 'Скидки сотрудникам'
  },
  '12': {
    url: 'https://images.cf-rabota.com.ua/alliance/mini-profile-benefits/new/bonuses.svg',
    __unused_name__: 'Бонусы и премии'
  },
  '13': {
    url: 'https://images.cf-rabota.com.ua/alliance/mini-profile-benefits/new/regular-review-salaries.svg',
    __unused_name__: 'Регулярный пересмотр зарплаты'
  },
  '14': {
    url: 'https://images.cf-rabota.com.ua/alliance/mini-profile-benefits/new/corporate-events.svg',
    __unused_name__: 'Корпоративные мероприятия'
  },
  '15': {
    url: 'https://images.cf-rabota.com.ua/alliance/mini-profile-benefits/new/gym-compensation.svg',
    __unused_name__: 'Компенсация спортзала'
  },
  '16': {
    url: 'https://images.cf-rabota.com.ua/alliance/mini-profile-benefits/new/free-education.svg',
    __unused_name__: 'Компенсация обучения'
  },
  '17': {
    url: 'https://images.cf-rabota.com.ua/alliance/mini-profile-benefits/new/paid-internship.svg',
    __unused_name__: 'Оплачиваемая стажировка'
  },
  '18': {
    url: 'https://images.cf-rabota.com.ua/alliance/mini-profile-benefits/new/travel-compensation.svg',
    __unused_name__: 'Компенсация путешествий'
  },
  '19': {
    url: 'https://images.cf-rabota.com.ua/alliance/mini-profile-benefits/new/extra-weekend.svg',
    __unused_name__: 'Дополнительные выходные'
  },
  '20': {
    url: 'https://images.cf-rabota.com.ua/alliance/mini-profile-benefits/new/remote-work.svg',
    __unused_name__: 'Удалённая работа'
  },
  '21': {
    url: 'https://images.cf-rabota.com.ua/alliance/mini-profile-benefits/new/flexible-schedule.svg',
    __unused_name__: 'Гибкий график'
  },
  '22': {
    url: 'https://images.cf-rabota.com.ua/alliance/mini-profile-benefits/new/pets.svg',
    __unused_name__: 'Питомцы в офисе'
  },
  '23': {
    url: 'https://images.cf-rabota.com.ua/alliance/mini-profile-benefits/new/school-test-results.svg',
    __unused_name__: 'Профессиональное и корпоративное обучение'
  },
  '24': {
    url: 'https://images.cf-rabota.com.ua/alliance/mini-profile-benefits/new/office-employee.svg',
    __unused_name__: 'Официальное трудоустройство согласно КЗоТу Украины'
  },
  '25': {
    url: 'https://images.cf-rabota.com.ua/alliance/mini-profile-benefits/new/insurance-hands.svg',
    __unused_name__: 'Полный социальный пакет'
  },
  '26': {
    url: 'https://images.cf-rabota.com.ua/alliance/mini-profile-benefits/new/insurance-hand.svg',
    __unused_name__: 'Страхование жизни и медицинское страхование'
  },
  '27': {
    url: 'https://images.cf-rabota.com.ua/alliance/mini-profile-benefits/new/restaurant-eating-set.svg',
    __unused_name__: 'Денежная компенсация питания'
  },
  '28': {
    url: 'https://images.cf-rabota.com.ua/alliance/mini-profile-benefits/new/school-teacher.svg',
    __unused_name__: 'Корпоративное обучение'
  },
  '29': {
    url: 'https://images.cf-rabota.com.ua/alliance/mini-profile-benefits/new/single-neutral-heart.svg',
    __unused_name__: 'Инклюзивный офис'
  },
  '30': {
    url: 'https://images.cf-rabota.com.ua/alliance/mini-profile-benefits/new/money-bags.svg',
    __unused_name__: 'Полностью официальная зарплата'
  }
}
