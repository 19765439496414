import { Injectable } from '@angular/core'
import { PartialSeoParamsResponse, PlatformHosts, VacancySeoParams } from '../models'
import { Observable, zip } from 'rxjs'
import { map } from 'rxjs/operators'
import { Translations } from '../localization/translations'
import { TranslationService } from '@alliance/shared/translation'
import { DictionaryService } from '@alliance/jobseeker/api'
import { HelpersService } from '../helpers.service'
import { BreadcrumbList, JobPosting } from 'schema-dts'
import { VacancyModel } from '../../openapi/model/vacancy.model'
import { isValidKey } from '@alliance/shared/utils'

@Injectable({
  providedIn: 'root'
})
export class VacancyDictionaryService {
  public constructor(private translations: Translations, private translationService: TranslationService, private dictionaryService: DictionaryService, private helpersService: HelpersService) {}

  public getParams({ params }: VacancySeoParams): Observable<PartialSeoParamsResponse> {
    const desktopUrl = `company${params.notebookId}/vacancy${params.vacancyId}`
    const formattedSalary = params.salary ? ` ${params.salary} ${this.translationService.translate(this.translations.vacancy.uah)}` : ''

    return this.zipParams(params, formattedSalary, desktopUrl)
  }

  private zipParams(params: VacancyModel, formattedSalary: string, desktopUrl: string): Observable<PartialSeoParamsResponse> {
    const formattedSchedule = (scheduleName: string): string => (scheduleName ? `${scheduleName}. ` : '')
    return zip(this.dictionaryService.getCityName$(params.cityId, null, true), this.dictionaryService.getScheduleName$(params.scheduleId), this.getJsonLd$(PlatformHosts.desktop, params)).pipe(
      map(([cityName, scheduleName, desktopJsonLd]) => {
        const defaultSeoParams: PartialSeoParamsResponse = {
          title: this.translationService.translate(this.translations.vacancy.title, {
            vacancyName: params.vacancyName,
            companyName: params.companyName,
            cityInflected: cityName,
            salary: formattedSalary
          }),
          description: this.translationService.translate(this.translations.vacancy.description, {
            vacancyName: params.vacancyName,
            companyName: params.companyName,
            cityInflected: cityName,
            salary: formattedSalary,
            schedule: formattedSchedule(scheduleName),
            badges: (params?.vacancyBadges || []).join(' ► ')
          })
        }
        // disable indexing by bots if vacancy is archival:
        if (!params.isActive) {
          return {
            ...defaultSeoParams,
            noIndexNoFollow: true
          }
        }

        return {
          ...defaultSeoParams,
          canonicalUrl: this.helpersService.createURL(PlatformHosts.desktop, desktopUrl),
          alternateUrl: undefined,
          hrefLang: this.helpersService.createHrefLangURLs(desktopUrl, desktopUrl),
          h1: [params.vacancyName ?? ''],
          // If not company name, vacancy is ANONYMOUS and not need jsonLd
          jsonLd: params.companyName
            ? {
                desktop: desktopJsonLd,
                mobile: desktopJsonLd
              }
            : undefined,
          noIndexNoFollow: false
        }
      })
    )
  }

  private stripHtmlTags(description: string): string {
    return description
      .replace(/(<([^>]+)>)/gi, ' ') // remove all html tags
      .replace(/  +/g, ' ') // replace multiple spaces to a single space
      .trim()
      .slice(0, 300)
  }

  private getJsonLd$(platform: PlatformHosts, params: VacancyModel): Observable<string> {
    return zip(
      this.dictionaryService.getCityName$(params.cityId, null, true),
      this.helpersService.createDesktopVacancyListUrl$(params.cityId, ''),
      this.helpersService.createDesktopVacancyListUrl$(0, params.searchTag),
      this.helpersService.createDesktopVacancyListUrl$(params.cityId, params.searchTag)
    ).pipe(
      map(([cityName, cityVacancyListDesktopUrl, searchTagUkraineVacancyListDesktopUrl, searchTagInCityVacancyListDesktopUrl]) =>
        this.helpersService.createJsonLd<BreadcrumbList | JobPosting>(platform, [
          {
            ...this.getBreadcrumbListJsonLd(params, platform, cityName, cityVacancyListDesktopUrl, searchTagUkraineVacancyListDesktopUrl, searchTagInCityVacancyListDesktopUrl)
          },
          {
            ...this.getJobPostingJsonLd(params, cityName)
          }
        ])
      )
    )
  }

  private getJobPostingJsonLd(params: VacancyModel, cityName: string): object {
    const scheduleHashMap = {
      1: 'FULL_TIME',
      2: 'PART_TIME',
      3: 'CONTRACTOR',
      4: 'INTERN',
      5: null,
      6: 'TEMPORARY'
    } as const

    return {
      '@context': 'https://schema.org',
      '@type': 'JobPosting',
      title: params.vacancyName,
      description: this.stripHtmlTags(params.description).slice(0, 300),
      datePosted: params.date,
      employmentType: isValidKey(params.scheduleId, scheduleHashMap) ? scheduleHashMap[params.scheduleId] : null,
      hiringOrganization: {
        '@type': 'Organization',
        name: params.companyName,
        sameAs: `https://rabota.ua/company${params.notebookId}`
      },
      jobLocation: {
        '@type': 'Place',
        address: {
          '@type': 'PostalAddress',
          streetAddress: params?.vacancyAddress ?? '',
          addressLocality: cityName,
          addressCountry: 'UA'
        }
      },
      ...{
        ...(params.scheduleId === 6
          ? {
              jobLocationType: 'TELECOMMUTE',
              applicantLocationRequirements: {
                '@type': 'Country',
                name: 'UA'
              }
            }
          : {})
      }
    }
  }

  private getBreadcrumbListJsonLd(
    params: VacancyModel,
    platform: PlatformHosts,
    cityName: string,
    cityVacancyListDesktopUrl: string,
    searchTagUkraineVacancyListDesktopUrl: string,
    searchTagInCityVacancyListDesktopUrl: string
  ): object {
    const inCityTranslation = this.translationService.translate(this.translations.jsonLd.breadcrumbs.inCity, {
      cityInflected: cityName
    })

    return {
      '@context': 'https://schema.org',
      '@type': 'BreadcrumbList',
      itemListElement: [
        this.helpersService.getHomePageBreadcrumb(platform),
        this.helpersService.getSearchByVacanciesBreadcrumb(platform),
        {
          '@type': 'ListItem',
          position: 3,
          name: `${this.translationService.translate(this.translations.jsonLd.breadcrumbs.job)} ${inCityTranslation}`,
          item: cityVacancyListDesktopUrl
        },
        {
          '@type': 'ListItem',
          position: 4,
          name: params.searchTag,
          item: searchTagUkraineVacancyListDesktopUrl
        },
        {
          '@type': 'ListItem',
          position: 5,
          name: `${params.searchTag} ${inCityTranslation}`,
          item: searchTagInCityVacancyListDesktopUrl
        },
        {
          '@type': 'ListItem',
          position: 6,
          name: params.searchTag
        }
      ]
    }
  }
}
