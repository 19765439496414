import { SEOObject } from '../../openapi/model/vacancy-list.model'
import { RUA_DOMAIN } from '../constants'

const seoMapping: Record<string, SEOObject> = {
  cityId: {
    h1: '{keywords, select, null{Робота} other{Робота {keywords}}} в {cityId}',
    title: `▷ Робота в {cityId} | Пошук вакансій в {cityId} - знайди свою роботу на ${RUA_DOMAIN} {page, select, undefined{} other{| Стр. {page}}}`,
    description: `Шукаєте роботу в {cityId}? ✔ Робота в {cityId} - {totalItems} актуальних вакансій в {cityId} для вас! ✌ Знайди свою роботу в {cityId} на ${RUA_DOMAIN} {page, select, undefined{} other{| Стр. {page}}}`
  },
  'cityId+keywords': {
    h1: '{keywords, select, null{Робота} other{Робота {keywords}}} в {cityId}',
    title: `{keywords, select, null{Робота} other{Робота {keywords}}} в {cityId}, пошук {keywords, select, null{вакансій} other{вакансій {keywords}}} в {cityId} | ${RUA_DOMAIN} {page, select, undefined{} other{| Стр. {page}}}`,
    description: `${RUA_DOMAIN} ✪ шукаєте {keywords, select, null{роботу} other{роботу {keywords}}} в {cityId}? ➤ {totalItems} актуальних {keywords, select, null{вакансій} other{вакансій {keywords}}} в {cityId}. ☛ Актуальні вакансії {page, select, undefined{} other{| Стр. {page}}}`
  },
  'cityId+salary': {
    h1: `Робота з заробітною платою {salary} в {cityId}`,
    title: `▷ Робота з заробітною платою {salary} в {cityId} | Пошук вакансій з заробітною платою {salary} - знайди свою роботу на ${RUA_DOMAIN} {page, select, undefined{} other{| Стр. {page}}}`,
    description: `Шукаєте роботу з заробітною платою {salary} в {cityId}? ✔ {totalItems} актуальних вакансій для вас! ✌ Знайди свою роботу на ${RUA_DOMAIN} {page, select, undefined{} other{| Стр. {page}}}`
  },
  'cityId+districtIds': {
    h1: `Робота в {districtIds} районі {cityId}`,
    title: `▷ Робота в {districtIds} районі {cityId} | Пошук вакансій в {districtIds} районе {cityId} - знайди свою роботу на ${RUA_DOMAIN} {page, select, undefined{} other{| Стр. {page}}}`,
    description: `Шукаєте роботу в {districtIds} районе {cityId}? ✔{districtIds} актуальних вакансій для вас! ✌ Знайди свою роботу на ${RUA_DOMAIN} {page, select, undefined{} other{| Стр. {page}}}`
  }
}

export default {
  home: {
    title: `Робота в Україні. Працевлаштування й пошук роботи на ${RUA_DOMAIN}`,
    description: `Сайт пошуку роботи ${RUA_DOMAIN}. Зручні фільтри, якісна база вакансій і резюме - знайди кращу роботу у нас!`
  },
  employer: {
    title: `Публікація вакансій — Розміщення ефективних вакансій на сайті ${RUA_DOMAIN}`,
    description: `${RUA_DOMAIN} - ★ Публікація ефективних вакансій ★ - Найбільша база резюме України ➨ Розумний пошук ➨ Найкращі варіанти ➨ Знайти співробітника тепер просто! ${RUA_DOMAIN}`
  },
  list: {
    ...seoMapping
  },
  vacancyList: {
    ukraine: 'Україні',
    h1: '{keywords, select, null{Робота} other{Робота {keywords}}} в {cityName}',
    default: {
      title: `Вакансії в Україні. Знайти роботу на ${RUA_DOMAIN}`,
      description: `На нашому сайті завжди самі свіжі вакансії. Переглядайте список вакансій по розділах і містам.`
    }
  },
  company: {
    title: `Робота в {companyName}. Вакансії в {companyName} | ${RUA_DOMAIN}`,
    description: `{vacanciesCount} {vacanciesCount, plural, one{вакансія} few{вакансії} other{вакансій}} {companyName} на ${RUA_DOMAIN}. Хочете працювати в {companyName}. Тільки актуальні вакансії на ${RUA_DOMAIN}.`
  },
  vacancy: {
    title: `Вакансія: {vacancyName} {salary} в {cityInflected} - {companyName} | ${RUA_DOMAIN}`,
    description: `Вакансія - {vacancyName} у {cityInflected}, {companyName} ► {badges}.`,
    uah: 'грн'
  },
  prozora: {
    in: 'в',
    title: `Середня зарплата{cityInflected}. Рівень, статистика заробітної плати на ${RUA_DOMAIN}`,
    description: `Яка середня зарплата{keyword}{cityInflected}. Середній дохід, рівень, статистика заробітної плати на ${RUA_DOMAIN}`,
    ukraine: 'Україні',
    h1: {
      statistic: 'Статистика',
      forPosition: 'для позиції',
      in: 'по'
    }
  },
  cvwelcome: {
    title: `Створити резюме - скласти резюме онлайн | Конструктор резюме від ${RUA_DOMAIN}`,
    description: `${RUA_DOMAIN} - Створи резюме онлайн. Конструктор резюме від ${RUA_DOMAIN} допоможе скласти резюме онлайн безкоштовно. Шаблон резюме з фото ${RUA_DOMAIN}`,
    h1: {
      publish: 'Розмістити',
      resume: 'резюме'
    }
  },
  jsonLd: {
    androidAppName: `${RUA_DOMAIN} - робота в Україні (для пошукачів)`,
    androidEmployerAppName: `${RUA_DOMAIN} - робота в Україні`, // TEMPORARY TEXT
    iosAppName: `Робота і вакансії на ${RUA_DOMAIN}`,
    iosEmployerAppName: `Робота і вакансії на ${RUA_DOMAIN}`, // TEMPORARY TEXT
    breadcrumbs: {
      home: 'Головна',
      vacancies: 'Вакансії',
      resume: 'Резюме',
      resumeInUkraine: 'Резюме в областях України',
      job: 'Робота',
      inCity: 'в {cityInflected}',
      allUkraine: 'Вся Україна'
    }
  },
  searchByRootPage: {
    title: `Пошук резюме - Знайти резюме співробітника в Україні — Підбір персоналу на ${RUA_DOMAIN}`,
    description: `Підбір персоналу на robota.ua - зручно і ефективно. Шукайте резюме за рубриками, у містах і іншим параметрам.`,
    defaultHeadingText: 'Пошук резюме ',
    h1: {
      rubrics: 'за рубриками',
      professions: 'за професіями',
      cities: 'за містами'
    }
  },
  searchByRubrics: {
    title: `Вакансії в Україні. Знайти роботу на ${RUA_DOMAIN}`,
    description: 'На нашому сайті завжди самі свіжі вакансії. Переглядайте список вакансій по розділах і містам.',
    h1: 'Пошук вакансій за рубриками'
  },
  searchTabCity: {
    title: `Пошук роботи по областям України - ${RUA_DOMAIN}`,
    description: `Зручний пошук роботи по областям України ✔ Кращі пропозиції роботодавців ➨ Швидкий і зручний алгоритм пошуку роботи на сайті ${RUA_DOMAIN}`,
    h1: 'Пошук роботи по містах України'
  },
  searchTabRegion: {
    title: `Пошук роботи по областям України - ${RUA_DOMAIN}`,
    description: `Зручний пошук роботи по областям України ✔ Кращі пропозиції роботодавців ➨ Швидкий і зручний алгоритм пошуку роботи на сайті ${RUA_DOMAIN}`,
    h1: 'Пошук роботи по областях України'
  },
  searchProfAlphabet: {
    title: `Пошук роботи за професіями в Україні - ${RUA_DOMAIN}`,
    description: `Зручний пошук роботи за професіями в Україні. Статистика кількості вакансій по кожній професії в Україні на сайті ${RUA_DOMAIN}`,
    h1: 'Пошук роботи за професіями'
  },
  searchProfCity: {
    title: `Пошук роботи за професіями в містах України - ${RUA_DOMAIN}`,
    description: `Зручний пошук вакансій за професіями у всіх містах України на порталі ${RUA_DOMAIN}`,
    h1: 'Пошук вакансій за професіями в Україні'
  },
  searchProfSelectCity: {
    title: `Пошук роботи за професіями в {cityName} - ${RUA_DOMAIN}`,
    description: `Зручний пошук роботи за професіями в {cityName}. Кращі пропозиції роботодавців ➨ Швидкий і зручний алгоритм пошуку роботи на сайті ${RUA_DOMAIN}`,
    h1: 'Пошук вакансій за професіями в {cityName}'
  },
  searchCompanyIndustry: {
    title: `Пошук роботи по компаніях України - ${RUA_DOMAIN}`,
    description: `Зручний пошук роботи по компаніях України ✔ Кращі пропозиції роботодавців ➨ Швидкий і зручний алгоритм пошуку роботи на сайті ${RUA_DOMAIN}`,
    h1: 'Пошук роботи по компаніях'
  },
  searchCompanyName: {
    pageLeadingText: '| Стр. {page}',
    default: {
      title: `Компанії в Україні. Каталог компаній на ${RUA_DOMAIN}`,
      description: `Компанії в Україні. Працевлаштування, вакансії і робота в Україні. Галузевий каталог компаній на порталі ${RUA_DOMAIN}`
    },
    h1: 'Пошук роботи в компаніях'
  },
  candidates: {
    default: {
      pageLeadingText: '| Стр. {page}',
      title: `Резюме у Всій Україні, пошук резюме у Всій Україні | ${RUA_DOMAIN}`,
      description: `${RUA_DOMAIN} - пошук резюме у Всій Україні. {total} актуальних резюме у Всій Україні`,
      h1: 'Резюме у Всій Україні'
    },
    city: {
      title: `Резюме в {cityInflected}, пошук резюме в {cityInflected} | ${RUA_DOMAIN}`,
      description: `${RUA_DOMAIN} - пошук резюме в {cityInflected}. {total} актуальних резюме в {cityInflected}`,
      h1: 'Резюме в {cityInflected}'
    },
    keyword: {
      title: `Резюме {keyword} у Всій Україні, пошук резюме {keyword} у Всій Україні | ${RUA_DOMAIN}`,
      description: `${RUA_DOMAIN} - пошук резюме {keyword} у Всій Україні. {total} актуальних резюме {keyword} у Всій Україні`,
      h1: 'Резюме {keyword} в Україні'
    },
    keywordAndCity: {
      title: `Резюме {keyword} в {cityInflected}, пошук резюме {keyword} в {cityInflected} | ${RUA_DOMAIN}`,
      description: `${RUA_DOMAIN} - пошук резюме {keyword} в {cityInflected}. {total} актуальних резюме {keyword} в {cityInflected}`,
      h1: 'Резюме {keyword} в {cityInflected}'
    }
  },
  candidatePage: {
    resume: 'Резюме',
    in: 'в',
    jobSearch: 'пошук роботи',
    keyInfo: 'Ключова інформація',
    lastJob: 'останнє місце роботи',
    unknown: 'невідомо',
    years: '{amount} {amount, plural, one{рік} few{роки} other{років}}',
    months: '{amount} {amount, plural, one{місяць} few{місяці} other{місяців}}'
  }
}
