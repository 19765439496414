import { Injectable } from '@angular/core'
import { map, Observable } from 'rxjs'
import { ChatCountService } from '@alliance/chat/domain'

@Injectable({ providedIn: 'root' })
export class ChatCountApiService {
  public constructor(private chatCountService: ChatCountService) {}

  public getChatCount$(): Observable<number> {
    return this.chatCountService.select('chatCounters').pipe(map(countData => countData.totalUnreadMessagesCount ?? 0))
  }
}
