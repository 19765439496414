export interface MediaLibraryViewModel {
  id?: number
  url?: string
  type?: MediaLibraryViewModel.TypeEnum
  addedByMultiUserId?: number
  lastTimeUsedAt?: Date
}

export namespace MediaLibraryViewModel {
  export type TypeEnum = 'UploadedImage' | 'YoutubeVideo'
  export const TypeEnum = {
    UploadedImage: 'UploadedImage' as TypeEnum,
    YoutubeVideo: 'YoutubeVideo' as TypeEnum
  }
}
