import { Injectable } from '@angular/core'
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http'
import { BaseService as __BaseService } from '../base-service'
import { EmployerDataAccessConfiguration as __Configuration } from '../employer-data-access-configuration'
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response'
import { Observable as __Observable } from 'rxjs'
import { map as __map, filter as __filter } from 'rxjs/operators'

import { MiniProfile } from '../models/mini-profile'
import { MiniProfileUpdate } from '../models/mini-profile-update'
@Injectable({
  providedIn: 'root'
})
class NotebookProfileService extends __BaseService {
  public static readonly getPath = '/api/notebookprofile/{notebookId}'
  public static readonly updatePath = '/api/notebookprofile'
  public static readonly changeStatusPath = '/api/notebookprofile/status/{isEnabled}'
  public static readonly putImagePath = '/api/notebookprofile/image'

  public constructor(config: __Configuration, http: HttpClient) {
    super(config, http)
  }

  /**
   * @param notebookId undefined
   * @return Success
   */
  getResponse(notebookId: number): __Observable<__StrictHttpResponse<MiniProfile>> {
    let __params = this.newParams()
    let __headers = new HttpHeaders()
    let __body: any = null

    let req = new HttpRequest<any>('GET', this.rootUrl + `/api/notebookprofile/${notebookId}`, __body, {
      headers: __headers,
      params: __params,
      responseType: 'json'
    })

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map(_r => {
        return _r as __StrictHttpResponse<MiniProfile>
      })
    )
  }

  /**
   * @param notebookId undefined
   * @return Success
   */
  get(notebookId: number): __Observable<MiniProfile> {
    return this.getResponse(notebookId).pipe(__map(_r => _r.body as MiniProfile))
  }

  /**
   * @param profile undefined
   */
  updateResponse(profile?: MiniProfileUpdate): __Observable<__StrictHttpResponse<any>> {
    let __params = this.newParams()
    let __headers = new HttpHeaders()
    let __body: any = null
    __body = profile
    let req = new HttpRequest<any>('POST', this.rootUrl + `/api/notebookprofile`, __body, {
      headers: __headers,
      params: __params,
      responseType: 'json'
    })

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map(_r => {
        return _r as __StrictHttpResponse<any>
      })
    )
  }

  /**
   * @param profile undefined
   */
  update(profile?: MiniProfileUpdate): __Observable<any> {
    return this.updateResponse(profile).pipe(__map(_r => _r.body as any))
  }

  /**
   * @param isEnabled undefined
   */
  changeStatusResponse(isEnabled: boolean): __Observable<__StrictHttpResponse<any>> {
    let __params = this.newParams()
    let __headers = new HttpHeaders()
    let __body: any = null

    let req = new HttpRequest<any>('PUT', this.rootUrl + `/api/notebookprofile/status/${isEnabled}`, __body, {
      headers: __headers,
      params: __params,
      responseType: 'json'
    })

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map(_r => {
        return _r as __StrictHttpResponse<any>
      })
    )
  }

  /**
   * @param isEnabled undefined
   */
  changeStatus(isEnabled: boolean): __Observable<any> {
    return this.changeStatusResponse(isEnabled).pipe(__map(_r => _r.body as any))
  }

  /**
   * @param file The file to upload.
   * @return Success
   */
  putImageResponse(file: Blob): __Observable<__StrictHttpResponse<string>> {
    let __params = this.newParams()
    let __headers = new HttpHeaders()
    let __body: any = null
    let __formData = new FormData()
    __body = __formData
    if (file != null) {
      __formData.append('file', file as string | Blob)
    }
    let req = new HttpRequest<any>('PUT', this.rootUrl + `/api/notebookprofile/image`, __body, {
      headers: __headers,
      params: __params,
      responseType: 'json'
    })

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map(_r => {
        return _r as __StrictHttpResponse<string>
      })
    )
  }

  /**
   * @param file The file to upload.
   * @return Success
   */
  putImage(file: Blob): __Observable<string> {
    return this.putImageResponse(file).pipe(__map(_r => _r.body as string))
  }
}

module NotebookProfileService {}

export { NotebookProfileService }
