/**
 * Seo Gateway
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * contains url to the same page in another language
 */
export interface SeoParamsResponseHrefLangModel {
  langCode: SeoParamsResponseHrefLangModel.LangCodeEnum
  desktopUrl: string
  mobileUrl: string
}

// eslint-disable-next-line @typescript-eslint/no-namespace
export namespace SeoParamsResponseHrefLangModel {
  export type LangCodeEnum = 'uk' | 'ru'
  export const LangCodeEnum = {
    Uk: 'uk' as LangCodeEnum,
    Ru: 'ru' as LangCodeEnum
  }
}
