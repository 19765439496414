import { CookieDict, CookieOptions } from 'ngx-cookie'
import { AbstractStorage } from './abstract-storage'

export abstract class CookieStorage extends AbstractStorage {
  public abstract readonly isAvailable: boolean

  public abstract override setItem(key: string, value: string, options?: Partial<CookieOptions>, removableAfterLogout?: boolean): void
  public abstract getAll(): CookieDict
  public abstract clearAllRemovableKeysAfterLogout(): void
  public abstract override clear(options?: Partial<CookieOptions>): void
}
