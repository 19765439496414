import { Injectable } from '@angular/core'
import { BehaviorSubject, Observable } from 'rxjs'
import { distinctUntilChanged, filter } from 'rxjs/operators'

@Injectable({ providedIn: 'root' })
export class RouterConfigStateService {
  public routerConfigState$: Observable<string | null>

  private _routerConfigState = new BehaviorSubject<null | string>(null)

  private _basePath = new BehaviorSubject<null | string>(null)

  public constructor() {
    this.routerConfigState$ = this._routerConfigState.asObservable().pipe(
      filter(state => !!state),
      distinctUntilChanged()
    )
  }

  public setRouterConfigState(state: string): void {
    this._routerConfigState.next(state)
  }

  public setBasePath(path: string): void {
    this._basePath.next(path)
  }

  public selectBasePath$(): Observable<string | null> {
    return this._basePath.asObservable().pipe(
      filter(path => !!path),
      distinctUntilChanged()
    )
  }
}
