import { TransferService } from '@alliance/shared/utils'
import { animate, state, style, transition, trigger } from '@angular/animations'
import { AfterViewInit, ChangeDetectionStrategy, Component, ElementRef, Inject, Input, ViewChild, ViewContainerRef } from '@angular/core'
import { DOCUMENT } from '@angular/common'
import { asyncScheduler, BehaviorSubject, timer } from 'rxjs'
import { ModalComponent } from '../modal/modal.component'
import { SANTA_MODAL_REF } from '../../services/modal/modal.tokens'
import { ModalOverlayRef } from '../../services/modal/modal-overlay-ref'

export type ModalMode = 'default' | 'full'
// Hammer.js возвращает тип данных для event как HammerInput, однако этот HammerInput невозможно экспортировать. Поэтому я создал этот интерфейс, чтобы можно было сделать так: (event as unknown) as CustomHammerEvent.
interface CustomHammerEvent {
  [key: string]: unknown
  deltaY: number
}

@Component({
  selector: 'santa-mobile-overlay-modal',
  templateUrl: './mobile-overlay-modal.component.html',
  styleUrls: ['./mobile-overlay-modal.component.tw.css'],
  animations: [
    trigger('openState', [
      state('void, exit', style({ transform: 'translateY(100%)' })),
      state(
        'opened',
        style({
          transform: 'none'
        })
      ),
      transition('* => *', [animate('200ms cubic-bezier(0.86, 0, 0.07, 1)')])
    ]),
    trigger('openStateBottom', [
      state('void, exit', style({ transform: 'translateY(200%)' })),
      state(
        'opened',
        style({
          transform: 'none'
        })
      ),
      transition('* => *', [animate('200ms cubic-bezier(0.86, 0, 0.07, 1)')])
    ])
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MobileOverlayModalComponent<T> extends ModalComponent<T> implements AfterViewInit {
  @Input() public resetHeightOnContentMutation = false
  @Input() public fullHeight = false
  @ViewChild('mobileModalWrapper') public mobileModalWrapper: ElementRef<HTMLElement> | undefined
  @ViewChild('mobileModalContent') public mobileModalContent: ElementRef<HTMLElement> | undefined

  public height$ = new BehaviorSubject<number>(0)
  public heightOnStart$ = new BehaviorSubject<number>(0)
  public heightOnFullMode$ = new BehaviorSubject<number>(0)

  public modalMode$ = new BehaviorSubject<ModalMode>('default')
  public blockIsResized$ = new BehaviorSubject<boolean>(false)
  public disabled$ = new BehaviorSubject<boolean>(false)

  public readonly delta = 50

  public constructor(@Inject(SANTA_MODAL_REF) _modalOverlayRef: ModalOverlayRef, transfer: TransferService, @Inject(DOCUMENT) private document: Document, private viewContainerRef: ViewContainerRef) {
    super(_modalOverlayRef, transfer)
  }

  public getViewContainerRef(): ViewContainerRef {
    return this.viewContainerRef
  }

  public setHeight(val: number): void {
    this.height$.next(val)
  }

  // hummer js event move down
  public onPanDown(event: Event): void {
    const e = event as unknown as CustomHammerEvent
    this.disabled$.next(true)
    this.setHeight(this.height$.getValue() - Math.abs(e.deltaY / 25))
  }

  // hummer js event move up
  public onPanUp(event: Event): void {
    const e = event as unknown as CustomHammerEvent
    this.disabled$.next(true)
    this.setHeight(this.height$.getValue() + Math.abs(e.deltaY / 25))
  }

  public onPanStart(): void {
    this.disabled$.next(true)
  }

  // hummer js event end
  public onPanEnd(event: Event): void {
    const e = event as unknown as CustomHammerEvent
    timer(100, asyncScheduler).subscribe(() => this.disabled$.next(false))

    // если mode default и тянем вниз на ростояние больше чем this.delta - закрываем
    if (this.modalMode$.getValue() === 'default' && e.deltaY > this.delta) {
      this.animateCloseModal()
      return
    }

    // если mode full и тянем вниз на ростояние больше чем this.delta - делаем дефолтную высоту блока
    if (this.modalMode$.getValue() === 'full' && e.deltaY > this.delta) {
      this.setHeight(this.heightOnStart$.getValue())
      this.modalMode$.next('default')
      return
    }

    // если mode full и тянем ввверх - ничего не меняем
    if (this.modalMode$.getValue() === 'full' && e.deltaY > 0) {
      this.setHeight(this.heightOnFullMode$.getValue())
      return
    }

    // если mode full и тянем ввверх - ничего не меняем
    if (e.deltaY < -this.delta) {
      this.setHeight(this.heightOnFullMode$.getValue())
      this.modalMode$.next('full')
      return
    }

    // если deltaY от 0 до -100
    if (e.deltaY < 0 && e.deltaY > -this.delta) {
      this.setHeight(this.heightOnStart$.getValue())
      this.modalMode$.next('default')
      return
    }

    // если deltaY от 0 до 100
    if (e.deltaY > 0 && e.deltaY < this.delta) {
      this.setHeight(this.heightOnStart$.getValue())
      this.modalMode$.next('default')
      return
    }
  }

  public ngAfterViewInit(): void {
    timer(10, asyncScheduler).subscribe(() => this.heightBlockAtOpening())
  }

  public onContentMutation(): void {
    if (this.resetHeightOnContentMutation && this.heightOnStart$.getValue()) {
      const blockHeight = (this.mobileModalContent?.nativeElement?.scrollHeight ?? 0) + 25 // pan
      this.height$.next(blockHeight < this.heightOnFullMode$.getValue() ? blockHeight : this.heightOnFullMode$.getValue())
      this.heightOnStart$.next(Math.max(blockHeight, this.heightOnStart$.getValue()))
    }
  }

  // высота блока при открытии
  private heightBlockAtOpening(): void {
    const screenHeight = this.document?.documentElement?.clientHeight ?? 0
    const blockHeight = this.mobileModalWrapper?.nativeElement?.offsetHeight ?? 0

    if (this.fullHeight) {
      this.height$.next(screenHeight - 60)
      this.blockIsResized$.next(true)
    } else {
      // если половина высоты екрана больше чем высота блока то
      if (screenHeight / 2 > blockHeight) {
        this.height$.next(blockHeight)
      } else {
        this.height$.next(screenHeight / 2)
        this.blockIsResized$.next(true)
      }
    }

    this.heightOnStart$.next(this.height$.getValue())
    this.heightOnFullMode$.next(screenHeight - 60)
  }
}
