/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core'
import { HttpClient, HttpResponse, HttpContext } from '@angular/common/http'
import { BaseService } from '../base-service'
import { CompanyEmployeesApiDataAccessConfiguration } from '../company-employees-api-data-access-configuration'
import { StrictHttpResponse } from '../strict-http-response'
import { RequestBuilder } from '../request-builder'
import { Observable } from 'rxjs'
import { map, filter } from 'rxjs/operators'

import { UploadContactPhotoOutput } from '../models/upload-contact-photo-output'

@Injectable({
  providedIn: 'root'
})
export class CompanyEmployeesApiService extends BaseService {
  public constructor(config: CompanyEmployeesApiDataAccessConfiguration, http: HttpClient) {
    super(config, http)
  }

  /**
   * Path part for operation apiEmployeesPersonalInfoContactPhotoPost
   */
  public static readonly ApiEmployeesPersonalInfoContactPhotoPostPath = '/api/employees-personal-info/contact-photo'

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiEmployeesPersonalInfoContactPhotoPost$Plain()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  apiEmployeesPersonalInfoContactPhotoPost$Plain$Response(params?: {
    context?: HttpContext
    body?: {
      file?: Blob | null
    }
  }): Observable<StrictHttpResponse<UploadContactPhotoOutput>> {
    const rb = new RequestBuilder(this.rootUrl, CompanyEmployeesApiService.ApiEmployeesPersonalInfoContactPhotoPostPath, 'post')
    if (params) {
      rb.body(params.body, 'multipart/form-data')
    }

    return this.http
      .request(
        rb.build({
          responseType: 'text',
          accept: 'text/plain',
          context: params?.context
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<UploadContactPhotoOutput>
        })
      )
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiEmployeesPersonalInfoContactPhotoPost$Plain$Response()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  apiEmployeesPersonalInfoContactPhotoPost$Plain(params?: {
    context?: HttpContext
    body?: {
      file?: Blob | null
    }
  }): Observable<UploadContactPhotoOutput> {
    return this.apiEmployeesPersonalInfoContactPhotoPost$Plain$Response(params).pipe(map((r: StrictHttpResponse<UploadContactPhotoOutput>) => r.body as UploadContactPhotoOutput))
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiEmployeesPersonalInfoContactPhotoPost$Json()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  apiEmployeesPersonalInfoContactPhotoPost$Json$Response(params?: {
    context?: HttpContext
    body?: {
      file?: Blob | null
    }
  }): Observable<StrictHttpResponse<UploadContactPhotoOutput>> {
    const rb = new RequestBuilder(this.rootUrl, CompanyEmployeesApiService.ApiEmployeesPersonalInfoContactPhotoPostPath, 'post')
    if (params) {
      rb.body(params.body, 'multipart/form-data')
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'text/json',
          context: params?.context
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<UploadContactPhotoOutput>
        })
      )
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiEmployeesPersonalInfoContactPhotoPost$Json$Response()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  apiEmployeesPersonalInfoContactPhotoPost$Json(params?: {
    context?: HttpContext
    body?: {
      file?: Blob | null
    }
  }): Observable<UploadContactPhotoOutput> {
    return this.apiEmployeesPersonalInfoContactPhotoPost$Json$Response(params).pipe(map((r: StrictHttpResponse<UploadContactPhotoOutput>) => r.body as UploadContactPhotoOutput))
  }
}
