import { PublishedVacanciesClientFilterInput } from '@alliance/shared/domain-gql'
import { RxStateService } from '@alliance/shared/models'
import { LocalStorage } from '@alliance/shared/storage'
import { DetectPlatformService, safeJsonParse } from '@alliance/shared/utils'
import { Injectable } from '@angular/core'
import { filter } from 'rxjs/operators'
import { VacanciesLastFiltersStorageKey } from './constants/vacancies-last-filters-storage-key'

@Injectable({ providedIn: 'root' })
export class VacanciesSearchedFiltersService extends RxStateService<{
  filters: PublishedVacanciesClientFilterInput | null
  matrixParams: { [key: string]: string } | null
}> {
  public constructor(private platform: DetectPlatformService, private localStorage: LocalStorage) {
    super()

    this.initState({
      filters: null,
      matrixParams: null
    })

    if (this.platform.isBrowser) {
      const filters = safeJsonParse<PublishedVacanciesClientFilterInput | null>(this.localStorage.getItem(VacanciesLastFiltersStorageKey), null)

      this.set({ filters })
    }

    this.hold(this.select('filters').pipe(filter(Boolean)), filters => {
      this.localStorage.setItem(VacanciesLastFiltersStorageKey, JSON.stringify(filters))
    })
  }

  public setSearchedFilters(input: PublishedVacanciesClientFilterInput): void {
    this.set('filters', ({ filters }) => ({ ...(filters || {}), ...input }))
  }
}
