/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { Observable } from 'rxjs'
import { map } from 'rxjs/operators'

import { BaseService } from '../base-service'
import { VacancyApiDataAccessConfiguration } from '../vacancy-api-data-access-configuration'
import { StrictHttpResponse } from '../strict-http-response'

import { contactPhotoPost$Json } from '../fn/contact-photo/contact-photo-post-json'
import { ContactPhotoPost$Json$Params } from '../fn/contact-photo/contact-photo-post-json'
import { contactPhotoPost$Plain } from '../fn/contact-photo/contact-photo-post-plain'
import { ContactPhotoPost$Plain$Params } from '../fn/contact-photo/contact-photo-post-plain'
import { VacancyApiUploadContactPhotoOutput } from '../models/vacancy-api-upload-contact-photo-output'

@Injectable({ providedIn: 'root' })
export class VacancyApiContactPhotoService extends BaseService {
  constructor(config: VacancyApiDataAccessConfiguration, http: HttpClient) {
    super(config, http)
  }

  /** Path part for operation `contactPhotoPost()` */
  static readonly ContactPhotoPostPath = '/contact-photo'

  /**
   * Upload vacancy contact photo to file storage and return full url.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `contactPhotoPost$Plain()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  contactPhotoPost$Plain$Response(params?: ContactPhotoPost$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<VacancyApiUploadContactPhotoOutput>> {
    return contactPhotoPost$Plain(this.http, this.rootUrl, params, context)
  }

  /**
   * Upload vacancy contact photo to file storage and return full url.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `contactPhotoPost$Plain$Response()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  contactPhotoPost$Plain(params?: ContactPhotoPost$Plain$Params, context?: HttpContext): Observable<VacancyApiUploadContactPhotoOutput> {
    return this.contactPhotoPost$Plain$Response(params, context).pipe(map((r: StrictHttpResponse<VacancyApiUploadContactPhotoOutput>): VacancyApiUploadContactPhotoOutput => r.body))
  }

  /**
   * Upload vacancy contact photo to file storage and return full url.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `contactPhotoPost$Json()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  contactPhotoPost$Json$Response(params?: ContactPhotoPost$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<VacancyApiUploadContactPhotoOutput>> {
    return contactPhotoPost$Json(this.http, this.rootUrl, params, context)
  }

  /**
   * Upload vacancy contact photo to file storage and return full url.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `contactPhotoPost$Json$Response()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  contactPhotoPost$Json(params?: ContactPhotoPost$Json$Params, context?: HttpContext): Observable<VacancyApiUploadContactPhotoOutput> {
    return this.contactPhotoPost$Json$Response(params, context).pipe(map((r: StrictHttpResponse<VacancyApiUploadContactPhotoOutput>): VacancyApiUploadContactPhotoOutput => r.body))
  }
}
