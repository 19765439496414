import { DOCUMENT } from '@angular/common'
import { APP_INITIALIZER, FactoryProvider } from '@angular/core'
import { distinctUntilChanged } from 'rxjs/operators'
import { TranslationService } from '../translation.service'

export const provideDocumentLangAttribute = (): FactoryProvider => ({
  provide: APP_INITIALIZER,
  useFactory: (document: Document, translationService: TranslationService) => (): void => {
    translationService
      .langChanges$()
      .pipe(distinctUntilChanged())
      .subscribe(language => {
        if (document?.documentElement) {
          document.documentElement.lang = language
        }
      })
  },
  multi: true,
  deps: [DOCUMENT, TranslationService]
})
