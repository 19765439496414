import { GenericTranslation, LanguageCodesEnum, transformObjectToPath, TranslationService } from '@alliance/shared/translation'
import { Injectable } from '@angular/core'
import ru from './ru'
import uk from './uk'

const prefix = '@jobseeker/utils'

@Injectable({ providedIn: 'root' })
export class Translations extends GenericTranslation<typeof uk>() {
  public constructor(translationService: TranslationService) {
    translationService.addWithPrefix(prefix, LanguageCodesEnum.UK, uk)
    translationService.addWithPrefix(prefix, LanguageCodesEnum.RU, ru)
    super()
    Object.assign(this, transformObjectToPath(prefix, uk))
  }
}

export { uk, ru }
