import { Environment } from '@alliance/shared/environment'
import { log } from '@alliance/shared/logger'
import { HttpClient, HttpHeaders } from '@angular/common/http'
import { Inject, Injectable, isDevMode } from '@angular/core'
import { WINDOW } from '@ng-web-apis/common'
import { take } from 'rxjs/operators'
import { DetectPlatformService } from './detect-platform.service'

export interface DataLayerPushEvent {
  [key: string]: string | number | null | undefined
  event: string
  eventAction?: string | number | null | undefined
  eventCategory?: string | number | null | undefined
  eventLabel?: string | number | null | undefined
  eventContent?: string | number | null | undefined
}

export interface DataLayerPushVariable {
  [key: string]: string
}

interface CustomWindow extends Window {
  dataLayer: unknown[]
}

@Injectable({
  providedIn: 'root'
})
export class LoggingToBigQueryService {
  public constructor(private platform: DetectPlatformService, private http: HttpClient, private environment: Environment, @Inject(WINDOW) private readonly windowRef: CustomWindow) {}

  public pushToDataLayer(options: DataLayerPushEvent | DataLayerPushVariable): void {
    const validators = {
      notDev: !isDevMode(),
      hasOptions: !!options,
      isBrowser: this.platform.isBrowser
    }

    if (Object.values(validators).every(v => v) && this.platform.isBrowser) {
      try {
        let dataLayer = this.windowRef.dataLayer
        dataLayer = dataLayer || []
        dataLayer.push(options)
      } catch (e) {
        log.warn({ where: 'shared-utils: LoggingToBigQueryService', category: 'try_catch', message: 'pushToDataLayer failed', error: e })
      }
    }
  }

  public log(body: object): void {
    const url = `${this.environment.matchingEuropeApi ?? ''}/collecteu`
    const headers = new HttpHeaders({
      'Content-Type': 'application/json'
    })
    if (body && Object.keys(body).length > 0) {
      this.http
        .post(url, body ?? {}, { headers })
        .pipe(take(1))
        .subscribe({
          next: (): null => null,
          error: (e: Error): void => log.error({ where: 'shared-utils: LoggingToBigQueryService', category: 'api_call_failed', message: 'log to collecteu failed', error: e })
        })
    } else {
      log.error({ where: 'shared-utils: LoggingToBigQueryService', category: 'unexpected_value', message: 'log to collecteu failed', error: 'body cannot be empty' })
    }
  }
}
