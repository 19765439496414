import { finalize, Observable, shareReplay } from 'rxjs'

export class MulticastObservable {
  private readonly KEY = 'default'
  private readonly pending = new Map<string, Observable<unknown>>()

  public multicast<T>(source: Observable<T>, uniqueKey = this.KEY): Observable<T> {
    const pending$ = (this.pending as Map<string, Observable<T>>).get(uniqueKey)
    if (!pending$) {
      const stream$ = source.pipe(shareReplay())
      this.pending.set(uniqueKey, stream$)
      return stream$.pipe(finalize(() => this.pending.delete(uniqueKey)))
    }
    return pending$
  }
}
