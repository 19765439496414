import { log } from '@alliance/shared/logger'
import { UrlMatchResult, UrlSegment } from '@angular/router'

const getCompanyId = (segments: UrlSegment[]): UrlSegment => {
  try {
    const companyId = segments[0].toString().split('company')[1]
    return new UrlSegment(companyId, {})
  } catch (e) {
    log.warn({ where: 'desktop', category: 'try_catch', message: 'vac-page-matcher: getCompanyId failed', error: e })
    return new UrlSegment('', {})
  }
}

const getVacancyId = (segments: UrlSegment[]): UrlSegment => {
  try {
    const vacancyId = segments[1].toString().split('vacancy')[1]
    return new UrlSegment(vacancyId, {})
  } catch (e) {
    log.warn({ where: 'desktop', category: 'try_catch', message: 'vac-page-matcher: getVacancyId failed', error: e })
    return new UrlSegment('', {})
  }
}

export const VacPageRouteMatcher = (segments: UrlSegment[]): UrlMatchResult | null => {
  const length = segments.length
  const companyRegEx = /company[0-9]+$/
  const vacancyRegEx = /vacancy[0-9]+$/

  // company825/vacancy8431322/apply/form
  if (length === 4 && segments[3].path.indexOf('form') !== -1) {
    return {
      consumed: [segments[0], segments[1]],
      posParams: {
        companySegmentsId: getCompanyId(segments),
        vacancySegmentsId: getVacancyId(segments)
      }
    }
  }

  // company825/vacancy8431322/apply
  if (length === 3 && segments[2].path.indexOf('thanks-for-apply') === -1 && segments[2].path.indexOf('apply') !== -1) {
    return {
      consumed: [segments[0], segments[1]],
      posParams: {
        companySegmentsId: getCompanyId(segments),
        vacancySegmentsId: getVacancyId(segments)
      }
    }
  }

  // company825/vacancy8431322/thanks-for-apply
  if (length === 3 && segments[2].path.indexOf('thanks-for-apply') !== -1) {
    return {
      consumed: [segments[0], segments[1]],
      posParams: {
        companySegmentsId: getCompanyId(segments),
        vacancySegmentsId: getVacancyId(segments)
      }
    }
  }

  // company825/vacancy8431322
  if (length === 2 && segments[0].path.match(companyRegEx) && segments[1].path.match(vacancyRegEx)) {
    return {
      consumed: segments,
      posParams: {
        companySegmentsId: getCompanyId(segments),
        vacancySegmentsId: getVacancyId(segments)
      }
    }
  }

  return null
}
