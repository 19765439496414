import { LocalStorage } from '@alliance/shared/storage'
import { DetectPlatformService } from '@alliance/shared/utils'
import { isDevMode } from '@angular/core'

export const sentryIsEnabled = (localStorage: LocalStorage, platform: DetectPlatformService): boolean => {
  if (isDevMode()) {
    return false
  }

  const isEnabled = platform.isBrowser && !localStorage.getItem('sentry_disabled')

  if (platform.isBrowser && !isEnabled) {
    console.info('Sentry disabled')
  }

  return isEnabled
}
