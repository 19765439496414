import { IconWhiteRefresh } from '@alliance/shared/icons'
import { ModalOverlayRef, SANTA_MODAL_REF } from '@alliance/shared/santa'
import { ChangeDetectionStrategy, Component, Inject } from '@angular/core'
import { Translations } from '../localization/translations'

@Component({
  selector: 'alliance-shared-confirm-reload-on-chunk-load-error',
  templateUrl: './confirm-reload-on-chunk-load-error.component.html',
  styleUrls: ['./confirm-reload-on-chunk-load-error.component.tw.css'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ConfirmReloadOnChunkLoadErrorComponent {
  public readonly IconWhiteRefresh = IconWhiteRefresh

  public constructor(@Inject(SANTA_MODAL_REF) private modalRef: ModalOverlayRef, public translations: Translations) {}

  public confirm(): void {
    this.modalRef.close(true)
  }
}
