import { RxStateService } from '@alliance/shared/models'
import { getNonNullableItems, retryWhenStrategy } from '@alliance/shared/utils'
import { Injectable } from '@angular/core'
import { Observable, of } from 'rxjs'
import { catchError, filter, map, retryWhen, shareReplay, take } from 'rxjs/operators'
import { GetScheduleByIdGQL, GetSchedulesDictionaryGQL, ScheduleDictionaryItemFragment } from './schedule-dictionary.generated'

@Injectable({
  providedIn: 'root'
})
export class ScheduleDictionaryService extends RxStateService<{
  scheduleDictionary: ScheduleDictionaryItemFragment[]
}> {
  public constructor(private getSchedulesDictionaryGQL: GetSchedulesDictionaryGQL, private getScheduleByIdGQL: GetScheduleByIdGQL) {
    super()

    this.initState({
      scheduleDictionary: this.getSchedules$().pipe(
        filter(list => !!list?.length),
        shareReplay(1)
      )
    })
  }

  public getScheduleById$(id: string): Observable<ScheduleDictionaryItemFragment | null> {
    return this.select('scheduleDictionary').pipe(
      take(1),
      map(list => list.find(item => item.id === id) || null)
    )
  }

  public fetchScheduleById$(id: string): Observable<ScheduleDictionaryItemFragment | null> {
    return this.getScheduleByIdGQL.fetch({ id }, { fetchPolicy: 'network-only' }).pipe(
      map(({ data }) => data?.schedule || null),
      retryWhen(retryWhenStrategy()),
      catchError(() => of(null))
    )
  }

  private getSchedules$(): Observable<ScheduleDictionaryItemFragment[]> {
    return this.getSchedulesDictionaryGQL.fetch().pipe(
      map(({ data }) => getNonNullableItems<ScheduleDictionaryItemFragment>(data?.schedules || [])),
      retryWhen(retryWhenStrategy()),
      catchError(() => of([]))
    )
  }
}
