/* tslint:disable */
/* eslint-disable */
export enum MessageType {
  Text = 'Text',
  Vacancy = 'Vacancy',
  Cv = 'Cv',
  Apply = 'Apply',
  File = 'File',
  Image = 'Image',
  System = 'System'
}
