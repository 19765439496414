import { ApplyItem } from '@alliance/employer/data-access'
import ResumeTypeEnum = ApplyItem.ResumeTypeEnum

export enum AppliesResumeTypeRouteEnum {
  Prof = 'prof',
  Empty = 'empty',
  Attach = 'attach',
  Selected = 'select'
}

export const ResumeTypeToAppliesRouterParamsMap = {
  [ResumeTypeEnum.AttachedFile]: AppliesResumeTypeRouteEnum.Attach,
  [ResumeTypeEnum.Notepad]: AppliesResumeTypeRouteEnum.Prof,
  [ResumeTypeEnum.Selected]: AppliesResumeTypeRouteEnum.Selected,
  [ResumeTypeEnum.NoCvApply]: AppliesResumeTypeRouteEnum.Empty
}
