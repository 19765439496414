/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core'
import { HttpClient, HttpResponse, HttpContext } from '@angular/common/http'
import { BaseService } from '../base-service'
import { ApplyApiDataAccessConfiguration } from '../apply-api-data-access-configuration'
import { StrictHttpResponse } from '../strict-http-response'
import { RequestBuilder } from '../request-builder'
import { Observable } from 'rxjs'
import { map, filter } from 'rxjs/operators'

@Injectable({
  providedIn: 'root'
})
export class ApplyResumeIdService extends BaseService {
  public constructor(config: ApplyApiDataAccessConfiguration, http: HttpClient) {
    super(config, http)
  }

  /**
   * Path part for operation resumeIdContactsPut
   */
  public static readonly ResumeIdContactsPutPath = '/{resumeId}/contacts'

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `resumeIdContactsPut()` instead.
   *
   * This method doesn't expect any request body.
   */
  resumeIdContactsPut$Response(params: { resumeId: number; context?: HttpContext }): Observable<StrictHttpResponse<void>> {
    const rb = new RequestBuilder(this.rootUrl, ApplyResumeIdService.ResumeIdContactsPutPath, 'put')
    if (params) {
      rb.path('resumeId', params.resumeId, {})
    }

    return this.http
      .request(
        rb.build({
          responseType: 'text',
          accept: '*/*',
          context: params?.context
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>
        })
      )
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `resumeIdContactsPut$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  resumeIdContactsPut(params: { resumeId: number; context?: HttpContext }): Observable<void> {
    return this.resumeIdContactsPut$Response(params).pipe(map((r: StrictHttpResponse<void>) => r.body as void))
  }
}
