/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http'
import { Observable } from 'rxjs'
import { filter, map } from 'rxjs/operators'
import { StrictHttpResponse } from '../../strict-http-response'
import { RequestBuilder } from '../../request-builder'

import { CvCreationFlow } from '../../models/cv-creation-flow'

export interface ChatWizardGetCvCreationFlow$Json$Params {}

export function chatWizardGetCvCreationFlow$Json(
  http: HttpClient,
  rootUrl: string,
  params?: ChatWizardGetCvCreationFlow$Json$Params,
  context?: HttpContext
): Observable<StrictHttpResponse<CvCreationFlow>> {
  const rb = new RequestBuilder(rootUrl, chatWizardGetCvCreationFlow$Json.PATH, 'get')
  if (params) {
  }

  return http.request(rb.build({ responseType: 'json', accept: 'text/json', context })).pipe(
    filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
    map((r: HttpResponse<any>) => {
      return r as StrictHttpResponse<CvCreationFlow>
    })
  )
}

chatWizardGetCvCreationFlow$Json.PATH = '/v1/chatWizard/cv/flow'
