export const ParseKeyWords = (value: string = ''): string =>
  value
    .replace(/[/()?;=+&#.'"]/gi, ' ')
    .replace(/\s\s+/gi, ' ')
    .trim()

export const GetKeywordToUrl = (keyword: string, defaultValue: string = ''): string => {
  const parsedKeyWords = ParseKeyWords(keyword)
    .replace(/\s/gi, '-')
    .toLowerCase()
  return parsedKeyWords?.length ? encodeURIComponent(parsedKeyWords) : defaultValue
}
