import { RxStateService } from '@alliance/shared/models'
import { Injectable } from '@angular/core'
import { filter, switchMap } from 'rxjs'
import { SignalrMessageService, StreamTypeMap } from '@alliance/socket/api'
import { MessageUpdateContextViewModel, MessageViewModel } from '@alliance/chat/data-access'
import { AuthService } from '@alliance/shared/auth/api'

@Injectable({ providedIn: 'root' })
export class ChatStreamService extends RxStateService<{
  messageStream: MessageViewModel | MessageUpdateContextViewModel
}> {
  public constructor(private authService: AuthService, private signalrMessageService: SignalrMessageService) {
    super()

    this.initState({
      messageStream: this.authService.token$.pipe(
        switchMap(() => this.signalrMessageService.getStreamByType(StreamTypeMap.chat).pipe(filter((msg): msg is MessageViewModel | MessageUpdateContextViewModel => !!msg)))
      )
    })
  }
}
