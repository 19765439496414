import { combineLatest, Observable, of, switchMap } from 'rxjs'
import { catchError, distinctUntilChanged, map } from 'rxjs/operators'
import { Injectable } from '@angular/core'
import { TranslationService } from '@alliance/shared/translation'
import { AuthService } from '@alliance/shared/auth/api'
import { log } from '@alliance/shared/logger'
import { RxStateService } from '@alliance/shared/models'
import { DetectPlatformService, RuavarsService } from '@alliance/shared/utils'
import { LocalStorage } from '@alliance/shared/storage'
import { format } from '@alliance/shared/datetime'
import { unLoggedApplyEmailKey } from '../constants/apply-constants'
import { ResumeShortListItemDTO, AttachInfo, ResumeService, AccountJobsearcherService } from '@alliance/jobseeker/data-access'

@Injectable({ providedIn: 'root' })
export class ResumesService extends RxStateService<{
  profResumeList: ResumeShortListItemDTO[]
  fullProfResume: ResumeShortListItemDTO[]
  attachResumeList: AttachInfo[]
  haveResume: boolean
  haveProfResume: boolean
  isJobSeeker: boolean
  allResumesList: Array<ResumeShortListItemDTO | AttachInfo>
  resumeArrayLength: number
  userEmail: string
  updateEmail: boolean
}> {
  public constructor(
    private resumeApiService: ResumeService,
    private translationService: TranslationService,
    private authService: AuthService,
    private ruavarsService: RuavarsService,
    private localStorage: LocalStorage,
    private platform: DetectPlatformService,
    private accountService: AccountJobsearcherService
  ) {
    super()

    this.initState({
      isJobSeeker: this.authService.token$.pipe(map(token => !!token && this.authService.isSeeker)),
      updateEmail: false,
      userEmail: combineLatest([this.select('isJobSeeker'), this.select('updateEmail')]).pipe(
        map(([isJobSeeker]) => (isJobSeeker ? this.authService.user : null)),
        map(user => user?.email || this.localStorage.getItem(unLoggedApplyEmailKey) || '')
      ),
      fullProfResume: this.getFullProfResume$(),
      profResumeList: this.select('fullProfResume').pipe(map(profList => profList.filter(cv => cv.hasEnoughInfoForApply))),
      attachResumeList: this.getAttachResumeList$(),
      haveProfResume: this.select('fullProfResume').pipe(map(list => list.length !== 0)),
      haveResume: combineLatest([this.select('attachResumeList'), this.select('profResumeList')]).pipe(
        map(([profResumes, attaches]) => !![...profResumes, ...attaches].length),
        distinctUntilChanged()
      ),
      allResumesList: combineLatest([this.select('profResumeList'), this.select('attachResumeList')]).pipe(map(([profResumes, attaches]) => [...profResumes, ...attaches])),
      resumeArrayLength: this.select('profResumeList').pipe(
        map(array => array?.length ?? 0),
        distinctUntilChanged()
      )
    })

    this.hold(this.select('fullProfResume'), fullProfResume => {
      this.ruavarsService.setInfo({
        hasResume: (fullProfResume ?? []).some(item => +(item?.level ?? 0) !== 6)
      })
    })
  }

  /* deprecated, use `state resumeArrayLength` */
  public getResumeArrayLength(): Observable<number> {
    if (this.platform.isServer) {
      return of(0)
    }
    return this.resumeApiService.resumeGetJobseekerResumeList().pipe(map(list => list.length))
  }

  public updateAttachAndProfResumeLists(): void {
    this.set(state => ({
      updateEmail: !state.updateEmail
    }))
    this.connect('fullProfResume', this.getFullProfResume$())
    this.connect('attachResumeList', this.getAttachResumeList$())
  }

  public getFullProfResume$(): Observable<ResumeShortListItemDTO[]> {
    if (this.platform.isServer) {
      return of([])
    }
    return this.select('isJobSeeker').pipe(
      switchMap(isJobSeeker =>
        isJobSeeker
          ? this.resumeApiService.resumeGetJobseekerResumeList().pipe(
              map(resumes =>
                resumes.map(cv => ({
                  ...cv,
                  name: cv.name || (this.translationService.currentLangIsUkrainian() ? 'Резюме без назви' : 'Резюме без названия')
                }))
              ),
              catchError(e => {
                log.warn({ where: 'jobseeker-feature-utils: ResumesService', category: 'api_call_failed', message: 'resumeGetJobseekerResumeList failed', error: e })
                return of([])
              })
            )
          : of([])
      )
    )
  }

  private getAttachResumeList$(): Observable<AttachInfo[]> {
    if (this.platform.isServer) {
      return of([])
    }

    return this.select('userEmail').pipe(
      switchMap(email =>
        email
          ? this.accountService.getAttachList(email).pipe(
              map(resumes =>
                resumes.map(resume => ({
                  ...resume,
                  isAttach: true,
                  name: resume.fileName,
                  date: resume.lastSentDate ? format(resume.lastSentDate, 'd.MM.yyyy') : null
                }))
              ),
              catchError(e => {
                log.warn({ where: 'jobseeker-feature-utils: ResumesService', category: 'api_call_failed', message: 'getAttachList failed', error: e })
                return of([])
              })
            )
          : of([])
      )
    )
  }
}
