const WIDTH = {
  320: '320px',
  700: '700px',
  1000: '1000px',
  1200: '1200px',
  1400: '1400px',
  1800: '1800px',
  half: '50%',
  full: '100%',
  '100vw': '100vw'
}

module.exports = { WIDTH }
