import { CommonModule } from '@angular/common'
import { NgModule } from '@angular/core'
import { RouterModule } from '@angular/router'
import { LinkWrappingComponent } from './components/link-wrapping/link-wrapping.component'
import { AccountLinkDirective } from './directives/account-link.directive'

@NgModule({
  declarations: [AccountLinkDirective, LinkWrappingComponent],
  imports: [CommonModule, RouterModule],
  exports: [AccountLinkDirective, LinkWrappingComponent]
})
export class SharedFeatureAccountLinkMapModule {}
