import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http'
import { Observable } from 'rxjs'
import { filter, map } from 'rxjs/operators'
import { StrictHttpResponse } from '../../strict-http-response'
import { RequestBuilder } from '../../request-builder'

export interface VacancyStatisticsSaveGet$Params {
  event_name?: string
  redir_token?: string
}

export function vacancyStatisticsSaveGet(http: HttpClient, rootUrl: string, params?: VacancyStatisticsSaveGet$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
  const rb = new RequestBuilder(rootUrl, vacancyStatisticsSaveGet.PATH, 'get')
  if (params) {
    rb.query('event_name', params.event_name, {})
    rb.query('redir_token', params.redir_token, {})
  }

  return http.request(rb.build({ responseType: 'text', accept: '*/*', context })).pipe(
    filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
    map((r: HttpResponse<any>) => {
      return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>
    })
  )
}

vacancyStatisticsSaveGet.PATH = '/vacancy-statistics/save'
