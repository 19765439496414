import { Injectable } from '@angular/core'
import { Observable } from 'rxjs'
import { map, share } from 'rxjs/operators'
import { differenceInDays } from '@alliance/shared/datetime'
import { RxStateService } from '@alliance/shared/models'
import {
  IconAnonymousPublication20,
  IconName,
  IconPackageServices20,
  IconPublicationsBusiness,
  IconPublicationsOptimum,
  IconPublicationsProfessional,
  IconPublicationBase
} from '@alliance/shared/icons'
import {
  Expirable,
  ExpiringServicePackage,
  ExpiringServicePackageFactoryService,
  ExpiringServicePackageNotification,
  ExpiringServicePackagePublicationType,
  NotificationServicePackage
} from '../../domain'

const SERVICE_PACKAGE_ICONS: Record<ExpiringServicePackagePublicationType, IconName> = {
  BUSINESS: IconPublicationsBusiness.name,
  FREE_BUSINESS: IconPublicationsBusiness.name,
  BUSINESS_SINGLE: IconPublicationsBusiness.name,
  OPTIMUM: IconPublicationsOptimum.name,
  OPTIMUM_SINGLE: IconPublicationsOptimum.name,
  PROFESSIONAL: IconPublicationsProfessional.name,
  PROFESSIONAL_SINGLE: IconPublicationsProfessional.name,
  ANONYMOUS: IconAnonymousPublication20.name,
  TEST: IconPublicationsOptimum.name,
  BASE: IconPublicationBase.name
}

const SERVICE_EXPIRY_DAYS_SPAN = 7

const isExpiring = ({ expiresAt }: ExpiringServicePackage): boolean => {
  const daysDiff = differenceInDays(new Date(expiresAt), new Date())

  return 0 <= daysDiff && daysDiff <= SERVICE_EXPIRY_DAYS_SPAN
}

const groupByExpiryDaysNumber = (servicePackages: ExpiringServicePackage[]): Map<number, ExpiringServicePackage[]> => {
  const servicePackageGroups = new Map<number, ExpiringServicePackage[]>([])

  for (const servicePackage of servicePackages) {
    const daysDiff = differenceInDays(new Date(servicePackage.expiresAt), new Date())

    if (servicePackageGroups.has(daysDiff)) {
      const group = servicePackageGroups.get(daysDiff) || []
      group.push(servicePackage)
    } else {
      servicePackageGroups.set(daysDiff, [servicePackage])
    }
  }

  return servicePackageGroups
}

const groupByServicePackageType = (servicePackages: ExpiringServicePackage[]): Map<ExpiringServicePackagePublicationType, ExpiringServicePackage[]> => {
  const groups = new Map<ExpiringServicePackagePublicationType, ExpiringServicePackage[]>([])

  for (const servicePackage of servicePackages) {
    const { publicationType } = servicePackage

    if (groups.has(publicationType)) {
      const group = groups.get(publicationType) || []
      group.push(servicePackage)
    } else {
      groups.set(publicationType, [servicePackage])
    }
  }

  return groups
}

const getNotificationServicePackages = (servicePackages: ExpiringServicePackage[]): NotificationServicePackage[] => {
  const notificationServicePackages: NotificationServicePackage[] = []

  const servicePackageGroups = groupByServicePackageType(servicePackages)

  for (const servicePackageGroup of servicePackageGroups.values()) {
    const [servicePackage] = servicePackageGroup

    if (!servicePackage) {
      continue
    }

    const { name, publicationType } = servicePackage
    const icon = SERVICE_PACKAGE_ICONS[publicationType]

    notificationServicePackages.push({
      name,
      icon,
      count: servicePackageGroup.length
    })
  }

  return notificationServicePackages
}

const getExpiringServicePackageNotification = (servicePackages: ExpiringServicePackage[]): ExpiringServicePackageNotification => {
  const servicePackageGroups = groupByExpiryDaysNumber(servicePackages)

  const expirations: Array<Expirable<NotificationServicePackage[]>> = []
  const publications = { count: servicePackages.reduce((total, servicePackage) => servicePackage.publications.count + total, 0) }

  for (const groupedServicePackages of servicePackageGroups.values()) {
    const servicePackage = groupedServicePackages[0]

    if (!servicePackage) {
      continue
    }

    const notificationServicePackages = getNotificationServicePackages(groupedServicePackages)
    expirations.push({ expiresAt: servicePackage.expiresAt, data: notificationServicePackages })
  }

  return {
    type: 'expiring-service',
    icon: IconPackageServices20.name,
    expirations,
    publications
  }
}

const splitByExpiry = (notification: ExpiringServicePackageNotification): ExpiringServicePackageNotification[] => {
  const { expirations, publications, icon, type } = notification

  const notifications: ExpiringServicePackageNotification[] = []

  for (const expiration of expirations) {
    notifications.push({
      expirations: [expiration],
      type,
      icon,
      publications
    })
  }

  return notifications
}

@Injectable({
  providedIn: 'root'
})
export class ExpiringServicePackageNotificationFacadeService extends RxStateService<{
  notification: ExpiringServicePackageNotification | null
  hasAvailablePublications: boolean
  splitByExpiryNotifications: ExpiringServicePackageNotification[]
}> {
  public constructor(private readonly expiringServicePackageFactory: ExpiringServicePackageFactoryService) {
    super()
    this.initializeState()
  }

  public getNotification(): Observable<ExpiringServicePackageNotification | null> {
    return this.select('notification')
  }

  public getSplitByExpiryNotifications(): Observable<ExpiringServicePackageNotification[]> {
    return this.select('splitByExpiryNotifications')
  }

  public hasAvailablePublications(): Observable<boolean> {
    return this.select('hasAvailablePublications')
  }

  private initializeState(): void {
    const notification$ = this.getExpiringServicePackageNotification().pipe(share())
    this.initState({
      notification: notification$,
      hasAvailablePublications: this.expiringServicePackageFactory.hasAvailablePublications(),
      splitByExpiryNotifications: notification$.pipe(map(notification => (notification ? splitByExpiry(notification) : [])))
    })
  }

  private getExpiringServicePackageNotification(): Observable<ExpiringServicePackageNotification | null> {
    return this.getExpiringServicePackages().pipe(map(servicePackages => (servicePackages.length ? getExpiringServicePackageNotification(servicePackages) : null)))
  }

  private getExpiringServicePackages(): Observable<ExpiringServicePackage[]> {
    return this.expiringServicePackageFactory.getExpiringEmployerServicePackages().pipe(map(packages => packages.filter(isExpiring)))
  }
}
