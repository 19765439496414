import { Injector, TemplateRef, Type, ViewContainerRef, InjectionToken } from '@angular/core'
import { ComponentPortal } from '@angular/cdk/portal'
import { ModalOverlayRef } from '../services/modal'

export interface IBaseModal {
  hasAttachedModal: boolean
  openByComponent<T, K>(content: Type<T>, data?: K): ModalOverlayRef
  openByComponentWithContextInjector<T, K>(contentOrPortal: Type<T> | ComponentPortal<T>, data?: K, contextInjector?: Injector): ModalOverlayRef
  openByTemplate<K>(content: TemplateRef<K>, context?: K, viewContainerRef?: ViewContainerRef | null): ModalOverlayRef
  close(): void
}

export const BASE_MODAL = new InjectionToken<IBaseModal>('modal_service_injection')
