const { BORDER_RADIUS } = require('./border-radius')
const { COLORS, COLORS_RGB } = require('./colors')
const { GAP } = require('./gap')
const { FONT_SIZE, FONT_FAMILY } = require('./fonts')
const { LINE_HEIGHT } = require('./line-height')
const { OPACITY } = require('./opacity')
const { SCREENS } = require('./screens')
const { SPACING } = require('./spacing')
const { WIDTH } = require('./width')

const THEME = {
  borderRadius: BORDER_RADIUS,
  colors: COLORS,
  colorsRgb: COLORS_RGB,
  gap: GAP,
  fontFamily: FONT_FAMILY,
  fontSize: FONT_SIZE,
  lineHeight: LINE_HEIGHT,
  maxWidth: WIDTH,
  opacity: OPACITY,
  screens: SCREENS,
  spacing: SPACING
}

module.exports = { THEME }
