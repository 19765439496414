import { CommonModule } from '@angular/common'
import { NgModule } from '@angular/core'
import { PolymorpheusOutletDirective } from './directives'

@NgModule({
  imports: [CommonModule],
  declarations: [PolymorpheusOutletDirective],
  exports: [PolymorpheusOutletDirective]
})
export class SharedPolymorpheusModule {}
