/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { Observable } from 'rxjs'
import { map } from 'rxjs/operators'

import { BaseService } from '../base-service'
import { NotificationDataAccessConfiguration } from '../notification-data-access-configuration'
import { StrictHttpResponse } from '../strict-http-response'

import { statusesGet$Json } from '../fn/statuses/statuses-get-json'
import { StatusesGet$Json$Params } from '../fn/statuses/statuses-get-json'
import { statusesGet$Plain } from '../fn/statuses/statuses-get-plain'
import { StatusesGet$Plain$Params } from '../fn/statuses/statuses-get-plain'
import { statusesGetUnreadCount$Json } from '../fn/statuses/statuses-get-unread-count-json'
import { StatusesGetUnreadCount$Json$Params } from '../fn/statuses/statuses-get-unread-count-json'
import { statusesGetUnreadCount$Plain } from '../fn/statuses/statuses-get-unread-count-plain'
import { StatusesGetUnreadCount$Plain$Params } from '../fn/statuses/statuses-get-unread-count-plain'
import { StatusViewModel } from '../models/status-view-model'

/**
 * Provides functionality to operate with blacklist: fetch banned user's related, add/remove to blacklist.
 */
@Injectable({ providedIn: 'root' })
export class StatusesService extends BaseService {
  constructor(config: NotificationDataAccessConfiguration, http: HttpClient) {
    super(config, http)
  }

  /** Path part for operation `statusesGet()` */
  static readonly StatusesGetPath = '/v1/conversations/{conversationId}/statuses'

  /**
   * Fetches status for user by corresponding conversation id.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `statusesGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  statusesGet$Plain$Response(params: StatusesGet$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<StatusViewModel>> {
    return statusesGet$Plain(this.http, this.rootUrl, params, context)
  }

  /**
   * Fetches status for user by corresponding conversation id.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `statusesGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  statusesGet$Plain(params: StatusesGet$Plain$Params, context?: HttpContext): Observable<StatusViewModel> {
    return this.statusesGet$Plain$Response(params, context).pipe(map((r: StrictHttpResponse<StatusViewModel>): StatusViewModel => r.body))
  }

  /**
   * Fetches status for user by corresponding conversation id.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `statusesGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  statusesGet$Json$Response(params: StatusesGet$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<StatusViewModel>> {
    return statusesGet$Json(this.http, this.rootUrl, params, context)
  }

  /**
   * Fetches status for user by corresponding conversation id.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `statusesGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  statusesGet$Json(params: StatusesGet$Json$Params, context?: HttpContext): Observable<StatusViewModel> {
    return this.statusesGet$Json$Response(params, context).pipe(map((r: StrictHttpResponse<StatusViewModel>): StatusViewModel => r.body))
  }

  /** Path part for operation `statusesGetUnreadCount()` */
  static readonly StatusesGetUnreadCountPath = '/v1/statuses/unread-count'

  /**
   * Fetches all not read messages for corresponding user.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `statusesGetUnreadCount$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  statusesGetUnreadCount$Plain$Response(params?: StatusesGetUnreadCount$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<number>> {
    return statusesGetUnreadCount$Plain(this.http, this.rootUrl, params, context)
  }

  /**
   * Fetches all not read messages for corresponding user.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `statusesGetUnreadCount$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  statusesGetUnreadCount$Plain(params?: StatusesGetUnreadCount$Plain$Params, context?: HttpContext): Observable<number> {
    return this.statusesGetUnreadCount$Plain$Response(params, context).pipe(map((r: StrictHttpResponse<number>): number => r.body))
  }

  /**
   * Fetches all not read messages for corresponding user.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `statusesGetUnreadCount$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  statusesGetUnreadCount$Json$Response(params?: StatusesGetUnreadCount$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<number>> {
    return statusesGetUnreadCount$Json(this.http, this.rootUrl, params, context)
  }

  /**
   * Fetches all not read messages for corresponding user.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `statusesGetUnreadCount$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  statusesGetUnreadCount$Json(params?: StatusesGetUnreadCount$Json$Params, context?: HttpContext): Observable<number> {
    return this.statusesGetUnreadCount$Json$Response(params, context).pipe(map((r: StrictHttpResponse<number>): number => r.body))
  }
}
