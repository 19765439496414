import { DetectPlatformService, isJwtTokenExpired } from '@alliance/shared/utils'

import { Inject, Injectable } from '@angular/core'
import { LOCATION } from '@ng-web-apis/common'
import { Observable, of } from 'rxjs'
import { map } from 'rxjs/operators'

import { AUTOLOGIN_QUERY_NAME } from './constants'
import { AuthService } from './auth.service'

@Injectable({ providedIn: 'root' })
export class AppInitializerService {
  public constructor(@Inject(LOCATION) private readonly location: Location, private authService: AuthService, private platform: DetectPlatformService) {}

  public initializeApp(): Observable<boolean> {
    if (this.platform.isServer) {
      return of(true)
    }

    const token = this.authService.token
    const hasToken = !!token
    const tokenExpired = !!token && isJwtTokenExpired(token)

    const urlParams = new URLSearchParams(this.location.search)
    const hasAutologinParams = urlParams.has(AUTOLOGIN_QUERY_NAME)

    if (hasAutologinParams && (!hasToken || tokenExpired)) {
      return this.authService.autoLogin(urlParams.get(AUTOLOGIN_QUERY_NAME) || '').pipe(map(() => true))
    }

    if (hasToken && tokenExpired) {
      return this.authService.refresh().pipe(map(() => true))
    }

    return of(true)
  }
}
