import { AuthService } from '@alliance/shared/auth/api'
import { UserSideEnum } from '@alliance/shared/utils'
import { Injectable } from '@angular/core'
import { ActivatedRouteSnapshot, CanActivate, Params, Router, RouterStateSnapshot } from '@angular/router'

@Injectable()
export class FeedbackByUserGuard implements CanActivate {
  public constructor(private authService: AuthService, private router: Router) {}

  public canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | Promise<boolean> {
    const lastPath = ((state?.url ?? '').split('?')[0] ?? '').split('/').reverse()[0]
    const userSideInQueryParams = route.queryParamMap.get('userSide')
    const userSideByQueryParams: UserSideEnum = userSideInQueryParams
      ? Object.values<UserSideEnum>(UserSideEnum).find(value => value !== UserSideEnum.all && value.toLowerCase().includes(userSideInQueryParams.toLowerCase())) ?? UserSideEnum.jobseeker
      : UserSideEnum.jobseeker

    if (!lastPath || lastPath === 'feedback') {
      if (this.authService.token) {
        return this.redirectByLoggedRole(route.queryParams)
      }

      return this.redirectByUserSide(userSideByQueryParams, route.queryParams)
    }

    if (this.authService.token && this.isWrongPathByLoggedRole(lastPath)) {
      return this.redirectByLoggedRole(route.queryParams)
    }

    if (!this.authService.token && this.isWrongPathByUserSide(lastPath, userSideByQueryParams)) {
      return this.redirectByUserSide(userSideByQueryParams, route.queryParams)
    }

    return true
  }

  private isWrongPathByLoggedRole(path: string): boolean {
    return (this.authService.isEmployer && path !== 'employer') || (this.authService.isSeeker && path !== 'seeker')
  }

  private isWrongPathByUserSide(path: string, userSide: UserSideEnum): boolean {
    const PathByUserSideMap: Record<UserSideEnum, 'employer' | 'seeker'> = {
      [UserSideEnum.employer]: 'employer',
      [UserSideEnum.jobseeker]: 'seeker',
      [UserSideEnum.all]: 'seeker'
    }

    return path !== PathByUserSideMap[userSide]
  }

  private redirectByLoggedRole(withQueryParams: Params): Promise<boolean> {
    return this.router.navigate(['/feedback', this.authService.isEmployer ? 'employer' : 'seeker'], {
      queryParams: withQueryParams
    })
  }

  private redirectByUserSide(userSide: UserSideEnum, withQueryParams: Params): Promise<boolean> {
    return this.router.navigate(['/feedback', userSide === UserSideEnum.employer ? 'employer' : 'seeker'], {
      queryParams: withQueryParams
    })
  }
}
