import { Inject, Injectable } from '@angular/core'
import { SessionStorage } from '@alliance/shared/storage'
import { DOCUMENT } from '@angular/common'
import { DetectPlatformService, ScrollHelperService } from '@alliance/shared/utils'

@Injectable({
  providedIn: 'root'
})
export class RestoreScrollPositionService {
  public constructor(@Inject(DOCUMENT) private document: Document, private sessionStorage: SessionStorage, public platform: DetectPlatformService, private scrollService: ScrollHelperService) {}

  public saveScrollPosition(scrollPositionKey: string): void {
    if (this.platform.isBrowser) {
      this.sessionStorage.setItem(scrollPositionKey, `${this.document.defaultView?.pageYOffset ?? 0}`)
    }
  }

  public resetScrollPosition(scrollPositionKey: string): void {
    if (this.platform.isBrowser) {
      this.sessionStorage.setItem(scrollPositionKey, '0')
    }
  }

  public restoreScrollPosition(scrollPositionKey: string): void {
    if (this.platform.isBrowser) {
      if (!this.sessionStorage.getItem(scrollPositionKey)) {
        this.scrollService.scrollTo({ topPosition: 0 })
      }
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      if (typeof +this.sessionStorage.getItem(scrollPositionKey) === 'number') {
        this.scrollService.scrollTo({
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          topPosition: +this.sessionStorage.getItem(scrollPositionKey),
          behavior: 'smooth'
        })
      }
    }
  }
}
