import { HttpClient, HttpContext } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { Observable } from 'rxjs'
import { map } from 'rxjs/operators'

import { BaseService } from '../base-service'
import { VacancyApiDataAccessConfiguration } from '../vacancy-api-data-access-configuration'
import { StrictHttpResponse } from '../strict-http-response'

import { featureAccessGet$Json } from '../fn/feature-access/feature-access-get-json'
import { FeatureAccessGet$Json$Params } from '../fn/feature-access/feature-access-get-json'
import { featureAccessGet$Plain } from '../fn/feature-access/feature-access-get-plain'
import { FeatureAccessGet$Plain$Params } from '../fn/feature-access/feature-access-get-plain'

@Injectable({ providedIn: 'root' })
export class VacancyApiFeatureAccessService extends BaseService {
  constructor(config: VacancyApiDataAccessConfiguration, http: HttpClient) {
    super(config, http)
  }

  /** Path part for operation `featureAccessGet()` */
  static readonly FeatureAccessGetPath = '/feature-access'

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `featureAccessGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  featureAccessGet$Plain$Response(params?: FeatureAccessGet$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<boolean>> {
    return featureAccessGet$Plain(this.http, this.rootUrl, params, context)
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `featureAccessGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  featureAccessGet$Plain(params?: FeatureAccessGet$Plain$Params, context?: HttpContext): Observable<boolean> {
    return this.featureAccessGet$Plain$Response(params, context).pipe(map((r: StrictHttpResponse<boolean>): boolean => r.body))
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `featureAccessGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  featureAccessGet$Json$Response(params?: FeatureAccessGet$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<boolean>> {
    return featureAccessGet$Json(this.http, this.rootUrl, params, context)
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `featureAccessGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  featureAccessGet$Json(params?: FeatureAccessGet$Json$Params, context?: HttpContext): Observable<boolean> {
    return this.featureAccessGet$Json$Response(params, context).pipe(map((r: StrictHttpResponse<boolean>): boolean => r.body))
  }
}
