import { SUPPORTED_METHODS } from './models'
import { normalize } from './normalize'
import { stringify } from './stringify'

const STYLING_MAP: Record<SUPPORTED_METHODS, { label: string; styling: string }> = {
  error: {
    label: '🚨 %cSHARED_LOGGER ERROR:',
    styling: `color: red; font-weight: bold`
  },
  log: {
    label: '📢 %cSHARED_LOGGER LOG:',
    styling: `color: #68bbe3; font-weight: bold`
  },
  warn: {
    label: '💡 %cSHARED_LOGGER WARN:',
    styling: `color: orange; font-weight: bold`
  }
}

const contentWithWhere = (value: object): value is { where: string } => 'where' in value
const contentWithMessage = (value: object): value is { message: string } => 'message' in value

export const prepareForConsoleTable = (key: string, value: unknown): object => {
  if (Array.isArray(value)) {
    return {
      [key]: stringify(value),
      ...value.reduce<object>(
        (acc, item, index) => ({
          ...acc,
          ...prepareForConsoleTable(`${key}_${index}`, item)
        }),
        {}
      )
    }
  }
  if (typeof value === 'object' && value !== null) {
    return {
      [key]: stringify(value),
      ...Object.entries(value).reduce<object>(
        (acc, [property, item]) => ({
          ...acc,
          ...prepareForConsoleTable(`${key}_${property}`, item)
        }),
        {}
      )
    }
  }

  return { [key]: value }
}

export const prettify = (method: SUPPORTED_METHODS, meta: object, content: object): void => {
  const where = contentWithWhere(content) ? `where: ${content.where}` : null
  const message = contentWithMessage(content) ? `message: ${content.message}` : null
  const label = [where, message].filter(Boolean).join(' --- ')
  const table = Object.entries({ ...normalize(content), ...meta }).reduce<object>((acc, [key, value]) => ({ ...acc, ...prepareForConsoleTable(key, value) }), {})

  console.groupCollapsed(`${STYLING_MAP[method].label} ${label}`, STYLING_MAP[method].styling)
  console.table(table)
  console.log('-----')
  console.log('%cSOURCE OBJECT', 'color: #68bbe3; font-weight: bold')
  console.dir(content)
  console.log('-----')
  console.groupEnd()
}
