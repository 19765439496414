import { Environment } from '@alliance/shared/environment'
import { ModuleWithProviders, NgModule } from '@angular/core'
import { HttpClientModule } from '@angular/common/http'
import { SharedAuthDataAccessConfiguration, SharedAuthDataAccessConfigurationInterface } from './shared-auth-data-access-configuration'

import { AuthService } from './services/auth.service'

/**
 * Provider for all SharedAuthDataAccess services, plus SharedAuthDataAccessConfiguration
 */
@NgModule({
  imports: [HttpClientModule],
  exports: [HttpClientModule],
  declarations: [],
  providers: [
    {
      provide: SharedAuthDataAccessConfiguration,
      useFactory: (environment: Environment): SharedAuthDataAccessConfigurationInterface => ({
        rootUrl: environment.authApi
      }),
      deps: [Environment]
    },
    AuthService
  ]
})
export class SharedAuthDataAccessCustomModule {
  public static forRoot(customParams?: SharedAuthDataAccessConfigurationInterface): ModuleWithProviders<SharedAuthDataAccessCustomModule> {
    return {
      ngModule: SharedAuthDataAccessCustomModule,
      providers: [
        customParams && customParams.rootUrl
          ? {
              provide: SharedAuthDataAccessConfiguration,
              useValue: { rootUrl: customParams.rootUrl }
            }
          : []
      ]
    }
  }
}
