import { logFactory } from '@alliance/shared/logger'

type JsonSafeType = null | boolean | number | string | object | unknown[] // FIXME: here we are saying that all this types are fine, but at the very first line we are doing if (typeof value !== 'string') retunr fallback, which makes me believe something is wrong here

/**
 * Safely parse json string
 * @param value value to parse
 * @param fallbackValue fallback value if something went wrong
 * @param message whenever something went wrong, in logs you will see "safeJsonParse failed" - but why and where? Your job is to put some hint into message that will help to find the place.
 * @returns parsed value or fallback value
 */
export const safeJsonParse = <T extends JsonSafeType>(value: unknown, fallbackValue: T, message?: string): T => {
  const log = logFactory({ where: `safeJsonParse: ${message}` })

  if (typeof value !== 'string' || !value?.trim()) {
    if (message) {
      log.warn({ message: 'parsed value - empty or non string', category: 'unexpected_value', value, fallback: fallbackValue })
    }
    return fallbackValue
  }

  try {
    // eslint-disable-next-line no-restricted-properties
    return JSON.parse(value) as T
  } catch (e) {
    if (message) {
      log.warn({ message: 'parsed value - non json', category: 'try_catch', value, fallback: fallbackValue })
    }
    return fallbackValue
  }
}
