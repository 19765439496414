import { Environment } from '@alliance/shared/environment'
import { log } from '@alliance/shared/logger'
import { RxStateComponent } from '@alliance/shared/models'
import { CookieStorage } from '@alliance/shared/storage'
import { DetectPlatformService, LoggingToBigQueryService } from '@alliance/shared/utils'
import { ChangeDetectionStrategy, Component, Inject } from '@angular/core'
import { DOCUMENT } from '@angular/common'
import { NAVIGATOR } from '@ng-web-apis/common'
import { HttpClient } from '@angular/common/http'
import { of, take } from 'rxjs'
import { catchError } from 'rxjs/operators'

interface GeoLocationData {
  countryId: number
  countryName: string
  countryShortName: string
}
@Component({
  selector: 'app-eng-widget',
  template: '<div class="santa-hidden" id="google_translate_element"></div>',
  styleUrls: [],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ShellEngWidgetComponent extends RxStateComponent<object> {
  private browserLanguage = ['en-US', 'en']
  private usaId = 228

  public constructor(
    @Inject(DOCUMENT) private document: Document,
    @Inject(NAVIGATOR) public readonly navigator: Navigator,
    private cookieStorage: CookieStorage,
    private http: HttpClient,
    private platform: DetectPlatformService,
    private env: Environment,
    private bqService: LoggingToBigQueryService
  ) {
    super()

    if (this.platform.isBrowser && this.browserLanguage.includes(this.navigator.language)) {
      this.hold(
        this.http.get<GeoLocationData>(`${this.env.usersApi}/UserCountry/`).pipe(
          take(1),
          catchError(() => of(null))
        ),
        data => {
          if (data && data?.countryId === this.usaId) {
            this.cookieStorage.setItem('googtrans', '/uk/en', {
              storeUnencoded: true
            })
            this.addTranslateScript()
            this.addCallbackTranslateScript()
            this.setBodyStyle()
            this.pushToDataLayer()
          }
        }
      )
    }
  }

  public pushToDataLayer(): void {
    this.bqService.pushToDataLayer({
      event: 'autoEventGBQ',
      eventCategory: 'load_en_translate_site',
      eventAction: null,
      eventLabel: null
    })
  }

  public addTranslateScript(): void {
    try {
      const translateScript = this.document.createElement('script')
      translateScript.async = true
      translateScript.defer = true
      translateScript.src = `https://translate.google.com/translate_a/element.js?cb=googleTranslateElementInit`
      this.document.head.insertBefore(translateScript, this.document.head.firstChild)
    } catch (e) {
      log.warn({ where: 'desktop', category: 'try_catch', message: 'addTranslateScript failed', error: e })
    }
  }

  public addCallbackTranslateScript(): void {
    try {
      const callbackScript = this.document.createElement('script')
      callbackScript.type = 'text/javascript'
      callbackScript.textContent = `
      function googleTranslateElementInit() {
        new google.translate.TranslateElement(
          {pageLanguage: 'uk', defaultLanguage: 'en', layout: google.translate.TranslateElement.InlineLayout.SIMPLE},
          'google_translate_element'
        );
      }
    `
      this.document.head.insertBefore(callbackScript, this.document.head.firstChild)
    } catch (e) {
      log.warn({ where: 'desktop', category: 'try_catch', message: 'addCallbackTranslateScript failed', error: e })
    }
  }

  public setBodyStyle(): void {
    this.document.body.classList.add('santa-top-0')
  }
}
