import { RxStateService } from '@alliance/shared/models'
import { Inject, Injectable, Optional } from '@angular/core'
import { BreakpointObserver, BreakpointState } from '@angular/cdk/layout'
import { Observable, of } from 'rxjs'
import { distinctUntilChanged, map } from 'rxjs/operators'
import { REQUEST } from '@nguniversal/express-engine/tokens'
import { Request } from 'express'
import { DetectPlatformService } from './detect-platform.service'

@Injectable({
  providedIn: 'root'
})
export class MediaService extends RxStateService<{
  isMobileScreen: boolean
}> {
  private readonly mobileScreenWidth = 759

  public constructor(private breakpointObserver: BreakpointObserver, private platform: DetectPlatformService, @Optional() @Inject(REQUEST) private request: Request) {
    super()

    this.initState({
      isMobileScreen: this.platform.isServer
        ? of(this.request?.headers?.['cf-device-type'] === 'mobile' || false) // https://support.cloudflare.com/hc/en-us/articles/229373388-Understand-Cache-by-Device-Type-Enterprise-plans-only-
        : this.breakpointObserver.observe(`(max-width: ${this.mobileScreenWidth}px)`).pipe(map((val: BreakpointState) => val.matches))
    })
  }

  public isMobileScreen(): boolean {
    return this.get().isMobileScreen
  }

  public getScreen$(screenValue: string): Observable<boolean> {
    return this.breakpointObserver.observe(`(max-width: ${screenValue})`).pipe(
      map((val: BreakpointState) => val.matches),
      distinctUntilChanged()
    )
  }
}
