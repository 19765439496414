export const ERROR_MESSAGES_RU = {
  emailIsAlreadyTaken: 'Этот email уже зарегистрирован',
  invalidDate: 'Неправильно указана дата',
  invalidFieldEmail: 'Неверный формат адреса',
  invalidFieldLastname: 'Неправильно заполнено поле Фамилия',
  invalidFieldFirstname: 'Неправильно заполнено поле Имя',
  invalidFieldPhone: 'Неверный формат номера телефона',
  invalidLogin: 'Вы ввели неверный пароль или логин',
  invalidEmailOrPhone: 'Email или номер введен в неправильном формате',
  requiredField: 'Поле обязательное для заполнения',
  invalidField: 'Неправильно заполнено поле',
  maxLength50: 'Максимальное количество - 50 символов',
  serverError: 'Произошла ошибка, попробуйте еще раз или обратитесь в службу поддержки',
  fileHasIncorrectType: 'Файл имеет недопустимый формат',
  fileWeightsTooMuch: 'Файл слишком большой',
  incorrect: 'Неверный код. Попробуйте запросить его повторно'
}

export const ERROR_MESSAGES_UK = {
  emailIsAlreadyTaken: 'Цей email вже зареєстрований',
  invalidDate: 'Неправильно вказана дата',
  invalidFieldEmail: 'Помилковий формат адреси',
  invalidFieldLastname: 'Неправильно заповнене поле Прізвище',
  invalidFieldFirstname: `Неправильно заповнене поле Ім'я`,
  invalidFieldPhone: 'Невірний формат номера телефону',
  invalidLogin: 'Ви ввели невірний пароль або логін',
  invalidEmailOrPhone: 'Email або номер введені в невірному форматі',
  requiredField: `Поле обов'язкове для заповнення`,
  invalidField: 'Неправильно заповнене поле',
  maxLength50: 'Максимальна кількість - 50 символів',
  serverError: 'Сталася помилка, спробуйте ще раз або зверніться у службу підтримки',
  fileHasIncorrectType: 'Файл має неприпустимий формат',
  fileWeightsTooMuch: 'Файл занадто великий',
  incorrect: 'Невірний код. Спробуйте запитати його повторно'
}
