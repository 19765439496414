import { CoreSeoModule, PlatformHosts } from '@alliance/core/seo'
import { Compiler, Injector, NgModule } from '@angular/core'
import { Location } from '@angular/common'
import { HttpClientModule } from '@angular/common/http'
import { ReactiveFormsModule } from '@angular/forms'
import { BrowserModule } from '@angular/platform-browser'
import { BrowserAnimationsModule } from '@angular/platform-browser/animations'
import { ChildrenOutletContexts, Router, RouterModule, UrlSerializer } from '@angular/router'
import { NgxMaskModule } from 'ngx-mask'

import { ChatApiModule } from '@alliance/chat/api'

import { EmployerApiModule } from '@alliance/employer/api'
import { EmployerGqlDomainModule } from '@alliance/employer/gql-domain'

import { JobseekerApiModule } from '@alliance/jobseeker/api'
import { JobseekerDomainGqlModule } from '@alliance/jobseeker/domain-gql'

import { NotificationApiModule } from '@alliance/notification/api'
import { NotificationShellAlertModule } from '@alliance/notification/shell-alert'

import { SocketApiModule } from '@alliance/socket/api'

import { SharedFooterModule } from '@alliance/shared/footer'

import { SharedAuthApiModule } from '@alliance/shared/auth/api'
import { SharedDomainGqlModule } from '@alliance/shared/domain-gql'
import { Environment } from '@alliance/shared/environment'
import { SharedIconsModule } from '@alliance/shared/icons'
import { SharedSantaModule } from '@alliance/shared/santa'
import { CustomLanguageRouter, provideDocumentLangAttribute, provideSavedLanguageStorage, SharedTranslationModule, TranslationService } from '@alliance/shared/translation'
import { SharedDatetimeModule } from '@alliance/shared/datetime'
import { SharedSSRTransferStateModule } from '@alliance/shared/ssr/transfer-state'
import { SharedGoogleAnalyticModule } from '@alliance/shared-google-analytics'
import { UsersOpenApiModule } from '@alliance/shared/users/domain'
import { IsNewRobotaDomainService } from '@alliance/shared/utils'
import { SharedEventPluginsModule } from '@alliance/shared/event-plugins'

import { environment } from '../environments/environment'
import { AppComponent } from './app.component'
import { AppRoutingModule, routes, routesDefaultUa } from './app-routing.module'
import { APP_TRANSLOCO_CONFIG } from './configs/transloco-config'

import { GeneralGuard } from './guards/general.guard'
import { EmployerGuard } from './guards/employer.guard'
import { ManagerGuard } from './guards/manager.guard'
import { EmployerDashboardGuard } from './guards/employer-dashboard.guard'
import { TrackPageGuard } from './guards/track-page.guard'
import { CenterTurbotyRedirectGuard } from './guards/center-turboty-redirect.guard'
import { FeedbackByUserGuard } from './guards/feedback-by-user.guard'
import { OnlySeekerGuard } from './guards/only-seeker.guard'

import { ShellHeaderComponent } from './lazy-modules/header/shell/shell.component'
import { ShellMobileNavigationBarComponent } from './lazy-modules/mobile-navigation-bar/shell/shell.component'
import { ShellFastGoogleAuthComponent } from './lazy-modules/fast-google-auth/shell/shell.component'
import { ShellEngWidgetComponent } from './lazy-modules/eng-widget/shell/shell.component'
import { SharedDictionaryModule } from '@alliance/shared/dictionary'

const guards = [CenterTurbotyRedirectGuard, EmployerDashboardGuard, EmployerGuard, GeneralGuard, ManagerGuard, TrackPageGuard, FeedbackByUserGuard, OnlySeekerGuard]

@NgModule({
  declarations: [
    AppComponent,
    // lazy loaded features
    ShellHeaderComponent,
    ShellMobileNavigationBarComponent,
    ShellFastGoogleAuthComponent,
    ShellEngWidgetComponent
  ],
  imports: [
    // angular modules
    BrowserAnimationsModule,
    BrowserModule.withServerTransition({ appId: 'app-desktop' }),
    HttpClientModule,
    ReactiveFormsModule,
    NgxMaskModule.forRoot(),
    RouterModule,

    AppRoutingModule,

    // employer modules
    EmployerApiModule,
    EmployerGqlDomainModule,

    // jobseeker modules
    JobseekerApiModule,
    JobseekerDomainGqlModule,

    // other domains modules
    ChatApiModule,

    // users api modules
    UsersOpenApiModule,

    NotificationApiModule,
    NotificationShellAlertModule, // lazy & adaptive wrapper

    CoreSeoModule.forRoot(PlatformHosts.desktop),
    SocketApiModule,

    // shared modules
    SharedIconsModule,
    SharedSantaModule,
    SharedTranslationModule.forRoot(APP_TRANSLOCO_CONFIG, [provideDocumentLangAttribute(), provideSavedLanguageStorage()]),
    SharedDatetimeModule.forRoot(),
    SharedFooterModule,
    SharedAuthApiModule.forRoot({ logoutUnAuthorizedUsers: true, retryFailedGetRequests: true }),
    SharedSSRTransferStateModule,
    SharedGoogleAnalyticModule,
    SharedDomainGqlModule,
    SharedEventPluginsModule,
    SharedDictionaryModule
  ],
  providers: [
    { provide: Environment, useValue: environment },
    {
      provide: Router,
      useFactory: (
        urlSerializer: UrlSerializer,
        rootContexts: ChildrenOutletContexts,
        location: Location,
        injector: Injector,
        compiler: Compiler,
        translationService: TranslationService,
        isNewRobotaDomainService: IsNewRobotaDomainService
      ): CustomLanguageRouter =>
        new CustomLanguageRouter(
          AppComponent,
          urlSerializer,
          rootContexts,
          location,
          injector,
          compiler,
          isNewRobotaDomainService.isNewRobotaDomain() ? routesDefaultUa : routes,
          translationService,
          isNewRobotaDomainService
        ),
      deps: [UrlSerializer, ChildrenOutletContexts, Location, Injector, Compiler, TranslationService, IsNewRobotaDomainService]
    },
    ...guards
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
