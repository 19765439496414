import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { WidgetRenderService } from './widget/widget-render.service'
import { ChatShellDesktopAppRoutingModule } from './chat-shell-desktop-app-routing.module'

@NgModule({
  imports: [CommonModule, ChatShellDesktopAppRoutingModule],
  exports: [],
  providers: [WidgetRenderService]
})
export class ChatShellDesktopAppModule {
  public constructor(private widgetRenderService: WidgetRenderService) {
    this.widgetRenderService.initChat()
    this.widgetRenderService.renderNotification()
  }
}
