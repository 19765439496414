import { Injectable, OnDestroy } from '@angular/core'
import { Meta } from '@angular/platform-browser'

export interface OgParams {
  locale?: string
  type?: string
  image?: string
  title?: string
  description?: string
  url?: string
  site_name?: string
  width?: string
  height?: string
  twitterSite?: string
  twitterTitle?: string
  twitterDescription?: string
}

export enum OgParamsEnum {
  locale = 'og:locale',
  image = 'og:image',
  title = 'og:title',
  description = 'og:description',
  type = 'og:type',
  siteName = 'og:site_name',
  url = 'og:url',
  width = 'og:image:width',
  height = 'og:image:height',
  twitterCard = 'twitter:card',
  twitterImage = 'twitter:image',
  twitterSite = 'twitter:site',
  twitterTitle = 'twitter:title',
  twitterDescription = 'twitter:description'
}

@Injectable()
export class OpenGraphHelperService implements OnDestroy {
  public openGraphParams = {
    locale: 'uk_UA',
    type: 'website',
    image: 'https://images.cf-rabota.com.ua/2022/08/og-general-image.png',
    width: '1200',
    height: '630',
    site_name: '',
    title: '',
    description: '',
    url: '',
    twitterCard: 'summary_large_image',
    twitterSite: '@rabota_ua',
    twitterTitle: '',
    twitterDescription: ''
  }

  public constructor(private meta: Meta) {}

  public addOgTags(ogParams: OgParams): void {
    this.openGraphParams = {
      ...this.openGraphParams,
      ...ogParams
    }

    const { locale, type, image, site_name, title, description, url, width, height, twitterCard, twitterSite, twitterTitle, twitterDescription } = this.openGraphParams

    this.addMetaTag(OgParamsEnum.locale, locale)
    this.addMetaTag(OgParamsEnum.image, image)
    this.addMetaTag(OgParamsEnum.title, title)
    this.addMetaTag(OgParamsEnum.description, description)
    this.addMetaTag(OgParamsEnum.type, type)
    this.addMetaTag(OgParamsEnum.siteName, site_name)
    this.addMetaTag(OgParamsEnum.url, url)
    this.addMetaTag(OgParamsEnum.width, width)
    this.addMetaTag(OgParamsEnum.height, height)
    this.addMetaTag(OgParamsEnum.twitterCard, twitterCard)
    this.addMetaTag(OgParamsEnum.twitterImage, image)
    this.addMetaTag(OgParamsEnum.twitterSite, twitterSite)
    this.addMetaTag(OgParamsEnum.twitterTitle, twitterTitle)
    this.addMetaTag(OgParamsEnum.twitterDescription, twitterDescription)
  }

  public removeOgTags(): void {
    this.removeTag(OgParamsEnum.locale)
    this.removeTag(OgParamsEnum.image)
    this.removeTag(OgParamsEnum.title)
    this.removeTag(OgParamsEnum.description)
    this.removeTag(OgParamsEnum.type)
    this.removeTag(OgParamsEnum.siteName)
    this.removeTag(OgParamsEnum.url)
    this.removeTag(OgParamsEnum.width)
    this.removeTag(OgParamsEnum.height)
    this.removeTag(OgParamsEnum.twitterCard)
    this.removeTag(OgParamsEnum.twitterImage)
    this.removeTag(OgParamsEnum.twitterSite)
    this.removeTag(OgParamsEnum.twitterTitle)
    this.removeTag(OgParamsEnum.twitterDescription)
  }

  public ngOnDestroy(): void {
    this.removeOgTags()
  }

  private addMetaTag(property: string, content: string): void {
    if (content) {
      if (this.meta.getTag(`property='${property}'`)) {
        this.removeTag(property)
      }
      this.meta.addTag({ property, content })
    }
  }

  private removeTag(property: string): void {
    this.meta.removeTag(`property='${property}'`)
  }
}
