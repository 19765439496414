import { Injectable } from '@angular/core'
import { RxState as BaseRxState } from '@rx-angular/state'
import { isObservable, Observable } from 'rxjs'

@Injectable()
export class RxStateService<T extends object> extends BaseRxState<T> {
  public initState(slices$: { [K in keyof T]: Observable<T[K]> | T[K] }): void {
    for (const [key, val] of Object.entries(slices$)) {
      if (isObservable(val)) {
        this.connect(key as keyof T, val as Observable<T[keyof T]>)
      } else {
        this.set({ [key]: val } as T)
      }
    }
  }
}
