/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { Observable } from 'rxjs'
import { map } from 'rxjs/operators'

import { BaseService } from '../base-service'
import { NotificationDataAccessConfiguration } from '../notification-data-access-configuration'
import { StrictHttpResponse } from '../strict-http-response'

import { conversationMassActionsArchiveByUser$Json } from '../fn/conversation-mass-actions/conversation-mass-actions-archive-by-user-json'
import { ConversationMassActionsArchiveByUser$Json$Params } from '../fn/conversation-mass-actions/conversation-mass-actions-archive-by-user-json'
import { conversationMassActionsArchiveByUser$Plain } from '../fn/conversation-mass-actions/conversation-mass-actions-archive-by-user-plain'
import { ConversationMassActionsArchiveByUser$Plain$Params } from '../fn/conversation-mass-actions/conversation-mass-actions-archive-by-user-plain'
import { conversationMassActionsDeleteByUser$Json } from '../fn/conversation-mass-actions/conversation-mass-actions-delete-by-user-json'
import { ConversationMassActionsDeleteByUser$Json$Params } from '../fn/conversation-mass-actions/conversation-mass-actions-delete-by-user-json'
import { conversationMassActionsDeleteByUser$Plain } from '../fn/conversation-mass-actions/conversation-mass-actions-delete-by-user-plain'
import { ConversationMassActionsDeleteByUser$Plain$Params } from '../fn/conversation-mass-actions/conversation-mass-actions-delete-by-user-plain'
import { conversationMassActionsGetChatActiveEmployers$Json } from '../fn/conversation-mass-actions/conversation-mass-actions-get-chat-active-employers-json'
import { ConversationMassActionsGetChatActiveEmployers$Json$Params } from '../fn/conversation-mass-actions/conversation-mass-actions-get-chat-active-employers-json'
import { conversationMassActionsGetChatActiveEmployers$Plain } from '../fn/conversation-mass-actions/conversation-mass-actions-get-chat-active-employers-plain'
import { ConversationMassActionsGetChatActiveEmployers$Plain$Params } from '../fn/conversation-mass-actions/conversation-mass-actions-get-chat-active-employers-plain'
import { conversationMassActionsGetConversationsToArchive$Json } from '../fn/conversation-mass-actions/conversation-mass-actions-get-conversations-to-archive-json'
import { ConversationMassActionsGetConversationsToArchive$Json$Params } from '../fn/conversation-mass-actions/conversation-mass-actions-get-conversations-to-archive-json'
import { conversationMassActionsGetConversationsToArchive$Plain } from '../fn/conversation-mass-actions/conversation-mass-actions-get-conversations-to-archive-plain'
import { ConversationMassActionsGetConversationsToArchive$Plain$Params } from '../fn/conversation-mass-actions/conversation-mass-actions-get-conversations-to-archive-plain'
import { conversationMassActionsGetConversationsToDelete$Json } from '../fn/conversation-mass-actions/conversation-mass-actions-get-conversations-to-delete-json'
import { ConversationMassActionsGetConversationsToDelete$Json$Params } from '../fn/conversation-mass-actions/conversation-mass-actions-get-conversations-to-delete-json'
import { conversationMassActionsGetConversationsToDelete$Plain } from '../fn/conversation-mass-actions/conversation-mass-actions-get-conversations-to-delete-plain'
import { ConversationMassActionsGetConversationsToDelete$Plain$Params } from '../fn/conversation-mass-actions/conversation-mass-actions-get-conversations-to-delete-plain'
import { ConversationsToModify } from '../models/conversations-to-modify'
import { MassActionsResponse } from '../models/mass-actions-response'
import { UserConversationsModel } from '../models/user-conversations-model'

@Injectable({ providedIn: 'root' })
export class ConversationMassActionsService extends BaseService {
  constructor(config: NotificationDataAccessConfiguration, http: HttpClient) {
    super(config, http)
  }

  /** Path part for operation `conversationMassActionsGetChatActiveEmployers()` */
  static readonly ConversationMassActionsGetChatActiveEmployersPath = '/v1/conversation-mass-actions/get-users-to-validate'

  /**
   * Retuns list of user ids, that use chatss.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `conversationMassActionsGetChatActiveEmployers$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  conversationMassActionsGetChatActiveEmployers$Plain$Response(
    params?: ConversationMassActionsGetChatActiveEmployers$Plain$Params,
    context?: HttpContext
  ): Observable<StrictHttpResponse<Array<string>>> {
    return conversationMassActionsGetChatActiveEmployers$Plain(this.http, this.rootUrl, params, context)
  }

  /**
   * Retuns list of user ids, that use chatss.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `conversationMassActionsGetChatActiveEmployers$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  conversationMassActionsGetChatActiveEmployers$Plain(params?: ConversationMassActionsGetChatActiveEmployers$Plain$Params, context?: HttpContext): Observable<Array<string>> {
    return this.conversationMassActionsGetChatActiveEmployers$Plain$Response(params, context).pipe(map((r: StrictHttpResponse<Array<string>>): Array<string> => r.body))
  }

  /**
   * Retuns list of user ids, that use chatss.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `conversationMassActionsGetChatActiveEmployers$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  conversationMassActionsGetChatActiveEmployers$Json$Response(
    params?: ConversationMassActionsGetChatActiveEmployers$Json$Params,
    context?: HttpContext
  ): Observable<StrictHttpResponse<Array<string>>> {
    return conversationMassActionsGetChatActiveEmployers$Json(this.http, this.rootUrl, params, context)
  }

  /**
   * Retuns list of user ids, that use chatss.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `conversationMassActionsGetChatActiveEmployers$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  conversationMassActionsGetChatActiveEmployers$Json(params?: ConversationMassActionsGetChatActiveEmployers$Json$Params, context?: HttpContext): Observable<Array<string>> {
    return this.conversationMassActionsGetChatActiveEmployers$Json$Response(params, context).pipe(map((r: StrictHttpResponse<Array<string>>): Array<string> => r.body))
  }

  /** Path part for operation `conversationMassActionsGetConversationsToArchive()` */
  static readonly ConversationMassActionsGetConversationsToArchivePath = '/v1/conversation-mass-actions/get-to-archive'

  /**
   * Retuns list of conversation ids, that needs to be archived.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `conversationMassActionsGetConversationsToArchive$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  conversationMassActionsGetConversationsToArchive$Plain$Response(
    params: ConversationMassActionsGetConversationsToArchive$Plain$Params,
    context?: HttpContext
  ): Observable<StrictHttpResponse<UserConversationsModel>> {
    return conversationMassActionsGetConversationsToArchive$Plain(this.http, this.rootUrl, params, context)
  }

  /**
   * Retuns list of conversation ids, that needs to be archived.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `conversationMassActionsGetConversationsToArchive$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  conversationMassActionsGetConversationsToArchive$Plain(params: ConversationMassActionsGetConversationsToArchive$Plain$Params, context?: HttpContext): Observable<UserConversationsModel> {
    return this.conversationMassActionsGetConversationsToArchive$Plain$Response(params, context).pipe(map((r: StrictHttpResponse<UserConversationsModel>): UserConversationsModel => r.body))
  }

  /**
   * Retuns list of conversation ids, that needs to be archived.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `conversationMassActionsGetConversationsToArchive$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  conversationMassActionsGetConversationsToArchive$Json$Response(
    params: ConversationMassActionsGetConversationsToArchive$Json$Params,
    context?: HttpContext
  ): Observable<StrictHttpResponse<UserConversationsModel>> {
    return conversationMassActionsGetConversationsToArchive$Json(this.http, this.rootUrl, params, context)
  }

  /**
   * Retuns list of conversation ids, that needs to be archived.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `conversationMassActionsGetConversationsToArchive$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  conversationMassActionsGetConversationsToArchive$Json(params: ConversationMassActionsGetConversationsToArchive$Json$Params, context?: HttpContext): Observable<UserConversationsModel> {
    return this.conversationMassActionsGetConversationsToArchive$Json$Response(params, context).pipe(map((r: StrictHttpResponse<UserConversationsModel>): UserConversationsModel => r.body))
  }

  /** Path part for operation `conversationMassActionsGetConversationsToDelete()` */
  static readonly ConversationMassActionsGetConversationsToDeletePath = '/v1/conversation-mass-actions/get-to-delete'

  /**
   * Retuns list of conversation ids, that needs to be deleted from archive.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `conversationMassActionsGetConversationsToDelete$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  conversationMassActionsGetConversationsToDelete$Plain$Response(
    params: ConversationMassActionsGetConversationsToDelete$Plain$Params,
    context?: HttpContext
  ): Observable<StrictHttpResponse<ConversationsToModify>> {
    return conversationMassActionsGetConversationsToDelete$Plain(this.http, this.rootUrl, params, context)
  }

  /**
   * Retuns list of conversation ids, that needs to be deleted from archive.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `conversationMassActionsGetConversationsToDelete$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  conversationMassActionsGetConversationsToDelete$Plain(params: ConversationMassActionsGetConversationsToDelete$Plain$Params, context?: HttpContext): Observable<ConversationsToModify> {
    return this.conversationMassActionsGetConversationsToDelete$Plain$Response(params, context).pipe(map((r: StrictHttpResponse<ConversationsToModify>): ConversationsToModify => r.body))
  }

  /**
   * Retuns list of conversation ids, that needs to be deleted from archive.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `conversationMassActionsGetConversationsToDelete$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  conversationMassActionsGetConversationsToDelete$Json$Response(
    params: ConversationMassActionsGetConversationsToDelete$Json$Params,
    context?: HttpContext
  ): Observable<StrictHttpResponse<ConversationsToModify>> {
    return conversationMassActionsGetConversationsToDelete$Json(this.http, this.rootUrl, params, context)
  }

  /**
   * Retuns list of conversation ids, that needs to be deleted from archive.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `conversationMassActionsGetConversationsToDelete$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  conversationMassActionsGetConversationsToDelete$Json(params: ConversationMassActionsGetConversationsToDelete$Json$Params, context?: HttpContext): Observable<ConversationsToModify> {
    return this.conversationMassActionsGetConversationsToDelete$Json$Response(params, context).pipe(map((r: StrictHttpResponse<ConversationsToModify>): ConversationsToModify => r.body))
  }

  /** Path part for operation `conversationMassActionsArchiveByUser()` */
  static readonly ConversationMassActionsArchiveByUserPath = '/v1/conversation-mass-actions/archive-by-user'

  /**
   * Moves conversations and related data for them to archive for provided user.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `conversationMassActionsArchiveByUser$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  conversationMassActionsArchiveByUser$Plain$Response(params?: ConversationMassActionsArchiveByUser$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<MassActionsResponse>> {
    return conversationMassActionsArchiveByUser$Plain(this.http, this.rootUrl, params, context)
  }

  /**
   * Moves conversations and related data for them to archive for provided user.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `conversationMassActionsArchiveByUser$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  conversationMassActionsArchiveByUser$Plain(params?: ConversationMassActionsArchiveByUser$Plain$Params, context?: HttpContext): Observable<MassActionsResponse> {
    return this.conversationMassActionsArchiveByUser$Plain$Response(params, context).pipe(map((r: StrictHttpResponse<MassActionsResponse>): MassActionsResponse => r.body))
  }

  /**
   * Moves conversations and related data for them to archive for provided user.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `conversationMassActionsArchiveByUser$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  conversationMassActionsArchiveByUser$Json$Response(params?: ConversationMassActionsArchiveByUser$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<MassActionsResponse>> {
    return conversationMassActionsArchiveByUser$Json(this.http, this.rootUrl, params, context)
  }

  /**
   * Moves conversations and related data for them to archive for provided user.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `conversationMassActionsArchiveByUser$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  conversationMassActionsArchiveByUser$Json(params?: ConversationMassActionsArchiveByUser$Json$Params, context?: HttpContext): Observable<MassActionsResponse> {
    return this.conversationMassActionsArchiveByUser$Json$Response(params, context).pipe(map((r: StrictHttpResponse<MassActionsResponse>): MassActionsResponse => r.body))
  }

  /** Path part for operation `conversationMassActionsDeleteByUser()` */
  static readonly ConversationMassActionsDeleteByUserPath = '/v1/conversation-mass-actions/delete-by-user'

  /**
   * Deletes conversations and related data for them from archive for provided user.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `conversationMassActionsDeleteByUser$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  conversationMassActionsDeleteByUser$Plain$Response(params?: ConversationMassActionsDeleteByUser$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<MassActionsResponse>> {
    return conversationMassActionsDeleteByUser$Plain(this.http, this.rootUrl, params, context)
  }

  /**
   * Deletes conversations and related data for them from archive for provided user.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `conversationMassActionsDeleteByUser$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  conversationMassActionsDeleteByUser$Plain(params?: ConversationMassActionsDeleteByUser$Plain$Params, context?: HttpContext): Observable<MassActionsResponse> {
    return this.conversationMassActionsDeleteByUser$Plain$Response(params, context).pipe(map((r: StrictHttpResponse<MassActionsResponse>): MassActionsResponse => r.body))
  }

  /**
   * Deletes conversations and related data for them from archive for provided user.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `conversationMassActionsDeleteByUser$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  conversationMassActionsDeleteByUser$Json$Response(params?: ConversationMassActionsDeleteByUser$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<MassActionsResponse>> {
    return conversationMassActionsDeleteByUser$Json(this.http, this.rootUrl, params, context)
  }

  /**
   * Deletes conversations and related data for them from archive for provided user.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `conversationMassActionsDeleteByUser$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  conversationMassActionsDeleteByUser$Json(params?: ConversationMassActionsDeleteByUser$Json$Params, context?: HttpContext): Observable<MassActionsResponse> {
    return this.conversationMassActionsDeleteByUser$Json$Response(params, context).pipe(map((r: StrictHttpResponse<MassActionsResponse>): MassActionsResponse => r.body))
  }
}
