/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core'
import { HttpClient, HttpResponse, HttpContext } from '@angular/common/http'
import { BaseService } from '../base-service'
import { PromoDataAccessConfigurationInterface } from '../promo-data-access-configuration-interface'
import { StrictHttpResponse } from '../strict-http-response'
import { RequestBuilder } from '../request-builder'
import { Observable } from 'rxjs'
import { map, filter } from 'rxjs/operators'

import { CreatePromoCodeResponse } from '../models/create-promo-code-response'
import { PromoCodeServiceTypeEnum } from '../models/promo-code-service-type-enum'

@Injectable({
  providedIn: 'root'
})
export class PublicPromocodeService extends BaseService {
  constructor(config: PromoDataAccessConfigurationInterface, http: HttpClient) {
    super(config, http)
  }

  /**
   * Path part for operation publicForSpecialServiceIdPost
   */
  static readonly PublicForSpecialServiceIdPostPath = '/public/for-special-serviceId'

  /**
   * Создание ПУБЛИЧНОГО промокода на скидку для опеределенного сервиса ( ПО АЙДИ ) и
   * Каждная компания сможет начислить себе его 1 раз
   * - Обязательные поля имеют пометку *required
   * - Сервис должен быть доступным в каталоге
   * - Скидка не должна быть более 33 процентов
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `publicForSpecialServiceIdPost$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  publicForSpecialServiceIdPost$Plain$Response(
    params: {
      /**
       * Размер скидки в процентах
       */
      DiscountSizePercent: number

      /**
       * Айди сервиса
       */
      ServiceId: number

      /**
       * Количество дней от сегодня, когда промокод будет активным
       */
      DaysActive: number

      /**
       * Количество зарядов использования промокода
       */
      ChargesCount: number

      /**
       * Код промокода, можно не указывать, тогда АПИ само создаст его
       */
      Code?: string
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<CreatePromoCodeResponse>> {
    const rb = new RequestBuilder(this.rootUrl, PublicPromocodeService.PublicForSpecialServiceIdPostPath, 'post')
    if (params) {
      rb.query('DiscountSizePercent', params.DiscountSizePercent, {})
      rb.query('ServiceId', params.ServiceId, {})
      rb.query('DaysActive', params.DaysActive, {})
      rb.query('ChargesCount', params.ChargesCount, {})
      rb.query('Code', params.Code, {})
    }

    return this.http
      .request(
        rb.build({
          responseType: 'text',
          accept: 'text/plain',
          context: context
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<CreatePromoCodeResponse>
        })
      )
  }

  /**
   * Создание ПУБЛИЧНОГО промокода на скидку для опеределенного сервиса ( ПО АЙДИ ) и
   * Каждная компания сможет начислить себе его 1 раз
   * - Обязательные поля имеют пометку *required
   * - Сервис должен быть доступным в каталоге
   * - Скидка не должна быть более 33 процентов
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `publicForSpecialServiceIdPost$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  publicForSpecialServiceIdPost$Plain(
    params: {
      /**
       * Размер скидки в процентах
       */
      DiscountSizePercent: number

      /**
       * Айди сервиса
       */
      ServiceId: number

      /**
       * Количество дней от сегодня, когда промокод будет активным
       */
      DaysActive: number

      /**
       * Количество зарядов использования промокода
       */
      ChargesCount: number

      /**
       * Код промокода, можно не указывать, тогда АПИ само создаст его
       */
      Code?: string
    },
    context?: HttpContext
  ): Observable<CreatePromoCodeResponse> {
    return this.publicForSpecialServiceIdPost$Plain$Response(params, context).pipe(map((r: StrictHttpResponse<CreatePromoCodeResponse>) => r.body as CreatePromoCodeResponse))
  }

  /**
   * Создание ПУБЛИЧНОГО промокода на скидку для опеределенного сервиса ( ПО АЙДИ ) и
   * Каждная компания сможет начислить себе его 1 раз
   * - Обязательные поля имеют пометку *required
   * - Сервис должен быть доступным в каталоге
   * - Скидка не должна быть более 33 процентов
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `publicForSpecialServiceIdPost$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  publicForSpecialServiceIdPost$Json$Response(
    params: {
      /**
       * Размер скидки в процентах
       */
      DiscountSizePercent: number

      /**
       * Айди сервиса
       */
      ServiceId: number

      /**
       * Количество дней от сегодня, когда промокод будет активным
       */
      DaysActive: number

      /**
       * Количество зарядов использования промокода
       */
      ChargesCount: number

      /**
       * Код промокода, можно не указывать, тогда АПИ само создаст его
       */
      Code?: string
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<CreatePromoCodeResponse>> {
    const rb = new RequestBuilder(this.rootUrl, PublicPromocodeService.PublicForSpecialServiceIdPostPath, 'post')
    if (params) {
      rb.query('DiscountSizePercent', params.DiscountSizePercent, {})
      rb.query('ServiceId', params.ServiceId, {})
      rb.query('DaysActive', params.DaysActive, {})
      rb.query('ChargesCount', params.ChargesCount, {})
      rb.query('Code', params.Code, {})
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'text/json',
          context: context
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<CreatePromoCodeResponse>
        })
      )
  }

  /**
   * Создание ПУБЛИЧНОГО промокода на скидку для опеределенного сервиса ( ПО АЙДИ ) и
   * Каждная компания сможет начислить себе его 1 раз
   * - Обязательные поля имеют пометку *required
   * - Сервис должен быть доступным в каталоге
   * - Скидка не должна быть более 33 процентов
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `publicForSpecialServiceIdPost$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  publicForSpecialServiceIdPost$Json(
    params: {
      /**
       * Размер скидки в процентах
       */
      DiscountSizePercent: number

      /**
       * Айди сервиса
       */
      ServiceId: number

      /**
       * Количество дней от сегодня, когда промокод будет активным
       */
      DaysActive: number

      /**
       * Количество зарядов использования промокода
       */
      ChargesCount: number

      /**
       * Код промокода, можно не указывать, тогда АПИ само создаст его
       */
      Code?: string
    },
    context?: HttpContext
  ): Observable<CreatePromoCodeResponse> {
    return this.publicForSpecialServiceIdPost$Json$Response(params, context).pipe(map((r: StrictHttpResponse<CreatePromoCodeResponse>) => r.body as CreatePromoCodeResponse))
  }

  /**
   * Path part for operation publicForSpecialServiceTypePost
   */
  static readonly PublicForSpecialServiceTypePostPath = '/public/for-special-service-type'

  /**
   * Создание ПУБЛИЧНОГО промокода на скидку для опеределенного сервис ТИПА ( Оптимум, Бизнес и тд ) и
   * Каждная компания сможет начислить себе его 1 раз
   * - Обязательные поля имеют пометку *required
   * - Сервис должен быть доступным в каталоге
   * - Скидка не должна быть более 33 процентов
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `publicForSpecialServiceTypePost$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  publicForSpecialServiceTypePost$Plain$Response(
    params: {
      /**
       * Размер скидки в процентах
       */
      DiscountSizePercent: number

      /**
       * Тип сервиса ( Business, Optimum, и тд )
       */
      ServiceType: PromoCodeServiceTypeEnum

      /**
       * Количество дней от сегодня, когда промокод будет активным
       */
      DaysActive: number

      /**
       * Количество зарядов использования промокода
       */
      ChargesCount: number

      /**
       * Код промокода, можно не указывать, тогда АПИ само создаст его
       */
      Code?: string
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<CreatePromoCodeResponse>> {
    const rb = new RequestBuilder(this.rootUrl, PublicPromocodeService.PublicForSpecialServiceTypePostPath, 'post')
    if (params) {
      rb.query('DiscountSizePercent', params.DiscountSizePercent, {})
      rb.query('ServiceType', params.ServiceType, {})
      rb.query('DaysActive', params.DaysActive, {})
      rb.query('ChargesCount', params.ChargesCount, {})
      rb.query('Code', params.Code, {})
    }

    return this.http
      .request(
        rb.build({
          responseType: 'text',
          accept: 'text/plain',
          context: context
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<CreatePromoCodeResponse>
        })
      )
  }

  /**
   * Создание ПУБЛИЧНОГО промокода на скидку для опеределенного сервис ТИПА ( Оптимум, Бизнес и тд ) и
   * Каждная компания сможет начислить себе его 1 раз
   * - Обязательные поля имеют пометку *required
   * - Сервис должен быть доступным в каталоге
   * - Скидка не должна быть более 33 процентов
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `publicForSpecialServiceTypePost$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  publicForSpecialServiceTypePost$Plain(
    params: {
      /**
       * Размер скидки в процентах
       */
      DiscountSizePercent: number

      /**
       * Тип сервиса ( Business, Optimum, и тд )
       */
      ServiceType: PromoCodeServiceTypeEnum

      /**
       * Количество дней от сегодня, когда промокод будет активным
       */
      DaysActive: number

      /**
       * Количество зарядов использования промокода
       */
      ChargesCount: number

      /**
       * Код промокода, можно не указывать, тогда АПИ само создаст его
       */
      Code?: string
    },
    context?: HttpContext
  ): Observable<CreatePromoCodeResponse> {
    return this.publicForSpecialServiceTypePost$Plain$Response(params, context).pipe(map((r: StrictHttpResponse<CreatePromoCodeResponse>) => r.body as CreatePromoCodeResponse))
  }

  /**
   * Создание ПУБЛИЧНОГО промокода на скидку для опеределенного сервис ТИПА ( Оптимум, Бизнес и тд ) и
   * Каждная компания сможет начислить себе его 1 раз
   * - Обязательные поля имеют пометку *required
   * - Сервис должен быть доступным в каталоге
   * - Скидка не должна быть более 33 процентов
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `publicForSpecialServiceTypePost$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  publicForSpecialServiceTypePost$Json$Response(
    params: {
      /**
       * Размер скидки в процентах
       */
      DiscountSizePercent: number

      /**
       * Тип сервиса ( Business, Optimum, и тд )
       */
      ServiceType: PromoCodeServiceTypeEnum

      /**
       * Количество дней от сегодня, когда промокод будет активным
       */
      DaysActive: number

      /**
       * Количество зарядов использования промокода
       */
      ChargesCount: number

      /**
       * Код промокода, можно не указывать, тогда АПИ само создаст его
       */
      Code?: string
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<CreatePromoCodeResponse>> {
    const rb = new RequestBuilder(this.rootUrl, PublicPromocodeService.PublicForSpecialServiceTypePostPath, 'post')
    if (params) {
      rb.query('DiscountSizePercent', params.DiscountSizePercent, {})
      rb.query('ServiceType', params.ServiceType, {})
      rb.query('DaysActive', params.DaysActive, {})
      rb.query('ChargesCount', params.ChargesCount, {})
      rb.query('Code', params.Code, {})
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'text/json',
          context: context
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<CreatePromoCodeResponse>
        })
      )
  }

  /**
   * Создание ПУБЛИЧНОГО промокода на скидку для опеределенного сервис ТИПА ( Оптимум, Бизнес и тд ) и
   * Каждная компания сможет начислить себе его 1 раз
   * - Обязательные поля имеют пометку *required
   * - Сервис должен быть доступным в каталоге
   * - Скидка не должна быть более 33 процентов
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `publicForSpecialServiceTypePost$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  publicForSpecialServiceTypePost$Json(
    params: {
      /**
       * Размер скидки в процентах
       */
      DiscountSizePercent: number

      /**
       * Тип сервиса ( Business, Optimum, и тд )
       */
      ServiceType: PromoCodeServiceTypeEnum

      /**
       * Количество дней от сегодня, когда промокод будет активным
       */
      DaysActive: number

      /**
       * Количество зарядов использования промокода
       */
      ChargesCount: number

      /**
       * Код промокода, можно не указывать, тогда АПИ само создаст его
       */
      Code?: string
    },
    context?: HttpContext
  ): Observable<CreatePromoCodeResponse> {
    return this.publicForSpecialServiceTypePost$Json$Response(params, context).pipe(map((r: StrictHttpResponse<CreatePromoCodeResponse>) => r.body as CreatePromoCodeResponse))
  }

  /**
   * Path part for operation publicForWholeOrderPost
   */
  static readonly PublicForWholeOrderPostPath = '/public/for-whole-order'

  /**
   * Создание ПУБЛИЧНОГО промокода на скидку для всего заказа
   * Каждая компания сможет начислить себе его 1 раз
   * - Обязательные поля имеют пометку *required
   * - Сервис должен быть доступным в каталоге
   * - Скидка не должна быть более 33 процентов
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `publicForWholeOrderPost$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  publicForWholeOrderPost$Plain$Response(
    params: {
      /**
       * Размер скидки в процентах
       */
      DiscountSizePercent: number

      /**
       * Количество дней от сегодня, когда промокод будет активным
       */
      DaysActive: number

      /**
       * Количество зарядов использования промокода
       */
      ChargesCount: number

      /**
       * Код промокода, можно не указывать, тогда АПИ само создаст его
       */
      Code?: string
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<CreatePromoCodeResponse>> {
    const rb = new RequestBuilder(this.rootUrl, PublicPromocodeService.PublicForWholeOrderPostPath, 'post')
    if (params) {
      rb.query('DiscountSizePercent', params.DiscountSizePercent, {})
      rb.query('DaysActive', params.DaysActive, {})
      rb.query('ChargesCount', params.ChargesCount, {})
      rb.query('Code', params.Code, {})
    }

    return this.http
      .request(
        rb.build({
          responseType: 'text',
          accept: 'text/plain',
          context: context
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<CreatePromoCodeResponse>
        })
      )
  }

  /**
   * Создание ПУБЛИЧНОГО промокода на скидку для всего заказа
   * Каждая компания сможет начислить себе его 1 раз
   * - Обязательные поля имеют пометку *required
   * - Сервис должен быть доступным в каталоге
   * - Скидка не должна быть более 33 процентов
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `publicForWholeOrderPost$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  publicForWholeOrderPost$Plain(
    params: {
      /**
       * Размер скидки в процентах
       */
      DiscountSizePercent: number

      /**
       * Количество дней от сегодня, когда промокод будет активным
       */
      DaysActive: number

      /**
       * Количество зарядов использования промокода
       */
      ChargesCount: number

      /**
       * Код промокода, можно не указывать, тогда АПИ само создаст его
       */
      Code?: string
    },
    context?: HttpContext
  ): Observable<CreatePromoCodeResponse> {
    return this.publicForWholeOrderPost$Plain$Response(params, context).pipe(map((r: StrictHttpResponse<CreatePromoCodeResponse>) => r.body as CreatePromoCodeResponse))
  }

  /**
   * Создание ПУБЛИЧНОГО промокода на скидку для всего заказа
   * Каждая компания сможет начислить себе его 1 раз
   * - Обязательные поля имеют пометку *required
   * - Сервис должен быть доступным в каталоге
   * - Скидка не должна быть более 33 процентов
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `publicForWholeOrderPost$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  publicForWholeOrderPost$Json$Response(
    params: {
      /**
       * Размер скидки в процентах
       */
      DiscountSizePercent: number

      /**
       * Количество дней от сегодня, когда промокод будет активным
       */
      DaysActive: number

      /**
       * Количество зарядов использования промокода
       */
      ChargesCount: number

      /**
       * Код промокода, можно не указывать, тогда АПИ само создаст его
       */
      Code?: string
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<CreatePromoCodeResponse>> {
    const rb = new RequestBuilder(this.rootUrl, PublicPromocodeService.PublicForWholeOrderPostPath, 'post')
    if (params) {
      rb.query('DiscountSizePercent', params.DiscountSizePercent, {})
      rb.query('DaysActive', params.DaysActive, {})
      rb.query('ChargesCount', params.ChargesCount, {})
      rb.query('Code', params.Code, {})
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'text/json',
          context: context
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<CreatePromoCodeResponse>
        })
      )
  }

  /**
   * Создание ПУБЛИЧНОГО промокода на скидку для всего заказа
   * Каждая компания сможет начислить себе его 1 раз
   * - Обязательные поля имеют пометку *required
   * - Сервис должен быть доступным в каталоге
   * - Скидка не должна быть более 33 процентов
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `publicForWholeOrderPost$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  publicForWholeOrderPost$Json(
    params: {
      /**
       * Размер скидки в процентах
       */
      DiscountSizePercent: number

      /**
       * Количество дней от сегодня, когда промокод будет активным
       */
      DaysActive: number

      /**
       * Количество зарядов использования промокода
       */
      ChargesCount: number

      /**
       * Код промокода, можно не указывать, тогда АПИ само создаст его
       */
      Code?: string
    },
    context?: HttpContext
  ): Observable<CreatePromoCodeResponse> {
    return this.publicForWholeOrderPost$Json$Response(params, context).pipe(map((r: StrictHttpResponse<CreatePromoCodeResponse>) => r.body as CreatePromoCodeResponse))
  }

  /**
   * Path part for operation publicForBonusServicesPost
   */
  static readonly PublicForBonusServicesPostPath = '/public/for-bonus-services'

  /**
   * Создание ПУБЛИЧНОГО промокода для начисления бонусного сервиса и
   * начисления его на баланс нужной компании
   * - Обязательные поля имеют пометку *required
   * - Сервис должен быть доступным в каталоге
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `publicForBonusServicesPost$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  publicForBonusServicesPost$Plain$Response(
    params: {
      ServiceIds: Array<number>

      /**
       * Количество дней от сегодня, когда промокод будет активным
       */
      DaysActive: number

      /**
       * Количество зарядов использования промокода
       */
      ChargesCount: number

      /**
       * Код промокода, можно не указывать, тогда АПИ само создаст его
       */
      Code?: string
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<CreatePromoCodeResponse>> {
    const rb = new RequestBuilder(this.rootUrl, PublicPromocodeService.PublicForBonusServicesPostPath, 'post')
    if (params) {
      rb.query('ServiceIds', params.ServiceIds, {})
      rb.query('DaysActive', params.DaysActive, {})
      rb.query('ChargesCount', params.ChargesCount, {})
      rb.query('Code', params.Code, {})
    }

    return this.http
      .request(
        rb.build({
          responseType: 'text',
          accept: 'text/plain',
          context: context
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<CreatePromoCodeResponse>
        })
      )
  }

  /**
   * Создание ПУБЛИЧНОГО промокода для начисления бонусного сервиса и
   * начисления его на баланс нужной компании
   * - Обязательные поля имеют пометку *required
   * - Сервис должен быть доступным в каталоге
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `publicForBonusServicesPost$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  publicForBonusServicesPost$Plain(
    params: {
      ServiceIds: Array<number>

      /**
       * Количество дней от сегодня, когда промокод будет активным
       */
      DaysActive: number

      /**
       * Количество зарядов использования промокода
       */
      ChargesCount: number

      /**
       * Код промокода, можно не указывать, тогда АПИ само создаст его
       */
      Code?: string
    },
    context?: HttpContext
  ): Observable<CreatePromoCodeResponse> {
    return this.publicForBonusServicesPost$Plain$Response(params, context).pipe(map((r: StrictHttpResponse<CreatePromoCodeResponse>) => r.body as CreatePromoCodeResponse))
  }

  /**
   * Создание ПУБЛИЧНОГО промокода для начисления бонусного сервиса и
   * начисления его на баланс нужной компании
   * - Обязательные поля имеют пометку *required
   * - Сервис должен быть доступным в каталоге
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `publicForBonusServicesPost$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  publicForBonusServicesPost$Json$Response(
    params: {
      ServiceIds: Array<number>

      /**
       * Количество дней от сегодня, когда промокод будет активным
       */
      DaysActive: number

      /**
       * Количество зарядов использования промокода
       */
      ChargesCount: number

      /**
       * Код промокода, можно не указывать, тогда АПИ само создаст его
       */
      Code?: string
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<CreatePromoCodeResponse>> {
    const rb = new RequestBuilder(this.rootUrl, PublicPromocodeService.PublicForBonusServicesPostPath, 'post')
    if (params) {
      rb.query('ServiceIds', params.ServiceIds, {})
      rb.query('DaysActive', params.DaysActive, {})
      rb.query('ChargesCount', params.ChargesCount, {})
      rb.query('Code', params.Code, {})
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'text/json',
          context: context
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<CreatePromoCodeResponse>
        })
      )
  }

  /**
   * Создание ПУБЛИЧНОГО промокода для начисления бонусного сервиса и
   * начисления его на баланс нужной компании
   * - Обязательные поля имеют пометку *required
   * - Сервис должен быть доступным в каталоге
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `publicForBonusServicesPost$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  publicForBonusServicesPost$Json(
    params: {
      ServiceIds: Array<number>

      /**
       * Количество дней от сегодня, когда промокод будет активным
       */
      DaysActive: number

      /**
       * Количество зарядов использования промокода
       */
      ChargesCount: number

      /**
       * Код промокода, можно не указывать, тогда АПИ само создаст его
       */
      Code?: string
    },
    context?: HttpContext
  ): Observable<CreatePromoCodeResponse> {
    return this.publicForBonusServicesPost$Json$Response(params, context).pipe(map((r: StrictHttpResponse<CreatePromoCodeResponse>) => r.body as CreatePromoCodeResponse))
  }
}
