import { DictionaryService } from '@alliance/jobseeker/api'
import { TranslationService } from '@alliance/shared/translation'
import { Injectable } from '@angular/core'
import { Observable, of, zip } from 'rxjs'
import { map, switchMap } from 'rxjs/operators'
import { BreadcrumbList } from 'schema-dts'
import { HelpersService } from '../helpers.service'
import { Translations } from '../localization/translations'
import { PartialSeoParamsResponse, PlatformHosts, VacancyListSeoParams } from '../models'
import { AllowedFilterCombinations, AllowedFilters, AllowedIndexFiltersEnum, VacListSeoParams, VacancyListModel } from '../../openapi/model/vacancy-list.model'

@Injectable({ providedIn: 'root' })
export class VacancyListDictionaryService {
  public constructor(private translations: Translations, private translationService: TranslationService, private dictionaryService: DictionaryService, private helpersService: HelpersService) {}

  public getParams({ params }: VacancyListSeoParams): Observable<PartialSeoParamsResponse> {
    const { totalItems } = params
    const seoParams = this.getSeoParams(params)

    return this.getBaseParams$(seoParams).pipe(
      switchMap(baseParams =>
        of(seoParams.filters?.keywords).pipe(
          switchMap(value => (value ? this.dictionaryService.getKeywordsWithTransliteration() : of([]))),
          switchMap(list => {
            const isKeywordsNotAllowedForIndexing = list.length && !list.some(item => (item?.name ?? '').toLowerCase() === (seoParams.filters?.keywords ?? '').toLowerCase())
            return isKeywordsNotAllowedForIndexing || !totalItems || !seoParams.combinationKey
              ? of({ ...baseParams, noIndexNoFollow: true })
              : this.getIndexingParams$(params).pipe(map(indexingParams => ({ ...baseParams, ...indexingParams })))
          })
        )
      )
    )
  }

  private getJsonLd$(inputParams: VacancyListModel): Observable<string> {
    const { cityId, keywords } = inputParams.filters
    const platform = PlatformHosts.desktop

    // only city
    if (+cityId > 0 && !keywords) {
      return this.jsonLdOnlyCity(+cityId, platform)
    }

    // only keywords
    if (!cityId && keywords) {
      return this.jsonLdOnlyKeywords(platform, keywords)
    }

    // keywords and city
    if (keywords && +cityId > 0) {
      return this.jsonLdKeywordsCity(+cityId, keywords, platform)
    }

    return of(this.helpersService.createJsonLd(platform, {})) // default
  }

  private jsonLdOnlyCity(cityId: number, platform: PlatformHosts): Observable<string> {
    return this.dictionaryService.getCityName$(cityId, null, true).pipe(
      map(cityName =>
        this.helpersService.createJsonLd<BreadcrumbList>(platform, {
          '@context': 'https://schema.org',
          '@type': 'BreadcrumbList',
          itemListElement: [
            this.helpersService.getHomePageBreadcrumb(platform),
            this.helpersService.getSearchByVacanciesBreadcrumb(platform),
            {
              '@type': 'ListItem',
              position: 3,
              name: `${this.translationService.translate(this.translations.jsonLd.breadcrumbs.job)} ${this.translationService.translate(this.translations.jsonLd.breadcrumbs.inCity, {
                cityInflected: cityName
              })}`
            }
          ]
        })
      )
    )
  }

  private jsonLdOnlyKeywords(platform: PlatformHosts, keywords: string): Observable<string> {
    return of(
      this.helpersService.createJsonLd<BreadcrumbList>(platform, {
        '@context': 'https://schema.org',
        '@type': 'BreadcrumbList',
        itemListElement: [
          this.helpersService.getHomePageBreadcrumb(platform),
          this.helpersService.getSearchByVacanciesBreadcrumb(platform),
          {
            '@type': 'ListItem',
            position: 3,
            name: `${this.translationService.translate(this.translations.jsonLd.breadcrumbs.job)} ${keywords}`
          }
        ]
      })
    )
  }

  private jsonLdKeywordsCity(cityId: number, keywords: string, platform: PlatformHosts): Observable<string> {
    return zip(
      this.dictionaryService.getCityName$(cityId, null, true),
      this.helpersService.createDesktopVacancyListUrl$(cityId, ''),
      this.helpersService.createDesktopVacancyListUrl$(0, keywords)
    ).pipe(
      map(([cityName, cityVacancyListDesktopUrl, searchTagUkraineVacancyListDesktopUrl]) => {
        const inCityTranslation = this.translationService.translate(this.translations.jsonLd.breadcrumbs.inCity, {
          cityInflected: cityName
        })

        return this.helpersService.createJsonLd<BreadcrumbList>(platform, {
          '@context': 'https://schema.org',
          '@type': 'BreadcrumbList',
          itemListElement: [
            this.helpersService.getHomePageBreadcrumb(platform),
            this.helpersService.getSearchByVacanciesBreadcrumb(platform),
            {
              '@type': 'ListItem',
              position: 3,
              item: cityVacancyListDesktopUrl,
              name: `${this.translationService.translate(this.translations.jsonLd.breadcrumbs.vacancies)} ${inCityTranslation}`
            },
            {
              '@type': 'ListItem',
              position: 4,
              item: searchTagUkraineVacancyListDesktopUrl,
              name: keywords
            },
            {
              '@type': 'ListItem',
              position: 5,
              name: `${keywords} ${inCityTranslation}`
            }
          ]
        })
      })
    )
  }

  private getBaseParams$(seoParams: VacListSeoParams): Observable<PartialSeoParamsResponse> {
    if (!seoParams.combinationKey) {
      return of({
        h1: [''],
        title: this.translationService.translate(this.translations.vacancyList.default.title),
        description: this.translationService.translate(this.translations.vacancyList.default.description)
      })
    }

    return this.dictionaryService.getCityName$(+(seoParams.filters?.cityId ?? 0), null, true).pipe(
      map(cityName => {
        const { filters } = seoParams
        const translateParams = {
          ...filters,
          totalItems: `${seoParams.totalItems}`,
          cityId: cityName ?? this.translationService.translate(this.translations.vacancyList.ukraine)
        }
        return {
          h1: [this.translationService.translate(this.translations.list[seoParams.combinationKey].h1, translateParams) ?? ''],
          title: this.translationService.translate(this.translations.list[seoParams.combinationKey].title, translateParams) ?? '',
          description: this.translationService.translate(this.translations.list[seoParams.combinationKey].description, translateParams) ?? ''
        }
      })
    )
  }

  private getIndexingParams$(inputParams: VacancyListModel): Observable<PartialSeoParamsResponse> {
    const { url } = inputParams
    const path = url.replace(/\/ru/, '').split('?')[0]

    return this.getJsonLd$(inputParams).pipe(
      map(jsonLd => ({
        canonicalUrl: this.helpersService.createURL(PlatformHosts.desktop, path),
        hrefLang: this.helpersService.createHrefLangURLs(path, path),
        jsonLd: {
          desktop: jsonLd,
          mobile: jsonLd
        }
      }))
    )
  }

  private getSeoParams(input: VacancyListModel): VacListSeoParams {
    const seoParams = this.validateAndFilterParams(input.filters)

    return {
      combinationKey: this.getMatchingCombination(seoParams),
      totalItems: input.totalItems,
      filters: seoParams
    }
  }

  // Метод для проверки, существует ли комбинация с текущим набором ключей
  private getMatchingCombination(params: AllowedFilters | null): string {
    if (!params) {
      return ''
    }
    const filters = Object.fromEntries(Object.entries(params).filter(([key, value]) => (key !== 'keywords' || value !== '') && key !== 'page'))
    // Получаем ключи параметров, сортируем и создаем строку ключа
    const paramKeys = Object.keys(filters).sort().join('+')
    // Получаем масив разрешенных комбинаций
    const allowedCombinations = AllowedFilterCombinations.map(combination => combination.sort().join('+'))
    return allowedCombinations.includes(paramKeys) ? paramKeys : ''
  }

  public validateAndFilterParams(params: { [key: string]: string }): AllowedFilters | null {
    const allowedKeys = Object.values(AllowedIndexFiltersEnum)
    // Проверяем, что все ключи объекта params соответствуют допустимым ключам
    if (!Object.keys(params).every(key => allowedKeys.includes(key as AllowedIndexFiltersEnum))) {
      return null
    }
    // Фильтруем и возвращаем только разрешённые ключи
    return Object.fromEntries(Object.entries(params).filter(([key]) => allowedKeys.includes(key as AllowedIndexFiltersEnum))) as AllowedFilters
  }
}
