export const ATTACH_ALLOWED_MIME_TYPES = [
  'application/msword',
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  'application/rtf',
  'text/rtf',
  'text/plain',
  'application/vnd.oasis.opendocument.text',
  'application/pdf'
]

export const ATTACH_ALLOWED_FILE_EXTENSIONS = ['.doc', '.docx', '.txt', '.rtf', '.odt', '.pdf']

export const ATTACH_ALLOWED_MAX_FILE_SIZE = 5e6
