/**
 * RUA Socket API v1.0.91
 * Abstract API layer communication through web sockets based on SignalR<br>Branch: <b>master</b><br>Commit: <a href=\"https://bitbucket.org/rabotaua/socketapi/src/b3bc936\" target=\"_blank\">b3bc936</a>
 *
 * OpenAPI spec version: v1.0.91
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
/* eslint-disable no-unused-vars, @typescript-eslint/no-unused-vars, @typescript-eslint/member-ordering */

import { Inject, Injectable, Optional } from '@angular/core'
import { HttpClient, HttpHeaders, HttpParams, HttpResponse, HttpEvent } from '@angular/common/http'
import { CustomHttpUrlEncodingCodec } from '../encoder'

import { Observable } from 'rxjs'

import { ConnectionInfo } from '../model/connectionInfo'

import { BASE_PATH } from '../variables'
import { Configuration } from '../configuration'

@Injectable()
export class WebSocketsService {
  protected basePath = 'https://localhost'
  public defaultHeaders = new HttpHeaders()
  public configuration = new Configuration()

  public constructor(protected httpClient: HttpClient, @Optional() @Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
    if (basePath) {
      this.basePath = basePath
    }
    if (configuration) {
      this.configuration = configuration
      this.basePath = basePath || configuration.basePath || this.basePath
    }
  }

  /**
   * Removes a recipient with identifier &#39;targetId&#39; from a group with &#39;groupId&#39; identifier
   *
   * @param groupId Recipients group id
   * @param targetId Recipient&#39;s id to remove from group
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public deleteGroups(groupId: string, targetId: string, observe?: 'body', reportProgress?: boolean): Observable<any>
  public deleteGroups(groupId: string, targetId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>
  public deleteGroups(groupId: string, targetId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>
  public deleteGroups(groupId: string, targetId: string, observe: any = 'body', reportProgress: boolean = false): Observable<any> {
    if (groupId === null || groupId === undefined) {
      throw new Error('Required parameter groupId was null or undefined when calling deleteGroups.')
    }

    if (targetId === null || targetId === undefined) {
      throw new Error('Required parameter targetId was null or undefined when calling deleteGroups.')
    }

    let headers = this.defaultHeaders

    // authentication (ApiKey) required
    if (this.configuration.accessToken) {
      const accessToken = typeof this.configuration.accessToken === 'function' ? this.configuration.accessToken() : this.configuration.accessToken
      headers = headers.set('Authorization', 'Bearer ' + accessToken)
    }
    // to determine the Accept header
    const httpHeaderAccepts: string[] = []
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts)
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected)
    }

    return this.httpClient.delete<any>(`${this.basePath}/v1/groups/${encodeURIComponent(String(groupId))}/${encodeURIComponent(String(targetId))}`, {
      withCredentials: this.configuration.withCredentials,
      headers,
      // TODO: Fix this the next time the file is edited.
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      observe,
      reportProgress
    })
  }

  /**
   * Allows to establish a connection to start send messages
   *
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public getConnect(observe?: 'body', reportProgress?: boolean): Observable<ConnectionInfo>
  public getConnect(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ConnectionInfo>>
  public getConnect(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ConnectionInfo>>
  public getConnect(observe: any = 'body', reportProgress: boolean = false): Observable<any> {
    let headers = this.defaultHeaders

    // authentication (Bearer) required
    if (this.configuration.accessToken) {
      const accessToken = typeof this.configuration.accessToken === 'function' ? this.configuration.accessToken() : this.configuration.accessToken
      headers = headers.set('Authorization', 'Bearer ' + accessToken)
    }
    // to determine the Accept header
    const httpHeaderAccepts: string[] = ['text/plain', 'application/json', 'text/json']
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts)
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected)
    }

    return this.httpClient.get<ConnectionInfo>(`${this.basePath}/v1/connect`, {
      withCredentials: this.configuration.withCredentials,
      headers,
      // TODO: Fix this the next time the file is edited.
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      observe,
      reportProgress
    })
  }

  /**
   * Sends abstract &#39;payload&#39; to a group with &#39;targetId&#39; identifier
   *
   * @param targetId Recipients group id
   * @param payload abstract data to send
   * @param payloadType payload type. &#39;message&#39; by default. &#39;target&#39; property on socket message will be filled up with this value
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public postGroups(targetId: string, payload?: any, payloadType?: string, observe?: 'body', reportProgress?: boolean): Observable<any>
  public postGroups(targetId: string, payload?: any, payloadType?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>
  public postGroups(targetId: string, payload?: any, payloadType?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>
  public postGroups(targetId: string, payload?: any, payloadType?: string, observe: any = 'body', reportProgress: boolean = false): Observable<any> {
    if (targetId === null || targetId === undefined) {
      throw new Error('Required parameter targetId was null or undefined when calling postGroups.')
    }

    let queryParameters = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() })
    if (payloadType !== undefined && payloadType !== null) {
      queryParameters = queryParameters.set('payloadType', <any>payloadType)
    }

    let headers = this.defaultHeaders

    // authentication (ApiKey) required
    if (this.configuration.accessToken) {
      const accessToken = typeof this.configuration.accessToken === 'function' ? this.configuration.accessToken() : this.configuration.accessToken
      headers = headers.set('Authorization', 'Bearer ' + accessToken)
    }
    // to determine the Accept header
    const httpHeaderAccepts: string[] = []
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts)
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected)
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json-patch+json', 'application/json', 'text/json', 'application/_*+json']
    const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes)
    if (httpContentTypeSelected != undefined) {
      headers = headers.set('Content-Type', httpContentTypeSelected)
    }

    return this.httpClient.post<any>(`${this.basePath}/v1/groups/${encodeURIComponent(String(targetId))}`, payload, {
      params: queryParameters,
      withCredentials: this.configuration.withCredentials,
      headers,
      // TODO: Fix this the next time the file is edited.
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      observe,
      reportProgress
    })
  }

  /**
   * Sends abstract &#39;payload&#39; to &#39;targetId&#39; recipient
   *
   * @param targetId Recipient id
   * @param payload abstract data to send
   * @param payloadType payload type. &#39;message&#39; by default. &#39;target&#39; property on socket message will be filled up with this value
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public postSingles(targetId: string, payload?: any, payloadType?: string, observe?: 'body', reportProgress?: boolean): Observable<any>
  public postSingles(targetId: string, payload?: any, payloadType?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>
  public postSingles(targetId: string, payload?: any, payloadType?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>
  public postSingles(targetId: string, payload?: any, payloadType?: string, observe: any = 'body', reportProgress: boolean = false): Observable<any> {
    if (targetId === null || targetId === undefined) {
      throw new Error('Required parameter targetId was null or undefined when calling postSingles.')
    }

    let queryParameters = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() })
    if (payloadType !== undefined && payloadType !== null) {
      queryParameters = queryParameters.set('payloadType', <any>payloadType)
    }

    let headers = this.defaultHeaders

    // authentication (ApiKey) required
    if (this.configuration.accessToken) {
      const accessToken = typeof this.configuration.accessToken === 'function' ? this.configuration.accessToken() : this.configuration.accessToken
      headers = headers.set('Authorization', 'Bearer ' + accessToken)
    }
    // to determine the Accept header
    const httpHeaderAccepts: string[] = []
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts)
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected)
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json-patch+json', 'application/json', 'text/json', 'application/_*+json']
    const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes)
    if (httpContentTypeSelected != undefined) {
      headers = headers.set('Content-Type', httpContentTypeSelected)
    }

    return this.httpClient.post<any>(`${this.basePath}/v1/singles/${encodeURIComponent(String(targetId))}`, payload, {
      params: queryParameters,
      withCredentials: this.configuration.withCredentials,
      headers,
      // TODO: Fix this the next time the file is edited.
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      observe,
      reportProgress
    })
  }

  /**
   * Adds a recipient with identifier &#39;targetId&#39; to a group with &#39;groupId&#39; identifier
   *
   * @param groupId Recipients group id
   * @param targetId Recipient&#39;s id to add to group
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public putGroups(groupId: string, targetId: string, observe?: 'body', reportProgress?: boolean): Observable<any>
  public putGroups(groupId: string, targetId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>
  public putGroups(groupId: string, targetId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>
  public putGroups(groupId: string, targetId: string, observe: any = 'body', reportProgress: boolean = false): Observable<any> {
    if (groupId === null || groupId === undefined) {
      throw new Error('Required parameter groupId was null or undefined when calling putGroups.')
    }

    if (targetId === null || targetId === undefined) {
      throw new Error('Required parameter targetId was null or undefined when calling putGroups.')
    }

    let headers = this.defaultHeaders

    // authentication (ApiKey) required
    if (this.configuration.accessToken) {
      const accessToken = typeof this.configuration.accessToken === 'function' ? this.configuration.accessToken() : this.configuration.accessToken
      headers = headers.set('Authorization', 'Bearer ' + accessToken)
    }
    // to determine the Accept header
    const httpHeaderAccepts: string[] = []
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts)
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected)
    }

    return this.httpClient.put<any>(`${this.basePath}/v1/groups/${encodeURIComponent(String(groupId))}/${encodeURIComponent(String(targetId))}`, null, {
      withCredentials: this.configuration.withCredentials,
      headers,
      // TODO: Fix this the next time the file is edited.
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      observe,
      reportProgress
    })
  }
}
