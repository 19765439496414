export const ExceptionsUaKeywords = [
  {
    keyword: 'администратор гостиници',
    localKeyword: 'адміністратор готелю'
  },
  {
    keyword: 'екскаваторщик',
    localKeyword: 'екскаваторник'
  },
  {
    keyword: 'продавец авто',
    localKeyword: 'продавець авто'
  },
  {
    keyword: 'продавец автозапчастин',
    localKeyword: 'продавець автозапчастин'
  },
  {
    keyword: 'продавец консультант',
    localKeyword: 'продавець консультант'
  },
  {
    keyword: 'продавец мороженого',
    localKeyword: 'продавець морозива'
  },
  {
    keyword: 'продавец в зоомагазин',
    localKeyword: 'продавець в зоомагазин'
  },
  {
    keyword: 'продавец',
    localKeyword: 'продавець'
  },
  {
    keyword: 'ручная вишивка',
    localKeyword: 'ручна вишивка'
  },
  {
    keyword: 'строителство',
    localKeyword: 'будівництво'
  },
  {
    keyword: 'водитель електропогрузчика',
    localKeyword: 'водій електронавантажувача'
  },
  {
    keyword: 'инженер еколог',
    localKeyword: 'інженер еколог'
  }
]
