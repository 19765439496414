import { log } from '@alliance/shared/logger'
import { LOCAL_STORAGE, SESSION_STORAGE } from '@ng-web-apis/common'
import { Injector, PLATFORM_ID, Provider } from '@angular/core'
import { isPlatformServer } from '@angular/common'

import { LocalStorage } from '../interfaces/local-storage'
import { CookieStorage } from '../interfaces/cookie-storage'
import { SessionStorage } from '../interfaces/session-storage'

import { CookieStorageImplementation } from '../storages/cookie-storage-implementation'
import { StorageFallback } from '../storages/storage-fallback'

import { localStorageAvailable, sessionStorageAvailable } from './storage.util'

export const getStorageProviders = (): Provider[] => [
  {
    provide: CookieStorage,
    useClass: CookieStorageImplementation
  },
  {
    provide: LocalStorage,
    deps: [Injector, PLATFORM_ID],
    useFactory: (injector: Injector, platformId: object): Storage => {
      if (isPlatformServer(platformId) || localStorageAvailable()) {
        return injector.get(LOCAL_STORAGE)
      } else {
        log.warn({ where: 'shared-storage', category: 'unexpected_value', message: 'LOCAL_STORAGE UNAVAILABLE --> fallback to new Map()' })
        return new StorageFallback()
      }
    }
  },
  {
    provide: SessionStorage,
    deps: [Injector, PLATFORM_ID],
    useFactory: (injector: Injector, platformId: object): Storage => {
      if (isPlatformServer(platformId) || sessionStorageAvailable()) {
        return injector.get(SESSION_STORAGE)
      } else {
        log.warn({ where: 'shared-storage', category: 'unexpected_value', message: 'SESSION_STORAGE UNAVAILABLE --> fallback to new Map()' })
        return new StorageFallback()
      }
    }
  }
]
