import { Duration } from './duration'

export const add = (date: Date, duration: Duration): Date => {
  const result = new Date(date)

  if (duration.years) {
    result.setUTCFullYear(result.getUTCFullYear() + duration.years)
  }
  if (duration.months) {
    result.setUTCMonth(result.getUTCMonth() + duration.months)
  }
  if (duration.weeks) {
    result.setUTCDate(result.getUTCDate() + duration.weeks * 7)
  }
  if (duration.days) {
    result.setUTCDate(result.getUTCDate() + duration.days)
  }
  if (duration.hours) {
    result.setUTCHours(result.getUTCHours() + duration.hours)
  }
  if (duration.minutes) {
    result.setUTCMinutes(result.getUTCMinutes() + duration.minutes)
  }
  if (duration.seconds) {
    result.setUTCSeconds(result.getUTCSeconds() + duration.seconds)
  }

  return result
}
