import { Injectable } from '@angular/core'
import { LoggingToBigQueryService } from '@alliance/shared/utils'
import { Observable, tap } from 'rxjs'
import { map } from 'rxjs/operators'
import { SatisfactionSurveyStatuses, UsersOpenApiSatisfactionSurveysService } from '../../infrastructure'

@Injectable({
  providedIn: 'root'
})
export class UserSatisfactionSurveyService {
  public constructor(private readonly satisfactionSurveysService: UsersOpenApiSatisfactionSurveysService, private readonly bigQueryService: LoggingToBigQueryService) {}

  public isOpen$(): Observable<boolean> {
    return this.satisfactionSurveysService.satisfactionSurveyGet().pipe(map(result => result.status === SatisfactionSurveyStatuses.Open))
  }

  public close$(hasAnswer: boolean): Observable<void> {
    return this.satisfactionSurveysService
      .satisfactionSurveyPost({ body: { status: hasAnswer ? SatisfactionSurveyStatuses.ClosedWithRating : SatisfactionSurveyStatuses.Closed } })
      .pipe(tap(() => this.bigQueryService.pushToDataLayer({})))
  }
}
