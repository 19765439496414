import { RxStateComponent } from '@alliance/shared/models'
import { TransferService } from '@alliance/shared/utils'
import { ChangeDetectionStrategy, Component, Inject, Input } from '@angular/core'
import { animate, AnimationEvent, state, style, transition, trigger } from '@angular/animations'
import { ComponentPortal, TemplatePortal } from '@angular/cdk/portal'
import { BehaviorSubject } from 'rxjs'
import { SANTA_MODAL_REF } from '../../services/modal/modal.tokens'
import { ModalOverlayRef } from '../../services/modal/modal-overlay-ref'

interface ModalState {
  openedState: string // TODO: make me strictly typed
}

@Component({
  selector: 'santa-modal',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.tw.css'],
  animations: [
    trigger('openState', [
      state('void, exit', style({ opacity: '0' })),
      state(
        'opened',
        style({
          opacity: '1'
        })
      ),
      transition('* => *', [animate('300ms cubic-bezier(0.86, 0, 0.07, 1)')])
    ])
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ModalComponent<T> extends RxStateComponent<ModalState> {
  @Input() public content: ComponentPortal<T> | TemplatePortal<T> | null = null

  public readonly closeIconLink = 'https://images.cf-rabota.com.ua/alliance/close-white.svg'

  public openedState$ = new BehaviorSubject<string>('opened')

  // quick fix to trigger view render (connectViewState under the hood calls markDirty to schedule change detection call)
  // TODO: fully re-write to RxStateComponent
  public readonly state = this.connectViewState(['openedState'])

  public constructor(@Inject(SANTA_MODAL_REF) private modalOverlayRef: ModalOverlayRef, protected transfer: TransferService) {
    super()

    this.connect('openedState', this.openedState$)
  }

  public onCloseModalClick(event?: Event): void {
    if (event) {
      event.stopPropagation()
    }

    this.animateCloseModal()
  }

  public onInnerClick(event: Event): void {
    event.stopPropagation()
  }

  public onBackDropClick(event: Event): void {
    event.stopPropagation()
    this.animateCloseModal()
  }

  public animateCloseModal(): void {
    this.openedState$.next('exit')
  }

  public onAnimationEnd(a: AnimationEvent): void {
    if (a.toState === 'exit') {
      this.closeModal()
    }
  }

  protected closeModal(): void {
    this.modalOverlayRef.close()
  }
}
