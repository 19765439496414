<div
  class="
    santa-grid
    santa-grid-cols-1
    santa-w-full
    santa-max-w-full
    santa-h-full
    santa-max-h-full
    santa-overflow-x-hidden
    santa-overflow-y-auto
    santa-bg-white
  "
>
  <ng-container [cdkPortalOutlet]="content"></ng-container>
</div>
