import { Injectable } from '@angular/core'
import { BehaviorSubject, fromEvent, Observable, of } from 'rxjs'
import { filter, map, pluck, take } from 'rxjs/operators'
import { DetectPlatformService } from './detect-platform.service'

@Injectable({
  providedIn: 'root'
})
export class VideoThumbnailsService {
  private youTubeThumbnails = new BehaviorSubject<{ [key: string]: string }>({})

  public constructor(private platform: DetectPlatformService) {}

  public getYoutubeThumbnail(videoId: string): Observable<string> {
    if (this.platform.isServer) {
      return of('')
    }

    this.findValidUrl(`https://i3.ytimg.com/vi/${videoId}/maxresdefault.jpg`).subscribe(url => {
      this.youTubeThumbnails.next({ ...this.youTubeThumbnails.value, [videoId]: url })
    })

    return this.youTubeThumbnails.pipe(
      filter(t => !!t[videoId]),
      pluck(videoId),
      take(1)
    )
  }

  private findValidUrl(imgUrl: string): Observable<string> {
    const img = new Image()
    img.src = imgUrl
    return fromEvent(img, 'load').pipe(
      map((e: Event) => {
        const target = e.target as HTMLImageElement
        if (!target.naturalWidth || !target.src) {
          return imgUrl
        }
        if (target.naturalWidth === 120 && target.src.indexOf('/default.') === -1) {
          img.src = this.replaceImageSource(target.src)
          return ''
        }
        return target.src
      })
    )
  }

  private replaceImageSource(src: string): string {
    const sizeKeys = ['maxresdefault', 'sddefault', 'hqdefault', 'mqdefault', 'default']
    const imageSize = src.match(/(\w+)?default/)
    if (!imageSize) {
      return src
    }
    const size = imageSize.shift() || ''
    return sizeKeys.indexOf(size) >= 0 ? src.replace(size, sizeKeys[sizeKeys.indexOf(size) + 1]) : src
  }
}
