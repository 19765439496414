import { ErrorHandler, ModuleWithProviders, NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'

import { SantaButtonModule } from '@alliance/santa/button'
import { SharedIconsModule } from '@alliance/shared/icons'
import { SharedSantaModule } from '@alliance/shared/santa'
import { SharedTranslationModule } from '@alliance/shared/translation'

import { ConfirmReloadOnChunkLoadErrorComponent } from './confirm-reload-on-chunk-load-error/confirm-reload-on-chunk-load-error.component'

import { LazyLoadHelperService } from './services/lazy-load-helper.service'
import { OnChunkLoadErrorHandler } from './services/on-chunk-load-error.handler'
import { OpenModalHelperService } from './services/open-modal-helper.service'

@NgModule({
  imports: [CommonModule, SharedIconsModule, SharedSantaModule, SharedTranslationModule, SantaButtonModule],
  declarations: [ConfirmReloadOnChunkLoadErrorComponent],
  exports: [ConfirmReloadOnChunkLoadErrorComponent]
})
export class SharedChunkLoadErrorHandlerModule {
  public static forRoot(): ModuleWithProviders<SharedChunkLoadErrorHandlerModule> {
    return {
      ngModule: SharedChunkLoadErrorHandlerModule,
      providers: [
        OpenModalHelperService,
        LazyLoadHelperService,
        {
          provide: ErrorHandler,
          useClass: OnChunkLoadErrorHandler
        }
      ]
    }
  }

  public static forChild(): ModuleWithProviders<SharedChunkLoadErrorHandlerModule> {
    return {
      ngModule: SharedChunkLoadErrorHandlerModule,
      providers: [OpenModalHelperService, LazyLoadHelperService]
    }
  }
}
