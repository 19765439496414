import { Injectable } from '@angular/core'
import { Observable, of } from 'rxjs'
import { map } from 'rxjs/operators'

import { CityDictionaryItemFragment, CityDictionaryService } from '@alliance/shared/domain-gql'

import { DEFAULT_AUTOCOMPLITE_AFTER, DEFAULT_AUTOCOMPLITE_FIRST, FAKE_OPTION_LIST_ID } from './cities-helper-constants'

@Injectable({
  providedIn: 'root'
})
export class CitiesHelperService {
  public constructor(private readonly cityDictionaryService: CityDictionaryService) {}

  public getCities$(): Observable<CityDictionaryItemFragment[]> {
    return this.cityDictionaryService.getCities$()
  }

  public getCitiesByKeyword$(keyword: string, after: string = DEFAULT_AUTOCOMPLITE_AFTER, first: number = DEFAULT_AUTOCOMPLITE_FIRST): Observable<CityDictionaryItemFragment[]> {
    return this.cityDictionaryService.getCitiesByKeyword$(keyword, after, first).pipe(map(list => list.filter(node => !FAKE_OPTION_LIST_ID.includes(node.id || ''))))
  }

  public getCityById$(cityId: string | undefined | null): Observable<CityDictionaryItemFragment | null> {
    return cityId && !FAKE_OPTION_LIST_ID.includes(cityId) ? this.cityDictionaryService.getCityById$(cityId) : of(null)
  }
}
