import { Environment } from '@alliance/shared/environment'
import { log } from '@alliance/shared/logger'
import { CookieStorage } from '@alliance/shared/storage'
import { Inject, Injectable, Optional } from '@angular/core'
import { DOCUMENT } from '@angular/common'
import { REQUEST } from '@nguniversal/express-engine/tokens'
import { Request } from 'express'
import { decodeToken } from './decode-token'
import { DetectPlatformService } from './detect-platform.service'

@Injectable({
  providedIn: 'root'
})
export class GetTokenFromCookiesService {
  private readonly PROD_DOMAIN = 'robota.ua'
  private readonly OLD_PROD_DOMAIN = 'rabota.ua'

  public constructor(
    @Inject(DOCUMENT) private readonly document: Document,
    @Optional() @Inject(REQUEST) private readonly request: Request,
    private cookieStorage: CookieStorage,
    private environment: Environment,
    private platform: DetectPlatformService
  ) {}

  public tokenFromCookie(cookieKey: string): string | null {
    if (this.platform.isServer) {
      return null
    }

    try {
      const isProd = [this.PROD_DOMAIN, this.OLD_PROD_DOMAIN].some(domain => domain === this.environment.domain)
      // CookieStorage (with CookieService) can't get multiple cookies with a same name,
      // so have to use another way for getting cookies for dev & test env-s
      let plainCookies = ''
      try {
        plainCookies = this.request?.headers?.cookie || this.document?.cookie || ''
      } catch (e) {
        log.warn({ where: 'shared-utils: GetTokenFromCookiesService', category: 'try_catch', message: 'tokenFromCookie failed', error: e })
      }

      const token = isProd ? this.cookieStorage.getItem(cookieKey) || null : this.getDevToken(plainCookies)

      return token || null
    } catch (e) {
      log.warn({ where: 'shared-utils: GetTokenFromCookiesService', category: 'try_catch', message: 'tokenFromCookie failed', error: e })
      return null
    }
  }

  private getDevToken(plainCookies: string): string | null {
    const allJwtTokens: string[] =
      plainCookies
        .split(';')
        .filter(value => value.includes('jwt-token='))
        .map(value => value.replace('jwt-token=', '').trim()) || []

    // TODO: remove OLD_PROD_DOMAIN after full domain migration (including jwt token claims)
    return (
      allJwtTokens.filter(_token =>
        [this.environment.domain ?? '', (this.environment.domain ?? '').replace(this.PROD_DOMAIN, this.OLD_PROD_DOMAIN)].some(domain => domain === decodeToken<{ aud?: string }>(_token)?.aud)
      )[0] || null
    )
  }
}
