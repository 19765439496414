type PhoneItem = { raw: string; formatted: string }

export const ukrainianPhoneRegex = /((((\+?3([\s\-‒—])?8|8)+([\s\-‒—])?)?\(?0([\s\-‒—]){0,2}[3-9]([\s\-‒—]){0,2}){1}(\d(\s|([-‒—])|\s(\(|\)|([-‒—]))|(\(|\)|([-‒—]))\s?){0,2}){8,9}(?![\w]))/gim

export const findPhonesInText = (value: string): PhoneItem[] => {
  const cleanedInput = value.replace(/&nbsp;/, ' ')
  let startIndex = 0
  const foundItems = (cleanedInput.match(ukrainianPhoneRegex) ?? []).reduce<PhoneItem[]>((acc, item) => {
    const itemIndex = cleanedInput.indexOf(item, startIndex)
    const valueWithCode = `+38${item.replace(/^((\+?3([\s\-‒—])?8|8)+([\s\-‒—])?)?\(?/, '')}`
    const firstSymbolBeforePhone = cleanedInput[itemIndex - 1]
    const nextSymbolAfterPhone = cleanedInput[itemIndex + item.trim().length]
    const checkBeginningSafe = !firstSymbolBeforePhone || /[^\w\d]/.test(firstSymbolBeforePhone)
    const checkEndingSafe = !nextSymbolAfterPhone || /[^\w\d]/.test(nextSymbolAfterPhone)
    const isPhone = valueWithCode.replace(/[\s\-‒—()]/g, '').length === 13
    const phone = { raw: item.trim(), formatted: valueWithCode.replace(/[\s\-‒—()]/g, '') }

    startIndex = cleanedInput.indexOf(nextSymbolAfterPhone, itemIndex)
    return isPhone && checkEndingSafe && checkBeginningSafe ? [...acc, { ...phone }] : [...acc]
  }, [])

  return foundItems
}
