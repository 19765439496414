import { HttpClient, HttpContext } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { Observable } from 'rxjs'
import { map } from 'rxjs/operators'

import { BaseService } from '../base-service'
import { VacancyApiDataAccessConfiguration } from '../vacancy-api-data-access-configuration'
import { StrictHttpResponse } from '../strict-http-response'

import { vacancyStatisticsSaveGet } from '../fn/vacancy-statistics/vacancy-statistics-save-get'
import { VacancyStatisticsSaveGet$Params } from '../fn/vacancy-statistics/vacancy-statistics-save-get'

@Injectable({ providedIn: 'root' })
export class VacancyApiVacancyStatisticsService extends BaseService {
  constructor(config: VacancyApiDataAccessConfiguration, http: HttpClient) {
    super(config, http)
  }

  /** Path part for operation `vacancyStatisticsSaveGet()` */
  static readonly VacancyStatisticsSaveGetPath = '/vacancy-statistics/save'

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `vacancyStatisticsSaveGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  vacancyStatisticsSaveGet$Response(params?: VacancyStatisticsSaveGet$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return vacancyStatisticsSaveGet(this.http, this.rootUrl, params, context)
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `vacancyStatisticsSaveGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  vacancyStatisticsSaveGet(params?: VacancyStatisticsSaveGet$Params, context?: HttpContext): Observable<void> {
    return this.vacancyStatisticsSaveGet$Response(params, context).pipe(map((r: StrictHttpResponse<void>): void => r.body))
  }
}
