import { Environment } from '@alliance/shared/environment'
import { log } from '@alliance/shared/logger'
import { DetectPlatformService } from '@alliance/shared/utils'
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { Observable } from 'rxjs'
import { AuthService } from '../authentication/auth.service'

@Injectable()
export class LogoutUnauthorizedUsersInterceptor implements HttpInterceptor {
  private readonly whitelist = [this.env.authApi].map(url => {
    try {
      return url ? new URL(url).origin : url
    } catch (e) {
      log.warn({ where: 'auth-api: LogoutUnauthorizedUsersInterceptor', category: 'try_catch', message: 'allowedUrls failed', error: e })
      return ''
    }
  })

  public constructor(private platform: DetectPlatformService, private authService: AuthService, private env: Environment) {}

  public intercept(req: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    if (this.platform.isServer) {
      return next.handle(req)
    }

    let requestUrlOrigin = ''

    try {
      requestUrlOrigin = new URL(req.url).origin
    } catch (e) {
      log.log({ where: 'auth-api: LogoutUnauthorizedUsersInterceptor', category: 'try_catch', message: 'intercept: getting requestUrlOrigin failed', error: e })
    }

    return !this.whitelist.includes(requestUrlOrigin) ? next.handle(req).pipe(this.authService.logoutOnAuthError()) : next.handle(req)
  }
}
