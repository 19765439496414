import { log } from '@alliance/shared/logger'
import { differenceInMonths, parseISO } from 'date-fns'

/**
 * Calculates the period between two dates in months and years.
 * @param  dateStart - The starting date.
 * @param  dateEnd - The optional ending date. If not provided, the current date is used.
 * @returns Returns an object containing the difference in months and years, or null if there's an error or if the start date is not provided.
 */
// FIXME: it wont ever return null
// FIXME: after adding types internals may be rewritten
export const getExperiencePeriod = (dateStart: Date, dateEnd?: Date): { months: number; years: number } | null => {
  if (!dateStart) {
    return null
  }
  try {
    const _formatDate = (date: Date | number): Date | number => (typeof date === 'string' ? parseISO(date) : date)
    const diffInMonths = differenceInMonths(_formatDate(dateEnd || Date.now()), _formatDate(dateStart))
    const years = Math.floor((diffInMonths + 1) / 12)
    const months = (diffInMonths + 1) % 12

    return {
      months,
      years
    }
  } catch (e) {
    log.error({ where: 'shared-datetime', category: 'try_catch', message: 'getExperiencePeriod failed', error: e })
    return null
  }
}
