import { Injectable } from '@angular/core'
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http'
import { BaseService as __BaseService } from '../base-service'
import { JobseekerDataAccessConfiguration as __Configuration } from '../jobseeker-data-access-configuration'
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response'
import { Observable as __Observable } from 'rxjs'
import { map as __map, filter as __filter } from 'rxjs/operators'

import { CompanyInfo } from '../models/company-info'
import { UpdateCompanyRequest } from '../models/update-company-request'
import { CompanyMiniProfileDto } from '../models/company-mini-profile-dto'
import { VacancySearchResponse } from '../models/vacancy-search-response'
import { CompanyVacanciesResponse } from '../models/company-vacancies-response'
import { FullCompanyCustomDesignResponse } from '../models/full-company-custom-design-response'
import { CompanyAdditionalPage } from '../models/company-additional-page'
import { CompanyInRubricDto } from '../models/company-in-rubric-dto'
import { FilteredCompanies } from '../models/filtered-companies'
import { CompanyVipInfo } from '../models/company-vip-info'
@Injectable({
  providedIn: 'root'
})
class CompanyService extends __BaseService {
  public static readonly companyGetPath = '/company/{id}'
  public static readonly companyUpdatePath = '/company/facebook-link'
  public static readonly companyGetMiniProfilePath = '/companies/{notebookId}/mini-profile'
  public static readonly companyGetListPath = '/company/{id}/vacancies'
  public static readonly companyGetCompanyPublishedVacanciesPath = '/companies/{notebookId}/published-vacancies'
  public static readonly companyGetDesignPath = '/company/{id}/customdesign'
  public static readonly companyGetCustomDesignElementsPath = '/companies/{notebookId}/full-custom-design'
  public static readonly companyGetCustomPagesDesignElementsPath = '/companies/{notebookId}/additional-pages'
  public static readonly companyGetCompaniesInRubricPath = '/companies'
  public static readonly companyGetFilteredCompaniesPath = '/companies/filter'
  public static readonly companyGetVipCompaniesPath = '/companies/vip'
  public static readonly companyGetPublishedCompanyCountPath = '/companies/count'
  public static readonly companyTrackCompanyViewPath = '/company/{id}/track'

  public constructor(config: __Configuration, http: HttpClient) {
    super(config, http)
  }

  /**
   * @param id undefined
   * @return OK
   */
  companyGetResponse(id: number): __Observable<__StrictHttpResponse<CompanyInfo>> {
    let __params = this.newParams()
    let __headers = new HttpHeaders()
    let __body: any = null

    let req = new HttpRequest<any>('GET', this.rootUrl + `/company/${id}`, __body, {
      headers: __headers,
      params: __params,
      responseType: 'json'
    })

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map(_r => {
        return _r as __StrictHttpResponse<CompanyInfo>
      })
    )
  }

  /**
   * @param id undefined
   * @return OK
   */
  companyGet(id: number): __Observable<CompanyInfo> {
    return this.companyGetResponse(id).pipe(__map(_r => _r.body as CompanyInfo))
  }

  /**
   * @param request undefined
   * @return OK
   */
  companyUpdateResponse(request: UpdateCompanyRequest): __Observable<__StrictHttpResponse<{}>> {
    let __params = this.newParams()
    let __headers = new HttpHeaders()
    let __body: any = null
    __body = request
    let req = new HttpRequest<any>('PATCH', this.rootUrl + `/company/facebook-link`, __body, {
      headers: __headers,
      params: __params,
      responseType: 'json'
    })

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map(_r => {
        return _r as __StrictHttpResponse<{}>
      })
    )
  }

  /**
   * @param request undefined
   * @return OK
   */
  companyUpdate(request: UpdateCompanyRequest): __Observable<{}> {
    return this.companyUpdateResponse(request).pipe(__map(_r => _r.body as {}))
  }

  /**
   * @param notebookId undefined
   * @return OK
   */
  companyGetMiniProfileResponse(notebookId: number): __Observable<__StrictHttpResponse<CompanyMiniProfileDto>> {
    let __params = this.newParams()
    let __headers = new HttpHeaders()
    let __body: any = null

    let req = new HttpRequest<any>('GET', this.rootUrl + `/companies/${notebookId}/mini-profile`, __body, {
      headers: __headers,
      params: __params,
      responseType: 'json'
    })

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map(_r => {
        return _r as __StrictHttpResponse<CompanyMiniProfileDto>
      })
    )
  }

  /**
   * @param notebookId undefined
   * @return OK
   */
  companyGetMiniProfile(notebookId: number): __Observable<CompanyMiniProfileDto> {
    return this.companyGetMiniProfileResponse(notebookId).pipe(__map(_r => _r.body as CompanyMiniProfileDto))
  }

  /**
   * @param params The `CompanyService.CompanyGetListParams` containing the following parameters:
   *
   * - `id`:
   *
   * - `start`:
   *
   * - `count`:
   *
   * @return OK
   */
  companyGetListResponse(params: CompanyService.CompanyGetListParams): __Observable<__StrictHttpResponse<VacancySearchResponse>> {
    let __params = this.newParams()
    let __headers = new HttpHeaders()
    let __body: any = null

    if (params.start != null) __params = __params.set('start', params.start.toString())
    if (params.count != null) __params = __params.set('count', params.count.toString())
    let req = new HttpRequest<any>('GET', this.rootUrl + `/company/${params.id}/vacancies`, __body, {
      headers: __headers,
      params: __params,
      responseType: 'json'
    })

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map(_r => {
        return _r as __StrictHttpResponse<VacancySearchResponse>
      })
    )
  }

  /**
   * @param params The `CompanyService.CompanyGetListParams` containing the following parameters:
   *
   * - `id`:
   *
   * - `start`:
   *
   * - `count`:
   *
   * @return OK
   */
  companyGetList(params: CompanyService.CompanyGetListParams): __Observable<VacancySearchResponse> {
    return this.companyGetListResponse(params).pipe(__map(_r => _r.body as VacancySearchResponse))
  }

  /**
   * @param params The `CompanyService.CompanyGetCompanyPublishedVacanciesParams` containing the following parameters:
   *
   * - `notebookId`:
   *
   * - `start`:
   *
   * - `rubricId`:
   *
   * - `count`:
   *
   * - `cityId`:
   *
   * @return OK
   */
  companyGetCompanyPublishedVacanciesResponse(params: CompanyService.CompanyGetCompanyPublishedVacanciesParams): __Observable<__StrictHttpResponse<CompanyVacanciesResponse>> {
    let __params = this.newParams()
    let __headers = new HttpHeaders()
    let __body: any = null

    if (params.start != null) __params = __params.set('start', params.start.toString())
    if (params.rubricId != null) __params = __params.set('rubricId', params.rubricId.toString())
    if (params.count != null) __params = __params.set('count', params.count.toString())
    if (params.cityId != null) __params = __params.set('cityId', params.cityId.toString())
    let req = new HttpRequest<any>('GET', this.rootUrl + `/companies/${params.notebookId}/published-vacancies`, __body, {
      headers: __headers,
      params: __params,
      responseType: 'json'
    })

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map(_r => {
        return _r as __StrictHttpResponse<CompanyVacanciesResponse>
      })
    )
  }

  /**
   * @param params The `CompanyService.CompanyGetCompanyPublishedVacanciesParams` containing the following parameters:
   *
   * - `notebookId`:
   *
   * - `start`:
   *
   * - `rubricId`:
   *
   * - `count`:
   *
   * - `cityId`:
   *
   * @return OK
   */
  companyGetCompanyPublishedVacancies(params: CompanyService.CompanyGetCompanyPublishedVacanciesParams): __Observable<CompanyVacanciesResponse> {
    return this.companyGetCompanyPublishedVacanciesResponse(params).pipe(__map(_r => _r.body as CompanyVacanciesResponse))
  }

  /**
   * @param id undefined
   * @return OK
   */
  companyGetDesignResponse(id: number): __Observable<__StrictHttpResponse<string>> {
    let __params = this.newParams()
    let __headers = new HttpHeaders()
    let __body: any = null

    let req = new HttpRequest<any>('GET', this.rootUrl + `/company/${id}/customdesign`, __body, {
      headers: __headers,
      params: __params,
      responseType: 'json'
    })

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map(_r => {
        return _r as __StrictHttpResponse<string>
      })
    )
  }

  /**
   * @param id undefined
   * @return OK
   */
  companyGetDesign(id: number): __Observable<string> {
    return this.companyGetDesignResponse(id).pipe(__map(_r => _r.body as string))
  }

  /**
   * @param params The `CompanyService.CompanyGetCustomDesignElementsParams` containing the following parameters:
   *
   * - `notebookId`:
   *
   * - `visibleLink`:
   *
   * @return OK
   */
  companyGetCustomDesignElementsResponse(params: CompanyService.CompanyGetCustomDesignElementsParams): __Observable<__StrictHttpResponse<FullCompanyCustomDesignResponse>> {
    let __params = this.newParams()
    let __headers = new HttpHeaders()
    let __body: any = null

    if (params.visibleLink != null) __params = __params.set('visibleLink', params.visibleLink.toString())
    let req = new HttpRequest<any>('GET', this.rootUrl + `/companies/${params.notebookId}/full-custom-design`, __body, {
      headers: __headers,
      params: __params,
      responseType: 'json'
    })

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map(_r => {
        return _r as __StrictHttpResponse<FullCompanyCustomDesignResponse>
      })
    )
  }

  /**
   * @param params The `CompanyService.CompanyGetCustomDesignElementsParams` containing the following parameters:
   *
   * - `notebookId`:
   *
   * - `visibleLink`:
   *
   * @return OK
   */
  companyGetCustomDesignElements(params: CompanyService.CompanyGetCustomDesignElementsParams): __Observable<FullCompanyCustomDesignResponse> {
    return this.companyGetCustomDesignElementsResponse(params).pipe(__map(_r => _r.body as FullCompanyCustomDesignResponse))
  }

  /**
   * @param notebookId undefined
   * @return OK
   */
  companyGetCustomPagesDesignElementsResponse(notebookId: number): __Observable<__StrictHttpResponse<Array<CompanyAdditionalPage>>> {
    let __params = this.newParams()
    let __headers = new HttpHeaders()
    let __body: any = null

    let req = new HttpRequest<any>('GET', this.rootUrl + `/companies/${notebookId}/additional-pages`, __body, {
      headers: __headers,
      params: __params,
      responseType: 'json'
    })

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map(_r => {
        return _r as __StrictHttpResponse<Array<CompanyAdditionalPage>>
      })
    )
  }

  /**
   * @param notebookId undefined
   * @return OK
   */
  companyGetCustomPagesDesignElements(notebookId: number): __Observable<Array<CompanyAdditionalPage>> {
    return this.companyGetCustomPagesDesignElementsResponse(notebookId).pipe(__map(_r => _r.body as Array<CompanyAdditionalPage>))
  }

  /**
   * @param params The `CompanyService.CompanyGetCompaniesInRubricParams` containing the following parameters:
   *
   * - `rubricId`:
   *
   * - `keyword`:
   *
   * @return OK
   */
  companyGetCompaniesInRubricResponse(params: CompanyService.CompanyGetCompaniesInRubricParams): __Observable<__StrictHttpResponse<Array<CompanyInRubricDto>>> {
    let __params = this.newParams()
    let __headers = new HttpHeaders()
    let __body: any = null
    if (params.rubricId != null) __params = __params.set('rubricId', params.rubricId.toString())
    if (params.keyword != null) __params = __params.set('keyword', params.keyword.toString())
    let req = new HttpRequest<any>('GET', this.rootUrl + `/companies`, __body, {
      headers: __headers,
      params: __params,
      responseType: 'json'
    })

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map(_r => {
        return _r as __StrictHttpResponse<Array<CompanyInRubricDto>>
      })
    )
  }

  /**
   * @param params The `CompanyService.CompanyGetCompaniesInRubricParams` containing the following parameters:
   *
   * - `rubricId`:
   *
   * - `keyword`:
   *
   * @return OK
   */
  companyGetCompaniesInRubric(params: CompanyService.CompanyGetCompaniesInRubricParams): __Observable<Array<CompanyInRubricDto>> {
    return this.companyGetCompaniesInRubricResponse(params).pipe(__map(_r => _r.body as Array<CompanyInRubricDto>))
  }

  /**
   * @param params The `CompanyService.CompanyGetFilteredCompaniesParams` containing the following parameters:
   *
   * - `request.onlyWithPublishedVacancies`:
   *
   * - `request.offset`:
   *
   * - `request.limit`:
   *
   * - `request.firstLetter`:
   *
   * - `request.branchIds`:
   *
   * @return OK
   */
  companyGetFilteredCompaniesResponse(params: CompanyService.CompanyGetFilteredCompaniesParams): __Observable<__StrictHttpResponse<FilteredCompanies>> {
    let __params = this.newParams()
    let __headers = new HttpHeaders()
    let __body: any = null
    if (params.requestOnlyWithPublishedVacancies != null) __params = __params.set('request.onlyWithPublishedVacancies', params.requestOnlyWithPublishedVacancies.toString())
    if (params.requestOffset != null) __params = __params.set('request.offset', params.requestOffset.toString())
    if (params.requestLimit != null) __params = __params.set('request.limit', params.requestLimit.toString())
    if (params.requestFirstLetter != null) __params = __params.set('request.firstLetter', params.requestFirstLetter.toString())
    ;(params.requestBranchIds || []).forEach(val => {
      if (val != null) __params = __params.append('request.branchIds', val.toString())
    })
    let req = new HttpRequest<any>('GET', this.rootUrl + `/companies/filter`, __body, {
      headers: __headers,
      params: __params,
      responseType: 'json'
    })

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map(_r => {
        return _r as __StrictHttpResponse<FilteredCompanies>
      })
    )
  }

  /**
   * @param params The `CompanyService.CompanyGetFilteredCompaniesParams` containing the following parameters:
   *
   * - `request.onlyWithPublishedVacancies`:
   *
   * - `request.offset`:
   *
   * - `request.limit`:
   *
   * - `request.firstLetter`:
   *
   * - `request.branchIds`:
   *
   * @return OK
   */
  companyGetFilteredCompanies(params: CompanyService.CompanyGetFilteredCompaniesParams): __Observable<FilteredCompanies> {
    return this.companyGetFilteredCompaniesResponse(params).pipe(__map(_r => _r.body as FilteredCompanies))
  }

  /**
   * @param channels undefined
   * @return OK
   */
  companyGetVipCompaniesResponse(
    channels: Array<'None' | 'FinanceBank' | 'FinanceInvest' | 'FinanceInsurance' | 'FinanceMain' | 'ITMain' | 'PharmaMain' | 'PharmaSecond' | 'StudentMain' | 'StudentCareer' | 'StudentJob'>
  ): __Observable<__StrictHttpResponse<Array<CompanyVipInfo>>> {
    let __params = this.newParams()
    let __headers = new HttpHeaders()
    let __body: any = null
    ;(channels || []).forEach(val => {
      if (val != null) __params = __params.append('channels', val.toString())
    })
    let req = new HttpRequest<any>('GET', this.rootUrl + `/companies/vip`, __body, {
      headers: __headers,
      params: __params,
      responseType: 'json'
    })

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map(_r => {
        return _r as __StrictHttpResponse<Array<CompanyVipInfo>>
      })
    )
  }

  /**
   * @param channels undefined
   * @return OK
   */
  companyGetVipCompanies(
    channels: Array<'None' | 'FinanceBank' | 'FinanceInvest' | 'FinanceInsurance' | 'FinanceMain' | 'ITMain' | 'PharmaMain' | 'PharmaSecond' | 'StudentMain' | 'StudentCareer' | 'StudentJob'>
  ): __Observable<Array<CompanyVipInfo>> {
    return this.companyGetVipCompaniesResponse(channels).pipe(__map(_r => _r.body as Array<CompanyVipInfo>))
  }

  /**
   * @return OK
   */
  companyGetPublishedCompanyCountResponse(): __Observable<__StrictHttpResponse<number>> {
    let __params = this.newParams()
    let __headers = new HttpHeaders()
    let __body: any = null
    let req = new HttpRequest<any>('GET', this.rootUrl + `/companies/count`, __body, {
      headers: __headers,
      params: __params,
      responseType: 'json'
    })

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map(_r => {
        return (_r as HttpResponse<any>).clone({
          body: parseFloat((_r as HttpResponse<any>).body as string)
        }) as __StrictHttpResponse<number>
      })
    )
  }

  /**
   * @return OK
   */
  companyGetPublishedCompanyCount(): __Observable<number> {
    return this.companyGetPublishedCompanyCountResponse().pipe(__map(_r => _r.body as number))
  }

  /**
   * @param id undefined
   */
  companyTrackCompanyViewResponse(id: number): __Observable<__StrictHttpResponse<any>> {
    let __params = this.newParams()
    let __headers = new HttpHeaders()
    let __body: any = null

    let req = new HttpRequest<any>('POST', this.rootUrl + `/company/${id}/track`, __body, {
      headers: __headers,
      params: __params,
      responseType: 'json'
    })

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map(_r => {
        return _r as __StrictHttpResponse<any>
      })
    )
  }

  /**
   * @param id undefined
   */
  companyTrackCompanyView(id: number): __Observable<any> {
    return this.companyTrackCompanyViewResponse(id).pipe(__map(_r => _r.body as any))
  }
}

module CompanyService {
  /**
   * Parameters for CompanyGetList
   */
  export interface CompanyGetListParams {
    id: number
    start?: number
    count?: number
  }

  /**
   * Parameters for CompanyGetCompanyPublishedVacancies
   */
  export interface CompanyGetCompanyPublishedVacanciesParams {
    notebookId: number
    start?: number
    rubricId?: number
    count?: number
    cityId?: number
  }

  /**
   * Parameters for CompanyGetCustomDesignElements
   */
  export interface CompanyGetCustomDesignElementsParams {
    notebookId: number
    visibleLink?: string
  }

  /**
   * Parameters for CompanyGetCompaniesInRubric
   */
  export interface CompanyGetCompaniesInRubricParams {
    rubricId?: number
    keyword?: string
  }

  /**
   * Parameters for CompanyGetFilteredCompanies
   */
  export interface CompanyGetFilteredCompaniesParams {
    requestOnlyWithPublishedVacancies?: boolean
    requestOffset?: number
    requestLimit?: number
    requestFirstLetter?: string
    requestBranchIds?: Array<number>
  }
}

export { CompanyService }
