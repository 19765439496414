import { Injectable } from '@angular/core'
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http'
import { Observable } from 'rxjs'
import { Environment } from '@alliance/shared/environment'
import { TranslationService } from '@alliance/shared/translation'

@Injectable()
export class JobseekerApiPrefixerInterceptor implements HttpInterceptor {
  public constructor(private environment: Environment, private translationService: TranslationService) {}

  public intercept(req: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    if (req.url.indexOf(this.environment.jobseekerApi ?? '') > -1 && this.environment.jobseekerApiUk && this.translationService.currentLangIsUkrainian()) {
      req = req.clone({
        url: req.url.replace(this.environment.jobseekerApi ?? '', this.environment.jobseekerApiUk)
      })
    }
    return next.handle(req)
  }
}
