import { Injectable } from '@angular/core'
import { CanActivate, Router, UrlTree } from '@angular/router'
import { Observable, of } from 'rxjs'
import { catchError, map } from 'rxjs/operators'
import { RedirectUrlService } from '../services/redirect-url.service'
import { ResumesService } from '../services/resumes.service'

@Injectable()
export class ApplyRedirectGuard implements CanActivate {
  public constructor(private redirectUrlService: RedirectUrlService, private router: Router, private resumesService: ResumesService) {}

  public canActivate(): Observable<boolean | UrlTree> {
    if (this.redirectUrlService.getRedirectUrl()) {
      return this.resumesService.getFullProfResume$().pipe(
        map(list => {
          if (!!this.redirectUrlService.getRedirectUrl() && !list.length) {
            this.redirectUrlService.resetRedirectUrl()
            return true
          }
          if (this.redirectUrlService.getRedirectUrl()) {
            this.router.navigateByUrl(this.redirectUrlService.getRedirectUrl())
            this.redirectUrlService.resetRedirectUrl()
            return false
          }
          return true
        }),
        catchError(() => of(true))
      )
    }

    return of(true)
  }
}
