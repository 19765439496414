import { Injectable } from '@angular/core'
import { CanActivate, Router } from '@angular/router'
import { Observable } from 'rxjs'
import { AuthService } from '@alliance/shared/auth/api'
import { LoginService } from '@alliance/shared/login/api'

@Injectable({
  providedIn: 'root'
})
export class OnlyLoggedInUsersGuard implements CanActivate {
  public constructor(private authService: AuthService, private loginService: LoginService, private router: Router) {}

  public canActivate(): Observable<boolean> | Promise<boolean> | boolean {
    if (!this.authService.user) {
      this.router.navigate(['/'])
      this.loginService.goToLogin()
      return false
    }

    return true
  }
}
