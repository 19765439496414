import { Injectable } from '@angular/core'
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http'
import { BaseService as __BaseService } from '../base-service'
import { EmployerDataAccessConfiguration as __Configuration } from '../employer-data-access-configuration'
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response'
import { Observable as __Observable } from 'rxjs'
import { map as __map, filter as __filter } from 'rxjs/operators'

import { VacancyListResponse } from '../models/vacancy-list-response'
import { VacancyListRequest } from '../models/vacancy-list-request'
import { VacancyInfo } from '../models/vacancy-info'
import { CommonResponse } from '../models/common-response'
import { CityInfo } from '../models/city-info'
import { VacancyFilter } from '../models/vacancy-filter'
import { VacancyStatistic } from '../models/vacancy-statistic'
import { VacancyAddResponse } from '../models/vacancy-add-response'
import { VacancyAddRequest } from '../models/vacancy-add-request'
import { VacancyRepublishRequest } from '../models/vacancy-republish-request'
import { ControlQuestion } from '../models/control-question'
import { ControlQuestionTemplate } from '../models/control-question-template'
import { VacancyCount } from '../models/vacancy-count'
import { VacancyCountByStateViewModel } from '../models/vacancy-count-by-state-view-model'
import { VacancyOffer } from '../models/vacancy-offer'
import { SendOfferMessageRequest } from '../models/send-offer-message-request'
@Injectable({
  providedIn: 'root'
})
class VacancyService extends __BaseService {
  public static readonly getVacanciesPath = '/vacancy/list'
  public static readonly getVacancyPath = '/vacancy/get/{id}'
  public static readonly getVacancy_1Path = '/vacancy/get/{id}'
  public static readonly setStatePath = '/vacancy/state/{id}'
  public static readonly makeHotPath = '/vacancy/makehot/{id}'
  public static readonly vacancyEndingTypePath = '/vacancy/endingtype/{id}'
  public static readonly cityListPath = '/vacancy/citylist'
  public static readonly filterListPath = '/vacancy/selectlist'
  public static readonly vacancyStatisticPath = '/vacancy/statistic'
  public static readonly addPath = '/vacancy/add'
  public static readonly republishNewTicketPath = '/vacancy/republish'
  public static readonly updateAndRepublishPath = '/vacancy/republish'
  public static readonly questionAddPath = '/vacancy/question/add'
  public static readonly questionDeletePath = '/vacancy/question/{id}'
  public static readonly questionGetPath = '/vacancy/question/{vacancyId}'
  public static readonly questionTemplateGetPath = '/vacancy/question/template/{type}'
  public static readonly rubric1ByPositionPath = '/vacancy/rubricbyposition'
  public static readonly vacancyCounterPath = '/vacancy/counter'
  public static readonly vacancyCounterByStatePath = '/vacancy/counterbystate'
  public static readonly getOfferPath = '/vacancy/lastoffer'
  public static readonly addOfferPath = '/vacancy/offer'

  public constructor(config: __Configuration, http: HttpClient) {
    super(config, http)
  }

  /**
   * @param request undefined
   * @return Success
   */
  getVacanciesResponse(request?: VacancyListRequest): __Observable<__StrictHttpResponse<VacancyListResponse>> {
    let __params = this.newParams()
    let __headers = new HttpHeaders()
    let __body: any = null
    __body = request
    let req = new HttpRequest<any>('POST', this.rootUrl + `/vacancy/list`, __body, {
      headers: __headers,
      params: __params,
      responseType: 'json'
    })

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map(_r => {
        return _r as __StrictHttpResponse<VacancyListResponse>
      })
    )
  }

  /**
   * @param request undefined
   * @return Success
   */
  getVacancies(request?: VacancyListRequest): __Observable<VacancyListResponse> {
    return this.getVacanciesResponse(request).pipe(__map(_r => _r.body as VacancyListResponse))
  }

  /**
   * @param params The `VacancyService.GetVacancyParams` containing the following parameters:
   *
   * - `id`:
   *
   * - `isNewAccount`:
   *
   * @return Success
   */
  getVacancyResponse(params: VacancyService.GetVacancyParams): __Observable<__StrictHttpResponse<VacancyInfo>> {
    let __params = this.newParams()
    let __headers = new HttpHeaders()
    let __body: any = null

    if (params.isNewAccount != null) __params = __params.set('isNewAccount', params.isNewAccount.toString())
    let req = new HttpRequest<any>('GET', this.rootUrl + `/vacancy/get/${params.id}`, __body, {
      headers: __headers,
      params: __params,
      responseType: 'json'
    })

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map(_r => {
        return _r as __StrictHttpResponse<VacancyInfo>
      })
    )
  }

  /**
   * @param params The `VacancyService.GetVacancyParams` containing the following parameters:
   *
   * - `id`:
   *
   * - `isNewAccount`:
   *
   * @return Success
   */
  getVacancy(params: VacancyService.GetVacancyParams): __Observable<VacancyInfo> {
    return this.getVacancyResponse(params).pipe(__map(_r => _r.body as VacancyInfo))
  }

  /**
   * @param params The `VacancyService.GetVacancy_1Params` containing the following parameters:
   *
   * - `id`:
   *
   * - `isNewAccount`:
   *
   * @return Success
   */
  getVacancy_1Response(params: VacancyService.GetVacancy_1Params): __Observable<__StrictHttpResponse<VacancyInfo>> {
    let __params = this.newParams()
    let __headers = new HttpHeaders()
    let __body: any = null

    if (params.isNewAccount != null) __params = __params.set('isNewAccount', params.isNewAccount.toString())
    let req = new HttpRequest<any>('POST', this.rootUrl + `/vacancy/get/${params.id}`, __body, {
      headers: __headers,
      params: __params,
      responseType: 'json'
    })

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map(_r => {
        return _r as __StrictHttpResponse<VacancyInfo>
      })
    )
  }

  /**
   * @param params The `VacancyService.GetVacancy_1Params` containing the following parameters:
   *
   * - `id`:
   *
   * - `isNewAccount`:
   *
   * @return Success
   */
  getVacancy_1(params: VacancyService.GetVacancy_1Params): __Observable<VacancyInfo> {
    return this.getVacancy_1Response(params).pipe(__map(_r => _r.body as VacancyInfo))
  }

  /**
   * @param params The `VacancyService.SetStateParams` containing the following parameters:
   *
   * - `id`:
   *
   * - `state`:
   *
   * @return Success
   */
  setStateResponse(params: VacancyService.SetStateParams): __Observable<__StrictHttpResponse<CommonResponse>> {
    let __params = this.newParams()
    let __headers = new HttpHeaders()
    let __body: any = null

    if (params.state != null) __params = __params.set('state', params.state.toString())
    let req = new HttpRequest<any>('POST', this.rootUrl + `/vacancy/state/${params.id}`, __body, {
      headers: __headers,
      params: __params,
      responseType: 'json'
    })

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map(_r => {
        return _r as __StrictHttpResponse<CommonResponse>
      })
    )
  }

  /**
   * @param params The `VacancyService.SetStateParams` containing the following parameters:
   *
   * - `id`:
   *
   * - `state`:
   *
   * @return Success
   */
  setState(params: VacancyService.SetStateParams): __Observable<CommonResponse> {
    return this.setStateResponse(params).pipe(__map(_r => _r.body as CommonResponse))
  }

  /**
   * @param params The `VacancyService.MakeHotParams` containing the following parameters:
   *
   * - `id`:
   *
   * - `weeks`:
   *
   * - `additional`:
   *
   * @return Success
   */
  makeHotResponse(params: VacancyService.MakeHotParams): __Observable<__StrictHttpResponse<CommonResponse>> {
    let __params = this.newParams()
    let __headers = new HttpHeaders()
    let __body: any = null

    if (params.weeks != null) __params = __params.set('weeks', params.weeks.toString())
    if (params.additional != null) __params = __params.set('additional', params.additional.toString())
    let req = new HttpRequest<any>('POST', this.rootUrl + `/vacancy/makehot/${params.id}`, __body, {
      headers: __headers,
      params: __params,
      responseType: 'json'
    })

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map(_r => {
        return _r as __StrictHttpResponse<CommonResponse>
      })
    )
  }

  /**
   * @param params The `VacancyService.MakeHotParams` containing the following parameters:
   *
   * - `id`:
   *
   * - `weeks`:
   *
   * - `additional`:
   *
   * @return Success
   */
  makeHot(params: VacancyService.MakeHotParams): __Observable<CommonResponse> {
    return this.makeHotResponse(params).pipe(__map(_r => _r.body as CommonResponse))
  }

  /**
   * @param params The `VacancyService.VacancyEndingTypeParams` containing the following parameters:
   *
   * - `id`:
   *
   * - `type`:
   *
   * - `onlyMonth`:
   *
   * @return Success
   */
  vacancyEndingTypeResponse(params: VacancyService.VacancyEndingTypeParams): __Observable<__StrictHttpResponse<CommonResponse>> {
    let __params = this.newParams()
    let __headers = new HttpHeaders()
    let __body: any = null

    if (params.type != null) __params = __params.set('type', params.type.toString())
    if (params.onlyMonth != null) __params = __params.set('onlyMonth', params.onlyMonth.toString())
    let req = new HttpRequest<any>('POST', this.rootUrl + `/vacancy/endingtype/${params.id}`, __body, {
      headers: __headers,
      params: __params,
      responseType: 'json'
    })

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map(_r => {
        return _r as __StrictHttpResponse<CommonResponse>
      })
    )
  }

  /**
   * @param params The `VacancyService.VacancyEndingTypeParams` containing the following parameters:
   *
   * - `id`:
   *
   * - `type`:
   *
   * - `onlyMonth`:
   *
   * @return Success
   */
  vacancyEndingType(params: VacancyService.VacancyEndingTypeParams): __Observable<CommonResponse> {
    return this.vacancyEndingTypeResponse(params).pipe(__map(_r => _r.body as CommonResponse))
  }

  /**
   * @return Success
   */
  cityListResponse(): __Observable<__StrictHttpResponse<Array<CityInfo>>> {
    let __params = this.newParams()
    let __headers = new HttpHeaders()
    let __body: any = null
    let req = new HttpRequest<any>('GET', this.rootUrl + `/vacancy/citylist`, __body, {
      headers: __headers,
      params: __params,
      responseType: 'json'
    })

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map(_r => {
        return _r as __StrictHttpResponse<Array<CityInfo>>
      })
    )
  }

  /**
   * @return Success
   */
  cityList(): __Observable<Array<CityInfo>> {
    return this.cityListResponse().pipe(__map(_r => _r.body as Array<CityInfo>))
  }

  /**
   * @param multiUserId undefined
   * @return Success
   */
  filterListResponse(multiUserId?: number): __Observable<__StrictHttpResponse<Array<VacancyFilter>>> {
    let __params = this.newParams()
    let __headers = new HttpHeaders()
    let __body: any = null
    if (multiUserId != null) __params = __params.set('multiUserId', multiUserId.toString())
    let req = new HttpRequest<any>('GET', this.rootUrl + `/vacancy/selectlist`, __body, {
      headers: __headers,
      params: __params,
      responseType: 'json'
    })

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map(_r => {
        return _r as __StrictHttpResponse<Array<VacancyFilter>>
      })
    )
  }

  /**
   * @param multiUserId undefined
   * @return Success
   */
  filterList(multiUserId?: number): __Observable<Array<VacancyFilter>> {
    return this.filterListResponse(multiUserId).pipe(__map(_r => _r.body as Array<VacancyFilter>))
  }

  /**
   * @param params The `VacancyService.VacancyStatisticParams` containing the following parameters:
   *
   * - `vacancyId`:
   *
   * - `period`:
   *
   * @return Success
   */
  vacancyStatisticResponse(params: VacancyService.VacancyStatisticParams): __Observable<__StrictHttpResponse<Array<VacancyStatistic>>> {
    let __params = this.newParams()
    let __headers = new HttpHeaders()
    let __body: any = null
    if (params.vacancyId != null) __params = __params.set('vacancyId', params.vacancyId.toString())
    if (params.period != null) __params = __params.set('period', params.period.toString())
    let req = new HttpRequest<any>('GET', this.rootUrl + `/vacancy/statistic`, __body, {
      headers: __headers,
      params: __params,
      responseType: 'json'
    })

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map(_r => {
        return _r as __StrictHttpResponse<Array<VacancyStatistic>>
      })
    )
  }

  /**
   * @param params The `VacancyService.VacancyStatisticParams` containing the following parameters:
   *
   * - `vacancyId`:
   *
   * - `period`:
   *
   * @return Success
   */
  vacancyStatistic(params: VacancyService.VacancyStatisticParams): __Observable<Array<VacancyStatistic>> {
    return this.vacancyStatisticResponse(params).pipe(__map(_r => _r.body as Array<VacancyStatistic>))
  }

  /**
   * Below is an example of vacancy creation request with a minimum sufficient set of fields:
   *
   *     {
   *        "id": 0,
   *        "name": "Your vacancy title",
   *        "description": "Your vacancy description",
   *        "cityId": 1,
   *        "rubricList": [25],
   *        "salary": 15000,
   *        "publishType": "Professional",
   *        "designId": 321,
   *        "sendResumeType": "EmailAndCvArchive",
   *        "contactEMail": "abc@gmail.com",
   *        "endingType": "AutoRepublish"
   *     }
   * @param vacancy undefined
   * @return Success
   */
  addResponse(vacancy?: VacancyAddRequest): __Observable<__StrictHttpResponse<VacancyAddResponse>> {
    let __params = this.newParams()
    let __headers = new HttpHeaders()
    let __body: any = null
    __body = vacancy
    let req = new HttpRequest<any>('POST', this.rootUrl + `/vacancy/add`, __body, {
      headers: __headers,
      params: __params,
      responseType: 'json'
    })

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map(_r => {
        return _r as __StrictHttpResponse<VacancyAddResponse>
      })
    )
  }

  /**
   * Below is an example of vacancy creation request with a minimum sufficient set of fields:
   *
   *     {
   *        "id": 0,
   *        "name": "Your vacancy title",
   *        "description": "Your vacancy description",
   *        "cityId": 1,
   *        "rubricList": [25],
   *        "salary": 15000,
   *        "publishType": "Professional",
   *        "designId": 321,
   *        "sendResumeType": "EmailAndCvArchive",
   *        "contactEMail": "abc@gmail.com",
   *        "endingType": "AutoRepublish"
   *     }
   * @param vacancy undefined
   * @return Success
   */
  add(vacancy?: VacancyAddRequest): __Observable<VacancyAddResponse> {
    return this.addResponse(vacancy).pipe(__map(_r => _r.body as VacancyAddResponse))
  }

  /**
   * @param request undefined
   * @return Success
   */
  republishNewTicketResponse(request?: VacancyRepublishRequest): __Observable<__StrictHttpResponse<CommonResponse>> {
    let __params = this.newParams()
    let __headers = new HttpHeaders()
    let __body: any = null
    __body = request
    let req = new HttpRequest<any>('PUT', this.rootUrl + `/vacancy/republish`, __body, {
      headers: __headers,
      params: __params,
      responseType: 'json'
    })

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map(_r => {
        return _r as __StrictHttpResponse<CommonResponse>
      })
    )
  }

  /**
   * @param request undefined
   * @return Success
   */
  republishNewTicket(request?: VacancyRepublishRequest): __Observable<CommonResponse> {
    return this.republishNewTicketResponse(request).pipe(__map(_r => _r.body as CommonResponse))
  }

  /**
   * @param vacancy undefined
   * @return Success
   */
  updateAndRepublishResponse(vacancy?: VacancyAddRequest): __Observable<__StrictHttpResponse<VacancyAddResponse>> {
    let __params = this.newParams()
    let __headers = new HttpHeaders()
    let __body: any = null
    __body = vacancy
    let req = new HttpRequest<any>('POST', this.rootUrl + `/vacancy/republish`, __body, {
      headers: __headers,
      params: __params,
      responseType: 'json'
    })

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map(_r => {
        return _r as __StrictHttpResponse<VacancyAddResponse>
      })
    )
  }

  /**
   * @param vacancy undefined
   * @return Success
   */
  updateAndRepublish(vacancy?: VacancyAddRequest): __Observable<VacancyAddResponse> {
    return this.updateAndRepublishResponse(vacancy).pipe(__map(_r => _r.body as VacancyAddResponse))
  }

  /**
   * @param question undefined
   * @return Success
   */
  questionAddResponse(question?: ControlQuestion): __Observable<__StrictHttpResponse<CommonResponse>> {
    let __params = this.newParams()
    let __headers = new HttpHeaders()
    let __body: any = null
    __body = question
    let req = new HttpRequest<any>('POST', this.rootUrl + `/vacancy/question/add`, __body, {
      headers: __headers,
      params: __params,
      responseType: 'json'
    })

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map(_r => {
        return _r as __StrictHttpResponse<CommonResponse>
      })
    )
  }

  /**
   * @param question undefined
   * @return Success
   */
  questionAdd(question?: ControlQuestion): __Observable<CommonResponse> {
    return this.questionAddResponse(question).pipe(__map(_r => _r.body as CommonResponse))
  }

  /**
   * @param id undefined
   * @return Success
   */
  questionDeleteResponse(id: number): __Observable<__StrictHttpResponse<CommonResponse>> {
    let __params = this.newParams()
    let __headers = new HttpHeaders()
    let __body: any = null

    let req = new HttpRequest<any>('DELETE', this.rootUrl + `/vacancy/question/${id}`, __body, {
      headers: __headers,
      params: __params,
      responseType: 'json'
    })

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map(_r => {
        return _r as __StrictHttpResponse<CommonResponse>
      })
    )
  }

  /**
   * @param id undefined
   * @return Success
   */
  questionDelete(id: number): __Observable<CommonResponse> {
    return this.questionDeleteResponse(id).pipe(__map(_r => _r.body as CommonResponse))
  }

  /**
   * @param vacancyId undefined
   * @return Success
   */
  questionGetResponse(vacancyId: number): __Observable<__StrictHttpResponse<Array<ControlQuestion>>> {
    let __params = this.newParams()
    let __headers = new HttpHeaders()
    let __body: any = null

    let req = new HttpRequest<any>('GET', this.rootUrl + `/vacancy/question/${vacancyId}`, __body, {
      headers: __headers,
      params: __params,
      responseType: 'json'
    })

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map(_r => {
        return _r as __StrictHttpResponse<Array<ControlQuestion>>
      })
    )
  }

  /**
   * @param vacancyId undefined
   * @return Success
   */
  questionGet(vacancyId: number): __Observable<Array<ControlQuestion>> {
    return this.questionGetResponse(vacancyId).pipe(__map(_r => _r.body as Array<ControlQuestion>))
  }

  /**
   * @param type undefined
   * @return Success
   */
  questionTemplateGetResponse(type: number): __Observable<__StrictHttpResponse<ControlQuestionTemplate>> {
    let __params = this.newParams()
    let __headers = new HttpHeaders()
    let __body: any = null

    let req = new HttpRequest<any>('GET', this.rootUrl + `/vacancy/question/template/${type}`, __body, {
      headers: __headers,
      params: __params,
      responseType: 'json'
    })

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map(_r => {
        return _r as __StrictHttpResponse<ControlQuestionTemplate>
      })
    )
  }

  /**
   * @param type undefined
   * @return Success
   */
  questionTemplateGet(type: number): __Observable<ControlQuestionTemplate> {
    return this.questionTemplateGetResponse(type).pipe(__map(_r => _r.body as ControlQuestionTemplate))
  }

  /**
   * @param position undefined
   * @return Success
   */
  rubric1ByPositionResponse(position?: string): __Observable<__StrictHttpResponse<Array<number>>> {
    let __params = this.newParams()
    let __headers = new HttpHeaders()
    let __body: any = null
    if (position != null) __params = __params.set('position', position.toString())
    let req = new HttpRequest<any>('GET', this.rootUrl + `/vacancy/rubricbyposition`, __body, {
      headers: __headers,
      params: __params,
      responseType: 'json'
    })

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map(_r => {
        return _r as __StrictHttpResponse<Array<number>>
      })
    )
  }

  /**
   * @param position undefined
   * @return Success
   */
  rubric1ByPosition(position?: string): __Observable<Array<number>> {
    return this.rubric1ByPositionResponse(position).pipe(__map(_r => _r.body as Array<number>))
  }

  /**
   * @param multiUserId undefined
   * @return Success
   */
  vacancyCounterResponse(multiUserId?: number): __Observable<__StrictHttpResponse<VacancyCount>> {
    let __params = this.newParams()
    let __headers = new HttpHeaders()
    let __body: any = null
    if (multiUserId != null) __params = __params.set('multiUserId', multiUserId.toString())
    let req = new HttpRequest<any>('GET', this.rootUrl + `/vacancy/counter`, __body, {
      headers: __headers,
      params: __params,
      responseType: 'json'
    })

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map(_r => {
        return _r as __StrictHttpResponse<VacancyCount>
      })
    )
  }

  /**
   * @param multiUserId undefined
   * @return Success
   */
  vacancyCounter(multiUserId?: number): __Observable<VacancyCount> {
    return this.vacancyCounterResponse(multiUserId).pipe(__map(_r => _r.body as VacancyCount))
  }

  /**
   * @param multiUserId undefined
   * @return Success
   */
  vacancyCounterByStateResponse(multiUserId?: number): __Observable<__StrictHttpResponse<Array<VacancyCountByStateViewModel>>> {
    let __params = this.newParams()
    let __headers = new HttpHeaders()
    let __body: any = null
    if (multiUserId != null) __params = __params.set('multiUserId', multiUserId.toString())
    let req = new HttpRequest<any>('GET', this.rootUrl + `/vacancy/counterbystate`, __body, {
      headers: __headers,
      params: __params,
      responseType: 'json'
    })

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map(_r => {
        return _r as __StrictHttpResponse<Array<VacancyCountByStateViewModel>>
      })
    )
  }

  /**
   * @param multiUserId undefined
   * @return Success
   */
  vacancyCounterByState(multiUserId?: number): __Observable<Array<VacancyCountByStateViewModel>> {
    return this.vacancyCounterByStateResponse(multiUserId).pipe(__map(_r => _r.body as Array<VacancyCountByStateViewModel>))
  }

  /**
   * @param params The `VacancyService.GetOfferParams` containing the following parameters:
   *
   * - `vacancyId`:
   *
   * - `multiUserId`:
   *
   * @return Success
   */
  getOfferResponse(params: VacancyService.GetOfferParams): __Observable<__StrictHttpResponse<VacancyOffer>> {
    let __params = this.newParams()
    let __headers = new HttpHeaders()
    let __body: any = null
    if (params.vacancyId != null) __params = __params.set('vacancyId', params.vacancyId.toString())
    if (params.multiUserId != null) __params = __params.set('multiUserId', params.multiUserId.toString())
    let req = new HttpRequest<any>('GET', this.rootUrl + `/vacancy/lastoffer`, __body, {
      headers: __headers,
      params: __params,
      responseType: 'json'
    })

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map(_r => {
        return _r as __StrictHttpResponse<VacancyOffer>
      })
    )
  }

  /**
   * @param params The `VacancyService.GetOfferParams` containing the following parameters:
   *
   * - `vacancyId`:
   *
   * - `multiUserId`:
   *
   * @return Success
   */
  getOffer(params: VacancyService.GetOfferParams): __Observable<VacancyOffer> {
    return this.getOfferResponse(params).pipe(__map(_r => _r.body as VacancyOffer))
  }

  /**
   * @param request undefined
   */
  addOfferResponse(request?: SendOfferMessageRequest): __Observable<__StrictHttpResponse<any>> {
    let __params = this.newParams()
    let __headers = new HttpHeaders()
    let __body: any = null
    __body = request
    let req = new HttpRequest<any>('POST', this.rootUrl + `/vacancy/offer`, __body, {
      headers: __headers,
      params: __params,
      responseType: 'json'
    })

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map(_r => {
        return _r as __StrictHttpResponse<any>
      })
    )
  }

  /**
   * @param request undefined
   */
  addOffer(request?: SendOfferMessageRequest): __Observable<any> {
    return this.addOfferResponse(request).pipe(__map(_r => _r.body as any))
  }
}

module VacancyService {
  /**
   * Parameters for GetVacancy
   */
  export interface GetVacancyParams {
    id: number
    isNewAccount?: boolean
  }

  /**
   * Parameters for GetVacancy_1
   */
  export interface GetVacancy_1Params {
    id: number
    isNewAccount?: boolean
  }

  /**
   * Parameters for SetState
   */
  export interface SetStateParams {
    id: number
    state?:
      | 'All'
      | 'Deleted'
      | 'Banned'
      | 'Closed'
      | 'Publicated'
      | 'NotPublicated'
      | 'Suspend'
      | 'Waiting'
      | 'WaitingForAdmin'
      | 'BannedByAdmin'
      | 'PublicatedVirtual'
      | 'OnModeration'
      | 'BannedOnModeration'
  }

  /**
   * Parameters for MakeHot
   */
  export interface MakeHotParams {
    id: number
    weeks?: number
    additional?: boolean
  }

  /**
   * Parameters for VacancyEndingType
   */
  export interface VacancyEndingTypeParams {
    id: number
    type?: 'None' | 'CloseAndNotify' | 'AutoRepublish' | 'Close'
    onlyMonth?: boolean
  }

  /**
   * Parameters for VacancyStatistic
   */
  export interface VacancyStatisticParams {
    vacancyId?: number
    period?: 'Day' | 'Week' | 'Month' | 'All'
  }

  /**
   * Parameters for GetOffer
   */
  export interface GetOfferParams {
    vacancyId?: number
    multiUserId?: number
  }
}

export { VacancyService }
