export const sortByAlphabetCallback = (str1: string, str2: string, locales: string[]): number => {
  if (localeCompareSupportsLocales()) {
    return str1.localeCompare(str2, locales)
  } else {
    return str1 > str2 ? 1 : str1 < str2 ? -1 : 0
  }
}

const localeCompareSupportsLocales = (): boolean => {
  try {
    return !isNaN('a'.localeCompare('b', 'i'))
  } catch (e) {
    return e instanceof Error && e.name === 'RangeError'
  }
}
