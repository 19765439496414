import { RangeInt } from './range-int'
export interface CvDbSearchRequest {
  /**
   * Сортувати за.
   * 0 - релевантність, 1 - дата оновлення. 2 -  дата активності
   */
  sort?: CvDbSearchRequest.SortEnum

  /**
   * Место в интерфейсе, откуда был инициирован поиск
   * 0 - не обрано, 1 - пошук, 2 -  фільтри
   */
  searchContext?: CvDbSearchRequest.SearchContextEnum

  /**
   * Номер сторінки, починаючи з 0.
   */
  page?: number

  /**
   * Мова відвовіді.
   * true - укр, false - рус.
   */
  ukrainian?: boolean

  /**
   * ID міста.
   */
  cityId?: number

  /**
   * Включаючи в області.
   */
  inside?: boolean

  /**
   * Готовність до переїзду.
   * true - якщо готовий до переїзду; false - не готовий до переїзду.
   */
  moveability?: boolean

  /**
   * Готовність до переїзду.
   * true - тільки готових до переїзду
   */
  onlyMoveability?: boolean

  /**
   * Вид освіти.
   * 1 - вища, 2 - незакінченна вища, 3 - середньо-спеціальна, 4 - середня, 5 - МВА, 6- Дія. Фифрова освіта
   */
  educationIds?: Array<string>

  /**
   * Вік
   */
  age?: RangeInt
  showCvWithoutSalary?: boolean

  /**
   * Зарплата "від-до".
   */
  salary?: RangeInt
  sex?: CvDbSearchRequest.SexEnum

  /**
   * Не використовується.
   */
  profLevelIds?: Array<string>

  /**
   * Вид зайнятості.
   * 1 - повна, 2 - не повна, 3 - віддалена, 4 - стажування/практика, 5- проектна робота, 6 - сезонна/тимчасова робота.
   */
  scheduleIds?: Array<string>
  period?: CvDbSearchRequest.PeriodEnum

  /**
   * Галузь компанії - https://api.rabota.ua/dictionary/branch
   */
  branchIds?: Array<string>

  /**
   * Досвід роботи - https://employer-api.rabota.ua/values/cv-search-experience
   * 0 - без досвіду, 1 - до 1 року, 2 - від 1 до 2 років, 3 - від 2 до 5 років, 4 - від 5 до 10 років, 5 - більше 10 років
   */
  experienceIds?: Array<string>

  /**
   * Фоточка.
   * true - yes, false - no.
   */
  hasPhoto?: boolean

  /**
   * Мова резюме.
   * 1 - рус, 2 - анг, 3 - укр.
   */
  cvLanguage?: CvDbSearchRequest.CvLanguageEnum

  /**
   * Пошуковий запит.
   */
  keyWords?: string
  isSynonym?: boolean

  /**
   * Де шукати.
   * everywhere - по всьому тексту, default - з урахуванням синонімів, speciality - в назві резюме, education - в освіті, skills - в ключових навичках, experience - в досвіді роботи.
   */
  searchType?: string
  lastSort?: string
  rubrics?: Array<string>

  /**
   * Володіння мовами -  https://api.rabota.ua/dictionary/language, https://api.rabota.ua/dictionary/language/skill
   */
  languages?: Array<string>
  onlyWithCurrentNotebookNotes?: boolean
  onlyFavorite?: boolean
  onlyNew?: boolean
  onlyWithOpenedContacts?: boolean
  onlyViewed?: boolean
  includeDisliked?: boolean
  onlyDisliked?: boolean
  reverse?: boolean
  count?: number
  resumeFillingTypeIds?: Array<string>
  districtIds?: Array<string>
  onlyDisabled?: boolean
  disabled?: boolean
  onlyStudents?: boolean
  onlyWithOwnCar?: boolean
}

export namespace CvDbSearchRequest {
  export type SortEnum = 'LastActivity' | 'Score' | 'UpdateDate'
  export const SortEnum = {
    LastActivity: 'LastActivity' as SortEnum,
    Score: 'Score' as SortEnum,
    UpdateDate: 'UpdateDate' as SortEnum
  }
  export type SearchContextEnum = 'Filters' | 'History' | 'Main' | 'None'
  export const SearchContextEnum = {
    Filters: 'Filters' as SearchContextEnum,
    History: 'History' as SearchContextEnum,
    Main: 'Main' as SearchContextEnum,
    None: 'None' as SearchContextEnum
  }
  export type SexEnum = 'Any' | 'Female' | 'Male'
  export const SexEnum = {
    Any: 'Any' as SexEnum,
    Female: 'Female' as SexEnum,
    Male: 'Male' as SexEnum
  }
  export type PeriodEnum = 'All' | 'Month' | 'ThreeDays' | 'ThreeMonths' | 'Today' | 'Week' | 'Year'
  export const PeriodEnum = {
    All: 'All' as PeriodEnum,
    Month: 'Month' as PeriodEnum,
    ThreeDays: 'ThreeDays' as PeriodEnum,
    ThreeMonths: 'ThreeMonths' as PeriodEnum,
    Today: 'Today' as PeriodEnum,
    Week: 'Week' as PeriodEnum,
    Year: 'Year' as PeriodEnum
  }
  export type CvLanguageEnum = 'All' | 'English' | 'Russian' | 'Ukrainian'
  export const CvLanguageEnum = {
    All: 'All' as CvLanguageEnum,
    English: 'English' as CvLanguageEnum,
    Russian: 'Russian' as CvLanguageEnum,
    Ukrainian: 'Ukrainian' as CvLanguageEnum
  }
}
