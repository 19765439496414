import { Injectable, Injector, ɵcreateInjector as createInjector } from '@angular/core'
import { from, of, switchMap } from 'rxjs'
import { RxStateService } from '@alliance/shared/models'
import { CookieStorage } from '@alliance/shared/storage'

export const NEW_APP_BANNER_STORAGE_KEY = 'alreadyShowBanner'
const ONE_DAY = 24 * 60 * 60 * 1000

@Injectable()
export class AppPromoBannersService extends RxStateService<object> {
  private oneDayTimeStamp = Date.now() + ONE_DAY
  public constructor(private _injector: Injector, private readonly cookieStorage: CookieStorage) {
    super()
  }

  public openBannerModal(): void {
    this.hold(
      from(import('../app-promo-banner/jobseeker-app-promo-banner.module')).pipe(
        switchMap(({ JobseekerAppPromoBannerModule }) => {
          const injector = createInjector(JobseekerAppPromoBannerModule, this._injector)
          const module = injector.get(JobseekerAppPromoBannerModule)
          module.openMapModal()
          this.cookieStorage.setItem(NEW_APP_BANNER_STORAGE_KEY, 'true', { expires: new Date(this.oneDayTimeStamp) })
          return of(true)
        })
      )
    )
  }
}
