export enum AtsStaticTooltipParentRefEnum {
  createProject = 'createProject',
  importVacancies = 'importVacancies',
  connectWorkIntegration = 'connectWorkIntegration',
  importVacanciesWhenProjectsExist = 'importVacanciesWhenProjectsExist',
  importCandidatesFromExcel = 'importCandidatesFromExcel',
  projectRecommendedCandidates = 'projectRecommendedCandidates',

  landingRedirectImportVacancies = 'landingRedirectImportVacancies',
  landingRedirectKanbanList = 'landingRedirectKanbanList',
  landingRedirectAnalytics = 'landingRedirectAnalytics',
  landingRedirectProfiles = 'landingRedirectProfiles',
  landingRedirectHiringProcess = 'landingRedirectHiringProcess'
}
