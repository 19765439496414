/* eslint-disable */
import { HttpClient, HttpParameterCodec, HttpParams } from '@angular/common/http'
import { SharedAuthDataAccessConfiguration } from './shared-auth-data-access-configuration'

/**
 * Custom parameter codec to correctly handle the plus sign in parameter
 * values. See https://github.com/angular/angular/issues/18261
 */
class ParameterCodec implements HttpParameterCodec {
  encodeKey(key: string): string {
    return encodeURIComponent(key)
  }

  encodeValue(value: string): string {
    return encodeURIComponent(value)
  }

  decodeKey(key: string): string {
    return decodeURIComponent(key)
  }

  decodeValue(value: string): string {
    return decodeURIComponent(value)
  }
}
const PARAMETER_CODEC = new ParameterCodec()

/**
 * Base class for API services
 */
export class BaseService {
  public constructor(protected config: SharedAuthDataAccessConfiguration, protected http: HttpClient) {}

  private _rootUrl: string = ''

  /**
   * Returns the root url for API operations. If not set directly in this
   * service, will fallback to ApiConfiguration.rootUrl.
   */
  public get rootUrl(): string {
    return this._rootUrl || this.config.rootUrl
  }

  /**
   * Sets the root URL for API operations in this service.
   */
  public set rootUrl(rootUrl: string) {
    this._rootUrl = rootUrl
  }

  /**
   * Creates a new `HttpParams` with the correct codec
   */
  protected newParams(): HttpParams {
    return new HttpParams({
      encoder: PARAMETER_CODEC
    })
  }
}
