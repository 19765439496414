import { Injectable } from '@angular/core'
import { Observable, catchError, map, of } from 'rxjs'

import { getNonNullableItems } from '@alliance/shared/utils'

import { VacancyChangeClosingBehaviorGQL } from './prolong-vacancy.generated'
import { ProlongVacancyParams } from '../../../models/vacancy-actions/prolong-vacancy/prolong-vacancy-params.interface'
import { ProlongVacancyResponse } from '../../../models/vacancy-actions/prolong-vacancy/prolong-vacancy-response.interface'

@Injectable()
export class ProlongVacancyService {
  public constructor(private readonly vacancyChangeClosingBehaviorGQL: VacancyChangeClosingBehaviorGQL) {}

  public prolongVacancy$(params: ProlongVacancyParams): Observable<ProlongVacancyResponse | null> {
    return this.vacancyChangeClosingBehaviorGQL
      .mutate({
        input: {
          vacancies: params.vacancies,
          vacancyId: params.vacancies[0].vacancyId.toString(), // is going to be removed after schema change
          behavior: params.vacancies[0].behavior, // is going to be removed after schema change
          ...(params.serviceActivationBehavior && params.vacancies.length === 1 && { serviceActivationBehavior: params.serviceActivationBehavior })
        }
      })
      .pipe(
        map(({ data }) => {
          const vacancies = getNonNullableItems(data?.vacancyChangeClosingBehavior?.vacancies ?? [])
          if (vacancies.length) {
            return { vacancies }
          }
          return null
        }),
        catchError(() => of(null))
      )
  }
}
