import { Inject, Injectable } from '@angular/core'
import { CanActivate } from '@angular/router'
import { WINDOW } from '@ng-web-apis/common'

@Injectable()
export class CenterTurbotyRedirectGuard implements CanActivate {
  public constructor(@Inject(WINDOW) private readonly windowRef: Window) {}

  public canActivate(): boolean {
    this.windowRef.open('https://customer.care.robota.ua/servicedesk/customer/portals', '_self')
    return false
  }
}
