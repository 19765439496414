import { Injectable } from '@angular/core'

import { AbstractStorageById } from '@alliance/shared/utils'
import { AuthService } from '@alliance/shared/auth/api'
import { LocalStorage } from '@alliance/shared/storage'

const ONE_WEEK = 7 * 24 * 60 * 60 * 1000

@Injectable({ providedIn: 'root' })
export class StorageByCompanyIdService extends AbstractStorageById {
  public constructor(storageService: LocalStorage, private readonly authService: AuthService) {
    super(storageService)
  }

  protected readonly defaultTimeToLive = ONE_WEEK

  protected get id(): string | null {
    return this.authService.user?.NotebookId?.toString() || null
  }
}
