import { Injectable } from '@angular/core'
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http'
import { BaseService as __BaseService } from '../base-service'
import { JobseekerDataAccessConfiguration as __Configuration } from '../jobseeker-data-access-configuration'
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response'
import { Observable as __Observable } from 'rxjs'
import { map as __map, filter as __filter } from 'rxjs/operators'

import { CompanyHint } from '../models/company-hint'
import { CityDataLocalized } from '../models/city-data-localized'
@Injectable({
  providedIn: 'root'
})
class AutocompleteService extends __BaseService {
  public static readonly autocompleteCompanyGetPath = '/autocomplete/company'
  public static readonly autocompleteCompanyPostPath = '/autocomplete/company'
  public static readonly autocompleteKeywordGetPath = '/autocomplete/keyword'
  public static readonly autocompleteRubricRubricByPositionPath = '/autocomplete/rubricbyposition'
  public static readonly autocompleteRubricGetSubRubricsByTermPath = '/autocomplete/position/{term}/subrubrics'
  public static readonly autocompleteRubricGetRubricsByTermPath = '/autocomplete/position/{term}/rubrics'
  public static readonly autocompleteCityGetPath = '/autocomplete/city'

  public constructor(config: __Configuration, http: HttpClient) {
    super(config, http)
  }

  /**
   * @param term undefined
   * @return OK
   */
  autocompleteCompanyGetResponse(term: string): __Observable<__StrictHttpResponse<Array<CompanyHint>>> {
    let __params = this.newParams()
    let __headers = new HttpHeaders()
    let __body: any = null
    if (term != null) __params = __params.set('term', term.toString())
    let req = new HttpRequest<any>('GET', this.rootUrl + `/autocomplete/company`, __body, {
      headers: __headers,
      params: __params,
      responseType: 'json'
    })

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map(_r => {
        return _r as __StrictHttpResponse<Array<CompanyHint>>
      })
    )
  }

  /**
   * @param term undefined
   * @return OK
   */
  autocompleteCompanyGet(term: string): __Observable<Array<CompanyHint>> {
    return this.autocompleteCompanyGetResponse(term).pipe(__map(_r => _r.body as Array<CompanyHint>))
  }

  /**
   * @param ids undefined
   * @return OK
   */
  autocompleteCompanyPostResponse(ids: Array<number>): __Observable<__StrictHttpResponse<Array<CompanyHint>>> {
    let __params = this.newParams()
    let __headers = new HttpHeaders()
    let __body: any = null
    __body = ids
    let req = new HttpRequest<any>('POST', this.rootUrl + `/autocomplete/company`, __body, {
      headers: __headers,
      params: __params,
      responseType: 'json'
    })

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map(_r => {
        return _r as __StrictHttpResponse<Array<CompanyHint>>
      })
    )
  }

  /**
   * @param ids undefined
   * @return OK
   */
  autocompleteCompanyPost(ids: Array<number>): __Observable<Array<CompanyHint>> {
    return this.autocompleteCompanyPostResponse(ids).pipe(__map(_r => _r.body as Array<CompanyHint>))
  }

  /**
   * @param params The `AutocompleteService.AutocompleteKeywordGetParams` containing the following parameters:
   *
   * - `term`:
   *
   * - `language`:
   *
   * - `count`:
   *
   * @return OK
   */
  autocompleteKeywordGetResponse(params: AutocompleteService.AutocompleteKeywordGetParams): __Observable<__StrictHttpResponse<Array<string>>> {
    let __params = this.newParams()
    let __headers = new HttpHeaders()
    let __body: any = null
    if (params.term != null) __params = __params.set('term', params.term.toString())
    if (params.language != null) __params = __params.set('language', params.language.toString())
    if (params.count != null) __params = __params.set('count', params.count.toString())
    let req = new HttpRequest<any>('GET', this.rootUrl + `/autocomplete/keyword`, __body, {
      headers: __headers,
      params: __params,
      responseType: 'json'
    })

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map(_r => {
        return _r as __StrictHttpResponse<Array<string>>
      })
    )
  }

  /**
   * @param params The `AutocompleteService.AutocompleteKeywordGetParams` containing the following parameters:
   *
   * - `term`:
   *
   * - `language`:
   *
   * - `count`:
   *
   * @return OK
   */
  autocompleteKeywordGet(params: AutocompleteService.AutocompleteKeywordGetParams): __Observable<Array<string>> {
    return this.autocompleteKeywordGetResponse(params).pipe(__map(_r => _r.body as Array<string>))
  }

  /**
   * @param term undefined
   * @return OK
   */
  autocompleteRubricRubricByPositionResponse(term: string): __Observable<__StrictHttpResponse<Array<number>>> {
    let __params = this.newParams()
    let __headers = new HttpHeaders()
    let __body: any = null
    if (term != null) __params = __params.set('term', term.toString())
    let req = new HttpRequest<any>('GET', this.rootUrl + `/autocomplete/rubricbyposition`, __body, {
      headers: __headers,
      params: __params,
      responseType: 'json'
    })

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map(_r => {
        return _r as __StrictHttpResponse<Array<number>>
      })
    )
  }

  /**
   * @param term undefined
   * @return OK
   */
  autocompleteRubricRubricByPosition(term: string): __Observable<Array<number>> {
    return this.autocompleteRubricRubricByPositionResponse(term).pipe(__map(_r => _r.body as Array<number>))
  }

  /**
   * @param term undefined
   * @return OK
   */
  autocompleteRubricGetSubRubricsByTermResponse(term: string): __Observable<__StrictHttpResponse<Array<number>>> {
    let __params = this.newParams()
    let __headers = new HttpHeaders()
    let __body: any = null

    let req = new HttpRequest<any>('GET', this.rootUrl + `/autocomplete/position/${term}/subrubrics`, __body, {
      headers: __headers,
      params: __params,
      responseType: 'json'
    })

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map(_r => {
        return _r as __StrictHttpResponse<Array<number>>
      })
    )
  }

  /**
   * @param term undefined
   * @return OK
   */
  autocompleteRubricGetSubRubricsByTerm(term: string): __Observable<Array<number>> {
    return this.autocompleteRubricGetSubRubricsByTermResponse(term).pipe(__map(_r => _r.body as Array<number>))
  }

  /**
   * @param term undefined
   * @return OK
   */
  autocompleteRubricGetRubricsByTermResponse(term: string): __Observable<__StrictHttpResponse<Array<number>>> {
    let __params = this.newParams()
    let __headers = new HttpHeaders()
    let __body: any = null

    let req = new HttpRequest<any>('GET', this.rootUrl + `/autocomplete/position/${term}/rubrics`, __body, {
      headers: __headers,
      params: __params,
      responseType: 'json'
    })

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map(_r => {
        return _r as __StrictHttpResponse<Array<number>>
      })
    )
  }

  /**
   * @param term undefined
   * @return OK
   */
  autocompleteRubricGetRubricsByTerm(term: string): __Observable<Array<number>> {
    return this.autocompleteRubricGetRubricsByTermResponse(term).pipe(__map(_r => _r.body as Array<number>))
  }

  /**
   * @param term undefined
   * @return OK
   */
  autocompleteCityGetResponse(term?: string): __Observable<__StrictHttpResponse<Array<CityDataLocalized>>> {
    let __params = this.newParams()
    let __headers = new HttpHeaders()
    let __body: any = null
    if (term != null) __params = __params.set('term', term.toString())
    let req = new HttpRequest<any>('GET', this.rootUrl + `/autocomplete/city`, __body, {
      headers: __headers,
      params: __params,
      responseType: 'json'
    })

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map(_r => {
        return _r as __StrictHttpResponse<Array<CityDataLocalized>>
      })
    )
  }

  /**
   * @param term undefined
   * @return OK
   */
  autocompleteCityGet(term?: string): __Observable<Array<CityDataLocalized>> {
    return this.autocompleteCityGetResponse(term).pipe(__map(_r => _r.body as Array<CityDataLocalized>))
  }
}

module AutocompleteService {
  /**
   * Parameters for AutocompleteKeywordGet
   */
  export interface AutocompleteKeywordGetParams {
    term: string
    language?: 'None' | 'English' | 'Ukrainian' | 'Russian'
    count?: number
  }
}

export { AutocompleteService }
