<div class="modal-content santa-px-20 760:santa-px-40 760:santa-py-40">
  <h3 class="santa-typo-h3 760:santa-typo-h2 santa-text-center 760:santa-text-left santa-mb-10">
    {{ translations.confirmReloadModal.title | translate }}
  </h3>

  <p class="santa-typo-regular 760:santa-typo-important">
    {{ translations.confirmReloadModal.description | translate }}
  </p>

  <santa-button class="santa-block santa-mt-20" [fullWidth]="true" (click)="confirm()">
    <div class="santa-flex santa-items-center santa-justify-center">
      <alliance-icon class="santa-mr-10" [name]="IconWhiteRefresh.name"></alliance-icon>
      <span>{{ translations.confirmReloadModal.action | translate }}</span>
    </div>
  </santa-button>
</div>
