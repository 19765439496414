import { Injectable } from '@angular/core'
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http'
import { BaseService as __BaseService } from '../base-service'
import { EmployerDataAccessConfiguration as __Configuration } from '../employer-data-access-configuration'
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response'
import { Observable as __Observable } from 'rxjs'
import { map as __map, filter as __filter } from 'rxjs/operators'

import { CvNote } from '../models/cv-note'
import { EditNoteRequest } from '../models/edit-note-request'
import { AddNoteRequest } from '../models/add-note-request'
@Injectable({
  providedIn: 'root'
})
class NoteService extends __BaseService {
  public static readonly notesPath = '/note/list/{id}'
  public static readonly editNotePath = '/note'
  public static readonly addNotePath = '/note'
  public static readonly deleteNotePath = '/note'

  public constructor(config: __Configuration, http: HttpClient) {
    super(config, http)
  }

  /**
   * @param params The `NoteService.NotesParams` containing the following parameters:
   *
   * - `id`:
   *
   * - `resumeType`:
   *
   * @return Success
   */
  notesResponse(params: NoteService.NotesParams): __Observable<__StrictHttpResponse<Array<CvNote>>> {
    let __params = this.newParams()
    let __headers = new HttpHeaders()
    let __body: any = null

    if (params.resumeType != null) __params = __params.set('resumeType', params.resumeType.toString())
    let req = new HttpRequest<any>('GET', this.rootUrl + `/note/list/${params.id}`, __body, {
      headers: __headers,
      params: __params,
      responseType: 'json'
    })

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map(_r => {
        return _r as __StrictHttpResponse<Array<CvNote>>
      })
    )
  }

  /**
   * @param params The `NoteService.NotesParams` containing the following parameters:
   *
   * - `id`:
   *
   * - `resumeType`:
   *
   * @return Success
   */
  notes(params: NoteService.NotesParams): __Observable<Array<CvNote>> {
    return this.notesResponse(params).pipe(__map(_r => _r.body as Array<CvNote>))
  }

  /**
   * @param editRequest undefined
   */
  editNoteResponse(editRequest?: EditNoteRequest): __Observable<__StrictHttpResponse<any>> {
    let __params = this.newParams()
    let __headers = new HttpHeaders()
    let __body: any = null
    __body = editRequest
    let req = new HttpRequest<any>('PUT', this.rootUrl + `/note`, __body, {
      headers: __headers,
      params: __params,
      responseType: 'json'
    })

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map(_r => {
        return _r as __StrictHttpResponse<any>
      })
    )
  }

  /**
   * @param editRequest undefined
   */
  editNote(editRequest?: EditNoteRequest): __Observable<any> {
    return this.editNoteResponse(editRequest).pipe(__map(_r => _r.body as any))
  }

  /**
   * @param note undefined
   * @return Success
   */
  addNoteResponse(note?: AddNoteRequest): __Observable<__StrictHttpResponse<CvNote>> {
    let __params = this.newParams()
    let __headers = new HttpHeaders()
    let __body: any = null
    __body = note
    let req = new HttpRequest<any>('POST', this.rootUrl + `/note`, __body, {
      headers: __headers,
      params: __params,
      responseType: 'json'
    })

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map(_r => {
        return _r as __StrictHttpResponse<CvNote>
      })
    )
  }

  /**
   * @param note undefined
   * @return Success
   */
  addNote(note?: AddNoteRequest): __Observable<CvNote> {
    return this.addNoteResponse(note).pipe(__map(_r => _r.body as CvNote))
  }

  /**
   * @param params The `NoteService.DeleteNoteParams` containing the following parameters:
   *
   * - `resumeType`:
   *
   * - `noteId`:
   */
  deleteNoteResponse(params: NoteService.DeleteNoteParams): __Observable<__StrictHttpResponse<any>> {
    let __params = this.newParams()
    let __headers = new HttpHeaders()
    let __body: any = null
    if (params.resumeType != null) __params = __params.set('resumeType', params.resumeType.toString())
    if (params.noteId != null) __params = __params.set('noteId', params.noteId.toString())
    let req = new HttpRequest<any>('DELETE', this.rootUrl + `/note`, __body, {
      headers: __headers,
      params: __params,
      responseType: 'json'
    })

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map(_r => {
        return _r as __StrictHttpResponse<any>
      })
    )
  }

  /**
   * @param params The `NoteService.DeleteNoteParams` containing the following parameters:
   *
   * - `resumeType`:
   *
   * - `noteId`:
   */
  deleteNote(params: NoteService.DeleteNoteParams): __Observable<any> {
    return this.deleteNoteResponse(params).pipe(__map(_r => _r.body as any))
  }
}

module NoteService {
  /**
   * Parameters for Notes
   */
  export interface NotesParams {
    id: number
    resumeType?: 'None' | 'AttachedFile' | 'Notepad' | 'Selected' | 'NoCvApply'
  }

  /**
   * Parameters for DeleteNote
   */
  export interface DeleteNoteParams {
    resumeType?: 'None' | 'AttachedFile' | 'Notepad' | 'Selected' | 'NoCvApply'
    noteId?: number
  }
}

export { NoteService }
