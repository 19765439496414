export const robotsList: RegExp[] = [
  /bot/,
  /spider/,
  /crawl/,
  /APIs-Google/,
  /AdsBot/,
  /Googlebot/,
  /mediapartners/,
  /Google Favicon/,
  /FeedFetcher/,
  /Google-Read-Aloud/,
  /DuplexWeb-Google/,
  /googleweblight/,
  /ia_archiver/,
  /facebook/,
  /instagram/,
  /pinterest/,
  /reddit/,
  /slack/,
  /twitter/,
  /whatsapp/,
  /youtube/,
  /telegram/,
  /viber/,
  /Google-InspectionTool/
]

export const detectRobot = (userAgent: string): boolean => {
  const robots = new RegExp(robotsList.map(r => r.source).join('|'), 'i') // BUILD REGEXP + "i" FLAG

  return robots.test(userAgent)
}
