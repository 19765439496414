import { Injectable } from '@angular/core'

/**
 * Global configuration for ServiceApiDataAccess services
 */
@Injectable({
  providedIn: 'root'
})
export class ServiceApiDataAccessConfiguration {
  rootUrl: string = 'https://service-api.robota.ua'
}

export interface ServiceApiDataAccessConfigurationInterface {
  rootUrl?: string
}
