import { SendEmailStatusEnum } from '@alliance/shared/domain-gql'

export const LETTRER_NOTIFICARION_STATUS_UA: Record<SendEmailStatusEnum, string> = {
  FAILED: 'Помилка надсилання листа',
  FAILED_USER_DONT_EXISTS: 'Помилка, такого користувача не існує',
  REJECTED_ATTEMPTS_LIMIT: 'Перевищений ліміт спроб надсилання листа',
  REJECTED_RATE_LIMIT: 'Перевищено ліміт надсилання листа за хвилину',
  REJECTED_WRONG_EMAIL: 'Неправильна адреса пошти',
  SELECTED_FOR_DELIVERY: 'Ми повторно надіслали листа на вашу пошту',
  USER_BLOCKED: ''
}

export const LETTRER_NOTIFICARION_STATUS_RU: Record<SendEmailStatusEnum, string> = {
  FAILED: 'Ошибка при отправке письма',
  FAILED_USER_DONT_EXISTS: 'Ошибка, такого пользователя не существует',
  REJECTED_ATTEMPTS_LIMIT: 'Превышен лимит попыток отправки письма',
  REJECTED_RATE_LIMIT: 'Превышен лимит отправки письма за минуту',
  REJECTED_WRONG_EMAIL: 'Неправильный адрес почты',
  SELECTED_FOR_DELIVERY: 'Мы повторно отправили письмо на вашу почту',
  USER_BLOCKED: ''
}
