import { Injectable } from '@angular/core'
import { map, Observable, of } from 'rxjs'
import {
  CreateProfResumeAsCopyGQL,
  CreateResumeFromAttachFileGQL,
  CreateResumeFromAttachFileResponseFragment,
  DeleteSeekerProfResumeGQL,
  PublishSeekerProfResumeFragment,
  PublishSeekerProfResumeGQL,
  UnPublishSeekerProfResumeFragment,
  UnPublishSeekerProfResumeGQL,
  UpdateSeekerProfResumeAnonymityStatusGQL,
  UpdateSeekerProfResumeFragment,
  UpdateSeekerProfResumeSortDateGQL
} from './resume.generated'
import { catchError } from 'rxjs/operators'
import { UpdateQueryOption } from './update-query-option-type'
import { CreateProfResumeFromAttachFileInput } from '@alliance/shared/domain-gql'

@Injectable({ providedIn: 'root' })
export class ResumeGqlService {
  public constructor(
    private deleteSeekerProfResumeGQL: DeleteSeekerProfResumeGQL,
    private createProfResumeAsCopyGQL: CreateProfResumeAsCopyGQL,
    private publishSeekerProfResumeGQL: PublishSeekerProfResumeGQL,
    private unPublishSeekerProfResumeGQL: UnPublishSeekerProfResumeGQL,
    private updateSeekerProfResumeAnonymityStatusGQL: UpdateSeekerProfResumeAnonymityStatusGQL,
    private updateSeekerProfResumeSortDateGQL: UpdateSeekerProfResumeSortDateGQL,
    private createResumeFromAttachFileGQL: CreateResumeFromAttachFileGQL
  ) {}

  public deleteSeekerProfResume$(resumeId: string): Observable<boolean> {
    return this.deleteSeekerProfResumeGQL.mutate({ input: { resumeId } }).pipe(map(({ data }) => !data?.deleteSeekerProfResume.errors))
  }

  public copySeekerProfResume$(resumeId: string): Observable<string | null> {
    return this.createProfResumeAsCopyGQL.mutate({ input: { resumeId } }).pipe(map(({ data }) => data?.createProfResumeAsCopy?.profResume?.id ?? null))
  }

  public publishSeekerProfResume(resumeId: string, updateQueryOption: UpdateQueryOption | undefined = undefined): Observable<PublishSeekerProfResumeFragment | null> {
    return this.publishSeekerProfResumeGQL.mutate({ input: { resumeId } }, updateQueryOption).pipe(
      map(date => date?.data?.publishSeekerProfResume ?? null),
      catchError(() => of(null))
    )
  }

  public unPublishSeekerProfResume(resumeId: string, updateQueryOption: UpdateQueryOption | undefined = undefined): Observable<UnPublishSeekerProfResumeFragment | null> {
    return this.unPublishSeekerProfResumeGQL.mutate({ input: { resumeId } }, updateQueryOption).pipe(
      map(date => date?.data?.unpublishSeekerProfResume ?? null),
      catchError(() => of(null))
    )
  }

  public updateSeekerProfResumeAnonymityStatus(resumeId: string, isAnonymous: boolean): Observable<string | null> {
    return this.updateSeekerProfResumeAnonymityStatusGQL.mutate({ input: { resumeId, isAnonymous } }).pipe(
      map(date => date.data?.updateSeekerProfResumeAnonymity.profResume?.id ?? null),
      catchError(() => of(null))
    )
  }

  public updateSeekerProfResumeSortDate(resumeId: string): Observable<UpdateSeekerProfResumeFragment | null> {
    return this.updateSeekerProfResumeSortDateGQL.mutate({ input: { resumeId } }).pipe(
      map(date => date.data?.updateSeekerProfResumeSortDate ?? null),
      catchError(() => of(null))
    )
  }

  public createResumeFromFile(input: CreateProfResumeFromAttachFileInput): Observable<CreateResumeFromAttachFileResponseFragment | null> {
    return this.createResumeFromAttachFileGQL.mutate({ input }).pipe(
      map(res => res.data?.createProfResumeFromAttachFile ?? null),
      catchError(() => of(null))
    )
  }
}
