import { Environment } from '@alliance/shared/environment'
import { TranslationService } from '@alliance/shared/translation'
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { Observable } from 'rxjs'

@Injectable()
export class AcceptLanguageInterceptorService implements HttpInterceptor {
  private readonly allowedUrls = [this.environment.tagsSuggesterApi]

  public constructor(private translationService: TranslationService, private environment: Environment) {}

  public intercept(req: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    if (this.allowedUrls.includes(new URL(req.url).origin)) {
      req = req.clone({ headers: req.headers.set('accept-language', this.translationService.getCurrentLang()) })
    }
    return next.handle(req)
  }
}
