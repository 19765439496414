import { NgModule, Optional } from '@angular/core'
import { Configuration } from './configuration'
import { HttpClient } from '@angular/common/http'
import { Environment } from '@alliance/shared/environment'
import { WebSocketsService } from './api/webSockets.service'
import { AuthService } from '@alliance/shared/auth/api'

@NgModule({
  imports: [],
  declarations: [],
  exports: [],
  providers: [
    WebSocketsService,
    {
      provide: Configuration,
      useFactory: (environment: Environment, authService: AuthService): Configuration =>
        new Configuration({
          basePath: (environment.socketApi ?? '').replace(/\/$/, ''),
          withCredentials: true,
          accessToken: () => authService.token || ''
        }),
      deps: [Environment, AuthService]
    }
  ]
})
export class SocketDataAccessModule {
  public constructor(@Optional() http: HttpClient) {
    if (!http) {
      throw new Error('You need to import the HttpClientModule in your AppModule! \n' + 'See also https://github.com/angular/angular/issues/20575')
    }
  }
}
