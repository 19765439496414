import { Environment, EnvironmentAppEnum } from './environment'
import { getDomain } from './domain'
import { getNamespace } from './octopus'

export abstract class EnvironmentOctopus implements Environment {
  public app: EnvironmentAppEnum = null
  public production = true
  public publicHost = `https://${this.domain}`
  public mainApiUrl = `https://api.${this.domain}`
  public mainSiteUrl = `${this.publicHost}/`
  public accountHost = `https://account.${this.domain}`
  public authApi = `https://auth-api.${this.domain}`
  public employerApi = `https://employer-api.${this.domain}`
  public notificationApi = `https://notificationapi.${this.domain}`
  public chatApi = `https://chat-api.${this.domain}`
  public socketApi = `https://socket-api.${this.domain}`
  public jobseekerApi = `https://api.${this.domain}`
  public usersApi = `https://users-api.${this.domain}`
  public jobseekerApiUk = `https://ua-api.${this.domain}`
  public matchingEuropeApi = 'https://europe-west1-majestic-cairn-171208.cloudfunctions.net'
  public matchingRecomApi = `https://recommended.${this.domain}`
  public admin8Host = `https://admin8.${this.domain}`
  public adminApi = `https://admin-api.${this.domain}`
  public adjwtApi = `https://adjwt-api.${this.domain}`
  public graphqlApi = `https://dracula.${this.domain}`
  public graphqlLimitsApi = `https://service-api.${this.domain}/graphql`
  public graphqlApiCredentials = false
  public admin8Api = `https://admin8.${this.domain}/api`
  public vacancyApi = `https://vacancy-api.${this.domain}`
  public applyApi = `https://apply-api.${this.domain}`
  public employerAppsApi = `https://employer-apps-api.${this.domain}`
  public companyLogoUrlHostname = `https://company-logo-frankfurt.robota.ua/`
  public companyLogoUrl = `${this.companyLogoUrlHostname}cdn-cgi/image`
  public vacancySearchApi = `https://vacancy-search-api.${this.domain}`
  public employerServiceApi = `https://service-api.${this.domain}`
  public paymentApi = `https://payment-api.${this.domain}`
  public promoApi = `https://promo-api.${this.domain}`
  public companyEmployeesServiceApi = `https://company-employees-api.${this.domain}`
  public companyServiceApi = `https://company-api.${this.domain}`
  public gtmIdDesktop = 'GTM-TBLWWS'
  public gtmIdMobile = 'GTM-TBLWWS'
  public clubsApi = `https://clubs-api.${this.domain}/graphql`
  public clubsStaticAWS = ''
  public budni = `https://budni.${this.domain}`
  public ats = `https://helper.${this.domain}`
  public feedbackApi = `https://feedback-api.${this.domain}`
  public googleClientId = '832273042012-evn4lmjds3jahouikvcok4hguda2t7ht.apps.googleusercontent.com'
  public graphqlAtsApi = `https://ats-api.${this.domain}/graphql`
  public tagsSuggesterApi = `https://tags-suggester.${this.domain}`
  public bannerApi = `https://banner-api.${this.domain}`
  public marketingApi = `https://marketing-api.${this.domain}`
  public chatGptProxy = `https://chat-gpt-proxy.${this.domain}`

  public constructor(public domain = getDomain({ production: true }), public name = getNamespace({ production: true })) {}
}
