import { ChatDataAccessModule } from '@alliance/chat/data-access'
import { CommonModule } from '@angular/common'
import { HTTP_INTERCEPTORS } from '@angular/common/http'
import { NgModule, Optional, SkipSelf } from '@angular/core'
import { AcceptLanguageInterceptorService } from './interceptor/accept-language-interceptor.service'

@NgModule({
  imports: [CommonModule, ChatDataAccessModule],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AcceptLanguageInterceptorService,
      multi: true
    }
  ]
})
export class ChatApiModule {
  public constructor(@Optional() @SkipSelf() parentModule: ChatApiModule) {
    if (parentModule) {
      throw new Error('ChatApiModule is already loaded. Import in your base AppModule only.')
    }
  }
}
