export enum LabelTypeEnum {
  hot = 'Hot',
  new = 'New',
  apply = 'Apply',
  watch = 'Watch',
  close = 'Close',
  remote = 'Remote'
}

export interface VacancyLabelData {
  hot: boolean
  closed: boolean
  apply?: {
    date: string | null
    isApply: boolean
  }
  remote: boolean
}

export interface VacancyLabelArrayItem {
  type: LabelTypeEnum
  show: boolean
  date?: string
}
