import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'

import { OuterClickDirective } from './outer-click.directive'

@NgModule({
  imports: [CommonModule],
  declarations: [OuterClickDirective],
  exports: [OuterClickDirective]
})
export class SharedOuterClickDirectiveModule {}
