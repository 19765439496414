import { Injectable } from '@angular/core'
import { map, Observable, of } from 'rxjs'
import { AuthService } from '@alliance/shared/auth/api'
import { EmployerSession } from './employer-session'

@Injectable({
  providedIn: 'root'
})
export class EmployerSessionService {
  public constructor(private readonly authService: AuthService) {}

  public getEmployerSession$(): Observable<EmployerSession | null> {
    return of(this.authService.user).pipe(
      map(user => {
        if (!user) {
          return null
        }

        return { companyId: user?.NotebookId ? `${user?.NotebookId}` : '' }
      })
    )
  }
}
