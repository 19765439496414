export interface VacancyListModel {
  filters: { [key: string]: string }
  totalItems: number
  url: string
}

export enum AllowedIndexFiltersEnum {
  cityId = 'cityId',
  keywords = 'keywords',
  page = 'page',
  salary = 'salary',
  districtIds = 'districtIds'
}

// Тип для комбинации фильтров
export type FilterCombination<T extends AllowedIndexFiltersEnum[]> = [...T]

export type AllowedFilters = Partial<Record<AllowedIndexFiltersEnum, string>>

export const AllowedFilterCombinations: Array<FilterCombination<AllowedIndexFiltersEnum[]>> = [[AllowedIndexFiltersEnum.cityId], [AllowedIndexFiltersEnum.cityId, AllowedIndexFiltersEnum.keywords]]

export type SEOObject = {
  h1: string
  title: string
  description: string
}

export interface VacListSeoParams {
  totalItems: number
  combinationKey: string
  filters: AllowedFilters | null
}
