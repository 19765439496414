export interface CvNote {
  parentId?: number
  id?: number
  resumeType?: CvNote.ResumeTypeEnum
  description?: string
  addDate?: Date
  resumeId?: number
  name?: string
  isOnlyShowToMe?: boolean
  multiUserId?: number
  multiUserName?: string
  multiUserAppointment?: string
}

export namespace CvNote {
  export type ResumeTypeEnum = 'AttachedFile' | 'NoCvApply' | 'None' | 'Notepad' | 'Selected'
  export const ResumeTypeEnum = {
    AttachedFile: 'AttachedFile' as ResumeTypeEnum,
    NoCvApply: 'NoCvApply' as ResumeTypeEnum,
    None: 'None' as ResumeTypeEnum,
    Notepad: 'Notepad' as ResumeTypeEnum,
    Selected: 'Selected' as ResumeTypeEnum
  }
}
