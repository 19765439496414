import { Injectable } from '@angular/core'
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http'
import { BaseService as __BaseService } from '../base-service'
import { JobseekerDataAccessConfiguration as __Configuration } from '../jobseeker-data-access-configuration'
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response'
import { Observable as __Observable } from 'rxjs'
import { map as __map, filter as __filter } from 'rxjs/operators'

@Injectable({
  providedIn: 'root'
})
class AppleService extends __BaseService {
  public static readonly appleAppleIdPath = '/login/appleid'
  public static readonly appleGetAppleSiteAssociationPath = '/apple-app-site-association'

  public constructor(config: __Configuration, http: HttpClient) {
    super(config, http)
  }

  /**
   * @param params The `AppleService.AppleAppleIdParams` containing the following parameters:
   *
   * - `code`:
   *
   * - `lastName`:
   *
   * - `firstName`:
   *
   * @return OK
   */
  appleAppleIdResponse(params: AppleService.AppleAppleIdParams): __Observable<__StrictHttpResponse<{}>> {
    let __params = this.newParams()
    let __headers = new HttpHeaders()
    let __body: any = null
    if (params.code != null) __params = __params.set('code', params.code.toString())
    if (params.lastName != null) __params = __params.set('lastName', params.lastName.toString())
    if (params.firstName != null) __params = __params.set('firstName', params.firstName.toString())
    let req = new HttpRequest<any>('POST', this.rootUrl + `/login/appleid`, __body, {
      headers: __headers,
      params: __params,
      responseType: 'json'
    })

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map(_r => {
        return _r as __StrictHttpResponse<{}>
      })
    )
  }

  /**
   * @param params The `AppleService.AppleAppleIdParams` containing the following parameters:
   *
   * - `code`:
   *
   * - `lastName`:
   *
   * - `firstName`:
   *
   * @return OK
   */
  appleAppleId(params: AppleService.AppleAppleIdParams): __Observable<{}> {
    return this.appleAppleIdResponse(params).pipe(__map(_r => _r.body as {}))
  }

  /**
   * @return OK
   */
  appleGetAppleSiteAssociationResponse(): __Observable<__StrictHttpResponse<{}>> {
    let __params = this.newParams()
    let __headers = new HttpHeaders()
    let __body: any = null
    let req = new HttpRequest<any>('GET', this.rootUrl + `/apple-app-site-association`, __body, {
      headers: __headers,
      params: __params,
      responseType: 'json'
    })

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map(_r => {
        return _r as __StrictHttpResponse<{}>
      })
    )
  }

  /**
   * @return OK
   */
  appleGetAppleSiteAssociation(): __Observable<{}> {
    return this.appleGetAppleSiteAssociationResponse().pipe(__map(_r => _r.body as {}))
  }
}

module AppleService {
  /**
   * Parameters for AppleAppleId
   */
  export interface AppleAppleIdParams {
    code: string
    lastName?: string
    firstName?: string
  }
}

export { AppleService }
