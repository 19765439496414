import { DataLayerPushEvent } from '@alliance/shared/utils'

export const APPLIES_RESUME_DISLIKE_EVENT: DataLayerPushEvent = {
  event: 'EMP_Applies',
  eventCategory: 'EMP_Applies',
  eventAction: 'Dislike',
  eventLabel: null,
  eventContent: null
}

export const APPLIES_RESUME_UN_DISLIKE_EVENT: DataLayerPushEvent = {
  event: 'EMP_Applies',
  eventCategory: 'EMP_Applies',
  eventAction: 'UnDislike',
  eventLabel: null,
  eventContent: null
}
