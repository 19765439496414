import { EVENT_MANAGER_PLUGINS } from '@angular/platform-browser'
import { Provider } from '@angular/core'

import { PreventEventPlugin } from '../plugins/prevent.plugin'
import { SilentEventPlugin } from '../plugins/silent.plugin'
import { StopEventPlugin } from '../plugins/stop.plugin'

// plugins from https://github.com/taiga-family/ng-event-plugins/tree/965c53377b30af10e8b96def566f15bdf08e5eca/projects/ng-event-plugins/src/plugins

const PLUGINS = [PreventEventPlugin, SilentEventPlugin, StopEventPlugin]

export const NG_EVENT_PLUGINS: Provider[] = PLUGINS.map(useClass => ({
  provide: EVENT_MANAGER_PLUGINS,
  multi: true,
  useClass
}))
