/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core'
import { HttpClient, HttpResponse } from '@angular/common/http'
import { BaseService } from '../base-service'
import { VacancySearchDataAccessConfiguration } from '../vacancy-search-data-access-configuration'
import { StrictHttpResponse } from '../strict-http-response'
import { RequestBuilder } from '../request-builder'
import { Observable } from 'rxjs'
import { map, filter } from 'rxjs/operators'

import { VacancySearchApiSearchResponse } from '../models/vacancy-search-api-search-response'
import { VacancySearchApiVacancyKeywordsResponse } from '../models/vacancy-search-api-vacancy-keywords-response'

@Injectable({
  providedIn: 'root'
})
export class VacancySearchApiService extends BaseService {
  public constructor(config: VacancySearchDataAccessConfiguration, http: HttpClient) {
    super(config, http)
  }

  /**
   * Path part for operation healthHealthGet
   */
  public static readonly HealthHealthGetPath = '/health'

  /**
   * Entrypoint for health check.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `healthHealthGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  healthHealthGet$Response(params?: {}): Observable<StrictHttpResponse<any>> {
    const rb = new RequestBuilder(this.rootUrl, VacancySearchApiService.HealthHealthGetPath, 'get')
    if (params) {
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json'
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<any>
        })
      )
  }

  /**
   * Entrypoint for health check.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `healthHealthGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  healthHealthGet(params?: {}): Observable<any> {
    return this.healthHealthGet$Response(params).pipe(map((r: StrictHttpResponse<any>) => r.body as any))
  }

  /**
   * Path part for operation healthHealthGet_1
   */
  public static readonly HealthHealthGet_1Path = '/health/'

  /**
   * Entrypoint for health check.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `healthHealthGet_1()` instead.
   *
   * This method doesn't expect any request body.
   */
  healthHealthGet_1$Response(params?: {}): Observable<StrictHttpResponse<any>> {
    const rb = new RequestBuilder(this.rootUrl, VacancySearchApiService.HealthHealthGet_1Path, 'get')
    if (params) {
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json'
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<any>
        })
      )
  }

  /**
   * Entrypoint for health check.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `healthHealthGet_1$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  healthHealthGet_1(params?: {}): Observable<any> {
    return this.healthHealthGet_1$Response(params).pipe(map((r: StrictHttpResponse<any>) => r.body as any))
  }

  /**
   * Path part for operation vacancyKeywordsVacancyKeywordsVacancyIdGet
   */
  public static readonly VacancyKeywordsVacancyKeywordsVacancyIdGetPath = '/vacancy/keywords/{vacancy_id}'

  /**
   * Get vacancy keywords.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `vacancyKeywordsVacancyKeywordsVacancyIdGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  vacancyKeywordsVacancyKeywordsVacancyIdGet$Response(params: { vacancy_id: number }): Observable<StrictHttpResponse<VacancySearchApiVacancyKeywordsResponse>> {
    const rb = new RequestBuilder(this.rootUrl, VacancySearchApiService.VacancyKeywordsVacancyKeywordsVacancyIdGetPath, 'get')
    if (params) {
      rb.path('vacancy_id', params.vacancy_id, {})
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json'
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<VacancySearchApiVacancyKeywordsResponse>
        })
      )
  }

  /**
   * Get vacancy keywords.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `vacancyKeywordsVacancyKeywordsVacancyIdGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  vacancyKeywordsVacancyKeywordsVacancyIdGet(params: { vacancy_id: number }): Observable<VacancySearchApiVacancyKeywordsResponse> {
    return this.vacancyKeywordsVacancyKeywordsVacancyIdGet$Response(params).pipe(
      map((r: StrictHttpResponse<VacancySearchApiVacancyKeywordsResponse>) => r.body as VacancySearchApiVacancyKeywordsResponse)
    )
  }

  /**
   * Path part for operation searchSearchGet
   */
  public static readonly SearchSearchGetPath = '/search/'

  /**
   * Vacancy search endpoint.
   *
   * Search vacancies.
   *
   * Arguments:
   * - ``type`` (str): request type, should be one of 'search' or 'geo_agg'.
   *     'search' - simple search (default).
   *     'geo_agg' - search vacancies in geolocation clusters.
   * - ``keyWords`` (str)
   * - ``additionalKeyWords`` (str)
   * - ``page`` (int)
   * - ``lang`` (str): 'ua' or 'ru'
   * - ``uid`` (int): user identifier, default 0
   * - ``cid`` (str): client identifier, default ''
   * - ``count`` (int): number of documents to show, default 20
   * - ``sort`` (str): sorting order, can be by 'score' and by 'geo',
   *     default '' (by score)
   * - ``lat`` (float): latitude, used for searching by geolocation,
   *     default 0.0
   * - ``lon`` (float): longitude, used for searching by geolocation,
   *     default 0.0
   * - ``cityId`` (int): city id, used for filtering vacancies
   *     (or more simple - search in some city),
   *     default 0.0
   * - ``salary`` (int): salary, default 0
   * - ``noSalary`` (str): search vacancies with or without salary,
   *     expected 'true' or 'false', default 'true'
   * - ``lastViewDate`` (str): add 'lastViewDate' field to vacancies,
   *     expected 'true' or 'false', default 'false' (works only with
   *     search requests)
   * - ``scheduleId`` (int): filter by schedule, default 0
   * - ``profLevelIds`` (str): filter by prof level ids,
   *     expects a string with ids separated by ',', default ''
   * - ``rubricIds`` (str): filter by rubric ids,
   *     expects a string with ids separated by ',', default ''
   * - ``parentId`` (int): filter by parent id, default 0
   * - ``period`` (int): filter by period, default 0
   * - ``show_agency`` (str): filter vacancies from agencies,
   *     expected 'true' or 'false', default 'true'
   * - ``district`` (str): filter by districts,
   *     expected district ids separated by ',', default '[]'
   * - ``metro`` (str): filter by metro stations,
   *     expected metro ids separated by ',', default '[]'
   * - ``top_left_lat`` (float): latitude ot top left
   *     geolocation point
   * - ``top_left_lon`` (float): longitude ot top left
   *     geolocation point
   * - ``bottom_right_lat`` (float): latitude ot bottom right
   *     geolocation point
   * - ``bottom_right_lon`` (float): longitude ot bottom right
   *     geolocation point
   * - ``geohash`` (str): geohash string, shold be a string
   *     with lengths 1-12 including.
   * - ``precision`` (int): precision of geological aggregation.
   *     Valid values - 1,..,12.
   *     If specified then output will be modified - with an empty
   *     list of documents and with additional field 'geo_agg'
   *     with information about geolocation clusters and number
   *     of documents which matched to filters in this clusters.
   *     Default 0
   * - ``debug`` (int): flag for debug mode, default 0
   * - ``debugVacancyId`` (int): debug vacancy, default None
   *
   * **NOTE:** if specified ``type=geo_agg`` then
   * will be removed field 'documents' and added new field
   * 'geo_agg' with information about clusters.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `searchSearchGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  searchSearchGet$Response(params?: {
    type?: string
    keyWords?: string
    additionalKeyWords?: string
    page?: number
    lang?: string
    uid?: number
    cid?: string
    count?: number
    sort?: string
    lat?: number
    lon?: number
    cityId?: number
    salary?: number
    noSalary?: string
    lastViewDate?: string
    scheduleId?: number
    profLevelIds?: string
    rubricIds?: string
    parentId?: number
    period?: number
    showAgency?: string
    district?: string
    metro?: string
    top_left_lat?: number
    top_left_lon?: number
    bottom_right_lat?: number
    bottom_right_lon?: number
    geohash?: string
    precision?: number
    debug?: number
    debugVacancyId?: number
  }): Observable<StrictHttpResponse<VacancySearchApiSearchResponse>> {
    const rb = new RequestBuilder(this.rootUrl, VacancySearchApiService.SearchSearchGetPath, 'get')
    if (params) {
      rb.query('type', params.type, {})
      rb.query('keyWords', params.keyWords, {})
      rb.query('additionalKeyWords', params.additionalKeyWords, {})
      rb.query('page', params.page, {})
      rb.query('lang', params.lang, {})
      rb.query('uid', params.uid, {})
      rb.query('cid', params.cid, {})
      rb.query('count', params.count, {})
      rb.query('sort', params.sort, {})
      rb.query('lat', params.lat, {})
      rb.query('lon', params.lon, {})
      rb.query('cityId', params.cityId, {})
      rb.query('salary', params.salary, {})
      rb.query('noSalary', params.noSalary, {})
      rb.query('lastViewDate', params.lastViewDate, {})
      rb.query('scheduleId', params.scheduleId, {})
      rb.query('profLevelIds', params.profLevelIds, {})
      rb.query('rubricIds', params.rubricIds, {})
      rb.query('parentId', params.parentId, {})
      rb.query('period', params.period, {})
      rb.query('showAgency', params.showAgency, {})
      rb.query('district', params.district, {})
      rb.query('metro', params.metro, {})
      rb.query('top_left_lat', params.top_left_lat, {})
      rb.query('top_left_lon', params.top_left_lon, {})
      rb.query('bottom_right_lat', params.bottom_right_lat, {})
      rb.query('bottom_right_lon', params.bottom_right_lon, {})
      rb.query('geohash', params.geohash, {})
      rb.query('precision', params.precision, {})
      rb.query('debug', params.debug, {})
      rb.query('debugVacancyId', params.debugVacancyId, {})
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json'
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<VacancySearchApiSearchResponse>
        })
      )
  }

  /**
   * Vacancy search endpoint.
   *
   * Search vacancies.
   *
   * Arguments:
   * - ``type`` (str): request type, should be one of 'search' or 'geo_agg'.
   *     'search' - simple search (default).
   *     'geo_agg' - search vacancies in geolocation clusters.
   * - ``keyWords`` (str)
   * - ``additionalKeyWords`` (str)
   * - ``page`` (int)
   * - ``lang`` (str): 'ua' or 'ru'
   * - ``uid`` (int): user identifier, default 0
   * - ``cid`` (str): client identifier, default ''
   * - ``count`` (int): number of documents to show, default 20
   * - ``sort`` (str): sorting order, can be by 'score' and by 'geo',
   *     default '' (by score)
   * - ``lat`` (float): latitude, used for searching by geolocation,
   *     default 0.0
   * - ``lon`` (float): longitude, used for searching by geolocation,
   *     default 0.0
   * - ``cityId`` (int): city id, used for filtering vacancies
   *     (or more simple - search in some city),
   *     default 0.0
   * - ``salary`` (int): salary, default 0
   * - ``noSalary`` (str): search vacancies with or without salary,
   *     expected 'true' or 'false', default 'true'
   * - ``lastViewDate`` (str): add 'lastViewDate' field to vacancies,
   *     expected 'true' or 'false', default 'false' (works only with
   *     search requests)
   * - ``scheduleId`` (int): filter by schedule, default 0
   * - ``profLevelIds`` (str): filter by prof level ids,
   *     expects a string with ids separated by ',', default ''
   * - ``rubricIds`` (str): filter by rubric ids,
   *     expects a string with ids separated by ',', default ''
   * - ``parentId`` (int): filter by parent id, default 0
   * - ``period`` (int): filter by period, default 0
   * - ``show_agency`` (str): filter vacancies from agencies,
   *     expected 'true' or 'false', default 'true'
   * - ``district`` (str): filter by districts,
   *     expected district ids separated by ',', default '[]'
   * - ``metro`` (str): filter by metro stations,
   *     expected metro ids separated by ',', default '[]'
   * - ``top_left_lat`` (float): latitude ot top left
   *     geolocation point
   * - ``top_left_lon`` (float): longitude ot top left
   *     geolocation point
   * - ``bottom_right_lat`` (float): latitude ot bottom right
   *     geolocation point
   * - ``bottom_right_lon`` (float): longitude ot bottom right
   *     geolocation point
   * - ``geohash`` (str): geohash string, shold be a string
   *     with lengths 1-12 including.
   * - ``precision`` (int): precision of geological aggregation.
   *     Valid values - 1,..,12.
   *     If specified then output will be modified - with an empty
   *     list of documents and with additional field 'geo_agg'
   *     with information about geolocation clusters and number
   *     of documents which matched to filters in this clusters.
   *     Default 0
   * - ``debug`` (int): flag for debug mode, default 0
   * - ``debugVacancyId`` (int): debug vacancy, default None
   *
   * **NOTE:** if specified ``type=geo_agg`` then
   * will be removed field 'documents' and added new field
   * 'geo_agg' with information about clusters.
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `searchSearchGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  searchSearchGet(params?: {
    type?: string
    keyWords?: string
    additionalKeyWords?: string
    page?: number
    lang?: string
    uid?: number
    cid?: string
    count?: number
    sort?: string
    lat?: number
    lon?: number
    cityId?: number
    salary?: number
    noSalary?: string
    lastViewDate?: string
    scheduleId?: number
    profLevelIds?: string
    rubricIds?: string
    parentId?: number
    period?: number
    showAgency?: string
    district?: string
    metro?: string
    top_left_lat?: number
    top_left_lon?: number
    bottom_right_lat?: number
    bottom_right_lon?: number
    geohash?: string
    precision?: number
    debug?: number
    debugVacancyId?: number
  }): Observable<VacancySearchApiSearchResponse> {
    return this.searchSearchGet$Response(params).pipe(map((r: StrictHttpResponse<VacancySearchApiSearchResponse>) => r.body as VacancySearchApiSearchResponse))
  }

  /**
   * Path part for operation searchSearchGet_1
   */
  public static readonly SearchSearchGet_1Path = '/search'

  /**
   * Vacancy search endpoint.
   *
   * Search vacancies.
   *
   * Arguments:
   * - ``type`` (str): request type, should be one of 'search' or 'geo_agg'.
   *     'search' - simple search (default).
   *     'geo_agg' - search vacancies in geolocation clusters.
   * - ``keyWords`` (str)
   * - ``additionalKeyWords`` (str)
   * - ``page`` (int)
   * - ``lang`` (str): 'ua' or 'ru'
   * - ``uid`` (int): user identifier, default 0
   * - ``cid`` (str): client identifier, default ''
   * - ``count`` (int): number of documents to show, default 20
   * - ``sort`` (str): sorting order, can be by 'score' and by 'geo',
   *     default '' (by score)
   * - ``lat`` (float): latitude, used for searching by geolocation,
   *     default 0.0
   * - ``lon`` (float): longitude, used for searching by geolocation,
   *     default 0.0
   * - ``cityId`` (int): city id, used for filtering vacancies
   *     (or more simple - search in some city),
   *     default 0.0
   * - ``salary`` (int): salary, default 0
   * - ``noSalary`` (str): search vacancies with or without salary,
   *     expected 'true' or 'false', default 'true'
   * - ``lastViewDate`` (str): add 'lastViewDate' field to vacancies,
   *     expected 'true' or 'false', default 'false' (works only with
   *     search requests)
   * - ``scheduleId`` (int): filter by schedule, default 0
   * - ``profLevelIds`` (str): filter by prof level ids,
   *     expects a string with ids separated by ',', default ''
   * - ``rubricIds`` (str): filter by rubric ids,
   *     expects a string with ids separated by ',', default ''
   * - ``parentId`` (int): filter by parent id, default 0
   * - ``period`` (int): filter by period, default 0
   * - ``show_agency`` (str): filter vacancies from agencies,
   *     expected 'true' or 'false', default 'true'
   * - ``district`` (str): filter by districts,
   *     expected district ids separated by ',', default '[]'
   * - ``metro`` (str): filter by metro stations,
   *     expected metro ids separated by ',', default '[]'
   * - ``top_left_lat`` (float): latitude ot top left
   *     geolocation point
   * - ``top_left_lon`` (float): longitude ot top left
   *     geolocation point
   * - ``bottom_right_lat`` (float): latitude ot bottom right
   *     geolocation point
   * - ``bottom_right_lon`` (float): longitude ot bottom right
   *     geolocation point
   * - ``geohash`` (str): geohash string, shold be a string
   *     with lengths 1-12 including.
   * - ``precision`` (int): precision of geological aggregation.
   *     Valid values - 1,..,12.
   *     If specified then output will be modified - with an empty
   *     list of documents and with additional field 'geo_agg'
   *     with information about geolocation clusters and number
   *     of documents which matched to filters in this clusters.
   *     Default 0
   * - ``debug`` (int): flag for debug mode, default 0
   * - ``debugVacancyId`` (int): debug vacancy, default None
   *
   * **NOTE:** if specified ``type=geo_agg`` then
   * will be removed field 'documents' and added new field
   * 'geo_agg' with information about clusters.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `searchSearchGet_1()` instead.
   *
   * This method doesn't expect any request body.
   */
  searchSearchGet_1$Response(params?: {
    type?: string
    keyWords?: string
    additionalKeyWords?: string
    page?: number
    lang?: string
    uid?: number
    cid?: string
    count?: number
    sort?: string
    lat?: number
    lon?: number
    cityId?: number
    salary?: number
    noSalary?: string
    lastViewDate?: string
    scheduleId?: number
    profLevelIds?: string
    rubricIds?: string
    parentId?: number
    period?: number
    showAgency?: string
    district?: string
    metro?: string
    top_left_lat?: number
    top_left_lon?: number
    bottom_right_lat?: number
    bottom_right_lon?: number
    geohash?: string
    precision?: number
    debug?: number
    debugVacancyId?: number
  }): Observable<StrictHttpResponse<VacancySearchApiSearchResponse>> {
    const rb = new RequestBuilder(this.rootUrl, VacancySearchApiService.SearchSearchGet_1Path, 'get')
    if (params) {
      rb.query('type', params.type, {})
      rb.query('keyWords', params.keyWords, {})
      rb.query('additionalKeyWords', params.additionalKeyWords, {})
      rb.query('page', params.page, {})
      rb.query('lang', params.lang, {})
      rb.query('uid', params.uid, {})
      rb.query('cid', params.cid, {})
      rb.query('count', params.count, {})
      rb.query('sort', params.sort, {})
      rb.query('lat', params.lat, {})
      rb.query('lon', params.lon, {})
      rb.query('cityId', params.cityId, {})
      rb.query('salary', params.salary, {})
      rb.query('noSalary', params.noSalary, {})
      rb.query('lastViewDate', params.lastViewDate, {})
      rb.query('scheduleId', params.scheduleId, {})
      rb.query('profLevelIds', params.profLevelIds, {})
      rb.query('rubricIds', params.rubricIds, {})
      rb.query('parentId', params.parentId, {})
      rb.query('period', params.period, {})
      rb.query('showAgency', params.showAgency, {})
      rb.query('district', params.district, {})
      rb.query('metro', params.metro, {})
      rb.query('top_left_lat', params.top_left_lat, {})
      rb.query('top_left_lon', params.top_left_lon, {})
      rb.query('bottom_right_lat', params.bottom_right_lat, {})
      rb.query('bottom_right_lon', params.bottom_right_lon, {})
      rb.query('geohash', params.geohash, {})
      rb.query('precision', params.precision, {})
      rb.query('debug', params.debug, {})
      rb.query('debugVacancyId', params.debugVacancyId, {})
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json'
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<VacancySearchApiSearchResponse>
        })
      )
  }

  /**
   * Vacancy search endpoint.
   *
   * Search vacancies.
   *
   * Arguments:
   * - ``type`` (str): request type, should be one of 'search' or 'geo_agg'.
   *     'search' - simple search (default).
   *     'geo_agg' - search vacancies in geolocation clusters.
   * - ``keyWords`` (str)
   * - ``additionalKeyWords`` (str)
   * - ``page`` (int)
   * - ``lang`` (str): 'ua' or 'ru'
   * - ``uid`` (int): user identifier, default 0
   * - ``cid`` (str): client identifier, default ''
   * - ``count`` (int): number of documents to show, default 20
   * - ``sort`` (str): sorting order, can be by 'score' and by 'geo',
   *     default '' (by score)
   * - ``lat`` (float): latitude, used for searching by geolocation,
   *     default 0.0
   * - ``lon`` (float): longitude, used for searching by geolocation,
   *     default 0.0
   * - ``cityId`` (int): city id, used for filtering vacancies
   *     (or more simple - search in some city),
   *     default 0.0
   * - ``salary`` (int): salary, default 0
   * - ``noSalary`` (str): search vacancies with or without salary,
   *     expected 'true' or 'false', default 'true'
   * - ``lastViewDate`` (str): add 'lastViewDate' field to vacancies,
   *     expected 'true' or 'false', default 'false' (works only with
   *     search requests)
   * - ``scheduleId`` (int): filter by schedule, default 0
   * - ``profLevelIds`` (str): filter by prof level ids,
   *     expects a string with ids separated by ',', default ''
   * - ``rubricIds`` (str): filter by rubric ids,
   *     expects a string with ids separated by ',', default ''
   * - ``parentId`` (int): filter by parent id, default 0
   * - ``period`` (int): filter by period, default 0
   * - ``show_agency`` (str): filter vacancies from agencies,
   *     expected 'true' or 'false', default 'true'
   * - ``district`` (str): filter by districts,
   *     expected district ids separated by ',', default '[]'
   * - ``metro`` (str): filter by metro stations,
   *     expected metro ids separated by ',', default '[]'
   * - ``top_left_lat`` (float): latitude ot top left
   *     geolocation point
   * - ``top_left_lon`` (float): longitude ot top left
   *     geolocation point
   * - ``bottom_right_lat`` (float): latitude ot bottom right
   *     geolocation point
   * - ``bottom_right_lon`` (float): longitude ot bottom right
   *     geolocation point
   * - ``geohash`` (str): geohash string, shold be a string
   *     with lengths 1-12 including.
   * - ``precision`` (int): precision of geological aggregation.
   *     Valid values - 1,..,12.
   *     If specified then output will be modified - with an empty
   *     list of documents and with additional field 'geo_agg'
   *     with information about geolocation clusters and number
   *     of documents which matched to filters in this clusters.
   *     Default 0
   * - ``debug`` (int): flag for debug mode, default 0
   * - ``debugVacancyId`` (int): debug vacancy, default None
   *
   * **NOTE:** if specified ``type=geo_agg`` then
   * will be removed field 'documents' and added new field
   * 'geo_agg' with information about clusters.
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `searchSearchGet_1$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  searchSearchGet_1(params?: {
    type?: string
    keyWords?: string
    additionalKeyWords?: string
    page?: number
    lang?: string
    uid?: number
    cid?: string
    count?: number
    sort?: string
    lat?: number
    lon?: number
    cityId?: number
    salary?: number
    noSalary?: string
    lastViewDate?: string
    scheduleId?: number
    profLevelIds?: string
    rubricIds?: string
    parentId?: number
    period?: number
    showAgency?: string
    district?: string
    metro?: string
    top_left_lat?: number
    top_left_lon?: number
    bottom_right_lat?: number
    bottom_right_lon?: number
    geohash?: string
    precision?: number
    debug?: number
    debugVacancyId?: number
  }): Observable<VacancySearchApiSearchResponse> {
    return this.searchSearchGet_1$Response(params).pipe(map((r: StrictHttpResponse<VacancySearchApiSearchResponse>) => r.body as VacancySearchApiSearchResponse))
  }
}
