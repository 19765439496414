import { PATTERNS } from '@alliance/shared/constants'

export const LOCAL_PATTERNS = {
  EMAIL_PATTERN: PATTERNS.EMAIL_PATTERN,
  NAME_PATTERN: PATTERNS.NAME_PATTERN,
  NAME_AND_LAST_NAME_PATTERN: PATTERNS.NAME_AND_LAST_NAME_PATTERN,
  PHONE_PATTERN: PATTERNS.PHONE_PATTERN,
  YEAR_PATTERN: PATTERNS.YEAR_PATTERN,
  ONLY_NUMBERS_PATTERN: PATTERNS.ONLY_NUMBERS_PATTERN,
  URL_PATTERN: PATTERNS.URL_PATTERN,
  SOCIAL_NETWORKS_PATTERNS: {
    // eslint-disable-next-line no-useless-escape
    facebook: /^(https?:\/\/)?(www\.)?(facebook|fb\.com)\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)$/,
    // eslint-disable-next-line no-useless-escape
    twitter: /^(https?:\/\/)?(www\.)?(twitter\.com)\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)$/,
    // eslint-disable-next-line no-useless-escape
    linkedin: /^(https?:\/\/)?(www\.)?(linkedin\.com)\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)$/
  },
  // eslint-disable-next-line no-useless-escape
  SKYPE_USER_NAME_PATTERN: /^[a-z][a-z0-9\.,\-_]{5,31}$/i
}
