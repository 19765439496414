import { Injectable } from '@angular/core'
import { combineLatest, Observable, of, switchMap } from 'rxjs'
import { map, take } from 'rxjs/operators'
import { BreadcrumbList } from 'schema-dts'

import { ResumeInfo } from '@alliance/employer/api'
import { DictionaryService } from '@alliance/jobseeker/api'
import { timePeriodTransform } from '@alliance/shared/pipes/time-period'
import { TranslationService } from '@alliance/shared/translation'
import { GetPrepositionByWordFirstLetters } from '@alliance/shared/utils'

import { RUA_DOMAIN } from '../constants'
import { HelpersService } from '../helpers.service'
import { Translations } from '../localization/translations'
import { CandidatePageSeoParams, PartialSeoParamsResponse, PlatformHosts } from '../models'

@Injectable({
  providedIn: 'root'
})
export class CandidatePageDictionaryService {
  public constructor(private translations: Translations, private translationService: TranslationService, private dictionaryService: DictionaryService, private helpersService: HelpersService) {}

  private static getFullName(cv: ResumeInfo): string {
    return [cv.name ?? '', cv.fatherName ?? '', cv.surname ?? ''].filter(value => !!value.trim()).join(' ')
  }

  public getParams({ params: cv }: CandidatePageSeoParams): Observable<PartialSeoParamsResponse> {
    return combineLatest([this.getJsonLd(cv), this.findCityName(cv.cityId)]).pipe(
      switchMap(([jsonLD, cityName]) =>
        of({
          title: this.getTitle(cv, cityName),
          hrefLang: this.helpersService.createHrefLangURLs(`candidates/${cv.id}`, `candidates/${cv.id}`),
          description: this.getDescription(cv, cityName),
          canonicalUrl: this.helpersService.createURL(PlatformHosts.desktop, `candidates/${cv.id}`),
          jsonLd: {
            desktop: jsonLD,
            mobile: jsonLD
          }
        })
      )
    )
  }

  private findCityName(cityId = 0): Observable<string> {
    return this.dictionaryService.getCityName$(cityId, null, true).pipe(take(1))
  }

  private getInCity(cityName: string): string {
    return `${GetPrepositionByWordFirstLetters(cityName, this.translationService.currentLangIsUkrainian())} ${cityName}`
  }

  private getTitle(cv: ResumeInfo, cityName: string): string {
    return (
      this.translationService.translate(this.translations.candidatePage.resume) +
      ' ' +
      (cv.speciality || '') +
      ' ' +
      this.getInCity(cityName) +
      ' - ' +
      CandidatePageDictionaryService.getFullName(cv) +
      ', ' +
      (cv.age ?? '') +
      ' | ' +
      RUA_DOMAIN
    )
  }

  private getLastJob(cv: ResumeInfo): string {
    const lastJob = cv?.experiences?.length ? cv.experiences[0] : null

    if (!lastJob) {
      return this.translationService.translate(this.translations.candidatePage.unknown)
    }

    const periodObj = timePeriodTransform(lastJob?.period ?? '')
    const periodYears = periodObj?.years && this.translationService.translate(this.translations.candidatePage.years, { amount: periodObj.years })
    const periodMonths = periodObj?.months && this.translationService.translate(this.translations.candidatePage.months, { amount: periodObj.months })
    const parsedPeriod = [periodYears, periodMonths].filter(v => !!v).join(' ')
    return [lastJob.position ?? '', lastJob.company ?? '', parsedPeriod]
      .map(value => value.trim())
      .filter(value => !!value)
      .join(', ')
  }

  private getDescription(cv: ResumeInfo, cityName: string): string {
    return (
      CandidatePageDictionaryService.getFullName(cv) +
      ', ' +
      (cv.speciality || '') +
      ' - ' +
      this.translationService.translate(this.translations.candidatePage.jobSearch) +
      ' ' +
      this.getInCity(cityName) +
      '. ' +
      this.translationService.translate(this.translations.candidatePage.keyInfo) +
      ': ' +
      (cv.age ?? '') +
      ', ' +
      this.translationService.translate(this.translations.candidatePage.lastJob) +
      ': ' +
      this.getLastJob(cv)
    )
  }

  private getJsonLd(cv: ResumeInfo): Observable<string> {
    const platform = PlatformHosts.desktop

    return combineLatest([
      this.helpersService.createDesktopCandidatesListUrl$(cv.cityId || 0, cv?.speciality ?? ''),
      this.helpersService.createDesktopCandidatesListUrl$(cv.cityId || 0, 'all'),
      this.dictionaryService.getCityList$(false, null)
    ]).pipe(
      map(([urlWithKeyword, candidatesUrl, cityList]) => {
        const cityItem = cityList.find(city => city.id === (cv.cityId || 0))
        const speciality = cv.speciality
          ? {
              '@type': 'ListItem',
              position: 4,
              name: cv.speciality,
              item: urlWithKeyword
            }
          : null
        return this.helpersService.createJsonLd<BreadcrumbList>(platform, {
          '@context': 'https://schema.org',
          '@type': 'BreadcrumbList',
          itemListElement: [
            this.helpersService.getHomePageBreadcrumb(platform),
            this.helpersService.getCandidatesBreadcrumb(platform),
            {
              '@type': 'ListItem',
              position: 3,
              name: this.translationService.currentLangIsUkrainian() ? cityItem?.ua || '' : cityItem?.ru || '',
              item: candidatesUrl
            },
            speciality
          ]
        })
      })
    )
  }
}
