import { Inject, Injectable } from '@angular/core'
import { ActivatedRouteSnapshot, CanActivate } from '@angular/router'
import { Observable, race, timer } from 'rxjs'
import { AuthService } from '@alliance/shared/auth/api'
import { filter, map, take } from 'rxjs/operators'
import { DOCUMENT } from '@angular/common'
import { Environment } from '@alliance/shared/environment'

@Injectable()
export class ManagerGuard implements CanActivate {
  private timer$: Observable<boolean> = timer(5000).pipe(map(d => !!d))

  public constructor(@Inject(DOCUMENT) private document: Document, private authService: AuthService, private environment: Environment) {}

  private get isManager(): boolean {
    return /@rabota(.com)?.ua/.test(this.authService.user?.email ?? '')
  }

  public canActivate(childRoute: ActivatedRouteSnapshot): Observable<boolean> {
    return race(this.authService.tokenAppeared$.pipe(filter(Boolean)), this.timer$).pipe(
      take(1),
      map(result => {
        const queryParamsArray = Object.entries<string>(childRoute.queryParams ?? {})

        if (!result) {
          const queryParamsString = queryParamsArray.map(([key, value]) => `${key}=${value}`).join('&')
          const redirectUrl = encodeURIComponent('/my/users/analytics' + (queryParamsArray.length ? `?${queryParamsString}` : ''))

          this.changeUrl(`${this.environment.mainSiteUrl ?? 'https://rabota.ua'}/employer/login?redirectUrl=${redirectUrl}`)

          return false
        }

        if (!this.isManager) {
          this.changeUrl(`${this.environment.mainSiteUrl ?? 'https://rabota.ua'}/employer`)
          return false
        }

        return true
      })
    )
  }

  private changeUrl(url: string): void {
    if (this.document.defaultView) {
      this.document.defaultView.location.href = url
    }
  }
}
