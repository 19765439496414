/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core'
import { HttpClient, HttpResponse, HttpContext } from '@angular/common/http'
import { BaseService } from '../base-service'
import { UsersOpenApiModuleConfig } from '../users-open-api-module-config'
import { StrictHttpResponse } from '../strict-http-response'
import { RequestBuilder } from '../request-builder'
import { Observable } from 'rxjs'
import { map, filter } from 'rxjs/operators'

import { CountryModelOutput } from '../models/country-model-output'

@Injectable({
  providedIn: 'root'
})
export class UsersOpenApiUserCountryService extends BaseService {
  constructor(config: UsersOpenApiModuleConfig, http: HttpClient) {
    super(config, http)
  }

  /**
   * Path part for operation userCountryGet
   */
  static readonly UserCountryGetPath = '/UserCountry'

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `userCountryGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  userCountryGet$Plain$Response(params?: {}, context?: HttpContext): Observable<StrictHttpResponse<CountryModelOutput>> {
    const rb = new RequestBuilder(this.rootUrl, UsersOpenApiUserCountryService.UserCountryGetPath, 'get')
    if (params) {
    }

    return this.http
      .request(
        rb.build({
          responseType: 'text',
          accept: 'text/plain',
          context: context
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<CountryModelOutput>
        })
      )
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `userCountryGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  userCountryGet$Plain(params?: {}, context?: HttpContext): Observable<CountryModelOutput> {
    return this.userCountryGet$Plain$Response(params, context).pipe(map((r: StrictHttpResponse<CountryModelOutput>) => r.body as CountryModelOutput))
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `userCountryGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  userCountryGet$Json$Response(params?: {}, context?: HttpContext): Observable<StrictHttpResponse<CountryModelOutput>> {
    const rb = new RequestBuilder(this.rootUrl, UsersOpenApiUserCountryService.UserCountryGetPath, 'get')
    if (params) {
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'text/json',
          context: context
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<CountryModelOutput>
        })
      )
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `userCountryGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  userCountryGet$Json(params?: {}, context?: HttpContext): Observable<CountryModelOutput> {
    return this.userCountryGet$Json$Response(params, context).pipe(map((r: StrictHttpResponse<CountryModelOutput>) => r.body as CountryModelOutput))
  }

  /**
   * Path part for operation userCountryIpAddressGet
   */
  static readonly UserCountryIpAddressGetPath = '/UserCountry/{ipAddress}'

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `userCountryIpAddressGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  userCountryIpAddressGet$Response(
    params: {
      ipAddress: string
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<void>> {
    const rb = new RequestBuilder(this.rootUrl, UsersOpenApiUserCountryService.UserCountryIpAddressGetPath, 'get')
    if (params) {
      rb.path('ipAddress', params.ipAddress, {})
    }

    return this.http
      .request(
        rb.build({
          responseType: 'text',
          accept: '*/*',
          context: context
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>
        })
      )
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `userCountryIpAddressGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  userCountryIpAddressGet(
    params: {
      ipAddress: string
    },
    context?: HttpContext
  ): Observable<void> {
    return this.userCountryIpAddressGet$Response(params, context).pipe(map((r: StrictHttpResponse<void>) => r.body as void))
  }
}
