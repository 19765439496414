import { Injectable } from '@angular/core'
import { getOperationName } from '@apollo/client/utilities'
import { Observable, of } from 'rxjs'
import { catchError, map, take } from 'rxjs/operators'

import { GetAllPublicationServicesDocument, GetEmployerCommonLimitsDocument } from '@alliance/employer/gql-domain'
import { VacancyClosingBehavior } from '@alliance/shared/domain-gql'
import { getNonNullableItems } from '@alliance/shared/utils'

import { CheckCorrectVacancyForPublishGQL, VacancyChangeClosingBehaviorGQL, VacancyPublishGQL, VacancyRepublishGQL } from './publish-vacancy.generated'
import { PublishVacancyParams } from '../../../models/vacancy-actions/publish-vacancy/publish-vacancy-params.interface'
import { ChangeVacancyClosingBehaviorResponse, PublishVacancyResponse } from '../../../models/vacancy-actions/publish-vacancy/publish-vacancy-response.interface'

@Injectable()
export class PublishVacancyService {
  public constructor(
    private readonly vacancyPublishGQL: VacancyPublishGQL,
    private readonly vacancyRepublishGQL: VacancyRepublishGQL,
    private readonly vacancyClosingBehaviourGQL: VacancyChangeClosingBehaviorGQL,
    private readonly checkCorrectVacancyForPublishGQL: CheckCorrectVacancyForPublishGQL
  ) {}

  public publishVacancy$(params: PublishVacancyParams, isLimitationScenario: boolean): Observable<PublishVacancyResponse | null> {
    if (!params.vacancies.length) {
      return of(null)
    }

    return this.vacancyPublishGQL
      .mutate(
        {
          input: {
            vacancies: params.vacancies,
            publicationType: params.vacancies[0].publicationType, // is going to be removed after schema change
            vacancyId: params.vacancies[0].vacancyId.toString() // is going to be removed after schema change
          }
        },
        { refetchQueries: this.getQueriesNameToRefetch(isLimitationScenario) }
      )
      .pipe(
        take(1),
        map(
          ({ data }) => ({
            vacancies: getNonNullableItems(data?.vacancyPublish?.vacancies ?? []),
            errors: getNonNullableItems(data?.vacancyPublish?.errors ?? [])
          }),
          catchError(() => of(null))
        )
      )
  }

  public republishVacancy$(params: PublishVacancyParams, isLimitationScenario: boolean): Observable<PublishVacancyResponse | null> {
    if (!params.vacancies.length) {
      return of(null)
    }

    return this.vacancyRepublishGQL
      .mutate(
        {
          input: {
            vacancies: params.vacancies,
            publicationType: params.vacancies[0].publicationType, // is going to be removed after schema change
            vacancyId: params.vacancies[0].vacancyId.toString() // is going to be removed after schema change
          }
        },
        { refetchQueries: this.getQueriesNameToRefetch(isLimitationScenario) }
      )
      .pipe(
        take(1),
        map(
          ({ data }) => ({
            vacancies: getNonNullableItems(data?.vacancyRepublish?.vacancies ?? []),
            errors: getNonNullableItems(data?.vacancyRepublish?.errors ?? [])
          }),
          catchError(() => of(null))
        )
      )
  }

  public changeVacancyClosingBehavior$(params: PublishVacancyParams, behavior: VacancyClosingBehavior | null = null): Observable<ChangeVacancyClosingBehaviorResponse | null> {
    if (!params.vacancies.length || !behavior) {
      return of(null)
    }

    return this.vacancyClosingBehaviourGQL
      .mutate({
        input: {
          behavior, // is going to be removed after schema change
          vacancyId: params.vacancies[0].vacancyId.toString(), // is going to be removed after schema change
          vacancies: params.vacancies.map(({ vacancyId }) => ({ vacancyId, behavior }))
        }
      })
      .pipe(
        take(1),
        map(({ data }) => ({
          vacancies: getNonNullableItems(data?.vacancyChangeClosingBehavior?.vacancies || [])
        })),
        catchError(() => of(null))
      )
  }

  public checkCorrectVacancyForPublish$(vacancyId: string): Observable<boolean> {
    return this.checkCorrectVacancyForPublishGQL.fetch({ vacancyId }, { fetchPolicy: 'network-only' }).pipe(
      map(({ data }) => !!data?.checkVacancyContentCorrectnessForPublishing?.isCorrect),
      catchError(() => of(false))
    )
  }

  private getQueriesNameToRefetch(isLimitationScenario: boolean): string[] {
    return [getOperationName(GetAllPublicationServicesDocument), ...(isLimitationScenario ? [getOperationName(GetEmployerCommonLimitsDocument)] : [])].filter<string>(
      (name): name is string => typeof name === 'string'
    )
  }
}
