import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { HttpClientModule } from '@angular/common/http'
import { IconComponent } from './icon-component/icon.component'
import { SharedPipesBypassSanitizerModule } from '@alliance/shared/pipes/bypass-sanitizer'

@NgModule({
  imports: [CommonModule, HttpClientModule, SharedPipesBypassSanitizerModule],
  exports: [IconComponent],
  declarations: [IconComponent]
})
export class SharedIconsModule {}
