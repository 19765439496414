import { Injectable, NgZone } from '@angular/core'
import { MonoTypeOperatorFunction, Observable } from 'rxjs'

@Injectable({ providedIn: 'root' })
export class NgZoneHelperService {
  public constructor(private ngZone: NgZone) {}

  public outsideNgZone<T>(): MonoTypeOperatorFunction<T> {
    return (source: Observable<T>): Observable<T> =>
      new Observable<T>(observer =>
        this.ngZone.runOutsideAngular(() =>
          source.subscribe(
            (value: T) => observer.next(value),
            (error: unknown) => observer.error(error),
            () => observer.complete()
          )
        )
      )
  }
}
