import { ComponentRef, Injectable, TemplateRef, Type, ViewContainerRef } from '@angular/core'
import { ComponentPortal, TemplatePortal } from '@angular/cdk/portal'
import { asyncScheduler, timer } from 'rxjs'
import { ModalOverlayRef, ModalService } from '../modal'
import { MobileFullScreenModalComponent } from '../../components/mobile-full-screen-modal/mobile-full-screen-modal.component'

@Injectable({
  providedIn: 'root'
})
export class MobileFullScreenModalService extends ModalService {
  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  protected override readonly hostComponent: any = MobileFullScreenModalComponent

  public override openByComponent<T, K>(content: Type<T>, data?: K): ModalOverlayRef {
    const overlayRef = this.createOverlay()
    const portal = new ComponentPortal(content)
    const modalRef = new ModalOverlayRef(overlayRef)
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const injector = this.createInjector<K>(modalRef, data)
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
    const modalPortal = new ComponentPortal<MobileFullScreenModalComponent<T>>(this.hostComponent, null, injector)

    timer(0, asyncScheduler).subscribe(() => {
      const modalComponent: ComponentRef<MobileFullScreenModalComponent<T>> = overlayRef.attach(modalPortal)
      modalComponent.instance.content = portal
    })

    return modalRef
  }

  public override openByTemplate<K>(content: TemplateRef<K>, context?: K, viewContainerRef: ViewContainerRef | null = null): ModalOverlayRef {
    const overlayRef = this.createOverlay()
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const portal = new TemplatePortal(content, viewContainerRef, context)
    const modalRef = new ModalOverlayRef(overlayRef)
    const modalPortal = new ComponentPortal<MobileFullScreenModalComponent<K>>(
      // TODO: Fix this the next time the file is edited.
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
      this.hostComponent,
      viewContainerRef,
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      this.createInjector<K>(modalRef, context)
    )

    timer(0, asyncScheduler).subscribe(() => {
      const modalComponent: ComponentRef<MobileFullScreenModalComponent<K>> = overlayRef.attach(modalPortal)
      modalComponent.instance.content = portal
    })

    return modalRef
  }
}
