const LINE_HEIGHT = {
  zero: '0',
  none: '1',
  10: '10px',
  20: '20px',
  30: '30px',
  40: '40px',
  50: '50px'
}

module.exports = { LINE_HEIGHT }
