export const UKRAINE_PHONE_PREFIX = '38'
export const PHONE_WITHOUT_CODE_REGEXP = /^0[1-9]+/

export const clearPhoneFromSymbols = (phone: string): string => phone?.trim()?.replace(/-|\s|\(|\)/gi, '') || ''

export const normalizePhone = (phone: string, addPlus = false): string => {
  const cleared = clearPhoneFromSymbols(phone)
  const normalizedPhone = PHONE_WITHOUT_CODE_REGEXP.test(cleared) ? `${UKRAINE_PHONE_PREFIX}${cleared}` : cleared

  return normalizedPhone.startsWith(UKRAINE_PHONE_PREFIX) && addPlus ? `+${normalizedPhone}` : normalizedPhone
}
