import { log } from '@alliance/shared/logger'
import { RxStateComponent } from '@alliance/shared/models'
import { DetectPlatformService } from '@alliance/shared/utils'
import { ChangeDetectionStrategy, Component, Input } from '@angular/core'
import { combineLatest, of } from 'rxjs'
import { filter, map, switchMap } from 'rxjs/operators'
import { IconName } from '../autogenerated/models/icon-model'
import { IconsService } from '../services/icons.service'

@Component({
  selector: 'alliance-icon',
  templateUrl: './icon.component.html',
  styleUrls: ['./icon.component.tw.css'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class IconComponent extends RxStateComponent<{
  name: IconName | null
  inheritColor: boolean
  widthPercent: number | null
  svgElement: string
  width: number
  color: string
}> {
  public readonly DEFAULT_WIDTH = 20 // px

  public readonly state = this.connectViewState(['svgElement'])

  public constructor(private iconsService: IconsService, private detectPlatform: DetectPlatformService) {
    super()

    this.initState({
      name: null,
      inheritColor: false,
      widthPercent: null,
      svgElement: this.detectPlatform.isBrowser
        ? this.onInit$.pipe(
            switchMap(() => combineLatest([this.select('color'), this.select('width'), this.select('inheritColor')])),
            switchMap(() => this.select('name')),
            filter((v): v is IconName => !!v),
            switchMap(name => this.iconsService.getIconData$(name)),
            map(svg => this.setSvgWidth(this.setSvgColor(svg, this.get('color'))))
          )
        : of(''),
      color: '',
      width: this.DEFAULT_WIDTH
    })
  }

  public get getWidth(): string {
    const { width, widthPercent } = this.get() || {}
    return widthPercent ? `${widthPercent}%` : `${width || this.DEFAULT_WIDTH}px`
  }

  @Input()
  public set inheritColor(inheritColor: boolean) {
    this.set({ inheritColor })
  }

  @Input()
  public set widthPercent(widthPercent: number) {
    this.set({ widthPercent })
  }

  @Input()
  public set color(color: string) {
    this.set({ color })
  }

  @Input()
  public set name(name: IconName) {
    this.set({ name })
  }

  @Input()
  public set width(width: number) {
    this.set({ width })
  }

  private setSvgColor(svgData: string, hexValue: string): string {
    if (this.detectPlatform.isServer) {
      return ''
    }

    const hexPattern = /(#([A-Fa-f0-9]{6}|[A-Fa-f0-9]{3}))|(currentColor)/g
    const isValidHex = !!hexValue.match(hexPattern)?.length

    if (!svgData) {
      log.warn({ where: 'shared-icons: IconComponent', category: 'unexpected_value', message: 'setSvgColor - invalid svgData', svgData })
      return ''
    }
    if (this.get('inheritColor')) {
      return svgData.replace(hexPattern, 'currentColor')
    }
    if (!isValidHex) {
      if (hexValue) {
        log.warn({ where: 'shared-icons: IconComponent', category: 'unexpected_value', message: 'color is not hex formatted', hexValue })
      }
      return svgData
    }
    return svgData.replace(hexPattern, hexValue)
  }

  private setSvgWidth(svgString: string = ''): string {
    return svgString.replace(/(<svg\s)/, `$1 style="width:${this.getWidth};" `)
  }
}
