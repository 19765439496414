/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { Observable } from 'rxjs'
import { map } from 'rxjs/operators'

import { BaseService } from '../base-service'
import { TagsSuggesterDataAccessConfiguration } from '../tags-suggester-data-access-configuration'
import { StrictHttpResponse } from '../strict-http-response'

import { topTagsByNameEndpointTopNameGet } from '../fn/top/top-tags-by-name-endpoint-top-name-get'
import { topTagsByNameEndpointTopNameGet_1 } from '../fn/top/top-tags-by-name-endpoint-top-name-get-1'
import { TopTagsByNameEndpointTopNameGet_1$Params } from '../fn/top/top-tags-by-name-endpoint-top-name-get-1'
import { TopTagsByNameEndpointTopNameGet$Params } from '../fn/top/top-tags-by-name-endpoint-top-name-get'
import { topTagsEndpointTopGet } from '../fn/top/top-tags-endpoint-top-get'
import { topTagsEndpointTopGet_1 } from '../fn/top/top-tags-endpoint-top-get-1'
import { TopTagsEndpointTopGet_1$Params } from '../fn/top/top-tags-endpoint-top-get-1'
import { TopTagsEndpointTopGet$Params } from '../fn/top/top-tags-endpoint-top-get'
import { TopTagsResponse } from '../models/top-tags-response'

@Injectable({ providedIn: 'root' })
export class TagsSuggesterTopService extends BaseService {
  constructor(config: TagsSuggesterDataAccessConfiguration, http: HttpClient) {
    super(config, http)
  }

  /** Path part for operation `topTagsEndpointTopGet()` */
  static readonly TopTagsEndpointTopGetPath = '/top/'

  /**
   * Find closest tags (by some distance) to profession.
   *
   * Find closest tags (by some distance) to profession. <br><br>
   *
   * `tag (int)` - tag id <br>
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `topTagsEndpointTopGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  topTagsEndpointTopGet$Response(params: TopTagsEndpointTopGet$Params, context?: HttpContext): Observable<StrictHttpResponse<TopTagsResponse>> {
    return topTagsEndpointTopGet(this.http, this.rootUrl, params, context)
  }

  /**
   * Find closest tags (by some distance) to profession.
   *
   * Find closest tags (by some distance) to profession. <br><br>
   *
   * `tag (int)` - tag id <br>
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `topTagsEndpointTopGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  topTagsEndpointTopGet(params: TopTagsEndpointTopGet$Params, context?: HttpContext): Observable<TopTagsResponse> {
    return this.topTagsEndpointTopGet$Response(params, context).pipe(map((r: StrictHttpResponse<TopTagsResponse>): TopTagsResponse => r.body))
  }

  /** Path part for operation `topTagsEndpointTopGet_1()` */
  static readonly TopTagsEndpointTopGet_1Path = '/top'

  /**
   * Find closest tags (by some distance) to profession.
   *
   * Find closest tags (by some distance) to profession. <br><br>
   *
   * `tag (int)` - tag id <br>
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `topTagsEndpointTopGet_1()` instead.
   *
   * This method doesn't expect any request body.
   */
  topTagsEndpointTopGet_1$Response(params: TopTagsEndpointTopGet_1$Params, context?: HttpContext): Observable<StrictHttpResponse<TopTagsResponse>> {
    return topTagsEndpointTopGet_1(this.http, this.rootUrl, params, context)
  }

  /**
   * Find closest tags (by some distance) to profession.
   *
   * Find closest tags (by some distance) to profession. <br><br>
   *
   * `tag (int)` - tag id <br>
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `topTagsEndpointTopGet_1$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  topTagsEndpointTopGet_1(params: TopTagsEndpointTopGet_1$Params, context?: HttpContext): Observable<TopTagsResponse> {
    return this.topTagsEndpointTopGet_1$Response(params, context).pipe(map((r: StrictHttpResponse<TopTagsResponse>): TopTagsResponse => r.body))
  }

  /** Path part for operation `topTagsByNameEndpointTopNameGet()` */
  static readonly TopTagsByNameEndpointTopNameGetPath = '/top/name/'

  /**
   * Find closest tags (by some distance) to profession.
   *
   * Find closest tags (by some distance) to profession. <br><br>
   *
   * `tag (str)` - tag name<br>
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `topTagsByNameEndpointTopNameGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  topTagsByNameEndpointTopNameGet$Response(params: TopTagsByNameEndpointTopNameGet$Params, context?: HttpContext): Observable<StrictHttpResponse<TopTagsResponse>> {
    return topTagsByNameEndpointTopNameGet(this.http, this.rootUrl, params, context)
  }

  /**
   * Find closest tags (by some distance) to profession.
   *
   * Find closest tags (by some distance) to profession. <br><br>
   *
   * `tag (str)` - tag name<br>
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `topTagsByNameEndpointTopNameGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  topTagsByNameEndpointTopNameGet(params: TopTagsByNameEndpointTopNameGet$Params, context?: HttpContext): Observable<TopTagsResponse> {
    return this.topTagsByNameEndpointTopNameGet$Response(params, context).pipe(map((r: StrictHttpResponse<TopTagsResponse>): TopTagsResponse => r.body))
  }

  /** Path part for operation `topTagsByNameEndpointTopNameGet_1()` */
  static readonly TopTagsByNameEndpointTopNameGet_1Path = '/top/name'

  /**
   * Find closest tags (by some distance) to profession.
   *
   * Find closest tags (by some distance) to profession. <br><br>
   *
   * `tag (str)` - tag name<br>
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `topTagsByNameEndpointTopNameGet_1()` instead.
   *
   * This method doesn't expect any request body.
   */
  topTagsByNameEndpointTopNameGet_1$Response(params: TopTagsByNameEndpointTopNameGet_1$Params, context?: HttpContext): Observable<StrictHttpResponse<TopTagsResponse>> {
    return topTagsByNameEndpointTopNameGet_1(this.http, this.rootUrl, params, context)
  }

  /**
   * Find closest tags (by some distance) to profession.
   *
   * Find closest tags (by some distance) to profession. <br><br>
   *
   * `tag (str)` - tag name<br>
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `topTagsByNameEndpointTopNameGet_1$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  topTagsByNameEndpointTopNameGet_1(params: TopTagsByNameEndpointTopNameGet_1$Params, context?: HttpContext): Observable<TopTagsResponse> {
    return this.topTagsByNameEndpointTopNameGet_1$Response(params, context).pipe(map((r: StrictHttpResponse<TopTagsResponse>): TopTagsResponse => r.body))
  }
}
