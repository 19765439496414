import { Environment } from '@alliance/shared/environment'

// take from https://raw.githubusercontent.com/cyclosproject/ng-openapi-gen/master/templates/module.handlebars
// and comment code from constructor and below

import { NgModule, ModuleWithProviders } from '@angular/core'
import { NotificationDataAccessConfiguration, NotificationDataAccessConfigurationParams } from './notification-data-access-configuration'

import { ChatWizardService } from './services/chat-wizard.service'
import { ConversationService } from './services/conversation.service'
import { ConversationMassActionsService } from './services/conversation-mass-actions.service'
import { ConversationsService } from './services/conversations.service'
import { ImageService } from './services/image.service'
import { MessagesService } from './services/messages.service'
import { NotReadMessagesService } from './services/not-read-messages.service'
import { StatusesService } from './services/statuses.service'

/**
 * Module that provides all services and configuration.
 */
@NgModule({
  imports: [],
  exports: [],
  declarations: [],
  providers: [
    ChatWizardService,
    ConversationService,
    ConversationMassActionsService,
    ConversationsService,
    ImageService,
    MessagesService,
    NotReadMessagesService,
    StatusesService,
    {
      provide: NotificationDataAccessConfiguration,
      useFactory: (environment: Environment): NotificationDataAccessConfigurationParams => ({
        rootUrl: environment.chatApi
      }),
      deps: [Environment]
    }
  ]
})
export class ChatDataAccessModule {
  static forRoot(params: NotificationDataAccessConfigurationParams): ModuleWithProviders<ChatDataAccessModule> {
    return {
      ngModule: ChatDataAccessModule,
      providers: [
        {
          provide: NotificationDataAccessConfiguration,
          useValue: params
        }
      ]
    }
  }

  /*
    constructor(
      @Optional() @SkipSelf() parentModule: ChatDataAccessModule,
      @Optional() http: HttpClient
    ) {

      if (parentModule) {
        throw new Error('ChatDataAccessModule is already loaded. Import in your base AppModule only.');
      }
      if (!http) {
        throw new Error('You need to import the HttpClientModule in your AppModule! \n' +
          'See also https://github.com/angular/angular/issues/20575');
      }

    } */
}
