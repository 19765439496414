export const prettyNumberTransform = (number: number | string | null | undefined, type: 'int' | 'float' = 'int'): string | number => {
  if (!number || isNaN(+number)) {
    return number ?? ''
  }

  const stringifiedNumber = `${number}`

  const _formatNumber = (data: string): string => data.replace(/\B(?=(\d{3})+(?!\d))/g, ' ')

  if (type === 'int') {
    return _formatNumber(parseInt(stringifiedNumber).toString())
  }

  if (type === 'float') {
    return _formatNumber(parseFloat(stringifiedNumber).toFixed(2))
  }

  return _formatNumber(stringifiedNumber)
}
