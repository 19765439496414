import { Environment } from '@alliance/shared/environment'
import { NgModule, ModuleWithProviders } from '@angular/core'
import { HttpClientModule } from '@angular/common/http'
import { EmployerDataAccessConfiguration, EmployerDataAccessConfigurationInterface } from './employer-data-access-configuration'

import { AccountService } from './services/account.service'
import { ApplyService } from './services/apply.service'
import { BotService } from './services/bot.service'
import { CartService } from './services/cart.service'
import { CheckerService } from './services/checker.service'
import { CvDbService } from './services/cv-db.service'
import { NoteService } from './services/note.service'
import { NotebookService } from './services/notebook.service'
import { NotebookProfileService } from './services/notebook-profile.service'
import { ResumeService } from './services/resume.service'
import { ServiceService } from './services/service.service'
import { StaticticsService } from './services/statictics.service'
import { VacancyService } from './services/vacancy.service'
import { VacancyImageService } from './services/vacancy-image.service'
import { ValuesService } from './services/values.service'

/**
 * Provider for all EmployerDataAccess services, plus EmployerDataAccessConfiguration
 */
@NgModule({
  imports: [HttpClientModule],
  exports: [HttpClientModule],
  declarations: [],
  providers: [
    {
      provide: EmployerDataAccessConfiguration,
      useFactory: (environment: Environment): EmployerDataAccessConfigurationInterface => ({
        rootUrl: environment.employerApi
      }),
      deps: [Environment]
    },
    AccountService,
    ApplyService,
    BotService,
    CartService,
    CheckerService,
    CvDbService,
    NoteService,
    NotebookService,
    NotebookProfileService,
    ResumeService,
    ServiceService,
    StaticticsService,
    VacancyService,
    VacancyImageService,
    ValuesService
  ]
})
export class EmployerDataAccessModule {
  public static forRoot(customParams?: EmployerDataAccessConfigurationInterface): ModuleWithProviders<EmployerDataAccessModule> {
    return {
      ngModule: EmployerDataAccessModule,
      providers: [
        customParams && customParams.rootUrl
          ? {
              provide: EmployerDataAccessConfiguration,
              useValue: { rootUrl: customParams.rootUrl }
            }
          : []
      ]
    }
  }
}
