import { Environment } from '@alliance/shared/environment'
import { log } from '@alliance/shared/logger'
import { DetectPlatformService } from '@alliance/shared/utils'
import { Injectable } from '@angular/core'
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http'
import { Observable } from 'rxjs'

@Injectable()
export class WithCredentialsInterceptor implements HttpInterceptor {
  private readonly allowedUrls = [this.env.admin8Api, this.env.mainSiteUrl, this.env.accountHost, this.env.jobseekerApi, this.env.jobseekerApiUk, this.env.authApi, this.env.feedbackApi].map(url => {
    try {
      return url ? new URL(url).origin : url
    } catch (e) {
      log.warn({ where: 'auth-api: WithCredentialsInterceptor', category: 'try_catch', message: 'allowedUrls failed', error: e })
      return ''
    }
  })

  public constructor(private env: Environment, private platform: DetectPlatformService) {}

  public intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    if (this.platform.isServer) {
      return next.handle(request)
    }

    let requestUrlOrigin = ''

    try {
      requestUrlOrigin = new URL(request.url).origin
    } catch (e) {
      log.warn({ where: 'auth-api: WithCredentialsInterceptor', category: 'try_catch', message: 'intercept: getting requestUrlOrigin failed', error: e })
    }

    const allowedUrl = this.allowedUrls.includes(requestUrlOrigin)

    return allowedUrl
      ? next.handle(
          request.clone({
            withCredentials: true
          })
        )
      : next.handle(request)
  }
}
