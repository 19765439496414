import { UrlMatchResult, UrlSegment } from '@angular/router'

const getCompanyId = (segments: UrlSegment[]): UrlSegment => {
  try {
    const companyId = segments[0].toString().split('company')[1]
    return new UrlSegment(companyId, {})
  } catch (e) {
    return new UrlSegment('', {})
  }
}

export const CompanyPageRouteMatcher = (segments: UrlSegment[]): UrlMatchResult | null => {
  const length = segments.length
  const companyRegEx = /company[0-9]+$/
  // company825

  if (length === 1 && segments[0].path.match(companyRegEx)) {
    return {
      consumed: segments,
      posParams: {
        companySegmentsId: getCompanyId(segments)
      }
    }
  }
  // /company/view/1934104
  if (segments[0].path === 'company' && segments[1].path === 'view' && segments[2]?.path?.length) {
    return {
      consumed: segments,
      posParams: {
        companySegmentsId: new UrlSegment(segments[2]?.path || '', {})
      }
    }
  }

  return null
}
