import { ModuleWithProviders, NgModule } from '@angular/core'
import { CookieModule } from 'ngx-cookie'
import { getStorageProviders } from './utils/providers'

@NgModule({
  imports: [CookieModule.withOptions()]
})
export class SharedBrowserStorageModule {
  public static forRoot(): ModuleWithProviders<SharedBrowserStorageModule> {
    return {
      ngModule: SharedBrowserStorageModule,
      providers: getStorageProviders()
    }
  }
}
