/**
 * Converts given hex color to rgb format
 * Original code from https://stackoverflow.com/a/5624139
 * @param {string} hex - The hex color code (e.g., "#0033FF").
 * @returns {string|null} The RGB representation of the color (e.g., "0, 51, 255") or null if the input is invalid.
 */
const hexToRgb = hex => {
  // Expand shorthand form (e.g. "03F") to full form (e.g. "0033FF")
  const shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i
  hex = hex.replace(shorthandRegex, (_m, r, g, b) => r + r + g + g + b + b)

  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex)
  return result ? `${parseInt(result[1], 16)}, ${parseInt(result[2], 16)}, ${parseInt(result[3], 16)}` : null
}

const COLORS = {
  transparent: 'transparent',
  white: '#fff',
  facebook: '#4267B2',
  google: '#4285f4',
  twitter: '#1DA1F2',
  youtube: '#FF0000',
  linkedin: '#0077B5',
  telegram: '#039BE5',
  viber: '#8E24AA',
  red: {
    100: '#FFF4F4',
    200: '#FFE9E9',
    300: '#FFD8D8',
    400: '#FF9D9D',
    500: '#FF5252',
    550: '#EC4142',
    600: '#D93A3B',
    700: '#BC0002',
    800: '#870000'
  },
  black: {
    100: '#F2F5F8',
    200: '#E0E7EB',
    300: '#C8D1D6',
    400: '#A3B2BA',
    500: '#687E88',
    600: '#303A3E',
    700: '#212527',
    800: '#050506'
  },
  green: {
    100: '#ECFFF4',
    200: '#D5FFE6',
    300: '#B3F6CE',
    400: '#88E5AE',
    500: '#3BC372',
    600: '#00963D',
    700: '#00702D',
    800: '#00491E'
  },
  yellow: {
    100: '#FFFDE7',
    200: '#FFF59D',
    300: '#FFE886',
    400: '#FDD835',
    500: '#F9A825',
    600: '#F57F17',
    700: '#DD3500',
    800: '#AA3300'
  },
  blue: {
    100: '#E9F1FF',
    200: '#DCE8FF',
    300: '#B7CFFF',
    400: '#7AA2F2',
    500: '#3366CC',
    600: '#154CBC',
    700: '#073797',
    800: '#001E5A'
  },
  violet: {
    100: '#F8EFFF',
    200: '#F2E3FF',
    300: '#E3C4FF',
    400: '#CCA4EF',
    500: '#9B6EC3',
    600: '#63338D',
    700: '#4A1678',
    800: '#330061'
  },
  pink: {
    500: '#E040FB'
  }
}

const COLORS_RGB = {
  black: {
    200: hexToRgb(COLORS.black[200]),
    300: hexToRgb(COLORS.black[300]),
    400: hexToRgb(COLORS.black[400]),
    500: hexToRgb(COLORS.black[500]),
    600: hexToRgb(COLORS.black[600]),
    700: hexToRgb(COLORS.black[700]),
    800: hexToRgb(COLORS.black[800])
  },
  blue: {
    700: hexToRgb(COLORS.blue[700])
  },
  green: {
    700: hexToRgb(COLORS.green[700])
  },
  red: {
    500: hexToRgb(COLORS.red[500]),
    700: hexToRgb(COLORS.red[700])
  },
  white: hexToRgb(COLORS.white),
  yellow: {
    300: hexToRgb(COLORS.yellow[300])
  }
}

module.exports = { COLORS, COLORS_RGB }
