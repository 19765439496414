import { RxStateService } from '@alliance/shared/models'
import { retryWhenStrategy } from '@alliance/shared/utils'
import { Injectable } from '@angular/core'
import { Observable, of } from 'rxjs'
import { catchError, filter, map, retryWhen, shareReplay, take } from 'rxjs/operators'
import { BranchDictionaryItemFragment, GetBranchByIdGQL, GetBranchesDictionaryGQL } from './branches-dictionary.generated'

@Injectable({
  providedIn: 'root'
})
export class BranchesDictionaryService extends RxStateService<{
  branchesDictionary: BranchDictionaryItemFragment[]
}> {
  public constructor(private branchesDictionaryGQL: GetBranchesDictionaryGQL, private getBranchByIdGQL: GetBranchByIdGQL) {
    super()

    this.initState({
      branchesDictionary: this.getBranches$().pipe(
        filter(branches => !!branches?.length),
        shareReplay(1)
      )
    })
  }

  public getBranchById$(branchId: string): Observable<BranchDictionaryItemFragment | null> {
    return this.select('branchesDictionary').pipe(
      take(1),
      map(branches => branches.find(city => city.id === branchId) || null)
    )
  }

  public fetchBranchById$(branchId: string): Observable<BranchDictionaryItemFragment | null> {
    return this.getBranchByIdGQL.fetch({ id: branchId }, { fetchPolicy: 'cache-first' }).pipe(
      map(({ data: { branch } }) => branch || null),
      retryWhen(retryWhenStrategy()),
      catchError(() => of(null))
    )
  }

  private getBranches$(): Observable<BranchDictionaryItemFragment[]> {
    return this.branchesDictionaryGQL.fetch({}, { fetchPolicy: 'cache-first' }).pipe(
      map(({ data: { branches } }) => (branches || []).reduce<BranchDictionaryItemFragment[]>((acc, branch) => (branch ? [...acc, branch] : acc), [])),
      retryWhen(retryWhenStrategy()),
      catchError(() => of([]))
    )
  }
}
