import { map, Observable } from 'rxjs'
import { distinctUntilChanged, filter } from 'rxjs/operators'
import { Store } from './store'

export abstract class Query<Model> {
  public store$: Observable<Model>
  private _store: Store<Model>

  public constructor(protected store: Store<Model>) {
    this._store = store
    this.store$ = this._store.store$
  }

  public select$<Key extends keyof Model>(stateKey: Key): Observable<Model[Key]> {
    return this.store$.pipe(
      map(data => data[stateKey]),
      distinctUntilChanged(),
      filter(data => typeof data !== 'undefined')
    )
  }
}
