/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core'
import { HttpClient, HttpResponse, HttpContext } from '@angular/common/http'
import { BaseService } from '../base-service'
import { ApplyApiDataAccessConfiguration } from '../apply-api-data-access-configuration'
import { StrictHttpResponse } from '../strict-http-response'
import { RequestBuilder } from '../request-builder'
import { Observable } from 'rxjs'
import { map, filter } from 'rxjs/operators'

import { ApplySavingResult } from '../models/apply-saving-result'

@Injectable({
  providedIn: 'root'
})
export class ApplyAttachApplicationService extends BaseService {
  public constructor(config: ApplyApiDataAccessConfiguration, http: HttpClient) {
    super(config, http)
  }

  /**
   * Path part for operation attachApplicationPost
   */
  public static readonly AttachApplicationPostPath = '/attach-application'

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `attachApplicationPost$Plain()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  attachApplicationPost$Plain$Response(params?: {
    context?: HttpContext
    body?: {
      file: Blob
      firstName?: string
      lastName?: string
      email?: string
      vacancyId: number
      addAlert?: boolean
      letter?: string
    }
  }): Observable<StrictHttpResponse<ApplySavingResult>> {
    const rb = new RequestBuilder(this.rootUrl, ApplyAttachApplicationService.AttachApplicationPostPath, 'post')
    if (params) {
      rb.body(params.body, 'multipart/form-data')
    }

    return this.http
      .request(
        rb.build({
          responseType: 'text',
          accept: 'text/plain',
          context: params?.context
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<ApplySavingResult>
        })
      )
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `attachApplicationPost$Plain$Response()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  attachApplicationPost$Plain(params?: {
    context?: HttpContext
    body?: {
      file: Blob
      firstName?: string
      lastName?: string
      email?: string
      vacancyId: number
      addAlert?: boolean
      letter?: string
    }
  }): Observable<ApplySavingResult> {
    return this.attachApplicationPost$Plain$Response(params).pipe(map((r: StrictHttpResponse<ApplySavingResult>) => r.body as ApplySavingResult))
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `attachApplicationPost$Json()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  attachApplicationPost$Json$Response(params?: {
    context?: HttpContext
    body?: {
      file: Blob
      firstName?: string
      lastName?: string
      email?: string
      vacancyId: number
      addAlert?: boolean
      letter?: string
    }
  }): Observable<StrictHttpResponse<ApplySavingResult>> {
    const rb = new RequestBuilder(this.rootUrl, ApplyAttachApplicationService.AttachApplicationPostPath, 'post')
    if (params) {
      rb.body(params.body, 'multipart/form-data')
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'text/json',
          context: params?.context
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<ApplySavingResult>
        })
      )
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `attachApplicationPost$Json$Response()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  attachApplicationPost$Json(params?: {
    context?: HttpContext
    body?: {
      file: Blob
      firstName?: string
      lastName?: string
      email?: string
      vacancyId: number
      addAlert?: boolean
      letter?: string
    }
  }): Observable<ApplySavingResult> {
    return this.attachApplicationPost$Json$Response(params).pipe(map((r: StrictHttpResponse<ApplySavingResult>) => r.body as ApplySavingResult))
  }
}
