import { CityDataLocalized, DataLocalized, LanguageDataLocalized, PopularTagInfo, ResourceLocalized, RubricLocalized, KeywordInfo, CityRegionDataLocalized } from '@alliance/jobseeker/data-access'

export class DictionaryModel {
  public rubricList: RubricLocalized[] = []
  public subRubricList: RubricLocalized[] = []
  public cityList: CityDataLocalized[] = []
  public cityWithCountry: CityDataLocalized[] = []
  public languageList: LanguageDataLocalized[] = []
  public languageSkillList: DataLocalized[] = []
  public scheduleList: DataLocalized[] = []
  public branchList: DataLocalized[] = []
  public experienceList: DataLocalized[] = []
  public cvdbExperienceList: DataLocalized[] = []
  public educationList: DataLocalized[] = []
  public sphereList: DataLocalized[] = []
  public additionalList: ResourceLocalized[] = []
  public districtsList: DistrictsLocalizedMap = {}
  public keywordsList: string[] = []
  public popularKeywordsList: PopularTagInfo[] = []
  public professionsList: DictionaryProfessionItem[] = []
  public fillingTypesList: DataLocalized[] = []
  public statusApplicationExperience: DataLocalized[] = []
  public statusApplicationSalary: DataLocalized[] = []
  public keywordsWithTransliterationList: KeywordInfo[] = []
  public transliterationKeywordToCyrillic: DictionaryTransliterationKeywordToCyrillic | null = null
  public citiesAndRegions: CityRegionDataLocalized[] = []
}

export interface DictionaryMap {
  [id: number]: string
}

export interface DistrictsLocalizedMap {
  [id: number]: DataLocalized[]
}

export interface DictionaryCustomMap<T> {
  [id: number]: T
}

export interface DictionaryItem {
  id: number
  value: string
  ru: string
  ua: string
  en?: string
  checked?: boolean
}

export interface DictionaryCityAndRegionsItem extends DictionaryItem {
  isRegion: boolean
  centerId: number
}

export interface DictionaryRubricItem {
  id: number
  value: string
  experienceId: number
  parentId: number
  vacCount: number
  checked?: boolean
  ru: string
  ua: string
}

export interface DictionaryCityItemWithRegion {
  id: number
  value: string
  ru: string
  ua: string
  inside: boolean
  centerId: number
  vacCount: number
  centerUa?: string
  centerRu?: string
  locativeRu?: string
  locativeUa?: string
}

export interface DictionaryPopularTag {
  id: number
  count: number
  value: string
}

export interface DictionaryProfessionItem {
  id: number
  count: number
  name: string
}

export interface DictionaryCityListWithCountry {
  id: number
  ru: string
  ua: string
  countryId: number
}

export interface DictionaryTransliterationKeywordToCyrillic {
  keyword: string
  list: KeywordInfo[]
}

export type DictionaryList = DictionaryItem[]
export type DictionaryRubricList = DictionaryRubricItem[]
export type DictionaryCityListWithRegions = DictionaryCityItemWithRegion[]
export type DictionaryPopularTagsList = DictionaryPopularTag[]
export type DictionaryProfessionList = DictionaryProfessionItem[]

export type LangCode = 'ru' | 'ua' | 'en'
