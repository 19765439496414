import { InjectionToken } from '@angular/core'
import { Observable } from 'rxjs'
import { LanguageCodesEnum } from '../enums/language-codes.enum'

export abstract class LanguageService {
  public get activeLanguage(): LanguageCodesEnum {
    return this._activeLanguage
  }

  public get activeLanguage$(): Observable<LanguageCodesEnum> {
    return this._activeLanguage$
  }

  protected abstract get _activeLanguage(): LanguageCodesEnum

  protected abstract get _activeLanguage$(): Observable<LanguageCodesEnum>
}

export const LANGUAGE = new InjectionToken<LanguageService>('LANGUAGE')
