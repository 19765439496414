import { GtmEvent } from '../../models/gtm-event'

export type NotificationCenterEventType =
  | 'AllApplies'
  | 'CompanyStateChanged'
  | 'LimitsChangeRequested'
  | 'NewApply'
  | 'OrderActivatedByTurbota'
  | 'PublicationsEnded'
  | 'UnpaidOrder'
  | 'VacancyAwaitingApproval'
  | 'VacancyBannedByModerator'
  | 'VacancyEndingCount'
  | 'VacancyEndingLeftDays'
  | 'VacancyPublicationApproved'
  | 'VacancyPublicationRejected'
  | 'VacancyOwnerChanged'
  | 'Custom'

export type NotificationCenterEventAction = 'Open' | 'Close' | 'Click' | 'ShowNotification'

export type NotificationCenterEventSource = 'Toast' | 'List'

export interface NotificationCenterEventInput {
  action: NotificationCenterEventAction
  label?: string | number
  type?: string
  source?: NotificationCenterEventSource
}

export const getNotificationCenterEvent = (input: NotificationCenterEventInput): GtmEvent => {
  const { action, label, source, type } = input

  const eventAction = [action, type, source].filter(Boolean).join('_')
  const eventLabel = label || null

  return {
    type: {
      event: 'EMP_Events',
      eventCategory: 'EMP_Events',
      eventContent: null,
      eventAction,
      eventLabel
    }
  }
}
