import { Pipe, PipeTransform } from '@angular/core'
import { getDateFromString } from './date-from-string'

@Pipe({
  name: 'dateFromString'
})
export class DateFromStringPipe implements PipeTransform {
  public transform(value: string): Date | null {
    return getDateFromString(value)
  }
}
