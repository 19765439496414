import { ServiceType, VacancyPublicationType } from '@alliance/shared/domain-gql'

export const PublicationToServiceTypeMap: Record<VacancyPublicationType, ServiceType> = {
  [VacancyPublicationType.Test]: ServiceType.Test,
  [VacancyPublicationType.Business]: ServiceType.Business,
  [VacancyPublicationType.Optimum]: ServiceType.Optimum,
  [VacancyPublicationType.Professional]: ServiceType.Professional,
  [VacancyPublicationType.Anonymous]: ServiceType.Anonymous,
  [VacancyPublicationType.Base]: ServiceType.Base
}
