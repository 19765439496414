import { Injectable } from '@angular/core'
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http'
import { BaseService as __BaseService } from '../base-service'
import { JobseekerDataAccessConfiguration as __Configuration } from '../jobseeker-data-access-configuration'
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response'
import { Observable as __Observable } from 'rxjs'
import { map as __map, filter as __filter } from 'rxjs/operators'

import { RequestText } from '../models/request-text'
import { UserModel } from '../models/user-model'
import { LoginUserModel } from '../models/login-user-model'
import { NewEmailInfo } from '../models/new-email-info'
import { UserInfo } from '../models/user-info'
import { PasswordUserModel } from '../models/password-user-model'
import { PasswordChangeViaEmail } from '../models/password-change-via-email'
import { PasswordChangeViaPhone } from '../models/password-change-via-phone'
@Injectable({
  providedIn: 'root'
})
class AccountService extends __BaseService {
  public static readonly accountJobsearchRegisterPhotoPath = '/account/photo'
  public static readonly feedbackGetPath = '/account/feedback'
  public static readonly feedbackPostPath = '/account/feedback'
  public static readonly feedbackRatePath = '/account/feedback/rate'
  public static readonly accountGetPath = '/account/login'
  public static readonly accountPostPath = '/account/login'
  public static readonly accountAutoLoginPath = '/account/autologin'
  public static readonly accountSendEmailWithAutoLoginLinkPath = '/account/autologin-email'
  public static readonly accountLoginByPhonePath = '/account/login-by-phone'
  public static readonly accountLogoutPath = '/account/logout'
  public static readonly accountSaveEmailPath = '/account/email'
  public static readonly accountChangeUserInfoPath = '/account/userinfo'
  public static readonly accountChangePasswordPath = '/account/password/reason/changed'
  public static readonly accountSendPasswordRecoveryKeyToEmailPath = '/account/password/recovery-email/{email}'
  public static readonly accountGetEmailToRecoverByKeyPath = '/account/password/recovery-email/{cachedKey}'
  public static readonly accountChangePasswordByEmailPath = '/account/password/reason/forgotten-email'
  public static readonly accountSendPasswordRecoveryCodeToPhonePath = '/account/password/recovery-phone'
  public static readonly accountValidatePhoneCodePath = '/account/password/recovery-phone/code-validation'
  public static readonly accountChangePasswordByPhonePath = '/account/password/reason/forgotten-phone'
  public static readonly accountGoToFullSitePath = '/account/fullsite'

  public constructor(config: __Configuration, http: HttpClient) {
    super(config, http)
  }

  /**
   * @return OK
   */
  accountJobsearchRegisterPhotoResponse(): __Observable<__StrictHttpResponse<string>> {
    let __params = this.newParams()
    let __headers = new HttpHeaders()
    let __body: any = null
    let req = new HttpRequest<any>('GET', this.rootUrl + `/account/photo`, __body, {
      headers: __headers,
      params: __params,
      responseType: 'json'
    })

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map(_r => {
        return _r as __StrictHttpResponse<string>
      })
    )
  }

  /**
   * @return OK
   */
  accountJobsearchRegisterPhoto(): __Observable<string> {
    return this.accountJobsearchRegisterPhotoResponse().pipe(__map(_r => _r.body as string))
  }

  /**
   * @param replyId undefined
   * @return OK
   */
  feedbackGetResponse(replyId: string): __Observable<__StrictHttpResponse<Array<RequestText>>> {
    let __params = this.newParams()
    let __headers = new HttpHeaders()
    let __body: any = null
    if (replyId != null) __params = __params.set('replyId', replyId.toString())
    let req = new HttpRequest<any>('GET', this.rootUrl + `/account/feedback`, __body, {
      headers: __headers,
      params: __params,
      responseType: 'json'
    })

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map(_r => {
        return _r as __StrictHttpResponse<Array<RequestText>>
      })
    )
  }

  /**
   * @param replyId undefined
   * @return OK
   */
  feedbackGet(replyId: string): __Observable<Array<RequestText>> {
    return this.feedbackGetResponse(replyId).pipe(__map(_r => _r.body as Array<RequestText>))
  }

  /**
   * @param params The `AccountService.FeedbackPostParams` containing the following parameters:
   *
   * - `url`:
   *
   * - `message`:
   *
   * - `replyId`:
   *
   * - `phone`:
   *
   * - `name`:
   *
   * - `email`:
   *
   * @return OK
   */
  feedbackPostResponse(params: AccountService.FeedbackPostParams): __Observable<__StrictHttpResponse<{}>> {
    let __params = this.newParams()
    let __headers = new HttpHeaders()
    let __body: any = null
    if (params.url != null) __params = __params.set('url', params.url.toString())
    if (params.message != null) __params = __params.set('message', params.message.toString())
    if (params.replyId != null) __params = __params.set('replyId', params.replyId.toString())
    if (params.phone != null) __params = __params.set('phone', params.phone.toString())
    if (params.name != null) __params = __params.set('name', params.name.toString())
    if (params.email != null) __params = __params.set('email', params.email.toString())
    let req = new HttpRequest<any>('POST', this.rootUrl + `/account/feedback`, __body, {
      headers: __headers,
      params: __params,
      responseType: 'json'
    })

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map(_r => {
        return _r as __StrictHttpResponse<{}>
      })
    )
  }

  /**
   * @param params The `AccountService.FeedbackPostParams` containing the following parameters:
   *
   * - `url`:
   *
   * - `message`:
   *
   * - `replyId`:
   *
   * - `phone`:
   *
   * - `name`:
   *
   * - `email`:
   *
   * @return OK
   */
  feedbackPost(params: AccountService.FeedbackPostParams): __Observable<{}> {
    return this.feedbackPostResponse(params).pipe(__map(_r => _r.body as {}))
  }

  /**
   * @param params The `AccountService.FeedbackRateParams` containing the following parameters:
   *
   * - `replyId`:
   *
   * - `rate`:
   *
   * @return OK
   */
  feedbackRateResponse(params: AccountService.FeedbackRateParams): __Observable<__StrictHttpResponse<{}>> {
    let __params = this.newParams()
    let __headers = new HttpHeaders()
    let __body: any = null
    if (params.replyId != null) __params = __params.set('replyId', params.replyId.toString())
    if (params.rate != null) __params = __params.set('rate', params.rate.toString())
    let req = new HttpRequest<any>('POST', this.rootUrl + `/account/feedback/rate`, __body, {
      headers: __headers,
      params: __params,
      responseType: 'json'
    })

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map(_r => {
        return _r as __StrictHttpResponse<{}>
      })
    )
  }

  /**
   * @param params The `AccountService.FeedbackRateParams` containing the following parameters:
   *
   * - `replyId`:
   *
   * - `rate`:
   *
   * @return OK
   */
  feedbackRate(params: AccountService.FeedbackRateParams): __Observable<{}> {
    return this.feedbackRateResponse(params).pipe(__map(_r => _r.body as {}))
  }

  /**
   * @return OK
   */
  accountGetResponse(): __Observable<__StrictHttpResponse<UserModel>> {
    let __params = this.newParams()
    let __headers = new HttpHeaders()
    let __body: any = null
    let req = new HttpRequest<any>('GET', this.rootUrl + `/account/login`, __body, {
      headers: __headers,
      params: __params,
      responseType: 'json'
    })

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map(_r => {
        return _r as __StrictHttpResponse<UserModel>
      })
    )
  }

  /**
   * @return OK
   */
  accountGet(): __Observable<UserModel> {
    return this.accountGetResponse().pipe(__map(_r => _r.body as UserModel))
  }

  /**
   * @param model undefined
   * @return OK
   */
  accountPostResponse(model: LoginUserModel): __Observable<__StrictHttpResponse<{}>> {
    let __params = this.newParams()
    let __headers = new HttpHeaders()
    let __body: any = null
    __body = model
    let req = new HttpRequest<any>('POST', this.rootUrl + `/account/login`, __body, {
      headers: __headers,
      params: __params,
      responseType: 'json'
    })

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map(_r => {
        return _r as __StrictHttpResponse<{}>
      })
    )
  }

  /**
   * @param model undefined
   * @return OK
   */
  accountPost(model: LoginUserModel): __Observable<{}> {
    return this.accountPostResponse(model).pipe(__map(_r => _r.body as {}))
  }

  /**
   * @param key undefined
   * @return OK
   */
  accountAutoLoginResponse(key: string): __Observable<__StrictHttpResponse<{}>> {
    let __params = this.newParams()
    let __headers = new HttpHeaders()
    let __body: any = null
    if (key != null) __params = __params.set('key', key.toString())
    let req = new HttpRequest<any>('POST', this.rootUrl + `/account/autologin`, __body, {
      headers: __headers,
      params: __params,
      responseType: 'json'
    })

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map(_r => {
        return _r as __StrictHttpResponse<{}>
      })
    )
  }

  /**
   * @param key undefined
   * @return OK
   */
  accountAutoLogin(key: string): __Observable<{}> {
    return this.accountAutoLoginResponse(key).pipe(__map(_r => _r.body as {}))
  }

  /**
   * @param email undefined
   * @return OK
   */
  accountSendEmailWithAutoLoginLinkResponse(email: string): __Observable<__StrictHttpResponse<{}>> {
    let __params = this.newParams()
    let __headers = new HttpHeaders()
    let __body: any = null
    if (email != null) __params = __params.set('email', email.toString())
    let req = new HttpRequest<any>('GET', this.rootUrl + `/account/autologin-email`, __body, {
      headers: __headers,
      params: __params,
      responseType: 'json'
    })

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map(_r => {
        return _r as __StrictHttpResponse<{}>
      })
    )
  }

  /**
   * @param email undefined
   * @return OK
   */
  accountSendEmailWithAutoLoginLink(email: string): __Observable<{}> {
    return this.accountSendEmailWithAutoLoginLinkResponse(email).pipe(__map(_r => _r.body as {}))
  }

  /**
   * @param params The `AccountService.AccountLoginByPhoneParams` containing the following parameters:
   *
   * - `phone`:
   *
   * - `code`:
   *
   * - `lastName`:
   *
   * - `firstName`:
   *
   * @return OK
   */
  accountLoginByPhoneResponse(params: AccountService.AccountLoginByPhoneParams): __Observable<__StrictHttpResponse<{}>> {
    let __params = this.newParams()
    let __headers = new HttpHeaders()
    let __body: any = null
    if (params.phone != null) __params = __params.set('phone', params.phone.toString())
    if (params.code != null) __params = __params.set('code', params.code.toString())
    if (params.lastName != null) __params = __params.set('lastName', params.lastName.toString())
    if (params.firstName != null) __params = __params.set('firstName', params.firstName.toString())
    let req = new HttpRequest<any>('POST', this.rootUrl + `/account/login-by-phone`, __body, {
      headers: __headers,
      params: __params,
      responseType: 'json'
    })

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map(_r => {
        return _r as __StrictHttpResponse<{}>
      })
    )
  }

  /**
   * @param params The `AccountService.AccountLoginByPhoneParams` containing the following parameters:
   *
   * - `phone`:
   *
   * - `code`:
   *
   * - `lastName`:
   *
   * - `firstName`:
   *
   * @return OK
   */
  accountLoginByPhone(params: AccountService.AccountLoginByPhoneParams): __Observable<{}> {
    return this.accountLoginByPhoneResponse(params).pipe(__map(_r => _r.body as {}))
  }
  accountLogoutResponse(): __Observable<__StrictHttpResponse<any>> {
    let __params = this.newParams()
    let __headers = new HttpHeaders()
    let __body: any = null
    let req = new HttpRequest<any>('POST', this.rootUrl + `/account/logout`, __body, {
      headers: __headers,
      params: __params,
      responseType: 'json'
    })

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map(_r => {
        return _r as __StrictHttpResponse<any>
      })
    )
  }
  accountLogout(): __Observable<any> {
    return this.accountLogoutResponse().pipe(__map(_r => _r.body as any))
  }

  /**
   * @param info undefined
   * @return OK
   */
  accountSaveEmailResponse(info: NewEmailInfo): __Observable<__StrictHttpResponse<{}>> {
    let __params = this.newParams()
    let __headers = new HttpHeaders()
    let __body: any = null
    __body = info
    let req = new HttpRequest<any>('POST', this.rootUrl + `/account/email`, __body, {
      headers: __headers,
      params: __params,
      responseType: 'json'
    })

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map(_r => {
        return _r as __StrictHttpResponse<{}>
      })
    )
  }

  /**
   * @param info undefined
   * @return OK
   */
  accountSaveEmail(info: NewEmailInfo): __Observable<{}> {
    return this.accountSaveEmailResponse(info).pipe(__map(_r => _r.body as {}))
  }

  /**
   * @param userInfo undefined
   * @return OK
   */
  accountChangeUserInfoResponse(userInfo: UserInfo): __Observable<__StrictHttpResponse<{}>> {
    let __params = this.newParams()
    let __headers = new HttpHeaders()
    let __body: any = null
    __body = userInfo
    let req = new HttpRequest<any>('PUT', this.rootUrl + `/account/userinfo`, __body, {
      headers: __headers,
      params: __params,
      responseType: 'json'
    })

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map(_r => {
        return _r as __StrictHttpResponse<{}>
      })
    )
  }

  /**
   * @param userInfo undefined
   * @return OK
   */
  accountChangeUserInfo(userInfo: UserInfo): __Observable<{}> {
    return this.accountChangeUserInfoResponse(userInfo).pipe(__map(_r => _r.body as {}))
  }

  /**
   * @param model undefined
   * @return OK
   */
  accountChangePasswordResponse(model: PasswordUserModel): __Observable<__StrictHttpResponse<{}>> {
    let __params = this.newParams()
    let __headers = new HttpHeaders()
    let __body: any = null
    __body = model
    let req = new HttpRequest<any>('PUT', this.rootUrl + `/account/password/reason/changed`, __body, {
      headers: __headers,
      params: __params,
      responseType: 'json'
    })

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map(_r => {
        return _r as __StrictHttpResponse<{}>
      })
    )
  }

  /**
   * @param model undefined
   * @return OK
   */
  accountChangePassword(model: PasswordUserModel): __Observable<{}> {
    return this.accountChangePasswordResponse(model).pipe(__map(_r => _r.body as {}))
  }

  /**
   * @param email undefined
   * @return OK
   */
  accountSendPasswordRecoveryKeyToEmailResponse(email: string): __Observable<__StrictHttpResponse<{}>> {
    let __params = this.newParams()
    let __headers = new HttpHeaders()
    let __body: any = null

    let req = new HttpRequest<any>('POST', this.rootUrl + `/account/password/recovery-email/${email}`, __body, {
      headers: __headers,
      params: __params,
      responseType: 'json'
    })

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map(_r => {
        return _r as __StrictHttpResponse<{}>
      })
    )
  }

  /**
   * @param email undefined
   * @return OK
   */
  accountSendPasswordRecoveryKeyToEmail(email: string): __Observable<{}> {
    return this.accountSendPasswordRecoveryKeyToEmailResponse(email).pipe(__map(_r => _r.body as {}))
  }

  /**
   * @param cachedKey undefined
   * @return OK
   */
  accountGetEmailToRecoverByKeyResponse(cachedKey: string): __Observable<__StrictHttpResponse<{}>> {
    let __params = this.newParams()
    let __headers = new HttpHeaders()
    let __body: any = null

    let req = new HttpRequest<any>('GET', this.rootUrl + `/account/password/recovery-email/${cachedKey}`, __body, {
      headers: __headers,
      params: __params,
      responseType: 'json'
    })

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map(_r => {
        return _r as __StrictHttpResponse<{}>
      })
    )
  }

  /**
   * @param cachedKey undefined
   * @return OK
   */
  accountGetEmailToRecoverByKey(cachedKey: string): __Observable<{}> {
    return this.accountGetEmailToRecoverByKeyResponse(cachedKey).pipe(__map(_r => _r.body as {}))
  }

  /**
   * @param request undefined
   * @return OK
   */
  accountChangePasswordByEmailResponse(request: PasswordChangeViaEmail): __Observable<__StrictHttpResponse<{}>> {
    let __params = this.newParams()
    let __headers = new HttpHeaders()
    let __body: any = null
    __body = request
    let req = new HttpRequest<any>('PUT', this.rootUrl + `/account/password/reason/forgotten-email`, __body, {
      headers: __headers,
      params: __params,
      responseType: 'json'
    })

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map(_r => {
        return _r as __StrictHttpResponse<{}>
      })
    )
  }

  /**
   * @param request undefined
   * @return OK
   */
  accountChangePasswordByEmail(request: PasswordChangeViaEmail): __Observable<{}> {
    return this.accountChangePasswordByEmailResponse(request).pipe(__map(_r => _r.body as {}))
  }

  /**
   * @param phone undefined
   * @return OK
   */
  accountSendPasswordRecoveryCodeToPhoneResponse(phone: string): __Observable<__StrictHttpResponse<{}>> {
    let __params = this.newParams()
    let __headers = new HttpHeaders()
    let __body: any = null
    if (phone != null) __params = __params.set('phone', phone.toString())
    let req = new HttpRequest<any>('POST', this.rootUrl + `/account/password/recovery-phone`, __body, {
      headers: __headers,
      params: __params,
      responseType: 'json'
    })

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map(_r => {
        return _r as __StrictHttpResponse<{}>
      })
    )
  }

  /**
   * @param phone undefined
   * @return OK
   */
  accountSendPasswordRecoveryCodeToPhone(phone: string): __Observable<{}> {
    return this.accountSendPasswordRecoveryCodeToPhoneResponse(phone).pipe(__map(_r => _r.body as {}))
  }

  /**
   * @param params The `AccountService.AccountValidatePhoneCodeParams` containing the following parameters:
   *
   * - `phone`:
   *
   * - `code`:
   *
   * @return OK
   */
  accountValidatePhoneCodeResponse(params: AccountService.AccountValidatePhoneCodeParams): __Observable<__StrictHttpResponse<{}>> {
    let __params = this.newParams()
    let __headers = new HttpHeaders()
    let __body: any = null
    if (params.phone != null) __params = __params.set('phone', params.phone.toString())
    if (params.code != null) __params = __params.set('code', params.code.toString())
    let req = new HttpRequest<any>('GET', this.rootUrl + `/account/password/recovery-phone/code-validation`, __body, {
      headers: __headers,
      params: __params,
      responseType: 'json'
    })

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map(_r => {
        return _r as __StrictHttpResponse<{}>
      })
    )
  }

  /**
   * @param params The `AccountService.AccountValidatePhoneCodeParams` containing the following parameters:
   *
   * - `phone`:
   *
   * - `code`:
   *
   * @return OK
   */
  accountValidatePhoneCode(params: AccountService.AccountValidatePhoneCodeParams): __Observable<{}> {
    return this.accountValidatePhoneCodeResponse(params).pipe(__map(_r => _r.body as {}))
  }

  /**
   * @param request undefined
   * @return OK
   */
  accountChangePasswordByPhoneResponse(request: PasswordChangeViaPhone): __Observable<__StrictHttpResponse<{}>> {
    let __params = this.newParams()
    let __headers = new HttpHeaders()
    let __body: any = null
    __body = request
    let req = new HttpRequest<any>('PUT', this.rootUrl + `/account/password/reason/forgotten-phone`, __body, {
      headers: __headers,
      params: __params,
      responseType: 'json'
    })

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map(_r => {
        return _r as __StrictHttpResponse<{}>
      })
    )
  }

  /**
   * @param request undefined
   * @return OK
   */
  accountChangePasswordByPhone(request: PasswordChangeViaPhone): __Observable<{}> {
    return this.accountChangePasswordByPhoneResponse(request).pipe(__map(_r => _r.body as {}))
  }

  /**
   * @return OK
   */
  accountGoToFullSiteResponse(): __Observable<__StrictHttpResponse<{}>> {
    let __params = this.newParams()
    let __headers = new HttpHeaders()
    let __body: any = null
    let req = new HttpRequest<any>('GET', this.rootUrl + `/account/fullsite`, __body, {
      headers: __headers,
      params: __params,
      responseType: 'json'
    })

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map(_r => {
        return _r as __StrictHttpResponse<{}>
      })
    )
  }

  /**
   * @return OK
   */
  accountGoToFullSite(): __Observable<{}> {
    return this.accountGoToFullSiteResponse().pipe(__map(_r => _r.body as {}))
  }
}

module AccountService {
  /**
   * Parameters for FeedbackPost
   */
  export interface FeedbackPostParams {
    url: string
    message: string
    replyId?: string
    phone?: string
    name?: string
    email?: string
  }

  /**
   * Parameters for FeedbackRate
   */
  export interface FeedbackRateParams {
    replyId: string
    rate: number
  }

  /**
   * Parameters for AccountLoginByPhone
   */
  export interface AccountLoginByPhoneParams {
    phone: string
    code: string
    lastName?: string
    firstName?: string
  }

  /**
   * Parameters for AccountValidatePhoneCode
   */
  export interface AccountValidatePhoneCodeParams {
    phone: string
    code: string
  }
}

export { AccountService }
