import { ElementRef, inject } from '@angular/core'

import { booleanAttribute } from './boolean-attribute'

export const mutationObserverInitFactory = (): MutationObserverInit => {
  const { nativeElement } = inject<ElementRef<HTMLElement>>(ElementRef)
  const attributeFilter: string | null = nativeElement.getAttribute('attributeFilter')
  return {
    attributeFilter: attributeFilter?.split(',').map(attr => attr.trim()),
    attributeOldValue: booleanAttribute(nativeElement, 'attributeOldValue'),
    attributes: booleanAttribute(nativeElement, 'attributes'),
    characterData: booleanAttribute(nativeElement, 'characterData'),
    characterDataOldValue: booleanAttribute(nativeElement, 'characterDataOldValue'),
    childList: booleanAttribute(nativeElement, 'childList'),
    subtree: booleanAttribute(nativeElement, 'subtree')
  }
}
