import { isDevMode, NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { Apollo } from 'apollo-angular'
import { HttpLink } from 'apollo-angular/http'
import { Environment } from '@alliance/shared/environment'
import { GqlLanguageMiddlewareService } from '@alliance/shared/translation'
import { GetApolloClientMetricsService } from '@alliance/shared/gql/utils'
import { ApolloLink, InMemoryCache } from '@apollo/client/core'
import { APOLLO_CLIENT_NAME } from './constants/constants'
import { HttpHeaders } from '@angular/common/http'

@NgModule({
  imports: [CommonModule],
  providers: [GqlLanguageMiddlewareService]
})
export class SharedDictionaryModule {
  public constructor(
    private apollo: Apollo,
    private httpLink: HttpLink,
    private environment: Environment,
    private langMiddleware: GqlLanguageMiddlewareService,
    private getApolloClientMetricsService: GetApolloClientMetricsService
  ) {
    if (!this.apollo.use(APOLLO_CLIENT_NAME)) {
      this.createClient()
    }
  }

  private createClient(): void {
    const cache = new InMemoryCache()
    const httpLink = this.httpLink.create({
      uri: `${this.environment.graphqlApi}/graphql`,
      headers: new HttpHeaders({
        'apollo-require-preflight': 'true',
        'x-cache-control-max-age': '3600'
      }),
      withCredentials: false,
      method: 'GET'
    })

    this.apollo.createNamed(APOLLO_CLIENT_NAME, {
      link: ApolloLink.from([this.langMiddleware.getMiddleware(), httpLink]),
      cache,
      ...this.getApolloClientMetricsService.apolloClientMetrics,
      connectToDevTools: isDevMode()
    })
  }
}
