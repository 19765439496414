/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http'
import { Observable } from 'rxjs'
import { filter, map } from 'rxjs/operators'
import { StrictHttpResponse } from '../../strict-http-response'
import { RequestBuilder } from '../../request-builder'

import { ConversationsToModify } from '../../models/conversations-to-modify'

export interface ConversationMassActionsGetConversationsToDelete$Plain$Params {
  userId: string
}

export function conversationMassActionsGetConversationsToDelete$Plain(
  http: HttpClient,
  rootUrl: string,
  params: ConversationMassActionsGetConversationsToDelete$Plain$Params,
  context?: HttpContext
): Observable<StrictHttpResponse<ConversationsToModify>> {
  const rb = new RequestBuilder(rootUrl, conversationMassActionsGetConversationsToDelete$Plain.PATH, 'get')
  if (params) {
    rb.query('userId', params.userId, {})
  }

  return http.request(rb.build({ responseType: 'text', accept: 'text/plain', context })).pipe(
    filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
    map((r: HttpResponse<any>) => {
      return r as StrictHttpResponse<ConversationsToModify>
    })
  )
}

conversationMassActionsGetConversationsToDelete$Plain.PATH = '/v1/conversation-mass-actions/get-to-delete'
