import { Injectable } from '@angular/core'
import { Environment } from '@alliance/shared/environment'

@Injectable({
  providedIn: 'root'
})
export class CompanyEmployeesApiDataAccessConfiguration {
  public readonly rootUrl: string = this.env.companyEmployeesServiceApi ?? 'https://company-employees-api.robota.ua'

  public constructor(private readonly env: Environment) {}
}

export interface CompanyEmployeesApiDataAccessConfigurationParams {
  rootUrl?: string
}
