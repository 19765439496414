export interface EducationInfoDTO {
  id?: number
  typeId?: EducationInfoDTO.TypeIdEnum
  schoolName?: string
  location?: string
  speciality?: string
  year?: number
  diploma?: string
  resumeId?: number
}

export namespace EducationInfoDTO {
  export type TypeIdEnum = 'Higher' | 'MBA' | 'Secondary' | 'SecondarySpecial' | 'UnfinishedHigher'
  export const TypeIdEnum = {
    Higher: 'Higher' as TypeIdEnum,
    MBA: 'MBA' as TypeIdEnum,
    Secondary: 'Secondary' as TypeIdEnum,
    SecondarySpecial: 'SecondarySpecial' as TypeIdEnum,
    UnfinishedHigher: 'UnfinishedHigher' as TypeIdEnum
  }
}
