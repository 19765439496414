// редиректы ниже попросили добавить сеошники, такие урлы должны всегда ввести на главную (1 пункт) https://rabota.atlassian.net/browse/JS-4174
export const SsrRedirectUrlsToHome = [
  {
    oldUrl: `/index.php`,
    newUrl: '/'
  },
  {
    oldUrl: `/home.php`,
    newUrl: '/'
  },
  {
    oldUrl: `/home.html`,
    newUrl: '/'
  },
  {
    oldUrl: `/index.htm`,
    newUrl: '/'
  },
  {
    oldUrl: `/home.htm`,
    newUrl: '/'
  },
  {
    oldUrl: `/home`,
    newUrl: '/'
  }
]
// список старых страниц которые были на rAbota.ua
export const SeoPagesRedirectList = [
  {
    oldUrl: `/${encodeURIComponent('вакансии')}`,
    newUrl: '/jobsearch/rubrics'
  },
  {
    oldUrl: `/${encodeURIComponent('украина')}`,
    newUrl: '/jobsearch/cities'
  },
  {
    oldUrl: `/${encodeURIComponent('вакансии-областей-украины')}`,
    newUrl: '/jobsearch/regions'
  },
  {
    oldUrl: `/zapros`,
    newUrl: '/jobsearch/professions-in-cities'
  },
  {
    oldUrl: `/search-professions-in-cities`,
    newUrl: '/jobsearch/professions-in-cities'
  },
  {
    oldUrl: `/jobsearch/vip`,
    newUrl: '/jobsearch/industry'
  },
  {
    oldUrl: `/jobsearch/vip/industry`,
    newUrl: '/jobsearch/company'
  },
  {
    oldUrl: '/zapros/%D0%B3.%D0%BF%D0%BE%D0%B7%D0%BD%D0%B0%D0%BD%D1%8C/%D1%85%D0%B0%D1%80%D1%8C%D0%BA%D0%BE%D0%B2',
    newUrl: '/zapros/poznan'
  },
  {
    oldUrl: '/zapros/%D1%89%D0%B5%D1%86%D0%B8%D0%BD/%D0%B4%D1%80%D1%83%D0%B3%D0%B8%D0%B5_%D1%81%D1%82%D1%80%D0%B0%D0%BD%D1%8B',
    newUrl: '/zapros/szczecin'
  },
  {
    oldUrl: '/zapros/%D0%B2%D0%B0%D1%80%D1%88%D0%B0%D0%B2%D0%B0/%D0%B4%D1%80%D1%83%D0%B3%D0%B8%D0%B5_%D1%81%D1%82%D1%80%D0%B0%D0%BD%D1%8B',
    newUrl: '/zapros/warsaw'
  },
  {
    oldUrl: '/zapros/%D0%BA%D1%80%D0%B0%D0%BA%D1%96%D0%B2/%D0%B4%D1%80%D1%83%D0%B3%D0%B8%D0%B5_%D1%81%D1%82%D1%80%D0%B0%D0%BD%D1%8B',
    newUrl: '/zapros/krakow'
  },
  ...SsrRedirectUrlsToHome
]
