import { AuthService } from '@alliance/shared/auth/api'
import { Environment } from '@alliance/shared/environment'
import { RxStateService } from '@alliance/shared/models'
import { SessionStorage } from '@alliance/shared/storage'
import { safeJsonParse } from '@alliance/shared/utils'
import { HttpClient, HttpParams } from '@angular/common/http'
import { Inject, Injectable } from '@angular/core'
import { LOCATION } from '@ng-web-apis/common'
import { Observable, of } from 'rxjs'
import { catchError, filter, switchMap, take } from 'rxjs/operators'
import { EmployerMarketingCpaRegisterParams } from './models/employer-marketing-cpa-register-params.interface'

const SESSION_STORAGE_KEY = 'employerMarketingCpaParams'

@Injectable({ providedIn: 'root' })
export class EmployerMarketingCpaService extends RxStateService<object> {
  public constructor(
    @Inject(LOCATION) private readonly location: Location,
    private readonly http: HttpClient,
    private readonly authService: AuthService,
    private readonly environment: Environment,
    private readonly sessionStorage: SessionStorage
  ) {
    super()
  }

  public subscribeToCpaRegister(): void {
    const params = this.getParams()

    if (!params) {
      return
    }

    this.hold(
      this.authService.token$.pipe(
        filter(token => !!token && this.authService.isEmployer),
        take(1),
        switchMap(() => this.register$(params))
      )
    )
  }

  private getParams(): EmployerMarketingCpaRegisterParams | null {
    const searchParams = new URLSearchParams(this.location.search)

    const utmSource = searchParams.get('utm_source')
    const saUid = searchParams.get('SAuid')

    if (utmSource && saUid) {
      this.setParamsToStorage({ utmSource, saUid })
    }

    return this.getParamsFromStorage()
  }

  private setParamsToStorage(params: EmployerMarketingCpaRegisterParams): void {
    this.sessionStorage.setItem(SESSION_STORAGE_KEY, JSON.stringify(params))
  }

  private getParamsFromStorage(): EmployerMarketingCpaRegisterParams | null {
    try {
      return safeJsonParse<EmployerMarketingCpaRegisterParams | null>(this.sessionStorage.getItem(SESSION_STORAGE_KEY), null)
    } catch (e) {
      return null
    }
  }

  private register$(inputParams: EmployerMarketingCpaRegisterParams): Observable<unknown> {
    let params = new HttpParams()
    Object.entries(inputParams).forEach(([key, value]) => (params = params.set(key, `${value}`)))

    return this.http.post(`${this.environment.marketingApi || ''}/cpa/register`, null, { params }).pipe(catchError(() => of(null)))
  }
}
