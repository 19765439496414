import { logger } from '../logger/logger'

export type SSRLoggerHttpRequestParams = {
  request_method: string
  request_origin: string
  request_url: string
  request_url_with_params: string
  request_url_unique: string
}

type SSRLoggerErrorParams = {
  error_message: string
  error_name: string
  error_stack: string
}

type SSRLoggerPageParams = {
  page_url: string
  page_path: string
}

type SSRLoggerUnhandledError = SSRLoggerErrorParams &
  SSRLoggerPageParams & {
    [key: string]: unknown
    message: string
  }

type TimeoutErrorLoggerType = SSRLoggerErrorParams & SSRLoggerHttpRequestParams

export const ssrErrorLogger = logger<SSRLoggerUnhandledError, SSRLoggerUnhandledError>({
  source: 'SSR_LOGGER',
  board: 'UNHANDLED_ERRORS_ON_SSR'
})

export const ssrTimeoutLogger = logger<TimeoutErrorLoggerType, TimeoutErrorLoggerType>({
  source: 'SSR_LOGGER',
  board: 'REQUEST_TIMEOUT_ON_SSR',
  message: 'resource_timeout'
})

export const ssrHttpRequestLogger = logger<SSRLoggerPageParams & SSRLoggerHttpRequestParams & { duration: number }>({
  source: 'SSR_LOGGER',
  board: 'HTTP_REQUEST_ON_SSR',
  message: 'request_called'
})

export const ssrPageRenderLogger = logger<SSRLoggerPageParams & { user_agent: string; duration: number }>({
  source: 'SSR_LOGGER',
  board: 'PAGE_RENDER_ON_SSR',
  message: 'page_rendered'
})
