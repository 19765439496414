import { TranslationService } from '@alliance/shared/translation'
import { Injectable } from '@angular/core'
import { Observable, of } from 'rxjs'
import { HelpersService } from '../helpers.service'
import { Translations } from '../localization/translations'
import { CompanySeoParams, PartialSeoParamsResponse, PlatformHosts } from '../models'

@Injectable({
  providedIn: 'root'
})
export class CompanyDictionaryService {
  public constructor(private translations: Translations, private translationService: TranslationService, private helpersService: HelpersService) {}

  public getParams({ params: { notebookId, vacanciesCount, companyName } }: CompanySeoParams): Observable<PartialSeoParamsResponse> {
    const url = `company${notebookId}`

    return of({
      title: this.translationService.translate(this.translations.company.title, { companyName }),
      description: this.translationService.translate(this.translations.company.description, {
        companyName,
        vacanciesCount
      }),
      canonicalUrl: this.helpersService.createURL(PlatformHosts.desktop, url),
      hrefLang: this.helpersService.createHrefLangURLs(url, url),
      noIndexNoFollow: false
    })
  }
}
