import { CvdbService, TicketService4Detail } from '@alliance/employer/data-access'
import { Injectable } from '@angular/core'
import { combineLatest, Observable } from 'rxjs'
import { distinctUntilChanged, filter, map, pluck } from 'rxjs/operators'
import { Query } from '../base/query'
import { BillingModel, BillingStorePendingModel } from './billing.model'
import { BillingStore } from './billing.store'
import { ResumeContactsScenariosEnum } from './models/open-contacts'

@Injectable({
  providedIn: 'root'
})
export class BillingQuery extends Query<BillingModel> {
  // WARNING: do not use this service for new features - DEPRECATED
  public constructor(store: BillingStore) {
    super(store)
  }

  public selectPending$(name: keyof BillingStorePendingModel): Observable<boolean> {
    return this.select$('pending').pipe(pluck(name), distinctUntilChanged())
  }

  /**
   * @deprecated for new employer features use EmployerVacancyServicesService instead
   */
  public selectVacancyServices$(): Observable<TicketService4Detail[]> {
    return this.select$('vacancyServices')
  }

  public selectContactServicesToActivate$(): Observable<CvdbService[]> {
    return this.select$('contactServicesToActivate')
  }

  public selectSuitableActivatedServices$(): Observable<CvdbService[]> {
    return this.select$('suitableActivatedServices')
  }

  public selectContactScenario$(): Observable<ResumeContactsScenariosEnum> {
    return this.select$('contactScenario').pipe(filter((scenario): scenario is ResumeContactsScenariosEnum => !!scenario))
  }

  public selectActivatedContactsServices$(): Observable<CvdbService[]> {
    return this.select$('contactServices').pipe(map(services => services.filter(service => service.isActivate)))
  }

  public selectResumeServicesOpenContactsCount$(): Observable<number> {
    return combineLatest([this.selectPending$('openContactsCount'), this.select$('resumeServicesOpenContactsCount')]).pipe(
      filter(([pending, _]) => !pending),
      map(([_, count]) => count)
    )
  }

  public selectCurrentContactsService$(): Observable<CvdbService> {
    return combineLatest([this.selectPending$('currentContactsService'), this.select$('currentContactsService').pipe(filter((service): service is CvdbService => !!service))]).pipe(
      filter(([pending, _]) => !pending),
      map(([_, service]) => service)
    )
  }
}
