import { NewAccountAction } from '../models/new-account-action-interface'
import { NewAccountActionsEnum } from '../models/new-account-actions-enum'

export const NewAccountActionsList: NewAccountAction[] = [
  {
    type: NewAccountActionsEnum.addVacancy,
    weight: 1
  },
  {
    type: NewAccountActionsEnum.editVacancy,
    weight: 1
  },
  {
    type: NewAccountActionsEnum.viewVacancy,
    weight: 1
  },
  {
    type: NewAccountActionsEnum.viewApply,
    weight: 1
  },
  {
    type: NewAccountActionsEnum.viewModal,
    weight: 1
  },
  {
    type: NewAccountActionsEnum.viewCv,
    weight: 2
  },
  {
    type: NewAccountActionsEnum.openContact,
    weight: 2
  },
  {
    type: NewAccountActionsEnum.applyFilter,
    weight: 1
  },
  {
    type: NewAccountActionsEnum.downloadCv,
    weight: 1
  },
  {
    type: NewAccountActionsEnum.suggestVacancy,
    weight: 1
  },
  {
    type: NewAccountActionsEnum.initChat,
    weight: 1
  }
]
