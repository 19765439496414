import { ActionStatusMessageMetaType, ActionTypesEnum, channelTypes, NotificationService, NotificationTypeEnum } from '@alliance/notification/api'
import { Environment } from '@alliance/shared/environment'
import { TranslationService } from '@alliance/shared/translation'
import { DetectPlatformService } from '@alliance/shared/utils'
import { Injectable } from '@angular/core'
import { asyncScheduler, timer } from 'rxjs'
import { take } from 'rxjs/operators'
import { Translations } from '../localization/translations'
import { GlobalExceptionResponse } from '../models/global-exception-response'
import { ValidGraphqlErrorExtensions } from '../models/valid-graphql-error-extensions-type'

@Injectable()
export class BaseRestrictedByRightErrorMiddlewareService {
  public readonly GLOBAL_EXCEPTION_NAME = 'HasNoScopesForActionException'

  public constructor(
    protected platform: DetectPlatformService,
    protected environment: Environment,
    private notificationService: NotificationService,
    private translations: Translations,
    private translationService: TranslationService
  ) {}

  public isValidGraphqlErrorExtensions(errorExtensions: unknown): errorExtensions is ValidGraphqlErrorExtensions {
    return typeof errorExtensions === 'object' && !!errorExtensions && 'data' in errorExtensions
  }

  public isValidGlobalExceptionResponse(error: unknown): error is GlobalExceptionResponse {
    return typeof error === 'object' && !!error && 'key' in error
  }

  public notifyRestrictedByRightError(): void {
    timer(0, asyncScheduler)
      .pipe(take(1))
      .subscribe(() =>
        this.notificationService.dispatch<ActionStatusMessageMetaType>({
          type: NotificationTypeEnum.CREATED,
          payload: {
            channel: channelTypes.USER_ACTIONS,
            body: this.translationService.translate(this.translations.notify.restrictedByRightCommon),
            meta: {
              status: ActionTypesEnum.ERROR
            }
          }
        })
      )
  }
}
