import { Directive, HostListener, Input } from '@angular/core'
import { Params } from '@angular/router'
import { AccountLinkMapService } from '../account-link-map.service'
import { AccountLinkModel, TargetTypeEnum } from '../models/AccountLinkModel'

@Directive({
  selector: '[allianceSharedAccountLink]'
})
export class AccountLinkDirective {
  // params are a.k.a. routerLink's directive
  // so to be able easily replace this directive with routerLink once Old account gone
  @Input() public allianceSharedAccountLink: AccountLinkModel | undefined // [action, id]
  @Input() public queryParams: Params = {}
  @Input() public target: TargetTypeEnum = '_self'

  public constructor(private accountLinkMapService: AccountLinkMapService) {}

  @HostListener('click', ['$event'])
  public onClick(event: Event): void {
    event.preventDefault()
    this.proceedAction()
  }

  private proceedAction(): void {
    if (!this.allianceSharedAccountLink) {
      return
    }
    this.accountLinkMapService.proceedAction(this.allianceSharedAccountLink, {
      target: this.target,
      queryParams: this.queryParams
    })
  }
}
