import { ROBOTA_UA } from '@alliance/shared/constants'
import { ImageAttributesNameEnum, ImageAttributesType } from '@alliance/shared/models'

const companyLogoAttributes: ImageAttributesType = {
  [ImageAttributesNameEnum.alt]: '{companyName}{imageOrder, select, 0{} null{} other{: фото {imageOrder}}}',
  [ImageAttributesNameEnum.title]: `{companyName}{imageOrder, select, 0{} null{} other{: фото {imageOrder}}} — ${ROBOTA_UA}`
}

const companyImageAttributes: ImageAttributesType = {
  [ImageAttributesNameEnum.alt]: '{companyName} — фото работодателя{imageOrder, select, 0{} null{} other{ №{imageOrder}}}',
  [ImageAttributesNameEnum.title]: `{companyName} — фото работодателя{imageOrder, select, 0{} null{} other{ №{imageOrder}}}, ${ROBOTA_UA}`
}

const vacancyImageAttributes: ImageAttributesType = {
  [ImageAttributesNameEnum.alt]: '{vacancyName} — вакансия в {companyName}{imageOrder, select, 0{} null{} other{: фото {imageOrder}}}',
  [ImageAttributesNameEnum.title]: `{vacancyName} — вакансия в {companyName}{imageOrder, select, 0{} null{} other{: фото {imageOrder}}}, ${ROBOTA_UA}`
}

export default {
  imageAttributes: {
    companyLogo: {
      ...companyLogoAttributes
    },
    companyImage: {
      ...companyImageAttributes
    },
    vacancyImage: {
      ...vacancyImageAttributes
    }
  }
}
