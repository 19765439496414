import { DataLayerPushEvent } from '@alliance/shared/utils'

export const VACANCY_SOCIAL_TRY_TO_CREATE_EVENT: DataLayerPushEvent = {
  event: 'EMP_Vacancy',
  eventCategory: 'EMP_Vacancy',
  eventAction: 'tryToCreateText'
}

export const VACANCY_SOCIAL_GENERATE_TEXT_EVENT: DataLayerPushEvent = {
  event: 'EMP_Vacancy',
  eventCategory: 'EMP_Vacancy',
  eventAction: 'generateText'
}

export const VACANCY_SOCIAL_REGENERATE_TEXT_EVENT: DataLayerPushEvent = {
  event: 'EMP_Vacancy',
  eventCategory: 'EMP_Vacancy',
  eventAction: 're_generateText'
}

export const VACANCY_SOCIAL_COPY_TEXT_EVENT: DataLayerPushEvent = {
  event: 'EMP_Vacancy',
  eventCategory: 'EMP_Vacancy',
  eventAction: 'copyText'
}
