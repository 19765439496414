/**
 * @param obj - variable to check
 * @param key - key to find if variable is object
 * @returns boolean & obj with type assertion
 */
export function isObjectWithProperty<ObjectKey extends PropertyKey = PropertyKey>(obj: unknown, key: ObjectKey): obj is Record<ObjectKey, unknown>
export function isObjectWithProperty<ObjectType extends object, ObjectKey extends keyof ObjectType = keyof ObjectType>(obj: unknown, key: ObjectKey): obj is { [K in keyof ObjectType]: ObjectType[K] }
export function isObjectWithProperty<ObjectType extends object = object, ObjectKey extends keyof ObjectType | PropertyKey = keyof ObjectType | PropertyKey>(
  obj: ObjectType,
  key: ObjectKey
): obj is ObjectKey extends keyof ObjectType ? { [K in keyof ObjectType]: ObjectType[K] } : { [K in keyof ObjectType]: ObjectType[K] } & Record<ObjectKey, unknown>
export function isObjectWithProperty<
  CompareObjectType extends object,
  ObjectType extends object = object,
  ObjectKey extends keyof CompareObjectType | keyof ObjectType = keyof CompareObjectType | keyof ObjectType
>(
  obj: ObjectType,
  key: ObjectKey
): obj is CompareObjectType extends ObjectType
  ? { [K in keyof CompareObjectType]: CompareObjectType[K] }
  : { [K in keyof CompareObjectType]: CompareObjectType[K] } & { [K in keyof ObjectType]: ObjectType[K] }
export function isObjectWithProperty(obj: unknown, key: PropertyKey): boolean {
  return typeof obj === 'object' && !!obj && key in obj
}

// // USE CASES
// // I: type of object unknown from context
// // resulted type --> add key with unknown value as { key: unknown }
// const d_1 = { name: 'type' } as unknown
// if (isObjectWithProperty(d_1, 'name')) {
//   const dd_1 = d_1 // Record<"name", unknown>
//   const prop1 = d_1.name // unknown
//   console.info(dd_1, prop1)
// }
// // resulted type --> uses ObjectType for obj & key typings
// if (isObjectWithProperty<{ name: string; age: number | null }>(d_1, 'name')) {
//   const dd_1 = d_1 // {name: string, age: number | null}
//   const prop1 = d_1.name // string
//   const prop2 = d_1.age // number | null
//   console.info(dd_1, prop1, prop2)
// }
// // key is narrowed to keyof specified ObjectType --> impossible to use unknown key
// if (isObjectWithProperty<{ name: string; age: number | null }>(d_1, 'surname')) {
// //
// }
//
// // II: type of object known from context
// // inherits type
// const d_2 = { name: 'type', title: 't' } as { name: string; title: string | null }
// if (isObjectWithProperty(d_2, 'title')) {
//   const dd_2 = d_2 // {name: string, title: string | null}
//   const prop1 = d_2.title // string | null
//   const prop2 = d_2.name // string
//   console.info(dd_2, prop1, prop2)
// }
// // inherits type & extends if key is not presented in inherited object type
// if (isObjectWithProperty(d_2, 'surname')) {
//   const dd_2_1 = d_2 //  {name: string, title: string | null} & Record<"surname", unknown
//   const prop1 = d_2.title // string | null
//   const prop2 = d_2.name // string
//   const prop3 = d_2.surname // unknown
//   console.info(dd_2_1, prop1, prop2, prop3)
// }
// // narrows inherited
// if (isObjectWithProperty<{ name: string; title: string }>(d_2, 'title')) {
//   const dd_2_2 = d_2 //  {name: string, title: string}
//   const prop1 = d_2.name // string
//   const prop2 = d_2.title // string
//   console.info(dd_2_2, prop1, prop2)
// }
// // adds to inherited
// if (isObjectWithProperty<{ age: number }>(d_2, 'age')) {
//   const dd_2_3 = d_2 // {name: string, title: string | null} & {age: number}
//   const prop1 = d_2.name // string
//   const prop2 = d_2.title // string
//   const prop3 = d_2.age // number
//   console.info(dd_2_3, prop1, prop2, prop3)
// }
// // merge with ComparedObject first priority
// if (isObjectWithProperty<{ title: string, age: number, addedAt: number }>(d_2, 'addedAt')) {
//   const dd_2_4 = d_2 // {name: string, title: string | null} & {title: string, age: number, addedAt: number}
//   const prop1 = d_2.name // string
//   const prop2 = d_2.title // string
//   const prop3 = d_2.age // number
//   const prop4 = d_2.addedAt // number
//   console.info(dd_2_4, prop1, prop2, prop3, prop4)
// }
// // key is narrowed to keyof specified ComparedObject --> for preventing type checking with irrelevant key
// if (isObjectWithProperty<{ title: string, age: number, addedAt: number }>(d_2, 'name')) {
//   //
// }
