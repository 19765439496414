import { Injectable } from '@angular/core'
import { Observable, Subject } from 'rxjs'
import { filter, map } from 'rxjs/operators'
import { channelTypes } from './channel-types'
import { Action, Notification, NotificationTypeEnum } from './models'

@Injectable({
  providedIn: 'root'
})
export class NotificationService {
  private middlewareFilters: Array<{ id: number; fn: (action: Notification<unknown>) => boolean }> = []
  private actions$ = new Subject<Action<unknown>>()

  public dispatch<PayloadType>(action: Action<PayloadType>): void {
    this.actions$.next(action)
  }

  public listen<CustomMetaType>(type: NotificationTypeEnum = NotificationTypeEnum.CREATED, channel?: channelTypes): Observable<Notification<CustomMetaType> | undefined> {
    return ((this.actions$ as unknown) as Subject<Action<CustomMetaType>>).asObservable().pipe(
      filter(action => (type ? action.type === type : true)),
      filter(action => (channel ? action?.payload?.channel === channel : true)),
      this.applyMiddlewareFilters(),
      map(action => action.payload)
    )
  }

  public registerMiddlewareFilter<CustomMetaType>(middlewareFn: (item: Notification<CustomMetaType>) => boolean): number {
    const fnId = Date.now()

    this.middlewareFilters.push({
      id: fnId,
      fn: middlewareFn as (action: Notification<unknown>) => boolean
    })

    return fnId
  }

  public removeMiddlewareFilter(fnId: number): void {
    if (!fnId) {
      return
    }
    this.middlewareFilters = this.middlewareFilters.filter(({ id }) => id !== fnId)
  }

  private applyMiddlewareFilters<CustomMetaType>(): (source: Observable<Action<CustomMetaType>>) => Observable<Action<CustomMetaType>> {
    return (source: Observable<Action<CustomMetaType>>) =>
      source.pipe(
        filter(({ payload, type }) =>
          this.middlewareFilters.length && type === NotificationTypeEnum.CREATED && payload && payload.channel === channelTypes.CHAT_MESSAGE
            ? this.middlewareFilters.every(({ fn }) => fn(payload))
            : true
        )
      )
  }
}
