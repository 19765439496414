import { CvOfferInfo } from './cv-offer-info'
import { CvSkill } from './cv-skill'
import { CvEducation } from './cv-education'
import { CvExperience } from './cv-experience'
import { CvLanguage } from './cv-language'
import { CvAdditional } from './cv-additional'
import { CvSubRubric } from './cv-sub-rubric'
import { CvRelocation } from './cv-relocation'
import { CvTag } from './cv-tag'
import { CvContact } from './cv-contact'
import { CvApply } from './cv-apply'
import { CvNote } from './cv-note'

export interface ResumeInfoChat {
  readonly id: string
  readonly name: string
  readonly contextName: string
  readonly unreadMessagesCount: number
}

export interface ResumeInfo {
  resumeId?: number
  notebookId?: number
  name?: string
  birthDate?: Date
  age?: string
  email?: string
  phone?: string
  sex?: number
  speciality?: string
  cityId?: number
  scheduleId?: number
  scheduleIds?: string
  educationId?: number
  salary?: string
  currencyId?: number
  currencySign?: string
  addDate?: Date
  salaryFull?: string
  stateMode?: ResumeInfo.StateModeEnum
  link?: string
  isAnonymous?: boolean
  searchState?: ResumeInfo.SearchStateEnum
  language?: ResumeInfo.LanguageEnum
  surname?: string
  fatherName?: string
  photo?: string
  profLevelId?: number
  activeLevelId?: ResumeInfo.ActiveLevelIdEnum
  notebookIds?: string
  branchIds?: string
  isOpenResumeInfo?: boolean
  isHasTextPartition?: boolean
  blockingReasonIds?: string
  showResumeInfo?: boolean
  isInBlackList?: boolean
  customBlockingReason26_Text?: string
  openContact_ViewDate?: Date
  isConfirmed?: boolean
  hasDislike?: boolean
  skype?: string
  diiaCertificate?: string
  offerDate?: Date
  offerPerson?: string
  vacancyOffers?: CvOfferInfo[]
  lastActivityDate?: Date
  updateDate?: Date
  isOnline?: boolean
  fillingPercentage?: number
  fillingType?: number
  skills?: CvSkill[]
  educations?: CvEducation[]
  experiences?: CvExperience[]
  languageSkills?: CvLanguage[]
  additionals?: CvAdditional[]
  subRubrics?: CvSubRubric[]
  relocations?: CvRelocation[]
  seoTags?: CvTag[]
  contacts?: CvContact[]
  vacancyLinks?: CvApply[]
  isFromCvDb?: boolean
  isSelectedToNotepad?: boolean
  notes?: CvNote[]
  serviceExists?: boolean
  hasPhone?: boolean
  contactsOpenedBySystem?: boolean
  contactsOpenedByMe?: boolean
  openContact_MultiUserName?: string
  openContact_MultiUserId?: number
  isPhoneConfirmed?: boolean
  isViewed?: boolean
  lastViewDate?: Date
  districtIds?: number[]
  isDisabled?: boolean
  isStudent?: boolean
  hasOwnCar?: boolean
  // вообще приходит null
  // Коммент:
  // есть маленький нюанс при тестировании на деве или тесте) Чатик будет возвращаться только в том случае,
  // если собеседник хотяб раз логинился на деве / тесте. То-есть нужно будет разок зайти в блокнот сикера.
  // На проде такой проблемы не будет, так как там люди, которые размещают резюме в любом случае логинились
  // еще пока есть проблема с получением чата пока не открыты контакты
  chat?: ResumeInfoChat
}

export namespace ResumeInfo {
  export type StateModeEnum = 'Active' | 'Deleted' | 'Hide' | 'UnCreated'
  export const StateModeEnum = {
    Active: 'Active' as StateModeEnum,
    Deleted: 'Deleted' as StateModeEnum,
    Hide: 'Hide' as StateModeEnum,
    UnCreated: 'UnCreated' as StateModeEnum
  }
  export type SearchStateEnum = 'Active' | 'None' | 'NowWorking'
  export const SearchStateEnum = {
    Active: 'Active' as SearchStateEnum,
    None: 'None' as SearchStateEnum,
    NowWorking: 'NowWorking' as SearchStateEnum
  }
  export type LanguageEnum = 'English' | 'None' | 'Russian' | 'Ukrainian'
  export const LanguageEnum = {
    English: 'English' as LanguageEnum,
    None: 'None' as LanguageEnum,
    Russian: 'Russian' as LanguageEnum,
    Ukrainian: 'Ukrainian' as LanguageEnum
  }
  export type ActiveLevelIdEnum = 'ExceptEmployers' | 'Hide' | 'MegaEmployer' | 'None' | 'OnlyForVerifiedEmployer' | 'Public' | 'StrongDefinedEmployer'
  export const ActiveLevelIdEnum = {
    ExceptEmployers: 'ExceptEmployers' as ActiveLevelIdEnum,
    Hide: 'Hide' as ActiveLevelIdEnum,
    MegaEmployer: 'MegaEmployer' as ActiveLevelIdEnum,
    None: 'None' as ActiveLevelIdEnum,
    OnlyForVerifiedEmployer: 'OnlyForVerifiedEmployer' as ActiveLevelIdEnum,
    Public: 'Public' as ActiveLevelIdEnum,
    StrongDefinedEmployer: 'StrongDefinedEmployer' as ActiveLevelIdEnum
  }
}
