import { VacancyPublicationType } from '@alliance/shared/domain-gql'
import {
  ActivatePublicationPackageItemFragment,
  ActiveVacancyPublicationItemFragment,
  OrderedPublicationPackageItemFragment,
  PackageServiceSupportedRegionItemFragment
} from '../employer-vacancy-services.generated'

export enum SpecialServiceType {
  BusinessPlus = 'BusinessPlus',
  WithRegions = 'WithRegions'
}

export type SuitablePublicationService = ActiveVacancyPublicationItemFragment | ActivatePublicationPackageItemFragment | OrderedPublicationPackageItemFragment

export interface PublicationServicesAggregatedByType {
  publicationType: VacancyPublicationType
  count: number
  specialServiceType: SpecialServiceType | null
  vacancyMailingCount: number | null
  vacancyRisingCount: number | null
  supportedRegions: readonly PackageServiceSupportedRegionItemFragment[]
  items: SuitablePublicationService[]
}
