import { Injectable } from '@angular/core'
import { RxStateService } from '@alliance/shared/models'
import { Observable, of } from 'rxjs'
import { map, switchMap } from 'rxjs/operators'
import { EmployerSessionService, ServicePackageCounterService } from '../../domain'

@Injectable({
  providedIn: 'root'
})
export class NoPublicationsLeftNotificationFacadeService extends RxStateService<{ hasNotification: boolean }> {
  public constructor(private readonly servicePackageCounterService: ServicePackageCounterService, private readonly employerSessionService: EmployerSessionService) {
    super()

    this.initState({
      hasNotification: this.hasNotification$()
    })
  }

  private hasNotification$(): Observable<boolean> {
    return this.employerSessionService.getEmployerSession$().pipe(
      map(session => (session ? session.companyId : null)),
      switchMap(companyId => {
        if (!companyId) {
          return of(false)
        }

        return this.servicePackageCounterService.getPublicationsCount$(companyId, 'inactive').pipe(map(count => count === 0))
      })
    )
  }
}
