import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { WaMutationObserverDirective } from './directives/mutation-observer.directive'

@NgModule({
  imports: [CommonModule],
  exports: [WaMutationObserverDirective],
  declarations: [WaMutationObserverDirective]
})
export class SharedWebApisMutationObserverModule {}
