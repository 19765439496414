import { RxStateService } from '@alliance/shared/models'
import { Injectable } from '@angular/core'
import { asyncScheduler, Observable, timer } from 'rxjs'
import { filter, take } from 'rxjs/operators'
import { channelTypes } from './channel-types'
import { Action, Notification, NotificationTypeEnum } from './models'
import { NotificationService } from './notification.service'

@Injectable({
  providedIn: 'root'
})

// It is temporary fix service.
// Notifications load as lazy module and when we call it on app init it's not already loading and not show notifications
// We add some wrapper method and state isRender to call notify when it is loading
export class AlertRenderStateService extends RxStateService<{
  isRendered: boolean
}> {
  public constructor(private notificationService: NotificationService) {
    super()

    this.initState({
      isRendered: false
    })
  }

  public isRendered$(): Observable<boolean> {
    return this.select('isRendered')
  }

  public setIsRenderedNotify(isRendered: boolean): void {
    this.set({ isRendered })
  }

  public dispatchWithAlertAwait<PayloadType>(action: Action<PayloadType>): void {
    if (this.get().isRendered) {
      this.notificationService.dispatch<PayloadType>(action)
    } else {
      this.hold(this.isRendered$().pipe(filter(Boolean), take(1)), () => {
        this.notificationService.dispatch<PayloadType>(action)
      })
    }
  }

  public listenWithAlertAwait<CustomMetaType>(type: NotificationTypeEnum = NotificationTypeEnum.CREATED, channel?: channelTypes): Observable<Notification<CustomMetaType> | undefined> {
    timer(0, asyncScheduler).subscribe(() => this.set({ isRendered: true }))
    return this.notificationService.listen<CustomMetaType>(type, channel)
  }
}
