import { log } from '@alliance/shared/logger'
import { CookieStorage, LocalStorage } from '@alliance/shared/storage'
import { Injectable } from '@angular/core'

@Injectable({ providedIn: 'root' })
export class ExperimentsService {
  public constructor(private localStorage: LocalStorage, private cookieStorage: CookieStorage) {}

  public isInExperimentByCid(arrayDigits: number[], devKey?: string, storage: Storage = this.localStorage): boolean {
    /* for debugging & testing */
    if (devKey && storage.getItem(devKey)) {
      return true
    }

    const cid = this.getCid()

    if (!cid) {
      return false
    }

    if (!arrayDigits) {
      log.warn({ where: 'shared-utils: ExperimentsService', category: 'unexpected_value', message: 'isInExperimentByCid failed', error: 'You must specify a list of the last numbers of cid' })
      return false
    }

    const lastDigitOfCid = this.getLastDigitOfCid(cid)
    return !!arrayDigits.filter(t => lastDigitOfCid === t).length
  }

  public isInExperimentByOneBeforeLast(arrayDigits: number[]): boolean {
    const cid = this.getCid()

    if (!cid) {
      return false
    }

    const oneToLastDigitOfCid = parseInt(cid.split('')[cid.length - 2] || '')
    return !!arrayDigits.filter(t => oneToLastDigitOfCid === t).length
  }

  public getCurrentExperiment(): number | null {
    return this.getLastDigitOfCid(this.getCid()) || null
  }

  private getLastDigitOfCid(cid: string): number {
    return parseInt(cid.split('')?.pop() || '')
  }

  private getCid(): string {
    try {
      const cid = this.cookieStorage.getItem('_ga') || ''
      return cid
    } catch (e) {
      return ''
    }
  }
}
