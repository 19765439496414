import { MyVacanciesFilterInput, MyVacanciesSortType } from '@alliance/shared/domain-gql'
import { RxStateService } from '@alliance/shared/models'
import { getNonNullableItems } from '@alliance/shared/utils'
import { Inject, Injectable, InjectionToken, Optional } from '@angular/core'
import { catchError, finalize, Observable, of } from 'rxjs'
import { filter, map, switchMap, take } from 'rxjs/operators'
import { VacanciesEmployerIdsService } from '../vacancies-employer-ids.service'
import { GetSimpleVacanciesListGQL, SimpleVacanciesListItemFragment, SimpleVacanciesListResponseFragment } from './simple-vacancies-list.generated'

const ITEMS_PER_PAGE = 10

export interface SimpleVacanciesListFilters {
  sort?: MyVacanciesSortType
  filter?: MyVacanciesFilterInput
}

export const SIMPLE_VACANCIES_LIST_DEFAULT_FILTERS = new InjectionToken<SimpleVacanciesListFilters>('SIMPLE_VACANCIES_LIST_DEFAULT_FILTERS')

@Injectable()
export class SimpleVacanciesListService extends RxStateService<{
  response: SimpleVacanciesListResponseFragment | null
  list: SimpleVacanciesListItemFragment[] | null
  defaultFilters: SimpleVacanciesListFilters | null
  filters: SimpleVacanciesListFilters | null
  hasNextPage: boolean
  isLoading: boolean
}> {
  public constructor(
    @Optional() @Inject(SIMPLE_VACANCIES_LIST_DEFAULT_FILTERS) private readonly defaultFilters: SimpleVacanciesListFilters | null,
    private readonly vacanciesEmployerIdsService: VacanciesEmployerIdsService,
    private readonly getSimpleVacanciesListGQL: GetSimpleVacanciesListGQL
  ) {
    super()

    this.initState({
      response: null,
      list: this.select('response').pipe(map(response => (response?.items ? getNonNullableItems(response.items) : null))),
      defaultFilters: this.getDefaultFilters$(),
      filters: this.select('defaultFilters'),
      hasNextPage: this.select('response').pipe(map(response => !!response?.pageInfo?.hasNextPage)),
      isLoading: true
    })
  }

  public getList$(): Observable<SimpleVacanciesListItemFragment[] | null> {
    if (!this.get()?.response) {
      this.connect(
        'response',
        this.select('filters').pipe(
          filter(Boolean),
          switchMap(filters => {
            this.set({ isLoading: true })

            return this.fetchVacancies$(filters).pipe(
              take(1),
              finalize(() => this.set({ isLoading: false }))
            )
          })
        )
      )
    }

    return this.select('list')
  }

  public setFilter(input: MyVacanciesFilterInput): void {
    this.set(({ filters }) => ({ filters: { ...filters, filter: { ...filters?.filter, ...input } } }))
  }

  public setSort(value: MyVacanciesSortType): void {
    this.set(({ filters }) => ({ filters: { ...filters, sort: value } }))
  }

  public resetFilters(): void {
    this.set({ filters: { ...this.get().defaultFilters } })
  }

  public fetchMore(): void {
    const { response, filters, isLoading } = this.get()

    if (!response || !response.pageInfo?.hasNextPage || !filters || isLoading) {
      return
    }

    this.set({ isLoading: true })

    this.hold(this.fetchVacancies$(filters, response.pageInfo?.endCursor).pipe(finalize(() => this.set({ isLoading: false }))), fetchResponse => {
      if (!fetchResponse) {
        return
      }

      this.set({
        response: {
          ...fetchResponse,
          items: [...getNonNullableItems(response.items || []), ...getNonNullableItems(fetchResponse.items || [])]
        }
      })
    })
  }

  private getDefaultFilters$(): Observable<SimpleVacanciesListFilters | null> {
    return this.vacanciesEmployerIdsService.getEmployerIds$().pipe(map(employerIds => (employerIds ? { ...this.defaultFilters, filter: { ...this.defaultFilters?.filter, employerIds } } : null)))
  }

  private fetchVacancies$(filters: SimpleVacanciesListFilters, after: string | null = null): Observable<SimpleVacanciesListResponseFragment | null> {
    return this.getSimpleVacanciesListGQL
      .fetch(
        {
          filter: filters.filter || null,
          sort: filters.sort || null,
          first: ITEMS_PER_PAGE,
          after
        },
        { fetchPolicy: 'network-only' }
      )
      .pipe(
        map(({ data }) => data?.myVacancies || null),
        catchError(() => of(null))
      )
  }
}
