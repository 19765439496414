import { isDevMode, NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'

import { InMemoryCache } from '@apollo/client/core'
import { Apollo } from 'apollo-angular'
import { HttpLink } from 'apollo-angular/http'

import { Environment } from '@alliance/shared/environment'
import { GetApolloClientMetricsService } from '@alliance/shared/gql/utils'
import { GqlLanguageMiddlewareService } from '@alliance/shared/translation'

import { CompanyTrackService } from './services/company/company.service'
import { APOLLO_CLIENT_NAME } from './constants'

@NgModule({
  imports: [CommonModule],
  providers: [CompanyTrackService]
})
export class SharedDomainGqlModule {
  public constructor(
    private apollo: Apollo,
    private httpLink: HttpLink,
    private environment: Environment,
    private langMiddleware: GqlLanguageMiddlewareService,
    private getApolloClientMetricsService: GetApolloClientMetricsService
  ) {
    if (!this.apollo.use(APOLLO_CLIENT_NAME)) {
      this.createClient()
    }
  }

  private createClient(): void {
    const cache = new InMemoryCache()

    const httpLink = this.httpLink.create({
      uri: this.environment.graphqlApi,
      withCredentials: false
    })

    this.apollo.createNamed(APOLLO_CLIENT_NAME, {
      link: this.langMiddleware.getMiddleware().concat(httpLink),
      cache,
      ...this.getApolloClientMetricsService.apolloClientMetrics,
      connectToDevTools: isDevMode()
    })
  }
}
