import { LanguageCodesEnum } from '@alliance/shared/models'
import { CookieStorage } from '@alliance/shared/storage'
import { DetectPlatformService } from '@alliance/shared/utils'
import { AbstractType, APP_INITIALIZER, inject, Provider } from '@angular/core'
import { distinctUntilChanged, skip } from 'rxjs/operators'
import { SAVED_LANGUAGE, SavedLanguage } from '../tokens/saved-language.token'
import { TranslationService } from '../translation.service'

export interface SavedLanguageStorageConfig {
  key: string
  storage: AbstractType<Storage>
}

export const DEFAULT_SAVED_LANGUAGE_STORAGE_CONFIG: SavedLanguageStorageConfig = {
  key: 'rualang',
  storage: CookieStorage
}

export const provideSavedLanguageStorage = (config: SavedLanguageStorageConfig = DEFAULT_SAVED_LANGUAGE_STORAGE_CONFIG): Provider[] => [
  {
    provide: SAVED_LANGUAGE,
    useFactory: (): SavedLanguage => {
      const key = config.key
      const storage = inject(config.storage)

      return {
        saveLanguage: (language: LanguageCodesEnum): void => storage.setItem(key, language),
        getSavedLanguage: (): LanguageCodesEnum | null => {
          const value = storage.getItem(key)
          return value ? Object.values(LanguageCodesEnum).find(language => value === language) || null : null
        }
      }
    }
  },
  {
    provide: APP_INITIALIZER,
    useFactory: (savedLanguage: SavedLanguage, platform: DetectPlatformService, translationService: TranslationService) => (): void => {
      if (platform.isServer) {
        return
      }

      const saved = savedLanguage.getSavedLanguage()

      if (saved) {
        translationService.setActiveLang(saved)
      }

      translationService
        .langChanges$()
        .pipe(distinctUntilChanged(), skip(1))
        .subscribe(language => savedLanguage.saveLanguage(language))
    },
    deps: [SAVED_LANGUAGE, DetectPlatformService, TranslationService],
    multi: true
  }
]
