import { GtmEvent } from '../../models/gtm-event'

export type CartEventAction = 'AddToCart' | 'AddWholesale' | 'Add_coins' | 'Buy' | 'Change_coins' | 'DeleteFromCart' | 'DeselectPromocode' | 'SelectPromocode' | 'View'

export interface CartEventInput {
  action: CartEventAction
  label?: string | number
  source?: string
  content?: string
}

export const getCartEvent = (input: CartEventInput): GtmEvent => {
  const { action, label, source, content } = input

  const eventAction = [action, source].filter(Boolean).join('_')
  const eventLabel = label ?? null
  const eventContent = content ?? null

  return {
    type: {
      event: 'EMP_Cart',
      eventCategory: 'EMP_Cart',
      eventAction,
      eventLabel,
      eventContent
    }
  }
}
