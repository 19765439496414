import { VacancyPublicationType } from '@alliance/shared/domain-gql'
import { IconAnonymousPublication, IconModel, IconPublicationsBusiness, IconPublicationsOptimum, IconPublicationsProfessional, IconTestAccess, IconPublicationBase } from '@alliance/shared/icons'

export const PublicationsIconsMap: Record<VacancyPublicationType, IconModel> = {
  [VacancyPublicationType.Test]: IconTestAccess,
  [VacancyPublicationType.Optimum]: IconPublicationsOptimum,
  [VacancyPublicationType.Professional]: IconPublicationsProfessional,
  [VacancyPublicationType.Anonymous]: IconAnonymousPublication,
  [VacancyPublicationType.Business]: IconPublicationsBusiness,
  [VacancyPublicationType.Base]: IconPublicationBase
}
