import { Injectable } from '@angular/core'
import { HttpClient, HttpHeaders, HttpRequest, HttpResponse } from '@angular/common/http'
import { BaseService as __BaseService } from '../base-service'
import { SharedAuthDataAccessConfiguration as __Configuration } from '../shared-auth-data-access-configuration'
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response'
import { Observable as __Observable } from 'rxjs'
import { filter as __filter, map as __map } from 'rxjs/operators'

import { LoginModel } from '../models/login-model'
import { AutoLoginModel } from '../models/auto-login-model'

@Injectable({
  providedIn: 'root'
})
class AuthService extends __BaseService {
  public static readonly authLoginPath = '/Login'
  public static readonly authLogoutPath = '/Logout'
  public static readonly authAutoLoginPath = '/AutoLogin'
  public static readonly authRefreshExternalPath = '/RefreshUsingOnlyJwtToken'
  public static readonly authGenerateTokensByEmailsPath = '/token-by-email'
  public static readonly authRefreshPath = '/Refresh'

  public constructor(config: __Configuration, http: HttpClient) {
    super(config, http)
  }

  /**
   * @param params The `AuthService.AuthLoginParams` containing the following parameters:
   *
   * - `model`:
   *
   * - `Authorization`: access token
   *
   * @return OK
   */
  authLoginResponse(params: AuthService.AuthLoginParams): __Observable<__StrictHttpResponse<string>> {
    const __params = this.newParams()
    let __headers = new HttpHeaders()
    let __body: any = null
    __body = params.model
    if (params.Authorization != null) {
      __headers = __headers.set('Authorization', params.Authorization.toString())
    }
    const req = new HttpRequest<any>('POST', this.rootUrl + `/Login`, __body, {
      headers: __headers,
      params: __params,
      responseType: 'json'
    })

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map(_r => _r as __StrictHttpResponse<string>)
    )
  }

  /**
   * @param params The `AuthService.AuthLoginParams` containing the following parameters:
   *
   * - `model`:
   *
   * - `Authorization`: access token
   *
   * @return OK
   */
  authLogin(params: AuthService.AuthLoginParams): __Observable<string> {
    return this.authLoginResponse(params).pipe(__map(_r => _r.body))
  }

  /**
   * @param Authorization access token
   */
  authLogoutResponse(Authorization?: string): __Observable<__StrictHttpResponse<any>> {
    const __params = this.newParams()
    let __headers = new HttpHeaders()
    const __body: any = null
    if (Authorization != null) {
      __headers = __headers.set('Authorization', Authorization.toString())
    }
    const req = new HttpRequest<any>('GET', this.rootUrl + `/Logout`, __body, {
      headers: __headers,
      params: __params,
      responseType: 'json'
    })

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map(_r => _r as __StrictHttpResponse<any>)
    )
  }

  /**
   * @param Authorization access token
   */
  authLogout(Authorization?: string): __Observable<any> {
    return this.authLogoutResponse(Authorization).pipe(__map(_r => _r.body))
  }

  /**
   * @param params The `AuthService.AuthAutoLoginParams` containing the following parameters:
   *
   * - `request`:
   *
   * - `Authorization`: access token
   *
   * @return OK
   */
  authAutoLoginResponse(params: AuthService.AuthAutoLoginParams): __Observable<__StrictHttpResponse<string>> {
    const __params = this.newParams()
    let __headers = new HttpHeaders()
    let __body: any = null
    __body = params.request
    if (params.Authorization != null) {
      __headers = __headers.set('Authorization', params.Authorization.toString())
    }
    const req = new HttpRequest<any>('POST', this.rootUrl + `/AutoLogin`, __body, {
      headers: __headers,
      params: __params,
      responseType: 'json'
    })

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map(_r => _r as __StrictHttpResponse<string>)
    )
  }

  /**
   * @param params The `AuthService.AuthAutoLoginParams` containing the following parameters:
   *
   * - `request`:
   *
   * - `Authorization`: access token
   *
   * @return OK
   */
  authAutoLogin(params: AuthService.AuthAutoLoginParams): __Observable<string> {
    return this.authAutoLoginResponse(params).pipe(__map(_r => _r.body))
  }

  /**
   * @param Authorization access token
   * @return OK
   */
  authRefreshExternalResponse(Authorization?: string): __Observable<__StrictHttpResponse<string>> {
    const __params = this.newParams()
    let __headers = new HttpHeaders()
    const __body: any = null
    if (Authorization != null) {
      __headers = __headers.set('Authorization', Authorization.toString())
    }
    const req = new HttpRequest<any>('GET', this.rootUrl + `/RefreshUsingOnlyJwtToken`, __body, {
      headers: __headers,
      params: __params,
      responseType: 'json'
    })

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map(_r => _r as __StrictHttpResponse<string>)
    )
  }

  /**
   * @param Authorization access token
   * @return OK
   */
  authRefreshExternal(Authorization?: string): __Observable<string> {
    return this.authRefreshExternalResponse(Authorization).pipe(__map(_r => _r.body))
  }

  /**
   * @param params The `AuthService.AuthGenerateTokensByEmailsParams` containing the following parameters:
   *
   * - `emails`:
   *
   * - `Authorization`: access token
   *
   * @return OK
   */
  authGenerateTokensByEmailsResponse(params: AuthService.AuthGenerateTokensByEmailsParams): __Observable<__StrictHttpResponse<{}>> {
    const __params = this.newParams()
    let __headers = new HttpHeaders()
    let __body: any = null
    __body = params.emails
    if (params.Authorization != null) {
      __headers = __headers.set('Authorization', params.Authorization.toString())
    }
    const req = new HttpRequest<any>('POST', this.rootUrl + `/token-by-email`, __body, {
      headers: __headers,
      params: __params,
      responseType: 'json'
    })

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map(_r => _r as __StrictHttpResponse<{}>)
    )
  }

  /**
   * @param params The `AuthService.AuthGenerateTokensByEmailsParams` containing the following parameters:
   *
   * - `emails`:
   *
   * - `Authorization`: access token
   *
   * @return OK
   */
  authGenerateTokensByEmails(params: AuthService.AuthGenerateTokensByEmailsParams): __Observable<{}> {
    return this.authGenerateTokensByEmailsResponse(params).pipe(__map(_r => _r.body))
  }

  /**
   * @param Authorization access token
   * @return OK
   */
  authRefreshResponse(Authorization?: string): __Observable<__StrictHttpResponse<string>> {
    const __params = this.newParams()
    let __headers = new HttpHeaders()
    const __body: any = null
    if (Authorization != null) {
      __headers = __headers.set('Authorization', Authorization.toString())
    }
    const req = new HttpRequest<any>('GET', this.rootUrl + `/Refresh`, __body, {
      headers: __headers,
      params: __params,
      responseType: 'json'
    })

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map(_r => _r as __StrictHttpResponse<string>)
    )
  }

  /**
   * @param Authorization access token
   * @return OK
   */
  authRefresh(Authorization?: string): __Observable<string> {
    return this.authRefreshResponse(Authorization).pipe(__map(_r => _r.body))
  }
}

namespace AuthService {
  /**
   * Parameters for AuthLogin
   */
  export interface AuthLoginParams {
    model: LoginModel

    /**
     * access token
     */
    Authorization?: string
  }

  /**
   * Parameters for AuthAutoLogin
   */
  export interface AuthAutoLoginParams {
    request: AutoLoginModel

    /**
     * access token
     */
    Authorization?: string
  }

  /**
   * Parameters for AuthGenerateTokensByEmails
   */
  export interface AuthGenerateTokensByEmailsParams {
    emails: string[]

    /**
     * access token
     */
    Authorization?: string
  }
}

export { AuthService }
