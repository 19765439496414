import { parse as parseImpl, Locale } from 'date-fns'

export const parse = (
  dateString: string,
  formatString: string,
  referenceDate: Date,
  options?: {
    locale?: Locale
    weekStartsOn?: 0 | 1 | 2 | 3 | 4 | 5 | 6
    firstWeekContainsDate?: 1 | 4
    useAdditionalWeekYearTokens?: boolean
    useAdditionalDayOfYearTokens?: boolean
  }
): Date => parseImpl(dateString, formatString, referenceDate, options)
