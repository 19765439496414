export interface VacancyCustomHeaderItem {
  url?: string
  videoCoverImageUrl?: string
  type?: VacancyCustomHeaderItem.TypeEnum
}

export namespace VacancyCustomHeaderItem {
  export type TypeEnum = 'Image' | 'YouTubeVideo'
  export const TypeEnum = {
    Image: 'Image' as TypeEnum,
    YouTubeVideo: 'YouTubeVideo' as TypeEnum
  }
}
