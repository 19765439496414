import { Inject, Injectable, Renderer2, RendererFactory2 } from '@angular/core'
import { DOCUMENT } from '@angular/common'
import { Meta, Title } from '@angular/platform-browser'
import { PlatformHosts, SeoParamsResponse } from './models'
import { TranslationService } from '@alliance/shared/translation'
enum MetaDefinition {
  'description' = 'description',
  'robots' = 'robots'
}

@Injectable({ providedIn: 'root' })
export class SeoDomManipulatingService {
  private ALTERNATE_LINK_MEDIA_ATTR = 'only screen and (max-width: 640px)'
  private ALTERNATE_LINK_SELECTOR = `link[rel="alternate"][media="${this.ALTERNATE_LINK_MEDIA_ATTR}"]`
  private CANONICAL_LINK_SELECTOR = 'link[rel=canonical]'
  private renderer: Renderer2

  public constructor(private meta: Meta, private title: Title, private rendererFactory: RendererFactory2, private translateService: TranslationService, @Inject(DOCUMENT) private document: Document) {
    this.renderer = this.rendererFactory.createRenderer(this.document, null)
  }

  public setTitle(title: string): void {
    this.title.setTitle(title)
  }

  public setDescription(description: string): void {
    this.setMetaTag(MetaDefinition.description, description)
  }

  public removeDescription(): void {
    this.removeMetaTag(MetaDefinition.description)
  }

  public setNoIndex(): void {
    this.setMetaTag(MetaDefinition.robots, 'noindex, nofollow')
  }

  public setIndex(): void {
    this.setMetaTag(MetaDefinition.robots, 'index, follow')
  }

  public removeNoIndex(): void {
    this.removeMetaTag(MetaDefinition.robots)
  }

  public setCanonicalUrl(url: string): void {
    let link: HTMLLinkElement | null = this.getLinkElementBySelector(this.CANONICAL_LINK_SELECTOR)

    if (link) {
      this.renderer.setAttribute(link, 'href', url)
    } else {
      link = this.renderer.createElement('link') as HTMLLinkElement
      this.renderer.setAttribute(link, 'rel', 'canonical')
      this.renderer.setAttribute(link, 'href', url)
      this.renderer.appendChild(this.document.head, link)
    }
  }

  public removeCanonicalUrl(): void {
    this.removeElementFromDOM(this.CANONICAL_LINK_SELECTOR)
  }

  public setAlternateUrl(url?: string): void {
    let link = this.getLinkElementBySelector(this.ALTERNATE_LINK_SELECTOR)

    if (link && url) {
      return this.renderer.setAttribute(link, 'href', url)
    }

    link = this.renderer.createElement('link') as HTMLLinkElement
    this.renderer.setAttribute(link, 'rel', 'alternate')
    this.renderer.setAttribute(link, 'media', this.ALTERNATE_LINK_MEDIA_ATTR)
    if (url) {
      this.renderer.setAttribute(link, 'href', url)
    }
    this.renderer.appendChild(this.document.head, link)
  }

  public removeAlternateUrl(): void {
    this.removeElementFromDOM(this.ALTERNATE_LINK_SELECTOR)
  }

  public setHreflang(lang: string, url: string): void {
    let link: HTMLLinkElement | null = this.getLinkElementBySelector(`link[rel="alternate"][hreflang="${lang}"]`)

    if (link) {
      return this.renderer.setAttribute(link, 'href', url)
    }

    link = this.renderer.createElement('link') as HTMLLinkElement
    this.renderer.setAttribute(link, 'rel', 'alternate')
    this.renderer.setAttribute(link, 'hreflang', lang)
    this.renderer.setAttribute(link, 'href', url)
    this.renderer.appendChild(this.document.head, link)
  }

  public removeHrefLangs(): void {
    const head = this.document.head
    const links = Array.from(head.querySelectorAll('link[rel="alternate"][hreflang]'))
    if (links?.length) {
      links.forEach(link => this.renderer.removeChild(head, link))
    }
  }

  public setMetaTag(name: MetaDefinition, content: string): void {
    if (content) {
      if (this.getMetaTag(name)) {
        this.removeMetaTag(name)
      }
      this.meta.addTag({ name, content })
    }
  }

  public removeMetaTag(name: MetaDefinition): void {
    if (this.getMetaTag(name)) {
      this.meta.removeTag(`name="${name}"`)
    }
  }

  public getMetaTag(name: MetaDefinition): HTMLMetaElement | null {
    return this.meta.getTag(`name="${name}"`)
  }

  public getLinkElementBySelector(selector: string): HTMLLinkElement | null {
    return this.document.head.querySelector(selector)
  }

  public removeElementFromDOM(selector: string): void {
    const el = this.getLinkElementBySelector(selector)
    if (el) {
      this.renderer.removeChild(this.document.head, el)
    }
  }

  public removeJsonLd(): void {
    const scripts = Array.from(this.document.head.querySelectorAll('script[type="application/ld+json"]'))
    scripts.forEach(script => {
      this.renderer.removeChild(this.document.head, script)
    })
  }

  public setJsonLd(jsonLdData: string): void {
    this.removeJsonLd()

    if (jsonLdData) {
      const jsonLdScript = this.renderer.createElement('script') as unknown
      this.renderer.setAttribute(jsonLdScript, 'id', 'jsonld')
      this.renderer.setAttribute(jsonLdScript, 'type', 'application/ld+json')
      this.renderer.setProperty(jsonLdScript, 'innerHTML', jsonLdData)
      this.renderer.appendChild(this.document?.head, jsonLdScript)
    }
  }

  public setDefaultSeo(): void {
    this.setTitle('robota.ua')
    this.setHtmlLangAttribute()
    this.removeDescription()
    this.removeCanonicalUrl()
    this.removeAlternateUrl()
    this.removeHrefLangs()
    this.setNoIndex()
    this.removeJsonLd()
  }

  public setAllParamsInDOM(platform: PlatformHosts, { title, description, canonicalUrl, noIndexNoFollow, hrefLang, jsonLd, alternateUrl }: SeoParamsResponse): void {
    this.setDefaultSeo()

    if (title) {
      this.setTitle(title)
    }

    if (description) {
      this.setDescription(description)
    }

    if (canonicalUrl) {
      this.setCanonicalUrl(canonicalUrl)
    }

    if (alternateUrl && platform === PlatformHosts.desktop) {
      this.setAlternateUrl(alternateUrl)
    }

    if (!noIndexNoFollow) {
      this.removeNoIndex()
      this.setIndex()
    }

    hrefLang = hrefLang || []

    hrefLang.forEach(({ langCode, mobileUrl, desktopUrl }) => {
      const url = platform === PlatformHosts.desktop ? desktopUrl : mobileUrl
      if (langCode && url) {
        this.setHreflang(langCode, url)
      }
    })

    if (jsonLd) {
      this.setJsonLd(platform === PlatformHosts.desktop ? jsonLd?.desktop : jsonLd?.mobile)
    }
  }

  private setHtmlLangAttribute(): void {
    const currLang = this.translateService.getCurrentLang()
    this.document.querySelector('html')?.setAttribute('lang', currLang)
  }
}
