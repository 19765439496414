import { RxStateService } from '@alliance/shared/models'
import { PubsubMessageTypesEnum, TransferService } from '@alliance/shared/utils'
import { Overlay, OverlayPositionBuilder, OverlayRef } from '@angular/cdk/overlay'
import { ComponentPortal, PortalInjector, TemplatePortal } from '@angular/cdk/portal'
import { ComponentRef, Injectable, Injector, StaticProvider, TemplateRef, Type, ViewContainerRef } from '@angular/core'
import { asyncScheduler, distinctUntilChanged, timer } from 'rxjs'
import { ModalComponent } from '../../components/modal/modal.component'
import { ModalOverlayRef } from './modal-overlay-ref'
import { SANTA_MODAL_DATA, SANTA_MODAL_REF } from './modal.tokens'
import { IBaseModal } from '../../models/common-modals.interface'

@Injectable({
  providedIn: 'root'
})
export class ModalService
  extends RxStateService<{
    headerIsRendered: boolean
  }>
  implements IBaseModal
{
  protected readonly hostComponent = ModalComponent
  private overlayRef: OverlayRef | undefined

  public constructor(protected injector: Injector, private overlay: Overlay, private overlayPositionBuilder: OverlayPositionBuilder, private transferService: TransferService) {
    super()

    this.initState({
      headerIsRendered: this.transferService.subscribeTo<boolean>(PubsubMessageTypesEnum.headerIsRendered).pipe(distinctUntilChanged())
    })
  }

  public get hasAttachedModal(): boolean {
    return !!this.overlayRef && this.overlayRef.hasAttached()
  }

  public openByComponent<T, K>(content: Type<T>, data?: K): ModalOverlayRef {
    const overlayRef = this.createOverlay()
    const modalRef = new ModalOverlayRef(overlayRef)
    const portal = new ComponentPortal(content)
    const injector = this.createInjector<K | undefined>(modalRef, data)
    const modalPortal = new ComponentPortal<ModalComponent<T>>(this.hostComponent, null, injector)

    timer(0, asyncScheduler).subscribe(() => {
      const modalComponent: ComponentRef<ModalComponent<T>> = overlayRef.attach(modalPortal)
      modalComponent.instance.content = portal
      modalComponent.changeDetectorRef.detectChanges()
    })

    return modalRef
  }

  public openByComponentWithProviders<T, K>(contentOrPortal: Type<T> | ComponentPortal<T>, data?: K, providers?: StaticProvider[]): ModalOverlayRef {
    let modalContentPortal: ComponentPortal<T>
    if (contentOrPortal instanceof ComponentPortal) {
      modalContentPortal = contentOrPortal
    } else {
      modalContentPortal = new ComponentPortal(contentOrPortal)
    }
    const overlayRef = this.createOverlay()
    const modalRef = new ModalOverlayRef(overlayRef)
    const injector = this.createInjectorWithProviders<K | null>(modalRef, data ?? null, providers ?? [])
    const modalPortal = new ComponentPortal<ModalComponent<T>>(this.hostComponent, null, injector)

    timer(0, asyncScheduler).subscribe(() => {
      const modalComponent: ComponentRef<ModalComponent<T>> = overlayRef.attach(modalPortal)
      modalComponent.instance.content = modalContentPortal
      modalComponent.changeDetectorRef.detectChanges()
    })

    return modalRef
  }

  public openByComponentWithContextInjector<T, K>(contentOrPortal: Type<T> | ComponentPortal<T>, data?: K, contextInjector?: Injector): ModalOverlayRef {
    let modalContentPortal: ComponentPortal<T>
    if (contentOrPortal instanceof ComponentPortal) {
      modalContentPortal = contentOrPortal
    } else {
      modalContentPortal = new ComponentPortal(contentOrPortal)
    }
    const overlayRef = this.createOverlay()
    const modalRef = new ModalOverlayRef(overlayRef)
    const injector = this.createInjectorWithContextInjector<K | null>(modalRef, data ?? null, contextInjector ?? this.injector)
    const modalPortal = new ComponentPortal<ModalComponent<T>>(this.hostComponent, null, injector)

    timer(0, asyncScheduler).subscribe(() => {
      const modalComponent: ComponentRef<ModalComponent<T>> = overlayRef.attach(modalPortal)
      modalComponent.instance.content = modalContentPortal
      modalComponent.changeDetectorRef.detectChanges()
    })

    return modalRef
  }

  public openByTemplate<K>(content: TemplateRef<K>, context?: K, viewContainerRef: ViewContainerRef | null = null): ModalOverlayRef {
    const overlayRef = this.createOverlay()
    const modalRef = new ModalOverlayRef(overlayRef)
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const portal = new TemplatePortal(content, viewContainerRef, context)
    const modalPortal = new ComponentPortal<ModalComponent<K>>(
      this.hostComponent,
      viewContainerRef,
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      this.createInjector<K>(modalRef, context)
    )

    timer(0, asyncScheduler).subscribe(() => {
      const modalComponent: ComponentRef<ModalComponent<K>> = overlayRef.attach(modalPortal)
      modalComponent.instance.content = portal
    })

    return modalRef
  }

  public close(): void {
    if (this.hasAttachedModal) {
      this.overlayRef?.detach()
      this.overlayRef?.dispose()
    }
  }

  protected createOverlay(): OverlayRef {
    const scrollStrategy = this.overlay.scrollStrategies.block()
    const positionStrategy = this.overlayPositionBuilder.global().centerHorizontally().centerVertically()
    const overlayRef = this.overlay.create({ positionStrategy, scrollStrategy, width: '100%', height: '100%' })
    this.overlayRef = overlayRef
    return overlayRef
  }

  protected createInjector<K>(modalRef: ModalOverlayRef, data: K): PortalInjector {
    const injectionTokens = new WeakMap()

    injectionTokens.set(SANTA_MODAL_REF, modalRef)
    injectionTokens.set(SANTA_MODAL_DATA, data)

    return new PortalInjector(this.injector, injectionTokens)
  }

  protected createInjectorWithProviders<K>(modalRef: ModalOverlayRef, data: K, providers: StaticProvider[]): Injector {
    return Injector.create({
      providers: [
        {
          provide: SANTA_MODAL_REF,
          useValue: modalRef
        },
        {
          provide: SANTA_MODAL_DATA,
          useValue: data
        },
        ...providers
      ],
      parent: this.injector
    })
  }

  protected createInjectorWithContextInjector<K>(modalRef: ModalOverlayRef, data: K, contextInjector: Injector): Injector {
    return Injector.create({
      providers: [
        {
          provide: SANTA_MODAL_REF,
          useValue: modalRef
        },
        {
          provide: SANTA_MODAL_DATA,
          useValue: data
        }
      ],
      parent: contextInjector
    })
  }
}
