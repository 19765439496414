import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { LazyShellAlertComponent } from './lazy-shell-alert/lazy-shell-alert.component'

@NgModule({
  imports: [CommonModule],
  declarations: [LazyShellAlertComponent],
  exports: [LazyShellAlertComponent]
})
export class NotificationShellAlertModule {}
