import { Environment } from '@alliance/shared/environment'
import { ModuleWithProviders, NgModule } from '@angular/core'
import { HttpClientModule } from '@angular/common/http'
import { ServiceApiDataAccessConfiguration, ServiceApiDataAccessConfigurationInterface } from './service-api-data-access-configuration'

import { FeatureAccessService } from './services/feature-access.service'

/**
 * Provider for all ServiceApiDataAccess services, plus ServiceApiDataAccessConfiguration
 */
@NgModule({
  imports: [HttpClientModule],
  exports: [HttpClientModule],
  declarations: [],
  providers: [
    {
      provide: ServiceApiDataAccessConfiguration,
      useFactory: (environment: Environment): ServiceApiDataAccessConfigurationInterface => ({
        rootUrl: environment.employerServiceApi
      }),
      deps: [Environment]
    },
    FeatureAccessService
  ]
})
export class ServiceApiDataAccessModule {
  public static forRoot(customParams?: ServiceApiDataAccessConfigurationInterface): ModuleWithProviders<ServiceApiDataAccessModule> {
    return {
      ngModule: ServiceApiDataAccessModule,
      providers: [
        customParams && customParams.rootUrl
          ? {
              provide: ServiceApiDataAccessConfiguration,
              useValue: { rootUrl: customParams.rootUrl }
            }
          : []
      ]
    }
  }
}
