import { Injectable } from '@angular/core'
import { BehaviorSubject, Observable } from 'rxjs'
import { NewAccountActionsList } from './constants/new-account-actions-list'
import { NewAccountAction } from './models/new-account-action-interface'
import { NewAccountActionsEnum } from './models/new-account-actions-enum'

@Injectable({
  providedIn: 'root'
})
export class EmployerActivityService {
  public employerActivityHistory$: Observable<NewAccountAction[]>
  private readonly actionsList = NewAccountActionsList

  private _employerActivityHistory = new BehaviorSubject<NewAccountAction[]>([])

  public constructor() {
    this.employerActivityHistory$ = this._employerActivityHistory.asObservable()
  }

  public addActionToHistory(key: NewAccountActionsEnum): void {
    const currentHistoryState = this._employerActivityHistory.getValue()
    const newAction = this.actionsList.find(action => action.type === key)
    if (newAction) {
      this._employerActivityHistory.next([...currentHistoryState, newAction])
    }
  }
}
