export interface ResumeContactsFilters {
  cityIds: number[]
  rubricIds: number[]
}

export enum ResumeContactsScenariosEnum {
  action = 'action',
  activation = 'activation',
  activationWithCvdbServices = 'activationWithCvdbServices',
  activationLimited = 'activationLimited',
  offer = 'offer',
  offerWithCvdbServices = 'offerWithCvdbServices',
  offerLimited = 'offerLimited'
}
