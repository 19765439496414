import { log } from '@alliance/shared/logger'
import { EventEmitter, Inject, Injectable } from '@angular/core'
import { WINDOW } from '@ng-web-apis/common'
import { fromEvent, Observable, of } from 'rxjs'
import { filter, pluck } from 'rxjs/operators'
import { DetectPlatformService } from './detect-platform.service'

export interface IsEnabledPayload {
  isEnabled: boolean
}

@Injectable({
  providedIn: 'root'
})
export class TransferService {
  private emitter = new EventEmitter()
  public constructor(private platform: DetectPlatformService, @Inject(WINDOW) private readonly windowRef: Window) {}

  public publish<T>(eventName = '', payload: T): boolean {
    try {
      if (this.isCustomEvent()) {
        const event = new CustomEvent(eventName, { detail: payload })
        this.windowRef.dispatchEvent(event)
      } else {
        this.emitter.emit({ [eventName]: payload })
      }
      return true
    } catch (e) {
      log.log({ where: 'shared-utils: TransferService', category: 'try_catch', message: 'publish custom event failed', error: e })
      return false
    }
  }

  public subscribeTo<T>(eventName = ''): Observable<T> {
    try {
      return this.isCustomEvent()
        ? fromEvent<CustomEvent<T>>(this.windowRef, eventName).pipe(
            pluck('detail'),
            filter(payload => typeof payload !== 'undefined')
          )
        : (this.emitter.pipe(
            pluck(eventName),
            filter(payload => typeof payload !== 'undefined')
          ) as Observable<T>)
    } catch (e) {
      log.log({ where: 'shared-utils: TransferService', category: 'try_catch', message: 'subscribeTo custom event failed', error: e })
      return of()
    }
  }

  private isCustomEvent(): boolean {
    return this.platform.isBrowser && CustomEvent ? true : false
  }
}
