// from https://angularbites.com/async-rendering-with-a-single-rx-operator/

import { asyncScheduler, from, Observable, of, scheduled } from 'rxjs'
import { bufferCount, concatMap, delay, scan, switchMap, tap } from 'rxjs/operators'

export const lazyArray = <T>(handleOnlyFirstEmission = true, delayMs = 0, concurrency = 1): ((source$: Observable<T[]>) => Observable<T[]>) => {
  let isFirstEmission = true

  return (source$: Observable<T[]>): Observable<T[]> =>
    source$.pipe(
      switchMap(items => {
        if (!items?.length || (handleOnlyFirstEmission && !isFirstEmission)) {
          return of(items)
        }

        return from(items).pipe(
          bufferCount(concurrency),
          concatMap((value, index) => {
            const delayed = delay<T[]>(index * delayMs)
            return scheduled(of(value), asyncScheduler).pipe(delayed)
          }),
          scan((acc: T[], steps: T[]) => [...acc, ...steps], []),
          tap((scannedItems: T[]) => {
            const scanDidComplete = scannedItems.length === items.length

            if (handleOnlyFirstEmission && scanDidComplete) {
              isFirstEmission = false
            }
          })
        )
      })
    )
}
