import { RxStateService } from '@alliance/shared/models'
import { retryWhenStrategy } from '@alliance/shared/utils'
import { Injectable } from '@angular/core'
import { Observable, of } from 'rxjs'
import { catchError, filter, map, retryWhen, shareReplay, take } from 'rxjs/operators'
import { GetStaffSizeByIdGQL, GetStaffSizesDictionaryGQL, StaffSizeDictionaryItemFragment } from './staff-size-dictionary.generated'

@Injectable({
  providedIn: 'root'
})
export class StaffSizeDictionaryService extends RxStateService<{
  staffSizesDictionary: StaffSizeDictionaryItemFragment[]
}> {
  public constructor(private getStaffSizesDictionaryGQL: GetStaffSizesDictionaryGQL, private getStaffSizeByIdGQL: GetStaffSizeByIdGQL) {
    super()

    this.initState({
      staffSizesDictionary: this.getStaffSizes$().pipe(
        filter(companyStaffSizes => !!companyStaffSizes?.length),
        shareReplay(1)
      )
    })
  }

  public getStaffSizeById$(staffSizeId: string): Observable<StaffSizeDictionaryItemFragment | null> {
    return this.select('staffSizesDictionary').pipe(
      take(1),
      map(companyStaffSizes => companyStaffSizes.find(staffSize => staffSize.id === staffSizeId) || null)
    )
  }

  public fetchStaffSizeById$(staffSizeId: string): Observable<StaffSizeDictionaryItemFragment | null> {
    return this.getStaffSizeByIdGQL.fetch({ id: staffSizeId }, { fetchPolicy: 'cache-first' }).pipe(
      map(({ data: { companyStaffSize } }) => companyStaffSize || null),
      retryWhen(retryWhenStrategy()),
      catchError(() => of(null))
    )
  }

  private getStaffSizes$(): Observable<StaffSizeDictionaryItemFragment[]> {
    return this.getStaffSizesDictionaryGQL.fetch({}, { fetchPolicy: 'cache-first' }).pipe(
      map(({ data: { companyStaffSizes } }) =>
        (companyStaffSizes || []).reduce<StaffSizeDictionaryItemFragment[]>((acc, companyStaffSize) => (companyStaffSize ? [...acc, companyStaffSize] : acc), [])
      ),
      retryWhen(retryWhenStrategy()),
      catchError(() => of([]))
    )
  }
}
