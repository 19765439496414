import { ModuleWithProviders, NgModule, Provider } from '@angular/core'
import { TranslocoConfig, TranslocoDirective, TranslocoModule, TranslocoPipe } from '@ngneat/transloco'
import { TranslocoMessageFormatModule } from '@ngneat/transloco-messageformat'
import { languageProvider, translocoConfigProvider } from './providers/translation.providers'
import { TranslatePipe } from './translate/translate.pipe'

@NgModule({
  imports: [TranslocoModule, TranslocoMessageFormatModule.forRoot({ locales: ['ru-RU', 'uk-UA'] })],
  exports: [TranslocoPipe, TranslocoDirective, TranslatePipe],
  declarations: [TranslatePipe]
})
export class SharedTranslationModule {
  public static forRoot(config?: TranslocoConfig, additionalProviders: Provider[] = []): ModuleWithProviders<SharedTranslationModule> {
    return {
      ngModule: SharedTranslationModule,
      providers: [translocoConfigProvider(config), languageProvider(), additionalProviders]
    }
  }
}
