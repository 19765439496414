import { MobileModalService, ModalService } from '@alliance/shared/santa'
import { LoggingToBigQueryService, MediaService } from '@alliance/shared/utils'
import { Inject, Injectable, Injector } from '@angular/core'
import { WINDOW } from '@ng-web-apis/common'
import { map, switchMap, take } from 'rxjs/operators'
import { ConfirmReloadOnChunkLoadErrorComponent } from '../confirm-reload-on-chunk-load-error/confirm-reload-on-chunk-load-error.component'

@Injectable()
export class OpenModalHelperService {
  public constructor(@Inject(WINDOW) private readonly windowRef: Window, private injector: Injector, private bqService: LoggingToBigQueryService, private mediaService: MediaService) {}

  private get mobileModalService(): MobileModalService {
    return this.injector.get(MobileModalService)
  }

  private get modalService(): ModalService {
    return this.injector.get(ModalService)
  }

  public showConfirmReloadModal(): void {
    this.pushModalShownEvent()

    this.mediaService
      .select('isMobileScreen')
      .pipe(
        map(isMobileScreen => {
          if (this.mobileModalService.hasAttachedModal) {
            this.mobileModalService.close()
          }

          if (this.modalService.hasAttachedModal) {
            this.modalService.close()
          }

          return isMobileScreen ? this.mobileModalService.openByComponent(ConfirmReloadOnChunkLoadErrorComponent) : this.modalService.openByComponent(ConfirmReloadOnChunkLoadErrorComponent)
        }),
        switchMap(modalRef => modalRef.afterClosed$()),
        take(1),
        map(result => !!result)
      )
      .subscribe(reload => {
        this.pushModalActionEvent(reload ? 'Refresh' : 'Close')
        if (reload) {
          this.windowRef?.location?.reload()
        }
      })
  }

  private pushModalShownEvent(): void {
    this.bqService.pushToDataLayer({
      event: 'EMP_other',
      eventCategory: 'EMP_other',
      eventAction: 'ShowPopup_HashError',
      eventLabel: null,
      eventContent: null
    })
  }

  private pushModalActionEvent(action: 'Refresh' | 'Close'): void {
    this.bqService.pushToDataLayer({
      event: 'EMP_other',
      eventCategory: 'EMP_other',
      eventAction: 'ClickPopup_HashError',
      eventLabel: action,
      eventContent: null
    })
  }
}
