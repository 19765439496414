import { Injectable } from '@angular/core'
import { Observable, catchError, map, of } from 'rxjs'

import { GetVacancyShortStatisticGQL } from './vacancy-statistic.generated'
import { VacancyStatisticParams } from '../../../models/vacancy-actions/vacancy-statistic/vacancy-statistic-params.interface'
import { VacancyStatisticResponse } from '../../../models/vacancy-actions/vacancy-statistic/vacancy-statistic-response.interface'

@Injectable()
export class VacancyStatisticService {
  public constructor(private readonly getVacancyShortStatisticGQL: GetVacancyShortStatisticGQL) {}

  public getVacancyShortStatistic$(params: VacancyStatisticParams): Observable<VacancyStatisticResponse | null> {
    const { vacancyId, appliesFilter, period } = params

    return this.getVacancyShortStatisticGQL
      .fetch(
        {
          vacancyId,
          appliesFilter,
          statisticsPeriod: period,
          linkClicksPeriod: period
        },
        { fetchPolicy: 'cache-first' }
      )
      .pipe(
        map(({ data }) => {
          const vacancy = data?.vacancy

          if (!vacancy) {
            return null
          }

          return {
            appliesCount: vacancy?.applies || 0,
            openPhoneCount: vacancy?.statisticsTimeseries?.openedContacts?.total || 0,
            viewsCount: vacancy?.statisticsTimeseries?.views?.total || 0,
            showsCount: vacancy?.statisticsTimeseries?.shows?.total || 0,
            bodyLinksClickCount: vacancy?.linkClicks?.total || 0,
            appliesByLinkCount: vacancy?.appliesByLinkCount || 0
          }
        }),
        catchError(() => of(null))
      )
  }
}
