export const SEARCH_EVENT_ACTION_KEY = 'searchEventAction'

export enum EventActionEnum {
  suggest = 'suggest',
  suggest2 = 'suggest2',
  no_suggest = 'no_suggest'
}
export const ALL_CITIES_ID = 0
export const REMOTE_RUBRIC_ID = '3'
export const DELETE_RESUME_SURVEY_SCRIPT_URL = 'https://widget.surveymonkey.com/collect/website/js/tRaiETqnLgj758hTBazgd8fhFkvW0zQNGwjNZ9m2wq7wkmwYLcqWPwsZY_2BKdzi8R.js'

export const DELETE_ACC_SURVEY_SCRIPT_URL = 'https://widget.surveymonkey.com/collect/website/js/tRaiETqnLgj758hTBazgdz8i6X7bUSd98jWzo4GmtUPSgrog5foef_2FoFDZNEYNVH.js'
export const thxScrollPositionKey = 'thxRecomScrollPosition'
export const alreadySentRecruitmentOrder = 'alreadySentRecruitmentOrder'
