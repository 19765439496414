import { CookieStorage } from '@alliance/shared/storage'
import { HttpHeaders } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { ApolloLink } from '@apollo/client/core'

@Injectable()
export class ClientIdMiddlewareService {
  public constructor(private cookieStorage: CookieStorage) {}

  public getMiddleware(): ApolloLink {
    return new ApolloLink((operation, forward) => {
      const operationHeaders: unknown = operation.getContext()?.['headers']
      const headers = operationHeaders instanceof HttpHeaders ? operationHeaders : new HttpHeaders()

      let cid: string | undefined

      try {
        cid = (this.cookieStorage.getItem('_ga') || '').split('.').slice(2).join('.')
      } catch (e) {
        cid = ''
      }

      if (cid) {
        operation.setContext({ headers: headers.set('cid', cid) })
      }

      return forward(operation) as ApolloLink
    })
  }
}
