import { CvdbService, ResumeService, ServiceService } from '@alliance/employer/data-access'
import { AuthService } from '@alliance/shared/auth/api'
import { PubsubMessageTypesEnum, TransferService } from '@alliance/shared/utils'
import { Injectable } from '@angular/core'
import { combineLatest, Observable, of } from 'rxjs'
import { catchError, delay, filter, map, take } from 'rxjs/operators'
import { BillingStorePendingModel } from './billing.model'
import { BillingQuery } from './billing.query'
import { BillingStore } from './billing.store'
import { ResumeContactsFilters, ResumeContactsScenariosEnum } from './models/open-contacts'
import PublishTypeEnum = CvdbService.PublishTypeEnum
import TypeEnum = CvdbService.TypeEnum
import ActivateParams = ServiceService.ActivateParams

@Injectable({
  providedIn: 'root'
})
export class BillingService {
  // WARNING: do not use this service for new features - DEPRECATED

  private readonly CV_DB_SERVICE_ID = 4
  private readonly RESUME_CONTACTS_FILTER_DEFAULT: ResumeContactsFilters = {
    cityIds: [0],
    rubricIds: [0]
  }

  public constructor(
    private billingStore: BillingStore,
    private billingQuery: BillingQuery,
    private authService: AuthService,
    private customerService: ServiceService,
    private transferService: TransferService,
    private resumeService: ResumeService
  ) {
    this.authService.token$.pipe(filter(token => !!token && this.authService.isEmployer)).subscribe(() => this.init())

    combineLatest([this.billingQuery.select$('contactFilter').pipe(filter((service): service is ResumeContactsFilters => !!service)), this.billingQuery.select$('contactServices')])
      .pipe(
        delay(1),
        map(([filters, services]) => [
          (services || []).filter(
            service =>
              filters.cityIds.some(cityId => service?.cityIds && service.cityIds.includes(cityId)) && filters.rubricIds.some(rubricId => service?.rubric1Ids && service.rubric1Ids.includes(rubricId))
          ),
          services || []
        ])
      )
      .subscribe(([suitableServices, allServices]) => this.setResumeContactsInfo(suitableServices, allServices))
  }

  public setContactFilter(cities: number[], rubrics: number[]): void {
    this.setPending('currentContactsService', true)
    this.billingStore.contactFilter = {
      cityIds: this.RESUME_CONTACTS_FILTER_DEFAULT.cityIds.concat(cities),
      rubricIds: this.RESUME_CONTACTS_FILTER_DEFAULT.rubricIds.concat(rubrics)
    }
  }

  public init(): void {
    this.syncVacancyServices()
    this.syncContactsServices()
    this.syncResumeServicesOpenContactsCount()
  }

  public setPending(name: keyof BillingStorePendingModel, status: boolean): void {
    this.billingStore.setPending(name, status)
  }

  public syncVacancyServices(): void {
    this.customerService
      .ticketList(PublishTypeEnum.All)
      .pipe(catchError(() => of([])))
      .subscribe(services => (this.billingStore.vacancyServices = services))
  }

  public syncContactsServices(): void {
    this.syncContactsServices$().subscribe()
  }

  public syncContactsServices$(): Observable<boolean> {
    this.transferService.publish(PubsubMessageTypesEnum.syncServicesCounters, null)
    this.setPending('currentContactsService', true)

    return this.customerService.serviceCvdbList().pipe(
      take(1),
      map(services => {
        this.billingStore.contactServices = services
        return !!services
      }),
      catchError(() => of(false))
    )
  }

  public syncResumeServicesOpenContactsCount(): void {
    this.syncResumeServicesOpenContactsCount$().subscribe()
  }

  public activateService(orderId: number, detailId: number): Observable<unknown> {
    const params: ActivateParams = {
      orderId,
      detailId
    }
    this.transferService.publish(PubsubMessageTypesEnum.updateServices, null)
    return this.customerService.activate(params)
  }

  private syncResumeServicesOpenContactsCount$(): Observable<boolean> {
    this.setPending('openContactsCount', true)
    return this.resumeService.openContactsCount$Plain().pipe(
      take(1),
      map(count => {
        this.billingStore.resumeServicesOpenContactsCount = count.availableContacts || 0
        this.setPending('openContactsCount', false)
        return !!count
      }),
      catchError(() => of(false))
    )
  }

  private setResumeContactsInfo(suitableServices: CvdbService[], allServices: CvdbService[]): void {
    let suitableActiveServices = suitableServices
      .filter(item => item.isActivate)
      // test & free access can be in active services list even when all contacts from them are used up - DB bug
      .filter(item => (item.type !== TypeEnum.Test && item.type !== TypeEnum.Free) || (item?.openContactCount || 0) - (item?.usedContactCount || 0) !== 0)
      // 'same-type' services are used according to rule - service with an earlier 'activationEndDate' is used first
      // thence .find in the next lines will find relevant service
      .sort((a, b) => (a.activationEndDate && b.activationEndDate ? new Date(a.activationEndDate).getTime() - new Date(b.activationEndDate).getTime() : -1))

    // if user has payed active & suitable services --> make free access unavailable
    if (suitableActiveServices?.some(service => service.type === TypeEnum.Free) && suitableActiveServices?.length > 1) {
      suitableActiveServices = suitableActiveServices.filter(service => service.type !== TypeEnum.Free)
    }

    const freeAccess = this.getServiceAccessByType(suitableActiveServices, TypeEnum.Free)
    const testAccess = this.getServiceAccessByType(suitableActiveServices, TypeEnum.Test)
    const packageAccess = this.getServiceAccessByType(suitableActiveServices, TypeEnum.Pack)
    const suitableUnlimitedAccess = this.getServiceAccessByType(suitableActiveServices, TypeEnum.Unlimited)

    // test & free access do not affect the daily limit scenario --> if user has payed services - they are unavailable
    const dayLimitReached = packageAccess && (packageAccess.openContactCount || 0) - (packageAccess.usedContactCount || 0) === 0 && !suitableUnlimitedAccess

    const suitableStoredServices = suitableServices.filter(item => !item.isActivate)
    const hasSuitableActiveService = !!suitableActiveServices.length
    const hasSuitableStoredService = !!suitableStoredServices.length
    const hasSuitableStoredUnlimitedService = !!suitableStoredServices.find(service => service.type === TypeEnum.Unlimited)

    const hasUnsuitableActiveCvDbService = !!allServices.filter(el => el.isActivate && el.serviceId === this.CV_DB_SERVICE_ID)?.length

    const _getContactsScenario = (): ResumeContactsScenariosEnum => {
      switch (true) {
        case !hasSuitableActiveService && !hasSuitableStoredService && hasUnsuitableActiveCvDbService:
          return ResumeContactsScenariosEnum.offerWithCvdbServices
        case !hasSuitableActiveService && !hasSuitableStoredService:
          return ResumeContactsScenariosEnum.offer
        case !hasSuitableActiveService && hasSuitableStoredService && hasUnsuitableActiveCvDbService:
          return ResumeContactsScenariosEnum.activationWithCvdbServices
        case !hasSuitableActiveService && hasSuitableStoredService:
          return ResumeContactsScenariosEnum.activation
        case dayLimitReached && hasSuitableStoredUnlimitedService:
          return ResumeContactsScenariosEnum.activationLimited
        case dayLimitReached && !hasSuitableStoredUnlimitedService:
          return ResumeContactsScenariosEnum.offerLimited
        case hasSuitableActiveService:
          return ResumeContactsScenariosEnum.action
        default:
          return ResumeContactsScenariosEnum.offer
      }
    }

    const contactsScenario = _getContactsScenario()

    this.billingStore.cvRelatedData = {
      scenario: contactsScenario,
      // priority of contacts usage: unlimited packages -> limited packages -> freeAccess || testAccess
      // if user used up all daily contacts - free & test access still unavailable
      currentService: suitableUnlimitedAccess || (!dayLimitReached && (packageAccess || freeAccess || testAccess)) || null,
      servicesToActivate: suitableStoredServices.filter(service => !dayLimitReached || service.type === TypeEnum.Unlimited),
      activatedServices: suitableActiveServices
    }

    this.setPending('currentContactsService', false)
  }

  private getServiceAccessByType(suitableServices: CvdbService[], type: TypeEnum): CvdbService | null {
    if (type === TypeEnum.Pack) {
      return suitableServices
        .filter(item => item.type === type)
        .reduce<CvdbService | null>((acc, current) => {
          const currentEndDate = current?.activationEndDate || new Date()
          const accEndDate = acc?.activationEndDate || new Date()
          return !acc ||
            new Date(currentEndDate).getTime() < new Date(accEndDate).getTime() ||
            (new Date(currentEndDate).getTime() === new Date(accEndDate).getTime() && (current?.usedContactCount || 0) > (acc?.usedContactCount || 0))
            ? current
            : acc
        }, null)
    }

    return suitableServices.find(item => item.type === type) || null
  }
}
