import { LocalStorage } from '@alliance/shared/storage'

import { safeJsonParse } from '../safe-json-parse'

type TimeToLive = number | null | 'default'

interface DataItem<T> {
  readonly id: string
  readonly expiry: number | null
  readonly data: T
}

export abstract class AbstractStorageById {
  public constructor(private readonly storageService: LocalStorage) {}

  protected abstract readonly defaultTimeToLive: number

  protected abstract get id(): string | null

  public setItem<T>(key: string, data: T, timeToLive: TimeToLive = 'default'): void {
    const id = this.id

    if (!id) {
      return
    }

    const localStorageData = safeJsonParse<Array<DataItem<T>>>(this.storageService.getItem(key), [])

    const expiry = this.getExpireTime(timeToLive)

    const newDataItem: DataItem<T> = { id, expiry, data }

    const filterIndex = localStorageData.findIndex(item => item.id === this.id)

    if (filterIndex > -1) {
      localStorageData[filterIndex] = newDataItem
    } else {
      localStorageData.push(newDataItem)
    }

    this.storageService.setItem(key, JSON.stringify(localStorageData))
  }

  public getItem<T>(key: string): T | null {
    const localStorageData = safeJsonParse<Array<DataItem<T>>>(this.storageService.getItem(key), [])

    if (!localStorageData.length) {
      return null
    }

    const currentTime = new Date().getTime()
    const relevantData = localStorageData.filter(item => item.expiry === null || item.expiry > currentTime)

    // update localStorage if some filters expired
    if (localStorageData.length !== relevantData.length) {
      this.storageService.setItem(key, JSON.stringify(relevantData))
    }

    if (!relevantData.length) {
      return null
    }

    return localStorageData.find(item => item.id === this.id)?.data ?? null
  }

  private getExpireTime(timeToLive: TimeToLive): number | null {
    if (timeToLive === 'default') {
      return new Date().getTime() + this.defaultTimeToLive
    }

    if (typeof timeToLive === 'number' && timeToLive > 0) {
      return new Date().getTime() + timeToLive
    }

    return null
  }
}
