import { Injectable } from '@angular/core'
import { Observable, catchError, map, of } from 'rxjs'
import { getOperationName } from '@apollo/client/utilities'

import { GetEmployerCommonLimitsDocument, GetEmployerHotServicesDocument } from '@alliance/employer/gql-domain'

import { VacancyMakeHotResultFragment, VacancyMakeHotGQL } from './make-hot.generated'
import { MakeHotParams } from '../../../models/vacancy-actions/make-hot/make-hot-params.interface'

@Injectable()
export class VacancyMakeHotService {
  public constructor(private readonly vacancyMakeHotGQL: VacancyMakeHotGQL) {}

  public makeHot$(params: MakeHotParams): Observable<VacancyMakeHotResultFragment | null> {
    return this.vacancyMakeHotGQL
      .mutate(
        {
          input: { vacancyId: params.vacancyId, weeksNumber: params.weeksNumber }
        },
        {
          refetchQueries: () =>
            [getOperationName(GetEmployerHotServicesDocument), ...(params.isLimitationScenario ? [getOperationName(GetEmployerCommonLimitsDocument)] : [])].filter((name): name is string => !!name)
        }
      )
      .pipe(
        map(({ data }) => data?.vacancyMakeHot?.vacancy ?? null),
        catchError(() => of(null))
      )
  }
}
