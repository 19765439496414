import { log } from '@alliance/shared/logger'
import { CountryCodesEnum, LanguageCodesEnum, LanguageService } from '@alliance/shared/models'
import { Injectable, isDevMode } from '@angular/core'
import { HashMap, TranslateParams, Translation, TranslocoService } from '@ngneat/transloco'
import { distinctUntilChanged, Observable } from 'rxjs'
import { filter } from 'rxjs/operators'
import { transformObjectToPath } from './translations'

@Injectable({
  providedIn: 'root'
})
export class TranslationService extends LanguageService {
  public constructor(private translocoService: TranslocoService) {
    super()
  }

  public translate<T = string>(key: TranslateParams, params: HashMap = {}, lang?: string): T {
    return this.translocoService.translate<T>(key, params, lang)
  }

  public translateMap<T extends object>(map: { [K in keyof T]: T[K] }, lang: LanguageCodesEnum, params: HashMap = {}): T {
    return Object.entries(map).reduce<T>(
      (acc, [key, value]) => ({
        ...acc,
        [key]: typeof value === 'string' ? this.translocoService.translate<T>(value, params, lang) : value && typeof value === 'object' ? this.translateMap(value, lang, params) : value
      }),
      {} as unknown as T
    )
  }

  public addWithPrefix(prefix: string, language: LanguageCodesEnum, source: Translation): void {
    if (isDevMode() && !prefix?.trim()) {
      log.warn({ where: 'shared-translation', category: 'unexpected_value', message: 'SHARED TRANSLATION: prefix should be non empty value', prefix })
    }
    this.add(language, { [prefix]: source })
  }

  public setActiveLang(code: LanguageCodesEnum | string = LanguageCodesEnum.UK): void {
    // coercing to correct code
    code = code.toString() === CountryCodesEnum.UA ? LanguageCodesEnum.UK : code

    if (this.translocoService.isLang(code) && this.getCurrentLang() !== code) {
      this.translocoService.setActiveLang(code)
    }
  }

  public getCurrentLang(): LanguageCodesEnum {
    return this.translocoService.getActiveLang() as LanguageCodesEnum
  }

  public getAvailableLanguages(): LanguageCodesEnum[] {
    const availableLanguages = this.translocoService.getAvailableLangs()
    return [...availableLanguages]
      .map(lang => (typeof lang === 'string' ? lang : lang.label))
      .filter<LanguageCodesEnum>((lang): lang is LanguageCodesEnum => !!Object.values(LanguageCodesEnum).find(item => item === lang))
  }

  public langChanges$(): Observable<LanguageCodesEnum> {
    return this.translocoService.langChanges$.pipe(filter((lang): lang is LanguageCodesEnum => this.translocoService.isLang(lang)))
  }

  /* DEPRECATED, use explicit currentLangIsUkrainian/currentLangIsRussian instead */
  public getCurrentLangFix_DEPRECATED(): string {
    const lang = this.getCurrentLang()
    return this.getLangFix_DEPRECATED(lang)
  }

  public currentLangIsUkrainian(): boolean {
    return this.getCurrentLang() === LanguageCodesEnum.UK
  }

  public currentLangIsRussian(): boolean {
    return this.getCurrentLang() === LanguageCodesEnum.RU
  }

  public getLangPath(): string {
    return this.currentLangIsUkrainian() ? '' : '/ru'
  }

  protected get _activeLanguage(): LanguageCodesEnum {
    return this.getCurrentLang()
  }

  protected get _activeLanguage$(): Observable<LanguageCodesEnum> {
    return this.langChanges$().pipe(distinctUntilChanged())
  }

  private mapToSimplePaths<T extends object>(object: T): string[] {
    return Object.values(object).reduce<string[]>((acc, item) => {
      if (typeof item === 'string') {
        return [...acc, item]
      }

      if (typeof item === 'object' && !!item) {
        return [...acc, ...this.mapToSimplePaths(item)]
      }

      return acc
    }, [])
  }

  private add(language: LanguageCodesEnum, source: Translation): void {
    const translations = this.translocoService.getTranslation(language)

    if (isDevMode() && translations) {
      this.handleTranslationMissing(source, translations, language)
    }

    this.translocoService.setTranslation(source, language, { merge: true })
  }

  private handleTranslationMissing(source: Translation, translations: Translation, language: LanguageCodesEnum): void {
    for (const [key, value] of Object.entries(transformObjectToPath('', source))) {
      if (Object.keys(translations).some(translationKey => translationKey.startsWith(`${key}.`))) {
        log.warn({ where: 'shared-translation', category: 'unexpected_value', message: 'SHARED TRANSLATION: Potential problem with rewriting translations in:', key, language })
      }

      for (const lang of this.getAvailableLanguages()) {
        this.logTranslationErrors(lang, language, value as object, key)
      }
    }
  }

  private logTranslationErrors(lang: LanguageCodesEnum, language: LanguageCodesEnum, value: object, key: string): void {
    if (lang !== language) {
      const existingTranslations = Object.entries(this.translocoService.getTranslation(lang)).reduce<string[]>(
        (acc, [translationKey]) => (translationKey.startsWith(`${key}.`) ? [...acc, translationKey] : acc),
        []
      )

      if (existingTranslations.length) {
        const tryToAddTranslations = this.mapToSimplePaths(value)
        const leftDifference = tryToAddTranslations.filter(item => !existingTranslations.includes(item))
        const rightDifference = existingTranslations.filter(item => !tryToAddTranslations.includes(item))

        if (leftDifference?.length) {
          log.warn({
            where: 'shared-translation',
            category: 'unexpected_value',
            message: 'SHARED TRANSLATION: Potential problem of translation data inconsistency',
            inLang: lang,
            leftDifference: leftDifference.join('\n').trim()
          })
        }

        if (rightDifference?.length) {
          log.warn({
            where: 'shared-translation',
            category: 'unexpected_value',
            message: 'SHARED TRANSLATION: Potential problem of translation data inconsistency',
            inLang: lang,
            rightDifference: rightDifference.join('\n').trim()
          })
        }
      }
    }
  }

  /* DEPRECATED, use explicit currentLangIsUkrainian/currentLangIsRussian instead */
  private getLangFix_DEPRECATED(lang: string): string {
    if (lang === LanguageCodesEnum.UK) {
      return 'ua'
    }
    return lang
  }
}
