import { TransferService } from '@alliance/shared/utils'
import { Component, ChangeDetectionStrategy, AfterViewInit, Inject, ElementRef } from '@angular/core'
import { DOCUMENT } from '@angular/common'
import { animate, state, style, transition, trigger } from '@angular/animations'

import { ModalOverlayRef, SANTA_MODAL_REF } from '../../services/modal'
import { ModalComponent } from '../modal/modal.component'

@Component({
  selector: 'santa-fixed-modal',
  templateUrl: './fixed-modal.component.html',
  styleUrls: ['./fixed-modal.component.tw.css'],
  animations: [
    trigger('openState', [
      state('void, exit', style({ transform: 'translateY(100%)' })),
      state(
        'opened',
        style({
          transform: 'none'
        })
      ),
      transition('* => *', [animate('200ms cubic-bezier(0.86, 0, 0.07, 1)')])
    ]),
    trigger('openStateBottom', [
      state('void, exit', style({ transform: 'translateY(200%)' })),
      state(
        'opened',
        style({
          transform: 'none'
        })
      ),
      transition('* => *', [animate('200ms cubic-bezier(0.86, 0, 0.07, 1)')])
    ])
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FixedModalComponent<T> extends ModalComponent<T> implements AfterViewInit {
  public constructor(@Inject(SANTA_MODAL_REF) _modalOverlayRef: ModalOverlayRef, @Inject(DOCUMENT) public _document: Document, private elemRef: ElementRef<HTMLElement>, transfer: TransferService) {
    super(_modalOverlayRef, transfer)
  }

  public ngAfterViewInit(): void {
    this.elemRef?.nativeElement?.parentElement?.classList.add('santa-w-0')
    this._document?.documentElement?.classList.remove('cdk-global-scrollblock')
  }
}
