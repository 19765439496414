import { Injectable } from '@angular/core'
import { UsersOpenApiSsoService } from '../../infrastructure'

type Service = 'google' | 'facebook' | 'linkedin'

export interface SsoServiceLinkBuilderParams {
  readonly state?: string
  readonly service: Service
}

@Injectable({
  providedIn: 'root'
})
export class SsoServiceLinkBuilderService {
  private socialSsoUrls: Record<Service, string> = {
    facebook: `${this.usersOpenApiSsoService.rootUrl}${UsersOpenApiSsoService.SsoFacebookGetPath}`,
    google: `${this.usersOpenApiSsoService.rootUrl}${UsersOpenApiSsoService.SsoGoogleGetPath}`,
    linkedin: `${this.usersOpenApiSsoService.rootUrl}${UsersOpenApiSsoService.SsoLinkedinLoginGetPath}`
  }

  public constructor(private readonly usersOpenApiSsoService: UsersOpenApiSsoService) {}

  public build({ service, state }: SsoServiceLinkBuilderParams): string | null {
    let serviceUrl: string | null = null

    serviceUrl = this.socialSsoUrls[service]

    if (!serviceUrl) {
      return null
    }

    if (!state) {
      return serviceUrl
    }

    const searchParams = new URLSearchParams({ state })

    return serviceUrl + '?' + searchParams.toString()
  }
}
