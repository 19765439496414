import { CommonModule } from '@angular/common'
import { NgModule } from '@angular/core'
import { IntersectionObserverModule } from '@ng-web-apis/intersection-observer'
import { StickyObserverComponent } from './sticky-observer.component'

@NgModule({
  imports: [CommonModule, IntersectionObserverModule],
  declarations: [StickyObserverComponent],
  exports: [StickyObserverComponent]
})
export class StickyObserverComponentModule {}
