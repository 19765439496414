import { DetectPlatformService } from '@alliance/shared/utils'
import { ChangeDetectionStrategy, Component, Input, OnChanges, SimpleChanges } from '@angular/core'
import { AccountLinkMapService } from '../../account-link-map.service'
import { AccountLinkModel, TargetTypeEnum } from '../../models/AccountLinkModel'
import { AccountQueryParamsModel } from '../../models/account-query-params-model'

@Component({
  selector: 'alliance-shared-link-wrapping',
  templateUrl: './link-wrapping.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LinkWrappingComponent implements OnChanges {
  @Input() public path: AccountLinkModel | undefined
  @Input() public queryParams: AccountQueryParamsModel = {}
  @Input() public state: { [k: string]: unknown } | undefined
  @Input() public target: TargetTypeEnum = '_self'

  public externalLink = ''
  public internalLink = ''

  public constructor(private accountLinkMapService: AccountLinkMapService, private platform: DetectPlatformService) {}

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes['path'] && changes['path'].currentValue) {
      if (!this.path) {
        return
      }

      if (this.platform.isBrowser) {
        this.accountLinkMapService.checkIfInternal(this.path[0]) ? (this.internalLink = this.path.slice(1).join('/')) : (this.externalLink = this.composeExternalLink(this.path))
      } else {
        this.externalLink = this.composeExternalLink(this.path)
      }
    }
  }

  private composeExternalLink(path: AccountLinkModel): string {
    const queryParamsString = Object.entries<string>(this.queryParams)
      .map(([key, value]) => `${key}=${value}`)
      .join('&')
    const composedPath = this.accountLinkMapService.composeLink(path)
    return queryParamsString ? `${composedPath}?${queryParamsString}` : `${composedPath}`
  }
}
