/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { Observable } from 'rxjs'
import { map } from 'rxjs/operators'

import { BaseService } from '../base-service'
import { NotificationDataAccessConfiguration } from '../notification-data-access-configuration'
import { StrictHttpResponse } from '../strict-http-response'

import { chatWizardGetCvCreationFlow$Json } from '../fn/chat-wizard/chat-wizard-get-cv-creation-flow-json'
import { ChatWizardGetCvCreationFlow$Json$Params } from '../fn/chat-wizard/chat-wizard-get-cv-creation-flow-json'
import { chatWizardGetCvCreationFlow$Plain } from '../fn/chat-wizard/chat-wizard-get-cv-creation-flow-plain'
import { ChatWizardGetCvCreationFlow$Plain$Params } from '../fn/chat-wizard/chat-wizard-get-cv-creation-flow-plain'
import { chatWizardStartCvBot$Json } from '../fn/chat-wizard/chat-wizard-start-cv-bot-json'
import { ChatWizardStartCvBot$Json$Params } from '../fn/chat-wizard/chat-wizard-start-cv-bot-json'
import { chatWizardStartCvBot$Plain } from '../fn/chat-wizard/chat-wizard-start-cv-bot-plain'
import { ChatWizardStartCvBot$Plain$Params } from '../fn/chat-wizard/chat-wizard-start-cv-bot-plain'
import { CvCreationFlow } from '../models/cv-creation-flow'
import { StartBotResponse } from '../models/start-bot-response'

@Injectable({ providedIn: 'root' })
export class ChatWizardService extends BaseService {
  constructor(config: NotificationDataAccessConfiguration, http: HttpClient) {
    super(config, http)
  }

  /** Path part for operation `chatWizardGetCvCreationFlow()` */
  static readonly ChatWizardGetCvCreationFlowPath = '/v1/chatWizard/cv/flow'

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `chatWizardGetCvCreationFlow$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  chatWizardGetCvCreationFlow$Plain$Response(params?: ChatWizardGetCvCreationFlow$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<CvCreationFlow>> {
    return chatWizardGetCvCreationFlow$Plain(this.http, this.rootUrl, params, context)
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `chatWizardGetCvCreationFlow$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  chatWizardGetCvCreationFlow$Plain(params?: ChatWizardGetCvCreationFlow$Plain$Params, context?: HttpContext): Observable<CvCreationFlow> {
    return this.chatWizardGetCvCreationFlow$Plain$Response(params, context).pipe(map((r: StrictHttpResponse<CvCreationFlow>): CvCreationFlow => r.body))
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `chatWizardGetCvCreationFlow$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  chatWizardGetCvCreationFlow$Json$Response(params?: ChatWizardGetCvCreationFlow$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<CvCreationFlow>> {
    return chatWizardGetCvCreationFlow$Json(this.http, this.rootUrl, params, context)
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `chatWizardGetCvCreationFlow$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  chatWizardGetCvCreationFlow$Json(params?: ChatWizardGetCvCreationFlow$Json$Params, context?: HttpContext): Observable<CvCreationFlow> {
    return this.chatWizardGetCvCreationFlow$Json$Response(params, context).pipe(map((r: StrictHttpResponse<CvCreationFlow>): CvCreationFlow => r.body))
  }

  /** Path part for operation `chatWizardStartCvBot()` */
  static readonly ChatWizardStartCvBotPath = '/v1/chatWizard/cv'

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `chatWizardStartCvBot$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  chatWizardStartCvBot$Plain$Response(params?: ChatWizardStartCvBot$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<StartBotResponse>> {
    return chatWizardStartCvBot$Plain(this.http, this.rootUrl, params, context)
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `chatWizardStartCvBot$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  chatWizardStartCvBot$Plain(params?: ChatWizardStartCvBot$Plain$Params, context?: HttpContext): Observable<StartBotResponse> {
    return this.chatWizardStartCvBot$Plain$Response(params, context).pipe(map((r: StrictHttpResponse<StartBotResponse>): StartBotResponse => r.body))
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `chatWizardStartCvBot$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  chatWizardStartCvBot$Json$Response(params?: ChatWizardStartCvBot$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<StartBotResponse>> {
    return chatWizardStartCvBot$Json(this.http, this.rootUrl, params, context)
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `chatWizardStartCvBot$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  chatWizardStartCvBot$Json(params?: ChatWizardStartCvBot$Json$Params, context?: HttpContext): Observable<StartBotResponse> {
    return this.chatWizardStartCvBot$Json$Response(params, context).pipe(map((r: StrictHttpResponse<StartBotResponse>): StartBotResponse => r.body))
  }
}
