import { Injectable } from '@angular/core'
import { Observable, of } from 'rxjs'
import { catchError, map, retryWhen } from 'rxjs/operators'

import { getNonNullableItems, retryWhenStrategy } from '@alliance/shared/utils'

import { CityDictionaryItemFragment, GetCitiesByKeywordGQL, GetCitiesDictionaryGQL, GetCityByIdGQL } from './city-dictionary.generated'

@Injectable({
  providedIn: 'root'
})
export class CityDictionaryService {
  public constructor(private citiesDictionaryGQL: GetCitiesDictionaryGQL, private getCitiesByKeywordGQL: GetCitiesByKeywordGQL, private getCityByIdGQL: GetCityByIdGQL) {}

  public getCities$(): Observable<CityDictionaryItemFragment[]> {
    return this.citiesDictionaryGQL.fetch({}, { fetchPolicy: 'cache-first' }).pipe(
      map(({ data: { cities } }) => (cities || []).reduce<CityDictionaryItemFragment[]>((acc, city) => (city ? [...acc, city] : acc), [])),
      retryWhen(retryWhenStrategy()),
      catchError(() => of([]))
    )
  }

  public getCitiesByKeyword$(keyword: string, after: string, first: number): Observable<CityDictionaryItemFragment[]> {
    return this.getCitiesByKeywordGQL.fetch({ after, first, input: { keyword } }, { fetchPolicy: 'cache-first' }).pipe(
      map(({ data }) => getNonNullableItems(data?.citiesConnection?.nodes ?? [])),
      retryWhen(retryWhenStrategy()),
      catchError(() => of([]))
    )
  }

  public getCityById$(cityId: string): Observable<CityDictionaryItemFragment | null> {
    return this.getCityByIdGQL.fetch({ cityId }, { fetchPolicy: 'cache-first' }).pipe(
      map(({ data }) => data?.city ?? null),
      retryWhen(retryWhenStrategy()),
      catchError(() => of(null))
    )
  }
}
