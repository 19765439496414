import { ConversationActionEnum } from './enums'

export enum RenderChatAlertEnum {
  applyMessageError = 'applyMessageError',
  errorLimit = 'errorLimit',
  blackListError = 'blackListError',
  conversationAction = 'conversationAction',
  applyAttachIncorrect = 'applyAttachIncorrect'
}

export interface ChatAlertData {
  type: RenderChatAlertEnum
  action?: ConversationActionEnum
  conversationName?: string
}
