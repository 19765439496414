import { AuthService } from '@alliance/shared/auth/api'
import { Environment, EnvironmentName } from '@alliance/shared/environment'
import { LocalStorage } from '@alliance/shared/storage'
import { DetectPlatformService } from '@alliance/shared/utils'
import { Injectable } from '@angular/core'
import { ApolloLink, Operation } from '@apollo/client/core'
import { onError } from '@apollo/client/link/error'
import { HttpParams } from '@angular/common/http'

@Injectable({
  providedIn: 'root'
})
export class LoggerMiddlewareService {
  private readonly LOCAL_STORAGE_KEY = 'dracula_logger_enabled'

  public constructor(private authService: AuthService, private environment: Environment, private localStorage: LocalStorage, private platform: DetectPlatformService) {
    if (this.platform.isServer) {
      return
    }

    const CONSOLE_STYLE = 'background-color:#F2DBE7;color:black;padding:5px;display:block'

    try {
      if (this.environment.name !== EnvironmentName.prod && !this.localStorage.getItem(this.LOCAL_STORAGE_KEY)) {
        console.log(`%cTo enable GQL logger run this code in the console and refresh the page:\nlocalStorage.setItem("${this.LOCAL_STORAGE_KEY}", 1)`, CONSOLE_STYLE)
      }
    } catch (_e) {
      console.warn('LoggerMiddlewareService: constructor', _e)
    }

    try {
      if (this.localStorage.getItem(this.LOCAL_STORAGE_KEY)) {
        console.log(`%cTo disable GQL logger run this code in the console and refresh the page:\nlocalStorage.removeItem("${this.LOCAL_STORAGE_KEY}")`, CONSOLE_STYLE)
      }
    } catch (_e) {
      console.warn('LoggerMiddlewareService: constructor', _e)
    }
  }

  public onErrorLogger(): ApolloLink {
    return onError(({ operation, graphQLErrors, networkError }) => {
      if (this.platform.isBrowser && this.localStorage.getItem(this.LOCAL_STORAGE_KEY)) {
        const { operationType, operationName, variables, querySource, formattedDate } = this.prepareOperationData(operation)

        console.groupCollapsed(`%cerror %c${operationType} %c${operationName} %c${formattedDate}`, `color: red`, '', 'font-weight:bold', 'color:#777;font-weight:normal')

        if (graphQLErrors) {
          console.groupCollapsed('🍆 gql errors')
          graphQLErrors.map(({ message, locations, path }) => console.log(`Message: ${message}, Location: ${(locations ?? '').toString()}, Path: ${(path ?? '').toString()}`))
          console.groupEnd()
        }

        if (networkError) {
          console.groupCollapsed('🌐 network errors')
          console.log(networkError)
          console.groupEnd()
        }

        this.showBaseInfo(JSON.stringify(variables), querySource)

        console.groupEnd()
      }
    })
  }

  public operationsLogger(): ApolloLink {
    return new ApolloLink((operation, forward) => {
      if (this.localStorage.getItem(this.LOCAL_STORAGE_KEY)) {
        const { operationType, operationName, variables, querySource, formattedDate } = this.prepareOperationData(operation)

        console.groupCollapsed(
          `%c${operationType} %c${operationName} %c${formattedDate}`,
          `color:${operationType === 'query' ? '#68bbe3' : 'orange'}`,
          'font-weight:bold',
          'color:#777;font-weight:normal'
        )

        this.showBaseInfo(JSON.stringify(variables), querySource)
        console.groupEnd()
      }

      return forward(operation) as ApolloLink
    })
  }

  private prepareOperationData(operation: Operation): { operationType: string; variables: Record<string, unknown> | null; formattedDate: string; operationName: string; querySource: string } {
    const operationType = (operation.query.definitions?.[0] as { operation: string })?.operation
    const { operationName, variables = null } = operation

    const _date = new Date()
    const formattedDate = `@ ${_date.getHours()}:${_date.getMinutes()}:${_date.getSeconds()}`

    const authHeaderComment = `# Authorization: "Bearer ${this.authService.token ?? ''}" }\n\n`
    const variablesComment = `# variables: ${JSON.stringify(variables)}\n\n`
    const dividerComment = '# -------------------------------------------------\n\n'

    const querySource = `${authHeaderComment}${variablesComment}${dividerComment}${operation?.query?.loc?.source?.body?.replace(/(\.\.\.)/gi, ' ...') ?? ''}`

    return {
      operationType,
      variables,
      formattedDate,
      operationName,
      querySource
    }
  }

  private showBaseInfo(variables: string, querySource: string): void {
    console.groupCollapsed('🔗 link for preview in playground')

    let params = new HttpParams()
    params = params.appendAll({
      docsPanelState: 'closed',
      showHeadersAndEnvVars: true,
      endpoint: this.environment.graphqlApi ?? '',
      document: querySource,
      variables
    })

    console.log(`https://studio.apollographql.com/sandbox/explorer/?${params.toString()}`)
    console.groupEnd()

    console.groupCollapsed('🔤 variables')
    console.log(variables)
    console.groupEnd()

    console.groupCollapsed('👮 authorization header')
    console.log(`{ "Authorization": "Bearer ${this.authService.token ?? ''}" }`)
    console.groupEnd()

    console.groupCollapsed('💻 source code')
    console.log(querySource)
    console.groupEnd()
  }
}
