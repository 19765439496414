export interface TicketService4Detail {
  serviceTypeId?: TicketService4Detail.ServiceTypeIdEnum
  serviceId?: number
  serviceName?: string
  serviceNameUkr?: string
  count?: number
  ticketRest?: number
  orderDetailId?: number
  orderId?: number
  orderState?: number
  publishType?: TicketService4Detail.PublishTypeEnum
  isPackage?: boolean
  isActivate?: boolean
  openContactCount?: number
  packageCount?: number
  activationEndDate?: Date
}

export namespace TicketService4Detail {
  export type ServiceTypeIdEnum =
    | 'All'
    | 'AnonPiece'
    | 'Article'
    | 'BusinessPack'
    | 'BusinessPiece'
    | 'Channel'
    | 'CompanyProfile'
    | 'CvDb'
    | 'FreeBusiness'
    | 'HotPack'
    | 'HotPiece'
    | 'Interview'
    | 'InterviewPromo'
    | 'LogoMainPage'
    | 'LogoRubric'
    | 'Mailist'
    | 'MasterClass'
    | 'OptimumPack'
    | 'OptimumPiece'
    | 'ProfPack'
    | 'ProfPiece'
  export const ServiceTypeIdEnum = {
    All: 'All' as ServiceTypeIdEnum,
    AnonPiece: 'AnonPiece' as ServiceTypeIdEnum,
    Article: 'Article' as ServiceTypeIdEnum,
    BusinessPack: 'BusinessPack' as ServiceTypeIdEnum,
    BusinessPiece: 'BusinessPiece' as ServiceTypeIdEnum,
    Channel: 'Channel' as ServiceTypeIdEnum,
    CompanyProfile: 'CompanyProfile' as ServiceTypeIdEnum,
    CvDb: 'CvDb' as ServiceTypeIdEnum,
    FreeBusiness: 'FreeBusiness' as ServiceTypeIdEnum,
    HotPack: 'HotPack' as ServiceTypeIdEnum,
    HotPiece: 'HotPiece' as ServiceTypeIdEnum,
    Interview: 'Interview' as ServiceTypeIdEnum,
    InterviewPromo: 'InterviewPromo' as ServiceTypeIdEnum,
    LogoMainPage: 'LogoMainPage' as ServiceTypeIdEnum,
    LogoRubric: 'LogoRubric' as ServiceTypeIdEnum,
    Mailist: 'Mailist' as ServiceTypeIdEnum,
    MasterClass: 'MasterClass' as ServiceTypeIdEnum,
    OptimumPack: 'OptimumPack' as ServiceTypeIdEnum,
    OptimumPiece: 'OptimumPiece' as ServiceTypeIdEnum,
    ProfPack: 'ProfPack' as ServiceTypeIdEnum,
    ProfPiece: 'ProfPiece' as ServiceTypeIdEnum
  }
  export type PublishTypeEnum = 'All' | 'Anonym' | 'Business' | 'Hot' | 'Optimum' | 'Professional' | 'Test'
  export const PublishTypeEnum = {
    All: 'All' as PublishTypeEnum,
    Anonym: 'Anonym' as PublishTypeEnum,
    Business: 'Business' as PublishTypeEnum,
    Hot: 'Hot' as PublishTypeEnum,
    Optimum: 'Optimum' as PublishTypeEnum,
    Professional: 'Professional' as PublishTypeEnum,
    Test: 'Test' as PublishTypeEnum
  }
}
