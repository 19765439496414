import { CvDbSearchRequest, CvDbService } from '@alliance/employer/data-access'

export type CacheableParams = CvDbService.CacheableSearchParams | CvDbService.FacetsV2Params

export const convertCvDbSearchRequestToCacheableParams = (request: CvDbSearchRequest): CacheableParams => ({
  Ukrainian: request.ukrainian,
  Sort: request.sort,
  ShowCvWithoutSalary: request.showCvWithoutSalary,
  Sex: request.sex,
  SearchType: request.searchType,
  SearchContext: request.searchContext,
  ScheduleIds: request.scheduleIds,
  SalaryTo: request.salary?.to,
  SalaryFrom: request.salary?.from,
  Rubrics: request.rubrics,
  Reverse: request.reverse,
  ProfLevelIds: request.profLevelIds,
  Period: request.period,
  Page: request.page,
  OnlyWithCurrentNotebookNotes: request.onlyWithCurrentNotebookNotes,
  OnlyNew: request.onlyNew,
  OnlyMoveability: request.onlyMoveability,
  OnlyFavorite: request.onlyFavorite,
  Moveability: request.moveability,
  LastSort: request.lastSort,
  Languages: request.languages,
  KeyWords: request.keyWords,
  IsSynonym: request.isSynonym,
  Inside: request.inside,
  OnlyDisliked: request.onlyDisliked,
  HasPhoto: request.hasPhoto,
  ExperienceIds: request.experienceIds,
  EducationIds: request.educationIds,
  CvLanguage: request.cvLanguage,
  Count: request.count,
  CityId: request.cityId,
  BranchIds: request.branchIds,
  AgeTo: request.age?.to,
  AgeFrom: request.age?.from,
  ResumeFillingTypeIds: request.resumeFillingTypeIds,
  DistrictIds: request.districtIds,
  OnlyStudents: request.onlyStudents
})
