import { Injectable } from '@angular/core'

/**
 * Global configuration for JobseekerDataAccess services
 */
@Injectable({
  providedIn: 'root'
})
export class JobseekerDataAccessConfiguration {
  rootUrl = 'https://api.robota.ua'
}

export interface JobseekerDataAccessConfigurationInterface {
  rootUrl?: string
}
