import { Injectable } from '@angular/core'

import { combineLatest, Observable, of, zip } from 'rxjs'
import { map, switchMap } from 'rxjs/operators'
import { BreadcrumbList } from 'schema-dts'

import { DictionaryService } from '@alliance/jobseeker/api'
import { Environment } from '@alliance/shared/environment'
import { prettyNumberTransform } from '@alliance/shared/pipes/pretty-number'
import { TranslationService } from '@alliance/shared/translation'
import { safeJsonParse } from '@alliance/shared/utils'

import { SeoCandidatesModel } from '../../openapi/model/candidates.model'
import { CandidatesSeoParams, PartialSeoParamsResponse, PlatformHosts } from '../models'
import { Translations } from '../localization/translations'
import { HelpersService } from '../helpers.service'

@Injectable({
  providedIn: 'root'
})
export class CandidatesDictionaryService {
  private readonly mainSiteUrl = (this.environment.mainSiteUrl ?? '').replace(/\/$/, '')
  public constructor(
    private translations: Translations,
    private translationService: TranslationService,
    private dictionaryService: DictionaryService,
    private helpersService: HelpersService,
    private environment: Environment
  ) {}

  public getParams({ params: inputParams }: CandidatesSeoParams): Observable<PartialSeoParamsResponse> {
    inputParams.keyWords = inputParams.keyWords?.trim().toLowerCase()

    return combineLatest([this.dictionaryService.getProfessionsList$(), this.getJsonLd$(PlatformHosts.desktop, inputParams)]).pipe(
      switchMap(([allowedKeywordsList, jsonLd]) => {
        const keywordIsNotAllowedForIndexing = !!inputParams.keyWords && !allowedKeywordsList.map(keyword => keyword.name.trim().toLowerCase()).includes(inputParams.keyWords?.trim()?.toLowerCase())
        const candidatesListIsEmpty = !inputParams.total
        const candidatesTotal: string = (prettyNumberTransform(inputParams.total ?? 0, 'int') as string) ?? '0'
        const defaultSeoParams: PartialSeoParamsResponse = {
          title: this.translationService.translate(this.translations.candidates.default.title),
          description: this.translationService.translate(this.translations.candidates.default.description, {
            total: candidatesTotal
          }),
          noIndexNoFollow: true,
          jsonLd: {
            desktop: jsonLd,
            mobile: jsonLd
          }
        }
        switch (true) {
          // disable indexing by bots under the following conditions:
          case keywordIsNotAllowedForIndexing || candidatesListIsEmpty || inputParams.isNarrowedSearch:
            return of({ ...defaultSeoParams })
          // all ukraine
          case !inputParams.keyWords && !inputParams.cityId:
            return this.getAllUkraineSeoParams$(candidatesTotal).pipe(switchMap((responseParams: PartialSeoParamsResponse) => this.addCommonParams$(inputParams, responseParams)))
          // keyword and city
          case !!inputParams.keyWords && !!inputParams.cityId:
            return this.getKeywordAndCitySeoParams$(inputParams, candidatesTotal).pipe(switchMap((responseParams: PartialSeoParamsResponse) => this.addCommonParams$(inputParams, responseParams)))
          // only city
          case !!inputParams.cityId:
            return this.getCitySeoParams$(inputParams, candidatesTotal).pipe(switchMap((responseParams: PartialSeoParamsResponse) => this.addCommonParams$(inputParams, responseParams)))
          // only keyword
          case !!inputParams.keyWords:
            return this.getKeywordSeoParams$(inputParams, candidatesTotal).pipe(switchMap((responseParams: PartialSeoParamsResponse) => this.addCommonParams$(inputParams, responseParams)))
          default:
            return of({ ...defaultSeoParams })
        }
      })
    )
  }

  private getAllUkraineSeoParams$(candidatesTotal: string): Observable<PartialSeoParamsResponse> {
    return of({
      title: this.translationService.translate(this.translations.candidates.default.title),
      description: this.translationService.translate(this.translations.candidates.default.description, {
        total: candidatesTotal
      }),
      h1: [this.translationService.translate(this.translations.candidates.default.h1)]
    })
  }

  private getCitySeoParams$(inputParams: SeoCandidatesModel, candidatesTotal: string): Observable<PartialSeoParamsResponse> {
    return this.dictionaryService.getCityName$(inputParams.cityId, null, true).pipe(
      map(
        (cityName): PartialSeoParamsResponse => ({
          title: this.translationService.translate(this.translations.candidates.city.title, {
            cityInflected: cityName
          }),
          description: this.translationService.translate(this.translations.candidates.city.description, {
            cityInflected: cityName,
            total: candidatesTotal
          }),
          h1: [
            this.translationService.translate(this.translations.candidates.city.h1, {
              cityInflected: cityName
            })
          ]
        })
      )
    )
  }

  private getKeywordSeoParams$(inputParams: SeoCandidatesModel, candidatesTotal: string): Observable<PartialSeoParamsResponse> {
    const keyword = ` ${inputParams.keyWords}`

    return of({
      title: this.translationService.translate(this.translations.candidates.keyword.title, { keyword }),
      description: this.translationService.translate(this.translations.candidates.keyword.description, {
        keyword,
        total: candidatesTotal
      }),
      h1: [this.translationService.translate(this.translations.candidates.keyword.h1, { keyword })]
    })
  }

  private getKeywordAndCitySeoParams$(inputParams: SeoCandidatesModel, candidatesTotal: string): Observable<PartialSeoParamsResponse> {
    return this.dictionaryService.getCityName$(inputParams.cityId, null, true).pipe(
      map(
        (cityName): PartialSeoParamsResponse => ({
          title: this.translationService.translate(this.translations.candidates.keywordAndCity.title, {
            keyword: inputParams.keyWords,
            cityInflected: cityName
          }),
          description: this.translationService.translate(this.translations.candidates.keywordAndCity.description, {
            cityInflected: cityName,
            keyword: inputParams.keyWords,
            total: candidatesTotal
          }),
          h1: [
            this.translationService.translate(this.translations.candidates.keywordAndCity.h1, {
              cityInflected: cityName,
              keyword: inputParams.keyWords
            })
          ]
        })
      )
    )
  }

  private addCommonParams$(inputParams: SeoCandidatesModel, responseParams: PartialSeoParamsResponse): Observable<PartialSeoParamsResponse> {
    return zip(
      this.helpersService.createDesktopCandidatesListUrl$(inputParams.cityId, inputParams.keyWords, inputParams.page),
      this.helpersService.createDesktopCandidatesListUrl$(inputParams.cityId, inputParams.keyWords, inputParams.page),
      this.helpersService.createCandidatesListPathName$(inputParams.cityId, inputParams.keyWords, inputParams.page),
      this.getJsonLd$(PlatformHosts.desktop, inputParams)
    ).pipe(
      map(([canonicalUrl, alternateUrl, pathName, jsonLd]) => ({
        ...responseParams,
        canonicalUrl,
        alternateUrl,
        title: this.prependPageNumberText(inputParams.page, responseParams.title ?? ''),
        description: this.prependPageNumberText(inputParams.page, responseParams.description ?? ''),
        hrefLang: this.helpersService.createHrefLangURLs(pathName, pathName),
        jsonLd: {
          desktop: jsonLd,
          mobile: jsonLd
        }
      }))
    )
  }

  private getJsonLd$(platform: PlatformHosts, { cityId }: SeoCandidatesModel): Observable<string> {
    const defaultJsonLd = this.helpersService.createJsonLd<BreadcrumbList>(platform, {
      '@context': 'https://schema.org',
      '@type': 'BreadcrumbList',
      itemListElement: [
        this.helpersService.getHomePageBreadcrumb(platform),
        this.helpersService.getCandidatesBreadcrumb(platform),
        {
          '@type': 'ListItem',
          position: 3,
          name: this.translationService.translate(this.translations.jsonLd.breadcrumbs.allUkraine),
          item: this.helpersService.getLanguageUrl(`${this.mainSiteUrl}/candidates/all`)
        }
      ]
    })

    // City Json LD
    if (cityId > 0) {
      return combineLatest([this.dictionaryService.getCityList$(), this.helpersService.createDesktopCandidatesListUrl$(cityId, '')]).pipe(
        map(([cityList, path]) => {
          const cityItem = cityList.find(city => city.id === (cityId || 0))

          return this.helpersService.createJsonLd<BreadcrumbList>(platform, {
            '@context': 'https://schema.org',
            '@type': 'BreadcrumbList',
            itemListElement: [
              this.helpersService.getHomePageBreadcrumb(platform),
              this.helpersService.getCandidatesBreadcrumb(platform),
              {
                '@type': 'ListItem',
                position: 3,
                name: this.translationService.currentLangIsUkrainian() ? cityItem?.ua || '' : cityItem?.ru || '',
                item: path
              }
            ]
          })
        })
      )
    }

    // return default JsonLd
    return of(this.helpersService.createJsonLd(platform, safeJsonParse(defaultJsonLd, {}, 'candidates-dictionary.service - getJsonLd$')))
  }

  private prependPageNumberText(page: number, text: string): string {
    return page > 1 ? `${text} ${this.translationService.translate(this.translations.candidates.default.pageLeadingText, { page })}` : text
  }
}
