import { Injectable } from '@angular/core'
import { Observable, of } from 'rxjs'
import { catchError, map, retry } from 'rxjs/operators'
import { QuestionnaireGiveAnswersInput } from '@alliance/shared/domain-gql'
import { ApplyVacancyQuestionnaireFragment, GetApplyVacancyQuestionnaireInfoGQL, GiveAnswersToVacancyQuestionnaireGQL } from './vacancy-questionnaire.generated'

@Injectable({ providedIn: 'root' })
export class VacancyQuestionnaireService {
  public constructor(private getApplyVacancyQuestionnaireInfoGQL: GetApplyVacancyQuestionnaireInfoGQL, private giveAnswersToVacancyQuestionnaireGQL: GiveAnswersToVacancyQuestionnaireGQL) {}

  // WARNING: applyId must be in format: `123456-suffix`, where:
  // 123456 - applyId returned by rest api
  // suffix - 'prof' | 'attach' | 'empty'
  // cause applyId in "rest" & "graph" apis are incompatible
  public giveAnswersToVacancyQuestionnaire(giveAnswersInput: QuestionnaireGiveAnswersInput): Observable<boolean> {
    return this.giveAnswersToVacancyQuestionnaireGQL.mutate({ input: giveAnswersInput }).pipe(
      map(({ data }) => !!data?.questionnaireGiveAnswers?.isSuccess),
      retry(2),
      catchError(() => of(false))
    )
  }

  public getApplyVacancyQuestionnaire$(vacancyId: string): Observable<ApplyVacancyQuestionnaireFragment | null> {
    return this.getApplyVacancyQuestionnaireInfoGQL.fetch({ id: vacancyId }, { fetchPolicy: 'network-only' }).pipe(
      map(({ data }) => (data?.vacancy?.candidatesScreening?.isEnabled ? data?.vacancy?.candidatesScreening.questionnaire : null)),
      catchError(() => of(null))
    )
  }
}
