const BORDER_RADIUS = {
  none: '0',
  full: '9999px',
  1: '1px',
  5: '5px',
  10: '10px',
  20: '20px'
}

module.exports = { BORDER_RADIUS }
