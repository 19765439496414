import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { Observable } from 'rxjs'
import { Environment } from '@alliance/shared/environment'
import { TranslationService } from '@alliance/shared/translation'

@Injectable()
export class AcceptLanguageInterceptorService implements HttpInterceptor {
  public constructor(private translationService: TranslationService, private env: Environment) {}

  public intercept(req: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    if (req.url.includes(this.env.chatApi ?? '')) {
      req = req.clone({ headers: req.headers.set('accept-language', this.translationService.getCurrentLang()) })
    }
    return next.handle(req)
  }
}
