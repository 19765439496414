import { Component, ChangeDetectionStrategy, Inject } from '@angular/core'
import { HelpersService } from '@alliance/shared/utils'
import { RxStateComponent } from '@alliance/shared/models'
import { WINDOW } from '@ng-web-apis/common'
import { ModalOverlayRef, SANTA_MODAL_REF } from '@alliance/shared/santa'
import { Translations } from '../localization/translations'
import { TranslationService } from '@alliance/shared/translation'
import { IconChatMessageBubble20, IconRemoveCross, IconName, IconPhoneMobileNumber, IconPinMapsNavigation20 } from '@alliance/shared/icons'

export type PlatformType = 'ios' | 'android'

@Component({
  selector: 'alliance-app-promo-banner',
  templateUrl: './app-promo-banner.component.html',
  styleUrls: ['./app-promo-banner.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AppPromoBannerComponent extends RxStateComponent<{
  platform: PlatformType
}> {
  public state = this.connectViewState(['platform'])
  public closeIcon = IconRemoveCross
  public storeLink = {
    ios: 'https://itunes.apple.com/app/apple-store/id1273713363?pt=118374016&ct=webbanner&mt=8',
    android: 'https://play.google.com/store/apps/details?id=ua.rabota.app&referrer=utm_source%3Dwebbanner%26utm_medium%3Dmobileweb'
  }

  public tipsList: Array<{ text: string; icon: IconName }> = [
    {
      text: this.translateService.translate(this.translations.dontMiss),
      icon: IconChatMessageBubble20.name
    },
    {
      text: this.translateService.translate(this.translations.find),
      icon: IconPinMapsNavigation20.name
    },
    {
      text: this.translateService.translate(this.translations.pocket),
      icon: IconPhoneMobileNumber.name
    }
  ]

  public constructor(
    private readonly helpers: HelpersService,
    private translateService: TranslationService,
    @Inject(WINDOW) private readonly windowRef: Window,
    @Inject(SANTA_MODAL_REF) public modalOverlayRef: ModalOverlayRef,
    public translations: Translations
  ) {
    super()
    this.initState({
      platform: this.helpers.isIos() ? 'ios' : 'android'
    })
  }

  public goToStore(): void {
    const link = this.get('platform') === 'ios' ? this.storeLink.ios : this.storeLink.android
    this.windowRef.open(link, '_blank')
    this.closeDialog()
  }

  public closeDialog(): void {
    this.modalOverlayRef.close()
  }

  public trackByFn(index: number): number {
    return index
  }
}
