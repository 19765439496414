type LanguageCodesUnion = 'uk' | 'ru'
type CasesOfMonthsUnion = 'nominative' | 'genitive'
type MonthNameTranslationKeyUnion = 'January' | 'February' | 'March' | 'April' | 'May' | 'June' | 'July' | 'August' | 'September' | 'October' | 'November' | 'December'
type MonthNameTranslationType = Record<LanguageCodesUnion, Record<CasesOfMonthsUnion, string>>
type MonthsType = Record<MonthNameTranslationKeyUnion, MonthNameTranslationType>

const MONTHS: MonthsType = {
  January: { uk: { nominative: 'Січень', genitive: 'Січня' }, ru: { nominative: 'Январь', genitive: 'Января' } },
  February: { uk: { nominative: 'Лютий', genitive: 'Лютого' }, ru: { nominative: 'Февраль', genitive: 'Февраля' } },
  March: { uk: { nominative: 'Березень', genitive: 'Березня' }, ru: { nominative: 'Март', genitive: 'Марта' } },
  April: { uk: { nominative: 'Квітень', genitive: 'Квітня' }, ru: { nominative: 'Апрель', genitive: 'Апреля' } },
  May: { uk: { nominative: 'Травень', genitive: 'Травня' }, ru: { nominative: 'Май', genitive: 'Мая' } },
  June: { uk: { nominative: 'Червень', genitive: 'Червня' }, ru: { nominative: 'Июнь', genitive: 'Июня' } },
  July: { uk: { nominative: 'Липень', genitive: 'Липня' }, ru: { nominative: 'Июль', genitive: 'Июля' } },
  August: { uk: { nominative: 'Серпень', genitive: 'Серпня' }, ru: { nominative: 'Август', genitive: 'Августа' } },
  September: { uk: { nominative: 'Вересень', genitive: 'Вересня' }, ru: { nominative: 'Сентябрь', genitive: 'Сентября' } },
  October: { uk: { nominative: 'Жовтень', genitive: 'Жовтня' }, ru: { nominative: 'Октябрь', genitive: 'Октября' } },
  November: { uk: { nominative: 'Листопад', genitive: 'Листопада' }, ru: { nominative: 'Ноябрь', genitive: 'Ноября' } },
  December: { uk: { nominative: 'Грудень', genitive: 'Грудня' }, ru: { nominative: 'Декабрь', genitive: 'Декабря' } }
}

const getMonthNameTranslationKey = (monthIndex: number): MonthNameTranslationKeyUnion => {
  switch (monthIndex) {
    case 0:
      return 'January'
    case 1:
      return 'February'
    case 2:
      return 'March'
    case 3:
      return 'April'
    case 4:
      return 'May'
    case 5:
      return 'June'
    case 6:
      return 'July'
    case 7:
      return 'August'
    case 8:
      return 'September'
    case 9:
      return 'October'
    case 10:
      return 'November'
    case 11:
      return 'December'
    default:
      throw new RangeError('Unexpected month index in getMonthNameTranslationKey')
  }
}

/**
 * Returns the translation of month.
 * @param date - The date object
 * @returns Translation of month
 */
export const getMonthNameTranslation = (date = new Date()): MonthNameTranslationType => MONTHS[getMonthNameTranslationKey(date.getMonth())]
