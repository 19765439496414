import { LANGUAGE, LanguageService } from '@alliance/shared/models'
import { APP_INITIALIZER, FactoryProvider } from '@angular/core'
import { setDefaultOptions } from 'date-fns'
import { LOCALE_BY_LANGUAGE_CODE_MAP } from './locale-by-language-code-map'

export const setDefaultOptionsProvider = (): FactoryProvider => ({
  provide: APP_INITIALIZER,
  useFactory: (languageService: LanguageService) => (): void => {
    languageService.activeLanguage$.subscribe(language => {
      const locale = LOCALE_BY_LANGUAGE_CODE_MAP[language]
      setDefaultOptions({ locale })
    })
  },
  deps: [LANGUAGE],
  multi: true
})
