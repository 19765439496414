/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http'
import { Observable } from 'rxjs'
import { filter, map } from 'rxjs/operators'
import { StrictHttpResponse } from '../../strict-http-response'
import { RequestBuilder } from '../../request-builder'

import { StatusViewModel } from '../../models/status-view-model'

export interface StatusesGet$Json$Params {
  conversationId: string
}

export function statusesGet$Json(http: HttpClient, rootUrl: string, params: StatusesGet$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<StatusViewModel>> {
  const rb = new RequestBuilder(rootUrl, statusesGet$Json.PATH, 'get')
  if (params) {
    rb.path('conversationId', params.conversationId, {})
  }

  return http.request(rb.build({ responseType: 'json', accept: 'text/json', context })).pipe(
    filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
    map((r: HttpResponse<any>) => {
      return r as StrictHttpResponse<StatusViewModel>
    })
  )
}

statusesGet$Json.PATH = '/v1/conversations/{conversationId}/statuses'
