/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core'
import { HttpClient, HttpResponse, HttpContext } from '@angular/common/http'
import { BaseService } from '../base-service'
import { PromoDataAccessConfigurationInterface } from '../promo-data-access-configuration-interface'
import { StrictHttpResponse } from '../strict-http-response'
import { RequestBuilder } from '../request-builder'
import { Observable } from 'rxjs'
import { map, filter } from 'rxjs/operators'

import { CreatePromoCodeResponse } from '../models/create-promo-code-response'
import { PromoCodeServiceTypeEnum } from '../models/promo-code-service-type-enum'

@Injectable({
  providedIn: 'root'
})
export class PrivatePromoCodeService extends BaseService {
  constructor(config: PromoDataAccessConfigurationInterface, http: HttpClient) {
    super(config, http)
  }

  /**
   * Path part for operation privateForSpecialServiceIdPost
   */
  static readonly PrivateForSpecialServiceIdPostPath = '/private/for-special-serviceId'

  /**
   * По айди компании.
   *
   * Создание промокода на скидку для опеределенного сервиса ( ПО АЙДИ ) и
   * начисления его на баланс нужной компании
   * - Обязательные поля имеют пометку *required
   * - Сервис должен быть доступным в каталоге
   * - Скидка не должна быть более 33 процентов
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `privateForSpecialServiceIdPost$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  privateForSpecialServiceIdPost$Plain$Response(
    params: {
      companyId?: number

      /**
       * Размер скидки в процентах
       */
      DiscountSizePercent: number

      /**
       * Айди сервиса
       */
      ServiceId: number

      /**
       * Количество дней от сегодня, когда промокод будет активным
       */
      DaysActive: number

      /**
       * Количество зарядов использования промокода
       */
      ChargesCount: number

      /**
       * Код промокода, можно не указывать, тогда АПИ само создаст его
       */
      Code?: string
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<CreatePromoCodeResponse>> {
    const rb = new RequestBuilder(this.rootUrl, PrivatePromoCodeService.PrivateForSpecialServiceIdPostPath, 'post')
    if (params) {
      rb.query('companyId', params.companyId, {})
      rb.query('DiscountSizePercent', params.DiscountSizePercent, {})
      rb.query('ServiceId', params.ServiceId, {})
      rb.query('DaysActive', params.DaysActive, {})
      rb.query('ChargesCount', params.ChargesCount, {})
      rb.query('Code', params.Code, {})
    }

    return this.http
      .request(
        rb.build({
          responseType: 'text',
          accept: 'text/plain',
          context: context
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<CreatePromoCodeResponse>
        })
      )
  }

  /**
   * По айди компании.
   *
   * Создание промокода на скидку для опеределенного сервиса ( ПО АЙДИ ) и
   * начисления его на баланс нужной компании
   * - Обязательные поля имеют пометку *required
   * - Сервис должен быть доступным в каталоге
   * - Скидка не должна быть более 33 процентов
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `privateForSpecialServiceIdPost$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  privateForSpecialServiceIdPost$Plain(
    params: {
      companyId?: number

      /**
       * Размер скидки в процентах
       */
      DiscountSizePercent: number

      /**
       * Айди сервиса
       */
      ServiceId: number

      /**
       * Количество дней от сегодня, когда промокод будет активным
       */
      DaysActive: number

      /**
       * Количество зарядов использования промокода
       */
      ChargesCount: number

      /**
       * Код промокода, можно не указывать, тогда АПИ само создаст его
       */
      Code?: string
    },
    context?: HttpContext
  ): Observable<CreatePromoCodeResponse> {
    return this.privateForSpecialServiceIdPost$Plain$Response(params, context).pipe(map((r: StrictHttpResponse<CreatePromoCodeResponse>) => r.body as CreatePromoCodeResponse))
  }

  /**
   * По айди компании.
   *
   * Создание промокода на скидку для опеределенного сервиса ( ПО АЙДИ ) и
   * начисления его на баланс нужной компании
   * - Обязательные поля имеют пометку *required
   * - Сервис должен быть доступным в каталоге
   * - Скидка не должна быть более 33 процентов
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `privateForSpecialServiceIdPost$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  privateForSpecialServiceIdPost$Json$Response(
    params: {
      companyId?: number

      /**
       * Размер скидки в процентах
       */
      DiscountSizePercent: number

      /**
       * Айди сервиса
       */
      ServiceId: number

      /**
       * Количество дней от сегодня, когда промокод будет активным
       */
      DaysActive: number

      /**
       * Количество зарядов использования промокода
       */
      ChargesCount: number

      /**
       * Код промокода, можно не указывать, тогда АПИ само создаст его
       */
      Code?: string
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<CreatePromoCodeResponse>> {
    const rb = new RequestBuilder(this.rootUrl, PrivatePromoCodeService.PrivateForSpecialServiceIdPostPath, 'post')
    if (params) {
      rb.query('companyId', params.companyId, {})
      rb.query('DiscountSizePercent', params.DiscountSizePercent, {})
      rb.query('ServiceId', params.ServiceId, {})
      rb.query('DaysActive', params.DaysActive, {})
      rb.query('ChargesCount', params.ChargesCount, {})
      rb.query('Code', params.Code, {})
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'text/json',
          context: context
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<CreatePromoCodeResponse>
        })
      )
  }

  /**
   * По айди компании.
   *
   * Создание промокода на скидку для опеределенного сервиса ( ПО АЙДИ ) и
   * начисления его на баланс нужной компании
   * - Обязательные поля имеют пометку *required
   * - Сервис должен быть доступным в каталоге
   * - Скидка не должна быть более 33 процентов
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `privateForSpecialServiceIdPost$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  privateForSpecialServiceIdPost$Json(
    params: {
      companyId?: number

      /**
       * Размер скидки в процентах
       */
      DiscountSizePercent: number

      /**
       * Айди сервиса
       */
      ServiceId: number

      /**
       * Количество дней от сегодня, когда промокод будет активным
       */
      DaysActive: number

      /**
       * Количество зарядов использования промокода
       */
      ChargesCount: number

      /**
       * Код промокода, можно не указывать, тогда АПИ само создаст его
       */
      Code?: string
    },
    context?: HttpContext
  ): Observable<CreatePromoCodeResponse> {
    return this.privateForSpecialServiceIdPost$Json$Response(params, context).pipe(map((r: StrictHttpResponse<CreatePromoCodeResponse>) => r.body as CreatePromoCodeResponse))
  }

  /**
   * Path part for operation privateForSpecialServiceIdFilePost
   */
  static readonly PrivateForSpecialServiceIdFilePostPath = '/private/for-special-serviceId-file'

  /**
   * По айдишкам из файла.
   *
   * Создание промокода на скидку для опеределенного сервиса ( ПО АЙДИ ) и
   * начисления его на баланс переданных в файле компаний
   * Формат файла - CSV с перечислением через ','
   * - Обязательные поля имеют пометку *required
   * - Сервис должен быть доступным в каталоге
   * - Скидка не должна быть более 33 процентов
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `privateForSpecialServiceIdFilePost$Plain()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  privateForSpecialServiceIdFilePost$Plain$Response(
    params: {
      /**
       * Размер скидки в процентах
       */
      DiscountSizePercent: number

      /**
       * Айди сервиса
       */
      ServiceId: number

      /**
       * Количество дней от сегодня, когда промокод будет активным
       */
      DaysActive: number

      /**
       * Количество зарядов использования промокода
       */
      ChargesCount: number

      /**
       * Код промокода, можно не указывать, тогда АПИ само создаст его
       */
      Code?: string
      body?: {
        file?: Blob
      }
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<CreatePromoCodeResponse>> {
    const rb = new RequestBuilder(this.rootUrl, PrivatePromoCodeService.PrivateForSpecialServiceIdFilePostPath, 'post')
    if (params) {
      rb.query('DiscountSizePercent', params.DiscountSizePercent, {})
      rb.query('ServiceId', params.ServiceId, {})
      rb.query('DaysActive', params.DaysActive, {})
      rb.query('ChargesCount', params.ChargesCount, {})
      rb.query('Code', params.Code, {})
      rb.body(params.body, 'multipart/form-data')
    }

    return this.http
      .request(
        rb.build({
          responseType: 'text',
          accept: 'text/plain',
          context: context
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<CreatePromoCodeResponse>
        })
      )
  }

  /**
   * По айдишкам из файла.
   *
   * Создание промокода на скидку для опеределенного сервиса ( ПО АЙДИ ) и
   * начисления его на баланс переданных в файле компаний
   * Формат файла - CSV с перечислением через ','
   * - Обязательные поля имеют пометку *required
   * - Сервис должен быть доступным в каталоге
   * - Скидка не должна быть более 33 процентов
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `privateForSpecialServiceIdFilePost$Plain$Response()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  privateForSpecialServiceIdFilePost$Plain(
    params: {
      /**
       * Размер скидки в процентах
       */
      DiscountSizePercent: number

      /**
       * Айди сервиса
       */
      ServiceId: number

      /**
       * Количество дней от сегодня, когда промокод будет активным
       */
      DaysActive: number

      /**
       * Количество зарядов использования промокода
       */
      ChargesCount: number

      /**
       * Код промокода, можно не указывать, тогда АПИ само создаст его
       */
      Code?: string
      body?: {
        file?: Blob
      }
    },
    context?: HttpContext
  ): Observable<CreatePromoCodeResponse> {
    return this.privateForSpecialServiceIdFilePost$Plain$Response(params, context).pipe(map((r: StrictHttpResponse<CreatePromoCodeResponse>) => r.body as CreatePromoCodeResponse))
  }

  /**
   * По айдишкам из файла.
   *
   * Создание промокода на скидку для опеределенного сервиса ( ПО АЙДИ ) и
   * начисления его на баланс переданных в файле компаний
   * Формат файла - CSV с перечислением через ','
   * - Обязательные поля имеют пометку *required
   * - Сервис должен быть доступным в каталоге
   * - Скидка не должна быть более 33 процентов
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `privateForSpecialServiceIdFilePost$Json()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  privateForSpecialServiceIdFilePost$Json$Response(
    params: {
      /**
       * Размер скидки в процентах
       */
      DiscountSizePercent: number

      /**
       * Айди сервиса
       */
      ServiceId: number

      /**
       * Количество дней от сегодня, когда промокод будет активным
       */
      DaysActive: number

      /**
       * Количество зарядов использования промокода
       */
      ChargesCount: number

      /**
       * Код промокода, можно не указывать, тогда АПИ само создаст его
       */
      Code?: string
      body?: {
        file?: Blob
      }
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<CreatePromoCodeResponse>> {
    const rb = new RequestBuilder(this.rootUrl, PrivatePromoCodeService.PrivateForSpecialServiceIdFilePostPath, 'post')
    if (params) {
      rb.query('DiscountSizePercent', params.DiscountSizePercent, {})
      rb.query('ServiceId', params.ServiceId, {})
      rb.query('DaysActive', params.DaysActive, {})
      rb.query('ChargesCount', params.ChargesCount, {})
      rb.query('Code', params.Code, {})
      rb.body(params.body, 'multipart/form-data')
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'text/json',
          context: context
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<CreatePromoCodeResponse>
        })
      )
  }

  /**
   * По айдишкам из файла.
   *
   * Создание промокода на скидку для опеределенного сервиса ( ПО АЙДИ ) и
   * начисления его на баланс переданных в файле компаний
   * Формат файла - CSV с перечислением через ','
   * - Обязательные поля имеют пометку *required
   * - Сервис должен быть доступным в каталоге
   * - Скидка не должна быть более 33 процентов
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `privateForSpecialServiceIdFilePost$Json$Response()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  privateForSpecialServiceIdFilePost$Json(
    params: {
      /**
       * Размер скидки в процентах
       */
      DiscountSizePercent: number

      /**
       * Айди сервиса
       */
      ServiceId: number

      /**
       * Количество дней от сегодня, когда промокод будет активным
       */
      DaysActive: number

      /**
       * Количество зарядов использования промокода
       */
      ChargesCount: number

      /**
       * Код промокода, можно не указывать, тогда АПИ само создаст его
       */
      Code?: string
      body?: {
        file?: Blob
      }
    },
    context?: HttpContext
  ): Observable<CreatePromoCodeResponse> {
    return this.privateForSpecialServiceIdFilePost$Json$Response(params, context).pipe(map((r: StrictHttpResponse<CreatePromoCodeResponse>) => r.body as CreatePromoCodeResponse))
  }

  /**
   * Path part for operation privateForSpecialServiceTypePost
   */
  static readonly PrivateForSpecialServiceTypePostPath = '/private/for-special-service-type'

  /**
   * По айди компании.
   *
   * Создание промокода на скидку для опеределенного сервис ТИПА ( Оптимум, Бизнес и тд ) и
   * начисления его на баланс нужной компании
   * - Обязательные поля имеют пометку *required
   * - Сервис должен быть доступным в каталоге
   * - Скидка не должна быть более 33 процентов
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `privateForSpecialServiceTypePost$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  privateForSpecialServiceTypePost$Plain$Response(
    params: {
      companyId?: number

      /**
       * Размер скидки в процентах
       */
      DiscountSizePercent: number

      /**
       * Тип сервиса ( Business, Optimum, и тд )
       */
      ServiceType: PromoCodeServiceTypeEnum

      /**
       * Количество дней от сегодня, когда промокод будет активным
       */
      DaysActive: number

      /**
       * Количество зарядов использования промокода
       */
      ChargesCount: number

      /**
       * Код промокода, можно не указывать, тогда АПИ само создаст его
       */
      Code?: string
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<CreatePromoCodeResponse>> {
    const rb = new RequestBuilder(this.rootUrl, PrivatePromoCodeService.PrivateForSpecialServiceTypePostPath, 'post')
    if (params) {
      rb.query('companyId', params.companyId, {})
      rb.query('DiscountSizePercent', params.DiscountSizePercent, {})
      rb.query('ServiceType', params.ServiceType, {})
      rb.query('DaysActive', params.DaysActive, {})
      rb.query('ChargesCount', params.ChargesCount, {})
      rb.query('Code', params.Code, {})
    }

    return this.http
      .request(
        rb.build({
          responseType: 'text',
          accept: 'text/plain',
          context: context
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<CreatePromoCodeResponse>
        })
      )
  }

  /**
   * По айди компании.
   *
   * Создание промокода на скидку для опеределенного сервис ТИПА ( Оптимум, Бизнес и тд ) и
   * начисления его на баланс нужной компании
   * - Обязательные поля имеют пометку *required
   * - Сервис должен быть доступным в каталоге
   * - Скидка не должна быть более 33 процентов
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `privateForSpecialServiceTypePost$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  privateForSpecialServiceTypePost$Plain(
    params: {
      companyId?: number

      /**
       * Размер скидки в процентах
       */
      DiscountSizePercent: number

      /**
       * Тип сервиса ( Business, Optimum, и тд )
       */
      ServiceType: PromoCodeServiceTypeEnum

      /**
       * Количество дней от сегодня, когда промокод будет активным
       */
      DaysActive: number

      /**
       * Количество зарядов использования промокода
       */
      ChargesCount: number

      /**
       * Код промокода, можно не указывать, тогда АПИ само создаст его
       */
      Code?: string
    },
    context?: HttpContext
  ): Observable<CreatePromoCodeResponse> {
    return this.privateForSpecialServiceTypePost$Plain$Response(params, context).pipe(map((r: StrictHttpResponse<CreatePromoCodeResponse>) => r.body as CreatePromoCodeResponse))
  }

  /**
   * По айди компании.
   *
   * Создание промокода на скидку для опеределенного сервис ТИПА ( Оптимум, Бизнес и тд ) и
   * начисления его на баланс нужной компании
   * - Обязательные поля имеют пометку *required
   * - Сервис должен быть доступным в каталоге
   * - Скидка не должна быть более 33 процентов
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `privateForSpecialServiceTypePost$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  privateForSpecialServiceTypePost$Json$Response(
    params: {
      companyId?: number

      /**
       * Размер скидки в процентах
       */
      DiscountSizePercent: number

      /**
       * Тип сервиса ( Business, Optimum, и тд )
       */
      ServiceType: PromoCodeServiceTypeEnum

      /**
       * Количество дней от сегодня, когда промокод будет активным
       */
      DaysActive: number

      /**
       * Количество зарядов использования промокода
       */
      ChargesCount: number

      /**
       * Код промокода, можно не указывать, тогда АПИ само создаст его
       */
      Code?: string
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<CreatePromoCodeResponse>> {
    const rb = new RequestBuilder(this.rootUrl, PrivatePromoCodeService.PrivateForSpecialServiceTypePostPath, 'post')
    if (params) {
      rb.query('companyId', params.companyId, {})
      rb.query('DiscountSizePercent', params.DiscountSizePercent, {})
      rb.query('ServiceType', params.ServiceType, {})
      rb.query('DaysActive', params.DaysActive, {})
      rb.query('ChargesCount', params.ChargesCount, {})
      rb.query('Code', params.Code, {})
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'text/json',
          context: context
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<CreatePromoCodeResponse>
        })
      )
  }

  /**
   * По айди компании.
   *
   * Создание промокода на скидку для опеределенного сервис ТИПА ( Оптимум, Бизнес и тд ) и
   * начисления его на баланс нужной компании
   * - Обязательные поля имеют пометку *required
   * - Сервис должен быть доступным в каталоге
   * - Скидка не должна быть более 33 процентов
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `privateForSpecialServiceTypePost$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  privateForSpecialServiceTypePost$Json(
    params: {
      companyId?: number

      /**
       * Размер скидки в процентах
       */
      DiscountSizePercent: number

      /**
       * Тип сервиса ( Business, Optimum, и тд )
       */
      ServiceType: PromoCodeServiceTypeEnum

      /**
       * Количество дней от сегодня, когда промокод будет активным
       */
      DaysActive: number

      /**
       * Количество зарядов использования промокода
       */
      ChargesCount: number

      /**
       * Код промокода, можно не указывать, тогда АПИ само создаст его
       */
      Code?: string
    },
    context?: HttpContext
  ): Observable<CreatePromoCodeResponse> {
    return this.privateForSpecialServiceTypePost$Json$Response(params, context).pipe(map((r: StrictHttpResponse<CreatePromoCodeResponse>) => r.body as CreatePromoCodeResponse))
  }

  /**
   * Path part for operation privateForSpecialServiceTypeFilePost
   */
  static readonly PrivateForSpecialServiceTypeFilePostPath = '/private/for-special-service-type-file'

  /**
   * По айдишкам из файла.
   *
   * Создание промокода на скидку для опеределенного сервис ТИПА ( Оптимум, Бизнес и тд ) и
   * начисления его на баланс переданных в файле компаний
   * Формат файла - CSV с перечислением через ','
   * - Обязательные поля имеют пометку *required
   * - Сервис должен быть доступным в каталоге
   * - Скидка не должна быть более 33 процентов
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `privateForSpecialServiceTypeFilePost$Plain()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  privateForSpecialServiceTypeFilePost$Plain$Response(
    params: {
      /**
       * Размер скидки в процентах
       */
      DiscountSizePercent: number

      /**
       * Тип сервиса ( Business, Optimum, и тд )
       */
      ServiceType: PromoCodeServiceTypeEnum

      /**
       * Количество дней от сегодня, когда промокод будет активным
       */
      DaysActive: number

      /**
       * Количество зарядов использования промокода
       */
      ChargesCount: number

      /**
       * Код промокода, можно не указывать, тогда АПИ само создаст его
       */
      Code?: string
      body?: {
        file?: Blob
      }
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<CreatePromoCodeResponse>> {
    const rb = new RequestBuilder(this.rootUrl, PrivatePromoCodeService.PrivateForSpecialServiceTypeFilePostPath, 'post')
    if (params) {
      rb.query('DiscountSizePercent', params.DiscountSizePercent, {})
      rb.query('ServiceType', params.ServiceType, {})
      rb.query('DaysActive', params.DaysActive, {})
      rb.query('ChargesCount', params.ChargesCount, {})
      rb.query('Code', params.Code, {})
      rb.body(params.body, 'multipart/form-data')
    }

    return this.http
      .request(
        rb.build({
          responseType: 'text',
          accept: 'text/plain',
          context: context
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<CreatePromoCodeResponse>
        })
      )
  }

  /**
   * По айдишкам из файла.
   *
   * Создание промокода на скидку для опеределенного сервис ТИПА ( Оптимум, Бизнес и тд ) и
   * начисления его на баланс переданных в файле компаний
   * Формат файла - CSV с перечислением через ','
   * - Обязательные поля имеют пометку *required
   * - Сервис должен быть доступным в каталоге
   * - Скидка не должна быть более 33 процентов
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `privateForSpecialServiceTypeFilePost$Plain$Response()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  privateForSpecialServiceTypeFilePost$Plain(
    params: {
      /**
       * Размер скидки в процентах
       */
      DiscountSizePercent: number

      /**
       * Тип сервиса ( Business, Optimum, и тд )
       */
      ServiceType: PromoCodeServiceTypeEnum

      /**
       * Количество дней от сегодня, когда промокод будет активным
       */
      DaysActive: number

      /**
       * Количество зарядов использования промокода
       */
      ChargesCount: number

      /**
       * Код промокода, можно не указывать, тогда АПИ само создаст его
       */
      Code?: string
      body?: {
        file?: Blob
      }
    },
    context?: HttpContext
  ): Observable<CreatePromoCodeResponse> {
    return this.privateForSpecialServiceTypeFilePost$Plain$Response(params, context).pipe(map((r: StrictHttpResponse<CreatePromoCodeResponse>) => r.body as CreatePromoCodeResponse))
  }

  /**
   * По айдишкам из файла.
   *
   * Создание промокода на скидку для опеределенного сервис ТИПА ( Оптимум, Бизнес и тд ) и
   * начисления его на баланс переданных в файле компаний
   * Формат файла - CSV с перечислением через ','
   * - Обязательные поля имеют пометку *required
   * - Сервис должен быть доступным в каталоге
   * - Скидка не должна быть более 33 процентов
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `privateForSpecialServiceTypeFilePost$Json()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  privateForSpecialServiceTypeFilePost$Json$Response(
    params: {
      /**
       * Размер скидки в процентах
       */
      DiscountSizePercent: number

      /**
       * Тип сервиса ( Business, Optimum, и тд )
       */
      ServiceType: PromoCodeServiceTypeEnum

      /**
       * Количество дней от сегодня, когда промокод будет активным
       */
      DaysActive: number

      /**
       * Количество зарядов использования промокода
       */
      ChargesCount: number

      /**
       * Код промокода, можно не указывать, тогда АПИ само создаст его
       */
      Code?: string
      body?: {
        file?: Blob
      }
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<CreatePromoCodeResponse>> {
    const rb = new RequestBuilder(this.rootUrl, PrivatePromoCodeService.PrivateForSpecialServiceTypeFilePostPath, 'post')
    if (params) {
      rb.query('DiscountSizePercent', params.DiscountSizePercent, {})
      rb.query('ServiceType', params.ServiceType, {})
      rb.query('DaysActive', params.DaysActive, {})
      rb.query('ChargesCount', params.ChargesCount, {})
      rb.query('Code', params.Code, {})
      rb.body(params.body, 'multipart/form-data')
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'text/json',
          context: context
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<CreatePromoCodeResponse>
        })
      )
  }

  /**
   * По айдишкам из файла.
   *
   * Создание промокода на скидку для опеределенного сервис ТИПА ( Оптимум, Бизнес и тд ) и
   * начисления его на баланс переданных в файле компаний
   * Формат файла - CSV с перечислением через ','
   * - Обязательные поля имеют пометку *required
   * - Сервис должен быть доступным в каталоге
   * - Скидка не должна быть более 33 процентов
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `privateForSpecialServiceTypeFilePost$Json$Response()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  privateForSpecialServiceTypeFilePost$Json(
    params: {
      /**
       * Размер скидки в процентах
       */
      DiscountSizePercent: number

      /**
       * Тип сервиса ( Business, Optimum, и тд )
       */
      ServiceType: PromoCodeServiceTypeEnum

      /**
       * Количество дней от сегодня, когда промокод будет активным
       */
      DaysActive: number

      /**
       * Количество зарядов использования промокода
       */
      ChargesCount: number

      /**
       * Код промокода, можно не указывать, тогда АПИ само создаст его
       */
      Code?: string
      body?: {
        file?: Blob
      }
    },
    context?: HttpContext
  ): Observable<CreatePromoCodeResponse> {
    return this.privateForSpecialServiceTypeFilePost$Json$Response(params, context).pipe(map((r: StrictHttpResponse<CreatePromoCodeResponse>) => r.body as CreatePromoCodeResponse))
  }

  /**
   * Path part for operation privateForWholeOrderPost
   */
  static readonly PrivateForWholeOrderPostPath = '/private/for-whole-order'

  /**
   * По айди компании.
   *
   * Создание промокода на скидку для всего заказа
   * начисления его на баланс нужной компании
   * - Обязательные поля имеют пометку *required
   * - Сервис должен быть доступным в каталоге
   * - Скидка не должна быть более 33 процентов
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `privateForWholeOrderPost$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  privateForWholeOrderPost$Plain$Response(
    params: {
      companyId?: number

      /**
       * Размер скидки в процентах
       */
      DiscountSizePercent: number

      /**
       * Количество дней от сегодня, когда промокод будет активным
       */
      DaysActive: number

      /**
       * Количество зарядов использования промокода
       */
      ChargesCount: number

      /**
       * Код промокода, можно не указывать, тогда АПИ само создаст его
       */
      Code?: string
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<CreatePromoCodeResponse>> {
    const rb = new RequestBuilder(this.rootUrl, PrivatePromoCodeService.PrivateForWholeOrderPostPath, 'post')
    if (params) {
      rb.query('companyId', params.companyId, {})
      rb.query('DiscountSizePercent', params.DiscountSizePercent, {})
      rb.query('DaysActive', params.DaysActive, {})
      rb.query('ChargesCount', params.ChargesCount, {})
      rb.query('Code', params.Code, {})
    }

    return this.http
      .request(
        rb.build({
          responseType: 'text',
          accept: 'text/plain',
          context: context
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<CreatePromoCodeResponse>
        })
      )
  }

  /**
   * По айди компании.
   *
   * Создание промокода на скидку для всего заказа
   * начисления его на баланс нужной компании
   * - Обязательные поля имеют пометку *required
   * - Сервис должен быть доступным в каталоге
   * - Скидка не должна быть более 33 процентов
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `privateForWholeOrderPost$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  privateForWholeOrderPost$Plain(
    params: {
      companyId?: number

      /**
       * Размер скидки в процентах
       */
      DiscountSizePercent: number

      /**
       * Количество дней от сегодня, когда промокод будет активным
       */
      DaysActive: number

      /**
       * Количество зарядов использования промокода
       */
      ChargesCount: number

      /**
       * Код промокода, можно не указывать, тогда АПИ само создаст его
       */
      Code?: string
    },
    context?: HttpContext
  ): Observable<CreatePromoCodeResponse> {
    return this.privateForWholeOrderPost$Plain$Response(params, context).pipe(map((r: StrictHttpResponse<CreatePromoCodeResponse>) => r.body as CreatePromoCodeResponse))
  }

  /**
   * По айди компании.
   *
   * Создание промокода на скидку для всего заказа
   * начисления его на баланс нужной компании
   * - Обязательные поля имеют пометку *required
   * - Сервис должен быть доступным в каталоге
   * - Скидка не должна быть более 33 процентов
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `privateForWholeOrderPost$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  privateForWholeOrderPost$Json$Response(
    params: {
      companyId?: number

      /**
       * Размер скидки в процентах
       */
      DiscountSizePercent: number

      /**
       * Количество дней от сегодня, когда промокод будет активным
       */
      DaysActive: number

      /**
       * Количество зарядов использования промокода
       */
      ChargesCount: number

      /**
       * Код промокода, можно не указывать, тогда АПИ само создаст его
       */
      Code?: string
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<CreatePromoCodeResponse>> {
    const rb = new RequestBuilder(this.rootUrl, PrivatePromoCodeService.PrivateForWholeOrderPostPath, 'post')
    if (params) {
      rb.query('companyId', params.companyId, {})
      rb.query('DiscountSizePercent', params.DiscountSizePercent, {})
      rb.query('DaysActive', params.DaysActive, {})
      rb.query('ChargesCount', params.ChargesCount, {})
      rb.query('Code', params.Code, {})
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'text/json',
          context: context
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<CreatePromoCodeResponse>
        })
      )
  }

  /**
   * По айди компании.
   *
   * Создание промокода на скидку для всего заказа
   * начисления его на баланс нужной компании
   * - Обязательные поля имеют пометку *required
   * - Сервис должен быть доступным в каталоге
   * - Скидка не должна быть более 33 процентов
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `privateForWholeOrderPost$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  privateForWholeOrderPost$Json(
    params: {
      companyId?: number

      /**
       * Размер скидки в процентах
       */
      DiscountSizePercent: number

      /**
       * Количество дней от сегодня, когда промокод будет активным
       */
      DaysActive: number

      /**
       * Количество зарядов использования промокода
       */
      ChargesCount: number

      /**
       * Код промокода, можно не указывать, тогда АПИ само создаст его
       */
      Code?: string
    },
    context?: HttpContext
  ): Observable<CreatePromoCodeResponse> {
    return this.privateForWholeOrderPost$Json$Response(params, context).pipe(map((r: StrictHttpResponse<CreatePromoCodeResponse>) => r.body as CreatePromoCodeResponse))
  }

  /**
   * Path part for operation privateForWholeOrderFilePost
   */
  static readonly PrivateForWholeOrderFilePostPath = '/private/for-whole-order-file'

  /**
   * По айдишкам из файла.
   *
   * Создание промокода на скидку для всего заказа
   * начисления его на баланс переданных в файле компаний
   * Формат файла - CSV с перечислением через ','
   * - Обязательные поля имеют пометку *required
   * - Сервис должен быть доступным в каталоге
   * - Скидка не должна быть более 33 процентов
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `privateForWholeOrderFilePost$Plain()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  privateForWholeOrderFilePost$Plain$Response(
    params: {
      /**
       * Размер скидки в процентах
       */
      DiscountSizePercent: number

      /**
       * Количество дней от сегодня, когда промокод будет активным
       */
      DaysActive: number

      /**
       * Количество зарядов использования промокода
       */
      ChargesCount: number

      /**
       * Код промокода, можно не указывать, тогда АПИ само создаст его
       */
      Code?: string
      body?: {
        file?: Blob
      }
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<CreatePromoCodeResponse>> {
    const rb = new RequestBuilder(this.rootUrl, PrivatePromoCodeService.PrivateForWholeOrderFilePostPath, 'post')
    if (params) {
      rb.query('DiscountSizePercent', params.DiscountSizePercent, {})
      rb.query('DaysActive', params.DaysActive, {})
      rb.query('ChargesCount', params.ChargesCount, {})
      rb.query('Code', params.Code, {})
      rb.body(params.body, 'multipart/form-data')
    }

    return this.http
      .request(
        rb.build({
          responseType: 'text',
          accept: 'text/plain',
          context: context
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<CreatePromoCodeResponse>
        })
      )
  }

  /**
   * По айдишкам из файла.
   *
   * Создание промокода на скидку для всего заказа
   * начисления его на баланс переданных в файле компаний
   * Формат файла - CSV с перечислением через ','
   * - Обязательные поля имеют пометку *required
   * - Сервис должен быть доступным в каталоге
   * - Скидка не должна быть более 33 процентов
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `privateForWholeOrderFilePost$Plain$Response()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  privateForWholeOrderFilePost$Plain(
    params: {
      /**
       * Размер скидки в процентах
       */
      DiscountSizePercent: number

      /**
       * Количество дней от сегодня, когда промокод будет активным
       */
      DaysActive: number

      /**
       * Количество зарядов использования промокода
       */
      ChargesCount: number

      /**
       * Код промокода, можно не указывать, тогда АПИ само создаст его
       */
      Code?: string
      body?: {
        file?: Blob
      }
    },
    context?: HttpContext
  ): Observable<CreatePromoCodeResponse> {
    return this.privateForWholeOrderFilePost$Plain$Response(params, context).pipe(map((r: StrictHttpResponse<CreatePromoCodeResponse>) => r.body as CreatePromoCodeResponse))
  }

  /**
   * По айдишкам из файла.
   *
   * Создание промокода на скидку для всего заказа
   * начисления его на баланс переданных в файле компаний
   * Формат файла - CSV с перечислением через ','
   * - Обязательные поля имеют пометку *required
   * - Сервис должен быть доступным в каталоге
   * - Скидка не должна быть более 33 процентов
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `privateForWholeOrderFilePost$Json()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  privateForWholeOrderFilePost$Json$Response(
    params: {
      /**
       * Размер скидки в процентах
       */
      DiscountSizePercent: number

      /**
       * Количество дней от сегодня, когда промокод будет активным
       */
      DaysActive: number

      /**
       * Количество зарядов использования промокода
       */
      ChargesCount: number

      /**
       * Код промокода, можно не указывать, тогда АПИ само создаст его
       */
      Code?: string
      body?: {
        file?: Blob
      }
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<CreatePromoCodeResponse>> {
    const rb = new RequestBuilder(this.rootUrl, PrivatePromoCodeService.PrivateForWholeOrderFilePostPath, 'post')
    if (params) {
      rb.query('DiscountSizePercent', params.DiscountSizePercent, {})
      rb.query('DaysActive', params.DaysActive, {})
      rb.query('ChargesCount', params.ChargesCount, {})
      rb.query('Code', params.Code, {})
      rb.body(params.body, 'multipart/form-data')
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'text/json',
          context: context
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<CreatePromoCodeResponse>
        })
      )
  }

  /**
   * По айдишкам из файла.
   *
   * Создание промокода на скидку для всего заказа
   * начисления его на баланс переданных в файле компаний
   * Формат файла - CSV с перечислением через ','
   * - Обязательные поля имеют пометку *required
   * - Сервис должен быть доступным в каталоге
   * - Скидка не должна быть более 33 процентов
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `privateForWholeOrderFilePost$Json$Response()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  privateForWholeOrderFilePost$Json(
    params: {
      /**
       * Размер скидки в процентах
       */
      DiscountSizePercent: number

      /**
       * Количество дней от сегодня, когда промокод будет активным
       */
      DaysActive: number

      /**
       * Количество зарядов использования промокода
       */
      ChargesCount: number

      /**
       * Код промокода, можно не указывать, тогда АПИ само создаст его
       */
      Code?: string
      body?: {
        file?: Blob
      }
    },
    context?: HttpContext
  ): Observable<CreatePromoCodeResponse> {
    return this.privateForWholeOrderFilePost$Json$Response(params, context).pipe(map((r: StrictHttpResponse<CreatePromoCodeResponse>) => r.body as CreatePromoCodeResponse))
  }

  /**
   * Path part for operation privateForBonusServicesPost
   */
  static readonly PrivateForBonusServicesPostPath = '/private/for-bonus-services'

  /**
   * По айди компании.
   *
   * Создание промокода для начисления бонусного сервиса и
   * начисления его на баланс нужной компании
   * - Обязательные поля имеют пометку *required
   * - Сервис должен быть доступным в каталоге
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `privateForBonusServicesPost$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  privateForBonusServicesPost$Plain$Response(
    params: {
      companyId?: number
      ServiceIds: Array<number>

      /**
       * Количество дней от сегодня, когда промокод будет активным
       */
      DaysActive: number

      /**
       * Количество зарядов использования промокода
       */
      ChargesCount: number

      /**
       * Код промокода, можно не указывать, тогда АПИ само создаст его
       */
      Code?: string
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<CreatePromoCodeResponse>> {
    const rb = new RequestBuilder(this.rootUrl, PrivatePromoCodeService.PrivateForBonusServicesPostPath, 'post')
    if (params) {
      rb.query('companyId', params.companyId, {})
      rb.query('ServiceIds', params.ServiceIds, {})
      rb.query('DaysActive', params.DaysActive, {})
      rb.query('ChargesCount', params.ChargesCount, {})
      rb.query('Code', params.Code, {})
    }

    return this.http
      .request(
        rb.build({
          responseType: 'text',
          accept: 'text/plain',
          context: context
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<CreatePromoCodeResponse>
        })
      )
  }

  /**
   * По айди компании.
   *
   * Создание промокода для начисления бонусного сервиса и
   * начисления его на баланс нужной компании
   * - Обязательные поля имеют пометку *required
   * - Сервис должен быть доступным в каталоге
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `privateForBonusServicesPost$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  privateForBonusServicesPost$Plain(
    params: {
      companyId?: number
      ServiceIds: Array<number>

      /**
       * Количество дней от сегодня, когда промокод будет активным
       */
      DaysActive: number

      /**
       * Количество зарядов использования промокода
       */
      ChargesCount: number

      /**
       * Код промокода, можно не указывать, тогда АПИ само создаст его
       */
      Code?: string
    },
    context?: HttpContext
  ): Observable<CreatePromoCodeResponse> {
    return this.privateForBonusServicesPost$Plain$Response(params, context).pipe(map((r: StrictHttpResponse<CreatePromoCodeResponse>) => r.body as CreatePromoCodeResponse))
  }

  /**
   * По айди компании.
   *
   * Создание промокода для начисления бонусного сервиса и
   * начисления его на баланс нужной компании
   * - Обязательные поля имеют пометку *required
   * - Сервис должен быть доступным в каталоге
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `privateForBonusServicesPost$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  privateForBonusServicesPost$Json$Response(
    params: {
      companyId?: number
      ServiceIds: Array<number>

      /**
       * Количество дней от сегодня, когда промокод будет активным
       */
      DaysActive: number

      /**
       * Количество зарядов использования промокода
       */
      ChargesCount: number

      /**
       * Код промокода, можно не указывать, тогда АПИ само создаст его
       */
      Code?: string
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<CreatePromoCodeResponse>> {
    const rb = new RequestBuilder(this.rootUrl, PrivatePromoCodeService.PrivateForBonusServicesPostPath, 'post')
    if (params) {
      rb.query('companyId', params.companyId, {})
      rb.query('ServiceIds', params.ServiceIds, {})
      rb.query('DaysActive', params.DaysActive, {})
      rb.query('ChargesCount', params.ChargesCount, {})
      rb.query('Code', params.Code, {})
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'text/json',
          context: context
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<CreatePromoCodeResponse>
        })
      )
  }

  /**
   * По айди компании.
   *
   * Создание промокода для начисления бонусного сервиса и
   * начисления его на баланс нужной компании
   * - Обязательные поля имеют пометку *required
   * - Сервис должен быть доступным в каталоге
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `privateForBonusServicesPost$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  privateForBonusServicesPost$Json(
    params: {
      companyId?: number
      ServiceIds: Array<number>

      /**
       * Количество дней от сегодня, когда промокод будет активным
       */
      DaysActive: number

      /**
       * Количество зарядов использования промокода
       */
      ChargesCount: number

      /**
       * Код промокода, можно не указывать, тогда АПИ само создаст его
       */
      Code?: string
    },
    context?: HttpContext
  ): Observable<CreatePromoCodeResponse> {
    return this.privateForBonusServicesPost$Json$Response(params, context).pipe(map((r: StrictHttpResponse<CreatePromoCodeResponse>) => r.body as CreatePromoCodeResponse))
  }

  /**
   * Path part for operation privateForBonusServicesFilePost
   */
  static readonly PrivateForBonusServicesFilePostPath = '/private/for-bonus-services-file'

  /**
   * По айдишкам из файла.
   *
   * Создание промокода для начисления бонусного сервиса и
   * начисления его на баланс переданных в файле компаний
   * Формат файла - CSV с перечислением через ','
   * - Обязательные поля имеют пометку *required
   * - Сервис должен быть доступным в каталоге
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `privateForBonusServicesFilePost$Plain()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  privateForBonusServicesFilePost$Plain$Response(
    params: {
      ServiceIds: Array<number>

      /**
       * Количество дней от сегодня, когда промокод будет активным
       */
      DaysActive: number

      /**
       * Количество зарядов использования промокода
       */
      ChargesCount: number

      /**
       * Код промокода, можно не указывать, тогда АПИ само создаст его
       */
      Code?: string
      body?: {
        file?: Blob
      }
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<CreatePromoCodeResponse>> {
    const rb = new RequestBuilder(this.rootUrl, PrivatePromoCodeService.PrivateForBonusServicesFilePostPath, 'post')
    if (params) {
      rb.query('ServiceIds', params.ServiceIds, {})
      rb.query('DaysActive', params.DaysActive, {})
      rb.query('ChargesCount', params.ChargesCount, {})
      rb.query('Code', params.Code, {})
      rb.body(params.body, 'multipart/form-data')
    }

    return this.http
      .request(
        rb.build({
          responseType: 'text',
          accept: 'text/plain',
          context: context
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<CreatePromoCodeResponse>
        })
      )
  }

  /**
   * По айдишкам из файла.
   *
   * Создание промокода для начисления бонусного сервиса и
   * начисления его на баланс переданных в файле компаний
   * Формат файла - CSV с перечислением через ','
   * - Обязательные поля имеют пометку *required
   * - Сервис должен быть доступным в каталоге
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `privateForBonusServicesFilePost$Plain$Response()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  privateForBonusServicesFilePost$Plain(
    params: {
      ServiceIds: Array<number>

      /**
       * Количество дней от сегодня, когда промокод будет активным
       */
      DaysActive: number

      /**
       * Количество зарядов использования промокода
       */
      ChargesCount: number

      /**
       * Код промокода, можно не указывать, тогда АПИ само создаст его
       */
      Code?: string
      body?: {
        file?: Blob
      }
    },
    context?: HttpContext
  ): Observable<CreatePromoCodeResponse> {
    return this.privateForBonusServicesFilePost$Plain$Response(params, context).pipe(map((r: StrictHttpResponse<CreatePromoCodeResponse>) => r.body as CreatePromoCodeResponse))
  }

  /**
   * По айдишкам из файла.
   *
   * Создание промокода для начисления бонусного сервиса и
   * начисления его на баланс переданных в файле компаний
   * Формат файла - CSV с перечислением через ','
   * - Обязательные поля имеют пометку *required
   * - Сервис должен быть доступным в каталоге
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `privateForBonusServicesFilePost$Json()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  privateForBonusServicesFilePost$Json$Response(
    params: {
      ServiceIds: Array<number>

      /**
       * Количество дней от сегодня, когда промокод будет активным
       */
      DaysActive: number

      /**
       * Количество зарядов использования промокода
       */
      ChargesCount: number

      /**
       * Код промокода, можно не указывать, тогда АПИ само создаст его
       */
      Code?: string
      body?: {
        file?: Blob
      }
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<CreatePromoCodeResponse>> {
    const rb = new RequestBuilder(this.rootUrl, PrivatePromoCodeService.PrivateForBonusServicesFilePostPath, 'post')
    if (params) {
      rb.query('ServiceIds', params.ServiceIds, {})
      rb.query('DaysActive', params.DaysActive, {})
      rb.query('ChargesCount', params.ChargesCount, {})
      rb.query('Code', params.Code, {})
      rb.body(params.body, 'multipart/form-data')
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'text/json',
          context: context
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<CreatePromoCodeResponse>
        })
      )
  }

  /**
   * По айдишкам из файла.
   *
   * Создание промокода для начисления бонусного сервиса и
   * начисления его на баланс переданных в файле компаний
   * Формат файла - CSV с перечислением через ','
   * - Обязательные поля имеют пометку *required
   * - Сервис должен быть доступным в каталоге
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `privateForBonusServicesFilePost$Json$Response()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  privateForBonusServicesFilePost$Json(
    params: {
      ServiceIds: Array<number>

      /**
       * Количество дней от сегодня, когда промокод будет активным
       */
      DaysActive: number

      /**
       * Количество зарядов использования промокода
       */
      ChargesCount: number

      /**
       * Код промокода, можно не указывать, тогда АПИ само создаст его
       */
      Code?: string
      body?: {
        file?: Blob
      }
    },
    context?: HttpContext
  ): Observable<CreatePromoCodeResponse> {
    return this.privateForBonusServicesFilePost$Json$Response(params, context).pipe(map((r: StrictHttpResponse<CreatePromoCodeResponse>) => r.body as CreatePromoCodeResponse))
  }
}
