/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { Observable } from 'rxjs'
import { map } from 'rxjs/operators'

import { BaseService } from '../base-service'
import { NotificationDataAccessConfiguration } from '../notification-data-access-configuration'
import { StrictHttpResponse } from '../strict-http-response'

import { imagePostImage$Json } from '../fn/image/image-post-image-json'
import { ImagePostImage$Json$Params } from '../fn/image/image-post-image-json'
import { imagePostImage$Plain } from '../fn/image/image-post-image-plain'
import { ImagePostImage$Plain$Params } from '../fn/image/image-post-image-plain'

@Injectable({ providedIn: 'root' })
export class ImageService extends BaseService {
  constructor(config: NotificationDataAccessConfiguration, http: HttpClient) {
    super(config, http)
  }

  /** Path part for operation `imagePostImage()` */
  static readonly ImagePostImagePath = '/v1/image'

  /**
   * Upload image to rua storage.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `imagePostImage$Plain()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  imagePostImage$Plain$Response(params?: ImagePostImage$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<string>> {
    return imagePostImage$Plain(this.http, this.rootUrl, params, context)
  }

  /**
   * Upload image to rua storage.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `imagePostImage$Plain$Response()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  imagePostImage$Plain(params?: ImagePostImage$Plain$Params, context?: HttpContext): Observable<string> {
    return this.imagePostImage$Plain$Response(params, context).pipe(map((r: StrictHttpResponse<string>): string => r.body))
  }

  /**
   * Upload image to rua storage.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `imagePostImage$Json()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  imagePostImage$Json$Response(params?: ImagePostImage$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<string>> {
    return imagePostImage$Json(this.http, this.rootUrl, params, context)
  }

  /**
   * Upload image to rua storage.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `imagePostImage$Json$Response()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  imagePostImage$Json(params?: ImagePostImage$Json$Params, context?: HttpContext): Observable<string> {
    return this.imagePostImage$Json$Response(params, context).pipe(map((r: StrictHttpResponse<string>): string => r.body))
  }
}
