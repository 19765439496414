const SPACING = {
  0: '0',
  5: '5px',
  10: '10px',
  20: '20px',
  40: '40px',
  80: '80px',
  160: '160px'
}

module.exports = { SPACING }
