import { CityDataLocalized, DataLocalized } from '@alliance/jobseeker/data-access'

export const allCitiesItem: CityDataLocalized = {
  id: 0,
  ru: 'Вся Украина',
  ua: 'Вся Україна',
  en: 'All Ukraine',
  locativeName: {
    ru: 'Всей Украине',
    ua: 'Всій Україні'
  }
}
export const allRubricsItem: DataLocalized = {
  id: 0,
  ru: 'Все рубрики',
  ua: 'Усі рубрики',
  en: 'All rubrics'
}
export const allSphereItem: DataLocalized = {
  en: 'I consider all areas',
  ua: 'Розглядаю всі сфери',
  ru: 'Рассматриваю все сферы',
  id: 0
}
