import { DEPLOY_FOLDER, DEPLOY_URL } from '@alliance/shared/models'
import { Inject, Injectable, Optional, Renderer2, RendererFactory2 } from '@angular/core'
import { DOCUMENT } from '@angular/common'
import { LOCATION } from '@ng-web-apis/common'
import { fromEvent, Observable, of } from 'rxjs'
import { take } from 'rxjs/operators'
import { getFileUrl, getWebpackPublicPath } from './dynamic-files-loader.util'

type AppendPlace = 'in-the-end' | 'before-global-style'

@Injectable({ providedIn: 'root' })
export class DynamicFilesLoaderService {
  private renderer: Renderer2

  public constructor(
    @Optional() @Inject(DEPLOY_URL) private deployUrl: string | null,
    @Optional() @Inject(DEPLOY_FOLDER) private deployFolder: string | null,
    @Inject(DOCUMENT) private document: Document,
    @Inject(LOCATION) private location: Location,
    private rendererFactory: RendererFactory2
  ) {
    this.renderer = this.rendererFactory.createRenderer(null, null)
  }

  /**
   *
   * @param url path to file from angular.json
   * @param id unique id for tag to check if it's already present
   * @param elementType type of imported element link, script etc
   * @param version version of library from importing files. Add to imported file url as query params
   * It need to reload cache if library updated and old styles were cashed
   * @param append 'in-the-end' | 'before-global-style'
   * (styles files import can rewrite our global styles if it's imported after)
   * @returns createdElement after its insertion
   */
  public loadFile<T extends keyof HTMLElementTagNameMap>(url: string, id: string, elementType: T, version: string, append: AppendPlace = 'in-the-end'): Observable<unknown> {
    const { head } = this.document
    const fileAlreadyLoaded = !!this.document?.getElementById(id)
    if (fileAlreadyLoaded || !head) {
      return of(null)
    }

    const createdElement = this.createElement(url, elementType, version)
    if (!createdElement) {
      return of(null)
    }

    if (append === 'before-global-style') {
      const beforeElement = this.getGlobalStyleElement()
      this.renderer.setAttribute(createdElement, 'id', id)
      this.renderer.insertBefore(head, createdElement, beforeElement)
    } else {
      this.renderer.setAttribute(createdElement, 'id', id)
      this.renderer.appendChild(head, createdElement)
    }

    return fromEvent(createdElement, 'load').pipe(take(1))
  }

  private getGlobalStyleElement(): Element | null {
    // TODO: delete this.deployUrl when admin8 & admin-crm ready
    // TODO: delete __webpack_public_path__ & use basePath when webpack setup removed
    const customPath = this.deployUrl || getWebpackPublicPath()
    let globalStyleQuery = `[rel="stylesheet"][href*="${(customPath ?? '').replace(/\/$/, '')}/styles."]`
    if (!customPath) {
      globalStyleQuery = globalStyleQuery.replace('/', '')
    }
    return this.document.querySelector(globalStyleQuery)
  }

  private createElement<T extends keyof HTMLElementTagNameMap>(pathname: string, elementType: T, version: string): HTMLElement | null {
    const pathNameWithQuery = `${pathname}?v=${version}`
    const url = getFileUrl(pathNameWithQuery, { origin: this.location.origin, folder: this.deployFolder })

    switch (elementType) {
      case 'link': {
        const linkEl = this.renderer.createElement(elementType) as HTMLLinkElement
        linkEl.rel = 'stylesheet'
        linkEl.href = url
        return linkEl
      }
      case 'script': {
        const scriptEl = this.renderer.createElement(elementType) as HTMLScriptElement
        scriptEl.src = url
        scriptEl.type = 'text/javascript'
        return scriptEl
      }
      default:
        return null
    }
  }
}
