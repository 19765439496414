import { DataLayerPushEvent } from '@alliance/shared/utils'

export const CvdbOpenResumeEvent: DataLayerPushEvent = {
  event: 'EMP_Resume',
  eventCategory: 'EMP_Resume',
  eventAction: 'View_FromCVDB',
  eventLabel: null
}

export const CvdbOpenFromSimilarResumeEvent: DataLayerPushEvent = {
  event: 'EMP_Resume',
  eventCategory: 'EMP_Resume',
  eventAction: 'View_FromSimilar',
  eventLabel: null
}
