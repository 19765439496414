import { UserService, UserSideEnum } from '@alliance/shared/utils'
import { Injectable } from '@angular/core'
import { ActivatedRouteSnapshot, CanActivateChild } from '@angular/router'

@Injectable()
export class GeneralGuard implements CanActivateChild {
  public constructor(private userService: UserService) {}

  public canActivateChild(childRoute: ActivatedRouteSnapshot): boolean {
    const userSide = childRoute?.data?.['userSide'] as unknown
    if (this.isValidUserSide(userSide)) {
      this.userService.userSide = userSide
    }
    return true
  }

  private isValidUserSide(value: unknown): value is UserSideEnum {
    return !!value && typeof value === 'string' && !!Object.values(UserSideEnum).find(item => item.toString() === value)
  }
}
