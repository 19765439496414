import { DataLayerPushEvent } from '@alliance/shared/utils'

// https://rabota.atlassian.net/browse/RUA-26623

type AppliesEventActionPrefix = 'vacancy_list' | 'vacancy_applies'

const commonEventData: { event: string; eventCategory: string } = { event: 'EMP_Applies', eventCategory: 'EMP_Applies' }

export const getRedirectToAppliesPageEvent = (vacancyId: string): DataLayerPushEvent => ({
  ...commonEventData,
  eventAction: 'ListView',
  eventLabel: vacancyId
})

export const getTryToInviteCandidateEvent = (vacancyId: string, applyId: string): DataLayerPushEvent => ({
  ...commonEventData,
  eventAction: 'TryToInvite',
  eventLabel: `${vacancyId}_${applyId}`
})

export const getInviteCandidateEvent = (vacancyId: string, applyId: string, textType: 'customText' | 'defaultText', sendMeCopyToEmail: boolean, addressId: string | null): DataLayerPushEvent => ({
  ...commonEventData,
  eventAction: 'Invite',
  eventLabel: `${vacancyId}_${applyId}_${textType}_${sendMeCopyToEmail ? '1' : '0'}_${addressId ?? null}`
})

export const getRefuseCandidateEvent = (vacancyId: string, applyId: string): DataLayerPushEvent => ({
  ...commonEventData,
  eventAction: 'Refuse',
  eventLabel: `${vacancyId}_${applyId}`
})

export const getTryToAddHelperEvent = (applyId: string): DataLayerPushEvent => ({
  ...commonEventData,
  eventAction: 'tryToAddHelper',
  eventLabel: applyId
})

export const getAddNoteEvent = (applyId: string): DataLayerPushEvent => ({
  ...commonEventData,
  eventAction: 'AddNote',
  eventLabel: applyId
})

export const getRedirectToAppliesEvent = (prefix: AppliesEventActionPrefix, vacancyId: string): DataLayerPushEvent => ({
  ...commonEventData,
  eventAction: `applies_${prefix}`,
  eventLabel: vacancyId
})

export const getRedirectToSavedEvent = (prefix: AppliesEventActionPrefix, vacancyId: string): DataLayerPushEvent => ({
  ...commonEventData,
  eventAction: `saved_${prefix}`,
  eventLabel: vacancyId
})

export const getRedirectToInterestedEvent = (prefix: AppliesEventActionPrefix, vacancyId: string): DataLayerPushEvent => ({
  ...commonEventData,
  eventAction: `interested_${prefix}`,
  eventLabel: vacancyId
})

export const getRedirectToRecommendedEvent = (prefix: AppliesEventActionPrefix, vacancyId: string): DataLayerPushEvent => ({
  ...commonEventData,
  eventAction: `recommended_${prefix}`,
  eventLabel: vacancyId
})

export const getRedirectToStatisticsEvent = (prefix: AppliesEventActionPrefix, vacancyId: string): DataLayerPushEvent => ({
  ...commonEventData,
  eventAction: `statistics_${prefix}`,
  eventLabel: vacancyId
})

export const getRedirectToCandidatesEvent = (prefix: AppliesEventActionPrefix, vacancyId: string): DataLayerPushEvent => ({
  ...commonEventData,
  eventAction: `candidates_${prefix}`,
  eventLabel: vacancyId
})
