import { APP_INITIALIZER, NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { GoogleAnalyticService } from './google-analytic.service'

@NgModule({
  imports: [CommonModule],
  providers: [
    {
      provide: APP_INITIALIZER,
      useFactory: (googleAnalyticService: GoogleAnalyticService) => (): boolean => googleAnalyticService.addGoogleAnalytics(),
      multi: true,
      deps: [GoogleAnalyticService]
    }
  ]
})
export class SharedGoogleAnalyticModule {}
