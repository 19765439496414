import { log } from '@alliance/shared/logger'
import { Injectable } from '@angular/core'
import { Observable, of } from 'rxjs'

@Injectable()
export abstract class WsMessageService {
  protected socket: WebSocket | undefined

  public connect(url: string): Observable<boolean> {
    if (this.socket) {
      this.disconnect('re-connect')
    }

    this.socket = new WebSocket(url)

    this.socket.onopen = (): void => this.OnOpen()

    this.socket.onclose = (data): void => this.OnClose(data)

    this.socket.onerror = (error): void => this.OnError(error)

    this.socket.onmessage = (data): void => this.OnMessage(data)

    return of(true)
  }

  public disconnect(reason = 'disconnect'): void {
    if (this.socket) {
      this.socket.close(1000, reason)
    }
  }

  protected OnClose(data: unknown): void {
    log.log({ where: 'socket-api: WsMessageService', message: 'OnClose', data })
  }

  protected OnError(error: unknown): void {
    log.log({ where: 'socket-api: WsMessageService', message: 'OnError', error })
  }

  protected abstract OnMessage(message: unknown): void
  protected abstract OnOpen(): void
}
