import { Injectable } from '@angular/core'

import { AbstractEventPlugin } from './abstract.plugin'
import { CallbackFunction } from '../types/callback-function.type'

@Injectable()
export class StopEventPlugin extends AbstractEventPlugin {
  protected readonly modifier = '.stop'

  public addEventListener(element: HTMLElement, event: string, handler: CallbackFunction): CallbackFunction {
    return this.manager.addEventListener(element, this.unwrap(event), (e: Event): void => {
      e.stopPropagation()
      handler(e)
    })
  }
}
