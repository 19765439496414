import { Injectable } from '@angular/core'
import { ActivatedRouteSnapshot, CanActivateChild, RouterStateSnapshot, UrlTree } from '@angular/router'
import { Observable } from 'rxjs'
import { DetectPlatformService, MediaService, NavigationService, RuavarsPlatform, RuavarsService, TrackPageService } from '@alliance/shared/utils'
import { RxStateService } from '@alliance/shared/models'
import { AuthService } from '@alliance/shared/auth/api'
import { TranslationService } from '@alliance/shared/translation'
import { map } from 'rxjs/operators'

@Injectable()
export class TrackPageGuard
  extends RxStateService<{
    isLogged: boolean
    isMobileScreen: boolean
  }>
  implements CanActivateChild {
  public constructor(
    private platform: DetectPlatformService,
    private navigationService: NavigationService,
    private ruavarsService: RuavarsService,
    private authService: AuthService,
    private translationService: TranslationService,
    private mediaService: MediaService,
    private trackPageService: TrackPageService
  ) {
    super()

    this.initState({
      isLogged: this.authService.token$.pipe(map(value => !!value)),
      isMobileScreen: this.mediaService.select('isMobileScreen')
    })
  }

  public canActivateChild(childRoute: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    if (this.platform.isServer) {
      return true // disallow logic below for server-platform
    }

    this.trackPageService.set(rxstate => ({
      prevUrl: rxstate.currentUrl
    }))

    // set ruavars
    this.hold(this.select('isLogged'), isLogged => {
      this.ruavarsService.setInfo({
        isEmployer: isLogged && this.authService.isEmployer,
        isJobsearcher: isLogged && !this.authService.isEmployer,
        uid: this.authService.user?.EmailId ?? null,
        userEMail: this.authService.user?.email ?? '',
        isUkrainian: this.translationService.currentLangIsUkrainian(),
        isLogged
      })
    })

    this.hold(this.select('isMobileScreen'), isMobile => {
      this.ruavarsService.setInfo({
        platform: isMobile ? RuavarsPlatform.mobile : RuavarsPlatform.desktop
      })
    })

    if (childRoute.component) {
      this.trackPageService.set({
        currentUrl: state.url
      })

      this.navigationService.pushToHistory(state.url)
    }

    return true
  }
}
