import { SuitablePublicationService } from '../models/publication-services-aggregated-by-type'

export const getAvailableVacancyCount = (service: SuitablePublicationService): number => {
  switch (service?.__typename) {
    case 'OrderedVacancyPackageService':
      return service.totalVacancyCount ?? 0
    case 'ActivatedVacancyPackageService':
    case 'ActivatedVacancyPublicationService':
      return service.availableCount ?? 0
    default:
      return 0
  }
}
