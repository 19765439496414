import { RxStateService } from '@alliance/shared/models'
import { Injectable } from '@angular/core'
import { Location } from '@angular/common'
import { Router } from '@angular/router'
import { map } from 'rxjs/operators'

@Injectable({
  providedIn: 'root'
})
export class NavigationService extends RxStateService<{
  history: string[]
  hasPrevious: boolean
}> {
  public constructor(private router: Router, private location: Location) {
    super()

    this.initState({
      history: [],
      hasPrevious: this.select('history').pipe(map(history => !!history?.length))
    })
  }

  public pushToHistory(url: string): void {
    const { history } = this.get()
    if (url !== history[history.length - 1]) {
      this.set({ history: [...history, url] })
    }
  }

  public deleteFromHistory(): void {
    // remove last item from history
    this.set(({ history }) => ({
      history: [...history].slice(0, -1)
    }))
  }

  public clearHistory(): void {
    this.set({ history: [] })
  }

  public back(url: string): void {
    this.deleteFromHistory()

    if (this.get().history.length > 0) {
      this.location.back()
    } else {
      this.router.navigate([url])
    }
  }
}
