export enum PubsubMessageTypesEnum {
  expandChatSidebar = 'expandChatSidebar',
  collapseChatSidebar = 'collapseChatSidebar',
  initNewConversation = 'initNewConversation',
  newConversationInitiated = 'newConversationInitiated',
  saveMiniProfile = 'saveMiniProfile',
  chatSidebarIsOpened = 'chatSidebarIsOpened',
  vacancyMediaFullscreenEnabled = 'vacancyMediaFullscreenEnabled',
  applyInCheckoutModeOpened = 'applyInCheckoutModeOpened',
  updateLimits = 'updateLimits',
  updateServices = 'updateServices',
  syncServicesCounters = 'syncServicesCounters',
  headerIsRendered = 'headerIsRendered',
  htmlElementsHeader = 'htmlElementsHeader',
  hideHeader = 'hideHeader',
  headerVisibilityChange = 'headerVisibilityChange',
  bottomBarMenuRendered = 'bottomBarMenuRendered',
  syncCvDbSavedSearches = 'syncCvDbSavedSearches',
  rerenderNotificationAlert = 'rerenderNotificationAlert',
  headerForNotifications = 'headerForNotifications',
  updatePromoCodesCounter = 'updatePromoCodesCounter',
  renderChatAlert = 'renderChatAlert',
  renderChat = 'renderChat'
}
