const getAmount = (str: string): number => {
  const result = str.match(/(\d+)/gi)
  return +(result?.[0]?.trim() || 0)
}

export const timePeriodTransform = (period: string): { years?: number | undefined; months?: number | undefined } | null | undefined => {
  if (!period) {
    return null
  }

  const val = period.match(/(\d+\s{\d})/gi)
  if (val) {
    return val.reduce<{ years?: number | undefined; months?: number | undefined } | null | undefined>((accumulator, current) => {
      if (accumulator && typeof accumulator !== 'undefined') {
        if (current.includes('{0}')) {
          accumulator.months = getAmount(current)
        } else {
          accumulator.years = getAmount(current)
        }
      }
      return accumulator
    }, {})
  } else {
    return null
  }
}
