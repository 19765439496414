export enum EmployerAccessByRightsEnum {
  manageUsers = 'manageUsers',
  publishVacanciesWithoutApproval = 'publishVacanciesWithoutApproval',
  viewSomeoneElseVacancies = 'viewSomeoneElseVacancies',
  manageSomeoneElseVacancies = 'manageSomeoneElseVacancies',
  activateServices = 'activateServices',
  openResumeContacts = 'openResumeContacts',
  editCompanyProfile = 'editCompanyProfile',
  manageUsersLimits = 'manageUsersLimits',
  useSavedCard = 'useSavedCard',
  manageSavedCard = 'manageSavedCard'
}
