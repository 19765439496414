import { log } from '@alliance/shared/logger'
import { parseISO } from './parse-iso'

export const getDateFromString = (value: string): Date | null => {
  if (!value) {
    return null
  }
  try {
    const parsedDate = parseISO(value)
    return isNaN(parsedDate.getTime()) ? null : parsedDate
  } catch (e) {
    log.log({ where: 'shared-datetime-date-from-string: getDateFromString helper', message: 'error parsing date from string', error: e })
    return null
  }
}
