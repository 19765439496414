import { DetectPlatformService, NgZoneHelperService, retryWhenStrategy } from '@alliance/shared/utils'
import { Injectable } from '@angular/core'
import { from, Observable, of } from 'rxjs'
import { catchError, retryWhen } from 'rxjs/operators'
import { OpenModalHelperService } from './open-modal-helper.service'

@Injectable()
export class LazyLoadHelperService {
  public constructor(private platform: DetectPlatformService, private openModalHelperService: OpenModalHelperService, private ngZoneHelper: NgZoneHelperService) {}

  public safeLazyModuleLoad$<T = unknown>(moduleImport: Promise<T>, options: { withReloadModal?: boolean; retryAttempts?: number } = {}): Observable<T | null> {
    const { withReloadModal = true, retryAttempts = 2 } = options

    return from(moduleImport).pipe(
      retryWhen(retryWhenStrategy(retryAttempts)),
      catchError(() => {
        if (this.platform.isBrowser && withReloadModal) {
          this.openModalHelperService.showConfirmReloadModal()
        }
        return of(null)
      }),
      this.ngZoneHelper.outsideNgZone<T | null>()
    )
  }
}
