/* tslint:disable */
/* eslint-disable */
/* Code generated by ng-openapi-gen DO NOT EDIT. */

import { HttpClient, HttpContext } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { Observable } from 'rxjs'
import { map } from 'rxjs/operators'

import { BaseService } from '../base-service'
import { TagsSuggesterDataAccessConfiguration } from '../tags-suggester-data-access-configuration'
import { StrictHttpResponse } from '../strict-http-response'

import { DutyResponse } from '../models/duty-response'
import { SimilarTagModel } from '../models/similar-tag-model'
import { topDutiesByNameEndpointCvDutiesGet } from '../fn/top-for-cv/top-duties-by-name-endpoint-cv-duties-get'
import { topDutiesByNameEndpointCvDutiesGet_1 } from '../fn/top-for-cv/top-duties-by-name-endpoint-cv-duties-get-1'
import { TopDutiesByNameEndpointCvDutiesGet_1$Params } from '../fn/top-for-cv/top-duties-by-name-endpoint-cv-duties-get-1'
import { TopDutiesByNameEndpointCvDutiesGet$Params } from '../fn/top-for-cv/top-duties-by-name-endpoint-cv-duties-get'
import { topSkillsByNameEndpointCvNameGet } from '../fn/top-for-cv/top-skills-by-name-endpoint-cv-name-get'
import { topSkillsByNameEndpointCvNameGet_1 } from '../fn/top-for-cv/top-skills-by-name-endpoint-cv-name-get-1'
import { TopSkillsByNameEndpointCvNameGet_1$Params } from '../fn/top-for-cv/top-skills-by-name-endpoint-cv-name-get-1'
import { TopSkillsByNameEndpointCvNameGet$Params } from '../fn/top-for-cv/top-skills-by-name-endpoint-cv-name-get'

@Injectable({ providedIn: 'root' })
export class TagsSuggesterTopForCvService extends BaseService {
  constructor(config: TagsSuggesterDataAccessConfiguration, http: HttpClient) {
    super(config, http)
  }

  /** Path part for operation `topSkillsByNameEndpointCvNameGet()` */
  static readonly TopSkillsByNameEndpointCvNameGetPath = '/CV/name/'

  /**
   * Find closest skills to profession.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `topSkillsByNameEndpointCvNameGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  topSkillsByNameEndpointCvNameGet$Response(params: TopSkillsByNameEndpointCvNameGet$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<SimilarTagModel>>> {
    return topSkillsByNameEndpointCvNameGet(this.http, this.rootUrl, params, context)
  }

  /**
   * Find closest skills to profession.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `topSkillsByNameEndpointCvNameGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  topSkillsByNameEndpointCvNameGet(params: TopSkillsByNameEndpointCvNameGet$Params, context?: HttpContext): Observable<Array<SimilarTagModel>> {
    return this.topSkillsByNameEndpointCvNameGet$Response(params, context).pipe(map((r: StrictHttpResponse<Array<SimilarTagModel>>): Array<SimilarTagModel> => r.body))
  }

  /** Path part for operation `topSkillsByNameEndpointCvNameGet_1()` */
  static readonly TopSkillsByNameEndpointCvNameGet_1Path = '/CV/name'

  /**
   * Find closest skills to profession.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `topSkillsByNameEndpointCvNameGet_1()` instead.
   *
   * This method doesn't expect any request body.
   */
  topSkillsByNameEndpointCvNameGet_1$Response(params: TopSkillsByNameEndpointCvNameGet_1$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<SimilarTagModel>>> {
    return topSkillsByNameEndpointCvNameGet_1(this.http, this.rootUrl, params, context)
  }

  /**
   * Find closest skills to profession.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `topSkillsByNameEndpointCvNameGet_1$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  topSkillsByNameEndpointCvNameGet_1(params: TopSkillsByNameEndpointCvNameGet_1$Params, context?: HttpContext): Observable<Array<SimilarTagModel>> {
    return this.topSkillsByNameEndpointCvNameGet_1$Response(params, context).pipe(map((r: StrictHttpResponse<Array<SimilarTagModel>>): Array<SimilarTagModel> => r.body))
  }

  /** Path part for operation `topDutiesByNameEndpointCvDutiesGet()` */
  static readonly TopDutiesByNameEndpointCvDutiesGetPath = '/CV/duties/'

  /**
   * Find closest duties to profession.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `topDutiesByNameEndpointCvDutiesGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  topDutiesByNameEndpointCvDutiesGet$Response(params?: TopDutiesByNameEndpointCvDutiesGet$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<DutyResponse>>> {
    return topDutiesByNameEndpointCvDutiesGet(this.http, this.rootUrl, params, context)
  }

  /**
   * Find closest duties to profession.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `topDutiesByNameEndpointCvDutiesGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  topDutiesByNameEndpointCvDutiesGet(params?: TopDutiesByNameEndpointCvDutiesGet$Params, context?: HttpContext): Observable<Array<DutyResponse>> {
    return this.topDutiesByNameEndpointCvDutiesGet$Response(params, context).pipe(map((r: StrictHttpResponse<Array<DutyResponse>>): Array<DutyResponse> => r.body))
  }

  /** Path part for operation `topDutiesByNameEndpointCvDutiesGet_1()` */
  static readonly TopDutiesByNameEndpointCvDutiesGet_1Path = '/CV/duties'

  /**
   * Find closest duties to profession.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `topDutiesByNameEndpointCvDutiesGet_1()` instead.
   *
   * This method doesn't expect any request body.
   */
  topDutiesByNameEndpointCvDutiesGet_1$Response(params?: TopDutiesByNameEndpointCvDutiesGet_1$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<DutyResponse>>> {
    return topDutiesByNameEndpointCvDutiesGet_1(this.http, this.rootUrl, params, context)
  }

  /**
   * Find closest duties to profession.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `topDutiesByNameEndpointCvDutiesGet_1$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  topDutiesByNameEndpointCvDutiesGet_1(params?: TopDutiesByNameEndpointCvDutiesGet_1$Params, context?: HttpContext): Observable<Array<DutyResponse>> {
    return this.topDutiesByNameEndpointCvDutiesGet_1$Response(params, context).pipe(map((r: StrictHttpResponse<Array<DutyResponse>>): Array<DutyResponse> => r.body))
  }
}
