import { Injectable } from '@angular/core'

/**
 * Global configuration for SharedAuthDataAccess services
 */
@Injectable({
  providedIn: 'root'
})
export class SharedAuthDataAccessConfiguration {
  rootUrl = 'https://auth-api.robota.ua'
}

export interface SharedAuthDataAccessConfigurationInterface {
  rootUrl?: string
}
