import { Injectable } from '@angular/core'
import { Observable } from 'rxjs'
import { map } from 'rxjs/operators'
import { parse } from '@alliance/shared/datetime'
import { ServiceType, VacancyPublicationType } from '@alliance/shared/domain-gql'
import { ActivatePublicationPackageItemFragment, ActiveVacancyPublicationItemFragment, EmployerVacancyServicesService, SuitablePublicationService } from '@alliance/employer/gql-domain'

type AnyActivatedService = ActiveVacancyPublicationItemFragment | ActivatePublicationPackageItemFragment
type ActivatedServiceTypeName = AnyActivatedService['__typename']

export type ExpiringServicePackageServiceType =
  | ServiceType.ProfessionalSingle
  | ServiceType.Professional
  | ServiceType.FreeBusiness
  | ServiceType.Business
  | ServiceType.BusinessSingle
  | ServiceType.Optimum
  | ServiceType.OptimumSingle
  | ServiceType.Test
  | ServiceType.Anonymous

export type ExpiringServicePackagePublicationType = `${VacancyPublicationType}` | ExpiringServicePackageServiceType

const EXPIRING_SERVICE_PACKAGE_TYPE_NAMES: Array<ExpiringServicePackageServiceType | ExpiringServicePackagePublicationType> = [
  VacancyPublicationType.Test,
  VacancyPublicationType.Anonymous,
  VacancyPublicationType.Business,
  VacancyPublicationType.Professional,
  VacancyPublicationType.Optimum,
  ServiceType.ProfessionalSingle,
  ServiceType.Professional,
  ServiceType.Business,
  ServiceType.FreeBusiness,
  ServiceType.BusinessSingle,
  ServiceType.Optimum,
  ServiceType.OptimumSingle,
  ServiceType.Anonymous,
  ServiceType.Test
]

export interface ExpiringServicePackage {
  readonly name: string
  readonly expiresAt: number
  readonly publicationType: ExpiringServicePackageServiceType
  readonly publications: { count: number }
}

type ActiveService = ActiveVacancyPublicationItemFragment
type ActiveServicePackage = ActivatePublicationPackageItemFragment
type AnyActiveService = ActiveService | ActiveServicePackage

const ACTIVATED_VACANCY_PACKAGE_SERVICE_TYPE_NAME: ActivatedServiceTypeName = 'ActivatedVacancyPackageService'
const ACTIVATED_VACANCY_PUBLICATION_SERVICE_TYPE_NAME: ActivatedServiceTypeName = 'ActivatedVacancyPublicationService'

const isActiveService = (service: SuitablePublicationService): service is AnyActiveService =>
  service.__typename === ACTIVATED_VACANCY_PACKAGE_SERVICE_TYPE_NAME || service.__typename === ACTIVATED_VACANCY_PUBLICATION_SERVICE_TYPE_NAME

const isExpiringServicePublicationType = (type: ServiceType): type is ExpiringServicePackageServiceType => EXPIRING_SERVICE_PACKAGE_TYPE_NAMES.some(name => name === type)

const pickByPublicationType = (services: AnyActiveService[]): AnyActiveService[] =>
  services.filter(service => service.publicationType && EXPIRING_SERVICE_PACKAGE_TYPE_NAMES.includes(service.publicationType))

const pickServicesWithPublications = (services: AnyActiveService[]): AnyActiveService[] => services.filter(service => service.availableCount && service.availableCount > 0)

const EXPIRATION_DATE_FORMAT = 'yyyy-MM-dd'

const getExpiringServicePackages = (services: AnyActiveService[]): ExpiringServicePackage[] => {
  const expiringServicePackages: ExpiringServicePackage[] = []
  const referenceDate = new Date()

  for (const service of services) {
    const { name, availableCount, type } = service
    if (!type) {
      continue
    }

    if (!isExpiringServicePublicationType(type)) {
      continue
    }

    const endedAt = service.endedAt ?? ''
    const date = parse(endedAt, EXPIRATION_DATE_FORMAT, referenceDate)

    expiringServicePackages.push({
      name: name || '',
      expiresAt: date.getTime(),
      publicationType: type,
      publications: {
        count: availableCount || 0
      }
    })
  }

  return expiringServicePackages
}

@Injectable({
  providedIn: 'root'
})
export class ExpiringServicePackageFactoryService {
  public constructor(private readonly employerVacancyServicesService: EmployerVacancyServicesService) {}

  public getExpiringEmployerServicePackages(): Observable<ExpiringServicePackage[]> {
    return this.getActiveServices().pipe(
      map(services => pickServicesWithPublications(services)),
      map(services => pickByPublicationType(services)),
      map(services => getExpiringServicePackages(services))
    )
  }

  public hasAvailablePublications(): Observable<boolean> {
    return this.getActiveServices().pipe(
      map(services => pickServicesWithPublications(services)),
      map(services => services.length > 0)
    )
  }

  private getActiveServices(): Observable<AnyActiveService[]> {
    return this.getServices().pipe(map(services => services.filter(isActiveService)))
  }

  private getServices(): Observable<SuitablePublicationService[]> {
    return this.employerVacancyServicesService.select('publicationServices')
  }
}
