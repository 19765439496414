import { SharedTranslationModule } from '@alliance/shared/translation'
import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { HTTP_INTERCEPTORS } from '@angular/common/http'
import { RestrictedByRightErrorInterceptorService } from './middlewares/restricted-by-right-error-interceptor.service'

@NgModule({
  imports: [CommonModule, SharedTranslationModule],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: RestrictedByRightErrorInterceptorService,
      multi: true
    }
  ]
})
export class EmployerInterceptorsRestrictedByRightMiddlewareModule {}
