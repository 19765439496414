/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http'
import { Observable } from 'rxjs'
import { filter, map } from 'rxjs/operators'
import { StrictHttpResponse } from '../../strict-http-response'
import { RequestBuilder } from '../../request-builder'

import { ConversationCountersDto } from '../../models/conversation-counters-dto'

export interface ConversationGetConversationCounters$Json$Params {}

export function conversationGetConversationCounters$Json(
  http: HttpClient,
  rootUrl: string,
  params?: ConversationGetConversationCounters$Json$Params,
  context?: HttpContext
): Observable<StrictHttpResponse<ConversationCountersDto>> {
  const rb = new RequestBuilder(rootUrl, conversationGetConversationCounters$Json.PATH, 'get')
  if (params) {
  }

  return http.request(rb.build({ responseType: 'json', accept: 'text/json', context })).pipe(
    filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
    map((r: HttpResponse<any>) => {
      return r as StrictHttpResponse<ConversationCountersDto>
    })
  )
}

conversationGetConversationCounters$Json.PATH = '/v2/conversations/counters'
