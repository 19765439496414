import { Directive, OnDestroy, Input, Output, EventEmitter, ElementRef, Inject } from '@angular/core'

import { WA_MUTATION_OBSERVER_INIT } from '../tokens/mutation-observer-init'
import { mutationObserverInitFactory } from '../utils/mutation-observer-init-factory'
import { SafeObserver } from '../classes/safe-observer'

@Directive({
  selector: '[allianceMutationObserver]',
  providers: [
    {
      provide: WA_MUTATION_OBSERVER_INIT,
      useFactory: mutationObserverInitFactory
    }
  ],
  exportAs: 'MutationObserver'
})
export class WaMutationObserverDirective extends SafeObserver implements OnDestroy {
  @Input()
  public attributeFilter = ''

  @Input()
  public attributeOldValue = '' as const

  @Input()
  public attributes = '' as const

  @Input()
  public characterData = '' as const

  @Input()
  public characterDataOldValue = '' as const

  @Input()
  public childList = '' as const

  @Input()
  public subtree = '' as const

  @Output()
  public readonly allianceMutationObserver = new EventEmitter<MutationRecord[]>()

  public constructor(private elementRef: ElementRef<HTMLElement>, @Inject(WA_MUTATION_OBSERVER_INIT) private config: MutationObserverInit) {
    super(records => {
      this.allianceMutationObserver.emit(records)
    })

    this.observe(this.elementRef.nativeElement, this.config)
  }

  public ngOnDestroy(): void {
    this.disconnect()
  }
}
