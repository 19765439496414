/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core'
import { HttpClient, HttpResponse, HttpContext } from '@angular/common/http'
import { BaseService } from '../base-service'
import { ApplyApiDataAccessConfiguration } from '../apply-api-data-access-configuration'
import { StrictHttpResponse } from '../strict-http-response'
import { RequestBuilder } from '../request-builder'
import { Observable } from 'rxjs'
import { map, filter } from 'rxjs/operators'

@Injectable({
  providedIn: 'root'
})
export class ApplyAbTestsService extends BaseService {
  public constructor(config: ApplyApiDataAccessConfiguration, http: HttpClient) {
    super(config, http)
  }

  /**
   * Path part for operation abTestsGet
   */
  public static readonly AbTestsGetPath = '/ab-tests'

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `abTestsGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  abTestsGet$Response(params?: { notebookId?: number; context?: HttpContext }): Observable<StrictHttpResponse<void>> {
    const rb = new RequestBuilder(this.rootUrl, ApplyAbTestsService.AbTestsGetPath, 'get')
    if (params) {
      rb.query('notebookId', params.notebookId, {})
    }

    return this.http
      .request(
        rb.build({
          responseType: 'text',
          accept: '*/*',
          context: params?.context
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>
        })
      )
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `abTestsGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  abTestsGet(params?: { notebookId?: number; context?: HttpContext }): Observable<void> {
    return this.abTestsGet$Response(params).pipe(map((r: StrictHttpResponse<void>) => r.body as void))
  }

  /**
   * Path part for operation abTestsNewPageLastImportTasksGet
   */
  public static readonly AbTestsNewPageLastImportTasksGetPath = '/ab-tests/new-page/last-import-tasks'

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `abTestsNewPageLastImportTasksGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  abTestsNewPageLastImportTasksGet$Response(params?: { context?: HttpContext }): Observable<StrictHttpResponse<void>> {
    const rb = new RequestBuilder(this.rootUrl, ApplyAbTestsService.AbTestsNewPageLastImportTasksGetPath, 'get')
    if (params) {
    }

    return this.http
      .request(
        rb.build({
          responseType: 'text',
          accept: '*/*',
          context: params?.context
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>
        })
      )
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `abTestsNewPageLastImportTasksGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  abTestsNewPageLastImportTasksGet(params?: { context?: HttpContext }): Observable<void> {
    return this.abTestsNewPageLastImportTasksGet$Response(params).pipe(map((r: StrictHttpResponse<void>) => r.body as void))
  }

  /**
   * Path part for operation abTestsNewPageLastImportTasksDelete
   */
  public static readonly AbTestsNewPageLastImportTasksDeletePath = '/ab-tests/new-page/last-import-tasks'

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `abTestsNewPageLastImportTasksDelete()` instead.
   *
   * This method doesn't expect any request body.
   */
  abTestsNewPageLastImportTasksDelete$Response(params?: { context?: HttpContext }): Observable<StrictHttpResponse<void>> {
    const rb = new RequestBuilder(this.rootUrl, ApplyAbTestsService.AbTestsNewPageLastImportTasksDeletePath, 'delete')
    if (params) {
    }

    return this.http
      .request(
        rb.build({
          responseType: 'text',
          accept: '*/*',
          context: params?.context
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>
        })
      )
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `abTestsNewPageLastImportTasksDelete$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  abTestsNewPageLastImportTasksDelete(params?: { context?: HttpContext }): Observable<void> {
    return this.abTestsNewPageLastImportTasksDelete$Response(params).pipe(map((r: StrictHttpResponse<void>) => r.body as void))
  }

  /**
   * Path part for operation abTestsNewPagePut
   */
  public static readonly AbTestsNewPagePutPath = '/ab-tests/new-page'

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `abTestsNewPagePut()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  abTestsNewPagePut$Response(params?: { showNewApplyPage?: boolean; forceImport?: boolean; context?: HttpContext; body?: Array<number> }): Observable<StrictHttpResponse<void>> {
    const rb = new RequestBuilder(this.rootUrl, ApplyAbTestsService.AbTestsNewPagePutPath, 'put')
    if (params) {
      rb.query('showNewApplyPage', params.showNewApplyPage, {})
      rb.query('forceImport', params.forceImport, {})
      rb.body(params.body, 'application/*+json')
    }

    return this.http
      .request(
        rb.build({
          responseType: 'text',
          accept: '*/*',
          context: params?.context
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>
        })
      )
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `abTestsNewPagePut$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  abTestsNewPagePut(params?: { showNewApplyPage?: boolean; forceImport?: boolean; context?: HttpContext; body?: Array<number> }): Observable<void> {
    return this.abTestsNewPagePut$Response(params).pipe(map((r: StrictHttpResponse<void>) => r.body as void))
  }

  /**
   * Path part for operation abTestsNewPageNotebookIdGet
   */
  public static readonly AbTestsNewPageNotebookIdGetPath = '/ab-tests/new-page/{notebookId}'

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `abTestsNewPageNotebookIdGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  abTestsNewPageNotebookIdGet$Response(params: { notebookId: number; context?: HttpContext }): Observable<StrictHttpResponse<void>> {
    const rb = new RequestBuilder(this.rootUrl, ApplyAbTestsService.AbTestsNewPageNotebookIdGetPath, 'get')
    if (params) {
      rb.path('notebookId', params.notebookId, {})
    }

    return this.http
      .request(
        rb.build({
          responseType: 'text',
          accept: '*/*',
          context: params?.context
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>
        })
      )
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `abTestsNewPageNotebookIdGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  abTestsNewPageNotebookIdGet(params: { notebookId: number; context?: HttpContext }): Observable<void> {
    return this.abTestsNewPageNotebookIdGet$Response(params).pipe(map((r: StrictHttpResponse<void>) => r.body as void))
  }
}
