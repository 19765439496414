import { Environment } from '@alliance/shared/environment'
import { log } from '@alliance/shared/logger'
import { DetectPlatformService, isJwtTokenExpired } from '@alliance/shared/utils'
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { Observable, switchMap } from 'rxjs'
import { AuthService } from '../authentication/auth.service'

@Injectable()
export class BearerInterceptor implements HttpInterceptor {
  private readonly allowedUrls = [
    this.env.notificationApi,
    this.env.chatApi,
    this.env.socketApi,
    this.env.adminApi,
    this.env.graphqlApi,
    this.env.graphqlLimitsApi,
    this.env.vacancyApi,
    this.env.applyApi,
    this.env.promoApi,
    this.env.employerAppsApi,
    this.env.employerServiceApi,
    this.env.paymentApi,
    this.env.employerApi,
    this.env.jobseekerApi,
    this.env.jobseekerApiUk,
    this.env.multiUserStatisticsApi,
    this.env.companyEmployeesServiceApi,
    this.env.companyServiceApi,
    this.env.clubsApi,
    this.env.feedbackApi,
    this.env.bannerApi,
    this.env.marketingApi,
    this.env.usersApi
  ].map(url => {
    try {
      return url ? new URL(url).origin : url
    } catch (e) {
      log.warn({ where: 'auth-api: BearerInterceptor', category: 'try_catch', message: 'allowedUrls failed', error: e })
      return ''
    }
  })

  public constructor(private authService: AuthService, private env: Environment, private platform: DetectPlatformService) {}

  public intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    if (this.platform.isServer) {
      return next.handle(request)
    }

    let requestUrlOrigin = ''

    try {
      requestUrlOrigin = new URL(request.url).origin
    } catch (e) {
      log.log({ where: 'auth-api: BearerInterceptor', category: 'try_catch', message: 'intercept: getting requestUrlOrigin failed', error: e })
    }

    const token = this.authService.token
    const allowedUrl = this.allowedUrls.includes(requestUrlOrigin)

    if (!token || !allowedUrl) {
      return next.handle(request)
    }

    if (isJwtTokenExpired(token)) {
      return this.authService.refresh().pipe(switchMap(() => next.handle(this.cloneRequestWithToken(request, this.authService.token ?? token))))
    }
    return next.handle(this.cloneRequestWithToken(request, token))
  }

  private cloneRequestWithToken(request: HttpRequest<unknown>, token: string): HttpRequest<unknown> {
    return request.clone({
      setHeaders: {
        Authorization: 'Bearer ' + token
      }
    })
  }
}
