import { EmployeeScopesChangedSocketMessage, EmployeeStateChangedSocketMessage } from '@alliance/employer/data-access'
import { SignalrMessageService, StreamTypeMap } from '@alliance/socket/api'
import { AccountLinkMapService } from '@alliance/shared/feature-account-link-map'
import { AuthService } from '@alliance/shared/auth/api'
import { CompanyStateEnum, EmployeeRole, EmployeeRoleBasedScopeEnum } from '@alliance/shared/domain-gql'
import { AccountLinksEnum } from '@alliance/shared/constants'
import { log } from '@alliance/shared/logger'
import { RxStateService } from '@alliance/shared/models'
import { deepEqual, retryWhenStrategy } from '@alliance/shared/utils'
import { Injectable } from '@angular/core'
import { QueryRef } from 'apollo-angular'
import { Observable, of } from 'rxjs'
import { audit, catchError, distinctUntilChanged, filter, finalize, first, map, pairwise, pluck, retryWhen, share, switchMap, take, takeUntil } from 'rxjs/operators'
import {
  CurrentEmployerFragment,
  CurrentEmployerRightFragment,
  CurrentEmployerStateFragment,
  GetCurrentEmployerStateGQL,
  GetCurrentEmployerStateQuery,
  GetCurrentEmployerStateQueryVariables
} from './employer-rights.generated'
import { EmployerAccessByRightsEnum } from './employer-rights-enum'

@Injectable({
  providedIn: 'root'
})
export class EmployerRightsService extends RxStateService<{
  employer: CurrentEmployerFragment | null
  isMain: boolean
  isRegistrationConfirmed: boolean
  tokenIsRefreshing: boolean
}> {
  private employerStateQueryRef: QueryRef<GetCurrentEmployerStateQuery, GetCurrentEmployerStateQueryVariables> | undefined

  private readonly getEmployerRightStrategyMap: Record<EmployerAccessByRightsEnum, (employer: CurrentEmployerFragment) => boolean> = {
    [EmployerAccessByRightsEnum.manageUsers]: (employer: CurrentEmployerFragment) =>
      employer.role === EmployeeRole.Main || this.hasRightInScopes(EmployeeRoleBasedScopeEnum.UsersManagement, employer.scopes ?? []),
    [EmployerAccessByRightsEnum.viewSomeoneElseVacancies]: (employer: CurrentEmployerFragment) =>
      employer.role === EmployeeRole.Main || this.hasRightInScopes(EmployeeRoleBasedScopeEnum.ViewingVacanciesAppliesOfOthers, employer.scopes ?? []),
    [EmployerAccessByRightsEnum.activateServices]: (employer: CurrentEmployerFragment) =>
      employer.role === EmployeeRole.Main || this.hasRightInScopes(EmployeeRoleBasedScopeEnum.ServiceManagement, employer.scopes ?? []),
    [EmployerAccessByRightsEnum.publishVacanciesWithoutApproval]: (employer: CurrentEmployerFragment) =>
      employer.role === EmployeeRole.Main || this.hasRightInScopes(EmployeeRoleBasedScopeEnum.SelfDependentPublication, employer.scopes ?? []),
    [EmployerAccessByRightsEnum.manageSomeoneElseVacancies]: (employer: CurrentEmployerFragment) =>
      employer.role === EmployeeRole.Main || this.hasRightInScopes(EmployeeRoleBasedScopeEnum.VacancyManagementOfOthers, employer.scopes ?? []),
    [EmployerAccessByRightsEnum.openResumeContacts]: (employer: CurrentEmployerFragment) =>
      employer.role === EmployeeRole.Main || this.hasRightInScopes(EmployeeRoleBasedScopeEnum.CvdbContactsOpening, employer.scopes ?? []),
    [EmployerAccessByRightsEnum.editCompanyProfile]: (employer: CurrentEmployerFragment) =>
      employer.role === EmployeeRole.Main || this.hasRightInScopes(EmployeeRoleBasedScopeEnum.CompanyInfoEditing, employer.scopes ?? []),
    [EmployerAccessByRightsEnum.manageUsersLimits]: (employer: CurrentEmployerFragment) =>
      !!employer.company?.hasServicesLimitAccess && (employer.role === EmployeeRole.Main || this.hasRightInScopes(EmployeeRoleBasedScopeEnum.LimitsManagement, employer.scopes ?? [])),
    [EmployerAccessByRightsEnum.useSavedCard]: (employer: CurrentEmployerFragment) =>
      employer.role === EmployeeRole.Main || this.hasRightInScopes(EmployeeRoleBasedScopeEnum.SavedCardUsage, employer.scopes ?? []),
    [EmployerAccessByRightsEnum.manageSavedCard]: (employer: CurrentEmployerFragment) =>
      employer.role === EmployeeRole.Main || this.hasRightInScopes(EmployeeRoleBasedScopeEnum.SavedCardManagement, employer.scopes ?? [])
  }

  public constructor(
    private authService: AuthService,
    private accountLinkMapService: AccountLinkMapService,
    private signalrMessageService: SignalrMessageService,
    private getCurrentEmployerStateGQL: GetCurrentEmployerStateGQL
  ) {
    super()

    this.initState({
      tokenIsRefreshing: false,
      employer: this.authService.token$.pipe(
        map(token => !!token && this.authService.isEmployer),
        filter(isLoggedEmployer => isLoggedEmployer),
        switchMap(() =>
          this.getCurrentEmployerState$().pipe(
            map(state => {
              const isUserAuthorized = this.getIsUserAuthorized(state)

              if (!isUserAuthorized) {
                this.hold(this.authService.logout(), () => this.accountLinkMapService.proceedAction([AccountLinksEnum.login]))
              }

              return state?.employer ?? null
            })
          )
        )
      ),
      isMain: this.select('employer').pipe(map(employer => employer?.role === EmployeeRole.Main)),
      isRegistrationConfirmed: this.select('employer').pipe(map(employer => !!employer?.company?.hasConfirmedContactEmail))
    })
  }

  public updateEmployerState(): void {
    this.getEmployerStateQueryRef().refetch()
  }

  public hasRightTo$(right: EmployerAccessByRightsEnum): Observable<boolean> {
    return this.select('employer').pipe(map(employer => !!employer && this.getEmployerRightStrategyMap[right](employer)))
  }

  public rightsList$(): Observable<EmployerAccessByRightsEnum[]> {
    return this.select('employer').pipe(
      filter(Boolean),
      map(employer =>
        Object.entries(this.getEmployerRightStrategyMap).reduce<EmployerAccessByRightsEnum[]>((acc, [key, func]) => (func(employer) ? [...acc, key as EmployerAccessByRightsEnum] : acc), [])
      )
    )
  }

  public rightsChanged$(): Observable<boolean> {
    return this.select('employer').pipe(
      filter(Boolean),
      pluck('scopes'),
      pairwise(),
      map(([old, current]) => !deepEqual(old, current)),
      filter(Boolean),
      share()
    )
  }

  public subscribeToEmployerRightsChanges(): void {
    this.hold(
      this.authService.token$.pipe(
        map(token => !!token && this.authService.isEmployer),
        distinctUntilChanged(),
        filter(Boolean)
      ),
      () => {
        this.listenToEmployerScopesChanges()
        this.listenToEmployerStateChanges()
      }
    )
  }

  private listenToEmployerScopesChanges(): void {
    this.hold(
      this.signalrMessageService.getStreamByType<EmployeeScopesChangedSocketMessage>(StreamTypeMap.employerScopesChanged).pipe(
        audit(() => this.select('tokenIsRefreshing').pipe(first(tokenIsRefreshing => !tokenIsRefreshing))),
        takeUntil(this.isLogout$())
      ),
      () => this.updateEmployerState()
    )
  }

  private listenToEmployerStateChanges(): void {
    this.hold(this.signalrMessageService.getStreamByType<EmployeeStateChangedSocketMessage>(StreamTypeMap.employerStateChanged).pipe(takeUntil(this.isLogout$())), stateChangedSocketMessage => {
      switch (stateChangedSocketMessage.actionContext) {
        case 'BecameAsMain':
        case 'BecameAsSubordinate':
          this.set({ tokenIsRefreshing: true })
          this.hold(this.authService.refresh().pipe(finalize(() => this.set({ tokenIsRefreshing: false }))))
          break
        case 'Blocked':
        case 'Deleted':
        case 'PasswordChanged':
          this.hold(this.authService.logout(), () => this.accountLinkMapService.proceedAction([AccountLinksEnum.login]))
          break
        default:
          log.warn({
            where: 'employer-domain: EmployerRightsService',
            category: 'unexpected_value',
            message: 'unknown employer state in socket message',
            actionContext: stateChangedSocketMessage?.actionContext
          })
          break
      }
    })
  }

  private isLogout$(): Observable<boolean> {
    return this.authService.token$.pipe(
      map(token => !token || !this.authService.isEmployer),
      filter(Boolean),
      take(1)
    )
  }

  private getEmployerStateQueryRef(): QueryRef<GetCurrentEmployerStateQuery, GetCurrentEmployerStateQueryVariables> {
    if (!this.employerStateQueryRef) {
      this.employerStateQueryRef = this.getCurrentEmployerStateGQL.watch({}, { fetchPolicy: 'network-only' })
    }

    return this.employerStateQueryRef
  }

  private hasRightInScopes(right: EmployeeRoleBasedScopeEnum, scopes: readonly CurrentEmployerRightFragment[]): boolean {
    return !!(scopes || []).find(scope => scope.role === right)
  }

  private getCurrentEmployerState$(): Observable<CurrentEmployerStateFragment | null> {
    return this.getEmployerStateQueryRef().valueChanges.pipe(
      map(({ data }) => data?.getCurrentEmployer ?? null),
      retryWhen(retryWhenStrategy()),
      catchError(() => of(null))
    )
  }

  private getIsUserAuthorized(state: CurrentEmployerStateFragment | null): boolean {
    return !!(state && state?.isSuccess && !(state.employer?.company?.companyState === CompanyStateEnum.BlackList))
  }
}
