import { UrlMatchResult, UrlSegment } from '@angular/router'

export const SERVICE_PAGE_ROUTE_MATCHER = (segments: UrlSegment[]): UrlMatchResult => {
  const path = segments[0]?.path

  if (path) {
    return { consumed: segments, posParams: { serviceType: new UrlSegment(path, {}) } }
  }

  return { consumed: segments }
}
