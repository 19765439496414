import { Injectable } from '@angular/core'
import { LocalStorage } from '@alliance/shared/storage'
import { safeJsonParse } from '@alliance/shared/utils'
import { CvdbSearchCity } from './models/cvdb-search-city'
import { CvdbSearchCityKey } from './constants/cvdb-search-city'

@Injectable({
  providedIn: 'root'
})
export class CvdbSearchedCityService {
  public constructor(private localStorage: LocalStorage) {}

  public get cvdbSearchedCity(): CvdbSearchCity | null {
    const parsedValue = safeJsonParse(this.localStorage.getItem(CvdbSearchCityKey), {}, 'cvdb-searched-city.service - cvdbSearchedCity')
    return this.isValidCity(parsedValue) ? parsedValue : null
  }

  public set cvdbSearchedCity(city: CvdbSearchCity | null) {
    if (!city) {
      return
    }
    this.localStorage.setItem(CvdbSearchCityKey, JSON.stringify(city))
  }

  private isValidCity(value: unknown): value is CvdbSearchCity {
    return typeof value === 'object' && !!value && 'id' in value && 'inside' in value
  }
}
