import { Injectable } from '@angular/core'
import { AccountJobsearcherService, AttachInfo, ResumeService } from '@alliance/jobseeker/data-access'
import { Observable } from 'rxjs'
import { map } from 'rxjs/operators'

@Injectable({ providedIn: 'root' })
export class JobseekerResumesService {
  public constructor(private resumeService: ResumeService, private accountService: AccountJobsearcherService) {}

  public makeAnonymResume(id: number): Observable<unknown> {
    return this.resumeService.resumeStatePost({
      resumeId: id,
      data: {
        resumeId: id,
        anonymous: true
      }
    })
  }

  public getResumeArrayLength(): Observable<number> {
    return this.resumeService.resumeGetJobseekerResumeList().pipe(map(list => list.length))
  }

  public getAttachList(email?: string): Observable<AttachInfo[]> {
    return this.accountService.getAttachList(email)
  }
}
