import { Injectable } from '@angular/core'
import { retryWhenStrategy } from '@alliance/shared/utils'
import { Observable, of } from 'rxjs'
import { catchError, filter, map, retryWhen, take } from 'rxjs/operators'
import { CityDistrictDictionaryItemFragment, GetDistrictsDictionaryGQL } from './district-dictionary.generated'

@Injectable({
  providedIn: 'root'
})
export class DistrictDictionaryService {
  public constructor(private districtsDictionaryGQL: GetDistrictsDictionaryGQL) {}

  public getDistricts$(cityId: string): Observable<CityDistrictDictionaryItemFragment[]> {
    return this.districtsDictionaryGQL.fetch({ cityId }, { fetchPolicy: 'cache-first' }).pipe(
      map(({ data }) => (data?.cityDistricts || []).reduce<CityDistrictDictionaryItemFragment[]>((acc, cityDistrict) => (cityDistrict ? [...acc, cityDistrict] : acc), [])),
      retryWhen(retryWhenStrategy()),
      catchError(() => of([]))
    )
  }

  public getDistrictById$(cityId: string, districtId: string): Observable<CityDistrictDictionaryItemFragment | null> {
    return this.getDistricts$(cityId).pipe(
      filter(districts => !!districts?.length),
      take(1),
      map(districts => districts.find(district => district.id === districtId) || null)
    )
  }
}
