import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http'
import { Observable } from 'rxjs'
import { filter, map } from 'rxjs/operators'
import { StrictHttpResponse } from '../../strict-http-response'
import { RequestBuilder } from '../../request-builder'

import { VacancyApiUploadContactPhotoOutput } from '../../models/vacancy-api-upload-contact-photo-output'

export interface ContactPhotoPost$Json$Params {
  body?: {
    file?: Blob
  }
}

export function contactPhotoPost$Json(
  http: HttpClient,
  rootUrl: string,
  params?: ContactPhotoPost$Json$Params,
  context?: HttpContext
): Observable<StrictHttpResponse<VacancyApiUploadContactPhotoOutput>> {
  const rb = new RequestBuilder(rootUrl, contactPhotoPost$Json.PATH, 'post')
  if (params) {
    rb.body(params.body, 'multipart/form-data')
  }

  return http.request(rb.build({ responseType: 'json', accept: 'text/json', context })).pipe(
    filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
    map((r: HttpResponse<any>) => {
      return r as StrictHttpResponse<VacancyApiUploadContactPhotoOutput>
    })
  )
}

contactPhotoPost$Json.PATH = '/contact-photo'
