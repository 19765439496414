import { CvDbSearchFacetsResponse, CvDbSearchFacetsResponseV2, FilterItem, FilterItemV2 } from '@alliance/employer/data-access'
import { DictionaryService, LangCode } from '@alliance/jobseeker/api'
import { TranslationService } from '@alliance/shared/translation'
import { Injectable } from '@angular/core'
import { combineLatest, forkJoin, Observable } from 'rxjs'
import { map } from 'rxjs/operators'

type FilterItemV2Map = { [key: string]: FilterItemV2 }

@Injectable({
  providedIn: 'root'
})
export class CvdbFacetsDictionaryService {
  private currentLangKey: LangCode = this.translationService.getCurrentLangFix_DEPRECATED() as LangCode

  public constructor(private dict: DictionaryService, private translationService: TranslationService) {}

  private static listAsMap(list: FilterItemV2[] = []): FilterItemV2Map {
    return list.reduce<FilterItemV2Map>((acc, item) => {
      if (!item?.uniqueId) {
        return acc
      }
      acc[item.uniqueId] = item
      return acc
    }, {})
  }

  public addNames(data: CvDbSearchFacetsResponseV2, cityId?: number): Observable<CvDbSearchFacetsResponse> {
    const _dictionaries$ = forkJoin({
      branches: this.branches$(CvdbFacetsDictionaryService.listAsMap(data.branches)),
      education: this.education$(CvdbFacetsDictionaryService.listAsMap(data.education)),
      experience: this.experience$(CvdbFacetsDictionaryService.listAsMap(data.experience)),
      rubrics: this.rubrics$(CvdbFacetsDictionaryService.listAsMap(data.rubrics)),
      languages: this.languages$(CvdbFacetsDictionaryService.listAsMap(data.languages)),
      schedules: this.schedules$(CvdbFacetsDictionaryService.listAsMap(data.schedules)),
      fillingType: this.fillingTypes$(CvdbFacetsDictionaryService.listAsMap(data.fillingType)),
      districts: this.districts$(CvdbFacetsDictionaryService.listAsMap(data.districts), cityId ?? 0)
    })
    return _dictionaries$.pipe(map(facetsWithNames => ({ ...data, ...facetsWithNames })))
  }

  private branches$(filtersMap: FilterItemV2Map): Observable<FilterItem[]> {
    return this.dict.getBranchList$(this.currentLangKey).pipe(map(branchList => branchList.map(({ value: name, id }) => ({ name, count: filtersMap[id]?.count || 0, uniqueId: id.toString() }))))
  }

  private education$(filtersMap: FilterItemV2Map): Observable<FilterItem[]> {
    return this.dict.getEducationList$(this.currentLangKey).pipe(
      map(eduList => [
        ...eduList.map(({ value: name, id }) => ({ name, count: filtersMap[id]?.count || 0, uniqueId: id.toString() })),
        {
          name: this.translationService.currentLangIsUkrainian() ? 'Сертифікат Цифрограм від Дія. Цифрова освіта' : 'Сертификат Цифрограм от Дія. Цифрова освіта',
          uniqueId: '6',
          count: filtersMap[6]?.count || 0
        }
      ])
    )
  }

  private experience$(filtersMap: FilterItemV2Map): Observable<FilterItem[]> {
    return this.dict.getCvdbExperienceList$(this.currentLangKey).pipe(map(expList => expList.map(({ value: name, id }) => ({ name, count: filtersMap[id]?.count || 0, uniqueId: id.toString() }))))
  }

  private schedules$(filtersMap: FilterItemV2Map): Observable<FilterItem[]> {
    return this.dict.getScheduleList$(this.currentLangKey).pipe(map(schedule => schedule.map(({ value: name, id }) => ({ name, count: filtersMap[id]?.count || 0, uniqueId: id.toString() }))))
  }

  private rubrics$(filtersMap: FilterItemV2Map): Observable<FilterItem[]> {
    return combineLatest([this.dict.getRubricList$(this.currentLangKey), this.dict.getSubRubricList$(this.currentLangKey)]).pipe(
      map(([rubrics, subRubricList]) =>
        rubrics.map(({ value: name, id }) => ({
          name,
          count: filtersMap[id]?.count || 0,
          uniqueId: id.toString(),
          children: subRubricList
            .filter(({ parentId }) => parentId === id)
            .map(({ value: subRubName, id: subRubId }) => ({
              ...((id && (filtersMap[id]?.children || []).find(child => child?.uniqueId && child.uniqueId.split('-').pop() === subRubId.toString())) || {
                uniqueId: `${id}-${subRubId}`,
                count: 0
              }),
              name: subRubName
            }))
        }))
      )
    )
  }

  private languages$(filtersMap: FilterItemV2Map): Observable<FilterItem[]> {
    return combineLatest([this.dict.getLanguageList$(this.currentLangKey), this.dict.getLanguageSkillList$(this.currentLangKey)]).pipe(
      map(([langs, skills]) =>
        langs.map(({ value: name, id }) => ({
          name,
          count: filtersMap[id]?.count || 0,
          uniqueId: id.toString(),
          children: skills.map(({ value: skillName, id: skillId }) => ({
            ...((id && (filtersMap[id]?.children || []).find(child => child?.uniqueId && child.uniqueId.split('-').pop() === skillId.toString())) || {
              uniqueId: `${id}-${skillId}`,
              count: 0
            }),
            name: skillName
          }))
        }))
      )
    )
  }

  private fillingTypes$(filtersMap: FilterItemV2Map): Observable<FilterItem[]> {
    return this.dict.getFillingTypesList$(this.currentLangKey).pipe(map(expList => expList.map(({ value: name, id }) => ({ name, count: filtersMap[id]?.count || 0, uniqueId: id.toString() }))))
  }
  private districts$(filtersMap: FilterItemV2Map, cityId: number): Observable<FilterItem[]> {
    return this.dict.getDistrictsList$(cityId, this.currentLangKey).pipe(map(expList => expList.map(({ value: name, id }) => ({ name, count: filtersMap[id]?.count || 0, uniqueId: id.toString() }))))
  }
}
