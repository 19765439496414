import { VacancyMediaInput, VacancyMediaType } from '@alliance/shared/domain-gql'
import { MediaLibraryViewModel, NotebookService } from '@alliance/employer/data-access'
import { RxStateService } from '@alliance/shared/models'
import { Injectable } from '@angular/core'
import { Observable, of } from 'rxjs'
import { catchError, map, mapTo, shareReplay, take, tap } from 'rxjs/operators'

@Injectable({
  providedIn: 'root'
})
export class CompanyMediaService extends RxStateService<{
  companyMedia: MediaLibraryViewModel[]
}> {
  public constructor(private notebookService: NotebookService) {
    super()

    this.initState({
      companyMedia: this.fetchCompanyMedia$().pipe(shareReplay(1))
    })
  }

  public getCompanyMedia$(): Observable<VacancyMediaInput[]> {
    return this.select('companyMedia').pipe(
      map(mediaList =>
        (mediaList || []).reduce<VacancyMediaInput[]>(
          (acc, media) =>
            media?.url
              ? [
                  ...acc,
                  {
                    url: media.url,
                    type: media?.type === MediaLibraryViewModel.TypeEnum.YoutubeVideo ? VacancyMediaType.YoutubeVideo : VacancyMediaType.UploadedImage
                  }
                ]
              : acc,
          []
        )
      )
    )
  }

  public syncCompanyMedia(): void {
    this.fetchCompanyMedia$()
      .pipe(take(1))
      .subscribe(media => this.set({ companyMedia: media }))
  }

  public deleteMediaFromLibrary$(list: VacancyMediaInput[]): Observable<VacancyMediaInput[]> {
    const { companyMedia } = this.get()
    const idsToDelete = list.reduce<number[]>((acc, current) => {
      const fromCompanyMedia = companyMedia.find(item => item.url === current.url)
      return fromCompanyMedia?.id ? [...acc, fromCompanyMedia.id] : acc
    }, [])

    return this.notebookService.deleteMedia(idsToDelete).pipe(
      mapTo(list),
      catchError(() => of([])),
      tap(() => this.syncCompanyMedia())
    )
  }

  private fetchCompanyMedia$(): Observable<MediaLibraryViewModel[]> {
    return this.notebookService.getMediaLibraryItems({}).pipe(catchError(() => of([])))
  }
}
