import { Injectable } from '@angular/core'
import { Observable, shareReplay } from 'rxjs'

import { assertAs } from '../type-assertions'

@Injectable()
export class CacheListHelperService {
  private readonly cachedListMap = new Map<string, Observable<unknown[]>>()

  public getCachedList<T>(key: string, initialList$: Observable<T[]>): Observable<T[]> {
    const cachedList = this.cachedListMap.get(key)

    if (!cachedList) {
      const list$ = initialList$.pipe(shareReplay())
      this.cachedListMap.set(key, list$)
      return list$
    }

    return assertAs(cachedList)
  }
}
