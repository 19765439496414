export const ExceptionsRuKeywords = [
  {
    keyword: 'администратор гостиници',
    localKeyword: 'администратор гостиницы'
  },
  {
    keyword: 'автоелектрик',
    localKeyword: 'автоэлектрик'
  },
  {
    keyword: 'доставка води',
    localKeyword: 'доставка воды'
  },
  {
    keyword: 'директор елеватора',
    localKeyword: 'директор элеватора'
  },
  {
    keyword: 'директор школи',
    localKeyword: 'директор школы'
  },
  {
    keyword: 'еколог',
    localKeyword: 'эколог'
  },
  {
    keyword: 'екскаваторщик',
    localKeyword: 'экскаваторщик'
  },
  {
    keyword: 'експедитор',
    localKeyword: 'экспедитор'
  },
  {
    keyword: 'експедитор',
    localKeyword: 'экспедитор'
  },
  {
    keyword: 'екструдерщик',
    localKeyword: 'экструдерщик'
  },
  {
    keyword: 'електромонтажник',
    localKeyword: 'электромонтажник'
  },
  {
    keyword: 'електромонтажник',
    localKeyword: 'электромонтажник'
  },
  {
    keyword: 'електромонтер',
    localKeyword: 'электромонтер'
  },
  {
    keyword: 'електронщик',
    localKeyword: 'электронщик'
  },
  {
    keyword: 'електрик вахта',
    localKeyword: 'электрик вахта'
  },
  {
    keyword: 'електрик',
    localKeyword: 'электрик'
  },
  {
    keyword: 'елеватор',
    localKeyword: 'элеватор'
  },
  {
    keyword: 'eндокринолог',
    localKeyword: 'эндокринолог'
  },
  {
    keyword: 'eнергетик',
    localKeyword: 'энергетик'
  },
  {
    keyword: 'фрахтовий брокер',
    localKeyword: 'фрахтовый брокер'
  },
  {
    keyword: 'финанси',
    localKeyword: 'финансы'
  },
  {
    keyword: 'гастроентеролог',
    localKeyword: 'гастроэнтеролог'
  },
  {
    keyword: 'генеральний директор',
    localKeyword: 'генеральный директор'
  },
  {
    keyword: 'грузчик експедитор',
    localKeyword: 'грузчик экспедитор'
  },
  {
    keyword: 'канцтовари',
    localKeyword: 'канцтовары'
  },
  {
    keyword: 'кредитний експерт',
    localKeyword: 'кредитный эксперт'
  },
  {
    keyword: 'кибербезопасност',
    localKeyword: 'кибербезопасность'
  },
  {
    keyword: 'ландшафтний дизайнер',
    localKeyword: 'ландшафтный дизайнер'
  },
  {
    keyword: 'машинист екскаватора',
    localKeyword: 'машинист экскаватора'
  },
  {
    keyword: 'менеджер по закупкам зернових',
    localKeyword: 'менеджер по закупкам зерновых'
  },
  {
    keyword: 'метро центральний ринок',
    localKeyword: 'метро центральный рынок'
  },
  {
    keyword: 'монтажник реа',
    localKeyword: 'монтажник рэа'
  },
  {
    keyword: 'музика',
    localKeyword: 'музыка'
  },
  {
    keyword: 'музикант',
    localKeyword: 'музыкант'
  },
  {
    keyword: 'начальник служби безопасности',
    localKeyword: 'начальник службы безопасности'
  },
  {
    keyword: 'немишлянський район',
    localKeyword: 'немышлянский район'
  },
  {
    keyword: 'оператор екскаватора',
    localKeyword: 'оператор экскаватора'
  },
  {
    keyword: 'оператор екструдера',
    localKeyword: 'оператор экструдера'
  },
  {
    keyword: 'пекар кондитер',
    localKeyword: 'пекарь кондитер'
  },
  {
    keyword: 'пекар',
    localKeyword: 'пекарь'
  },
  {
    keyword: 'правий берег',
    localKeyword: 'правый берег'
  },
  {
    keyword: 'пресс секретар',
    localKeyword: 'пресс секретарь'
  },
  {
    keyword: 'продавец в цветочний магазин',
    localKeyword: 'продавец в цветочный магазин'
  },
  {
    keyword: 'проектний менеджер',
    localKeyword: 'проектный менеджер'
  },
  {
    keyword: 'ресторани',
    localKeyword: 'рестораны'
  },
  {
    keyword: 'ретуш',
    localKeyword: 'ретушь'
  },
  {
    keyword: 'ручная вишивка',
    localKeyword: 'ручная вышивка'
  },
  {
    keyword: 'секретар',
    localKeyword: 'секретарь'
  },
  {
    keyword: 'шашличник',
    localKeyword: 'шашлычник'
  },
  {
    keyword: 'социальний работник',
    localKeyword: 'социальный работник'
  },
  {
    keyword: 'столярний цех',
    localKeyword: 'столярный цех'
  },
  {
    keyword: 'строителство',
    localKeyword: 'строительство'
  },
  {
    keyword: 'судмедексперт',
    localKeyword: 'судмедэксперт'
  },
  {
    keyword: 'сигарети',
    localKeyword: 'сигареты'
  },
  {
    keyword: 'технолог мясной промишленности',
    localKeyword: 'технолог мясной промышленности'
  },
  {
    keyword: 'техник електрик',
    localKeyword: 'техник электрик'
  },
  {
    keyword: 'токар',
    localKeyword: 'токарь'
  },
  {
    keyword: 'учитель музики',
    localKeyword: 'учитель музыки'
  },
  {
    keyword: 'ветеринарний врач',
    localKeyword: 'ветеринарный врач'
  },
  {
    keyword: 'вожатий',
    localKeyword: 'вожатый'
  },
  {
    keyword: 'висотник',
    localKeyword: 'высотник'
  },
  {
    keyword: 'заводи',
    localKeyword: 'заводы'
  },
  {
    keyword: 'жуляни',
    localKeyword: 'жуляны'
  },
  {
    keyword: 'зоотовари',
    localKeyword: 'зоотовары'
  },
  {
    keyword: 'зоотовари',
    localKeyword: 'зоотовары'
  }
]
