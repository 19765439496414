import { BehaviorSubject, Observable } from 'rxjs'

export class Store<Model> {
  public store$: Observable<Model>
  protected initial: Model
  protected _store$: BehaviorSubject<Model>

  public constructor(initial: Model) {
    this.initial = initial
    this._store$ = new BehaviorSubject<Model>({ ...this.initial })
    this.store$ = this._store$.asObservable()
  }

  public clear(): void {
    this.setStore({ ...this.initial })
  }

  public getStore(): Model {
    return { ...this._store$.getValue() }
  }

  public getValue<Key extends keyof Model>(key: Key): Model[Key] {
    return this.getStore()[key]
  }

  protected setStore(store: Partial<Model>): void {
    this._store$.next({ ...this.getStore(), ...store })
  }
}
