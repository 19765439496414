import { Injectable } from '@angular/core'
import { DefaultUrlSerializer, UrlSerializer, UrlTree } from '@angular/router'

@Injectable({
  providedIn: 'root'
})
export class DesktopUrlSerializerService implements UrlSerializer {
  private readonly outletPattern = /\(([^)]+:[^)]+)\)/g

  public constructor(private readonly defaultUrlSerializer: DefaultUrlSerializer) {}

  public serialize(tree: UrlTree): string {
    return this.defaultUrlSerializer.serialize(tree)
  }

  public parse(url: string): UrlTree {
    const urlWithoutParentheses = this.removeParentheses(url)
    return this.defaultUrlSerializer.parse(urlWithoutParentheses)
  }

  private removeParentheses(url: string): string {
    const result = url.match(this.outletPattern) || []
    const outlets = result.reduce((acc, match, index) => {
      acc.set(`<${index}>`, match)
      return acc
    }, new Map<string, string>())

    const formatUrl = result.reduce((acc, match, index) => acc.replace(match, `<${index}>`), url)
    const escapedUrl = formatUrl.replace(/[(|)]/g, match => (match === '(' ? '%28' : '%29'))
    return escapedUrl.replace(/<\d+>/g, match => outlets.get(match) || '')
  }
}
