import { InjectionToken } from '@angular/core'

export interface NotifierParams {
  readonly type: 'success' | 'error'
  readonly text: string
  readonly imageSrc?: string
}

export interface Notifier<TParams extends NotifierParams = NotifierParams> {
  notify(params: TParams): void
}

export const NOTIFIER = new InjectionToken<Notifier>('Notifier')
