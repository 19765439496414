import { set as setImpl } from 'date-fns'

export const set = (
  date: Date,
  values: {
    year?: number | undefined
    month?: number | undefined
    date?: number | undefined
    hours?: number | undefined
    minutes?: number | undefined
    seconds?: number | undefined
    milliseconds?: number | undefined
  }
): Date => setImpl(date, values)
