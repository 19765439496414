import { LocalStorage } from '@alliance/shared/storage'
import { DetectPlatformService } from '@alliance/shared/utils'
import { ErrorHandler, Injectable } from '@angular/core'
import { createErrorHandler } from '@sentry/angular-ivy'
import { sentryIsEnabled } from './utils'

@Injectable()
export class SentryBrowserErrorHandlerService extends ErrorHandler {
  private readonly sentryErrorHandler = createErrorHandler({ logErrors: true })

  public constructor(private localStorage: LocalStorage, protected platform: DetectPlatformService) {
    super()
  }

  public override handleError(error: unknown): void {
    if (!sentryIsEnabled(this.localStorage, this.platform)) {
      return super.handleError(error)
    }

    return this.sentryErrorHandler.handleError(error)
  }
}
