import { AuthService } from '@alliance/shared/auth/api'
import { Injectable } from '@angular/core'
import { ActivatedRouteSnapshot, CanActivateChild, Router, RouterStateSnapshot, UrlTree } from '@angular/router'

@Injectable()
export class EmployerDashboardGuard implements CanActivateChild {
  public constructor(private authService: AuthService, private router: Router) {}

  public canActivateChild(_: ActivatedRouteSnapshot, state: RouterStateSnapshot): UrlTree | boolean {
    const employerHomePageUrl = '/employer'
    const dashboardPageUrl = '/my'

    const isDashboardPage = state.url.includes(dashboardPageUrl)

    const queryParams = state.root.queryParams

    if (!!this.authService.token && this.authService.isEmployer) {
      return !isDashboardPage && !queryParams['goHome'] ? this.router.createUrlTree([dashboardPageUrl], { queryParams }) : true
    }

    if (isDashboardPage) {
      return this.router.createUrlTree([employerHomePageUrl], { queryParams })
    }

    return true
  }
}
