import { ComponentRef, Injectable, Type } from '@angular/core'
import { ComponentPortal } from '@angular/cdk/portal'
import { asyncScheduler, timer } from 'rxjs'
import { ModalOverlayRef } from '../modal'
import { ModalService } from '../modal/modal.service'
import { SnackbarComponent } from '../../components/snackbar/snackbar.component'

@Injectable({
  providedIn: 'root'
})
export class SnackbarService extends ModalService {
  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  protected override readonly hostComponent: any = SnackbarComponent

  public override openByComponent<T, K = { value: string }>(content: Type<T> | null, data?: K, offset?: number): ModalOverlayRef {
    if (this.hasAttachedModal) {
      this.close()
    }

    const overlayRef = this.createOverlay()

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const portal = new ComponentPortal(content)
    const modalRef = new ModalOverlayRef(overlayRef)
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const injector = this.createInjector<K>(modalRef, data)
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
    const modalPortal = new ComponentPortal<SnackbarComponent<T>>(this.hostComponent, null, injector)

    timer(0, asyncScheduler).subscribe(() => {
      const modalComponent: ComponentRef<SnackbarComponent<T>> = overlayRef.attach(modalPortal)

      modalComponent.instance.content = portal
      modalComponent.instance.offset = offset ?? 0
    })

    return modalRef
  }
}
