import { Injectable } from '@angular/core'
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http'
import { BaseService as __BaseService } from '../base-service'
import { JobseekerDataAccessConfiguration as __Configuration } from '../jobseeker-data-access-configuration'
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response'
import { Observable as __Observable } from 'rxjs'
import { map as __map, filter as __filter } from 'rxjs/operators'

import { DataLocalized } from '../models/data-localized'
import { ResourceLocalized } from '../models/resource-localized'
import { CityDataLocalized } from '../models/city-data-localized'
import { CityRegionDataLocalized } from '../models/city-region-data-localized'
import { MetroBranchDataLocalized } from '../models/metro-branch-data-localized'
import { LanguageDataLocalized } from '../models/language-data-localized'
import { KeywordInfo } from '../models/keyword-info'
import { PopularTagInfo } from '../models/popular-tag-info'
@Injectable({
  providedIn: 'root'
})
class DictionaryService extends __BaseService {
  static readonly dictionaryGetActivityLevelsPath = '/dictionary/activitylevel'
  static readonly dictionaryGetAdditionalPath = '/dictionary/additional'
  static readonly dictionaryGetBranchPath = '/dictionary/branch'
  static readonly dictionaryGetSpheresPath = '/dictionary/spheres'
  static readonly dictionaryGetCityPath = '/dictionary/city'
  static readonly dictionaryGetCitiesWithRegionsPath = '/dictionary/cities-with-regions'
  static readonly dictionaryGetCitiesAndRegionsPath = '/dictionary/cities-and-regions'
  static readonly dictionaryGetDistrictsForPath = '/dictionary/districts'
  static readonly dictionaryGetMetroForPath = '/dictionary/metro'
  static readonly dictionaryGetCurrencyPath = '/dictionary/currency'
  static readonly dictionaryGetEducationPath = '/dictionary/education'
  static readonly dictionaryGetFillingTypesPath = '/dictionary/fillingTypes'
  static readonly dictionaryGetExperiencePath = '/dictionary/experience'
  static readonly dictionaryGetCvDbExperiencePath = '/dictionary/cvdb-experience'
  static readonly dictionaryGetGenderPath = '/dictionary/gender'
  static readonly dictionaryGetResourcePath = '/dictionary/resource'
  static readonly dictionaryGetRubricPath = '/dictionary/rubric'
  static readonly dictionaryGetSubRubricPath = '/dictionary/subrubric'
  static readonly dictionaryGetSchedulePath = '/dictionary/schedule'
  static readonly dictionaryGetExperienceStatusPath = '/dictionary/statusapplication/experience'
  static readonly dictionaryGetSalaryStatusPath = '/dictionary/statusapplication/salary'
  static readonly dictionaryGetZaprosPath = '/dictionary/zapros'
  static readonly dictionaryGetVacancyStatePath = '/dictionary/vacancystate'
  static readonly dictionaryGetLanguageSkillPath = '/dictionary/language/skill'
  static readonly dictionaryGetLanguagePath = '/dictionary/language'
  static readonly dictionaryGetRegionsPath = '/dictionary/regions'
  static readonly dictionaryGetKeywordsPath = '/dictionary/keywords'
  static readonly dictionaryGetKeywordsWithTransliterationPath = '/dictionary/keywords-with-transliteration'
  static readonly dictionaryGetPopularTagsPath = '/dictionary/popular-tags'

  constructor(config: __Configuration, http: HttpClient) {
    super(config, http)
  }

  /**
   * @return OK
   */
  dictionaryGetActivityLevelsResponse(): __Observable<__StrictHttpResponse<Array<DataLocalized>>> {
    let __params = this.newParams()
    let __headers = new HttpHeaders()
    let __body: any = null
    let req = new HttpRequest<any>('GET', this.rootUrl + `/dictionary/activitylevel`, __body, {
      headers: __headers,
      params: __params,
      responseType: 'json'
    })

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map(_r => {
        return _r as __StrictHttpResponse<Array<DataLocalized>>
      })
    )
  }

  /**
   * @return OK
   */
  dictionaryGetActivityLevels(): __Observable<Array<DataLocalized>> {
    return this.dictionaryGetActivityLevelsResponse().pipe(__map(_r => _r.body as Array<DataLocalized>))
  }

  /**
   * @return OK
   */
  dictionaryGetAdditionalResponse(): __Observable<__StrictHttpResponse<Array<ResourceLocalized>>> {
    let __params = this.newParams()
    let __headers = new HttpHeaders()
    let __body: any = null
    let req = new HttpRequest<any>('GET', this.rootUrl + `/dictionary/additional`, __body, {
      headers: __headers,
      params: __params,
      responseType: 'json'
    })

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map(_r => {
        return _r as __StrictHttpResponse<Array<ResourceLocalized>>
      })
    )
  }

  /**
   * @return OK
   */
  dictionaryGetAdditional(): __Observable<Array<ResourceLocalized>> {
    return this.dictionaryGetAdditionalResponse().pipe(__map(_r => _r.body as Array<ResourceLocalized>))
  }

  /**
   * @return OK
   */
  dictionaryGetBranchResponse(): __Observable<__StrictHttpResponse<Array<DataLocalized>>> {
    let __params = this.newParams()
    let __headers = new HttpHeaders()
    let __body: any = null
    let req = new HttpRequest<any>('GET', this.rootUrl + `/dictionary/branch`, __body, {
      headers: __headers,
      params: __params,
      responseType: 'json'
    })

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map(_r => {
        return _r as __StrictHttpResponse<Array<DataLocalized>>
      })
    )
  }

  /**
   * @return OK
   */
  dictionaryGetBranch(): __Observable<Array<DataLocalized>> {
    return this.dictionaryGetBranchResponse().pipe(__map(_r => _r.body as Array<DataLocalized>))
  }

  /**
   * @return OK
   */
  dictionaryGetSpheresResponse(): __Observable<__StrictHttpResponse<Array<DataLocalized>>> {
    let __params = this.newParams()
    let __headers = new HttpHeaders()
    let __body: any = null
    let req = new HttpRequest<any>('GET', this.rootUrl + `/dictionary/spheres`, __body, {
      headers: __headers,
      params: __params,
      responseType: 'json'
    })

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map(_r => {
        return _r as __StrictHttpResponse<Array<DataLocalized>>
      })
    )
  }

  /**
   * @return OK
   */
  dictionaryGetSpheres(): __Observable<Array<DataLocalized>> {
    return this.dictionaryGetSpheresResponse().pipe(__map(_r => _r.body as Array<DataLocalized>))
  }

  /**
   * @return OK
   */
  dictionaryGetCityResponse(): __Observable<__StrictHttpResponse<Array<CityDataLocalized>>> {
    let __params = this.newParams()
    let __headers = new HttpHeaders()
    let __body: any = null
    let req = new HttpRequest<any>('GET', this.rootUrl + `/dictionary/city`, __body, {
      headers: __headers,
      params: __params,
      responseType: 'json'
    })

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map(_r => {
        return _r as __StrictHttpResponse<Array<CityDataLocalized>>
      })
    )
  }

  /**
   * @return OK
   */
  dictionaryGetCity(): __Observable<Array<CityDataLocalized>> {
    return this.dictionaryGetCityResponse().pipe(__map(_r => _r.body as Array<CityDataLocalized>))
  }

  /**
   * @return OK
   */
  dictionaryGetCitiesWithRegionsResponse(): __Observable<__StrictHttpResponse<Array<CityDataLocalized>>> {
    let __params = this.newParams()
    let __headers = new HttpHeaders()
    let __body: any = null
    let req = new HttpRequest<any>('GET', this.rootUrl + `/dictionary/cities-with-regions`, __body, {
      headers: __headers,
      params: __params,
      responseType: 'json'
    })

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map(_r => {
        return _r as __StrictHttpResponse<Array<CityDataLocalized>>
      })
    )
  }

  /**
   * @return OK
   */
  dictionaryGetCitiesWithRegions(): __Observable<Array<CityDataLocalized>> {
    return this.dictionaryGetCitiesWithRegionsResponse().pipe(__map(_r => _r.body as Array<CityDataLocalized>))
  }

  /**
   * @return OK
   */
  dictionaryGetCitiesAndRegionsResponse(): __Observable<__StrictHttpResponse<Array<CityRegionDataLocalized>>> {
    let __params = this.newParams()
    let __headers = new HttpHeaders()
    let __body: any = null
    let req = new HttpRequest<any>('GET', this.rootUrl + `/dictionary/cities-and-regions`, __body, {
      headers: __headers,
      params: __params,
      responseType: 'json'
    })

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map(_r => {
        return _r as __StrictHttpResponse<Array<CityRegionDataLocalized>>
      })
    )
  }

  /**
   * @return OK
   */
  dictionaryGetCitiesAndRegions(): __Observable<Array<CityRegionDataLocalized>> {
    return this.dictionaryGetCitiesAndRegionsResponse().pipe(__map(_r => _r.body as Array<CityRegionDataLocalized>))
  }

  /**
   * @param cityId undefined
   * @return OK
   */
  dictionaryGetDistrictsForResponse(cityId: number): __Observable<__StrictHttpResponse<Array<DataLocalized>>> {
    let __params = this.newParams()
    let __headers = new HttpHeaders()
    let __body: any = null
    if (cityId != null) __params = __params.set('cityId', cityId.toString())
    let req = new HttpRequest<any>('GET', this.rootUrl + `/dictionary/districts`, __body, {
      headers: __headers,
      params: __params,
      responseType: 'json'
    })

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map(_r => {
        return _r as __StrictHttpResponse<Array<DataLocalized>>
      })
    )
  }

  /**
   * @param cityId undefined
   * @return OK
   */
  dictionaryGetDistrictsFor(cityId: number): __Observable<Array<DataLocalized>> {
    return this.dictionaryGetDistrictsForResponse(cityId).pipe(__map(_r => _r.body as Array<DataLocalized>))
  }

  /**
   * @param cityId undefined
   * @return OK
   */
  dictionaryGetMetroForResponse(cityId: number): __Observable<__StrictHttpResponse<Array<MetroBranchDataLocalized>>> {
    let __params = this.newParams()
    let __headers = new HttpHeaders()
    let __body: any = null
    if (cityId != null) __params = __params.set('cityId', cityId.toString())
    let req = new HttpRequest<any>('GET', this.rootUrl + `/dictionary/metro`, __body, {
      headers: __headers,
      params: __params,
      responseType: 'json'
    })

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map(_r => {
        return _r as __StrictHttpResponse<Array<MetroBranchDataLocalized>>
      })
    )
  }

  /**
   * @param cityId undefined
   * @return OK
   */
  dictionaryGetMetroFor(cityId: number): __Observable<Array<MetroBranchDataLocalized>> {
    return this.dictionaryGetMetroForResponse(cityId).pipe(__map(_r => _r.body as Array<MetroBranchDataLocalized>))
  }

  /**
   * @return OK
   */
  dictionaryGetCurrencyResponse(): __Observable<__StrictHttpResponse<Array<DataLocalized>>> {
    let __params = this.newParams()
    let __headers = new HttpHeaders()
    let __body: any = null
    let req = new HttpRequest<any>('GET', this.rootUrl + `/dictionary/currency`, __body, {
      headers: __headers,
      params: __params,
      responseType: 'json'
    })

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map(_r => {
        return _r as __StrictHttpResponse<Array<DataLocalized>>
      })
    )
  }

  /**
   * @return OK
   */
  dictionaryGetCurrency(): __Observable<Array<DataLocalized>> {
    return this.dictionaryGetCurrencyResponse().pipe(__map(_r => _r.body as Array<DataLocalized>))
  }

  /**
   * @return OK
   */
  dictionaryGetEducationResponse(): __Observable<__StrictHttpResponse<Array<DataLocalized>>> {
    let __params = this.newParams()
    let __headers = new HttpHeaders()
    let __body: any = null
    let req = new HttpRequest<any>('GET', this.rootUrl + `/dictionary/education`, __body, {
      headers: __headers,
      params: __params,
      responseType: 'json'
    })

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map(_r => {
        return _r as __StrictHttpResponse<Array<DataLocalized>>
      })
    )
  }

  /**
   * @return OK
   */
  dictionaryGetEducation(): __Observable<Array<DataLocalized>> {
    return this.dictionaryGetEducationResponse().pipe(__map(_r => _r.body as Array<DataLocalized>))
  }

  /**
   * @return OK
   */
  dictionaryGetFillingTypesResponse(): __Observable<__StrictHttpResponse<Array<DataLocalized>>> {
    let __params = this.newParams()
    let __headers = new HttpHeaders()
    let __body: any = null
    let req = new HttpRequest<any>('GET', this.rootUrl + `/dictionary/fillingTypes`, __body, {
      headers: __headers,
      params: __params,
      responseType: 'json'
    })

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map(_r => {
        return _r as __StrictHttpResponse<Array<DataLocalized>>
      })
    )
  }

  /**
   * @return OK
   */
  dictionaryGetFillingTypes(): __Observable<Array<DataLocalized>> {
    return this.dictionaryGetFillingTypesResponse().pipe(__map(_r => _r.body as Array<DataLocalized>))
  }

  /**
   * @return OK
   */
  dictionaryGetExperienceResponse(): __Observable<__StrictHttpResponse<Array<DataLocalized>>> {
    let __params = this.newParams()
    let __headers = new HttpHeaders()
    let __body: any = null
    let req = new HttpRequest<any>('GET', this.rootUrl + `/dictionary/experience`, __body, {
      headers: __headers,
      params: __params,
      responseType: 'json'
    })

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map(_r => {
        return _r as __StrictHttpResponse<Array<DataLocalized>>
      })
    )
  }

  /**
   * @return OK
   */
  dictionaryGetExperience(): __Observable<Array<DataLocalized>> {
    return this.dictionaryGetExperienceResponse().pipe(__map(_r => _r.body as Array<DataLocalized>))
  }

  /**
   * @return OK
   */
  dictionaryGetCvDbExperienceResponse(): __Observable<__StrictHttpResponse<Array<DataLocalized>>> {
    let __params = this.newParams()
    let __headers = new HttpHeaders()
    let __body: any = null
    let req = new HttpRequest<any>('GET', this.rootUrl + `/dictionary/cvdb-experience`, __body, {
      headers: __headers,
      params: __params,
      responseType: 'json'
    })

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map(_r => {
        return _r as __StrictHttpResponse<Array<DataLocalized>>
      })
    )
  }

  /**
   * @return OK
   */
  dictionaryGetCvDbExperience(): __Observable<Array<DataLocalized>> {
    return this.dictionaryGetCvDbExperienceResponse().pipe(__map(_r => _r.body as Array<DataLocalized>))
  }

  /**
   * @return OK
   */
  dictionaryGetGenderResponse(): __Observable<__StrictHttpResponse<Array<DataLocalized>>> {
    let __params = this.newParams()
    let __headers = new HttpHeaders()
    let __body: any = null
    let req = new HttpRequest<any>('GET', this.rootUrl + `/dictionary/gender`, __body, {
      headers: __headers,
      params: __params,
      responseType: 'json'
    })

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map(_r => {
        return _r as __StrictHttpResponse<Array<DataLocalized>>
      })
    )
  }

  /**
   * @return OK
   */
  dictionaryGetGender(): __Observable<Array<DataLocalized>> {
    return this.dictionaryGetGenderResponse().pipe(__map(_r => _r.body as Array<DataLocalized>))
  }

  /**
   * @return OK
   */
  dictionaryGetResourceResponse(): __Observable<__StrictHttpResponse<{ [key: string]: ResourceLocalized }>> {
    let __params = this.newParams()
    let __headers = new HttpHeaders()
    let __body: any = null
    let req = new HttpRequest<any>('GET', this.rootUrl + `/dictionary/resource`, __body, {
      headers: __headers,
      params: __params,
      responseType: 'json'
    })

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map(_r => {
        return _r as __StrictHttpResponse<{ [key: string]: ResourceLocalized }>
      })
    )
  }

  /**
   * @return OK
   */
  dictionaryGetResource(): __Observable<{ [key: string]: ResourceLocalized }> {
    return this.dictionaryGetResourceResponse().pipe(__map(_r => _r.body as { [key: string]: ResourceLocalized }))
  }

  /**
   * @return OK
   */
  dictionaryGetRubricResponse(): __Observable<__StrictHttpResponse<Array<DataLocalized>>> {
    let __params = this.newParams()
    let __headers = new HttpHeaders()
    let __body: any = null
    let req = new HttpRequest<any>('GET', this.rootUrl + `/dictionary/rubric`, __body, {
      headers: __headers,
      params: __params,
      responseType: 'json'
    })

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map(_r => {
        return _r as __StrictHttpResponse<Array<DataLocalized>>
      })
    )
  }

  /**
   * @return OK
   */
  dictionaryGetRubric(): __Observable<Array<DataLocalized>> {
    return this.dictionaryGetRubricResponse().pipe(__map(_r => _r.body as Array<DataLocalized>))
  }

  /**
   * @return OK
   */
  dictionaryGetSubRubricResponse(): __Observable<__StrictHttpResponse<Array<DataLocalized>>> {
    let __params = this.newParams()
    let __headers = new HttpHeaders()
    let __body: any = null
    let req = new HttpRequest<any>('GET', this.rootUrl + `/dictionary/subrubric`, __body, {
      headers: __headers,
      params: __params,
      responseType: 'json'
    })

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map(_r => {
        return _r as __StrictHttpResponse<Array<DataLocalized>>
      })
    )
  }

  /**
   * @return OK
   */
  dictionaryGetSubRubric(): __Observable<Array<DataLocalized>> {
    return this.dictionaryGetSubRubricResponse().pipe(__map(_r => _r.body as Array<DataLocalized>))
  }

  /**
   * @return OK
   */
  dictionaryGetScheduleResponse(): __Observable<__StrictHttpResponse<Array<DataLocalized>>> {
    let __params = this.newParams()
    let __headers = new HttpHeaders()
    let __body: any = null
    let req = new HttpRequest<any>('GET', this.rootUrl + `/dictionary/schedule`, __body, {
      headers: __headers,
      params: __params,
      responseType: 'json'
    })

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map(_r => {
        return _r as __StrictHttpResponse<Array<DataLocalized>>
      })
    )
  }

  /**
   * @return OK
   */
  dictionaryGetSchedule(): __Observable<Array<DataLocalized>> {
    return this.dictionaryGetScheduleResponse().pipe(__map(_r => _r.body as Array<DataLocalized>))
  }

  /**
   * @return OK
   */
  dictionaryGetExperienceStatusResponse(): __Observable<__StrictHttpResponse<Array<DataLocalized>>> {
    let __params = this.newParams()
    let __headers = new HttpHeaders()
    let __body: any = null
    let req = new HttpRequest<any>('GET', this.rootUrl + `/dictionary/statusapplication/experience`, __body, {
      headers: __headers,
      params: __params,
      responseType: 'json'
    })

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map(_r => {
        return _r as __StrictHttpResponse<Array<DataLocalized>>
      })
    )
  }

  /**
   * @return OK
   */
  dictionaryGetExperienceStatus(): __Observable<Array<DataLocalized>> {
    return this.dictionaryGetExperienceStatusResponse().pipe(__map(_r => _r.body as Array<DataLocalized>))
  }

  /**
   * @return OK
   */
  dictionaryGetSalaryStatusResponse(): __Observable<__StrictHttpResponse<Array<DataLocalized>>> {
    let __params = this.newParams()
    let __headers = new HttpHeaders()
    let __body: any = null
    let req = new HttpRequest<any>('GET', this.rootUrl + `/dictionary/statusapplication/salary`, __body, {
      headers: __headers,
      params: __params,
      responseType: 'json'
    })

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map(_r => {
        return _r as __StrictHttpResponse<Array<DataLocalized>>
      })
    )
  }

  /**
   * @return OK
   */
  dictionaryGetSalaryStatus(): __Observable<Array<DataLocalized>> {
    return this.dictionaryGetSalaryStatusResponse().pipe(__map(_r => _r.body as Array<DataLocalized>))
  }

  /**
   * @param ukrainian undefined
   * @return OK
   */
  dictionaryGetZaprosResponse(ukrainian?: boolean): __Observable<__StrictHttpResponse<{}>> {
    let __params = this.newParams()
    let __headers = new HttpHeaders()
    let __body: any = null
    if (ukrainian != null) __params = __params.set('ukrainian', ukrainian.toString())
    let req = new HttpRequest<any>('GET', this.rootUrl + `/dictionary/zapros`, __body, {
      headers: __headers,
      params: __params,
      responseType: 'json'
    })

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map(_r => {
        return _r as __StrictHttpResponse<{}>
      })
    )
  }

  /**
   * @param ukrainian undefined
   * @return OK
   */
  dictionaryGetZapros(ukrainian?: boolean): __Observable<{}> {
    return this.dictionaryGetZaprosResponse(ukrainian).pipe(__map(_r => _r.body as {}))
  }

  /**
   * @return OK
   */
  dictionaryGetVacancyStateResponse(): __Observable<__StrictHttpResponse<{}>> {
    let __params = this.newParams()
    let __headers = new HttpHeaders()
    let __body: any = null
    let req = new HttpRequest<any>('GET', this.rootUrl + `/dictionary/vacancystate`, __body, {
      headers: __headers,
      params: __params,
      responseType: 'json'
    })

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map(_r => {
        return _r as __StrictHttpResponse<{}>
      })
    )
  }

  /**
   * @return OK
   */
  dictionaryGetVacancyState(): __Observable<{}> {
    return this.dictionaryGetVacancyStateResponse().pipe(__map(_r => _r.body as {}))
  }

  /**
   * @return OK
   */
  dictionaryGetLanguageSkillResponse(): __Observable<__StrictHttpResponse<Array<DataLocalized>>> {
    let __params = this.newParams()
    let __headers = new HttpHeaders()
    let __body: any = null
    let req = new HttpRequest<any>('GET', this.rootUrl + `/dictionary/language/skill`, __body, {
      headers: __headers,
      params: __params,
      responseType: 'json'
    })

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map(_r => {
        return _r as __StrictHttpResponse<Array<DataLocalized>>
      })
    )
  }

  /**
   * @return OK
   */
  dictionaryGetLanguageSkill(): __Observable<Array<DataLocalized>> {
    return this.dictionaryGetLanguageSkillResponse().pipe(__map(_r => _r.body as Array<DataLocalized>))
  }

  /**
   * @return OK
   */
  dictionaryGetLanguageResponse(): __Observable<__StrictHttpResponse<Array<LanguageDataLocalized>>> {
    let __params = this.newParams()
    let __headers = new HttpHeaders()
    let __body: any = null
    let req = new HttpRequest<any>('GET', this.rootUrl + `/dictionary/language`, __body, {
      headers: __headers,
      params: __params,
      responseType: 'json'
    })

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map(_r => {
        return _r as __StrictHttpResponse<Array<LanguageDataLocalized>>
      })
    )
  }

  /**
   * @return OK
   */
  dictionaryGetLanguage(): __Observable<Array<LanguageDataLocalized>> {
    return this.dictionaryGetLanguageResponse().pipe(__map(_r => _r.body as Array<LanguageDataLocalized>))
  }

  /**
   * @return OK
   */
  dictionaryGetRegionsResponse(): __Observable<__StrictHttpResponse<Array<DataLocalized>>> {
    let __params = this.newParams()
    let __headers = new HttpHeaders()
    let __body: any = null
    let req = new HttpRequest<any>('GET', this.rootUrl + `/dictionary/regions`, __body, {
      headers: __headers,
      params: __params,
      responseType: 'json'
    })

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map(_r => {
        return _r as __StrictHttpResponse<Array<DataLocalized>>
      })
    )
  }

  /**
   * @return OK
   */
  dictionaryGetRegions(): __Observable<Array<DataLocalized>> {
    return this.dictionaryGetRegionsResponse().pipe(__map(_r => _r.body as Array<DataLocalized>))
  }

  /**
   * @return OK
   */
  dictionaryGetKeywordsResponse(): __Observable<__StrictHttpResponse<Array<string>>> {
    let __params = this.newParams()
    let __headers = new HttpHeaders()
    let __body: any = null
    let req = new HttpRequest<any>('GET', this.rootUrl + `/dictionary/keywords`, __body, {
      headers: __headers,
      params: __params,
      responseType: 'json'
    })

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map(_r => {
        return _r as __StrictHttpResponse<Array<string>>
      })
    )
  }

  /**
   * @return OK
   */
  dictionaryGetKeywords(): __Observable<Array<string>> {
    return this.dictionaryGetKeywordsResponse().pipe(__map(_r => _r.body as Array<string>))
  }

  /**
   * @param transliteredkeyword undefined
   * @return OK
   */
  dictionaryGetKeywordsWithTransliterationResponse(transliteredkeyword?: string): __Observable<__StrictHttpResponse<Array<KeywordInfo>>> {
    let __params = this.newParams()
    let __headers = new HttpHeaders()
    let __body: any = null
    if (transliteredkeyword != null) __params = __params.set('transliteredkeyword', transliteredkeyword.toString())
    let req = new HttpRequest<any>('GET', this.rootUrl + `/dictionary/keywords-with-transliteration`, __body, {
      headers: __headers,
      params: __params,
      responseType: 'json'
    })

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map(_r => {
        return _r as __StrictHttpResponse<Array<KeywordInfo>>
      })
    )
  }

  /**
   * @param transliteredkeyword undefined
   * @return OK
   */
  dictionaryGetKeywordsWithTransliteration(transliteredkeyword?: string): __Observable<Array<KeywordInfo>> {
    return this.dictionaryGetKeywordsWithTransliterationResponse(transliteredkeyword).pipe(__map(_r => _r.body as Array<KeywordInfo>))
  }

  /**
   * @return OK
   */
  dictionaryGetPopularTagsResponse(): __Observable<__StrictHttpResponse<Array<PopularTagInfo>>> {
    let __params = this.newParams()
    let __headers = new HttpHeaders()
    let __body: any = null
    let req = new HttpRequest<any>('GET', this.rootUrl + `/dictionary/popular-tags`, __body, {
      headers: __headers,
      params: __params,
      responseType: 'json'
    })

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map(_r => {
        return _r as __StrictHttpResponse<Array<PopularTagInfo>>
      })
    )
  }

  /**
   * @return OK
   */
  dictionaryGetPopularTags(): __Observable<Array<PopularTagInfo>> {
    return this.dictionaryGetPopularTagsResponse().pipe(__map(_r => _r.body as Array<PopularTagInfo>))
  }
}

module DictionaryService {}

export { DictionaryService }
