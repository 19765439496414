import { Logger, logger } from '../logger/logger'

type SafeLoggerExtendedParams<T> = T & {
  [key: string]: unknown
}
type SafeLoggerMetaParams = {
  message: string
  where: string
}
type SafeLoggerCommonCategory = 'manual_lazy_load_failed' | 'api_call_failed' | 'try_catch' | 'unexpected_value'
type SafeLoggerLogParams = SafeLoggerMetaParams & {
  category?: SafeLoggerCommonCategory
  error?: unknown
}
type SafeLoggerWarnParams = SafeLoggerMetaParams & {
  category: SafeLoggerCommonCategory
  error?: unknown
}
type SafeLoggerErrorParams = SafeLoggerMetaParams & {
  category: SafeLoggerCommonCategory
  error: unknown
}

export const log = logger<SafeLoggerExtendedParams<SafeLoggerLogParams>, SafeLoggerExtendedParams<SafeLoggerErrorParams>, SafeLoggerExtendedParams<SafeLoggerWarnParams>>({
  source: 'SAFE_LOGGER'
})

export const logFactory = <PredefinedMeta extends Partial<SafeLoggerMetaParams>>(
  meta: PredefinedMeta
): Logger<
  SafeLoggerExtendedParams<Omit<SafeLoggerLogParams, keyof PredefinedMeta>>,
  SafeLoggerExtendedParams<Omit<SafeLoggerErrorParams, keyof PredefinedMeta>>,
  SafeLoggerExtendedParams<Omit<SafeLoggerWarnParams, keyof PredefinedMeta>>
> =>
  logger<
    SafeLoggerExtendedParams<Omit<SafeLoggerLogParams, keyof PredefinedMeta>>,
    SafeLoggerExtendedParams<Omit<SafeLoggerErrorParams, keyof PredefinedMeta>>,
    SafeLoggerExtendedParams<Omit<SafeLoggerWarnParams, keyof PredefinedMeta>>
  >({ ...meta, source: 'SAFE_LOGGER' })
