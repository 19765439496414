import { DataLayerPushEvent } from '@alliance/shared/utils'

export const APPLIES_RESUME_TRY_TO_REPORT_EVENT: DataLayerPushEvent = {
  event: 'EMP_Applies',
  eventCategory: 'EMP_Applies',
  eventAction: 'TryToReport',
  eventLabel: null,
  eventContent: null
}

export const APPLIES_RESUME_REPORT_EVENT: DataLayerPushEvent = {
  event: 'EMP_Applies',
  eventCategory: 'EMP_Applies',
  eventAction: 'Report',
  eventLabel: null,
  eventContent: null
}
