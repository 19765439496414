import { isServer } from './helpers/is-server'
import { SUPPORTED_METHODS } from './helpers/models'
import { normalize } from './helpers/normalize'
import { prettify } from './helpers/prettify'
import { stringify } from './helpers/stringify'

export interface Logger<LogType extends object = object, ErrorType extends object = object, WarnType extends object = object> {
  log(content: LogType): void
  error(content: ErrorType): void
  warn(content: WarnType): void
}

const MAP_CONSOLE_METHOD_TO_SEVERITY: Record<SUPPORTED_METHODS, string> = {
  error: 'error',
  log: 'info', // Loki treats log as debug level
  warn: 'warn'
}

const consoleCall = <ContentType extends object>(method: SUPPORTED_METHODS, content: ContentType): void => {
  const meta = {
    severity: MAP_CONSOLE_METHOD_TO_SEVERITY[method],
    logger: 'LOGGER',
    timestamp: Date.now()
  }
  try {
    if (isServer()) {
      console[method](
        stringify({
          ...normalize(content),
          ...meta
        })
      )
    } else {
      prettify(method, meta, content)
    }
  } catch {
    logger(meta).error({ message: 'SOMETHING WENT WRONG IN LOGGER --> CHECK UNHANDLED_ERRORS_ON_SSR' })
  }
}

export const logger = <LogType extends object = object, ErrorType extends object = object, WarnType extends object = object, MetaType extends object = object>(
  meta: MetaType
): Logger<LogType, ErrorType, WarnType> => ({
  error: (content: ErrorType): void => consoleCall<ErrorType & MetaType>('error', { ...content, ...meta }),
  log: (content: LogType): void => consoleCall<LogType & MetaType>('log', { ...content, ...meta }),
  warn: (content: WarnType): void => consoleCall<WarnType & MetaType>('warn', { ...content, ...meta })
})
