import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http'
import { Observable } from 'rxjs'
import { filter, map } from 'rxjs/operators'
import { StrictHttpResponse } from '../../strict-http-response'
import { RequestBuilder } from '../../request-builder'

import { VacancyApiFeatureContext } from '../../models/vacancy-api-feature-context'

export interface FeatureAccessGet$Plain$Params {
  context?: VacancyApiFeatureContext
  companyId?: number
}

export function featureAccessGet$Plain(http: HttpClient, rootUrl: string, params?: FeatureAccessGet$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<boolean>> {
  const rb = new RequestBuilder(rootUrl, featureAccessGet$Plain.PATH, 'get')
  if (params) {
    rb.query('context', params.context, {})
    rb.query('companyId', params.companyId, {})
  }

  return http.request(rb.build({ responseType: 'text', accept: 'text/plain', context })).pipe(
    filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
    map((r: HttpResponse<any>) => {
      return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as StrictHttpResponse<boolean>
    })
  )
}

featureAccessGet$Plain.PATH = '/feature-access'
