import { log } from '@alliance/shared/logger'
import { DetectPlatformService } from '@alliance/shared/utils'
import { AfterViewInit, ChangeDetectionStrategy, Component, Inject, Injector, ViewContainerRef, ɵcreateInjector as createInjector } from '@angular/core'
import { DOCUMENT } from '@angular/common'

@Component({
  selector: 'app-fast-google-auth',
  template: '',
  styleUrls: ['./shell.component.tw.css'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ShellFastGoogleAuthComponent implements AfterViewInit {
  public constructor(private _viewContainerRef: ViewContainerRef, private _injector: Injector, private platform: DetectPlatformService, @Inject(DOCUMENT) private document: Document) {
    if (this.platform.isBrowser) {
      this.addGoogleFastAuthScript()
    }
  }

  public ngAfterViewInit(): void {
    if (this.platform.isBrowser) {
      import('@alliance/shared/feature/google-fast-auth')
        .then(({ SharedFeatureGoogleFastAuthModule }) => {
          const injector = createInjector(SharedFeatureGoogleFastAuthModule, this._injector)
          const module = injector.get(SharedFeatureGoogleFastAuthModule)
          module.renderLazy(this._viewContainerRef)
        })
        .catch(e => {
          log.warn({ where: 'desktop', category: 'manual_lazy_load_failed', message: 'ShellFastGoogleAuthComponent: SharedFeatureGoogleFastAuthModule load failed', error: e })
        })
    }
  }

  public addGoogleFastAuthScript(): void {
    try {
      const googleScript = this.document.createElement('script')
      googleScript.async = true
      googleScript.defer = true
      googleScript.src = `https://accounts.google.com/gsi/client`
      this.document.head.insertBefore(googleScript, this.document.head.firstChild)
    } catch (e) {
      log.warn({ where: 'desktop', category: 'try_catch', message: 'addGoogleFastAuthScript failed', error: e })
    }
  }
}
