export enum CvdbAttributesEnum {
  cvDetailBackToListButton = 'cv-detail-back-to-list-button',
  cvControlsDislike = 'cv-controls-dislike',
  cvControlsDropdownAddToFavorites = 'cv-controls-dropdown-ad-to-favorites',
  cvControlsAddToFavorites = 'cv-controls-add-to-favorites',
  cvControlsRemoveFromFavorites = 'cv-controls-remove-from-favorites',
  cvControlsAddFavoriteToVacancy = 'cv-controls-add-favorite-to-vacancy',
  cvControlsMoreActionMenu = 'cv-controls-more-action-menu',
  cvControlsOpenContacts = 'cv-controls-open-contacts',
  cvControlsSuggestVacancy = 'cv-controls-suggest-vacancy',
  cvControlsComplain = 'cv-controls-complain',
  cvControlsAddNote = 'cv-controls-add-note',
  cvControlsPrint = 'cv-controls-print',
  cvControlsDownloadResume = 'cv-controls-download-resume',
  cvControlsDropdownDownloadResume = 'cv-controls-dropdown-download-resume',
  cvControlsDownloadResumeWithContacts = 'cv-controls-download-resume-with-contacts',
  cvControlsDownloadResumeWithoutContacts = 'cv-controls-download-resume-without-contacts',
  suggestVacancyModalVacanciesDropdown = 'suggest-vacancy-modal-vacancies-dropdown',
  suggestVacancyModalVacanciesDropdownOptions = 'suggest-vacancy-modal-vacancies-dropdown-options',
  suggestVacancyModalSubmitButton = 'suggest-vacancy-modal-submit-button'
}
