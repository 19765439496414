import { ChangeDetectionStrategy, Component, Input } from '@angular/core'
import { SantaButtonMode } from './button-mode'
import { SantaButtonType } from './button-type'

@Component({
  selector: 'santa-button',
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.tw.css'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ButtonComponent {
  @Input() public type: SantaButtonType = 'primary'
  @Input() public mode: SantaButtonMode = 'normal'
  @Input() public isSubmitType = false
  @Input() public fullWidth = false
  @Input() public disabled = false

  @Input() public clicked: VoidFunction = () => {
    //
  }
}
