/**
 * Return is storage available
 * @param storage Storage e.g. windowRef.localStorage, windowRef.sessionStorage
 * @see https://developer.mozilla.org/en-US/docs/Web/API/Web_Storage_API/Using_the_Web_Storage_API
 */
export const storageAvailable = (storage: Storage): boolean => {
  try {
    const x = '__storage_test__'
    storage.setItem(x, x)
    storage.removeItem(x)
    return true
  } catch (e) {
    return (
      e instanceof DOMException &&
      // everything except Firefox
      (e.code === 22 ||
        // Firefox
        e.code === 1014 ||
        // test name field too, because code might not be present
        // everything except Firefox
        e.name === 'QuotaExceededError' ||
        // Firefox
        e.name === 'NS_ERROR_DOM_QUOTA_REACHED') &&
      // acknowledge QuotaExceededError only if there's something already stored
      !!storage &&
      storage.length !== 0
    )
  }
}

export const localStorageAvailable = (): boolean => {
  try {
    // eslint-disable-next-line no-restricted-globals
    return typeof window !== 'undefined' && storageAvailable(window?.localStorage)
  } catch (e) {
    return false
  }
}

export const sessionStorageAvailable = (): boolean => {
  try {
    // eslint-disable-next-line no-restricted-globals
    return typeof window !== 'undefined' && storageAvailable(window?.sessionStorage)
  } catch (e) {
    return false
  }
}
