import { AuthService } from '@alliance/shared/auth/api'
import { Injectable } from '@angular/core'
import { CanActivateChild, Router } from '@angular/router'
import { Observable } from 'rxjs'

@Injectable({
  providedIn: 'root'
})
export class AuthRedirectGuard implements CanActivateChild {
  public constructor(private authService: AuthService, private router: Router) {}

  public canActivateChild(): Observable<boolean> | Promise<boolean> | boolean {
    if (this.authService.user && this.authService.isEmployer) {
      return this.router.navigate(['/my'])
    }

    return true
  }
}
