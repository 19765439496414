import { isObjectWithProperty } from '@alliance/shared/utils'
import { MessageTargetType } from '../models/StreamType'

type SignalrInvocationMessageType = 1
type SignalrStreamItemMessageType = 2
type SignalrCompletionMessageType = 3
type SignalrStreamInvocationMessageType = 4
type SignalrCancelInvocationMessageType = 5
type SignalrPingMessageType = 6
type SignalrCloseMessageType = 7

type SignalrMessageType =
  | SignalrInvocationMessageType
  | SignalrStreamItemMessageType
  | SignalrCompletionMessageType
  | SignalrStreamInvocationMessageType
  | SignalrCancelInvocationMessageType
  | SignalrPingMessageType
  | SignalrCloseMessageType

export const SIGNALR_INVOCATION_MESSAGE_TYPE: SignalrInvocationMessageType = 1
export const SIGNALR_STREAM_ITEM_MESSAGE_TYPE: SignalrStreamItemMessageType = 2
export const SIGNALR_COMPLETION_MESSAGE_TYPE: SignalrCompletionMessageType = 3
export const SIGNALR_STREAM_INVOCATION_MESSAGE_TYPE: SignalrStreamInvocationMessageType = 4
export const SIGNALR_CANCEL_INVOCATION_MESSAGE_TYPE: SignalrCancelInvocationMessageType = 5
export const SIGNALR_PING_MESSAGE_TYPE: SignalrPingMessageType = 6
export const SIGNALR_CLOSE_MESSAGE_TYPE: SignalrCloseMessageType = 7

export const SIGNALR_INVOCATION_MESSAGE_TYPES = {
  invocation: SIGNALR_INVOCATION_MESSAGE_TYPE,
  streamItem: SIGNALR_STREAM_ITEM_MESSAGE_TYPE,
  completion: SIGNALR_COMPLETION_MESSAGE_TYPE,
  streamInvocation: SIGNALR_STREAM_INVOCATION_MESSAGE_TYPE,
  cancelInvocation: SIGNALR_CANCEL_INVOCATION_MESSAGE_TYPE,
  ping: SIGNALR_PING_MESSAGE_TYPE,
  close: SIGNALR_CLOSE_MESSAGE_TYPE
} as const

export interface SignalrHandshakeRequest {
  readonly protocol: string
  readonly version: number
}

export interface SignalrHandshakeResponse {
  readonly error: string
}

export interface BaseSignalrMessage {
  readonly type: SignalrMessageType
}

export interface SignalrPingMessage {
  readonly type: typeof SIGNALR_PING_MESSAGE_TYPE
}

export interface SignalrInvocationMessage<Arg = unknown[], Target extends MessageTargetType = MessageTargetType> {
  readonly type: typeof SIGNALR_INVOCATION_MESSAGE_TYPE
  readonly target: Target
  readonly arguments: Arg[]
}

export interface SignalrCloseMessage extends BaseSignalrMessage {
  readonly type: typeof SIGNALR_CLOSE_MESSAGE_TYPE
  readonly allowReconnect: boolean
  readonly error?: string
}

export const isSignalrCloseMessage = (message: unknown): message is SignalrCloseMessage => isObjectWithProperty(message, 'type') && message.type === SIGNALR_CLOSE_MESSAGE_TYPE
