export const NOMINATIM_GEOCODING_URL = 'https://nominatim.openstreetmap.org/search'

export const NOMINATIM_GEOCODING_NORMALIZING_HASHMAP = [
  /* keep space at the end */
  { input: '.', toReplace: ' ' },
  { input: 'вул ', toReplace: 'улица ' },
  { input: 'ул ', toReplace: 'улица ' },
  { input: 'пр-т ', toReplace: 'проспект ' },
  { input: 'прт ', toReplace: 'проспект ' },
  { input: 'просп ', toReplace: 'проспект ' },
  { input: 'пер ', toReplace: 'переулок ' },
  { input: 'перев ', toReplace: 'переулок ' },
  { input: 'прв ', toReplace: 'переулок ' },
  { input: 'пров ', toReplace: 'переулок ' },
  { input: 'р-н ', toReplace: 'район ' },
  { input: 'наб ', toReplace: 'набережная ' },
  { input: 'б-р ', toReplace: 'бульвар ' }
]
