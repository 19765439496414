import { Inject, Injectable } from '@angular/core'
import { DOCUMENT } from '@angular/common'
import { RxStateService } from '@alliance/shared/models'

import { UserSideEnum } from './models/user-side-enum'

@Injectable({
  providedIn: 'root'
})
export class UserService extends RxStateService<{
  userSide: UserSideEnum | null
}> {
  public constructor(@Inject(DOCUMENT) private readonly document: Document) {
    super()

    this.initState({
      userSide: null
    })
  }

  public get userSide(): UserSideEnum {
    const userSide = this.get().userSide

    return userSide ? userSide : this.getUserSideForOldPages()
  }

  public set userSide(userSide: UserSideEnum | null) {
    this.set({ userSide })
  }

  private getUserSideForOldPages(): UserSideEnum {
    const origin = this.document?.location?.origin
    const href = this.document?.location?.href

    return href.replace('/ua/', '/').includes(`${origin}/employer`) ? UserSideEnum.employer : UserSideEnum.jobseeker
  }
}
