import { Environment } from '@alliance/shared/environment'
import { LANGUAGE } from '@alliance/shared/models'
import { ExistingProvider, FactoryProvider, inject } from '@angular/core'
import { TRANSLOCO_CONFIG, TranslocoConfig } from '@ngneat/transloco'
import { translocoDefaultConfig } from '../constants/transloco-default-config'
import { TranslationService } from '../translation.service'

export const translocoConfigProvider = (config?: TranslocoConfig): FactoryProvider => ({
  provide: TRANSLOCO_CONFIG,
  useFactory: (): TranslocoConfig => {
    const environment = inject(Environment)
    const defaultConfig = translocoDefaultConfig(environment)

    return {
      ...defaultConfig,
      ...config,
      missingHandler: {
        ...defaultConfig.missingHandler,
        ...config?.missingHandler
      }
    }
  }
})

export const languageProvider = (): ExistingProvider => ({
  provide: LANGUAGE,
  useExisting: TranslationService
})
