// https://stackoverflow.com/questions/25456013/javascript-deepequal-comparison/25456134
export const deepEqual = (x: unknown, y: unknown): boolean => {
  if (x === y) {
    return true
  }

  const typedX = x as Record<string, unknown>
  const typedY = y as Record<string, unknown>

  if (typeof x !== 'object' || !x || typeof y !== 'object' || !y || Object.keys(typedX).length !== Object.keys(typedY).length) {
    return false
  }

  return !Object.keys(typedX).some(prop => !deepEqual(typedX[prop], typedY[prop]))
}
