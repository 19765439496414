import { AuthService } from '@alliance/shared/auth/api'
import { Injectable } from '@angular/core'
import { ActivatedRouteSnapshot, CanActivateChild, Router } from '@angular/router'
import { Observable } from 'rxjs'

@Injectable({
  providedIn: 'root'
})
export class AuthorizationUserRedirectGuard implements CanActivateChild {
  public constructor(private authService: AuthService, private router: Router) {}

  public canActivateChild(next: ActivatedRouteSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    if (this.authService.user && this.authService.isSeeker && !next.queryParams?.['goHome'] && !next.queryParams?.['utm_source'] && !next.queryParams?.['withoutRedirect']) {
      return this.router.navigate(['/my/recommendations'])
    }

    return true
  }
}
