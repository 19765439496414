import { Inject, Injectable } from '@angular/core'
import { DOCUMENT } from '@angular/common'
import { BehaviorSubject, Observable } from 'rxjs'

@Injectable({
  providedIn: 'root'
})
export class PageTitleService {
  private _pageTitleChanges$ = new BehaviorSubject<string>(this.document.title)

  public constructor(@Inject(DOCUMENT) private document: Document) {}

  public get pageTitle(): string {
    return this.document.title
  }

  public get pageTitleChanges$(): Observable<string> {
    return this._pageTitleChanges$.asObservable()
  }

  public setPageTitle(title: string): void {
    this.document.title = title
    this._pageTitleChanges$.next(title)
  }
}
