import { Injectable } from '@angular/core'
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http'
import { BaseService as __BaseService } from '../base-service'
import { EmployerDataAccessConfiguration as __Configuration } from '../employer-data-access-configuration'
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response'
import { Observable as __Observable } from 'rxjs'
import { map as __map, filter as __filter } from 'rxjs/operators'

import { CartState } from '../models/cart-state'
import { AddCartRequest } from '../models/add-cart-request'
import { OrderDetail } from '../models/order-detail'
@Injectable({
  providedIn: 'root'
})
class CartService extends __BaseService {
  public static readonly getStatePath = '/cart/status'
  public static readonly addServicePath = '/cart/add'
  public static readonly addMultiServicePath = '/cart/addmulti'
  public static readonly listPath = '/cart/list'
  public static readonly checkoutPath = '/cart/checkout'
  public static readonly getOrderPath = '/cart/getorder'
  public static readonly plusServicePath = '/cart/plus/{id}'
  public static readonly minusServicePath = '/cart/minus/{id}'
  public static readonly deleteServicePath = '/cart/delete/{id}'
  public static readonly setCountPath = '/cart/setcount/{id}'
  public static readonly deleteMarkedPath = '/cart/complete'

  public constructor(config: __Configuration, http: HttpClient) {
    super(config, http)
  }

  /**
   * @return Success
   */
  getStateResponse(): __Observable<__StrictHttpResponse<CartState>> {
    let __params = this.newParams()
    let __headers = new HttpHeaders()
    let __body: any = null
    let req = new HttpRequest<any>('GET', this.rootUrl + `/cart/status`, __body, {
      headers: __headers,
      params: __params,
      responseType: 'json'
    })

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map(_r => {
        return _r as __StrictHttpResponse<CartState>
      })
    )
  }

  /**
   * @return Success
   */
  getState(): __Observable<CartState> {
    return this.getStateResponse().pipe(__map(_r => _r.body as CartState))
  }

  /**
   * @param request
   * @return Success
   */
  addServiceResponse(request?: AddCartRequest): __Observable<__StrictHttpResponse<boolean>> {
    let __params = this.newParams()
    let __headers = new HttpHeaders()
    let __body: any = null
    __body = request
    let req = new HttpRequest<any>('POST', this.rootUrl + `/cart/add`, __body, {
      headers: __headers,
      params: __params,
      responseType: 'json'
    })

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map(_r => {
        return (_r as HttpResponse<any>).clone({
          body: (_r as HttpResponse<any>).body === true
        }) as __StrictHttpResponse<boolean>
      })
    )
  }

  /**
   * @param request
   * @return Success
   */
  addService(request?: AddCartRequest): __Observable<boolean> {
    return this.addServiceResponse(request).pipe(__map(_r => _r.body as boolean))
  }

  /**
   * @param request
   * @return Success
   */
  addMultiServiceResponse(request?: Array<AddCartRequest>): __Observable<__StrictHttpResponse<boolean>> {
    let __params = this.newParams()
    let __headers = new HttpHeaders()
    let __body: any = null
    __body = request
    let req = new HttpRequest<any>('POST', this.rootUrl + `/cart/addmulti`, __body, {
      headers: __headers,
      params: __params,
      responseType: 'json'
    })

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map(_r => {
        return (_r as HttpResponse<any>).clone({
          body: (_r as HttpResponse<any>).body === true
        }) as __StrictHttpResponse<boolean>
      })
    )
  }

  /**
   * @param request
   * @return Success
   */
  addMultiService(request?: Array<AddCartRequest>): __Observable<boolean> {
    return this.addMultiServiceResponse(request).pipe(__map(_r => _r.body as boolean))
  }

  /**
   * @return Success
   */
  listResponse(): __Observable<__StrictHttpResponse<Array<OrderDetail>>> {
    let __params = this.newParams()
    let __headers = new HttpHeaders()
    let __body: any = null
    let req = new HttpRequest<any>('GET', this.rootUrl + `/cart/list`, __body, {
      headers: __headers,
      params: __params,
      responseType: 'json'
    })

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map(_r => {
        return _r as __StrictHttpResponse<Array<OrderDetail>>
      })
    )
  }

  /**
   * @return Success
   */
  list(): __Observable<Array<OrderDetail>> {
    return this.listResponse().pipe(__map(_r => _r.body as Array<OrderDetail>))
  }

  /**
   * @param params The `CartService.CheckoutParams` containing the following parameters:
   *
   * - `successUrl`:
   *
   * - `errorUrl`:
   *
   * @return Success
   */
  checkoutResponse(params: CartService.CheckoutParams): __Observable<__StrictHttpResponse<string>> {
    let __params = this.newParams()
    let __headers = new HttpHeaders()
    let __body: any = null
    if (params.successUrl != null) __params = __params.set('successUrl', params.successUrl.toString())
    if (params.errorUrl != null) __params = __params.set('errorUrl', params.errorUrl.toString())
    let req = new HttpRequest<any>('GET', this.rootUrl + `/cart/checkout`, __body, {
      headers: __headers,
      params: __params,
      responseType: 'json'
    })

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map(_r => {
        return _r as __StrictHttpResponse<string>
      })
    )
  }

  /**
   * @param params The `CartService.CheckoutParams` containing the following parameters:
   *
   * - `successUrl`:
   *
   * - `errorUrl`:
   *
   * @return Success
   */
  checkout(params: CartService.CheckoutParams): __Observable<string> {
    return this.checkoutResponse(params).pipe(__map(_r => _r.body as string))
  }

  /**
   * @return Success
   */
  getOrderResponse(): __Observable<__StrictHttpResponse<string>> {
    let __params = this.newParams()
    let __headers = new HttpHeaders()
    let __body: any = null
    let req = new HttpRequest<any>('GET', this.rootUrl + `/cart/getorder`, __body, {
      headers: __headers,
      params: __params,
      responseType: 'json'
    })

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map(_r => {
        return _r as __StrictHttpResponse<string>
      })
    )
  }

  /**
   * @return Success
   */
  getOrder(): __Observable<string> {
    return this.getOrderResponse().pipe(__map(_r => _r.body as string))
  }

  /**
   * @param id undefined
   * @return Success
   */
  plusServiceResponse(id: number): __Observable<__StrictHttpResponse<boolean>> {
    let __params = this.newParams()
    let __headers = new HttpHeaders()
    let __body: any = null

    let req = new HttpRequest<any>('POST', this.rootUrl + `/cart/plus/${id}`, __body, {
      headers: __headers,
      params: __params,
      responseType: 'json'
    })

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map(_r => {
        return (_r as HttpResponse<any>).clone({
          body: (_r as HttpResponse<any>).body === true
        }) as __StrictHttpResponse<boolean>
      })
    )
  }

  /**
   * @param id undefined
   * @return Success
   */
  plusService(id: number): __Observable<boolean> {
    return this.plusServiceResponse(id).pipe(__map(_r => _r.body as boolean))
  }

  /**
   * @param id undefined
   * @return Success
   */
  minusServiceResponse(id: number): __Observable<__StrictHttpResponse<boolean>> {
    let __params = this.newParams()
    let __headers = new HttpHeaders()
    let __body: any = null

    let req = new HttpRequest<any>('POST', this.rootUrl + `/cart/minus/${id}`, __body, {
      headers: __headers,
      params: __params,
      responseType: 'json'
    })

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map(_r => {
        return (_r as HttpResponse<any>).clone({
          body: (_r as HttpResponse<any>).body === true
        }) as __StrictHttpResponse<boolean>
      })
    )
  }

  /**
   * @param id undefined
   * @return Success
   */
  minusService(id: number): __Observable<boolean> {
    return this.minusServiceResponse(id).pipe(__map(_r => _r.body as boolean))
  }

  /**
   * @param id undefined
   * @return Success
   */
  deleteServiceResponse(id: number): __Observable<__StrictHttpResponse<boolean>> {
    let __params = this.newParams()
    let __headers = new HttpHeaders()
    let __body: any = null

    let req = new HttpRequest<any>('DELETE', this.rootUrl + `/cart/delete/${id}`, __body, {
      headers: __headers,
      params: __params,
      responseType: 'json'
    })

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map(_r => {
        return (_r as HttpResponse<any>).clone({
          body: (_r as HttpResponse<any>).body === true
        }) as __StrictHttpResponse<boolean>
      })
    )
  }

  /**
   * @param id undefined
   * @return Success
   */
  deleteService(id: number): __Observable<boolean> {
    return this.deleteServiceResponse(id).pipe(__map(_r => _r.body as boolean))
  }

  /**
   * @param params The `CartService.SetCountParams` containing the following parameters:
   *
   * - `id`:
   *
   * - `count`:
   *
   * @return Success
   */
  setCountResponse(params: CartService.SetCountParams): __Observable<__StrictHttpResponse<boolean>> {
    let __params = this.newParams()
    let __headers = new HttpHeaders()
    let __body: any = null

    if (params.count != null) __params = __params.set('count', params.count.toString())
    let req = new HttpRequest<any>('POST', this.rootUrl + `/cart/setcount/${params.id}`, __body, {
      headers: __headers,
      params: __params,
      responseType: 'json'
    })

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map(_r => {
        return (_r as HttpResponse<any>).clone({
          body: (_r as HttpResponse<any>).body === true
        }) as __StrictHttpResponse<boolean>
      })
    )
  }

  /**
   * @param params The `CartService.SetCountParams` containing the following parameters:
   *
   * - `id`:
   *
   * - `count`:
   *
   * @return Success
   */
  setCount(params: CartService.SetCountParams): __Observable<boolean> {
    return this.setCountResponse(params).pipe(__map(_r => _r.body as boolean))
  }
  deleteMarkedResponse(): __Observable<__StrictHttpResponse<any>> {
    let __params = this.newParams()
    let __headers = new HttpHeaders()
    let __body: any = null
    let req = new HttpRequest<any>('DELETE', this.rootUrl + `/cart/complete`, __body, {
      headers: __headers,
      params: __params,
      responseType: 'json'
    })

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map(_r => {
        return _r as __StrictHttpResponse<any>
      })
    )
  }
  deleteMarked(): __Observable<any> {
    return this.deleteMarkedResponse().pipe(__map(_r => _r.body as any))
  }
}

module CartService {
  /**
   * Parameters for Checkout
   */
  export interface CheckoutParams {
    successUrl?: string
    errorUrl?: string
  }

  /**
   * Parameters for SetCount
   */
  export interface SetCountParams {
    id: number
    count?: number
  }
}

export { CartService }
