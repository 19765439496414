import { Manager, NotebookService, ManagersInfo } from '@alliance/employer/data-access'
import { AuthService } from '@alliance/shared/auth/api'
import { RxStateService } from '@alliance/shared/models'
import { Injectable } from '@angular/core'
import { Observable } from 'rxjs'
import { filter, switchMap, take, withLatestFrom } from 'rxjs/operators'

@Injectable({
  providedIn: 'root'
})
export class ManagerService extends RxStateService<{
  manager: Manager | null
  managers: ManagersInfo | null
}> {
  public constructor(private notebookService: NotebookService, private authenticationService: AuthService) {
    super()

    this.initState({
      manager: null,
      managers: null
    })
  }

  public getManager$(): Observable<Manager | null> {
    this.hold(
      this.select('manager').pipe(
        take(1),
        withLatestFrom(this.authenticationService.token$),
        filter(([manager, token]) => !!token && !manager),
        switchMap(() => this.notebookService.getManager())
      ),
      (manager: Manager) => this.set({ manager })
    )
    return this.select('manager')
  }

  public getManagers$(): Observable<ManagersInfo | null> {
    this.hold(
      this.select('managers').pipe(
        take(1),
        withLatestFrom(this.authenticationService.token$),
        filter(([managers, token]) => !!token && !managers),
        switchMap(() => this.notebookService.getManagers(this.authenticationService.user?.MultiUserId || 0))
      ),
      (managers: ManagersInfo | null) => this.set({ managers })
    )
    return this.select('managers')
  }
}
