import { Injectable } from '@angular/core'
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http'
import { BaseService as __BaseService } from '../base-service'
import { JobseekerDataAccessConfiguration as __Configuration } from '../jobseeker-data-access-configuration'
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response'
import { Observable as __Observable } from 'rxjs'
import { map as __map, filter as __filter } from 'rxjs/operators'

import { VacancySearchUserResponse } from '../models/vacancy-search-user-response'
import { VacancySearchRequest } from '../models/vacancy-search-request'
import { VacancyFullDto } from '../models/vacancy-full-dto'
import { QuestionInfo } from '../models/question-info'
import { SynonymousInfo } from '../models/synonymous-info'
import { CityInfo } from '../models/city-info'
import { ApplyResponse } from '../models/apply-response'
import { ApplyModel } from '../models/apply-model'
import { ApplyModelBase } from '../models/apply-model-base'
import { NoCvApplyResponse } from '../models/no-cv-apply-response'
import { NoCvApplyRequest } from '../models/no-cv-apply-request'
import { SimilarVacanciesResponse } from '../models/similar-vacancies-response'
import { SimilarVacanciesRequest } from '../models/similar-vacancies-request'
@Injectable({
  providedIn: 'root'
})
class VacancyService extends __BaseService {
  public static readonly vacancySearchPath = '/vacancy/search'
  public static readonly vacancySearchPostPath = '/vacancy/search'
  public static readonly vacancySearchGetPath = '/vacancy'
  public static readonly vacancySearchGetCQPath = '/vacancy/questions'
  public static readonly vacancySearchGetVacancyTagsPath = '/vacancy/tags'
  public static readonly vacancySearchGetVacancyCitiesPath = '/vacancy/cities'
  public static readonly vacancySearchGetVacanciesCountPath = '/vacancy/count'
  public static readonly vacancyApplyApplyPath = '/vacancy/apply'
  public static readonly vacancyApplyApplyAttachPath = '/vacancy/applyattach'
  public static readonly vacancyApplyUnloggedApplyAttachPath = '/vacancy/applyunlogged'
  public static readonly vacancyApplyPostPath = '/vacancy/nocvapply'
  public static readonly vacancyApplyApplyClickPath = '/vacancy/applyclick'
  public static readonly vacancyApplyVacancyViewPath = '/vacancy/vacancyview'
  public static readonly vacancyApplyVacancyView_1Path = '/vacancy/{vacancyId}/view'
  public static readonly vacancyApplyPhoneClickPath = '/vacancy/phoneclick'
  public static readonly vacancyApplyLinkClickPath = '/vacancy/linkclick/{vacancyId}'
  public static readonly vacancySimilarPostPath = '/vacancy/similar'
  public static readonly vacancySimilarPost2Path = '/vacancy/similar2'

  public constructor(config: __Configuration, http: HttpClient) {
    super(config, http)
  }

  /**
   * @param params The `VacancyService.VacancySearchParams` containing the following parameters:
   *
   * - `viewedVacancyIds`:
   *
   * - `ukrainian`:
   *
   * - `uid`:
   *
   * - `sortBy`:
   *
   * - `showAgency`:
   *
   * - `searchMode`:
   *
   * - `scheduleId`:
   *
   * - `salary`:
   *
   * - `rubricIds`:
   *
   * - `profLevelIds`:
   *
   * - `period`:
   *
   * - `parentId`:
   *
   * - `page`:
   *
   * - `noSalary`:
   *
   * - `nearRegions`:
   *
   * - `metroIds`:
   *
   * - `longitude`:
   *
   * - `latitude`:
   *
   * - `lastViewDate`:
   *
   * - `keyWords`:
   *
   * - `isSynonym`:
   *
   * - `districtIds`:
   *
   * - `count`:
   *
   * - `cityId`:
   *
   * - `cid`:
   *
   * - `additionalKeywords`:
   *
   * @return OK
   */
  vacancySearchResponse(params: VacancyService.VacancySearchParams): __Observable<__StrictHttpResponse<VacancySearchUserResponse>> {
    let __params = this.newParams()
    let __headers = new HttpHeaders()
    let __body: any = null
    ;(params.viewedVacancyIds || []).forEach(val => {
      if (val != null) __params = __params.append('viewedVacancyIds', val.toString())
    })
    if (params.ukrainian != null) __params = __params.set('ukrainian', params.ukrainian.toString())
    if (params.uid != null) __params = __params.set('uid', params.uid.toString())
    if (params.sortBy != null) __params = __params.set('sortBy', params.sortBy.toString())
    if (params.showAgency != null) __params = __params.set('showAgency', params.showAgency.toString())
    if (params.searchMode != null) __params = __params.set('searchMode', params.searchMode.toString())
    if (params.scheduleId != null) __params = __params.set('scheduleId', params.scheduleId.toString())
    if (params.salary != null) __params = __params.set('salary', params.salary.toString())
    ;(params.rubricIds || []).forEach(val => {
      if (val != null) __params = __params.append('rubricIds', val.toString())
    })
    ;(params.profLevelIds || []).forEach(val => {
      if (val != null) __params = __params.append('profLevelIds', val.toString())
    })
    if (params.period != null) __params = __params.set('period', params.period.toString())
    if (params.parentId != null) __params = __params.set('parentId', params.parentId.toString())
    if (params.page != null) __params = __params.set('page', params.page.toString())
    if (params.noSalary != null) __params = __params.set('noSalary', params.noSalary.toString())
    if (params.nearRegions != null) __params = __params.set('nearRegions', params.nearRegions.toString())
    ;(params.metroIds || []).forEach(val => {
      if (val != null) __params = __params.append('metroIds', val.toString())
    })
    if (params.longitude != null) __params = __params.set('longitude', params.longitude.toString())
    if (params.latitude != null) __params = __params.set('latitude', params.latitude.toString())
    if (params.lastViewDate != null) __params = __params.set('lastViewDate', params.lastViewDate.toString())
    if (params.keyWords != null) __params = __params.set('keyWords', params.keyWords.toString())
    if (params.isSynonym != null) __params = __params.set('isSynonym', params.isSynonym.toString())
    ;(params.districtIds || []).forEach(val => {
      if (val != null) __params = __params.append('districtIds', val.toString())
    })
    if (params.count != null) __params = __params.set('count', params.count.toString())
    if (params.cityId != null) __params = __params.set('cityId', params.cityId.toString())
    if (params.cid != null) __params = __params.set('cid', params.cid.toString())
    ;(params.additionalKeywords || []).forEach(val => {
      if (val != null) __params = __params.append('additionalKeywords', val.toString())
    })
    let req = new HttpRequest<any>('GET', this.rootUrl + `/vacancy/search`, __body, {
      headers: __headers,
      params: __params,
      responseType: 'json'
    })

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map(_r => {
        return _r as __StrictHttpResponse<VacancySearchUserResponse>
      })
    )
  }

  /**
   * @param params The `VacancyService.VacancySearchParams` containing the following parameters:
   *
   * - `viewedVacancyIds`:
   *
   * - `ukrainian`:
   *
   * - `uid`:
   *
   * - `sortBy`:
   *
   * - `showAgency`:
   *
   * - `searchMode`:
   *
   * - `scheduleId`:
   *
   * - `salary`:
   *
   * - `rubricIds`:
   *
   * - `profLevelIds`:
   *
   * - `period`:
   *
   * - `parentId`:
   *
   * - `page`:
   *
   * - `noSalary`:
   *
   * - `nearRegions`:
   *
   * - `metroIds`:
   *
   * - `longitude`:
   *
   * - `latitude`:
   *
   * - `lastViewDate`:
   *
   * - `keyWords`:
   *
   * - `isSynonym`:
   *
   * - `districtIds`:
   *
   * - `count`:
   *
   * - `cityId`:
   *
   * - `cid`:
   *
   * - `additionalKeywords`:
   *
   * @return OK
   */
  vacancySearch(params: VacancyService.VacancySearchParams): __Observable<VacancySearchUserResponse> {
    return this.vacancySearchResponse(params).pipe(__map(_r => _r.body as VacancySearchUserResponse))
  }

  /**
   * @param request undefined
   * @return OK
   */
  vacancySearchPostResponse(request: VacancySearchRequest): __Observable<__StrictHttpResponse<VacancySearchUserResponse>> {
    let __params = this.newParams()
    let __headers = new HttpHeaders()
    let __body: any = null
    __body = request
    let req = new HttpRequest<any>('POST', this.rootUrl + `/vacancy/search`, __body, {
      headers: __headers,
      params: __params,
      responseType: 'json'
    })

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map(_r => {
        return _r as __StrictHttpResponse<VacancySearchUserResponse>
      })
    )
  }

  /**
   * @param request undefined
   * @return OK
   */
  vacancySearchPost(request: VacancySearchRequest): __Observable<VacancySearchUserResponse> {
    return this.vacancySearchPostResponse(request).pipe(__map(_r => _r.body as VacancySearchUserResponse))
  }

  /**
   * @param params The `VacancyService.VacancySearchGetParams` containing the following parameters:
   *
   * - `ukrainian`:
   *
   * - `isFromElasticSearch`:
   *
   * - `id`:
   *
   * - `cid`:
   *
   * @return OK
   */
  vacancySearchGetResponse(params: VacancyService.VacancySearchGetParams): __Observable<__StrictHttpResponse<VacancyFullDto>> {
    let __params = this.newParams()
    let __headers = new HttpHeaders()
    let __body: any = null
    if (params.ukrainian != null) __params = __params.set('ukrainian', params.ukrainian.toString())
    if (params.isFromElasticSearch != null) __params = __params.set('isFromElasticSearch', params.isFromElasticSearch.toString())
    if (params.id != null) __params = __params.set('id', params.id.toString())
    if (params.cid != null) __params = __params.set('cid', params.cid.toString())
    let req = new HttpRequest<any>('GET', this.rootUrl + `/vacancy`, __body, {
      headers: __headers,
      params: __params,
      responseType: 'json'
    })

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map(_r => {
        return _r as __StrictHttpResponse<VacancyFullDto>
      })
    )
  }

  /**
   * @param params The `VacancyService.VacancySearchGetParams` containing the following parameters:
   *
   * - `ukrainian`:
   *
   * - `isFromElasticSearch`:
   *
   * - `id`:
   *
   * - `cid`:
   *
   * @return OK
   */
  vacancySearchGet(params: VacancyService.VacancySearchGetParams): __Observable<VacancyFullDto> {
    return this.vacancySearchGetResponse(params).pipe(__map(_r => _r.body as VacancyFullDto))
  }

  /**
   * @param id undefined
   * @return OK
   */
  vacancySearchGetCQResponse(id: number): __Observable<__StrictHttpResponse<Array<QuestionInfo>>> {
    let __params = this.newParams()
    let __headers = new HttpHeaders()
    let __body: any = null
    if (id != null) __params = __params.set('id', id.toString())
    let req = new HttpRequest<any>('GET', this.rootUrl + `/vacancy/questions`, __body, {
      headers: __headers,
      params: __params,
      responseType: 'json'
    })

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map(_r => {
        return _r as __StrictHttpResponse<Array<QuestionInfo>>
      })
    )
  }

  /**
   * @param id undefined
   * @return OK
   */
  vacancySearchGetCQ(id: number): __Observable<Array<QuestionInfo>> {
    return this.vacancySearchGetCQResponse(id).pipe(__map(_r => _r.body as Array<QuestionInfo>))
  }

  /**
   * @param id undefined
   * @return OK
   */
  vacancySearchGetVacancyTagsResponse(id: number): __Observable<__StrictHttpResponse<Array<SynonymousInfo>>> {
    let __params = this.newParams()
    let __headers = new HttpHeaders()
    let __body: any = null
    if (id != null) __params = __params.set('id', id.toString())
    let req = new HttpRequest<any>('GET', this.rootUrl + `/vacancy/tags`, __body, {
      headers: __headers,
      params: __params,
      responseType: 'json'
    })

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map(_r => {
        return _r as __StrictHttpResponse<Array<SynonymousInfo>>
      })
    )
  }

  /**
   * @param id undefined
   * @return OK
   */
  vacancySearchGetVacancyTags(id: number): __Observable<Array<SynonymousInfo>> {
    return this.vacancySearchGetVacancyTagsResponse(id).pipe(__map(_r => _r.body as Array<SynonymousInfo>))
  }

  /**
   * @param id undefined
   * @return OK
   */
  vacancySearchGetVacancyCitiesResponse(id: number): __Observable<__StrictHttpResponse<Array<CityInfo>>> {
    let __params = this.newParams()
    let __headers = new HttpHeaders()
    let __body: any = null
    if (id != null) __params = __params.set('id', id.toString())
    let req = new HttpRequest<any>('GET', this.rootUrl + `/vacancy/cities`, __body, {
      headers: __headers,
      params: __params,
      responseType: 'json'
    })

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map(_r => {
        return _r as __StrictHttpResponse<Array<CityInfo>>
      })
    )
  }

  /**
   * @param id undefined
   * @return OK
   */
  vacancySearchGetVacancyCities(id: number): __Observable<Array<CityInfo>> {
    return this.vacancySearchGetVacancyCitiesResponse(id).pipe(__map(_r => _r.body as Array<CityInfo>))
  }

  /**
   * @return OK
   */
  vacancySearchGetVacanciesCountResponse(): __Observable<__StrictHttpResponse<number>> {
    let __params = this.newParams()
    let __headers = new HttpHeaders()
    let __body: any = null
    let req = new HttpRequest<any>('GET', this.rootUrl + `/vacancy/count`, __body, {
      headers: __headers,
      params: __params,
      responseType: 'json'
    })

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map(_r => {
        return (_r as HttpResponse<any>).clone({
          body: parseFloat((_r as HttpResponse<any>).body as string)
        }) as __StrictHttpResponse<number>
      })
    )
  }

  /**
   * @return OK
   */
  vacancySearchGetVacanciesCount(): __Observable<number> {
    return this.vacancySearchGetVacanciesCountResponse().pipe(__map(_r => _r.body as number))
  }

  /**
   * @param model
   * @return OK
   */
  vacancyApplyApplyResponse(model: ApplyModel): __Observable<__StrictHttpResponse<ApplyResponse>> {
    let __params = this.newParams()
    let __headers = new HttpHeaders()
    let __body: any = null
    __body = model
    let req = new HttpRequest<any>('POST', this.rootUrl + `/vacancy/apply`, __body, {
      headers: __headers,
      params: __params,
      responseType: 'json'
    })

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map(_r => {
        return _r as __StrictHttpResponse<ApplyResponse>
      })
    )
  }

  /**
   * @param model
   * @return OK
   */
  vacancyApplyApply(model: ApplyModel): __Observable<ApplyResponse> {
    return this.vacancyApplyApplyResponse(model).pipe(__map(_r => _r.body as ApplyResponse))
  }

  /**
   * @param params The `VacancyService.VacancyApplyApplyAttachParams` containing the following parameters:
   *
   * - `model`: apply description
   *
   * - `file`: upload file
   *
   * @return OK
   */
  vacancyApplyApplyAttachResponse(params: VacancyService.VacancyApplyApplyAttachParams): __Observable<__StrictHttpResponse<ApplyResponse>> {
    let __params = this.newParams()
    let __headers = new HttpHeaders()
    let __body: any = null
    let __formData = new FormData()
    __body = __formData
    if (params.model != null) {
      __formData.append('model', JSON.stringify(params.model))
    }
    if (params.file != null) {
      __formData.append('file', params.file as string | Blob)
    }
    let req = new HttpRequest<any>('POST', this.rootUrl + `/vacancy/applyattach`, __body, {
      headers: __headers,
      params: __params,
      responseType: 'json'
    })

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map(_r => {
        return _r as __StrictHttpResponse<ApplyResponse>
      })
    )
  }

  /**
   * @param params The `VacancyService.VacancyApplyApplyAttachParams` containing the following parameters:
   *
   * - `model`: apply description
   *
   * - `file`: upload file
   *
   * @return OK
   */
  vacancyApplyApplyAttach(params: VacancyService.VacancyApplyApplyAttachParams): __Observable<ApplyResponse> {
    return this.vacancyApplyApplyAttachResponse(params).pipe(__map(_r => _r.body as ApplyResponse))
  }

  /**
   * @param params The `VacancyService.VacancyApplyUnloggedApplyAttachParams` containing the following parameters:
   *
   * - `model`: apply description
   *
   * - `file`: upload file
   *
   * @return OK
   */
  vacancyApplyUnloggedApplyAttachResponse(params: VacancyService.VacancyApplyUnloggedApplyAttachParams): __Observable<__StrictHttpResponse<ApplyResponse>> {
    let __params = this.newParams()
    let __headers = new HttpHeaders()
    let __body: any = null
    let __formData = new FormData()
    __body = __formData
    if (params.model != null) {
      __formData.append('model', JSON.stringify(params.model))
    }
    if (params.file != null) {
      __formData.append('file', params.file as string | Blob)
    }
    let req = new HttpRequest<any>('POST', this.rootUrl + `/vacancy/applyunlogged`, __body, {
      headers: __headers,
      params: __params,
      responseType: 'json'
    })

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map(_r => {
        return _r as __StrictHttpResponse<ApplyResponse>
      })
    )
  }

  /**
   * @param params The `VacancyService.VacancyApplyUnloggedApplyAttachParams` containing the following parameters:
   *
   * - `model`: apply description
   *
   * - `file`: upload file
   *
   * @return OK
   */
  vacancyApplyUnloggedApplyAttach(params: VacancyService.VacancyApplyUnloggedApplyAttachParams): __Observable<ApplyResponse> {
    return this.vacancyApplyUnloggedApplyAttachResponse(params).pipe(__map(_r => _r.body as ApplyResponse))
  }

  /**
   * @param request undefined
   * @return OK
   */
  vacancyApplyPostResponse(request: NoCvApplyRequest): __Observable<__StrictHttpResponse<NoCvApplyResponse>> {
    let __params = this.newParams()
    let __headers = new HttpHeaders()
    let __body: any = null
    __body = request
    let req = new HttpRequest<any>('POST', this.rootUrl + `/vacancy/nocvapply`, __body, {
      headers: __headers,
      params: __params,
      responseType: 'json'
    })

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map(_r => {
        return _r as __StrictHttpResponse<NoCvApplyResponse>
      })
    )
  }

  /**
   * @param request undefined
   * @return OK
   */
  vacancyApplyPost(request: NoCvApplyRequest): __Observable<NoCvApplyResponse> {
    return this.vacancyApplyPostResponse(request).pipe(__map(_r => _r.body as NoCvApplyResponse))
  }

  /**
   * @param id undefined
   * @return OK
   */
  vacancyApplyApplyClickResponse(id: number): __Observable<__StrictHttpResponse<{}>> {
    let __params = this.newParams()
    let __headers = new HttpHeaders()
    let __body: any = null
    if (id != null) __params = __params.set('id', id.toString())
    let req = new HttpRequest<any>('POST', this.rootUrl + `/vacancy/applyclick`, __body, {
      headers: __headers,
      params: __params,
      responseType: 'json'
    })

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map(_r => {
        return _r as __StrictHttpResponse<{}>
      })
    )
  }

  /**
   * @param id undefined
   * @return OK
   */
  vacancyApplyApplyClick(id: number): __Observable<{}> {
    return this.vacancyApplyApplyClickResponse(id).pipe(__map(_r => _r.body as {}))
  }

  /**
   * @param params The `VacancyService.VacancyApplyVacancyViewParams` containing the following parameters:
   *
   * - `id`:
   *
   * - `notebookId`:
   *
   * - `email`:
   *
   * @return OK
   */
  vacancyApplyVacancyViewResponse(params: VacancyService.VacancyApplyVacancyViewParams): __Observable<__StrictHttpResponse<{}>> {
    let __params = this.newParams()
    let __headers = new HttpHeaders()
    let __body: any = null
    if (params.id != null) __params = __params.set('id', params.id.toString())
    if (params.notebookId != null) __params = __params.set('notebookId', params.notebookId.toString())
    if (params.email != null) __params = __params.set('email', params.email.toString())
    let req = new HttpRequest<any>('POST', this.rootUrl + `/vacancy/vacancyview`, __body, {
      headers: __headers,
      params: __params,
      responseType: 'json'
    })

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map(_r => {
        return _r as __StrictHttpResponse<{}>
      })
    )
  }

  /**
   * @param params The `VacancyService.VacancyApplyVacancyViewParams` containing the following parameters:
   *
   * - `id`:
   *
   * - `notebookId`:
   *
   * - `email`:
   *
   * @return OK
   */
  vacancyApplyVacancyView(params: VacancyService.VacancyApplyVacancyViewParams): __Observable<{}> {
    return this.vacancyApplyVacancyViewResponse(params).pipe(__map(_r => _r.body as {}))
  }

  /**
   * @param vacancyId undefined
   * @return OK
   */
  vacancyApplyVacancyView_1Response(vacancyId: number): __Observable<__StrictHttpResponse<{}>> {
    let __params = this.newParams()
    let __headers = new HttpHeaders()
    let __body: any = null

    let req = new HttpRequest<any>('POST', this.rootUrl + `/vacancy/${vacancyId}/view`, __body, {
      headers: __headers,
      params: __params,
      responseType: 'json'
    })

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map(_r => {
        return _r as __StrictHttpResponse<{}>
      })
    )
  }

  /**
   * @param vacancyId undefined
   * @return OK
   */
  vacancyApplyVacancyView_1(vacancyId: number): __Observable<{}> {
    return this.vacancyApplyVacancyView_1Response(vacancyId).pipe(__map(_r => _r.body as {}))
  }

  /**
   * @param id undefined
   */
  vacancyApplyPhoneClickResponse(id: number): __Observable<__StrictHttpResponse<any>> {
    let __params = this.newParams()
    let __headers = new HttpHeaders()
    let __body: any = null
    if (id != null) __params = __params.set('id', id.toString())
    let req = new HttpRequest<any>('POST', this.rootUrl + `/vacancy/phoneclick`, __body, {
      headers: __headers,
      params: __params,
      responseType: 'json'
    })

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map(_r => {
        return _r as __StrictHttpResponse<any>
      })
    )
  }

  /**
   * @param id undefined
   */
  vacancyApplyPhoneClick(id: number): __Observable<any> {
    return this.vacancyApplyPhoneClickResponse(id).pipe(__map(_r => _r.body as any))
  }

  /**
   * @param vacancyId undefined
   */
  vacancyApplyLinkClickResponse(vacancyId: number): __Observable<__StrictHttpResponse<any>> {
    let __params = this.newParams()
    let __headers = new HttpHeaders()
    let __body: any = null

    let req = new HttpRequest<any>('POST', this.rootUrl + `/vacancy/linkclick/${vacancyId}`, __body, {
      headers: __headers,
      params: __params,
      responseType: 'json'
    })

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map(_r => {
        return _r as __StrictHttpResponse<any>
      })
    )
  }

  /**
   * @param vacancyId undefined
   */
  vacancyApplyLinkClick(vacancyId: number): __Observable<any> {
    return this.vacancyApplyLinkClickResponse(vacancyId).pipe(__map(_r => _r.body as any))
  }

  /**
   * @param request undefined
   * @return OK
   */
  vacancySimilarPostResponse(request: SimilarVacanciesRequest): __Observable<__StrictHttpResponse<Array<SimilarVacanciesResponse>>> {
    let __params = this.newParams()
    let __headers = new HttpHeaders()
    let __body: any = null
    __body = request
    let req = new HttpRequest<any>('POST', this.rootUrl + `/vacancy/similar`, __body, {
      headers: __headers,
      params: __params,
      responseType: 'json'
    })

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map(_r => {
        return _r as __StrictHttpResponse<Array<SimilarVacanciesResponse>>
      })
    )
  }

  /**
   * @param request undefined
   * @return OK
   */
  vacancySimilarPost(request: SimilarVacanciesRequest): __Observable<Array<SimilarVacanciesResponse>> {
    return this.vacancySimilarPostResponse(request).pipe(__map(_r => _r.body as Array<SimilarVacanciesResponse>))
  }

  /**
   * @param request undefined
   * @return OK
   */
  vacancySimilarPost2Response(request: SimilarVacanciesRequest): __Observable<__StrictHttpResponse<Array<SimilarVacanciesResponse>>> {
    let __params = this.newParams()
    let __headers = new HttpHeaders()
    let __body: any = null
    __body = request
    let req = new HttpRequest<any>('POST', this.rootUrl + `/vacancy/similar2`, __body, {
      headers: __headers,
      params: __params,
      responseType: 'json'
    })

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map(_r => {
        return _r as __StrictHttpResponse<Array<SimilarVacanciesResponse>>
      })
    )
  }

  /**
   * @param request undefined
   * @return OK
   */
  vacancySimilarPost2(request: SimilarVacanciesRequest): __Observable<Array<SimilarVacanciesResponse>> {
    return this.vacancySimilarPost2Response(request).pipe(__map(_r => _r.body as Array<SimilarVacanciesResponse>))
  }
}

module VacancyService {
  /**
   * Parameters for VacancySearch
   */
  export interface VacancySearchParams {
    viewedVacancyIds?: Array<number>
    ukrainian?: boolean
    uid?: number
    sortBy?: 'Date' | 'Geo'
    showAgency?: boolean
    searchMode?: number
    scheduleId?: number
    salary?: number
    rubricIds?: Array<number>
    profLevelIds?: Array<number>
    period?: number
    parentId?: number
    page?: number
    noSalary?: boolean
    nearRegions?: boolean
    metroIds?: Array<number>
    longitude?: number
    latitude?: number
    lastViewDate?: boolean
    keyWords?: string
    isSynonym?: boolean
    districtIds?: Array<number>
    count?: number
    cityId?: number
    cid?: string
    additionalKeywords?: Array<string>
  }

  /**
   * Parameters for VacancySearchGet
   */
  export interface VacancySearchGetParams {
    ukrainian?: boolean
    isFromElasticSearch?: boolean
    id?: number
    cid?: string
  }

  /**
   * Parameters for VacancyApplyApplyAttach
   */
  export interface VacancyApplyApplyAttachParams {
    /**
     * apply description
     */
    model: ApplyModel

    /**
     * upload file
     */
    file: Blob
  }

  /**
   * Parameters for VacancyApplyUnloggedApplyAttach
   */
  export interface VacancyApplyUnloggedApplyAttachParams {
    /**
     * apply description
     */
    model: ApplyModelBase

    /**
     * upload file
     */
    file?: Blob
  }

  /**
   * Parameters for VacancyApplyVacancyView
   */
  export interface VacancyApplyVacancyViewParams {
    id: number
    notebookId?: number
    email?: string
  }
}

export { VacancyService }
