import { JobseekerDomainModule } from '@alliance/jobseeker/domain'
import { JobseekerApiPrefixerInterceptor } from '@alliance/shared/interceptors'
import { CommonModule } from '@angular/common'
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http'
import { NgModule } from '@angular/core'
import { AcceptLanguageInterceptorService } from './interceptors/accept-language-interceptor.service'

@NgModule({
  imports: [CommonModule, HttpClientModule, JobseekerDomainModule],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AcceptLanguageInterceptorService,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: JobseekerApiPrefixerInterceptor,
      multi: true
    }
  ]
})
export class JobseekerApiModule {}
