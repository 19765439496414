const FONT_SIZE = {
  10: '10px',
  12: '12px',
  14: '14px',
  16: '16px',
  18: '18px',
  20: '20px',
  24: '24px',
  32: '32px',
  40: '40px',
  60: '60px'
}

const FONT_FAMILY = {
  sans: ['Roboto', '"San Francisco"', 'Helvetica', 'sans-serif'],
  glight: ['"Graphik Light"', 'sans-serif'],
  gregular: ['"Graphik Regular"', 'sans-serif'],
  gmedium: ['"Graphik Medium"', 'sans-serif'],
  gsemibold: ['"Graphik Semibold"', 'sans-serif'],
  gbold: ['"Graphik Bold"', 'sans-serif']
}

module.exports = { FONT_FAMILY, FONT_SIZE }
