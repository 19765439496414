declare const window: Window
declare const process: { env: { ENV_NAME?: string } }

// CONSIDER: do not expose me, I not supposed to be used outside of this module, and keeped here just for backward compatibility
export const getDomain = ({ production }: { production: boolean }): string => fromWindow() || fromProcess() || fromDevMode(production)

const fromWindow = (): string | null => {
  if (typeof window === 'undefined') {
    return null
  }
  if (window?.location?.hostname?.replace('rabota', 'robota')?.includes('dev.robota.ua')) {
    return 'dev.robota.ua'
  } else if (window?.location?.hostname?.replace('rabota', 'robota')?.includes('test2.robota.ua')) {
    return 'test2.robota.ua'
  } else {
    return null
  }
}

const fromProcess = (): string | null => {
  if (typeof process === 'undefined') {
    return null
  }
  switch (process.env.ENV_NAME?.toLowerCase()) {
    case 'production':
      return 'robota.ua'
    case 'prod':
      return 'robota.ua'
    case 'staging':
      return 'test2.robota.ua'
    case 'test2':
      return 'test2.robota.ua'
    case 'dev':
      return 'dev.robota.ua'
    default:
      return null
  }
}

// CONSIDER: isDevMode can not be used here because of "Error: Cannot enable prod mode after platform setup" error, consider wiring up via DI
const fromDevMode = (production: boolean): string => (production ? 'robota.ua' : 'dev.robota.ua')
