import { Injectable } from '@angular/core'
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http'
import { BaseService as __BaseService } from '../base-service'
import { JobseekerDataAccessConfiguration as __Configuration } from '../jobseeker-data-access-configuration'
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response'
import { Observable as __Observable } from 'rxjs'
import { map as __map, filter as __filter } from 'rxjs/operators'

@Injectable({
  providedIn: 'root'
})
class GoogleService extends __BaseService {
  public static readonly googleGooglePath = '/login/google'
  public static readonly googleTokenPath = '/login/google/token'

  public constructor(config: __Configuration, http: HttpClient) {
    super(config, http)
  }

  /**
   * @param params The `GoogleService.GoogleGoogleParams` containing the following parameters:
   *
   * - `state`:
   *
   * - `code`:
   *
   * @return OK
   */
  googleGoogleResponse(params: GoogleService.GoogleGoogleParams): __Observable<__StrictHttpResponse<{}>> {
    let __params = this.newParams()
    let __headers = new HttpHeaders()
    let __body: any = null
    if (params.state != null) __params = __params.set('state', params.state.toString())
    if (params.code != null) __params = __params.set('code', params.code.toString())
    let req = new HttpRequest<any>('GET', this.rootUrl + `/login/google`, __body, {
      headers: __headers,
      params: __params,
      responseType: 'json'
    })

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map(_r => {
        return _r as __StrictHttpResponse<{}>
      })
    )
  }

  /**
   * @param params The `GoogleService.GoogleGoogleParams` containing the following parameters:
   *
   * - `state`:
   *
   * - `code`:
   *
   * @return OK
   */
  googleGoogle(params: GoogleService.GoogleGoogleParams): __Observable<{}> {
    return this.googleGoogleResponse(params).pipe(__map(_r => _r.body as {}))
  }

  /**
   * @param id_token undefined
   * @return OK
   */
  googleTokenResponse(idToken: string): __Observable<__StrictHttpResponse<{}>> {
    let __params = this.newParams()
    let __headers = new HttpHeaders()
    let __body: any = null
    if (idToken != null) __params = __params.set('id_token', idToken.toString())
    let req = new HttpRequest<any>('POST', this.rootUrl + `/login/google/token`, __body, {
      headers: __headers,
      params: __params,
      responseType: 'json'
    })

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map(_r => {
        return _r as __StrictHttpResponse<{}>
      })
    )
  }

  /**
   * @param id_token undefined
   * @return OK
   */
  googleToken(idToken: string): __Observable<{}> {
    return this.googleTokenResponse(idToken).pipe(__map(_r => _r.body as {}))
  }
}

module GoogleService {
  /**
   * Parameters for GoogleGoogle
   */
  export interface GoogleGoogleParams {
    state?: string
    code?: string
  }
}

export { GoogleService }
