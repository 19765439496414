const SCREENS = {
  321: '321px',
  480: '480px',
  600: '600px',
  700: '700px',
  760: '760px',
  1000: '1000px',
  1024: '1024px',
  1280: '1280px',
  1200: '1200px',
  1300: '1300px',
  1400: '1400px',
  1800: '1800px'
}

module.exports = { SCREENS }
