import { DictionaryService } from '@alliance/jobseeker/api' // TODO: new dictionary outside seeker/employer scope
import { TranslationService } from '@alliance/shared/translation'
import { Injectable } from '@angular/core'
import { HttpParams } from '@angular/common/http'
import { Observable } from 'rxjs'
import { map } from 'rxjs/operators'
import { HelpersService } from '../helpers.service'
import { PartialSeoParamsResponse, PlatformHosts, ProzoraSeoParams } from '../models'
import { Translations } from '../localization/translations'

@Injectable({
  providedIn: 'root'
})
export class ProzoraDictionaryService {
  private readonly DEFAULT_FILTERS = {
    keyword: '',
    cityId: '0',
    rubricId: '0'
  }
  public constructor(private translations: Translations, private translationService: TranslationService, private dictionaryService: DictionaryService, private helpersService: HelpersService) {}

  public getParams({ params: { keyWords = '', cityId = 0, rubricId = 0 } }: ProzoraSeoParams): Observable<PartialSeoParamsResponse> {
    const url = this.getuUrlWithParams(keyWords, cityId, rubricId)

    const statisticTranslation = this.translationService.translate<string>(this.translations.prozora.h1.statistic)
    const forPositionTranslation = this.translationService.translate<string>(this.translations.prozora.h1.forPosition)
    const ukraineTranslation = this.translationService.translate<string>(this.translations.prozora.ukraine)
    const poTranslation = this.translationService.translate<string>(this.translations.prozora.h1.in)
    const vTranslation = this.translationService.translate(this.translations.prozora.in)

    let h1 = statisticTranslation

    return this.dictionaryService.getCityName$(cityId || 0, null, true).pipe(
      map(cityName => (cityId === 0 ? ukraineTranslation : cityName)),
      map(cityName => {
        if (keyWords !== this.DEFAULT_FILTERS.keyword) {
          h1 += ` ${forPositionTranslation} ${keyWords}`
        }
        if (+cityId !== +(this.DEFAULT_FILTERS.cityId ?? 0)) {
          h1 += ` ${poTranslation} ${cityName}`
        }

        if (+cityId === +(this.DEFAULT_FILTERS.cityId ?? 0)) {
          h1 += ` ${poTranslation} ${ukraineTranslation}`
        }

        const cityText = ` ${vTranslation} ${cityName}`
        const keywordText = keyWords?.trim().length ? ` ${keyWords}` : ''

        return {
          title: this.translationService.translate(this.translations.prozora.title, {
            cityInflected: cityText
          }),
          description: this.translationService.translate(this.translations.prozora.description, {
            cityInflected: cityText,
            keyword: keywordText
          }),
          canonicalUrl: this.helpersService.createURL(PlatformHosts.desktop, url),
          alternateUrl: this.helpersService.createURL(PlatformHosts.desktop, url),
          hrefLang: this.helpersService.createHrefLangURLs(url, url),
          h1: [h1],
          jsonLd: {
            desktop: this.helpersService.createJsonLd(PlatformHosts.desktop),
            mobile: this.helpersService.createJsonLd(PlatformHosts.desktop)
          },
          noIndexNoFollow: false
        }
      })
    )
  }

  private getuUrlWithParams(keyword: string = '', cityId: number = 0, rubric: number = 0): string {
    let params = new HttpParams()
    params = params.set('keyword', keyword?.trim().toLowerCase() || '')
    params = params.set('cityId', cityId.toString() || '')
    params = params.set('rubricId', rubric.toString() || '')
    return `prozora?${params.toString()}`
  }
}
