import { DataLayerPushEvent } from '@alliance/shared/utils'

export const REGISTRATION_COMPLETE_EVENT: DataLayerPushEvent = {
  eventCategory: 'EMP_Registration',
  event: 'EMP_Registration',
  eventAction: 'RegComplete',
  eventLabel: 'registrationForm'
}

export const REGISTRATION_FINISHED_EVENT: DataLayerPushEvent = {
  eventCategory: 'EMP_Registration',
  event: 'EMP_Registration',
  eventAction: 'RegSuccess',
  eventLabel: 'companyInfo'
}

export const REGISTRATION_CREATE_SEEKER_PROFILE_EVENT: DataLayerPushEvent = {
  eventCategory: 'EMP_Registration',
  event: 'EMP_Registration',
  eventAction: 'createSeekerProfile',
  eventLabel: 'companyInfo'
}

export const REGISTRATION_RESEND_EMAIL_EVENT: DataLayerPushEvent = {
  eventCategory: 'EMP_Registration',
  event: 'EMP_Registration',
  eventAction: 'reSendEmail',
  eventLabel: 'confirmEmail'
}

export const REGISTRATION_ASK_SUPPORT_EVENT: DataLayerPushEvent = {
  eventCategory: 'EMP_Registration',
  event: 'EMP_Registration',
  eventAction: 'support',
  eventLabel: 'confirmEmail'
}

export const REGISTRATION_LOGIN_EVENT: DataLayerPushEvent = {
  eventCategory: 'EMP_Registration',
  event: 'EMP_Registration',
  eventAction: 'RegistrationLogin',
  eventLabel: 'confirmEmail'
}

export const REGISTRATION_CLOSE_EMAIL_CONFIRM_EVENT: DataLayerPushEvent = {
  eventCategory: 'EMP_Registration',
  event: 'EMP_Registration',
  eventAction: 'closedСonfirmEmail',
  eventLabel: 'confirmEmail'
}

export const REGISTRATION_ERROR_EVENT: DataLayerPushEvent = {
  eventCategory: 'EMP_Registration',
  event: 'EMP_Registration',
  eventAction: 'error'
}

export const FINISH_REGISTRATION_ERROR_EVENT: DataLayerPushEvent = {
  eventCategory: 'EMP_Registration',
  event: 'EMP_Registration',
  eventAction: 'error',
  eventLabel: 'companyInfo'
}
