import { AuthService } from '@alliance/shared/auth/api'
import { SignalrMessageService, StreamTypeMap } from '@alliance/socket/api'
import { inject, InjectionToken } from '@angular/core'
import { Observable, of, share } from 'rxjs'
import { switchMap } from 'rxjs/operators'

export interface VacancyRecommendedCandidatesUpdatedMessage {
  vacancyId: string
  vacancyTitle: string
  newCount: number
}

export const VACANCY_RECOMMENDED_CANDIDATES_UPDATED_MESSAGE_TOKEN = new InjectionToken<Observable<VacancyRecommendedCandidatesUpdatedMessage | null>>(
  'VACANCY_RECOMMENDED_CANDIDATES_UPDATED_MESSAGE_TOKEN',
  {
    factory: (): Observable<VacancyRecommendedCandidatesUpdatedMessage | null> => {
      const authService = inject(AuthService)
      const signalrMessageService = inject(SignalrMessageService)

      return authService.token$.pipe(
        switchMap(token =>
          !!token && authService.isEmployer ? signalrMessageService.getStreamByType<VacancyRecommendedCandidatesUpdatedMessage>(StreamTypeMap.vacancyRecommendedCandidatesUpdated) : of(null)
        ),
        share()
      )
    }
  }
)
