import { Injectable } from '@angular/core'
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http'
import { BaseService as __BaseService } from '../base-service'
import { JobseekerDataAccessConfiguration as __Configuration } from '../jobseeker-data-access-configuration'
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response'
import { Observable as __Observable } from 'rxjs'
import { map as __map, filter as __filter } from 'rxjs/operators'

import { SendSmsResponse } from '../models/send-sms-response'
@Injectable({
  providedIn: 'root'
})
class CheckerService extends __BaseService {
  public static readonly checkerVersionPath = '/service/version'
  public static readonly checkerReleaseInfoPath = '/service/releaseinfo'
  public static readonly checkerGetElasticErrorsPath = '/service/elasticerror'
  public static readonly checkerValidateEmailPath = '/service/checkemail'
  public static readonly checkerSendPhoneCodePath = '/service/checkphone'
  public static readonly checkerValidatePhoneCodePath = '/service/checkphonecode'

  public constructor(config: __Configuration, http: HttpClient) {
    super(config, http)
  }

  /**
   * @return OK
   */
  checkerVersionResponse(): __Observable<__StrictHttpResponse<{}>> {
    let __params = this.newParams()
    let __headers = new HttpHeaders()
    let __body: any = null
    let req = new HttpRequest<any>('POST', this.rootUrl + `/service/version`, __body, {
      headers: __headers,
      params: __params,
      responseType: 'json'
    })

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map(_r => {
        return _r as __StrictHttpResponse<{}>
      })
    )
  }

  /**
   * @return OK
   */
  checkerVersion(): __Observable<{}> {
    return this.checkerVersionResponse().pipe(__map(_r => _r.body as {}))
  }

  /**
   * @return OK
   */
  checkerReleaseInfoResponse(): __Observable<__StrictHttpResponse<{}>> {
    let __params = this.newParams()
    let __headers = new HttpHeaders()
    let __body: any = null
    let req = new HttpRequest<any>('GET', this.rootUrl + `/service/releaseinfo`, __body, {
      headers: __headers,
      params: __params,
      responseType: 'json'
    })

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map(_r => {
        return _r as __StrictHttpResponse<{}>
      })
    )
  }

  /**
   * @return OK
   */
  checkerReleaseInfo(): __Observable<{}> {
    return this.checkerReleaseInfoResponse().pipe(__map(_r => _r.body as {}))
  }

  /**
   * @return OK
   */
  checkerGetElasticErrorsResponse(): __Observable<__StrictHttpResponse<{}>> {
    let __params = this.newParams()
    let __headers = new HttpHeaders()
    let __body: any = null
    let req = new HttpRequest<any>('GET', this.rootUrl + `/service/elasticerror`, __body, {
      headers: __headers,
      params: __params,
      responseType: 'json'
    })

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map(_r => {
        return _r as __StrictHttpResponse<{}>
      })
    )
  }

  /**
   * @return OK
   */
  checkerGetElasticErrors(): __Observable<{}> {
    return this.checkerGetElasticErrorsResponse().pipe(__map(_r => _r.body as {}))
  }

  /**
   * @param params The `CheckerService.CheckerValidateEmailParams` containing the following parameters:
   *
   * - `email`:
   *
   * - `test`:
   *
   * @return OK
   */
  checkerValidateEmailResponse(params: CheckerService.CheckerValidateEmailParams): __Observable<__StrictHttpResponse<{}>> {
    let __params = this.newParams()
    let __headers = new HttpHeaders()
    let __body: any = null
    if (params.email != null) __params = __params.set('email', params.email.toString())
    if (params.test != null) __params = __params.set('test', params.test.toString())
    let req = new HttpRequest<any>('POST', this.rootUrl + `/service/checkemail`, __body, {
      headers: __headers,
      params: __params,
      responseType: 'json'
    })

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map(_r => {
        return _r as __StrictHttpResponse<{}>
      })
    )
  }

  /**
   * @param params The `CheckerService.CheckerValidateEmailParams` containing the following parameters:
   *
   * - `email`:
   *
   * - `test`:
   *
   * @return OK
   */
  checkerValidateEmail(params: CheckerService.CheckerValidateEmailParams): __Observable<{}> {
    return this.checkerValidateEmailResponse(params).pipe(__map(_r => _r.body as {}))
  }

  /**
   * @param phone undefined
   * @return OK
   */
  checkerSendPhoneCodeResponse(phone: string): __Observable<__StrictHttpResponse<SendSmsResponse>> {
    let __params = this.newParams()
    let __headers = new HttpHeaders()
    let __body: any = null
    if (phone != null) __params = __params.set('phone', phone.toString())
    let req = new HttpRequest<any>('POST', this.rootUrl + `/service/checkphone`, __body, {
      headers: __headers,
      params: __params,
      responseType: 'json'
    })

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map(_r => {
        return _r as __StrictHttpResponse<SendSmsResponse>
      })
    )
  }

  /**
   * @param phone undefined
   * @return OK
   */
  checkerSendPhoneCode(phone: string): __Observable<SendSmsResponse> {
    return this.checkerSendPhoneCodeResponse(phone).pipe(__map(_r => _r.body as SendSmsResponse))
  }

  /**
   * @param params The `CheckerService.CheckerValidatePhoneCodeParams` containing the following parameters:
   *
   * - `phone`:
   *
   * - `code`:
   *
   * @return OK
   */
  checkerValidatePhoneCodeResponse(params: CheckerService.CheckerValidatePhoneCodeParams): __Observable<__StrictHttpResponse<{}>> {
    let __params = this.newParams()
    let __headers = new HttpHeaders()
    let __body: any = null
    if (params.phone != null) __params = __params.set('phone', params.phone.toString())
    if (params.code != null) __params = __params.set('code', params.code.toString())
    let req = new HttpRequest<any>('POST', this.rootUrl + `/service/checkphonecode`, __body, {
      headers: __headers,
      params: __params,
      responseType: 'json'
    })

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map(_r => {
        return _r as __StrictHttpResponse<{}>
      })
    )
  }

  /**
   * @param params The `CheckerService.CheckerValidatePhoneCodeParams` containing the following parameters:
   *
   * - `phone`:
   *
   * - `code`:
   *
   * @return OK
   */
  checkerValidatePhoneCode(params: CheckerService.CheckerValidatePhoneCodeParams): __Observable<{}> {
    return this.checkerValidatePhoneCodeResponse(params).pipe(__map(_r => _r.body as {}))
  }
}

module CheckerService {
  /**
   * Parameters for CheckerValidateEmail
   */
  export interface CheckerValidateEmailParams {
    email: string
    test?: boolean
  }

  /**
   * Parameters for CheckerValidatePhoneCode
   */
  export interface CheckerValidatePhoneCodeParams {
    phone: string
    code: string
  }
}

export { CheckerService }
