export const removeHTMLTags = (text: string): string => text.replace(/<\/?[^>]+(>|$)/g, '')

// Расшифровка регулярного выражения:
// /: Начало регулярного выражения.
// <: Это символ "меньше", который обозначает начало HTML-тега. Например, <div>, <img>, <script>.
// /?: Опциональная косая черта /, которая соответствует закрывающему тегу. То есть она захватывает как открывающий тег (например, <div>), так и закрывающий тег (например, </div>). Знак вопроса ? делает косую черту опциональной.
// [^>]+: Эта часть говорит: "Найди любую последовательность символов, кроме символа >". Это значит, что внутри угловых скобок < > могут быть любые символы, кроме символа >.
// [^>] — выражение внутри квадратных скобок означает "любой символ, кроме >".
// + — означает "один или более символов".
// (>|$): Это группа, которая говорит: "Найди либо символ >, либо конец строки ($)".
// > — конец HTML-тега.
// $ — конец строки. Этот случай важен для обработки незакрытых тегов. Например, если пользователь ввел неполный тег вроде <div, то регулярное выражение все равно его найдет.
// /g: Флаг g означает, что регулярное выражение должно искать все совпадения в строке (глобальный поиск), а не останавливаться после первого совпадения.
