/* tslint:disable */
/* eslint-disable */
import { ModuleWithProviders, NgModule, Optional, SkipSelf } from '@angular/core'
import { HttpClient } from '@angular/common/http'
import { Environment } from '@alliance/shared/environment'
import { PromoDataAccessConfigurationInterface, PromoDataAccessConfigurationInterfaceParams } from './promo-data-access-configuration-interface'

import { PrivatePromoCodeService } from './services/private-promo-code.service'
import { PublicPromocodeService } from './services/public-promocode.service'
import { SpecialActivityService } from './services/special-activity.service'

/**
 * Module that provides all services and configuration.
 */
@NgModule({
  imports: [],
  exports: [],
  declarations: [],
  providers: [
    {
      provide: PromoDataAccessConfigurationInterface,
      useFactory: (environment: Environment): PromoDataAccessConfigurationInterfaceParams => ({
        rootUrl: environment.promoApi
      }),
      deps: [Environment]
    },
    PrivatePromoCodeService,
    PublicPromocodeService,
    SpecialActivityService
  ]
})
export class PromoDataAccessModule {
  constructor(@Optional() @SkipSelf() parentModule: PromoDataAccessModule, @Optional() http: HttpClient) {
    if (parentModule) {
      throw new Error('PromoDataAccessModule is already loaded. Import in your base AppModule only.')
    }
    if (!http) {
      throw new Error('You need to import the HttpClientModule in your AppModule! \n' + 'See also https://github.com/angular/angular/issues/20575')
    }
  }

  static forRoot(params: PromoDataAccessConfigurationInterfaceParams): ModuleWithProviders<PromoDataAccessModule> {
    return {
      ngModule: PromoDataAccessModule,
      providers: [
        {
          provide: PromoDataAccessConfigurationInterface,
          useValue: params
        }
      ]
    }
  }
}
