import { EventManager } from '@angular/platform-browser'

import { CallbackFunction } from '../types/callback-function.type'

type EventManagerArg = ConstructorParameters<typeof EventManager>[0][0]

type EventManagerPlugin = {
  [K in keyof EventManagerArg]: EventManagerArg[K]
}

export abstract class AbstractEventPlugin implements EventManagerPlugin {
  protected abstract readonly modifier: string

  public manager!: EventManager

  public abstract addEventListener(element: HTMLElement, event: string, handler: CallbackFunction): CallbackFunction

  public supports(event: string): boolean {
    return event.includes(this.modifier)
  }

  // Note: This is not used in Ivy anymore. To be removed in version 14
  public addGlobalEventListener(): CallbackFunction {
    return () => ({})
  }

  protected unwrap(event: string): string {
    return event
      .split('.')
      .filter(v => !this.modifier.includes(v))
      .join('.')
  }
}
