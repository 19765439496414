import { CvDbSearchFacetsResponse, CvDbSearchHistoryItemResponse, CvDbSearchRequest, CvKnockout, CvNote, DistributionBin, FilterItem, ResumeInfo, SearchItem } from '@alliance/employer/data-access'
import { isValidKey } from '@alliance/shared/utils'
import { Injectable } from '@angular/core'
import { combineLatest, Observable } from 'rxjs'
import { distinctUntilChanged, filter, map, pluck } from 'rxjs/operators'
import { CvdbDistributionFacets, CvdbFacets } from '../models/cvdb-facets'
import { CvdbState, CvdbStorePendingModel } from './cvdb.model'
import { CvdbStore } from './cvdb.store'

@Injectable({
  providedIn: 'root'
})
export class CvdbQuery {
  public constructor(private store: CvdbStore) {}

  public getValue(): CvdbState {
    return this.store.get()
  }

  public getPending$(name: keyof CvdbStorePendingModel): Observable<boolean> {
    return this.store.select('pending').pipe(pluck(name), distinctUntilChanged())
  }

  public selectSearchHistoryList$(): Observable<CvDbSearchHistoryItemResponse[]> {
    return this.store.select('searchHistoryList')
  }

  public selectSelectedSearches$(): Observable<SearchItem[]> {
    return this.store.select('selectedSearches')
  }

  public selectSearchFilters$(): Observable<CvDbSearchRequest> {
    return this.store.select('searchFilters')
  }

  public selectFilteredSearchList$(): Observable<CvKnockout[]> {
    // local filtering:
    //  - is only sugar for consistent store structure
    //  - skipped due to complicated search logic
    return combineLatest([this.store.select('searchFilters'), this.store.select('searchList')]).pipe(map(([_, resumes]) => resumes ?? []))
  }

  public selectSearchTotal$(): Observable<number> {
    return this.store.select('searchTotal').pipe(filter((total): total is number => total !== null))
  }

  public selectSearchTotalFull$(): Observable<number> {
    return combineLatest([this.getPending$('searchList'), this.store.select('searchTotalFull').pipe(filter((totalFull): totalFull is number => totalFull !== null))]).pipe(
      filter(([pending]) => !pending),
      map(([, searchTotalFull]) => searchTotalFull)
    )
  }

  public selectId$(): Observable<number> {
    return this.store.select('id').pipe(filter((id): id is number => !!id))
  }

  public selectResume$(): Observable<ResumeInfo | null> {
    return combineLatest([this.getPending$('resume'), this.selectId$(), this.store.select('resumeMap')]).pipe(
      filter(([pending]) => !pending),
      map(([, id, resumeMap]) => resumeMap[id]),
      filter((resume): resume is ResumeInfo => resume !== undefined)
    )
  }

  public selectNotesList$(resumeId: number): Observable<CvNote[]> {
    return this.store.select('notesMap').pipe(map(notesMap => notesMap[resumeId] || []))
  }

  public selectFacet$(key: keyof CvdbFacets): Observable<FilterItem[] | null> {
    return this.store.select('searchFacets').pipe(map(searchFacets => (isValidKey<CvDbSearchFacetsResponse>(key, searchFacets) ? searchFacets[key] ?? null : null)))
  }

  public selectDistribution$(key: keyof CvdbDistributionFacets): Observable<DistributionBin[] | null> {
    return this.store.select('searchFacets').pipe(map(searchFacets => (isValidKey<CvDbSearchFacetsResponse>(key, searchFacets) ? searchFacets[key] ?? null : null)))
  }

  public selectNewCvTotal$(): Observable<number> {
    return combineLatest([this.getPending$('searchFacets'), this.store.select('searchNewCv').pipe(filter((newTotal): newTotal is number => newTotal !== null))]).pipe(
      filter(([pending]) => !pending),
      map(([, newTotal]) => newTotal)
    )
  }

  public selectCvsPerPage$(): Observable<number> {
    return this.store.select('cvsPerPage').pipe(
      filter((perPage): perPage is number => perPage !== null),
      distinctUntilChanged()
    )
  }

  public getCvDislikedState$(): Observable<boolean> {
    return this.selectResume$().pipe(map(resume => (typeof resume?.hasDislike === 'boolean' ? resume.hasDislike : false)))
  }
}
