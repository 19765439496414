/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http'
import { Observable } from 'rxjs'
import { filter, map } from 'rxjs/operators'
import { StrictHttpResponse } from '../../strict-http-response'
import { RequestBuilder } from '../../request-builder'

import { ConversationsFetchType } from '../../models/conversations-fetch-type'
import { ConversationViewModelPagedResponse } from '../../models/conversation-view-model-paged-response'

export interface ConversationGetConversations$Plain$Params {
  fetchType?: ConversationsFetchType
  pageSize?: number
  pageNumber?: number
  session?: string
}

export function conversationGetConversations$Plain(
  http: HttpClient,
  rootUrl: string,
  params?: ConversationGetConversations$Plain$Params,
  context?: HttpContext
): Observable<StrictHttpResponse<ConversationViewModelPagedResponse>> {
  const rb = new RequestBuilder(rootUrl, conversationGetConversations$Plain.PATH, 'get')
  if (params) {
    rb.query('fetchType', params.fetchType, {})
    rb.query('pageSize', params.pageSize, {})
    rb.query('pageNumber', params.pageNumber, {})
    rb.query('session', params.session, {})
  }

  return http.request(rb.build({ responseType: 'text', accept: 'text/plain', context })).pipe(
    filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
    map((r: HttpResponse<any>) => {
      return r as StrictHttpResponse<ConversationViewModelPagedResponse>
    })
  )
}

conversationGetConversations$Plain.PATH = '/v2/conversations/all'
