export const freemiumQueryValues = {
  queryValueFromBanner: 'freemium-banner',
  queryValueFromLanding: 'freemium-landing'
}

export const freemiumQueryKeys = {
  fromSource: 'from_source'
}

export const localStorageFlags = {
  isWasClosed: {
    publicationGranted: 'Is_Notification_Closed_Publication_Granted_Freemium',
    grantUsDocumentsForCheck: 'Is_Notification_Closed_Grant_Us_Documents_For_Check',
    freemiumWelcomeBanner: 'Is_Freemium_Welcome_Banner_Was_Closed'
  }
}
