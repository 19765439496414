export const phoneFormat = (phone: string, addPlus = true): string => {
  if (!phone) {
    return ''
  }
  let cleaned = phone.replace(/\D/g, '')
  if (cleaned.startsWith('3') && cleaned.length === 12) {
    cleaned = cleaned.slice(1)
  }
  if (cleaned.startsWith('8') && cleaned.length === 11) {
    cleaned = cleaned.slice(1)
  }
  return `${addPlus ? '+' : ''}38${cleaned}`
}
