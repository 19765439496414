import { Injectable } from '@angular/core'
import { RefetchQueryDescription } from '@apollo/client/core/watchQueryOptions'
import { getOperationName } from '@apollo/client/utilities'
import { Observable, of } from 'rxjs'
import { catchError, filter, map, switchMap, take } from 'rxjs/operators'
import { QueryRef } from 'apollo-angular'

import { AuthService } from '@alliance/shared/auth/api'
import { ServiceActivationOutput } from '@alliance/shared/domain-gql'
import { RxStateService } from '@alliance/shared/models'
import { uniqueItemsByKey } from '@alliance/shared/utils'
import { SignalrMessageService, StreamTypeMap } from '@alliance/socket/api'

import {
  ActivatePublicationPackageGQL,
  ActivatePublicationPackageItemFragment,
  ActiveVacancyPublicationItemFragment,
  EmployerHotServiceItemFragment,
  GetAllPublicationServicesGQL,
  GetEmployerHotServicesGQL,
  OrderedPublicationPackageItemFragment,
  GetAllPublicationServicesQuery,
  GetAllPublicationServicesQueryVariables
} from './employer-vacancy-services.generated'
import { PublicationServicesAggregatedByType, SuitablePublicationService } from './models/publication-services-aggregated-by-type'
import { getAvailableVacancyCount } from './utils/get-available-vacancy-count'
import { isOnlyFreeBusiness } from './utils/is-only-free-business'
import { sortPublicationServices } from './utils/sort-publication-services'
import { getSpecialServiceType } from './utils/special-service-type'
import { DEFAULT_PUBLICATION_SERVICES_FILTER } from './consts/default-publication-services-filter'

@Injectable({
  providedIn: 'root'
})
export class EmployerVacancyServicesService extends RxStateService<{
  hotService: EmployerHotServiceItemFragment | null
  publicationServices: SuitablePublicationService[]
  publicationServicesAggregatedByType: PublicationServicesAggregatedByType[]
}> {
  private allPublicationServicesQuery: QueryRef<GetAllPublicationServicesQuery, GetAllPublicationServicesQueryVariables> | undefined = undefined

  public constructor(
    private authService: AuthService,
    private getEmployerHotServicesGQL: GetEmployerHotServicesGQL,
    private getAllPublicationServicesGQL: GetAllPublicationServicesGQL,
    private activatePublicationPackageGQL: ActivatePublicationPackageGQL,
    private signalrMessageService: SignalrMessageService
  ) {
    super()

    this.initState({
      hotService: this.getHotServices$().pipe(
        map(hotServices => {
          const hotService = hotServices?.length
            ? hotServices.reduce<EmployerHotServiceItemFragment>(
                (acc, current, index) =>
                  index
                    ? {
                        ...acc,
                        availableCount: (acc?.availableCount || 0) + (current?.availableCount || 0)
                      }
                    : acc,
                hotServices[0]
              )
            : null
          return hotService?.availableCount ? hotService : null
        })
      ),
      publicationServices: this.getPublicationServices$(),
      publicationServicesAggregatedByType: this.getPublicationServicesAggregated$().pipe(
        map(servicesAggregatedByType =>
          (servicesAggregatedByType || []).map(serviceAggregatedByType => ({
            ...serviceAggregatedByType,
            items: this.normalizePublicationServices(serviceAggregatedByType?.items || [])
          }))
        )
      )
    })

    this.listenToEmployerRegistrationEvent()
  }

  public static getIsOnlyOrderedServicesAvailable(services: SuitablePublicationService[]): boolean {
    return !(services || []).some(service => service?.__typename === 'ActivatedVacancyPublicationService' || service?.__typename === 'ActivatedVacancyPackageService')
  }

  public getPublicationServicesToActivate$(): Observable<OrderedPublicationPackageItemFragment[]> {
    return this.select('publicationServices').pipe(
      map(services =>
        (services || []).filter<OrderedPublicationPackageItemFragment>((service): service is OrderedPublicationPackageItemFragment => service?.__typename === 'OrderedVacancyPackageService')
      )
    )
  }

  public getOrderedPublicationServices$(): Observable<OrderedPublicationPackageItemFragment[]> {
    return this.select('publicationServices').pipe(
      map(services =>
        (services || []).filter<OrderedPublicationPackageItemFragment>((service): service is OrderedPublicationPackageItemFragment => service?.__typename === 'OrderedVacancyPackageService')
      )
    )
  }

  public activatePublicationPackage(id: string, queriesToRefetch: RefetchQueryDescription = []): Observable<ServiceActivationOutput | null> {
    return this.activatePublicationPackageGQL
      .mutate(
        { id },
        {
          refetchQueries: () => {
            const operationName = getOperationName(this.getAllPublicationServicesGQL.document)
            return operationName ? [operationName, ...queriesToRefetch] : queriesToRefetch
          }
        }
      )
      .pipe(map(({ data }) => data?.serviceActivate ?? null))
  }

  public isOnlyFreeBusiness$(): Observable<boolean> {
    return this.select('publicationServices').pipe(
      map(list => {
        const activeServices = (list || []).filter(service => service?.__typename === 'ActivatedVacancyPackageService' || service?.__typename === 'ActivatedVacancyPublicationService') ?? []
        return isOnlyFreeBusiness(activeServices)
      })
    )
  }

  public refetchAllPublicationServices(): void {
    if (this.allPublicationServicesQuery) {
      this.allPublicationServicesQuery.refetch(this.getAllPublicationServicesQueryVariables())
    }
  }

  private getHotServices$(): Observable<EmployerHotServiceItemFragment[]> {
    return this.authService.isLoggedIn$.pipe(
      filter<boolean>(isLoggedIn => isLoggedIn && this.authService.isEmployer),
      take(1),
      switchMap(() =>
        this.getEmployerHotServicesGQL.watch({ id: this.authService.user?.NotebookId?.toString() || '' }, { notifyOnNetworkStatusChange: true, useInitialLoading: true }).valueChanges.pipe(
          filter(({ loading }) => !loading),
          map(({ data }) =>
            (data?.company?.myServices?.items || []).reduce<EmployerHotServiceItemFragment[]>((acc, item) => {
              if (item?.__typename === 'ActivatedHotService') {
                acc = [...acc, item]
              }
              if (item?.__typename === 'ActivatedVacancyPackageService') {
                acc = [...acc, ...item.additionalServices.reduce<EmployerHotServiceItemFragment[]>((_acc, _item) => (_item?.__typename === 'ActivatedHotService' ? [..._acc, _item] : _acc), [])]
              }

              return acc
            }, [])
          ),
          catchError(() => of([]))
        )
      )
    )
  }

  private getAllPublicationServicesQueryRef(): QueryRef<GetAllPublicationServicesQuery, GetAllPublicationServicesQueryVariables> {
    if (!this.allPublicationServicesQuery) {
      this.allPublicationServicesQuery = this.getAllPublicationServicesGQL.watch(this.getAllPublicationServicesQueryVariables(), { notifyOnNetworkStatusChange: true, useInitialLoading: true })
    }

    return this.allPublicationServicesQuery
  }

  private getAllPublicationServicesQueryVariables(): GetAllPublicationServicesQueryVariables {
    return {
      id: this.authService.user?.NotebookId?.toString() || '',
      filter: DEFAULT_PUBLICATION_SERVICES_FILTER
    }
  }

  private getPublicationServices$(): Observable<SuitablePublicationService[]> {
    return this.authService.isLoggedIn$.pipe(
      filter<boolean>(isLoggedIn => isLoggedIn && this.authService.isEmployer),
      take(1),
      switchMap(() =>
        this.getAllPublicationServicesQueryRef().valueChanges.pipe(
          filter(({ loading }) => !loading),
          map(({ data }) => {
            const _hasAvailableAdditionalServices = (item: SuitablePublicationService): boolean =>
              item?.__typename === 'ActivatedVacancyPackageService' && !!item.additionalServices.filter(_item => _item?.__typename === 'ActivatedVacancyPublicationService' && !!_item.availableCount)

            const services = data?.company?.myServices?.items || []
            return services.reduce<SuitablePublicationService[]>((acc, item) => {
              if (item && (item?.__typename === 'OrderedVacancyPackageService' || ('availableCount' in item && (!!item?.availableCount || _hasAvailableAdditionalServices(item))))) {
                return item?.__typename === 'ActivatedVacancyPackageService'
                  ? [
                      ...acc,
                      item,
                      ...item.additionalServices.reduce<SuitablePublicationService[]>((_acc, _item) => (_item?.__typename === 'ActivatedVacancyPublicationService' ? [..._acc, _item] : _acc), [])
                    ]
                  : [...acc, item]
              }
              return acc
            }, [])
          }),
          catchError(() => of([]))
        )
      )
    )
  }

  private listenToEmployerRegistrationEvent(): void {
    this.hold(
      this.authService.token$.pipe(
        switchMap(token => (!!token && this.authService.isEmployer ? this.signalrMessageService.getStreamByType(StreamTypeMap.employerRegistrationEvent).pipe(map(() => true)) : of(null))),
        filter(Boolean)
      ),
      () => this.refetchAllPublicationServices()
    )
  }

  private getPublicationServicesAggregated$(): Observable<PublicationServicesAggregatedByType[]> {
    return this.select('publicationServices').pipe(
      map(services =>
        (services || []).reduce<PublicationServicesAggregatedByType[]>((acc, service) => {
          const availableCount = getAvailableVacancyCount(service)
          const { publicationType, vacancyMailingCount, vacancyRisingCount, supportedRegions } = service

          if (!publicationType || !availableCount) {
            return acc
          }

          const specialServiceType = getSpecialServiceType(service)

          const aggregatedService: PublicationServicesAggregatedByType = {
            publicationType,
            specialServiceType,
            vacancyMailingCount,
            vacancyRisingCount,
            supportedRegions,
            count: availableCount,
            items: [service]
          }

          const foundedIndex = acc.findIndex(accItem => accItem.publicationType === publicationType && accItem.specialServiceType === specialServiceType)

          if (acc[foundedIndex]) {
            return acc.map((accItem, accIndex) =>
              accIndex === foundedIndex
                ? {
                    ...accItem,
                    count: accItem.count + aggregatedService.count,
                    items: [...accItem.items, ...aggregatedService.items],
                    supportedRegions: uniqueItemsByKey([...accItem.supportedRegions, ...aggregatedService.supportedRegions], 'id')
                  }
                : accItem
            )
          }

          return [...acc, aggregatedService]
        }, [])
      ),
      map(aggregatedServices => sortPublicationServices(aggregatedServices))
    )
  }

  private normalizePublicationServices(services: SuitablePublicationService[]): SuitablePublicationService[] {
    const activePublications = (services || []).filter<ActiveVacancyPublicationItemFragment>(
      (item): item is ActiveVacancyPublicationItemFragment => item?.__typename === 'ActivatedVacancyPublicationService'
    )
    // merge publications with different endedAt date into one publication item
    const publications =
      activePublications?.length > 1
        ? [
            activePublications.reduce<ActiveVacancyPublicationItemFragment>(
              (acc, current, index) =>
                index
                  ? {
                      ...acc,
                      availableCount: (acc?.availableCount || 0) + (current?.availableCount || 0)
                    }
                  : acc,
              activePublications[0]
            )
          ]
        : activePublications
    const activePackages = (services || []).filter<ActivatePublicationPackageItemFragment>(
      (item): item is ActivatePublicationPackageItemFragment => item?.__typename === 'ActivatedVacancyPackageService'
    )
    const orderedPackages = (services || []).filter<OrderedPublicationPackageItemFragment>((item): item is OrderedPublicationPackageItemFragment => item?.__typename === 'OrderedVacancyPackageService')

    return [...publications, ...activePackages, ...orderedPackages]
  }
}
