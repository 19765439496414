import { Injectable } from '@angular/core'
import { ActivatedRouteSnapshot, CanActivateChild } from '@angular/router'
import { SeoDictionary, SeoPageKeysEnum, SeoService } from '@alliance/core/seo'
import { Observable } from 'rxjs'
import { map } from 'rxjs/operators'
import { DetectPlatformService } from '@alliance/shared/utils'

@Injectable({ providedIn: 'root' })
export class CommonSeoGuard implements CanActivateChild {
  public constructor(private seoService: SeoService, private platform: DetectPlatformService) {}

  public canActivateChild(next: ActivatedRouteSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    if (!next.component) {
      return true
    }
    if (this.platform.isServer) {
      // clear seo params (set to default) from prev route
      this.seoService.setDefaultSeo()

      switch (next.data?.['seoKey']) {
        case SeoPageKeysEnum.home:
          return this.setSeoParams({ key: SeoPageKeysEnum.home })
        case SeoPageKeysEnum.employer:
          return this.setSeoParams({ key: SeoPageKeysEnum.employer })
        case SeoPageKeysEnum.cvwelcome:
          return this.setSeoParams({ key: SeoPageKeysEnum.cvwelcome })
        case SeoPageKeysEnum.searchByCitiesRegions:
          return this.setSeoParams({ key: SeoPageKeysEnum.searchByCitiesRegions })
        case SeoPageKeysEnum.searchByRubrics:
          return this.setSeoParams({ key: SeoPageKeysEnum.searchByRubrics })
        case SeoPageKeysEnum.searchByProfessionsAlphabet:
          return this.setSeoParams({ key: SeoPageKeysEnum.searchByProfessionsAlphabet })
        case SeoPageKeysEnum.searchByProfessionsCities:
          return this.setSeoParams({ key: SeoPageKeysEnum.searchByProfessionsCities })
        case SeoPageKeysEnum.searchByCities:
          return this.setSeoParams({ key: SeoPageKeysEnum.searchByCities })
        case SeoPageKeysEnum.searchByCompanyBranch:
          return this.setSeoParams({ key: SeoPageKeysEnum.searchByCompanyBranch })
        default:
          return true
      }
    } else {
      return true
    }
  }

  private setSeoParams(seoParams: SeoDictionary): Observable<boolean> {
    return this.seoService.getSeoParams(seoParams).pipe(
      map(params => {
        this.seoService.setSeoParamsInDOM(params)
        return true
      })
    )
  }
}
