import { VacancyPublicationType } from '@alliance/shared/domain-gql'
import { SpecialServiceType } from '../models/publication-services-aggregated-by-type'

export const PUBLICATION_SERVICES_ORDER: VacancyPublicationType[] = [
  VacancyPublicationType.Professional,
  VacancyPublicationType.Optimum,
  VacancyPublicationType.Business,
  VacancyPublicationType.Anonymous,
  VacancyPublicationType.Test
]

export const PUBLICATION_SPECIAL_SERVICES_ORDER: Array<SpecialServiceType | null> = [SpecialServiceType.WithRegions, SpecialServiceType.BusinessPlus]

export const sortPublicationServices = <T extends { publicationType: VacancyPublicationType; specialServiceType: SpecialServiceType | null }>(services: T[]): T[] =>
  [...services].sort((a, b) =>
    a.publicationType === b.publicationType
      ? PUBLICATION_SPECIAL_SERVICES_ORDER.indexOf(a.specialServiceType) - PUBLICATION_SPECIAL_SERVICES_ORDER.indexOf(b.specialServiceType)
      : PUBLICATION_SERVICES_ORDER.indexOf(a.publicationType) - PUBLICATION_SERVICES_ORDER.indexOf(b.publicationType)
  )
