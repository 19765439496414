import { Inject, Injectable } from '@angular/core'
import { NAVIGATOR } from '@ng-web-apis/common'
import { RxStateService } from '@alliance/shared/models'
import { Observable } from 'rxjs'
import { map } from 'rxjs/operators'
import { HttpClient, HttpParams } from '@angular/common/http'
import { TranslationService } from '@alliance/shared/translation'
import { Address, GeoMapLatLng, LocationByGeoMapLatLng, NOMINATIM_GET_LOCATION_BY_GEO } from './constants'
import { DictionaryService } from '../dictionary/dictionary.service'
import { LoggingToBigQueryService } from '@alliance/shared/utils'

@Injectable({
  providedIn: 'root'
})
export class GeolocationService extends RxStateService<{
  longitude: number | null
  latitude: number | null
  geolocationAddress: Address | null
  geoLocationCityId: number | null
}> {
  public constructor(
    @Inject(NAVIGATOR) public readonly navigator: Navigator,
    private http: HttpClient,
    private translationService: TranslationService,
    private dictionaryService: DictionaryService,
    private bqService: LoggingToBigQueryService
  ) {
    super()
    this.initState({
      longitude: null,
      latitude: null,
      geolocationAddress: null,
      geoLocationCityId: null
    })
  }

  public getAddressByGeo(coordinates: GeoMapLatLng): Observable<Address> {
    return this.getLocationByGeoMapLatLng(coordinates).pipe(
      map(response => {
        this.set({ geolocationAddress: { ...response.address, city: response.address.city || response.address.town || response.address.village || '' } })
        return response.address
      })
    )
  }

  public getCityIdByName(cityName: string): Observable<number> {
    return this.dictionaryService.getCityList$().pipe(
      map(cityList => {
        const selectedCity = cityList.find(city => city.value === cityName)
        this.set({ geoLocationCityId: selectedCity?.id })
        return selectedCity ? selectedCity.id : 0
      })
    )
  }

  public getCurrentPosition(): Observable<GeolocationPosition> {
    return new Observable(observer => {
      this.navigator.geolocation.getCurrentPosition(
        position => {
          observer.next(position)
          this.geoPermissionAnalytics('allow')
          observer.complete()
        },
        error => {
          this.geoPermissionAnalytics('deny')
          observer.error(error)
        }
      )
    })
  }

  private geoPermissionAnalytics(status: 'deny' | 'allow'): void {
    this.bqService.pushToDataLayer({
      event: 'autoEventGBQ',
      eventCategory: 'geo_location_access',
      eventAction: status
    })
  }

  private getLocationByGeoMapLatLng(coordinates: GeoMapLatLng): Observable<LocationByGeoMapLatLng> {
    const params = new HttpParams().set('format', 'json').set('lon', coordinates.longitude).set('lat', coordinates.latitude).set('accept-language', this.translationService.getCurrentLang())
    this.set({ longitude: coordinates.longitude, latitude: coordinates.latitude })
    return this.http.get<LocationByGeoMapLatLng>(`${NOMINATIM_GET_LOCATION_BY_GEO}`, { params })
  }
}
