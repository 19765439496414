import { Injectable } from '@angular/core'
import { Observable, catchError, map, of } from 'rxjs'

import { getNonNullableItems } from '@alliance/shared/utils'

import { VacancyDeleteGQL } from './remove-vacancy.generated'
import { RemoveVacancyParams } from '../../../models/vacancy-actions/remove-vacancy/remove-vacancy-params.interface'
import { RemoveVacancyResponse } from '../../../models/vacancy-actions/remove-vacancy/remove-vacancy-response.interface'

@Injectable()
export class RemoveVacancyService {
  public constructor(private readonly vacancyDeleteGQL: VacancyDeleteGQL) {}

  public removeVacancy$(params: RemoveVacancyParams): Observable<RemoveVacancyResponse | null> {
    if (!params.vacanciesIds.length) {
      return of(null)
    }

    return this.vacancyDeleteGQL
      .mutate({
        input: {
          vacanciesIds: params.vacanciesIds,
          vacancyId: params.vacanciesIds[0] // is going to be removed after schema change
        }
      })
      .pipe(
        map(({ data }) => {
          const vacanciesIds = getNonNullableItems(data?.vacancyDelete?.vacanciesIds ?? [])
          if (vacanciesIds.length) {
            return { vacanciesIds }
          }
          return null
        }),
        catchError(() => of(null))
      )
  }
}
