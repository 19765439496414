export enum VacancyActionType {
  AutoProlong = 'autoProlong',
  Publish = 'publishVacancy',
  Approve = 'approve',
  PublishDraft = 'publishDraft'
}

export interface VacancyActionQueryParams {
  vacancyId: string
  actionType: VacancyActionType
}
