import { ActivatePublicationPackageItemFragment, OrderedPublicationPackageItemFragment } from '../employer-vacancy-services.generated'
import { PublicationServicesAggregatedByType } from '../models/publication-services-aggregated-by-type'

export const getPublicationServiceId = (service: PublicationServicesAggregatedByType, cityId: string | null = null): string | null => {
  const { specialServiceType, items } = service

  if (!specialServiceType || !items.length) {
    return null
  }

  const filteredServices = items.filter(item => (cityId && item.supportedRegions?.length ? item.supportedRegions.some(region => region.id === cityId) : true))
  const activatedServices = filteredServices
    .filter((item): item is ActivatePublicationPackageItemFragment => item.__typename === 'ActivatedVacancyPackageService')
    .sort((prev, current) => {
      const prevDate = prev.endedAt ? new Date(prev.endedAt).getTime() : 0
      const currentDate = current.endedAt ? new Date(current.endedAt).getTime() : 0
      return prevDate === currentDate ? (prev.availableCount || 0) - (current.availableCount || 0) : prevDate - currentDate
    })
  const orderedServices = filteredServices.filter((item): item is OrderedPublicationPackageItemFragment => item.__typename === 'OrderedVacancyPackageService')

  return [...activatedServices, ...orderedServices][0]?.serviceId || null
}
