import { log } from '@alliance/shared/logger'
import { RxStateService } from '@alliance/shared/models'
import { Inject, Injectable } from '@angular/core'
import { DOCUMENT } from '@angular/common'
import { Title } from '@angular/platform-browser'
import { filter } from 'rxjs/operators'
import { LoggingToBigQueryService } from './logging-to-big-query.service'

@Injectable({ providedIn: 'root' })
export class TrackPageService extends RxStateService<{
  prevUrl: string
  currentUrl: string
}> {
  public constructor(private titleService: Title, private bqService: LoggingToBigQueryService, @Inject(DOCUMENT) private document: Document) {
    super()

    this.initState({
      prevUrl: '',
      currentUrl: ''
    })

    this.hold(this.select('currentUrl').pipe(filter(currentUrl => !!currentUrl)), () => {
      this.trackPageViews()
    })
  }

  private trackPageViews(): void {
    const { currentUrl, prevUrl } = this.get()

    let routechange: string
    let routerefferer: string

    try {
      routechange = decodeURI(currentUrl)
      routerefferer = prevUrl ? decodeURI(prevUrl) : decodeURI(this.document.defaultView?.document?.referrer ?? '')
    } catch (e) {
      // If decodeURI failed, take url string without decodeURI
      routechange = currentUrl
      routerefferer = prevUrl ? prevUrl : this.document.defaultView?.document?.referrer ?? ''
      log.warn({ where: 'shared-utils: TrackPageService', category: 'try_catch', message: 'trackPageViews - Error in decodeURI', error: e })
    }

    this.bqService.pushToDataLayer({
      event: 'pageviewAngular',
      routechange,
      routerefferer,
      routehost: this.document.defaultView?.location?.hostname,
      title: this.titleService.getTitle()
    })
  }
}
