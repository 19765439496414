import { Observable } from 'rxjs'
import { distinctUntilChanged, pluck } from 'rxjs/operators'
import { Store } from './store'

export abstract class Query<Model> {
  public store$: Observable<Model>

  protected constructor(protected store: Store<Model>) {
    this.store$ = store.store$
  }

  public select$<Key extends keyof Model>(stateKeys: Key): Observable<Model[Key]> {
    return this.store$.pipe(pluck(stateKeys), distinctUntilChanged())
  }
}
