import { Injectable } from '@angular/core'
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http'
import { BaseService as __BaseService } from '../base-service'
import { JobseekerDataAccessConfiguration as __Configuration } from '../jobseeker-data-access-configuration'
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response'
import { Observable as __Observable } from 'rxjs'
import { map as __map, filter as __filter } from 'rxjs/operators'

@Injectable({
  providedIn: 'root'
})
class AccountEmployerService extends __BaseService {
  public static readonly employerLogUserPath = '/account/employer/loguser/{page}'
  public static readonly employerLogUser_1Path = '/account/employer/loguser/{page}'

  public constructor(config: __Configuration, http: HttpClient) {
    super(config, http)
  }

  /**
   * @param page undefined
   * @return OK
   */
  employerLogUserResponse(page: number): __Observable<__StrictHttpResponse<{}>> {
    let __params = this.newParams()
    let __headers = new HttpHeaders()
    let __body: any = null

    let req = new HttpRequest<any>('GET', this.rootUrl + `/account/employer/loguser/${page}`, __body, {
      headers: __headers,
      params: __params,
      responseType: 'json'
    })

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map(_r => {
        return _r as __StrictHttpResponse<{}>
      })
    )
  }

  /**
   * @param page undefined
   * @return OK
   */
  employerLogUser(page: number): __Observable<{}> {
    return this.employerLogUserResponse(page).pipe(__map(_r => _r.body as {}))
  }

  /**
   * @param page undefined
   * @return OK
   */
  employerLogUser_1Response(page: number): __Observable<__StrictHttpResponse<{}>> {
    let __params = this.newParams()
    let __headers = new HttpHeaders()
    let __body: any = null

    let req = new HttpRequest<any>('POST', this.rootUrl + `/account/employer/loguser/${page}`, __body, {
      headers: __headers,
      params: __params,
      responseType: 'json'
    })

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map(_r => {
        return _r as __StrictHttpResponse<{}>
      })
    )
  }

  /**
   * @param page undefined
   * @return OK
   */
  employerLogUser_1(page: number): __Observable<{}> {
    return this.employerLogUser_1Response(page).pipe(__map(_r => _r.body as {}))
  }
}

module AccountEmployerService {}

export { AccountEmployerService }
