import { ChangeDetectorRef, ComponentRef, Directive, Injector, Input, OnChanges, SimpleChanges, TemplateRef, ViewContainerRef } from '@angular/core'
import { PolymorpheusComponent, PolymorpheusContext } from '../classes'
import { PolymorpheusContent, PolymorpheusPrimitive } from '../types'

const isComponent = <C>(content: PolymorpheusContent<C>): content is PolymorpheusComponent<unknown, C> => content instanceof PolymorpheusComponent

const isTemplate = <C>(content: PolymorpheusContent<C>): content is TemplateRef<C> => content instanceof TemplateRef

@Directive({
  selector: '[polymorpheusOutlet]'
})
export class PolymorpheusOutletDirective<C> implements OnChanges {
  @Input('polymorpheusOutlet') public content: PolymorpheusContent<C>

  @Input('polymorpheusOutletContext') public context: C | undefined

  private componentRef: ComponentRef<unknown> | undefined

  public static ngTemplateContextGuard<T>(_dir: PolymorpheusOutletDirective<T>, _ctx: unknown): _ctx is PolymorpheusContext<T extends PolymorpheusPrimitive ? T : never> {
    return true
  }

  public constructor(private readonly injector: Injector, private readonly templateRef: TemplateRef<PolymorpheusContext<PolymorpheusPrimitive>>, private readonly viewContainerRef: ViewContainerRef) {}

  private get template(): TemplateRef<unknown> {
    return isTemplate(this.content) ? this.content : this.templateRef
  }

  public ngOnChanges({ content }: SimpleChanges): void {
    const context = this.getContext()

    this.componentRef?.injector.get(ChangeDetectorRef).markForCheck()

    if (!content) {
      return
    }

    this.viewContainerRef.clear()

    const proxy =
      context &&
      (new Proxy(context as object, {
        get: (_, key) => this.getContext()?.[key as keyof (C | PolymorpheusContext<unknown>)]
      }) as unknown as C)

    if (isComponent(this.content)) {
      this.process(this.content, proxy)
    } else if ((context instanceof PolymorpheusContext && context.$implicit) != null) {
      this.viewContainerRef.createEmbeddedView(this.template, proxy)
    }
  }

  private getContext(): C | undefined | PolymorpheusContext<unknown> {
    if (isTemplate(this.content) || isComponent(this.content)) {
      return this.context
    }

    return new PolymorpheusContext(typeof this.content === 'function' && typeof this.context !== 'undefined' ? this.content(this.context) : this.content)
  }

  private process(content: PolymorpheusComponent<unknown>, proxy?: C): void {
    const injector = content.createInjector(this.injector, proxy)

    this.componentRef = this.viewContainerRef.createComponent(content.component, { injector })
  }
}
