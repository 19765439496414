import { Injectable } from '@angular/core'
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http'
import { BaseService as __BaseService } from '../base-service'
import { EmployerDataAccessConfiguration as __Configuration } from '../employer-data-access-configuration'
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response'
import { Observable as __Observable } from 'rxjs'
import { map as __map, filter as __filter } from 'rxjs/operators'

import { VacancyInfo } from '../models/vacancy-info'
import { ResumeRecommended } from '../models/resume-recommended'
import { CvRecomRequest } from '../models/cv-recom-request'
import { VacancyListResponse } from '../models/vacancy-list-response'
import { CommonResponse } from '../models/common-response'
import { MultiUser } from '../models/multi-user'
import { VacancyAddResponse } from '../models/vacancy-add-response'
import { VacancyAddRequest } from '../models/vacancy-add-request'
import { VacancyDesign } from '../models/vacancy-design'
import { NotebookCompanyInfo } from '../models/notebook-company-info'
import { ActivationServiceResponse } from '../models/activation-service-response'
import { EvaApproveResponse } from '../models/eva-approve-response'
import { MultiUserResponse } from '../models/multi-user-response'
@Injectable({
  providedIn: 'root'
})
class BotService extends __BaseService {
  public static readonly getVacancyPath = '/bot/vacancy/{id}'
  public static readonly autoscreeningCvPath = '/bot/autoscreeningcv'
  public static readonly getVacanciesPath = '/bot/vacancylist/{notebookId}'
  public static readonly getVacancy_1Path = '/bot/vacancy'
  public static readonly setStatePath = '/bot/Vacancystate/{id}'
  public static readonly multiUserListPath = '/bot/Multiuserlist/{notebookId}'
  public static readonly addVacancyPath = '/bot/AddVacancy'
  public static readonly getVacancyDesignPath = '/bot/vacancydesignlist/{notebookId}'
  public static readonly editVacancyPath = '/bot/UpdateVacancy'
  public static readonly getCompanyPath = '/bot/GetCompany/{notebookId}'
  public static readonly markSurveyCvPath = '/bot/MarkSurveyCv'
  public static readonly surveyTicketsPath = '/bot/SurveyTickets'
  public static readonly activateSurveyPath = '/bot/ActivateSurvey'
  public static readonly appoveByEvaPath = '/bot/eva/{vacancyId}'
  public static readonly getMultiUserEmailPath = '/bot/multiuseremail/{userId}'
  public static readonly getMultiUserEmail_1Path = '/bot/multiuserId'

  public constructor(config: __Configuration, http: HttpClient) {
    super(config, http)
  }

  /**
   * @param id undefined
   * @return Success
   */
  getVacancyResponse(id: number): __Observable<__StrictHttpResponse<VacancyInfo>> {
    let __params = this.newParams()
    let __headers = new HttpHeaders()
    let __body: any = null

    let req = new HttpRequest<any>('GET', this.rootUrl + `/bot/vacancy/${id}`, __body, {
      headers: __headers,
      params: __params,
      responseType: 'json'
    })

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map(_r => {
        return _r as __StrictHttpResponse<VacancyInfo>
      })
    )
  }

  /**
   * @param id undefined
   * @return Success
   */
  getVacancy(id: number): __Observable<VacancyInfo> {
    return this.getVacancyResponse(id).pipe(__map(_r => _r.body as VacancyInfo))
  }

  /**
   * @param params The `BotService.AutoscreeningCvParams` containing the following parameters:
   *
   * - `request`:
   *
   * - `notebookId`:
   *
   * @return Success
   */
  autoscreeningCvResponse(params: BotService.AutoscreeningCvParams): __Observable<__StrictHttpResponse<ResumeRecommended>> {
    let __params = this.newParams()
    let __headers = new HttpHeaders()
    let __body: any = null
    __body = params.request
    if (params.notebookId != null) __params = __params.set('notebookId', params.notebookId.toString())
    let req = new HttpRequest<any>('GET', this.rootUrl + `/bot/autoscreeningcv`, __body, {
      headers: __headers,
      params: __params,
      responseType: 'json'
    })

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map(_r => {
        return _r as __StrictHttpResponse<ResumeRecommended>
      })
    )
  }

  /**
   * @param params The `BotService.AutoscreeningCvParams` containing the following parameters:
   *
   * - `request`:
   *
   * - `notebookId`:
   *
   * @return Success
   */
  autoscreeningCv(params: BotService.AutoscreeningCvParams): __Observable<ResumeRecommended> {
    return this.autoscreeningCvResponse(params).pipe(__map(_r => _r.body as ResumeRecommended))
  }

  /**
   * @param params The `BotService.GetVacanciesParams` containing the following parameters:
   *
   * - `notebookId`:
   *
   * - `VacancyTypeId`:
   *
   * - `VacancyStateId`:
   *
   * - `VacancyName`:
   *
   * - `SortField`:
   *
   * - `SortDirection`:
   *
   * - `Page`:
   *
   * - `MultiUserId`:
   *
   * - `Code`:
   *
   * - `CityId`:
   *
   * @return Success
   */
  getVacanciesResponse(params: BotService.GetVacanciesParams): __Observable<__StrictHttpResponse<VacancyListResponse>> {
    let __params = this.newParams()
    let __headers = new HttpHeaders()
    let __body: any = null

    if (params.VacancyTypeId != null) __params = __params.set('VacancyTypeId', params.VacancyTypeId.toString())
    if (params.VacancyStateId != null) __params = __params.set('VacancyStateId', params.VacancyStateId.toString())
    if (params.VacancyName != null) __params = __params.set('VacancyName', params.VacancyName.toString())
    if (params.SortField != null) __params = __params.set('SortField', params.SortField.toString())
    if (params.SortDirection != null) __params = __params.set('SortDirection', params.SortDirection.toString())
    if (params.Page != null) __params = __params.set('Page', params.Page.toString())
    if (params.MultiUserId != null) __params = __params.set('MultiUserId', params.MultiUserId.toString())
    if (params.Code != null) __params = __params.set('Code', params.Code.toString())
    if (params.CityId != null) __params = __params.set('CityId', params.CityId.toString())
    let req = new HttpRequest<any>('GET', this.rootUrl + `/bot/vacancylist/${params.notebookId}`, __body, {
      headers: __headers,
      params: __params,
      responseType: 'json'
    })

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map(_r => {
        return _r as __StrictHttpResponse<VacancyListResponse>
      })
    )
  }

  /**
   * @param params The `BotService.GetVacanciesParams` containing the following parameters:
   *
   * - `notebookId`:
   *
   * - `VacancyTypeId`:
   *
   * - `VacancyStateId`:
   *
   * - `VacancyName`:
   *
   * - `SortField`:
   *
   * - `SortDirection`:
   *
   * - `Page`:
   *
   * - `MultiUserId`:
   *
   * - `Code`:
   *
   * - `CityId`:
   *
   * @return Success
   */
  getVacancies(params: BotService.GetVacanciesParams): __Observable<VacancyListResponse> {
    return this.getVacanciesResponse(params).pipe(__map(_r => _r.body as VacancyListResponse))
  }

  /**
   * @param params The `BotService.GetVacancy_1Params` containing the following parameters:
   *
   * - `vacancyId`:
   *
   * - `notebookId`:
   *
   * @return Success
   */
  getVacancy_1Response(params: BotService.GetVacancy_1Params): __Observable<__StrictHttpResponse<VacancyInfo>> {
    let __params = this.newParams()
    let __headers = new HttpHeaders()
    let __body: any = null
    if (params.vacancyId != null) __params = __params.set('vacancyId', params.vacancyId.toString())
    if (params.notebookId != null) __params = __params.set('notebookId', params.notebookId.toString())
    let req = new HttpRequest<any>('GET', this.rootUrl + `/bot/vacancy`, __body, {
      headers: __headers,
      params: __params,
      responseType: 'json'
    })

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map(_r => {
        return _r as __StrictHttpResponse<VacancyInfo>
      })
    )
  }

  /**
   * @param params The `BotService.GetVacancy_1Params` containing the following parameters:
   *
   * - `vacancyId`:
   *
   * - `notebookId`:
   *
   * @return Success
   */
  getVacancy_1(params: BotService.GetVacancy_1Params): __Observable<VacancyInfo> {
    return this.getVacancy_1Response(params).pipe(__map(_r => _r.body as VacancyInfo))
  }

  /**
   * @param params The `BotService.SetStateParams` containing the following parameters:
   *
   * - `id`:
   *
   * - `state`:
   *
   * @return Success
   */
  setStateResponse(params: BotService.SetStateParams): __Observable<__StrictHttpResponse<CommonResponse>> {
    let __params = this.newParams()
    let __headers = new HttpHeaders()
    let __body: any = null

    if (params.state != null) __params = __params.set('state', params.state.toString())
    let req = new HttpRequest<any>('PUT', this.rootUrl + `/bot/Vacancystate/${params.id}`, __body, {
      headers: __headers,
      params: __params,
      responseType: 'json'
    })

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map(_r => {
        return _r as __StrictHttpResponse<CommonResponse>
      })
    )
  }

  /**
   * @param params The `BotService.SetStateParams` containing the following parameters:
   *
   * - `id`:
   *
   * - `state`:
   *
   * @return Success
   */
  setState(params: BotService.SetStateParams): __Observable<CommonResponse> {
    return this.setStateResponse(params).pipe(__map(_r => _r.body as CommonResponse))
  }

  /**
   * @param notebookId undefined
   * @return Success
   */
  multiUserListResponse(notebookId: number): __Observable<__StrictHttpResponse<Array<MultiUser>>> {
    let __params = this.newParams()
    let __headers = new HttpHeaders()
    let __body: any = null

    let req = new HttpRequest<any>('GET', this.rootUrl + `/bot/Multiuserlist/${notebookId}`, __body, {
      headers: __headers,
      params: __params,
      responseType: 'json'
    })

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map(_r => {
        return _r as __StrictHttpResponse<Array<MultiUser>>
      })
    )
  }

  /**
   * @param notebookId undefined
   * @return Success
   */
  multiUserList(notebookId: number): __Observable<Array<MultiUser>> {
    return this.multiUserListResponse(notebookId).pipe(__map(_r => _r.body as Array<MultiUser>))
  }

  /**
   * @param params The `BotService.AddVacancyParams` containing the following parameters:
   *
   * - `vacancy`:
   *
   * - `multiUserId`:
   *
   * @return Success
   */
  addVacancyResponse(params: BotService.AddVacancyParams): __Observable<__StrictHttpResponse<VacancyAddResponse>> {
    let __params = this.newParams()
    let __headers = new HttpHeaders()
    let __body: any = null
    __body = params.vacancy
    if (params.multiUserId != null) __params = __params.set('multiUserId', params.multiUserId.toString())
    let req = new HttpRequest<any>('POST', this.rootUrl + `/bot/AddVacancy`, __body, {
      headers: __headers,
      params: __params,
      responseType: 'json'
    })

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map(_r => {
        return _r as __StrictHttpResponse<VacancyAddResponse>
      })
    )
  }

  /**
   * @param params The `BotService.AddVacancyParams` containing the following parameters:
   *
   * - `vacancy`:
   *
   * - `multiUserId`:
   *
   * @return Success
   */
  addVacancy(params: BotService.AddVacancyParams): __Observable<VacancyAddResponse> {
    return this.addVacancyResponse(params).pipe(__map(_r => _r.body as VacancyAddResponse))
  }

  /**
   * @param notebookId undefined
   * @return Success
   */
  getVacancyDesignResponse(notebookId: number): __Observable<__StrictHttpResponse<Array<VacancyDesign>>> {
    let __params = this.newParams()
    let __headers = new HttpHeaders()
    let __body: any = null

    let req = new HttpRequest<any>('GET', this.rootUrl + `/bot/vacancydesignlist/${notebookId}`, __body, {
      headers: __headers,
      params: __params,
      responseType: 'json'
    })

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map(_r => {
        return _r as __StrictHttpResponse<Array<VacancyDesign>>
      })
    )
  }

  /**
   * @param notebookId undefined
   * @return Success
   */
  getVacancyDesign(notebookId: number): __Observable<Array<VacancyDesign>> {
    return this.getVacancyDesignResponse(notebookId).pipe(__map(_r => _r.body as Array<VacancyDesign>))
  }

  /**
   * @param params The `BotService.EditVacancyParams` containing the following parameters:
   *
   * - `vacancy`:
   *
   * - `multiUserId`:
   *
   * @return Success
   */
  editVacancyResponse(params: BotService.EditVacancyParams): __Observable<__StrictHttpResponse<VacancyAddResponse>> {
    let __params = this.newParams()
    let __headers = new HttpHeaders()
    let __body: any = null
    __body = params.vacancy
    if (params.multiUserId != null) __params = __params.set('multiUserId', params.multiUserId.toString())
    let req = new HttpRequest<any>('PUT', this.rootUrl + `/bot/UpdateVacancy`, __body, {
      headers: __headers,
      params: __params,
      responseType: 'json'
    })

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map(_r => {
        return _r as __StrictHttpResponse<VacancyAddResponse>
      })
    )
  }

  /**
   * @param params The `BotService.EditVacancyParams` containing the following parameters:
   *
   * - `vacancy`:
   *
   * - `multiUserId`:
   *
   * @return Success
   */
  editVacancy(params: BotService.EditVacancyParams): __Observable<VacancyAddResponse> {
    return this.editVacancyResponse(params).pipe(__map(_r => _r.body as VacancyAddResponse))
  }

  /**
   * @param params The `BotService.GetCompanyParams` containing the following parameters:
   *
   * - `notebookId`:
   *
   * - `multiUserId`:
   *
   * @return Success
   */
  getCompanyResponse(params: BotService.GetCompanyParams): __Observable<__StrictHttpResponse<NotebookCompanyInfo>> {
    let __params = this.newParams()
    let __headers = new HttpHeaders()
    let __body: any = null

    if (params.multiUserId != null) __params = __params.set('multiUserId', params.multiUserId.toString())
    let req = new HttpRequest<any>('GET', this.rootUrl + `/bot/GetCompany/${params.notebookId}`, __body, {
      headers: __headers,
      params: __params,
      responseType: 'json'
    })

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map(_r => {
        return _r as __StrictHttpResponse<NotebookCompanyInfo>
      })
    )
  }

  /**
   * @param params The `BotService.GetCompanyParams` containing the following parameters:
   *
   * - `notebookId`:
   *
   * - `multiUserId`:
   *
   * @return Success
   */
  getCompany(params: BotService.GetCompanyParams): __Observable<NotebookCompanyInfo> {
    return this.getCompanyResponse(params).pipe(__map(_r => _r.body as NotebookCompanyInfo))
  }

  /**
   * @param params The `BotService.MarkSurveyCvParams` containing the following parameters:
   *
   * - `vacancyId`:
   *
   * - `cvId`:
   */
  markSurveyCvResponse(params: BotService.MarkSurveyCvParams): __Observable<__StrictHttpResponse<any>> {
    let __params = this.newParams()
    let __headers = new HttpHeaders()
    let __body: any = null
    if (params.vacancyId != null) __params = __params.set('vacancyId', params.vacancyId.toString())
    if (params.cvId != null) __params = __params.set('cvId', params.cvId.toString())
    let req = new HttpRequest<any>('PATCH', this.rootUrl + `/bot/MarkSurveyCv`, __body, {
      headers: __headers,
      params: __params,
      responseType: 'json'
    })

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map(_r => {
        return _r as __StrictHttpResponse<any>
      })
    )
  }

  /**
   * @param params The `BotService.MarkSurveyCvParams` containing the following parameters:
   *
   * - `vacancyId`:
   *
   * - `cvId`:
   */
  markSurveyCv(params: BotService.MarkSurveyCvParams): __Observable<any> {
    return this.markSurveyCvResponse(params).pipe(__map(_r => _r.body as any))
  }

  /**
   * @param notebookId undefined
   * @return Success
   */
  surveyTicketsResponse(notebookId?: number): __Observable<__StrictHttpResponse<number>> {
    let __params = this.newParams()
    let __headers = new HttpHeaders()
    let __body: any = null
    if (notebookId != null) __params = __params.set('notebookId', notebookId.toString())
    let req = new HttpRequest<any>('PATCH', this.rootUrl + `/bot/SurveyTickets`, __body, {
      headers: __headers,
      params: __params,
      responseType: 'json'
    })

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map(_r => {
        return (_r as HttpResponse<any>).clone({
          body: parseFloat((_r as HttpResponse<any>).body as string)
        }) as __StrictHttpResponse<number>
      })
    )
  }

  /**
   * @param notebookId undefined
   * @return Success
   */
  surveyTickets(notebookId?: number): __Observable<number> {
    return this.surveyTicketsResponse(notebookId).pipe(__map(_r => _r.body as number))
  }

  /**
   * @param params The `BotService.ActivateSurveyParams` containing the following parameters:
   *
   * - `vacancyId`:
   *
   * - `notebookId`:
   *
   * - `login`:
   *
   * @return Success
   */
  activateSurveyResponse(params: BotService.ActivateSurveyParams): __Observable<__StrictHttpResponse<ActivationServiceResponse>> {
    let __params = this.newParams()
    let __headers = new HttpHeaders()
    let __body: any = null
    if (params.vacancyId != null) __params = __params.set('vacancyId', params.vacancyId.toString())
    if (params.notebookId != null) __params = __params.set('notebookId', params.notebookId.toString())
    if (params.login != null) __params = __params.set('login', params.login.toString())
    let req = new HttpRequest<any>('POST', this.rootUrl + `/bot/ActivateSurvey`, __body, {
      headers: __headers,
      params: __params,
      responseType: 'json'
    })

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map(_r => {
        return _r as __StrictHttpResponse<ActivationServiceResponse>
      })
    )
  }

  /**
   * @param params The `BotService.ActivateSurveyParams` containing the following parameters:
   *
   * - `vacancyId`:
   *
   * - `notebookId`:
   *
   * - `login`:
   *
   * @return Success
   */
  activateSurvey(params: BotService.ActivateSurveyParams): __Observable<ActivationServiceResponse> {
    return this.activateSurveyResponse(params).pipe(__map(_r => _r.body as ActivationServiceResponse))
  }

  /**
   * @param vacancyId undefined
   * @return Success
   */
  appoveByEvaResponse(vacancyId: number): __Observable<__StrictHttpResponse<EvaApproveResponse>> {
    let __params = this.newParams()
    let __headers = new HttpHeaders()
    let __body: any = null

    let req = new HttpRequest<any>('PATCH', this.rootUrl + `/bot/eva/${vacancyId}`, __body, {
      headers: __headers,
      params: __params,
      responseType: 'json'
    })

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map(_r => {
        return _r as __StrictHttpResponse<EvaApproveResponse>
      })
    )
  }

  /**
   * @param vacancyId undefined
   * @return Success
   */
  appoveByEva(vacancyId: number): __Observable<EvaApproveResponse> {
    return this.appoveByEvaResponse(vacancyId).pipe(__map(_r => _r.body as EvaApproveResponse))
  }

  /**
   * @param userId undefined
   * @return Success
   */
  getMultiUserEmailResponse(userId: number): __Observable<__StrictHttpResponse<string>> {
    let __params = this.newParams()
    let __headers = new HttpHeaders()
    let __body: any = null

    let req = new HttpRequest<any>('GET', this.rootUrl + `/bot/multiuseremail/${userId}`, __body, {
      headers: __headers,
      params: __params,
      responseType: 'json'
    })

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map(_r => {
        return _r as __StrictHttpResponse<string>
      })
    )
  }

  /**
   * @param userId undefined
   * @return Success
   */
  getMultiUserEmail(userId: number): __Observable<string> {
    return this.getMultiUserEmailResponse(userId).pipe(__map(_r => _r.body as string))
  }

  /**
   * @param email undefined
   * @return Success
   */
  getMultiUserEmail_1Response(email?: string): __Observable<__StrictHttpResponse<MultiUserResponse>> {
    let __params = this.newParams()
    let __headers = new HttpHeaders()
    let __body: any = null
    if (email != null) __params = __params.set('email', email.toString())
    let req = new HttpRequest<any>('GET', this.rootUrl + `/bot/multiuserId`, __body, {
      headers: __headers,
      params: __params,
      responseType: 'json'
    })

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map(_r => {
        return _r as __StrictHttpResponse<MultiUserResponse>
      })
    )
  }

  /**
   * @param email undefined
   * @return Success
   */
  getMultiUserEmail_1(email?: string): __Observable<MultiUserResponse> {
    return this.getMultiUserEmail_1Response(email).pipe(__map(_r => _r.body as MultiUserResponse))
  }
}

module BotService {
  /**
   * Parameters for AutoscreeningCv
   */
  export interface AutoscreeningCvParams {
    request?: CvRecomRequest
    notebookId?: number
  }

  /**
   * Parameters for GetVacancies
   */
  export interface GetVacanciesParams {
    notebookId: number
    VacancyTypeId?: 'All' | 'Hot' | 'Banned' | 'Waiting' | 'NewAccount'
    VacancyStateId?:
      | 'All'
      | 'Deleted'
      | 'Banned'
      | 'Closed'
      | 'Publicated'
      | 'NotPublicated'
      | 'Suspend'
      | 'Waiting'
      | 'WaitingForAdmin'
      | 'BannedByAdmin'
      | 'PublicatedVirtual'
      | 'OnModeration'
      | 'BannedOnModeration'
    VacancyName?: string
    SortField?: string
    SortDirection?: string
    Page?: number
    MultiUserId?: number
    Code?: string
    CityId?: number
  }

  /**
   * Parameters for GetVacancy_1
   */
  export interface GetVacancy_1Params {
    vacancyId?: number
    notebookId?: number
  }

  /**
   * Parameters for SetState
   */
  export interface SetStateParams {
    id: number
    state?:
      | 'All'
      | 'Deleted'
      | 'Banned'
      | 'Closed'
      | 'Publicated'
      | 'NotPublicated'
      | 'Suspend'
      | 'Waiting'
      | 'WaitingForAdmin'
      | 'BannedByAdmin'
      | 'PublicatedVirtual'
      | 'OnModeration'
      | 'BannedOnModeration'
  }

  /**
   * Parameters for AddVacancy
   */
  export interface AddVacancyParams {
    vacancy?: VacancyAddRequest
    multiUserId?: number
  }

  /**
   * Parameters for EditVacancy
   */
  export interface EditVacancyParams {
    vacancy?: VacancyAddRequest
    multiUserId?: number
  }

  /**
   * Parameters for GetCompany
   */
  export interface GetCompanyParams {
    notebookId: number
    multiUserId?: number
  }

  /**
   * Parameters for MarkSurveyCv
   */
  export interface MarkSurveyCvParams {
    vacancyId?: number
    cvId?: number
  }

  /**
   * Parameters for ActivateSurvey
   */
  export interface ActivateSurveyParams {
    vacancyId?: number
    notebookId?: number
    login?: string
  }
}

export { BotService }
