import { Environment, EnvironmentApp } from '@alliance/shared/environment'
import { RxStateService } from '@alliance/shared/models'
import { Inject, Injectable } from '@angular/core'
import { LOCATION } from '@ng-web-apis/common'
import { AuthWithOtpService } from '@alliance/shared/auth-with-otp/domain'

@Injectable({ providedIn: 'root' })
export class LoginService extends RxStateService<object> {
  private readonly isDesktopSPA = this.environment.app === EnvironmentApp.desktop

  public constructor(@Inject(LOCATION) private location: Location, private environment: Environment, private authWithOtpService: AuthWithOtpService) {
    super()
  }

  public goToLogin(cancelRedirectAfterLogin: boolean = false, redirectLink = ''): void {
    if (this.isDesktopSPA) {
      this.authWithOtpService.goToLogin(cancelRedirectAfterLogin, redirectLink)
      return
    }

    this.goToLoginWithRefresh()
  }

  public goToLoginWithRefresh(): void {
    this.location.href = (this.environment.publicHost ?? '') + '/auth/login'
  }
}
