import { log } from '@alliance/shared/logger'
import { safeJsonParse } from './safe-json-parse'

// from https://stackoverflow.com/a/61454823
const universalAtob = (b64Encoded: string): string => {
  try {
    return atob(b64Encoded)
  } catch (err) {
    return Buffer.from(b64Encoded, 'base64').toString()
  }
}

const b64DecodeUnicode = (str = ''): string =>
  // https://developer.mozilla.org/en-US/docs/Web/API/WindowBase64/Base64_encoding_and_decoding
  // Going backwards: from bytestream, to percent-encoding, to original string.
  decodeURIComponent(
    universalAtob(
      (
        str
          .split('.')
          .splice(1, 1)
          .shift() || ''
      )
        .replace(/-/g, '+')
        .replace(/_/g, '/')
    )
      .split('')
      .map(c => '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2))
      .join('')
  )

export const decodeToken = <T extends object>(token: string | null): T | null => {
  if (token) {
    try {
      const decodedToken = b64DecodeUnicode(token)
      return safeJsonParse<T>(decodedToken, {} as T)
    } catch (e) {
      log.warn({ where: 'shared-utils: decode-token', category: 'try_catch', message: 'decodeToken failed', error: e })
      return null
    }
  }

  return null
}
