import { CvdbActivation } from './cvdb-activation'
export interface CvdbService {
  type?: CvdbService.TypeEnum
  serviceTypeId?: CvdbService.ServiceTypeIdEnum
  serviceTypeName?: string
  serviceTypeNameUkr?: string
  serviceId?: number
  serviceName?: string
  serviceNameUkr?: string
  count?: number
  isActivate?: boolean
  enableActivate?: boolean
  isPackage?: boolean
  openContactCount?: number
  usedContactCount?: number
  rubric1Ids?: Array<number>
  cityIds?: Array<number>
  orderId?: number
  orderDetailId?: number
  activationDetails?: Array<CvdbActivation>
  activationEndDate?: Date
  unitId?: CvdbService.UnitIdEnum
  publishType?: CvdbService.PublishTypeEnum
  ticketRest?: number
}

export namespace CvdbService {
  export type TypeEnum = 'Free' | 'Pack' | 'Test' | 'Unlimited'
  export const TypeEnum = {
    Free: 'Free' as TypeEnum,
    Pack: 'Pack' as TypeEnum,
    Test: 'Test' as TypeEnum,
    Unlimited: 'Unlimited' as TypeEnum
  }
  export type ServiceTypeIdEnum =
    | 'All'
    | 'AnonPiece'
    | 'Article'
    | 'BusinessPack'
    | 'BusinessPiece'
    | 'Channel'
    | 'CompanyProfile'
    | 'CvDb'
    | 'FreeBusiness'
    | 'HotPack'
    | 'HotPiece'
    | 'Interview'
    | 'InterviewPromo'
    | 'LogoMainPage'
    | 'LogoRubric'
    | 'Mailist'
    | 'MasterClass'
    | 'OptimumPack'
    | 'OptimumPiece'
    | 'ProfPack'
    | 'ProfPiece'
  export const ServiceTypeIdEnum = {
    All: 'All' as ServiceTypeIdEnum,
    AnonPiece: 'AnonPiece' as ServiceTypeIdEnum,
    Article: 'Article' as ServiceTypeIdEnum,
    BusinessPack: 'BusinessPack' as ServiceTypeIdEnum,
    BusinessPiece: 'BusinessPiece' as ServiceTypeIdEnum,
    Channel: 'Channel' as ServiceTypeIdEnum,
    CompanyProfile: 'CompanyProfile' as ServiceTypeIdEnum,
    CvDb: 'CvDb' as ServiceTypeIdEnum,
    FreeBusiness: 'FreeBusiness' as ServiceTypeIdEnum,
    HotPack: 'HotPack' as ServiceTypeIdEnum,
    HotPiece: 'HotPiece' as ServiceTypeIdEnum,
    Interview: 'Interview' as ServiceTypeIdEnum,
    InterviewPromo: 'InterviewPromo' as ServiceTypeIdEnum,
    LogoMainPage: 'LogoMainPage' as ServiceTypeIdEnum,
    LogoRubric: 'LogoRubric' as ServiceTypeIdEnum,
    Mailist: 'Mailist' as ServiceTypeIdEnum,
    MasterClass: 'MasterClass' as ServiceTypeIdEnum,
    OptimumPack: 'OptimumPack' as ServiceTypeIdEnum,
    OptimumPiece: 'OptimumPiece' as ServiceTypeIdEnum,
    ProfPack: 'ProfPack' as ServiceTypeIdEnum,
    ProfPiece: 'ProfPiece' as ServiceTypeIdEnum
  }
  export type UnitIdEnum = 'HalfYear' | 'Month' | 'Piece' | 'Quater' | 'Week' | 'Year'
  export const UnitIdEnum = {
    HalfYear: 'HalfYear' as UnitIdEnum,
    Month: 'Month' as UnitIdEnum,
    Piece: 'Piece' as UnitIdEnum,
    Quater: 'Quater' as UnitIdEnum,
    Week: 'Week' as UnitIdEnum,
    Year: 'Year' as UnitIdEnum
  }
  export type PublishTypeEnum = 'All' | 'Anonym' | 'Business' | 'Hot' | 'Optimum' | 'Professional' | 'Test'
  export const PublishTypeEnum = {
    All: 'All' as PublishTypeEnum,
    Anonym: 'Anonym' as PublishTypeEnum,
    Business: 'Business' as PublishTypeEnum,
    Hot: 'Hot' as PublishTypeEnum,
    Optimum: 'Optimum' as PublishTypeEnum,
    Professional: 'Professional' as PublishTypeEnum,
    Test: 'Test' as PublishTypeEnum
  }
}
