import { format as formatImpl, Locale } from 'date-fns'

export const format = (
  date: Date,
  formatString: string,
  options?:
    | {
        locale?: Locale | undefined
        weekStartsOn?: 0 | 1 | 2 | 3 | 4 | 5 | 6 | undefined
        firstWeekContainsDate?: 1 | 4 | undefined
        useAdditionalWeekYearTokens?: boolean | undefined
        useAdditionalDayOfYearTokens?: boolean | undefined
      }
    | undefined
): string => formatImpl(date, formatString, options)
