// https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Errors/Cyclic_object_value
const getCircularReplacer = (): ((key: unknown, value: unknown) => unknown) => {
  const seen = new WeakSet()
  return (_, value): unknown => {
    if (typeof value === 'object' && value !== null) {
      if (seen.has(value)) {
        return '[Circular]'
      }

      seen.add(value)
    }

    return value
  }
}

export const stringify = (value: unknown): string => JSON.stringify(value, getCircularReplacer())
