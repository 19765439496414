import { Injectable } from '@angular/core'
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http'
import { BaseService as __BaseService } from '../base-service'
import { JobseekerDataAccessConfiguration as __Configuration } from '../jobseeker-data-access-configuration'
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response'
import { Observable as __Observable } from 'rxjs'
import { map as __map, filter as __filter } from 'rxjs/operators'

import { VacancyQuestion } from '../models/vacancy-question'
import { RegisterAccountModel } from '../models/register-account-model'
import { PasswordChangeViaEmail } from '../models/password-change-via-email'
import { EmailChangeRequest } from '../models/email-change-request'
import { VacancySearchResponse } from '../models/vacancy-search-response'
import { ApplicationHistoryResponse } from '../models/application-history-response'
import { ApplyInfo } from '../models/apply-info'
import { ResumeViewResponse } from '../models/resume-view-response'
import { ApplicationStatusResult } from '../models/application-status-result'
import { ApplicationStatusItem } from '../models/application-status-item'
import { WishesPoolResult } from '../models/wishes-pool-result'
import { ResumeListItemDTO } from '../models/resume-list-item-dto'
import { AttachInfo } from '../models/attach-info'
import { ActivePeriodInfo } from '../models/active-period-info'
import { VacancyMailingSubscription } from '../models/vacancy-mailing-subscription'
import { NotificationMailingSubscription } from '../models/notification-mailing-subscription'
import { AlertInfo } from '../models/alert-info'
import { VacancyResultItem } from '../models/vacancy-result-item'
import { AlertUpdateInfo } from '../models/alert-update-info'
import { SubscribeCompetitorInfo } from '../models/subscribe-competitor-info'
import { VacancySearchUserResponse } from '../models/vacancy-search-user-response'
@Injectable({
  providedIn: 'root'
})
class AccountJobsearcherService extends __BaseService {
  public static readonly vacancyQuestionsAskQuestionPath = '/account/jobsearch/{cid}/vacancy/{vacancyId}/question'
  public static readonly vacancyQuestionsGetQuestionPath = '/account/jobsearch/vacancy-question/{questionId}'
  public static readonly accountJobsearchRegisterRegisterPath = '/account/jobsearch/register'
  public static readonly accountJobsearchRegisterRecoverForgotPasswordObsoletePath = '/account/jobsearch/passwordrecovery'
  public static readonly accountJobsearchRegisterSendPasswordRecoveryKeyToEmailPath = '/account/jobsearch/password/recovery-email/{email}'
  public static readonly accountJobsearchRegisterGetEmailToRecoverByKeyPath = '/account/jobsearch/password/recovery-email/{cachedKey}'
  public static readonly accountJobsearchRegisterChangePasswordPath = '/account/jobsearch/password'
  public static readonly accountJobsearchRegisterConfirmEmailByKeyPath = '/account/jobsearch/email-confirmation'
  public static readonly accountJobsearchRegisterConfirmEmailChangePath = '/account/jobsearch/email'
  public static readonly accountJobsearchRegisterSendEmailChangeKeyPath = '/account/jobsearch/email'
  public static readonly vacancyJobsearcherGetResumeViewsCountPath = '/account/jobseeker/{notebookId}/resumeviewscount'
  public static readonly vacancyJobsearcherFavoritesPath = '/account/jobsearch/likevacancy'
  public static readonly vacancyJobsearcherLikePath = '/account/jobsearch/likevacancy'
  public static readonly vacancyJobsearcherGetRecommendedPath = '/account/jobsearch/recommended'
  public static readonly vacancyJobsearcherRecommendedByResumePath = '/account/jobsearch/recommendedbyresume'
  public static readonly vacancyJobsearcherApplicationHistoryPath = '/account/jobsearch/application_history'
  public static readonly vacancyJobsearcherApplyDetailsPath = '/account/jobsearch/application_history/{applyId}/{resumeType}'
  public static readonly vacancyJobsearcherCvViewPath = '/account/jobsearch/cvview'
  public static readonly vacancyJobsearcherPopularPath = '/account/jobsearch/popular'
  public static readonly vacancyJobsearcherApplicationStatusPath = '/account/jobsearch/statusapplication'
  public static readonly vacancyJobsearcherApplicationStatus_1Path = '/account/jobsearch/statusapplication'
  public static readonly vacancyJobsearcherVacancyPoolPath = '/account/jobsearch/vacancy_pool'
  public static readonly applyCvListPath = '/account/jobsearch/cvlist'
  public static readonly getAttachListPath = '/account/jobsearch/attachlist'
  public static readonly applyAttachListPath = '/account/jobsearch/attachlist'
  public static readonly applyGetActivePeriodInfoPath = '/account/jobsearch/activeperiod'
  public static readonly applyLogApplyPath = '/account/jobsearch/logapply'
  public static readonly applyGetCvAttachPath = '/account/jobsearch/cv_attach/{attachId}'
  public static readonly emailSubscriptionSettingsGetVacancyMailingsPath = '/account/jobsearch/email-settings/alert-subscriptions'
  public static readonly emailSubscriptionSettingsCountVacancyMailingsPath = '/account/jobsearch/email-settings/alert-subscriptions/count'
  public static readonly emailSubscriptionSettingsToggleVacancySubscriptionPath = '/account/jobsearch/email-settings/alert-subscriptions/{id}/status/{isActive}'
  public static readonly emailSubscriptionSettingsToggleAllManualVacancySubscriptionPath = '/account/jobsearch/email-settings/alert-subscriptions/all-manual/status/{isActive}'
  public static readonly emailSubscriptionSettingsToggleAllAutomaticVacancySubscriptionPath = '/account/jobsearch/email-settings/alert-subscriptions/all-automatic/status/{isActive}'
  public static readonly emailSubscriptionSettingsGetNotificationMailingSubscriptionsPath = '/account/jobsearch/email-settings/notification-subscriptions'
  public static readonly emailSubscriptionSettingsChangeNotificationMailingSubscriptionPath = '/account/jobsearch/email-settings/notification-subscriptions/type/{type}/status/{isActive}'
  public static readonly emailSubscriptionSettingsGetMailingLanguagePath = '/account/jobsearch/email-settings/language'
  public static readonly emailSubscriptionSettingsUpdateMailingLanguagePath = '/account/jobsearch/email-settings/language/{language}'
  public static readonly jobsearcherAlertGetPath = '/account/jobsearch/alert'
  public static readonly jobsearcherAlertGet_1Path = '/account/jobsearch/alert/{id}'
  public static readonly jobsearcherAlertAddAlertByCvPath = '/account/jobsearch/addalertbycv/{id}'
  public static readonly jobsearcherAlertAddAlertByVacancyPath = '/account/jobsearch/addalertbyvacancy/{id}'
  public static readonly jobsearcherAlertAddAlertByCompanyPath = '/account/jobsearch/addalertbycompany/{id}'
  public static readonly jobsearcherAlertSaveJobAlertActivityPath = '/account/jobsearch/markactive/{id}'
  public static readonly jobsearcherAlertAddAlertPath = '/account/jobsearch/addalert'
  public static readonly jobsearcherAlertDoesUserHasSubscriptionPath = '/account/jobsearch/alert-subscription'
  public static readonly jobsearcherAlertUpdateSubscriptionPath = '/account/jobsearch/alert-subscription'
  public static readonly jobsearcherAlertUnsubscribeUserPath = '/account/jobsearch/alert-subscription'
  public static readonly jobsearcherAlertGetSubscriptionByIdPath = '/account/jobsearch/alert-subscription/{id}'
  public static readonly jobsearcherAlertUnsubscribeUserBySubscriptionIdPath = '/account/jobsearch/alert-subscription/{id}'
  public static readonly jobsearcherAlertRedirectToSearchBySubscriptionIdPath = '/account/jobsearch/alert-subscription-vacancies/{id}'
  public static readonly jobsearcherAlertDoesUserHasSubscriptionOnCompanyPath = '/account/jobsearch/alert-subscription/company'

  public constructor(config: __Configuration, http: HttpClient) {
    super(config, http)
  }

  /**
   * @param params The `AccountJobsearcherService.VacancyQuestionsAskQuestionParams` containing the following parameters:
   *
   * - `vacancyId`:
   *
   * - `question`:
   *
   * - `cid`:
   */
  vacancyQuestionsAskQuestionResponse(params: AccountJobsearcherService.VacancyQuestionsAskQuestionParams): __Observable<__StrictHttpResponse<any>> {
    let __params = this.newParams()
    let __headers = new HttpHeaders()
    let __body: any = null

    __body = params.question

    let req = new HttpRequest<any>('POST', this.rootUrl + `/account/jobsearch/${params.cid}/vacancy/${params.vacancyId}/question`, __body, {
      headers: __headers,
      params: __params,
      responseType: 'json'
    })

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map(_r => {
        return _r as __StrictHttpResponse<any>
      })
    )
  }

  /**
   * @param params The `AccountJobsearcherService.VacancyQuestionsAskQuestionParams` containing the following parameters:
   *
   * - `vacancyId`:
   *
   * - `question`:
   *
   * - `cid`:
   */
  vacancyQuestionsAskQuestion(params: AccountJobsearcherService.VacancyQuestionsAskQuestionParams): __Observable<any> {
    return this.vacancyQuestionsAskQuestionResponse(params).pipe(__map(_r => _r.body as any))
  }

  /**
   * @param questionId undefined
   * @return OK
   */
  vacancyQuestionsGetQuestionResponse(questionId: number): __Observable<__StrictHttpResponse<VacancyQuestion>> {
    let __params = this.newParams()
    let __headers = new HttpHeaders()
    let __body: any = null

    let req = new HttpRequest<any>('GET', this.rootUrl + `/account/jobsearch/vacancy-question/${questionId}`, __body, {
      headers: __headers,
      params: __params,
      responseType: 'json'
    })

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map(_r => {
        return _r as __StrictHttpResponse<VacancyQuestion>
      })
    )
  }

  /**
   * @param questionId undefined
   * @return OK
   */
  vacancyQuestionsGetQuestion(questionId: number): __Observable<VacancyQuestion> {
    return this.vacancyQuestionsGetQuestionResponse(questionId).pipe(__map(_r => _r.body as VacancyQuestion))
  }

  /**
   * @param model
   * @return OK
   */
  accountJobsearchRegisterRegisterResponse(model: RegisterAccountModel): __Observable<__StrictHttpResponse<{}>> {
    let __params = this.newParams()
    let __headers = new HttpHeaders()
    let __body: any = null
    __body = model
    let req = new HttpRequest<any>('POST', this.rootUrl + `/account/jobsearch/register`, __body, {
      headers: __headers,
      params: __params,
      responseType: 'json'
    })

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map(_r => {
        return _r as __StrictHttpResponse<{}>
      })
    )
  }

  /**
   * @param model
   * @return OK
   */
  accountJobsearchRegisterRegister(model: RegisterAccountModel): __Observable<{}> {
    return this.accountJobsearchRegisterRegisterResponse(model).pipe(__map(_r => _r.body as {}))
  }

  /**
   * @param email undefined
   * @return OK
   */
  accountJobsearchRegisterRecoverForgotPasswordObsoleteResponse(email: string): __Observable<__StrictHttpResponse<{}>> {
    let __params = this.newParams()
    let __headers = new HttpHeaders()
    let __body: any = null
    if (email != null) __params = __params.set('email', email.toString())
    let req = new HttpRequest<any>('POST', this.rootUrl + `/account/jobsearch/passwordrecovery`, __body, {
      headers: __headers,
      params: __params,
      responseType: 'json'
    })

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map(_r => {
        return _r as __StrictHttpResponse<{}>
      })
    )
  }

  /**
   * @param email undefined
   * @return OK
   */
  accountJobsearchRegisterRecoverForgotPasswordObsolete(email: string): __Observable<{}> {
    return this.accountJobsearchRegisterRecoverForgotPasswordObsoleteResponse(email).pipe(__map(_r => _r.body as {}))
  }

  /**
   * @param email undefined
   * @return OK
   */
  accountJobsearchRegisterSendPasswordRecoveryKeyToEmailResponse(email: string): __Observable<__StrictHttpResponse<{}>> {
    let __params = this.newParams()
    let __headers = new HttpHeaders()
    let __body: any = null

    let req = new HttpRequest<any>('POST', this.rootUrl + `/account/jobsearch/password/recovery-email/${email}`, __body, {
      headers: __headers,
      params: __params,
      responseType: 'json'
    })

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map(_r => {
        return _r as __StrictHttpResponse<{}>
      })
    )
  }

  /**
   * @param email undefined
   * @return OK
   */
  accountJobsearchRegisterSendPasswordRecoveryKeyToEmail(email: string): __Observable<{}> {
    return this.accountJobsearchRegisterSendPasswordRecoveryKeyToEmailResponse(email).pipe(__map(_r => _r.body as {}))
  }

  /**
   * @param cachedKey undefined
   * @return OK
   */
  accountJobsearchRegisterGetEmailToRecoverByKeyResponse(cachedKey: string): __Observable<__StrictHttpResponse<{}>> {
    let __params = this.newParams()
    let __headers = new HttpHeaders()
    let __body: any = null

    let req = new HttpRequest<any>('GET', this.rootUrl + `/account/jobsearch/password/recovery-email/${cachedKey}`, __body, {
      headers: __headers,
      params: __params,
      responseType: 'json'
    })

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map(_r => {
        return _r as __StrictHttpResponse<{}>
      })
    )
  }

  /**
   * @param cachedKey undefined
   * @return OK
   */
  accountJobsearchRegisterGetEmailToRecoverByKey(cachedKey: string): __Observable<{}> {
    return this.accountJobsearchRegisterGetEmailToRecoverByKeyResponse(cachedKey).pipe(__map(_r => _r.body as {}))
  }

  /**
   * @param request undefined
   * @return OK
   */
  accountJobsearchRegisterChangePasswordResponse(request: PasswordChangeViaEmail): __Observable<__StrictHttpResponse<{}>> {
    let __params = this.newParams()
    let __headers = new HttpHeaders()
    let __body: any = null
    __body = request
    let req = new HttpRequest<any>('PUT', this.rootUrl + `/account/jobsearch/password`, __body, {
      headers: __headers,
      params: __params,
      responseType: 'json'
    })

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map(_r => {
        return _r as __StrictHttpResponse<{}>
      })
    )
  }

  /**
   * @param request undefined
   * @return OK
   */
  accountJobsearchRegisterChangePassword(request: PasswordChangeViaEmail): __Observable<{}> {
    return this.accountJobsearchRegisterChangePasswordResponse(request).pipe(__map(_r => _r.body as {}))
  }

  /**
   * @param key undefined
   * @return OK
   */
  accountJobsearchRegisterConfirmEmailByKeyResponse(key: string): __Observable<__StrictHttpResponse<{}>> {
    let __params = this.newParams()
    let __headers = new HttpHeaders()
    let __body: any = null
    if (key != null) __params = __params.set('key', key.toString())
    let req = new HttpRequest<any>('GET', this.rootUrl + `/account/jobsearch/email-confirmation`, __body, {
      headers: __headers,
      params: __params,
      responseType: 'json'
    })

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map(_r => {
        return _r as __StrictHttpResponse<{}>
      })
    )
  }

  /**
   * @param key undefined
   * @return OK
   */
  accountJobsearchRegisterConfirmEmailByKey(key: string): __Observable<{}> {
    return this.accountJobsearchRegisterConfirmEmailByKeyResponse(key).pipe(__map(_r => _r.body as {}))
  }

  /**
   * @param key undefined
   * @return OK
   */
  accountJobsearchRegisterConfirmEmailChangeResponse(key: string): __Observable<__StrictHttpResponse<{}>> {
    let __params = this.newParams()
    let __headers = new HttpHeaders()
    let __body: any = null
    if (key != null) __params = __params.set('key', key.toString())
    let req = new HttpRequest<any>('GET', this.rootUrl + `/account/jobsearch/email`, __body, {
      headers: __headers,
      params: __params,
      responseType: 'json'
    })

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map(_r => {
        return _r as __StrictHttpResponse<{}>
      })
    )
  }

  /**
   * @param key undefined
   * @return OK
   */
  accountJobsearchRegisterConfirmEmailChange(key: string): __Observable<{}> {
    return this.accountJobsearchRegisterConfirmEmailChangeResponse(key).pipe(__map(_r => _r.body as {}))
  }

  /**
   * @param request undefined
   * @return OK
   */
  accountJobsearchRegisterSendEmailChangeKeyResponse(request: EmailChangeRequest): __Observable<__StrictHttpResponse<{}>> {
    let __params = this.newParams()
    let __headers = new HttpHeaders()
    let __body: any = null
    __body = request
    let req = new HttpRequest<any>('POST', this.rootUrl + `/account/jobsearch/email`, __body, {
      headers: __headers,
      params: __params,
      responseType: 'json'
    })

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map(_r => {
        return _r as __StrictHttpResponse<{}>
      })
    )
  }

  /**
   * @param request undefined
   * @return OK
   */
  accountJobsearchRegisterSendEmailChangeKey(request: EmailChangeRequest): __Observable<{}> {
    return this.accountJobsearchRegisterSendEmailChangeKeyResponse(request).pipe(__map(_r => _r.body as {}))
  }

  /**
   * @param notebookId undefined
   * @return OK
   */
  vacancyJobsearcherGetResumeViewsCountResponse(notebookId: number): __Observable<__StrictHttpResponse<number>> {
    let __params = this.newParams()
    let __headers = new HttpHeaders()
    let __body: any = null

    let req = new HttpRequest<any>('GET', this.rootUrl + `/account/jobseeker/${notebookId}/resumeviewscount`, __body, {
      headers: __headers,
      params: __params,
      responseType: 'json'
    })

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map(_r => {
        return (_r as HttpResponse<any>).clone({
          body: parseFloat((_r as HttpResponse<any>).body as string)
        }) as __StrictHttpResponse<number>
      })
    )
  }

  /**
   * @param notebookId undefined
   * @return OK
   */
  vacancyJobsearcherGetResumeViewsCount(notebookId: number): __Observable<number> {
    return this.vacancyJobsearcherGetResumeViewsCountResponse(notebookId).pipe(__map(_r => _r.body as number))
  }

  /**
   * @param params The `AccountJobsearcherService.VacancyJobsearcherFavoritesParams` containing the following parameters:
   *
   * - `start`:
   *
   * - `count`:
   *
   * @return OK
   */
  vacancyJobsearcherFavoritesResponse(params: AccountJobsearcherService.VacancyJobsearcherFavoritesParams): __Observable<__StrictHttpResponse<VacancySearchResponse>> {
    let __params = this.newParams()
    let __headers = new HttpHeaders()
    let __body: any = null
    if (params.start != null) __params = __params.set('start', params.start.toString())
    if (params.count != null) __params = __params.set('count', params.count.toString())
    let req = new HttpRequest<any>('GET', this.rootUrl + `/account/jobsearch/likevacancy`, __body, {
      headers: __headers,
      params: __params,
      responseType: 'json'
    })

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map(_r => {
        return _r as __StrictHttpResponse<VacancySearchResponse>
      })
    )
  }

  /**
   * @param params The `AccountJobsearcherService.VacancyJobsearcherFavoritesParams` containing the following parameters:
   *
   * - `start`:
   *
   * - `count`:
   *
   * @return OK
   */
  vacancyJobsearcherFavorites(params: AccountJobsearcherService.VacancyJobsearcherFavoritesParams): __Observable<VacancySearchResponse> {
    return this.vacancyJobsearcherFavoritesResponse(params).pipe(__map(_r => _r.body as VacancySearchResponse))
  }

  /**
   * @param params The `AccountJobsearcherService.VacancyJobsearcherLikeParams` containing the following parameters:
   *
   * - `like`:
   *
   * - `id`:
   *
   * @return OK
   */
  vacancyJobsearcherLikeResponse(params: AccountJobsearcherService.VacancyJobsearcherLikeParams): __Observable<__StrictHttpResponse<{}>> {
    let __params = this.newParams()
    let __headers = new HttpHeaders()
    let __body: any = null
    if (params.like != null) __params = __params.set('like', params.like.toString())
    if (params.id != null) __params = __params.set('id', params.id.toString())
    let req = new HttpRequest<any>('POST', this.rootUrl + `/account/jobsearch/likevacancy`, __body, {
      headers: __headers,
      params: __params,
      responseType: 'json'
    })

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map(_r => {
        return _r as __StrictHttpResponse<{}>
      })
    )
  }

  /**
   * @param params The `AccountJobsearcherService.VacancyJobsearcherLikeParams` containing the following parameters:
   *
   * - `like`:
   *
   * - `id`:
   *
   * @return OK
   */
  vacancyJobsearcherLike(params: AccountJobsearcherService.VacancyJobsearcherLikeParams): __Observable<{}> {
    return this.vacancyJobsearcherLikeResponse(params).pipe(__map(_r => _r.body as {}))
  }

  /**
   * @param params The `AccountJobsearcherService.VacancyJobsearcherGetRecommendedParams` containing the following parameters:
   *
   * - `start`: From which vacancy number return recommendation list
   *
   * - `guid`: User id (if not specified, than current user id is taken).
   *   Based on this, City and KeyWords are taken for search.
   *
   * - `count`: How many vacancies to take from the top of the recommendation result list
   *
   * @return OK
   */
  vacancyJobsearcherGetRecommendedResponse(params: AccountJobsearcherService.VacancyJobsearcherGetRecommendedParams): __Observable<__StrictHttpResponse<VacancySearchResponse>> {
    let __params = this.newParams()
    let __headers = new HttpHeaders()
    let __body: any = null
    if (params.start != null) __params = __params.set('start', params.start.toString())
    if (params.guid != null) __params = __params.set('guid', params.guid.toString())
    if (params.count != null) __params = __params.set('count', params.count.toString())
    let req = new HttpRequest<any>('POST', this.rootUrl + `/account/jobsearch/recommended`, __body, {
      headers: __headers,
      params: __params,
      responseType: 'json'
    })

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map(_r => {
        return _r as __StrictHttpResponse<VacancySearchResponse>
      })
    )
  }

  /**
   * @param params The `AccountJobsearcherService.VacancyJobsearcherGetRecommendedParams` containing the following parameters:
   *
   * - `start`: From which vacancy number return recommendation list
   *
   * - `guid`: User id (if not specified, than current user id is taken).
   *   Based on this, City and KeyWords are taken for search.
   *
   * - `count`: How many vacancies to take from the top of the recommendation result list
   *
   * @return OK
   */
  vacancyJobsearcherGetRecommended(params: AccountJobsearcherService.VacancyJobsearcherGetRecommendedParams): __Observable<VacancySearchResponse> {
    return this.vacancyJobsearcherGetRecommendedResponse(params).pipe(__map(_r => _r.body as VacancySearchResponse))
  }

  /**
   * @param params The `AccountJobsearcherService.VacancyJobsearcherRecommendedByResumeParams` containing the following parameters:
   *
   * - `position`:
   *
   * - `cityId`:
   *
   * - `start`:
   *
   * - `count`:
   *
   * @return OK
   */
  vacancyJobsearcherRecommendedByResumeResponse(params: AccountJobsearcherService.VacancyJobsearcherRecommendedByResumeParams): __Observable<__StrictHttpResponse<VacancySearchResponse>> {
    let __params = this.newParams()
    let __headers = new HttpHeaders()
    let __body: any = null
    if (params.position != null) __params = __params.set('position', params.position.toString())
    if (params.cityId != null) __params = __params.set('cityId', params.cityId.toString())
    if (params.start != null) __params = __params.set('start', params.start.toString())
    if (params.count != null) __params = __params.set('count', params.count.toString())
    let req = new HttpRequest<any>('POST', this.rootUrl + `/account/jobsearch/recommendedbyresume`, __body, {
      headers: __headers,
      params: __params,
      responseType: 'json'
    })

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map(_r => {
        return _r as __StrictHttpResponse<VacancySearchResponse>
      })
    )
  }

  /**
   * @param params The `AccountJobsearcherService.VacancyJobsearcherRecommendedByResumeParams` containing the following parameters:
   *
   * - `position`:
   *
   * - `cityId`:
   *
   * - `start`:
   *
   * - `count`:
   *
   * @return OK
   */
  vacancyJobsearcherRecommendedByResume(params: AccountJobsearcherService.VacancyJobsearcherRecommendedByResumeParams): __Observable<VacancySearchResponse> {
    return this.vacancyJobsearcherRecommendedByResumeResponse(params).pipe(__map(_r => _r.body as VacancySearchResponse))
  }

  /**
   * @param params The `AccountJobsearcherService.VacancyJobsearcherApplicationHistoryParams` containing the following parameters:
   *
   * - `start`:
   *
   * - `count`:
   *
   * @return OK
   */
  vacancyJobsearcherApplicationHistoryResponse(params: AccountJobsearcherService.VacancyJobsearcherApplicationHistoryParams): __Observable<__StrictHttpResponse<ApplicationHistoryResponse>> {
    let __params = this.newParams()
    let __headers = new HttpHeaders()
    let __body: any = null
    if (params.start != null) __params = __params.set('start', params.start.toString())
    if (params.count != null) __params = __params.set('count', params.count.toString())
    let req = new HttpRequest<any>('GET', this.rootUrl + `/account/jobsearch/application_history`, __body, {
      headers: __headers,
      params: __params,
      responseType: 'json'
    })

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map(_r => {
        return _r as __StrictHttpResponse<ApplicationHistoryResponse>
      })
    )
  }

  /**
   * @param params The `AccountJobsearcherService.VacancyJobsearcherApplicationHistoryParams` containing the following parameters:
   *
   * - `start`:
   *
   * - `count`:
   *
   * @return OK
   */
  vacancyJobsearcherApplicationHistory(params: AccountJobsearcherService.VacancyJobsearcherApplicationHistoryParams): __Observable<ApplicationHistoryResponse> {
    return this.vacancyJobsearcherApplicationHistoryResponse(params).pipe(__map(_r => _r.body as ApplicationHistoryResponse))
  }

  /**
   * @param params The `AccountJobsearcherService.VacancyJobsearcherApplyDetailsParams` containing the following parameters:
   *
   * - `resumeType`:
   *
   * - `applyId`:
   */
  vacancyJobsearcherApplyDetailsResponse(params: AccountJobsearcherService.VacancyJobsearcherApplyDetailsParams): __Observable<__StrictHttpResponse<ApplyInfo>> {
    let __params = this.newParams()
    let __headers = new HttpHeaders()
    let __body: any = null

    let req = new HttpRequest<any>('GET', this.rootUrl + `/account/jobsearch/application_history/${params.applyId}/${params.resumeType}`, __body, {
      headers: __headers,
      params: __params,
      responseType: 'json'
    })

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map(_r => {
        return _r as __StrictHttpResponse<ApplyInfo>
      })
    )
  }

  /**
   * @param params The `AccountJobsearcherService.VacancyJobsearcherApplyDetailsParams` containing the following parameters:
   *
   * - `resumeType`:
   *
   * - `applyId`:
   */
  vacancyJobsearcherApplyDetails(params: AccountJobsearcherService.VacancyJobsearcherApplyDetailsParams): __Observable<ApplyInfo> {
    return this.vacancyJobsearcherApplyDetailsResponse(params).pipe(__map(_r => _r.body as ApplyInfo))
  }

  /**
   * @param params The `AccountJobsearcherService.VacancyJobsearcherCvViewParams` containing the following parameters:
   *
   * - `start`:
   *
   * - `cvId`:
   *
   * - `count`:
   *
   * @return OK
   */
  vacancyJobsearcherCvViewResponse(params: AccountJobsearcherService.VacancyJobsearcherCvViewParams): __Observable<__StrictHttpResponse<ResumeViewResponse>> {
    let __params = this.newParams()
    let __headers = new HttpHeaders()
    let __body: any = null
    if (params.start != null) __params = __params.set('start', params.start.toString())
    if (params.cvId != null) __params = __params.set('cvId', params.cvId.toString())
    if (params.count != null) __params = __params.set('count', params.count.toString())
    let req = new HttpRequest<any>('GET', this.rootUrl + `/account/jobsearch/cvview`, __body, {
      headers: __headers,
      params: __params,
      responseType: 'json'
    })

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map(_r => {
        return _r as __StrictHttpResponse<ResumeViewResponse>
      })
    )
  }

  /**
   * @param params The `AccountJobsearcherService.VacancyJobsearcherCvViewParams` containing the following parameters:
   *
   * - `start`:
   *
   * - `cvId`:
   *
   * - `count`:
   *
   * @return OK
   */
  vacancyJobsearcherCvView(params: AccountJobsearcherService.VacancyJobsearcherCvViewParams): __Observable<ResumeViewResponse> {
    return this.vacancyJobsearcherCvViewResponse(params).pipe(__map(_r => _r.body as ResumeViewResponse))
  }

  /**
   * @return OK
   */
  vacancyJobsearcherPopularResponse(): __Observable<__StrictHttpResponse<VacancySearchResponse>> {
    let __params = this.newParams()
    let __headers = new HttpHeaders()
    let __body: any = null
    let req = new HttpRequest<any>('POST', this.rootUrl + `/account/jobsearch/popular`, __body, {
      headers: __headers,
      params: __params,
      responseType: 'json'
    })

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map(_r => {
        return _r as __StrictHttpResponse<VacancySearchResponse>
      })
    )
  }

  /**
   * @return OK
   */
  vacancyJobsearcherPopular(): __Observable<VacancySearchResponse> {
    return this.vacancyJobsearcherPopularResponse().pipe(__map(_r => _r.body as VacancySearchResponse))
  }

  /**
   * @param params The `AccountJobsearcherService.VacancyJobsearcherApplicationStatusParams` containing the following parameters:
   *
   * - `attach`:
   *
   * - `applyId`:
   *
   * @return OK
   */
  vacancyJobsearcherApplicationStatusResponse(params: AccountJobsearcherService.VacancyJobsearcherApplicationStatusParams): __Observable<__StrictHttpResponse<ApplicationStatusResult>> {
    let __params = this.newParams()
    let __headers = new HttpHeaders()
    let __body: any = null
    if (params.attach != null) __params = __params.set('attach', params.attach.toString())
    if (params.applyId != null) __params = __params.set('applyId', params.applyId.toString())
    let req = new HttpRequest<any>('GET', this.rootUrl + `/account/jobsearch/statusapplication`, __body, {
      headers: __headers,
      params: __params,
      responseType: 'json'
    })

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map(_r => {
        return _r as __StrictHttpResponse<ApplicationStatusResult>
      })
    )
  }

  /**
   * @param params The `AccountJobsearcherService.VacancyJobsearcherApplicationStatusParams` containing the following parameters:
   *
   * - `attach`:
   *
   * - `applyId`:
   *
   * @return OK
   */
  vacancyJobsearcherApplicationStatus(params: AccountJobsearcherService.VacancyJobsearcherApplicationStatusParams): __Observable<ApplicationStatusResult> {
    return this.vacancyJobsearcherApplicationStatusResponse(params).pipe(__map(_r => _r.body as ApplicationStatusResult))
  }

  /**
   * @param data undefined
   * @return OK
   */
  vacancyJobsearcherApplicationStatus_1Response(data: ApplicationStatusItem): __Observable<__StrictHttpResponse<ApplicationStatusResult>> {
    let __params = this.newParams()
    let __headers = new HttpHeaders()
    let __body: any = null
    __body = data
    let req = new HttpRequest<any>('POST', this.rootUrl + `/account/jobsearch/statusapplication`, __body, {
      headers: __headers,
      params: __params,
      responseType: 'json'
    })

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map(_r => {
        return _r as __StrictHttpResponse<ApplicationStatusResult>
      })
    )
  }

  /**
   * @param data undefined
   * @return OK
   */
  vacancyJobsearcherApplicationStatus_1(data: ApplicationStatusItem): __Observable<ApplicationStatusResult> {
    return this.vacancyJobsearcherApplicationStatus_1Response(data).pipe(__map(_r => _r.body as ApplicationStatusResult))
  }

  /**
   * @param params The `AccountJobsearcherService.VacancyJobsearcherVacancyPoolParams` containing the following parameters:
   *
   * - `pzId`:
   *
   * - `cityId`:
   *
   * - `vacancyId`:
   *
   * @return OK
   */
  vacancyJobsearcherVacancyPoolResponse(params: AccountJobsearcherService.VacancyJobsearcherVacancyPoolParams): __Observable<__StrictHttpResponse<WishesPoolResult>> {
    let __params = this.newParams()
    let __headers = new HttpHeaders()
    let __body: any = null
    if (params.pzId != null) __params = __params.set('pzId', params.pzId.toString())
    if (params.cityId != null) __params = __params.set('cityId', params.cityId.toString())
    if (params.vacancyId != null) __params = __params.set('vacancyId', params.vacancyId.toString())
    let req = new HttpRequest<any>('GET', this.rootUrl + `/account/jobsearch/vacancy_pool`, __body, {
      headers: __headers,
      params: __params,
      responseType: 'json'
    })

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map(_r => {
        return _r as __StrictHttpResponse<WishesPoolResult>
      })
    )
  }

  /**
   * @param params The `AccountJobsearcherService.VacancyJobsearcherVacancyPoolParams` containing the following parameters:
   *
   * - `pzId`:
   *
   * - `cityId`:
   *
   * - `vacancyId`:
   *
   * @return OK
   */
  vacancyJobsearcherVacancyPool(params: AccountJobsearcherService.VacancyJobsearcherVacancyPoolParams): __Observable<WishesPoolResult> {
    return this.vacancyJobsearcherVacancyPoolResponse(params).pipe(__map(_r => _r.body as WishesPoolResult))
  }

  /**
   * @return OK
   */
  applyCvListResponse(): __Observable<__StrictHttpResponse<Array<ResumeListItemDTO>>> {
    let __params = this.newParams()
    let __headers = new HttpHeaders()
    let __body: any = null
    let req = new HttpRequest<any>('POST', this.rootUrl + `/account/jobsearch/cvlist`, __body, {
      headers: __headers,
      params: __params,
      responseType: 'json'
    })

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map(_r => {
        return _r as __StrictHttpResponse<Array<ResumeListItemDTO>>
      })
    )
  }

  /**
   * @return OK
   */
  applyCvList(): __Observable<Array<ResumeListItemDTO>> {
    return this.applyCvListResponse().pipe(__map(_r => _r.body as Array<ResumeListItemDTO>))
  }

  /**
   * @param email undefined
   * @return OK
   */
  getAttachListResponse(email?: string): __Observable<__StrictHttpResponse<Array<AttachInfo>>> {
    let __params = this.newParams()
    let __headers = new HttpHeaders()
    let __body: any = null
    if (email != null) __params = __params.set('email', email.toString())
    let req = new HttpRequest<any>('GET', this.rootUrl + `/account/jobsearch/attachlist`, __body, {
      headers: __headers,
      params: __params,
      responseType: 'json'
    })

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map(_r => {
        return _r as __StrictHttpResponse<Array<AttachInfo>>
      })
    )
  }

  /**
   * @param email undefined
   * @return OK
   */
  getAttachList(email?: string): __Observable<Array<AttachInfo>> {
    return this.getAttachListResponse(email).pipe(__map(_r => _r.body as Array<AttachInfo>))
  }

  /**
   * @param email undefined
   * @return OK
   */
  applyAttachListResponse(email?: string): __Observable<__StrictHttpResponse<Array<AttachInfo>>> {
    let __params = this.newParams()
    let __headers = new HttpHeaders()
    let __body: any = null
    if (email != null) __params = __params.set('email', email.toString())
    let req = new HttpRequest<any>('POST', this.rootUrl + `/account/jobsearch/attachlist`, __body, {
      headers: __headers,
      params: __params,
      responseType: 'json'
    })

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map(_r => {
        return _r as __StrictHttpResponse<Array<AttachInfo>>
      })
    )
  }

  /**
   * @param email undefined
   * @return OK
   */
  applyAttachList(email?: string): __Observable<Array<AttachInfo>> {
    return this.applyAttachListResponse(email).pipe(__map(_r => _r.body as Array<AttachInfo>))
  }

  /**
   * @param uid undefined
   * @return OK
   */
  applyGetActivePeriodInfoResponse(uid: number): __Observable<__StrictHttpResponse<ActivePeriodInfo>> {
    let __params = this.newParams()
    let __headers = new HttpHeaders()
    let __body: any = null
    if (uid != null) __params = __params.set('uid', uid.toString())
    let req = new HttpRequest<any>('GET', this.rootUrl + `/account/jobsearch/activeperiod`, __body, {
      headers: __headers,
      params: __params,
      responseType: 'json'
    })

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map(_r => {
        return _r as __StrictHttpResponse<ActivePeriodInfo>
      })
    )
  }

  /**
   * @param uid undefined
   * @return OK
   */
  applyGetActivePeriodInfo(uid: number): __Observable<ActivePeriodInfo> {
    return this.applyGetActivePeriodInfoResponse(uid).pipe(__map(_r => _r.body as ActivePeriodInfo))
  }

  /**
   * @param params The `AccountJobsearcherService.ApplyLogApplyParams` containing the following parameters:
   *
   * - `vacancyId`:
   *
   * - `email`:
   *
   * @return OK
   */
  applyLogApplyResponse(params: AccountJobsearcherService.ApplyLogApplyParams): __Observable<__StrictHttpResponse<{}>> {
    let __params = this.newParams()
    let __headers = new HttpHeaders()
    let __body: any = null
    if (params.vacancyId != null) __params = __params.set('vacancyId', params.vacancyId.toString())
    if (params.email != null) __params = __params.set('email', params.email.toString())
    let req = new HttpRequest<any>('POST', this.rootUrl + `/account/jobsearch/logapply`, __body, {
      headers: __headers,
      params: __params,
      responseType: 'json'
    })

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map(_r => {
        return _r as __StrictHttpResponse<{}>
      })
    )
  }

  /**
   * @param params The `AccountJobsearcherService.ApplyLogApplyParams` containing the following parameters:
   *
   * - `vacancyId`:
   *
   * - `email`:
   *
   * @return OK
   */
  applyLogApply(params: AccountJobsearcherService.ApplyLogApplyParams): __Observable<{}> {
    return this.applyLogApplyResponse(params).pipe(__map(_r => _r.body as {}))
  }

  /**
   * @param attachId undefined
   * @return OK
   */
  applyGetCvAttachResponse(attachId: number): __Observable<__StrictHttpResponse<{}>> {
    let __params = this.newParams()
    let __headers = new HttpHeaders()
    let __body: any = null

    let req = new HttpRequest<any>('GET', this.rootUrl + `/account/jobsearch/cv_attach/${attachId}`, __body, {
      headers: __headers,
      params: __params,
      responseType: 'json'
    })

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map(_r => {
        return _r as __StrictHttpResponse<{}>
      })
    )
  }

  /**
   * @param attachId undefined
   * @return OK
   */
  applyGetCvAttach(attachId: number): __Observable<{}> {
    return this.applyGetCvAttachResponse(attachId).pipe(__map(_r => _r.body as {}))
  }

  /**
   * @param isCreatedManually undefined
   * @return OK
   */
  emailSubscriptionSettingsGetVacancyMailingsResponse(isCreatedManually: boolean): __Observable<__StrictHttpResponse<Array<VacancyMailingSubscription>>> {
    let __params = this.newParams()
    let __headers = new HttpHeaders()
    let __body: any = null
    if (isCreatedManually != null) __params = __params.set('isCreatedManually', isCreatedManually.toString())
    let req = new HttpRequest<any>('GET', this.rootUrl + `/account/jobsearch/email-settings/alert-subscriptions`, __body, {
      headers: __headers,
      params: __params,
      responseType: 'json'
    })

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map(_r => {
        return _r as __StrictHttpResponse<Array<VacancyMailingSubscription>>
      })
    )
  }

  /**
   * @param isCreatedManually undefined
   * @return OK
   */
  emailSubscriptionSettingsGetVacancyMailings(isCreatedManually: boolean): __Observable<Array<VacancyMailingSubscription>> {
    return this.emailSubscriptionSettingsGetVacancyMailingsResponse(isCreatedManually).pipe(__map(_r => _r.body as Array<VacancyMailingSubscription>))
  }

  /**
   * @param isCreatedManually undefined
   * @return OK
   */
  emailSubscriptionSettingsCountVacancyMailingsResponse(isCreatedManually: boolean): __Observable<__StrictHttpResponse<number>> {
    let __params = this.newParams()
    let __headers = new HttpHeaders()
    let __body: any = null
    if (isCreatedManually != null) __params = __params.set('isCreatedManually', isCreatedManually.toString())
    let req = new HttpRequest<any>('GET', this.rootUrl + `/account/jobsearch/email-settings/alert-subscriptions/count`, __body, {
      headers: __headers,
      params: __params,
      responseType: 'json'
    })

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map(_r => {
        return (_r as HttpResponse<any>).clone({
          body: parseFloat((_r as HttpResponse<any>).body as string)
        }) as __StrictHttpResponse<number>
      })
    )
  }

  /**
   * @param isCreatedManually undefined
   * @return OK
   */
  emailSubscriptionSettingsCountVacancyMailings(isCreatedManually: boolean): __Observable<number> {
    return this.emailSubscriptionSettingsCountVacancyMailingsResponse(isCreatedManually).pipe(__map(_r => _r.body as number))
  }

  /**
   * @param params The `AccountJobsearcherService.EmailSubscriptionSettingsToggleVacancySubscriptionParams` containing the following parameters:
   *
   * - `isActive`:
   *
   * - `id`:
   */
  emailSubscriptionSettingsToggleVacancySubscriptionResponse(params: AccountJobsearcherService.EmailSubscriptionSettingsToggleVacancySubscriptionParams): __Observable<__StrictHttpResponse<any>> {
    let __params = this.newParams()
    let __headers = new HttpHeaders()
    let __body: any = null

    let req = new HttpRequest<any>('PUT', this.rootUrl + `/account/jobsearch/email-settings/alert-subscriptions/${params.id}/status/${params.isActive}`, __body, {
      headers: __headers,
      params: __params,
      responseType: 'json'
    })

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map(_r => {
        return _r as __StrictHttpResponse<any>
      })
    )
  }

  /**
   * @param params The `AccountJobsearcherService.EmailSubscriptionSettingsToggleVacancySubscriptionParams` containing the following parameters:
   *
   * - `isActive`:
   *
   * - `id`:
   */
  emailSubscriptionSettingsToggleVacancySubscription(params: AccountJobsearcherService.EmailSubscriptionSettingsToggleVacancySubscriptionParams): __Observable<any> {
    return this.emailSubscriptionSettingsToggleVacancySubscriptionResponse(params).pipe(__map(_r => _r.body as any))
  }

  /**
   * @param isActive undefined
   */
  emailSubscriptionSettingsToggleAllManualVacancySubscriptionResponse(isActive: boolean): __Observable<__StrictHttpResponse<any>> {
    let __params = this.newParams()
    let __headers = new HttpHeaders()
    let __body: any = null

    let req = new HttpRequest<any>('PUT', this.rootUrl + `/account/jobsearch/email-settings/alert-subscriptions/all-manual/status/${isActive}`, __body, {
      headers: __headers,
      params: __params,
      responseType: 'json'
    })

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map(_r => {
        return _r as __StrictHttpResponse<any>
      })
    )
  }

  /**
   * @param isActive undefined
   */
  emailSubscriptionSettingsToggleAllManualVacancySubscription(isActive: boolean): __Observable<any> {
    return this.emailSubscriptionSettingsToggleAllManualVacancySubscriptionResponse(isActive).pipe(__map(_r => _r.body as any))
  }

  /**
   * @param isActive undefined
   */
  emailSubscriptionSettingsToggleAllAutomaticVacancySubscriptionResponse(isActive: boolean): __Observable<__StrictHttpResponse<any>> {
    let __params = this.newParams()
    let __headers = new HttpHeaders()
    let __body: any = null

    let req = new HttpRequest<any>('PUT', this.rootUrl + `/account/jobsearch/email-settings/alert-subscriptions/all-automatic/status/${isActive}`, __body, {
      headers: __headers,
      params: __params,
      responseType: 'json'
    })

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map(_r => {
        return _r as __StrictHttpResponse<any>
      })
    )
  }

  /**
   * @param isActive undefined
   */
  emailSubscriptionSettingsToggleAllAutomaticVacancySubscription(isActive: boolean): __Observable<any> {
    return this.emailSubscriptionSettingsToggleAllAutomaticVacancySubscriptionResponse(isActive).pipe(__map(_r => _r.body as any))
  }

  /**
   * @return OK
   */
  emailSubscriptionSettingsGetNotificationMailingSubscriptionsResponse(): __Observable<__StrictHttpResponse<Array<NotificationMailingSubscription>>> {
    let __params = this.newParams()
    let __headers = new HttpHeaders()
    let __body: any = null
    let req = new HttpRequest<any>('GET', this.rootUrl + `/account/jobsearch/email-settings/notification-subscriptions`, __body, {
      headers: __headers,
      params: __params,
      responseType: 'json'
    })

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map(_r => {
        return _r as __StrictHttpResponse<Array<NotificationMailingSubscription>>
      })
    )
  }

  /**
   * @return OK
   */
  emailSubscriptionSettingsGetNotificationMailingSubscriptions(): __Observable<Array<NotificationMailingSubscription>> {
    return this.emailSubscriptionSettingsGetNotificationMailingSubscriptionsResponse().pipe(__map(_r => _r.body as Array<NotificationMailingSubscription>))
  }

  /**
   * @param params The `AccountJobsearcherService.EmailSubscriptionSettingsChangeNotificationMailingSubscriptionParams` containing the following parameters:
   *
   * - `type`: notification content type
   *
   * - `isActive`:
   */
  emailSubscriptionSettingsChangeNotificationMailingSubscriptionResponse(
    params: AccountJobsearcherService.EmailSubscriptionSettingsChangeNotificationMailingSubscriptionParams
  ): __Observable<__StrictHttpResponse<any>> {
    let __params = this.newParams()
    let __headers = new HttpHeaders()
    let __body: any = null

    let req = new HttpRequest<any>('PUT', this.rootUrl + `/account/jobsearch/email-settings/notification-subscriptions/type/${params.type}/status/${params.isActive}`, __body, {
      headers: __headers,
      params: __params,
      responseType: 'json'
    })

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map(_r => {
        return _r as __StrictHttpResponse<any>
      })
    )
  }

  /**
   * @param params The `AccountJobsearcherService.EmailSubscriptionSettingsChangeNotificationMailingSubscriptionParams` containing the following parameters:
   *
   * - `type`: notification content type
   *
   * - `isActive`:
   */
  emailSubscriptionSettingsChangeNotificationMailingSubscription(params: AccountJobsearcherService.EmailSubscriptionSettingsChangeNotificationMailingSubscriptionParams): __Observable<any> {
    return this.emailSubscriptionSettingsChangeNotificationMailingSubscriptionResponse(params).pipe(__map(_r => _r.body as any))
  }

  /**
   * @return OK
   */
  emailSubscriptionSettingsGetMailingLanguageResponse(): __Observable<__StrictHttpResponse<'Russian' | 'Ukrainian'>> {
    let __params = this.newParams()
    let __headers = new HttpHeaders()
    let __body: any = null
    let req = new HttpRequest<any>('GET', this.rootUrl + `/account/jobsearch/email-settings/language`, __body, {
      headers: __headers,
      params: __params,
      responseType: 'json'
    })

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map(_r => {
        return _r as __StrictHttpResponse<'Russian' | 'Ukrainian'>
      })
    )
  }

  /**
   * @return OK
   */
  emailSubscriptionSettingsGetMailingLanguage(): __Observable<'Russian' | 'Ukrainian'> {
    return this.emailSubscriptionSettingsGetMailingLanguageResponse().pipe(__map(_r => _r.body as 'Russian' | 'Ukrainian'))
  }

  /**
   * @param language undefined
   */
  emailSubscriptionSettingsUpdateMailingLanguageResponse(language: 'Russian' | 'Ukrainian'): __Observable<__StrictHttpResponse<any>> {
    let __params = this.newParams()
    let __headers = new HttpHeaders()
    let __body: any = null

    let req = new HttpRequest<any>('PUT', this.rootUrl + `/account/jobsearch/email-settings/language/${language}`, __body, {
      headers: __headers,
      params: __params,
      responseType: 'json'
    })

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map(_r => {
        return _r as __StrictHttpResponse<any>
      })
    )
  }

  /**
   * @param language undefined
   */
  emailSubscriptionSettingsUpdateMailingLanguage(language: 'Russian' | 'Ukrainian'): __Observable<any> {
    return this.emailSubscriptionSettingsUpdateMailingLanguageResponse(language).pipe(__map(_r => _r.body as any))
  }

  /**
   * @return OK
   */
  jobsearcherAlertGetResponse(): __Observable<__StrictHttpResponse<Array<AlertInfo>>> {
    let __params = this.newParams()
    let __headers = new HttpHeaders()
    let __body: any = null
    let req = new HttpRequest<any>('GET', this.rootUrl + `/account/jobsearch/alert`, __body, {
      headers: __headers,
      params: __params,
      responseType: 'json'
    })

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map(_r => {
        return _r as __StrictHttpResponse<Array<AlertInfo>>
      })
    )
  }

  /**
   * @return OK
   */
  jobsearcherAlertGet(): __Observable<Array<AlertInfo>> {
    return this.jobsearcherAlertGetResponse().pipe(__map(_r => _r.body as Array<AlertInfo>))
  }

  /**
   * @param id
   * @return OK
   */
  jobsearcherAlertGet_1Response(id: number): __Observable<__StrictHttpResponse<Array<VacancyResultItem>>> {
    let __params = this.newParams()
    let __headers = new HttpHeaders()
    let __body: any = null

    let req = new HttpRequest<any>('GET', this.rootUrl + `/account/jobsearch/alert/${id}`, __body, {
      headers: __headers,
      params: __params,
      responseType: 'json'
    })

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map(_r => {
        return _r as __StrictHttpResponse<Array<VacancyResultItem>>
      })
    )
  }

  /**
   * @param id
   * @return OK
   */
  jobsearcherAlertGet_1(id: number): __Observable<Array<VacancyResultItem>> {
    return this.jobsearcherAlertGet_1Response(id).pipe(__map(_r => _r.body as Array<VacancyResultItem>))
  }

  /**
   * @param params The `AccountJobsearcherService.JobsearcherAlertAddAlertByCvParams` containing the following parameters:
   *
   * - `id`:
   *
   * - `email`:
   *
   * @return OK
   */
  jobsearcherAlertAddAlertByCvResponse(params: AccountJobsearcherService.JobsearcherAlertAddAlertByCvParams): __Observable<__StrictHttpResponse<number>> {
    let __params = this.newParams()
    let __headers = new HttpHeaders()
    let __body: any = null

    if (params.email != null) __params = __params.set('email', params.email.toString())
    let req = new HttpRequest<any>('POST', this.rootUrl + `/account/jobsearch/addalertbycv/${params.id}`, __body, {
      headers: __headers,
      params: __params,
      responseType: 'json'
    })

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map(_r => {
        return (_r as HttpResponse<any>).clone({
          body: parseFloat((_r as HttpResponse<any>).body as string)
        }) as __StrictHttpResponse<number>
      })
    )
  }

  /**
   * @param params The `AccountJobsearcherService.JobsearcherAlertAddAlertByCvParams` containing the following parameters:
   *
   * - `id`:
   *
   * - `email`:
   *
   * @return OK
   */
  jobsearcherAlertAddAlertByCv(params: AccountJobsearcherService.JobsearcherAlertAddAlertByCvParams): __Observable<number> {
    return this.jobsearcherAlertAddAlertByCvResponse(params).pipe(__map(_r => _r.body as number))
  }

  /**
   * @param params The `AccountJobsearcherService.JobsearcherAlertAddAlertByVacancyParams` containing the following parameters:
   *
   * - `id`:
   *
   * - `email`:
   *
   * @return OK
   */
  jobsearcherAlertAddAlertByVacancyResponse(params: AccountJobsearcherService.JobsearcherAlertAddAlertByVacancyParams): __Observable<__StrictHttpResponse<number>> {
    let __params = this.newParams()
    let __headers = new HttpHeaders()
    let __body: any = null

    if (params.email != null) __params = __params.set('email', params.email.toString())
    let req = new HttpRequest<any>('POST', this.rootUrl + `/account/jobsearch/addalertbyvacancy/${params.id}`, __body, {
      headers: __headers,
      params: __params,
      responseType: 'json'
    })

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map(_r => {
        return (_r as HttpResponse<any>).clone({
          body: parseFloat((_r as HttpResponse<any>).body as string)
        }) as __StrictHttpResponse<number>
      })
    )
  }

  /**
   * @param params The `AccountJobsearcherService.JobsearcherAlertAddAlertByVacancyParams` containing the following parameters:
   *
   * - `id`:
   *
   * - `email`:
   *
   * @return OK
   */
  jobsearcherAlertAddAlertByVacancy(params: AccountJobsearcherService.JobsearcherAlertAddAlertByVacancyParams): __Observable<number> {
    return this.jobsearcherAlertAddAlertByVacancyResponse(params).pipe(__map(_r => _r.body as number))
  }

  /**
   * @param params The `AccountJobsearcherService.JobsearcherAlertAddAlertByCompanyParams` containing the following parameters:
   *
   * - `rubricId`:
   *
   * - `id`:
   *
   * - `cityId`:
   *
   * - `email`:
   *
   * @return OK
   */
  jobsearcherAlertAddAlertByCompanyResponse(params: AccountJobsearcherService.JobsearcherAlertAddAlertByCompanyParams): __Observable<__StrictHttpResponse<{}>> {
    let __params = this.newParams()
    let __headers = new HttpHeaders()
    let __body: any = null
    if (params.rubricId != null) __params = __params.set('rubricId', params.rubricId.toString())

    if (params.cityId != null) __params = __params.set('cityId', params.cityId.toString())
    if (params.email != null) __params = __params.set('email', params.email.toString())
    let req = new HttpRequest<any>('POST', this.rootUrl + `/account/jobsearch/addalertbycompany/${params.id}`, __body, {
      headers: __headers,
      params: __params,
      responseType: 'json'
    })

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map(_r => {
        return _r as __StrictHttpResponse<{}>
      })
    )
  }

  /**
   * @param params The `AccountJobsearcherService.JobsearcherAlertAddAlertByCompanyParams` containing the following parameters:
   *
   * - `rubricId`:
   *
   * - `id`:
   *
   * - `cityId`:
   *
   * - `email`:
   *
   * @return OK
   */
  jobsearcherAlertAddAlertByCompany(params: AccountJobsearcherService.JobsearcherAlertAddAlertByCompanyParams): __Observable<{}> {
    return this.jobsearcherAlertAddAlertByCompanyResponse(params).pipe(__map(_r => _r.body as {}))
  }

  /**
   * @param id undefined
   */
  jobsearcherAlertSaveJobAlertActivityResponse(id: number): __Observable<__StrictHttpResponse<any>> {
    let __params = this.newParams()
    let __headers = new HttpHeaders()
    let __body: any = null

    let req = new HttpRequest<any>('POST', this.rootUrl + `/account/jobsearch/markactive/${id}`, __body, {
      headers: __headers,
      params: __params,
      responseType: 'json'
    })

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map(_r => {
        return _r as __StrictHttpResponse<any>
      })
    )
  }

  /**
   * @param id undefined
   */
  jobsearcherAlertSaveJobAlertActivity(id: number): __Observable<any> {
    return this.jobsearcherAlertSaveJobAlertActivityResponse(id).pipe(__map(_r => _r.body as any))
  }

  /**
   * @param params The `AccountJobsearcherService.JobsearcherAlertAddAlertParams` containing the following parameters:
   *
   * - `cityId`:
   *
   * - `rubricId`:
   *
   * - `keywords`:
   *
   * - `email`:
   *
   * @return OK
   */
  jobsearcherAlertAddAlertResponse(params: AccountJobsearcherService.JobsearcherAlertAddAlertParams): __Observable<__StrictHttpResponse<{}>> {
    let __params = this.newParams()
    let __headers = new HttpHeaders()
    let __body: any = null
    if (params.cityId != null) __params = __params.set('cityId', params.cityId.toString())
    if (params.rubricId != null) __params = __params.set('rubricId', params.rubricId.toString())
    if (params.keywords != null) __params = __params.set('keywords', params.keywords.toString())
    if (params.email != null) __params = __params.set('email', params.email.toString())
    let req = new HttpRequest<any>('POST', this.rootUrl + `/account/jobsearch/addalert`, __body, {
      headers: __headers,
      params: __params,
      responseType: 'json'
    })

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map(_r => {
        return _r as __StrictHttpResponse<{}>
      })
    )
  }

  /**
   * @param params The `AccountJobsearcherService.JobsearcherAlertAddAlertParams` containing the following parameters:
   *
   * - `cityId`:
   *
   * - `rubricId`:
   *
   * - `keywords`:
   *
   * - `email`:
   *
   * @return OK
   */
  jobsearcherAlertAddAlert(params: AccountJobsearcherService.JobsearcherAlertAddAlertParams): __Observable<{}> {
    return this.jobsearcherAlertAddAlertResponse(params).pipe(__map(_r => _r.body as {}))
  }

  /**
   * @param params The `AccountJobsearcherService.JobsearcherAlertDoesUserHasSubscriptionParams` containing the following parameters:
   *
   * - `cityId`:
   *
   * - `rubricId`:
   *
   * - `keywords`:
   *
   * - `email`:
   *
   * @return OK
   */
  jobsearcherAlertDoesUserHasSubscriptionResponse(params: AccountJobsearcherService.JobsearcherAlertDoesUserHasSubscriptionParams): __Observable<__StrictHttpResponse<{}>> {
    let __params = this.newParams()
    let __headers = new HttpHeaders()
    let __body: any = null
    if (params.cityId != null) __params = __params.set('cityId', params.cityId.toString())
    if (params.rubricId != null) __params = __params.set('rubricId', params.rubricId.toString())
    if (params.keywords != null) __params = __params.set('keywords', params.keywords.toString())
    if (params.email != null) __params = __params.set('email', params.email.toString())
    let req = new HttpRequest<any>('GET', this.rootUrl + `/account/jobsearch/alert-subscription`, __body, {
      headers: __headers,
      params: __params,
      responseType: 'json'
    })

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map(_r => {
        return _r as __StrictHttpResponse<{}>
      })
    )
  }

  /**
   * @param params The `AccountJobsearcherService.JobsearcherAlertDoesUserHasSubscriptionParams` containing the following parameters:
   *
   * - `cityId`:
   *
   * - `rubricId`:
   *
   * - `keywords`:
   *
   * - `email`:
   *
   * @return OK
   */
  jobsearcherAlertDoesUserHasSubscription(params: AccountJobsearcherService.JobsearcherAlertDoesUserHasSubscriptionParams): __Observable<{}> {
    return this.jobsearcherAlertDoesUserHasSubscriptionResponse(params).pipe(__map(_r => _r.body as {}))
  }

  /**
   * @param alertUpdateInfo undefined
   * @return OK
   */
  jobsearcherAlertUpdateSubscriptionResponse(alertUpdateInfo: AlertUpdateInfo): __Observable<__StrictHttpResponse<{}>> {
    let __params = this.newParams()
    let __headers = new HttpHeaders()
    let __body: any = null
    __body = alertUpdateInfo
    let req = new HttpRequest<any>('PUT', this.rootUrl + `/account/jobsearch/alert-subscription`, __body, {
      headers: __headers,
      params: __params,
      responseType: 'json'
    })

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map(_r => {
        return _r as __StrictHttpResponse<{}>
      })
    )
  }

  /**
   * @param alertUpdateInfo undefined
   * @return OK
   */
  jobsearcherAlertUpdateSubscription(alertUpdateInfo: AlertUpdateInfo): __Observable<{}> {
    return this.jobsearcherAlertUpdateSubscriptionResponse(alertUpdateInfo).pipe(__map(_r => _r.body as {}))
  }

  /**
   * @param params The `AccountJobsearcherService.JobsearcherAlertUnsubscribeUserParams` containing the following parameters:
   *
   * - `keywords`:
   *
   * - `cityId`:
   *
   * - `email`:
   *
   * @return OK
   */
  jobsearcherAlertUnsubscribeUserResponse(params: AccountJobsearcherService.JobsearcherAlertUnsubscribeUserParams): __Observable<__StrictHttpResponse<{}>> {
    let __params = this.newParams()
    let __headers = new HttpHeaders()
    let __body: any = null
    if (params.keywords != null) __params = __params.set('keywords', params.keywords.toString())
    if (params.cityId != null) __params = __params.set('cityId', params.cityId.toString())
    if (params.email != null) __params = __params.set('email', params.email.toString())
    let req = new HttpRequest<any>('DELETE', this.rootUrl + `/account/jobsearch/alert-subscription`, __body, {
      headers: __headers,
      params: __params,
      responseType: 'json'
    })

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map(_r => {
        return _r as __StrictHttpResponse<{}>
      })
    )
  }

  /**
   * @param params The `AccountJobsearcherService.JobsearcherAlertUnsubscribeUserParams` containing the following parameters:
   *
   * - `keywords`:
   *
   * - `cityId`:
   *
   * - `email`:
   *
   * @return OK
   */
  jobsearcherAlertUnsubscribeUser(params: AccountJobsearcherService.JobsearcherAlertUnsubscribeUserParams): __Observable<{}> {
    return this.jobsearcherAlertUnsubscribeUserResponse(params).pipe(__map(_r => _r.body as {}))
  }

  /**
   * @param id undefined
   * @return OK
   */
  jobsearcherAlertGetSubscriptionByIdResponse(id: number): __Observable<__StrictHttpResponse<SubscribeCompetitorInfo>> {
    let __params = this.newParams()
    let __headers = new HttpHeaders()
    let __body: any = null

    let req = new HttpRequest<any>('GET', this.rootUrl + `/account/jobsearch/alert-subscription/${id}`, __body, {
      headers: __headers,
      params: __params,
      responseType: 'json'
    })

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map(_r => {
        return _r as __StrictHttpResponse<SubscribeCompetitorInfo>
      })
    )
  }

  /**
   * @param id undefined
   * @return OK
   */
  jobsearcherAlertGetSubscriptionById(id: number): __Observable<SubscribeCompetitorInfo> {
    return this.jobsearcherAlertGetSubscriptionByIdResponse(id).pipe(__map(_r => _r.body as SubscribeCompetitorInfo))
  }

  /**
   * @param id undefined
   * @return OK
   */
  jobsearcherAlertUnsubscribeUserBySubscriptionIdResponse(id: number): __Observable<__StrictHttpResponse<{}>> {
    let __params = this.newParams()
    let __headers = new HttpHeaders()
    let __body: any = null

    let req = new HttpRequest<any>('DELETE', this.rootUrl + `/account/jobsearch/alert-subscription/${id}`, __body, {
      headers: __headers,
      params: __params,
      responseType: 'json'
    })

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map(_r => {
        return _r as __StrictHttpResponse<{}>
      })
    )
  }

  /**
   * @param id undefined
   * @return OK
   */
  jobsearcherAlertUnsubscribeUserBySubscriptionId(id: number): __Observable<{}> {
    return this.jobsearcherAlertUnsubscribeUserBySubscriptionIdResponse(id).pipe(__map(_r => _r.body as {}))
  }

  /**
   * @param params The `AccountJobsearcherService.JobsearcherAlertRedirectToSearchBySubscriptionIdParams` containing the following parameters:
   *
   * - `id`:
   *
   * - `page`:
   *
   * - `count`:
   *
   * @return OK
   */
  jobsearcherAlertRedirectToSearchBySubscriptionIdResponse(
    params: AccountJobsearcherService.JobsearcherAlertRedirectToSearchBySubscriptionIdParams
  ): __Observable<__StrictHttpResponse<VacancySearchUserResponse>> {
    let __params = this.newParams()
    let __headers = new HttpHeaders()
    let __body: any = null

    if (params.page != null) __params = __params.set('page', params.page.toString())
    if (params.count != null) __params = __params.set('count', params.count.toString())
    let req = new HttpRequest<any>('GET', this.rootUrl + `/account/jobsearch/alert-subscription-vacancies/${params.id}`, __body, {
      headers: __headers,
      params: __params,
      responseType: 'json'
    })

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map(_r => {
        return _r as __StrictHttpResponse<VacancySearchUserResponse>
      })
    )
  }

  /**
   * @param params The `AccountJobsearcherService.JobsearcherAlertRedirectToSearchBySubscriptionIdParams` containing the following parameters:
   *
   * - `id`:
   *
   * - `page`:
   *
   * - `count`:
   *
   * @return OK
   */
  jobsearcherAlertRedirectToSearchBySubscriptionId(params: AccountJobsearcherService.JobsearcherAlertRedirectToSearchBySubscriptionIdParams): __Observable<VacancySearchUserResponse> {
    return this.jobsearcherAlertRedirectToSearchBySubscriptionIdResponse(params).pipe(__map(_r => _r.body as VacancySearchUserResponse))
  }

  /**
   * @param params The `AccountJobsearcherService.JobsearcherAlertDoesUserHasSubscriptionOnCompanyParams` containing the following parameters:
   *
   * - `rubricId`:
   *
   * - `companyId`:
   *
   * - `cityId`:
   *
   * - `email`:
   *
   * @return OK
   */
  jobsearcherAlertDoesUserHasSubscriptionOnCompanyResponse(params: AccountJobsearcherService.JobsearcherAlertDoesUserHasSubscriptionOnCompanyParams): __Observable<__StrictHttpResponse<{}>> {
    let __params = this.newParams()
    let __headers = new HttpHeaders()
    let __body: any = null
    if (params.rubricId != null) __params = __params.set('rubricId', params.rubricId.toString())
    if (params.companyId != null) __params = __params.set('companyId', params.companyId.toString())
    if (params.cityId != null) __params = __params.set('cityId', params.cityId.toString())
    if (params.email != null) __params = __params.set('email', params.email.toString())
    let req = new HttpRequest<any>('GET', this.rootUrl + `/account/jobsearch/alert-subscription/company`, __body, {
      headers: __headers,
      params: __params,
      responseType: 'json'
    })

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map(_r => {
        return _r as __StrictHttpResponse<{}>
      })
    )
  }

  /**
   * @param params The `AccountJobsearcherService.JobsearcherAlertDoesUserHasSubscriptionOnCompanyParams` containing the following parameters:
   *
   * - `rubricId`:
   *
   * - `companyId`:
   *
   * - `cityId`:
   *
   * - `email`:
   *
   * @return OK
   */
  jobsearcherAlertDoesUserHasSubscriptionOnCompany(params: AccountJobsearcherService.JobsearcherAlertDoesUserHasSubscriptionOnCompanyParams): __Observable<{}> {
    return this.jobsearcherAlertDoesUserHasSubscriptionOnCompanyResponse(params).pipe(__map(_r => _r.body as {}))
  }
}

module AccountJobsearcherService {
  /**
   * Parameters for VacancyQuestionsAskQuestion
   */
  export interface VacancyQuestionsAskQuestionParams {
    vacancyId: number
    question: VacancyQuestion
    cid: string
  }

  /**
   * Parameters for VacancyJobsearcherFavorites
   */
  export interface VacancyJobsearcherFavoritesParams {
    start?: number
    count?: number
  }

  /**
   * Parameters for VacancyJobsearcherLike
   */
  export interface VacancyJobsearcherLikeParams {
    like: boolean
    id: number
  }

  /**
   * Parameters for VacancyJobsearcherGetRecommended
   */
  export interface VacancyJobsearcherGetRecommendedParams {
    /**
     * From which vacancy number return recommendation list
     */
    start?: number

    /**
     * User id (if not specified, than current user id is taken).
     * Based on this, City and KeyWords are taken for search.
     */
    guid?: string

    /**
     * How many vacancies to take from the top of the recommendation result list
     */
    count?: number
  }

  /**
   * Parameters for VacancyJobsearcherRecommendedByResume
   */
  export interface VacancyJobsearcherRecommendedByResumeParams {
    position: string
    cityId: number
    start?: number
    count?: number
  }

  /**
   * Parameters for VacancyJobsearcherApplicationHistory
   */
  export interface VacancyJobsearcherApplicationHistoryParams {
    start?: number
    count?: number
  }

  /**
   * Parameters for VacancyJobsearcherApplyDetails
   */
  export interface VacancyJobsearcherApplyDetailsParams {
    resumeType: 'AttachCv' | 'ProfCv'
    applyId: number
  }

  /**
   * Parameters for VacancyJobsearcherCvView
   */
  export interface VacancyJobsearcherCvViewParams {
    start?: number
    cvId?: number
    count?: number
  }

  /**
   * Parameters for VacancyJobsearcherApplicationStatus
   */
  export interface VacancyJobsearcherApplicationStatusParams {
    attach: boolean
    applyId: number
  }

  /**
   * Parameters for VacancyJobsearcherVacancyPool
   */
  export interface VacancyJobsearcherVacancyPoolParams {
    pzId: number
    cityId: number
    vacancyId?: number
  }

  /**
   * Parameters for ApplyLogApply
   */
  export interface ApplyLogApplyParams {
    vacancyId: number
    email: string
  }

  /**
   * Parameters for EmailSubscriptionSettingsToggleVacancySubscription
   */
  export interface EmailSubscriptionSettingsToggleVacancySubscriptionParams {
    isActive: boolean
    id: number
  }

  /**
   * Parameters for EmailSubscriptionSettingsChangeNotificationMailingSubscription
   */
  export interface EmailSubscriptionSettingsChangeNotificationMailingSubscriptionParams {
    /**
     * notification content type
     */
    type:
      | 'BestArticlesFromBlogRabotaUa'
      | 'DigestFromBlogRabotaUa'
      | 'LettersFromPartners'
      | 'NewsInMyProfession'
      | 'ResumeViewNotification'
      | 'ResumeViewsStatistics'
      | 'WeeklyRecommendedVacanciesDigest'
      | 'BestPropositionsOnMarket'
      | 'VacanciesThatAreGoingToBeClosed'
      | 'RelevanceOfViewedVacancies'
      | 'SelectedVacanciesWithoutApply'
      | 'ClosingVacanciesWithYourApplies'
    isActive: boolean
  }

  /**
   * Parameters for JobsearcherAlertAddAlertByCv
   */
  export interface JobsearcherAlertAddAlertByCvParams {
    id: number
    email?: string
  }

  /**
   * Parameters for JobsearcherAlertAddAlertByVacancy
   */
  export interface JobsearcherAlertAddAlertByVacancyParams {
    id: number
    email?: string
  }

  /**
   * Parameters for JobsearcherAlertAddAlertByCompany
   */
  export interface JobsearcherAlertAddAlertByCompanyParams {
    rubricId: number
    id: number
    cityId: number
    email?: string
  }

  /**
   * Parameters for JobsearcherAlertAddAlert
   */
  export interface JobsearcherAlertAddAlertParams {
    cityId: number
    rubricId?: number
    keywords?: string
    email?: string
  }

  /**
   * Parameters for JobsearcherAlertDoesUserHasSubscription
   */
  export interface JobsearcherAlertDoesUserHasSubscriptionParams {
    cityId: number
    rubricId?: number
    keywords?: string
    email?: string
  }

  /**
   * Parameters for JobsearcherAlertUnsubscribeUser
   */
  export interface JobsearcherAlertUnsubscribeUserParams {
    keywords: string
    cityId: number
    email?: string
  }

  /**
   * Parameters for JobsearcherAlertRedirectToSearchBySubscriptionId
   */
  export interface JobsearcherAlertRedirectToSearchBySubscriptionIdParams {
    id: number
    page?: number
    count?: number
  }

  /**
   * Parameters for JobsearcherAlertDoesUserHasSubscriptionOnCompany
   */
  export interface JobsearcherAlertDoesUserHasSubscriptionOnCompanyParams {
    rubricId: number
    companyId: number
    cityId: number
    email?: string
  }
}

export { AccountJobsearcherService }
