import { CityInfo, ValuesService } from '@alliance/employer/data-access'
import { Injectable } from '@angular/core'
import { Observable, of } from 'rxjs'
import { catchError, map, shareReplay } from 'rxjs/operators'

@Injectable({
  providedIn: 'root'
})
export class LocationService {
  public cityList$: Observable<CityInfo[]>

  public constructor(private valuesService: ValuesService) {
    this.cityList$ = this.valuesService.cityList().pipe(
      shareReplay(1),
      map((cityList: CityInfo[]) =>
        cityList.sort((a: CityInfo, b: CityInfo) => {
          if (a?.name && b?.name && a.name < b.name) {
            return -1
          }

          if (a?.name && b?.name && a.name > b.name) {
            return 1
          }

          return 0
        })
      ),
      catchError(() => of([]))
    )
  }

  public getCityById(id: number): Observable<CityInfo | null> {
    return this.cityList$.pipe(map(cities => cities?.find(city => city.id === id) ?? null))
  }
}
