import { Injectable } from '@angular/core'
import { AnalyticsService as AnalyticsServiceDomain, GtmEvent } from '@alliance/employer/domain'

@Injectable({
  providedIn: 'root'
})
export class AnalyticsService {
  public constructor(private analyticsService: AnalyticsServiceDomain) {}

  public sendNextGtmEvent(gtmEvent: GtmEvent): void {
    this.analyticsService.sendNextGtmEvent(gtmEvent)
  }
}
