import { Injectable } from '@angular/core'
import { ApolloLink } from '@apollo/client/core'
import { onError } from '@apollo/client/link/error'
import { BaseRestrictedByRightErrorMiddlewareService } from './base-restricted-by-right-error-middleware.service'

@Injectable()
export class GqlRestrictedByRightErrorService extends BaseRestrictedByRightErrorMiddlewareService {
  public onRestrictedByRightError(clientName: string): ApolloLink {
    return onError(({ graphQLErrors, operation }) => {
      if (this.platform.isServer || !graphQLErrors?.length) {
        return
      }

      const operationContext = operation.getContext()
      const clientNameInContext = this.isContextWithClientName(operationContext) ? operationContext.clientName : null

      if (clientName !== clientNameInContext) {
        return
      }

      const restrictedByRightError = graphQLErrors.find(error => this.isValidGraphqlErrorExtensions(error?.extensions) && error.extensions.data?.globalExceptionName === this.GLOBAL_EXCEPTION_NAME)

      if (restrictedByRightError) {
        this.notifyRestrictedByRightError()
      }
    })
  }

  private isContextWithClientName(context: Record<string, unknown>): context is { clientName: string } {
    return 'clientName' in context
  }
}
