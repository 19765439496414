import { commit } from '@alliance/shared/utils'
import { Environment } from '@alliance/shared/environment'
import { Injectable } from '@angular/core'
import { ApolloClientOptions } from '@apollo/client/core'

@Injectable({
  providedIn: 'root'
})
export class GetApolloClientMetricsService {
  public constructor(private environment: Environment) {}

  public get apolloClientMetrics(): Required<Pick<ApolloClientOptions<unknown>, 'name' | 'version'>> {
    return {
      name: `web-alliance-${this.environment.app ?? 'unknown-app'}`,
      version: commit
    }
  }
}
