import { Injectable } from '@angular/core'
import { Observable, catchError, map, of } from 'rxjs'

import { getNonNullableItems } from '@alliance/shared/utils'

import { GetVacancyHistoryGQL, VacancyHistoryFragment } from './vacancy-history.generated'
import { VacancyHistoryParams } from '../../../models/vacancy-actions/show-history/vacancy-history-params.interface'
import { VacancyHistoryResponse } from '../../../models/vacancy-actions/show-history/vacancy-history-response.interface'

@Injectable()
export class VacancyHistoryService {
  public constructor(private readonly getVacancyHistoryGQL: GetVacancyHistoryGQL) {}

  public getVacancyHistory$(params: VacancyHistoryParams): Observable<VacancyHistoryResponse | null> {
    return this.getVacancyHistoryGQL.fetch({ id: params.vacancy.id }, { fetchPolicy: 'network-only' }).pipe(
      map(({ data }) => {
        const { vacancy, vacancyChangeHistory } = data || {}

        return {
          title: vacancy?.title ?? null,
          cityName: vacancy?.city?.name ?? null,
          history: getNonNullableItems<VacancyHistoryFragment>(vacancyChangeHistory || [])
        }
      }),
      catchError(() => of(null))
    )
  }
}
