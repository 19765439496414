import { VACANCY_RECOMMENDED_CANDIDATES_UPDATED_MESSAGE_TOKEN, VacancyRecommendedCandidatesUpdatedMessage } from '@alliance/employer/vacancies/api'
import { ActionStatusMessageMetaType, ActionTypesEnum, channelTypes, NotificationService, NotificationTypeEnum } from '@alliance/notification/api'
import { RxStateService } from '@alliance/shared/models'
import { SnackbarService } from '@alliance/shared/santa'
import { TranslationService } from '@alliance/shared/translation'
import { MediaService } from '@alliance/shared/utils'
import { Inject, Injectable, Injector } from '@angular/core'
import { Observable } from 'rxjs'
import { filter } from 'rxjs/operators'
import { Translations } from './localization/translations'

@Injectable({ providedIn: 'root' })
export class EmployerVacancyRecommendedCandidatesUpdatedService extends RxStateService<object> {
  public constructor(
    @Inject(VACANCY_RECOMMENDED_CANDIDATES_UPDATED_MESSAGE_TOKEN) private readonly candidatesUpdatedMessage$: Observable<VacancyRecommendedCandidatesUpdatedMessage | null>,
    private readonly injector: Injector,
    private readonly mediaService: MediaService,
    private readonly translations: Translations,
    private readonly translationService: TranslationService
  ) {
    super()
  }

  public subscribeToUpdated(): void {
    this.hold(this.candidatesUpdatedMessage$.pipe(filter(Boolean)), message => {
      const { newCount } = message

      const text = newCount ? this.translationService.translate(this.translations.new, { count: newCount }) : this.translationService.translate(this.translations.notFound)

      this.mediaService.isMobileScreen()
        ? this.injector.get(SnackbarService).openByComponent(null, { value: text })
        : this.injector.get(NotificationService).dispatch<ActionStatusMessageMetaType>({
            type: NotificationTypeEnum.CREATED,
            payload: {
              channel: channelTypes.USER_ACTIONS,
              body: text,
              meta: { status: newCount ? ActionTypesEnum.SUCCESS : ActionTypesEnum.ERROR }
            }
          })
    })
  }
}
