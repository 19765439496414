import { ActivatedRoute, ActivatedRouteSnapshot } from '@angular/router'
import { FileExtension, FileExtensionEnum } from './models/fileExtention'

export class StaticUtilsService {
  public static stringifyBasePath(activatedRoute: ActivatedRoute): string {
    const segmentsArray: string[] = []
    const separator = '/'
    const parse = (urlSegment: ActivatedRoute | null): string[] => {
      if (urlSegment) {
        segmentsArray.unshift(...urlSegment.snapshot.url.map(segment => segment.path))
        return urlSegment.parent ? parse(urlSegment.parent) : segmentsArray
      } else {
        return []
      }
    }
    return separator + parse(activatedRoute.parent).join(separator)
  }

  public static stringifyBasePathBySnapshot(activatedRouteSnapshot: ActivatedRouteSnapshot): string {
    const segmentsArray: string[] = []
    const separator = '/'
    const parse = (urlSegment: ActivatedRouteSnapshot | null): string[] => {
      if (urlSegment) {
        segmentsArray.unshift(...urlSegment.url.map(segment => segment.path))
        return urlSegment.parent ? parse(urlSegment.parent) : segmentsArray
      } else {
        return []
      }
    }
    return separator + parse(activatedRouteSnapshot.parent).join(separator)
  }

  public static getFileExtension(contentType: string): FileExtension {
    const docxTypes = ['document']
    const docTypes = ['msword']
    const txtTypes = ['plain']

    const raw =
      contentType
        .split('/')
        ?.pop()
        ?.split('.')
        ?.pop() || ''

    if (docxTypes.indexOf(raw) > -1) {
      return FileExtensionEnum.docx
    } else if (txtTypes.indexOf(raw) > -1) {
      return FileExtensionEnum.txt
    } else if (docTypes.indexOf(raw) > -1) {
      return FileExtensionEnum.doc
    } else {
      return raw as FileExtension
    }
  }
}
