import { Injectable } from '@angular/core'
import { Observable, catchError, map, of } from 'rxjs'

import { RejectVacancyGQL } from './reject-vacancy.generated'
import { RejectVacancyResponse } from '../../../models/vacancy-actions/reject-vacancy/reject-vacancy-response.interface'
import { VacancyRejectPublicationParams } from '../../../models/vacancy-actions/reject-vacancy/reject-vacancy-params.interface'

@Injectable()
export class RejectVacancyService {
  public constructor(private readonly rejectVacancyGQL: RejectVacancyGQL) {}

  public rejectVacancy$(params: VacancyRejectPublicationParams): Observable<RejectVacancyResponse | null> {
    return this.rejectVacancyGQL
      .mutate({
        input: {
          vacancyId: params.vacancyId,
          reason: params.reason
        }
      })
      .pipe(
        map(({ data }) => {
          const vacancy = data?.vacancyRejectPublication?.vacancy
          if (!vacancy) {
            return null
          }
          return { vacancyId: vacancy.id }
        }),
        catchError(() => of(null))
      )
  }
}
