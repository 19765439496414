import { Injectable } from '@angular/core'
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http'
import { BaseService as __BaseService } from '../base-service'
import { EmployerDataAccessConfiguration as __Configuration } from '../employer-data-access-configuration'
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response'
import { Observable as __Observable } from 'rxjs'
import { map as __map, filter as __filter } from 'rxjs/operators'

@Injectable({
  providedIn: 'root'
})
class VacancyImageService extends __BaseService {
  public static readonly saveFilePath = '/vacancyimage/savefile'

  public constructor(config: __Configuration, http: HttpClient) {
    super(config, http)
  }

  /**
   * @param file The file to upload.
   */
  saveFileResponse(file: Blob): __Observable<__StrictHttpResponse<any>> {
    let __params = this.newParams()
    let __headers = new HttpHeaders()
    let __body: any = null
    let __formData = new FormData()
    __body = __formData
    if (file != null) {
      __formData.append('file', file as string | Blob)
    }
    let req = new HttpRequest<any>('POST', this.rootUrl + `/vacancyimage/savefile`, __body, {
      headers: __headers,
      params: __params,
      responseType: 'json'
    })

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map(_r => {
        return _r as __StrictHttpResponse<any>
      })
    )
  }

  /**
   * @param file The file to upload.
   */
  saveFile(file: Blob): __Observable<any> {
    return this.saveFileResponse(file).pipe(__map(_r => _r.body as any))
  }
}

module VacancyImageService {}

export { VacancyImageService }
