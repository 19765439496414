<a class="santa-no-underline" *ngIf="externalLink" [href]="externalLink" [target]="target">
  <ng-container *ngTemplateOutlet="content"></ng-container>
</a>

<a class="santa-no-underline" *ngIf="internalLink" [routerLink]="internalLink" [queryParams]="queryParams" [state]="state" [target]="target">
  <ng-container *ngTemplateOutlet="content"></ng-container>
</a>
<ng-container *ngIf="!internalLink && !externalLink">
  <ng-container *ngTemplateOutlet="content"></ng-container>
</ng-container>

<ng-template #content><ng-content></ng-content></ng-template>
