import { Injectable } from '@angular/core'
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http'
import { BaseService as __BaseService } from '../base-service'
import { EmployerDataAccessConfiguration as __Configuration } from '../employer-data-access-configuration'
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response'
import { Observable as __Observable } from 'rxjs'
import { map as __map, filter as __filter } from 'rxjs/operators'

import { RubricInfo } from '../models/rubric-info'
import { SubRubricInfo } from '../models/sub-rubric-info'
import { CityInfo } from '../models/city-info'
import { LanguageInfo } from '../models/language-info'
import { SkillInfo } from '../models/skill-info'
import { LocalizableDataInfo } from '../models/localizable-data-info'
import { BlockingReasonInfo } from '../models/blocking-reason-info'
import { Oblast } from '../models/oblast'
@Injectable({
  providedIn: 'root'
})
class ValuesService extends __BaseService {
  public static readonly getPath = '/values/resume/type'
  public static readonly getResumeFolderPath = '/values/resume/folder'
  public static readonly getVacancyStatePath = '/values/vacancy/state'
  public static readonly getVacancyEndingTypePath = '/values/vacancy/endingtype'
  public static readonly employerMessageLettersPath = '/values/employermessagetemplates'
  public static readonly cvDbTicketTypePath = '/values/cvdbtickettype'
  public static readonly rubricListPath = '/values/rubrics'
  public static readonly subRubricListPath = '/values/subrubrics'
  public static readonly cityListPath = '/values/citylist'
  public static readonly positionListPath = '/values/position'
  public static readonly languageListPath = '/values/languagelist'
  public static readonly languageSkillListPath = '/values/languageskilllist'
  public static readonly publicationTypePath = '/values/vacancy/publicationtype'
  public static readonly sendResumeTypePath = '/values/vacancy/sendresumetype'
  public static readonly importancePath = '/values/controlquestion/importance'
  public static readonly blockingReasonPath = '/values/blockingreason'
  public static readonly pricePeriodPath = '/values/priceperiod'
  public static readonly platformPath = '/values/platform'
  public static readonly detailServiceTypePath = '/values/servicetype'
  public static readonly oblastPath = '/values/oblastlist'
  public static readonly profileBenefitsPath = '/values/profilebenefits'
  public static readonly vacancyBadgeSuggestionsPath = '/values/vacancy-badge-suggestions'
  public static readonly cvSearchExperiencePath = '/values/cv-search-experience'
  public static readonly synonymousCheckAsyncPath = '/values/synonymous-check/{keywords}'
  public static readonly synonymousSimilarPath = '/values/similar-synonymous/{keywords}'
  public static readonly randomSynonymousPath = '/values/random-synonymous'

  public constructor(config: __Configuration, http: HttpClient) {
    super(config, http)
  }
  getResponse(): __Observable<__StrictHttpResponse<any>> {
    let __params = this.newParams()
    let __headers = new HttpHeaders()
    let __body: any = null
    let req = new HttpRequest<any>('GET', this.rootUrl + `/values/resume/type`, __body, {
      headers: __headers,
      params: __params,
      responseType: 'json'
    })

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map(_r => {
        return _r as __StrictHttpResponse<any>
      })
    )
  }
  get(): __Observable<any> {
    return this.getResponse().pipe(__map(_r => _r.body as any))
  }
  getResumeFolderResponse(): __Observable<__StrictHttpResponse<any>> {
    let __params = this.newParams()
    let __headers = new HttpHeaders()
    let __body: any = null
    let req = new HttpRequest<any>('GET', this.rootUrl + `/values/resume/folder`, __body, {
      headers: __headers,
      params: __params,
      responseType: 'json'
    })

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map(_r => {
        return _r as __StrictHttpResponse<any>
      })
    )
  }
  getResumeFolder(): __Observable<any> {
    return this.getResumeFolderResponse().pipe(__map(_r => _r.body as any))
  }
  getVacancyStateResponse(): __Observable<__StrictHttpResponse<any>> {
    let __params = this.newParams()
    let __headers = new HttpHeaders()
    let __body: any = null
    let req = new HttpRequest<any>('GET', this.rootUrl + `/values/vacancy/state`, __body, {
      headers: __headers,
      params: __params,
      responseType: 'json'
    })

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map(_r => {
        return _r as __StrictHttpResponse<any>
      })
    )
  }
  getVacancyState(): __Observable<any> {
    return this.getVacancyStateResponse().pipe(__map(_r => _r.body as any))
  }
  getVacancyEndingTypeResponse(): __Observable<__StrictHttpResponse<any>> {
    let __params = this.newParams()
    let __headers = new HttpHeaders()
    let __body: any = null
    let req = new HttpRequest<any>('GET', this.rootUrl + `/values/vacancy/endingtype`, __body, {
      headers: __headers,
      params: __params,
      responseType: 'json'
    })

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map(_r => {
        return _r as __StrictHttpResponse<any>
      })
    )
  }
  getVacancyEndingType(): __Observable<any> {
    return this.getVacancyEndingTypeResponse().pipe(__map(_r => _r.body as any))
  }
  employerMessageLettersResponse(): __Observable<__StrictHttpResponse<any>> {
    let __params = this.newParams()
    let __headers = new HttpHeaders()
    let __body: any = null
    let req = new HttpRequest<any>('GET', this.rootUrl + `/values/employermessagetemplates`, __body, {
      headers: __headers,
      params: __params,
      responseType: 'json'
    })

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map(_r => {
        return _r as __StrictHttpResponse<any>
      })
    )
  }
  employerMessageLetters(): __Observable<any> {
    return this.employerMessageLettersResponse().pipe(__map(_r => _r.body as any))
  }
  cvDbTicketTypeResponse(): __Observable<__StrictHttpResponse<any>> {
    let __params = this.newParams()
    let __headers = new HttpHeaders()
    let __body: any = null
    let req = new HttpRequest<any>('GET', this.rootUrl + `/values/cvdbtickettype`, __body, {
      headers: __headers,
      params: __params,
      responseType: 'json'
    })

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map(_r => {
        return _r as __StrictHttpResponse<any>
      })
    )
  }
  cvDbTicketType(): __Observable<any> {
    return this.cvDbTicketTypeResponse().pipe(__map(_r => _r.body as any))
  }

  /**
   * @return Success
   */
  rubricListResponse(): __Observable<__StrictHttpResponse<Array<RubricInfo>>> {
    let __params = this.newParams()
    let __headers = new HttpHeaders()
    let __body: any = null
    let req = new HttpRequest<any>('GET', this.rootUrl + `/values/rubrics`, __body, {
      headers: __headers,
      params: __params,
      responseType: 'json'
    })

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map(_r => {
        return _r as __StrictHttpResponse<Array<RubricInfo>>
      })
    )
  }

  /**
   * @return Success
   */
  rubricList(): __Observable<Array<RubricInfo>> {
    return this.rubricListResponse().pipe(__map(_r => _r.body as Array<RubricInfo>))
  }

  /**
   * @return Success
   */
  subRubricListResponse(): __Observable<__StrictHttpResponse<Array<SubRubricInfo>>> {
    let __params = this.newParams()
    let __headers = new HttpHeaders()
    let __body: any = null
    let req = new HttpRequest<any>('GET', this.rootUrl + `/values/subrubrics`, __body, {
      headers: __headers,
      params: __params,
      responseType: 'json'
    })

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map(_r => {
        return _r as __StrictHttpResponse<Array<SubRubricInfo>>
      })
    )
  }

  /**
   * @return Success
   */
  subRubricList(): __Observable<Array<SubRubricInfo>> {
    return this.subRubricListResponse().pipe(__map(_r => _r.body as Array<SubRubricInfo>))
  }

  /**
   * @return Success
   */
  cityListResponse(): __Observable<__StrictHttpResponse<Array<CityInfo>>> {
    let __params = this.newParams()
    let __headers = new HttpHeaders()
    let __body: any = null
    let req = new HttpRequest<any>('GET', this.rootUrl + `/values/citylist`, __body, {
      headers: __headers,
      params: __params,
      responseType: 'json'
    })

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map(_r => {
        return _r as __StrictHttpResponse<Array<CityInfo>>
      })
    )
  }

  /**
   * @return Success
   */
  cityList(): __Observable<Array<CityInfo>> {
    return this.cityListResponse().pipe(__map(_r => _r.body as Array<CityInfo>))
  }

  /**
   * @param params The `ValuesService.PositionListParams` containing the following parameters:
   *
   * - `term`:
   *
   * - `language`:
   *
   * @return Success
   */
  positionListResponse(params: ValuesService.PositionListParams): __Observable<__StrictHttpResponse<Array<string>>> {
    let __params = this.newParams()
    let __headers = new HttpHeaders()
    let __body: any = null
    if (params.term != null) __params = __params.set('term', params.term.toString())
    if (params.language != null) __params = __params.set('language', params.language.toString())
    let req = new HttpRequest<any>('GET', this.rootUrl + `/values/position`, __body, {
      headers: __headers,
      params: __params,
      responseType: 'json'
    })

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map(_r => {
        return _r as __StrictHttpResponse<Array<string>>
      })
    )
  }

  /**
   * @param params The `ValuesService.PositionListParams` containing the following parameters:
   *
   * - `term`:
   *
   * - `language`:
   *
   * @return Success
   */
  positionList(params: ValuesService.PositionListParams): __Observable<Array<string>> {
    return this.positionListResponse(params).pipe(__map(_r => _r.body as Array<string>))
  }

  /**
   * @return Success
   */
  languageListResponse(): __Observable<__StrictHttpResponse<Array<LanguageInfo>>> {
    let __params = this.newParams()
    let __headers = new HttpHeaders()
    let __body: any = null
    let req = new HttpRequest<any>('GET', this.rootUrl + `/values/languagelist`, __body, {
      headers: __headers,
      params: __params,
      responseType: 'json'
    })

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map(_r => {
        return _r as __StrictHttpResponse<Array<LanguageInfo>>
      })
    )
  }

  /**
   * @return Success
   */
  languageList(): __Observable<Array<LanguageInfo>> {
    return this.languageListResponse().pipe(__map(_r => _r.body as Array<LanguageInfo>))
  }

  /**
   * @return Success
   */
  languageSkillListResponse(): __Observable<__StrictHttpResponse<Array<SkillInfo>>> {
    let __params = this.newParams()
    let __headers = new HttpHeaders()
    let __body: any = null
    let req = new HttpRequest<any>('GET', this.rootUrl + `/values/languageskilllist`, __body, {
      headers: __headers,
      params: __params,
      responseType: 'json'
    })

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map(_r => {
        return _r as __StrictHttpResponse<Array<SkillInfo>>
      })
    )
  }

  /**
   * @return Success
   */
  languageSkillList(): __Observable<Array<SkillInfo>> {
    return this.languageSkillListResponse().pipe(__map(_r => _r.body as Array<SkillInfo>))
  }
  publicationTypeResponse(): __Observable<__StrictHttpResponse<any>> {
    let __params = this.newParams()
    let __headers = new HttpHeaders()
    let __body: any = null
    let req = new HttpRequest<any>('GET', this.rootUrl + `/values/vacancy/publicationtype`, __body, {
      headers: __headers,
      params: __params,
      responseType: 'json'
    })

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map(_r => {
        return _r as __StrictHttpResponse<any>
      })
    )
  }
  publicationType(): __Observable<any> {
    return this.publicationTypeResponse().pipe(__map(_r => _r.body as any))
  }
  sendResumeTypeResponse(): __Observable<__StrictHttpResponse<any>> {
    let __params = this.newParams()
    let __headers = new HttpHeaders()
    let __body: any = null
    let req = new HttpRequest<any>('GET', this.rootUrl + `/values/vacancy/sendresumetype`, __body, {
      headers: __headers,
      params: __params,
      responseType: 'json'
    })

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map(_r => {
        return _r as __StrictHttpResponse<any>
      })
    )
  }
  sendResumeType(): __Observable<any> {
    return this.sendResumeTypeResponse().pipe(__map(_r => _r.body as any))
  }

  /**
   * @return Success
   */
  importanceResponse(): __Observable<__StrictHttpResponse<Array<LocalizableDataInfo>>> {
    let __params = this.newParams()
    let __headers = new HttpHeaders()
    let __body: any = null
    let req = new HttpRequest<any>('GET', this.rootUrl + `/values/controlquestion/importance`, __body, {
      headers: __headers,
      params: __params,
      responseType: 'json'
    })

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map(_r => {
        return _r as __StrictHttpResponse<Array<LocalizableDataInfo>>
      })
    )
  }

  /**
   * @return Success
   */
  importance(): __Observable<Array<LocalizableDataInfo>> {
    return this.importanceResponse().pipe(__map(_r => _r.body as Array<LocalizableDataInfo>))
  }

  /**
   * @return Success
   */
  blockingReasonResponse(): __Observable<__StrictHttpResponse<Array<BlockingReasonInfo>>> {
    let __params = this.newParams()
    let __headers = new HttpHeaders()
    let __body: any = null
    let req = new HttpRequest<any>('GET', this.rootUrl + `/values/blockingreason`, __body, {
      headers: __headers,
      params: __params,
      responseType: 'json'
    })

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map(_r => {
        return _r as __StrictHttpResponse<Array<BlockingReasonInfo>>
      })
    )
  }

  /**
   * @return Success
   */
  blockingReason(): __Observable<Array<BlockingReasonInfo>> {
    return this.blockingReasonResponse().pipe(__map(_r => _r.body as Array<BlockingReasonInfo>))
  }

  /**
   * @return Success
   */
  pricePeriodResponse(): __Observable<__StrictHttpResponse<Array<LocalizableDataInfo>>> {
    let __params = this.newParams()
    let __headers = new HttpHeaders()
    let __body: any = null
    let req = new HttpRequest<any>('GET', this.rootUrl + `/values/priceperiod`, __body, {
      headers: __headers,
      params: __params,
      responseType: 'json'
    })

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map(_r => {
        return _r as __StrictHttpResponse<Array<LocalizableDataInfo>>
      })
    )
  }

  /**
   * @return Success
   */
  pricePeriod(): __Observable<Array<LocalizableDataInfo>> {
    return this.pricePeriodResponse().pipe(__map(_r => _r.body as Array<LocalizableDataInfo>))
  }
  platformResponse(): __Observable<__StrictHttpResponse<any>> {
    let __params = this.newParams()
    let __headers = new HttpHeaders()
    let __body: any = null
    let req = new HttpRequest<any>('GET', this.rootUrl + `/values/platform`, __body, {
      headers: __headers,
      params: __params,
      responseType: 'json'
    })

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map(_r => {
        return _r as __StrictHttpResponse<any>
      })
    )
  }
  platform(): __Observable<any> {
    return this.platformResponse().pipe(__map(_r => _r.body as any))
  }
  detailServiceTypeResponse(): __Observable<__StrictHttpResponse<any>> {
    let __params = this.newParams()
    let __headers = new HttpHeaders()
    let __body: any = null
    let req = new HttpRequest<any>('GET', this.rootUrl + `/values/servicetype`, __body, {
      headers: __headers,
      params: __params,
      responseType: 'json'
    })

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map(_r => {
        return _r as __StrictHttpResponse<any>
      })
    )
  }
  detailServiceType(): __Observable<any> {
    return this.detailServiceTypeResponse().pipe(__map(_r => _r.body as any))
  }

  /**
   * @return Success
   */
  oblastResponse(): __Observable<__StrictHttpResponse<Array<Oblast>>> {
    let __params = this.newParams()
    let __headers = new HttpHeaders()
    let __body: any = null
    let req = new HttpRequest<any>('GET', this.rootUrl + `/values/oblastlist`, __body, {
      headers: __headers,
      params: __params,
      responseType: 'json'
    })

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map(_r => {
        return _r as __StrictHttpResponse<Array<Oblast>>
      })
    )
  }

  /**
   * @return Success
   */
  oblast(): __Observable<Array<Oblast>> {
    return this.oblastResponse().pipe(__map(_r => _r.body as Array<Oblast>))
  }

  /**
   * @return Success
   */
  profileBenefitsResponse(): __Observable<__StrictHttpResponse<Array<LocalizableDataInfo>>> {
    let __params = this.newParams()
    let __headers = new HttpHeaders()
    let __body: any = null
    let req = new HttpRequest<any>('GET', this.rootUrl + `/values/profilebenefits`, __body, {
      headers: __headers,
      params: __params,
      responseType: 'json'
    })

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map(_r => {
        return _r as __StrictHttpResponse<Array<LocalizableDataInfo>>
      })
    )
  }

  /**
   * @return Success
   */
  profileBenefits(): __Observable<Array<LocalizableDataInfo>> {
    return this.profileBenefitsResponse().pipe(__map(_r => _r.body as Array<LocalizableDataInfo>))
  }

  /**
   * @return Success
   */
  vacancyBadgeSuggestionsResponse(): __Observable<__StrictHttpResponse<Array<LocalizableDataInfo>>> {
    let __params = this.newParams()
    let __headers = new HttpHeaders()
    let __body: any = null
    let req = new HttpRequest<any>('GET', this.rootUrl + `/values/vacancy-badge-suggestions`, __body, {
      headers: __headers,
      params: __params,
      responseType: 'json'
    })

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map(_r => {
        return _r as __StrictHttpResponse<Array<LocalizableDataInfo>>
      })
    )
  }

  /**
   * @return Success
   */
  vacancyBadgeSuggestions(): __Observable<Array<LocalizableDataInfo>> {
    return this.vacancyBadgeSuggestionsResponse().pipe(__map(_r => _r.body as Array<LocalizableDataInfo>))
  }

  /**
   * @return Success
   */
  cvSearchExperienceResponse(): __Observable<__StrictHttpResponse<Array<LocalizableDataInfo>>> {
    let __params = this.newParams()
    let __headers = new HttpHeaders()
    let __body: any = null
    let req = new HttpRequest<any>('GET', this.rootUrl + `/values/cv-search-experience`, __body, {
      headers: __headers,
      params: __params,
      responseType: 'json'
    })

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map(_r => {
        return _r as __StrictHttpResponse<Array<LocalizableDataInfo>>
      })
    )
  }

  /**
   * @return Success
   */
  cvSearchExperience(): __Observable<Array<LocalizableDataInfo>> {
    return this.cvSearchExperienceResponse().pipe(__map(_r => _r.body as Array<LocalizableDataInfo>))
  }

  /**
   * @param keywords undefined
   */
  synonymousCheckAsyncResponse(keywords: string): __Observable<__StrictHttpResponse<any>> {
    let __params = this.newParams()
    let __headers = new HttpHeaders()
    let __body: any = null

    let req = new HttpRequest<any>('GET', this.rootUrl + `/values/synonymous-check/${keywords}`, __body, {
      headers: __headers,
      params: __params,
      responseType: 'json'
    })

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map(_r => {
        return _r as __StrictHttpResponse<any>
      })
    )
  }

  /**
   * @param keywords undefined
   */
  synonymousCheckAsync(keywords: string): __Observable<any> {
    return this.synonymousCheckAsyncResponse(keywords).pipe(__map(_r => _r.body as any))
  }

  /**
   * @param keywords undefined
   */
  synonymousSimilarResponse(keywords: string): __Observable<__StrictHttpResponse<any>> {
    let __params = this.newParams()
    let __headers = new HttpHeaders()
    let __body: any = null

    let req = new HttpRequest<any>('GET', this.rootUrl + `/values/similar-synonymous/${keywords}`, __body, {
      headers: __headers,
      params: __params,
      responseType: 'json'
    })

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map(_r => {
        return _r as __StrictHttpResponse<any>
      })
    )
  }

  /**
   * @param keywords undefined
   */
  synonymousSimilar(keywords: string): __Observable<any> {
    return this.synonymousSimilarResponse(keywords).pipe(__map(_r => _r.body as any))
  }

  /**
   * @param count undefined
   */
  randomSynonymousResponse(count?: number): __Observable<__StrictHttpResponse<any>> {
    let __params = this.newParams()
    let __headers = new HttpHeaders()
    let __body: any = null
    if (count != null) __params = __params.set('count', count.toString())
    let req = new HttpRequest<any>('GET', this.rootUrl + `/values/random-synonymous`, __body, {
      headers: __headers,
      params: __params,
      responseType: 'json'
    })

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map(_r => {
        return _r as __StrictHttpResponse<any>
      })
    )
  }

  /**
   * @param count undefined
   */
  randomSynonymous(count?: number): __Observable<any> {
    return this.randomSynonymousResponse(count).pipe(__map(_r => _r.body as any))
  }
}

module ValuesService {
  /**
   * Parameters for PositionList
   */
  export interface PositionListParams {
    term?: string
    language?: 'Ukrainian' | 'Russian' | 'English' | 'All'
  }
}

export { ValuesService }
