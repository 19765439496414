import { SortedItemsByKey } from './models/sorted-items-by-key'

export const getUniqueItemsFromArray = <T extends string | number>(array: T[]): T[] => array.filter((item, index) => array.indexOf(item) === index)

export const createArray = (length: number = 0): number[] => Array.from({ length })

export const splitArrayByParam = <K extends keyof T, T extends object>(itemsArray: T[], param: K): Array<SortedItemsByKey<T>> =>
  itemsArray.reduce<Array<SortedItemsByKey<T>>>((acc, item) => {
    const key = item[param]

    if (typeof key === 'string') {
      const itemWithKeyExist = acc.find((orderedItem: SortedItemsByKey<T>) => key === orderedItem.key)
      const itemExist = itemWithKeyExist ? itemWithKeyExist.items.find(searchedItem => searchedItem === item) : null

      if (!itemWithKeyExist) {
        return [...acc, { key, items: [item] }] as Array<SortedItemsByKey<T>>
      } else if (!itemExist) {
        return acc.map(accItem => (key === accItem.key ? { ...accItem, items: [...accItem.items, item] } : accItem))
      }
    }
    return acc
  }, [])

export const sortBySearchPosition = <T>(list: T[], key: keyof T, searchValue: string): T[] =>
  list.sort((a, b) => {
    const prevValue = a[key]
    const nextValue = b[key]
    if (isString(prevValue) && isString(nextValue)) {
      return prevValue.toLowerCase().indexOf(searchValue.toLowerCase()) - nextValue.toLowerCase().indexOf(searchValue.toLowerCase())
    }
    return -1
  })

export const sortByCustomWeight = <T>(list: T[], mainKey: keyof T, spareKeys: Array<keyof T>, searchValue: string): T[] => {
  const _getWeight = (item: T, search: string): number => {
    const normalizedSearch = search.toLowerCase()
    const mainValue = item[mainKey]
    const indexInCurrentLang = isString(mainValue) ? mainValue.toLowerCase().indexOf(normalizedSearch) : -1
    const spareKey = spareKeys.find(key => {
      const value = item[key]
      return isString(value) && value.toLowerCase().includes(normalizedSearch)
    })
    const spareValue = spareKey ? item[spareKey] : ''
    const indexInSpareLang = isString(spareValue) ? spareValue.toLowerCase().indexOf(normalizedSearch) : -1

    switch (true) {
      case indexInCurrentLang === 0:
        return 10
      case indexInCurrentLang > 0:
        return 20
      case indexInSpareLang === 0:
        return 30
      case indexInSpareLang > 0:
        return 40
      default:
        return -1
    }
  }
  return list.sort((a, b) => _getWeight(a, searchValue) - _getWeight(b, searchValue))
}

const isString = (input: unknown): input is string => typeof input === 'string'

export const getNonNullableItems = <T>(array: ReadonlyArray<T | null>): T[] => [...array].filter<T>((item): item is T => !!item)

export const uniqueItemsByKey = <T>(list: T[], key: keyof T): T[] => list.filter((item, index) => list.findIndex(_item => _item[key] === item[key]) === index)
