import { AuthService } from '@alliance/shared/auth/api'
import { RxStateService } from '@alliance/shared/models'
import { getNonNullableItems } from '@alliance/shared/utils'
import { Injectable } from '@angular/core'
import { iif, Observable, of } from 'rxjs'
import { catchError, distinctUntilChanged, map, shareReplay, switchMap } from 'rxjs/operators'
import { ServiceType } from '@alliance/shared/domain-gql'
import {
  EmployerCommonLimitFragment,
  GetEmployerCommonLimitsGQL,
  GetEmployerCommonLimitsQuery,
  GetEmployerCommonLimitsQueryVariables,
  GetEmployerHasAccessToLimitsGQL
} from './employer-services-limits.generated'
import { QueryRef } from 'apollo-angular'

@Injectable({
  providedIn: 'root'
})
export class EmployerServicesLimitsService extends RxStateService<{
  hasAccessToLimits: boolean
  servicesLimits: EmployerCommonLimitFragment[]
}> {
  private servicesLimitsQueryRef: QueryRef<GetEmployerCommonLimitsQuery, GetEmployerCommonLimitsQueryVariables> | undefined

  private readonly PUBLICATION_SERVICE_TYPES: ServiceType[] = [ServiceType.Business, ServiceType.Optimum, ServiceType.Professional, ServiceType.Anonymous]

  public constructor(protected authService: AuthService, protected getEmployerCommonLimitsGQL: GetEmployerCommonLimitsGQL, protected getEmployerHasAccessToLimitsGQL: GetEmployerHasAccessToLimitsGQL) {
    super()

    this.initState({
      hasAccessToLimits: this.getHasAccessToLimits$().pipe(shareReplay(1)),
      servicesLimits: this.select('hasAccessToLimits').pipe(
        switchMap(hasAccess => iif(() => hasAccess, this.getServicesLimits$(), of([]))),
        shareReplay(1)
      )
    })
  }

  public get hasAccessToLimits(): boolean {
    return this.get().hasAccessToLimits
  }

  public getHotLimit$(): Observable<EmployerCommonLimitFragment> {
    const defaultHotLimit: EmployerCommonLimitFragment = {
      serviceType: ServiceType.Hot,
      has: false,
      total: 0,
      remaining: 0
    }
    return this.select('servicesLimits').pipe(map(servicesLimits => servicesLimits?.find(limit => limit.serviceType === ServiceType.Hot) ?? defaultHotLimit))
  }

  public getPublicationLimit$(serviceTypes = this.PUBLICATION_SERVICE_TYPES): Observable<EmployerCommonLimitFragment[]> {
    return this.select('servicesLimits').pipe(map(servicesLimits => servicesLimits.filter(limit => limit?.serviceType && serviceTypes.includes(limit.serviceType)) ?? []))
  }

  public getHasAvailableLimits(limits: EmployerCommonLimitFragment[]): boolean {
    return !limits?.length || limits?.some(limit => !limit?.has || (limit?.remaining && limit.remaining > 0))
  }

  public update(): void {
    if (this.servicesLimitsQueryRef) {
      this.servicesLimitsQueryRef.refetch()
    }
  }

  private getHasAccessToLimits$(): Observable<boolean> {
    return this.authService.token$.pipe(
      distinctUntilChanged(),
      switchMap(token =>
        token && this.authService.isEmployer
          ? this.getEmployerHasAccessToLimitsGQL.fetch({}, { fetchPolicy: 'network-only' }).pipe(
              map(({ data }) => !!data?.hasLimitsFunctionality),
              catchError(() => of(false))
            )
          : of(false)
      )
    )
  }

  private getServicesLimits$(): Observable<EmployerCommonLimitFragment[]> {
    return this.getServicesLimitsQueryRef().valueChanges.pipe(
      map(({ data }) => getNonNullableItems<EmployerCommonLimitFragment>(data?.employee?.serviceLimitCounters || [])),
      catchError(() => of([]))
    )
  }

  private getServicesLimitsQueryRef(): QueryRef<GetEmployerCommonLimitsQuery, GetEmployerCommonLimitsQueryVariables> {
    if (!this.servicesLimitsQueryRef) {
      this.servicesLimitsQueryRef = this.getEmployerCommonLimitsGQL.watch({ multiUserId: this.authService.user?.MultiUserId?.toString() || '' }, { fetchPolicy: 'cache-and-network' })
    }
    return this.servicesLimitsQueryRef
  }
}
