import { AuthService } from '@alliance/shared/auth/api'
import { DataLayerPushEvent, LoggingToBigQueryService } from '@alliance/shared/utils'
import { Injectable } from '@angular/core'
import { BehaviorSubject } from 'rxjs'
import { filter } from 'rxjs/operators'
import { GtmEvent } from '../models/gtm-event'

@Injectable({
  providedIn: 'root'
})
export class AnalyticsService {
  private _gtmEvents = new BehaviorSubject<GtmEvent | null>(null)

  public constructor(private authService: AuthService, private bqLoggingService: LoggingToBigQueryService) {
    this._gtmEvents
      .asObservable()
      .pipe(filter((event): event is GtmEvent => !!event))
      .subscribe(gtmEvent => {
        const dataLayerEvent = this.composeGtmEvent(gtmEvent)
        this.bqLoggingService.pushToDataLayer(dataLayerEvent)
      })
  }

  public sendNextGtmEvent(gtmEvent: GtmEvent): void {
    this._gtmEvents.next(gtmEvent)
  }

  public composeGtmEvent(gtmEvent: GtmEvent): DataLayerPushEvent {
    const notebookId = this.authService?.user?.NotebookId || 0
    const multiuserId = this.authService?.user?.MultiUserId || 0
    const additionalParams: DataLayerPushEvent = gtmEvent.type

    return {
      notebookId,
      multiuserId,
      ...additionalParams,
      ...(gtmEvent.param && { eventLabel: gtmEvent.param }),
      ...(gtmEvent.source && { eventAction: `${additionalParams.eventAction || ''}${gtmEvent.source}` }),
      ...(gtmEvent.content && {
        eventContent: `${additionalParams.eventContent || ''}${gtmEvent.content}`
      })
    }
  }
}
