import { Injectable } from '@angular/core'
import { Observable, of } from 'rxjs'
import { PartialSeoParamsResponse, PlatformHosts, SearchCompanyAlphabetSeoParams } from '../models'
import { Translations } from '../localization/translations'
import { TranslationService } from '@alliance/shared/translation'
import { HelpersService } from '../helpers.service'

@Injectable({
  providedIn: 'root'
})
export class SearchByCompanyAlphabetDictionaryService {
  public constructor(private translations: Translations, private translationService: TranslationService, private helpersService: HelpersService) {}

  public getParams({ params: { page } }: SearchCompanyAlphabetSeoParams): Observable<PartialSeoParamsResponse> {
    return of({
      title: this.prependPageNumberText(page, this.translationService.translate(this.translations.searchCompanyName.default.title)),
      description: this.prependPageNumberText(page, this.translationService.translate(this.translations.searchCompanyName.default.description)),
      h1: [this.translationService.translate(this.translations.searchCompanyName.h1)],
      canonicalUrl: this.helpersService.createURL(PlatformHosts.desktop, this.getUrl()),
      hrefLang: this.helpersService.createHrefLangURLs(this.getUrl(page), this.getUrl(page)),
      noIndexNoFollow: false,
      jsonLd: {
        desktop: this.helpersService.createJsonLd(PlatformHosts.desktop)
      }
    })
  }

  private prependPageNumberText(page: number | undefined, text: string): string {
    return (page ?? 0) > 1
      ? `${this.translationService.translate(this.translations.searchCompanyName.pageLeadingText, {
          page
        })} ${text}`
      : text
  }

  private getUrl(page?: number): string {
    return '/jobsearch/company' + ((page ?? 0) > 1 ? `?pg=${page ?? ''}` : '')
  }
}
