import { City, VacancyPublicationType } from '@alliance/shared/domain-gql'
import { SpecialServiceType } from '../models/publication-services-aggregated-by-type'

interface VacancyWithSpecialService {
  publicationType?: VacancyPublicationType | null
  supportedRegions?: ReadonlyArray<Partial<City>> | null
  vacancyMailingCount?: number | null
  vacancyRisingCount?: number | null
}

export const isServiceWithRegions = <T extends VacancyWithSpecialService>(service: T | null | undefined): boolean => !!service?.supportedRegions?.length
export const isBusinessPlusService = <T extends VacancyWithSpecialService>(service: T | null | undefined): boolean =>
  !!service && service.publicationType === VacancyPublicationType.Business && !!service.vacancyMailingCount && !!service.vacancyRisingCount

export const isSpecialService = <T extends VacancyWithSpecialService>(service: T | null): boolean => [isServiceWithRegions, isBusinessPlusService].some(fn => fn(service))

export const getSpecialServiceType = <T extends VacancyWithSpecialService>(service: T | null | undefined): SpecialServiceType | null => {
  if (isServiceWithRegions(service)) {
    return SpecialServiceType.WithRegions
  }

  if (isBusinessPlusService(service)) {
    return SpecialServiceType.BusinessPlus
  }

  return null
}
