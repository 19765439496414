import { CvDbHintsResponse, CvDbSearchFacetsResponse, CvDbSearchHistoryItemResponse, CvDbSearchRequest, CvKnockout, CvNote, ResumeInfo, SearchItem } from '@alliance/employer/data-access'
import { ResumeFileModel } from '../models/ResumeFileModel'

export interface CvdbState {
  searchFilters: CvDbSearchRequest
  searchFacets: CvDbSearchFacetsResponse
  searchList: CvKnockout[]
  searchTotal: number | null
  searchTotalFull: number | null
  searchNewCv: number | null
  allCvdbTotal: number | null
  cvsPerPage: number | null
  resumeMap: { [id: number]: ResumeInfo | null }
  id: number | null
  resumeFileMap: { [id: number]: ResumeFileModel }
  notesMap: { [id: number]: CvNote[] }
  searchHistoryList: CvDbSearchHistoryItemResponse[]
  selectedSearches: SearchItem[]
  alternativeHints: CvDbHintsResponse | null
  pending: CvdbStorePendingModel
}

export const INITIAL_CVDB_STATE: CvdbState = {
  searchFacets: {},
  searchFilters: {},
  searchList: [],
  searchTotal: null,
  searchTotalFull: null,
  searchNewCv: null,
  allCvdbTotal: null,
  cvsPerPage: null,
  resumeMap: {},
  id: null,
  resumeFileMap: {},
  notesMap: {},
  searchHistoryList: [],
  selectedSearches: [],
  alternativeHints: null,
  pending: {
    resume: false,
    searchList: false,
    searchFacets: true
  }
}

export interface CvdbStorePendingModel {
  resume: boolean
  searchList: boolean
  searchFacets: boolean
}
