import { TailwindCustomConfig } from '@alliance/santa/tailwind'
import { HEADER_HEIGHT, HeaderData, HeaderModeEnum } from '@alliance/shared/header/utils'
import { RxStateComponent } from '@alliance/shared/models'
import { MediaService, UserSideEnum } from '@alliance/shared/utils'
import { AfterViewInit, ChangeDetectionStrategy, Component, ComponentRef, ElementRef, Injector, Input, OnDestroy, Renderer2, ViewContainerRef, ɵcreateInjector as createInjector } from '@angular/core'
import { combineLatest, from, Observable, of } from 'rxjs'
import { catchError, filter, map, switchMap, take } from 'rxjs/operators'

@Component({
  selector: 'app-shell-header',
  template: '',
  styleUrls: ['./shell.component.tw.css'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ShellHeaderComponent
  extends RxStateComponent<{
    userSide: UserSideEnum | null
    headerData: HeaderData | null
    isEmployer: boolean
    isSeeker: boolean
    isLoading: boolean
    isMobile: boolean
    isShowHeader: boolean
  }>
  implements AfterViewInit, OnDestroy
{
  private componentRef: ComponentRef<unknown> | null = null

  public constructor(
    private readonly viewContainerRef: ViewContainerRef,
    private readonly injector: Injector,
    private readonly elementRef: ElementRef<HTMLElement>,
    private readonly renderer: Renderer2,
    private readonly mediaService: MediaService
  ) {
    super()

    this.initState({
      userSide: null,
      headerData: null,
      isEmployer: false,
      isSeeker: false,
      isLoading: true,
      isMobile: this.mediaService.select('isMobileScreen'),
      isShowHeader: combineLatest([this.select('headerData'), this.select('isMobile')]).pipe(
        map(([headerData, isMobile]) => !!(headerData?.preventRenderHeaderIfMobile ? !isMobile && headerData?.isShowHeader : headerData?.isShowHeader))
      )
    })

    this.hold(
      this.select('isShowHeader').pipe(
        filter(Boolean),
        take(1),
        switchMap(() => combineLatest([this.select('headerData'), this.select('userSide'), this.select('isLoading')]))
      ),
      ([headerData, userSide, isLoading]) => {
        this.renderer.setStyle(this.elementRef.nativeElement, 'height', isLoading ? `${HEADER_HEIGHT}px` : null)
        this.renderer.setStyle(
          this.elementRef.nativeElement,
          'background-color',
          headerData?.headerMode === HeaderModeEnum.homePage && userSide === UserSideEnum.jobseeker ? TailwindCustomConfig.THEME.colors.red['500'] : null
        )
      }
    )
  }

  @Input() public set userSide(value: UserSideEnum | null) {
    this.set({ userSide: value })
  }

  @Input() public set headerData(value: HeaderData | null) {
    this.set({ headerData: value })
  }

  @Input() public set isEmployer(value: boolean) {
    this.set({ isEmployer: value })
  }

  @Input() public set isSeeker(value: boolean) {
    this.set({ isSeeker: value })
  }

  public ngAfterViewInit(): void {
    this.hold(this.select('isShowHeader').pipe(switchMap(isShowHeader => (isShowHeader ? this.render$() : of(this.clear()).pipe(map(() => null))))), componentRef => {
      this.componentRef = componentRef
    })
  }

  public override ngOnDestroy(): void {
    super.ngOnDestroy()

    this.clear()
  }

  private render$(): Observable<ComponentRef<unknown> | null> {
    return from(import('../lazy-desktop-header/lazy-desktop-header.module')).pipe(
      map(({ LazyDesktopHeaderModule }) => {
        const injector = createInjector(LazyDesktopHeaderModule, this.injector)
        const module = injector.get(LazyDesktopHeaderModule)

        if (this.viewContainerRef) {
          const componentRef = module.render(this.viewContainerRef)

          this.hold(componentRef.instance.loading, value => this.set({ isLoading: value }))
          this.hold(this.select('headerData').pipe(filter(Boolean)), ({ headerMode, positionMode }) => {
            componentRef.instance.headerMode = headerMode || null
            componentRef.instance.positionMode = positionMode || null
          })
          this.hold(this.select('userSide'), userSide => (componentRef.instance.userSide = userSide))
          this.hold(this.select('isEmployer'), isEmployer => (componentRef.instance.isEmployer = isEmployer))
          this.hold(this.select('isSeeker'), isSeeker => (componentRef.instance.isSeeker = isSeeker))

          componentRef.changeDetectorRef.detectChanges()
          return componentRef
        }

        return null
      }),
      catchError(() => of(null))
    )
  }

  private clear(): void {
    this.componentRef?.destroy()
    this.viewContainerRef?.clear()
  }
}
