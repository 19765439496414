import { ConnectionInfo, WebSocketsService } from '@alliance/socket/data-access'
import { Injectable } from '@angular/core'
import { Observable } from 'rxjs'

@Injectable({
  providedIn: 'root'
})
export class SocketService {
  public constructor(private webSocketService: WebSocketsService) {}

  public connect(): Observable<ConnectionInfo> {
    return this.webSocketService.getConnect()
  }
}
