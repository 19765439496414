export interface VacancyMediaItemDto {
  url?: string
  type?: VacancyMediaItemDto.TypeEnum
  description?: string
}

export namespace VacancyMediaItemDto {
  export type TypeEnum = 'UploadedImage' | 'YoutubeVideo'
  export const TypeEnum = {
    UploadedImage: 'UploadedImage' as TypeEnum,
    YoutubeVideo: 'YoutubeVideo' as TypeEnum
  }
}
