import { Injectable } from '@angular/core'

/**
 * Global configuration for EmployerDataAccess services
 */
@Injectable({
  providedIn: 'root'
})
export class EmployerDataAccessConfiguration {
  rootUrl = ''
}

export interface EmployerDataAccessConfigurationInterface {
  rootUrl?: string
}
