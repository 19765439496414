import { Injectable } from '@angular/core'
import { RxStateService } from '@alliance/shared/models'
@Injectable({ providedIn: 'root' })
export class ChatApplyStateService extends RxStateService<{
  showApplyMessage: boolean
}> {
  public constructor() {
    super()

    this.initState({
      showApplyMessage: false
    })
  }
}
