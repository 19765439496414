import { DataLayerPushEvent } from '@alliance/shared/utils'

export const STATISTIC_TRY_TO_SUGGEST_KEYWORDS_EVENT: DataLayerPushEvent = {
  event: 'EMP_Statistic',
  eventCategory: 'EMP_Statistic',
  eventAction: 'trySuggest_keyword',
  eventLabel: null,
  eventContent: null
}

export const STATISTIC_SUGGEST_KEYWORDS_EVENT: DataLayerPushEvent = {
  event: 'EMP_Statistic',
  eventCategory: 'EMP_Statistic',
  eventAction: 'Suggest_keyword',
  eventLabel: null,
  eventContent: null
}
