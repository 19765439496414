import { SharedAuthDataAccessCustomModule } from '@alliance/shared/auth/data-access'

import { CommonModule } from '@angular/common'
import { APP_INITIALIZER, ModuleWithProviders, NgModule, Provider } from '@angular/core'
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http'
import { Observable } from 'rxjs'

import { AppInitializerService } from './authentication/app-initializer.service'
import { BearerInterceptor } from './interceptors/bearer.interceptor'
import { LogoutUnauthorizedUsersInterceptor } from './interceptors/logout-unauthorized-users-interceptor.service'
import { WithCredentialsInterceptor } from './interceptors/with-credentials.interceptor'
import { RetryInterceptorService } from './interceptors/retry-interceptor.service'

interface AuthApiModuleParams {
  logoutUnAuthorizedUsers?: boolean
  retryFailedGetRequests?: boolean
}

@NgModule({
  imports: [CommonModule, HttpClientModule, SharedAuthDataAccessCustomModule],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: BearerInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: WithCredentialsInterceptor,
      multi: true
    },
    {
      provide: APP_INITIALIZER,
      useFactory: (appInitializer: AppInitializerService) => (): Observable<boolean> => appInitializer.initializeApp(),
      multi: true,
      deps: [AppInitializerService]
    }
  ]
})
export class SharedAuthApiModule {
  public static forRoot(params?: AuthApiModuleParams): ModuleWithProviders<SharedAuthApiModule> {
    const providers: Provider[] = []

    if (params?.logoutUnAuthorizedUsers) {
      providers.push({
        provide: HTTP_INTERCEPTORS,
        useClass: LogoutUnauthorizedUsersInterceptor,
        multi: true
      })
    }

    if (params?.retryFailedGetRequests) {
      providers.push({
        provide: HTTP_INTERCEPTORS,
        useClass: RetryInterceptorService,
        multi: true
      })
    }

    return {
      ngModule: SharedAuthApiModule,
      providers
    }
  }
}
