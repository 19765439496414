export enum RecomsPosLabels {
  spa_recomtab = 'spa_recomtab',
  dkp_recom_page_by_tag = 'dkp_recom_page_by_tag',
  spa_recom_page_by_tag = 'spa_recom_page_by_tag',
  spa_thanks_similar_profile = 'spa_thanks_similar_profile',
  spa_zerosearch = 'spa_zerosearch',
  spa_vacancy_similar_profile = 'spa_vacancy_similar_profile',
  spa_company = 'spa_company',
  spa_search = 'spa_search',
  dkp_search = 'dkp_search',
  dkp_zerosearch = 'dkp_zerosearch',
  dkp_vacancy_similar_profile = 'dkp_vacancy_similar_profile',
  user_profile = 'user_profile',
  desktop_dkp_search_by_tag = 'desktop_dkp_search_by_tag',
  dkp_thx_for_apply_recom = 'dkp_thanks_recom',
  spa_thx_for_apply_recom = 'spa_thanks_recom',
  dkp_thanks_similar_profile = 'dkp_thanks_similar_profile',
  dkp_recom_vacancy_profile = 'dkp_recom_vacancy_profile',
  dkp_recom_vacancy = 'dkp_recom_vacancy',
  spa_recom_vacancy_profile = 'spa_recom_vacancy_profile',
  spa_recom_vacancy = 'spa_recom_vacancy',
  dkp_zerosearch_by_tag = 'dkp_zerosearch_by_tag',
  spa_zerosearch_by_tag = 'spa_zerosearch_by_tag ',
  dkp_applies_similar_profile = 'dkp_applies_similar_profile',
  spa_applies_similar_profile = 'spa_applies_similar_profile'
}
