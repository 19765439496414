import { Environment } from '@alliance/shared/environment'

// take from https://raw.githubusercontent.com/cyclosproject/ng-openapi-gen/master/templates/module.handlebars
// and comment code from constructor and below

import { NgModule, ModuleWithProviders } from '@angular/core'

import { VacancyApiDataAccessConfiguration, VacancyApiDataAccessConfigurationParams } from './vacancy-api-data-access-configuration'

import { VacancyApiContactPhotoService } from './services/vacancy-api-contact-photo.service'
import { VacancyApiFeatureAccessService } from './services/vacancy-api-feature-access.service'
import { VacancyApiVacancyStatisticsService } from './services/vacancy-api-vacancy-statistics.service'

/**
 * Module that provides all services and configuration.
 */
@NgModule({
  imports: [],
  exports: [],
  declarations: [],
  providers: [
    VacancyApiContactPhotoService,
    VacancyApiFeatureAccessService,
    VacancyApiVacancyStatisticsService,
    {
      provide: VacancyApiDataAccessConfiguration,
      useFactory: (environment: Environment): VacancyApiDataAccessConfigurationParams => ({
        rootUrl: environment.vacancyApi
      }),
      deps: [Environment]
    }
  ]
})
export class VacancyApiDataAccessModule {
  static forRoot(params: VacancyApiDataAccessConfigurationParams): ModuleWithProviders<VacancyApiDataAccessModule> {
    return {
      ngModule: VacancyApiDataAccessModule,
      providers: [
        {
          provide: VacancyApiDataAccessConfiguration,
          useValue: params
        }
      ]
    }
  }

  /*
    constructor(
      @Optional() @SkipSelf() parentModule: VacancyApiDataAccessModule,
      @Optional() http: HttpClient
    ) {

      if (parentModule) {
        throw new Error('VacancyApiDataAccessModule is already loaded. Import in your base AppModule only.');
      }
      if (!http) {
        throw new Error('You need to import the HttpClientModule in your AppModule! \n' +
          'See also https://github.com/angular/angular/issues/20575');
      }

    } */
}
