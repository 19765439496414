import { BehaviorSubject, Observable } from 'rxjs'
import { EmployerActivityAction } from './models/employer-activity-action'

export abstract class BaseActivityStorageService<T> {
  public employerActivityHistory$: Observable<Array<EmployerActivityAction<T>>>
  private _employerActivityHistory = new BehaviorSubject<Array<EmployerActivityAction<T>>>([])
  protected abstract actionsList: Array<EmployerActivityAction<T>>

  public constructor() {
    this.employerActivityHistory$ = this._employerActivityHistory.asObservable()
  }

  public addActionToHistory(key: T): void {
    const newAction = this.actionsList.find(action => action.type === key)
    if (newAction) {
      const currentHistoryState = this._employerActivityHistory.getValue()
      this._employerActivityHistory.next([...currentHistoryState, newAction])
    }
  }
}
