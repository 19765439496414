import { OverlayRef } from '@angular/cdk/overlay'
import { Observable, Subject } from 'rxjs'

export class ModalOverlayRef {
  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private afterClosedSubj$ = new Subject<any>()
  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private emittedDataSubj$ = new Subject<any>()

  public constructor(private overlayRef: OverlayRef) {}

  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public close(data?: any): void {
    this.overlayRef.detach()
    this.overlayRef.dispose()

    this.afterClosedSubj$.next(data)
    this.afterClosedSubj$.complete()
    this.emittedDataSubj$.complete()
  }

  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public afterClosed$(): Observable<any> {
    return this.afterClosedSubj$.asObservable()
  }

  public emittedData$<T>(): Observable<T> {
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    return this.emittedDataSubj$.asObservable()
  }

  public emitData<T>(data: T): void {
    this.emittedDataSubj$.next(data)
  }
}
