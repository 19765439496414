import { RxStateService } from '@alliance/shared/models'
import { Injectable } from '@angular/core'
import { AuthService } from '@alliance/shared/auth/api'
import { ConversationCountersDto, ConversationService, MessageUpdateContextViewModel, MessageViewModel } from '@alliance/chat/data-access'
import { catchError, of, switchMap } from 'rxjs'
import { ChatStreamService } from './chat-stream.service'

@Injectable({ providedIn: 'root' })
export class ChatCountService extends RxStateService<{
  chatCounters: ConversationCountersDto
}> {
  public defaultChatCounter: ConversationCountersDto = {
    hasArchivedConversations: false,
    hasBlacklistedConversations: false,
    hasRegularConversations: false
  }

  public constructor(private readonly authService: AuthService, private chatStreamService: ChatStreamService, private conversationsServiceV2: ConversationService) {
    super()

    this.initState({
      chatCounters: this.authService.token$.pipe(
        switchMap(val => (val ? this.conversationsServiceV2.conversationGetConversationCounters$Json().pipe(catchError(() => of(this.defaultChatCounter))) : of(this.defaultChatCounter)))
      )
    })

    this.hold(this.chatStreamService.select('messageStream'), msg => {
      this.updateChatCounters(msg)
    })
  }

  public updateChatCounters(msg: MessageViewModel | MessageUpdateContextViewModel): void {
    if (msg.payloadType === 'message') {
      const message = msg as MessageViewModel
      if (!message.seen && !message.owned) {
        this.set(state => ({
          chatCounters: {
            ...(state.chatCounters ?? this.defaultChatCounter),
            totalUnreadMessagesCount: this.changeCounter(state.chatCounters?.totalUnreadMessagesCount, 1)
          }
        }))
      }
    }
    if (msg.payloadType === 'messageupdate') {
      const message = msg as MessageUpdateContextViewModel
      const readMsg = message.messageIds?.length ?? 0

      this.set(state => ({
        chatCounters: {
          ...(state.chatCounters ?? this.defaultChatCounter),
          totalUnreadMessagesCount: this.changeCounter(state.chatCounters?.totalUnreadMessagesCount, -readMsg)
        }
      }))
    }
  }

  public changeCounter(currentValue: number | undefined, changeValue: number): number {
    if (!currentValue) {
      currentValue = 0
    }
    const result = currentValue + changeValue
    return result < 0 ? 0 : result
  }
}
