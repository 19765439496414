import { CvExperience } from './cv-experience'
export interface ApplyItem {
  number?: number
  id?: number
  parentId?: number
  notebookId?: number
  folderId?: ApplyItem.FolderIdEnum
  isRead?: boolean
  isRepeated?: boolean
  addDate?: Date
  resumeId?: number
  isAnonymous?: boolean
  name?: string
  birthDate?: Date
  eMail?: string
  phone?: string
  speciality?: string
  cityId?: number
  salary?: number
  currencyId?: ApplyItem.CurrencyIdEnum
  language?: number
  isApproved?: boolean
  isArchive?: boolean
  fileName?: string
  coveringLetterExists?: boolean
  summaryPercentage?: number
  isControlQuestionExist?: boolean
  isInBlackList?: boolean
  vacancyId?: number
  originalVacancyId?: number
  isHaveNoExperience?: boolean
  isOpenResumeContactInfo?: boolean
  resumeType?: ApplyItem.ResumeTypeEnum
  photo?: string
  invitationDate?: Date
  refuseDate?: Date
  interviewDate?: Date
  isMessageSent?: boolean
  isInteresting?: boolean
  interestingDate?: Date
  blackListDate?: Date
  deleteDate?: Date
  isMatchVacancy?: boolean
  notesCount?: number
  isLetterReviewUnsubscribe?: boolean
  isFromCVDB?: boolean
  isInvited?: boolean
  isRefused?: boolean
  coveringLetterName?: string
  seekerNotebookId?: number
  isRegistred?: boolean
  isSuperCandidate?: boolean
  tagId?: ApplyItem.TagIdEnum
  experiences?: Array<CvExperience>
  email?: string
  filePath?: string
  isOpenContact?: boolean
}

export namespace ApplyItem {
  export type FolderIdEnum = 'All' | 'AllNotRead' | 'AllRead' | 'BlackList' | 'Deleted' | 'Hired' | 'Invited' | 'JobOffer' | 'New' | 'ReadSent' | 'Refused' | 'SavedCvDb' | 'Selected' | 'Sended'
  export const FolderIdEnum = {
    All: 'All' as FolderIdEnum,
    AllNotRead: 'AllNotRead' as FolderIdEnum,
    AllRead: 'AllRead' as FolderIdEnum,
    BlackList: 'BlackList' as FolderIdEnum,
    Deleted: 'Deleted' as FolderIdEnum,
    Hired: 'Hired' as FolderIdEnum,
    Invited: 'Invited' as FolderIdEnum,
    JobOffer: 'JobOffer' as FolderIdEnum,
    New: 'New' as FolderIdEnum,
    ReadSent: 'ReadSent' as FolderIdEnum,
    Refused: 'Refused' as FolderIdEnum,
    SavedCvDb: 'SavedCvDb' as FolderIdEnum,
    Selected: 'Selected' as FolderIdEnum,
    Sended: 'Sended' as FolderIdEnum
  }
  export type CurrencyIdEnum = 'Ua' | 'Usd'
  export const CurrencyIdEnum = {
    Ua: 'Ua' as CurrencyIdEnum,
    Usd: 'Usd' as CurrencyIdEnum
  }
  export type ResumeTypeEnum = 'AttachedFile' | 'NoCvApply' | 'None' | 'Notepad' | 'Selected'
  export const ResumeTypeEnum = {
    AttachedFile: 'AttachedFile' as ResumeTypeEnum,
    NoCvApply: 'NoCvApply' as ResumeTypeEnum,
    None: 'None' as ResumeTypeEnum,
    Notepad: 'Notepad' as ResumeTypeEnum,
    Selected: 'Selected' as ResumeTypeEnum
  }
  export type TagIdEnum = 'All' | 'AllNotRead' | 'AllRead' | 'BlackList' | 'Deleted' | 'Hired' | 'Invited' | 'JobOffer' | 'New' | 'ReadSent' | 'Refused' | 'SavedCvDb' | 'Selected' | 'Sended'
  export const TagIdEnum = {
    All: 'All' as TagIdEnum,
    AllNotRead: 'AllNotRead' as TagIdEnum,
    AllRead: 'AllRead' as TagIdEnum,
    BlackList: 'BlackList' as TagIdEnum,
    Deleted: 'Deleted' as TagIdEnum,
    Hired: 'Hired' as TagIdEnum,
    Invited: 'Invited' as TagIdEnum,
    JobOffer: 'JobOffer' as TagIdEnum,
    New: 'New' as TagIdEnum,
    ReadSent: 'ReadSent' as TagIdEnum,
    Refused: 'Refused' as TagIdEnum,
    SavedCvDb: 'SavedCvDb' as TagIdEnum,
    Selected: 'Selected' as TagIdEnum,
    Sended: 'Sended' as TagIdEnum
  }
}
